import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { useParams } from 'react-router-dom';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetEmployeePersonalDetails() {
  const { id } = useParams();
  return useQuery(['employee-personal-info'], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_PERSONAL_INFO_API, {
        employee_id: id,
      })
    );
    return res.data;
  });
}
