import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import useGetAllAppliedLeavesForAdmin from '../hooks/useGetAppliedLeavesOfEmployee';
import AcceptLeaveModal from '../../manageLeaves/components/acceptOrRejectLeaveModal';

import { ReactComponent as RightIcon } from 'assets/icons/rightIcon.svg';
import { ReactComponent as WrongIcon } from 'assets/icons/wrongIcon.svg';
import { ReactComponent as EmptyLeavesIlls } from 'assets/images/emptyLeaveIlls.svg';
import noLeavesAllowedIlls from 'assets/images/noLeavesAllowedIlls.png';

import useGetEmployeeLeaves from '../hooks/useGetEmployeeLeaves';

export default function Leaves({
  filterValue,
  searchValue,
  setIsLeavesFileDownload,
}) {
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);
  const [modalName, setModalName] = useState('');

  const [selectedLeaveData, setSelectedLeaveData] = useState('');

  const employeesLeavesData = useGetEmployeeLeaves();
  const getEmployeeAppliedLeaves = useGetAllAppliedLeavesForAdmin(
    filterValue,
    searchValue
  );

  useEffect(() => {
    if (getEmployeeAppliedLeaves?.data?.data?.length) {
      setIsLeavesFileDownload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeeAppliedLeaves?.data?.data]);

  return (
    <>
      <div className='py-4 min-h-[22vh] shadow-lg mt-6 border border-8B8B8B border-opacity-10 rounded-xl bg-white flex flex-wrap mb-7'>
        {employeesLeavesData?.data?.data?.[0]?.leaves_info?.length ? (
          <>
            {employeesLeavesData?.data?.data?.[0]?.leaves_info?.map(
              (item, index) => {
                return (
                  <div
                    className={`${
                      employeesLeavesData?.data?.data?.[0]?.leaves_info
                        ?.length > 5
                        ? 'w-1/5'
                        : employeesLeavesData?.data?.data?.[0]?.leaves_info
                            ?.length === 1
                        ? 'w-full'
                        : `w-1/${employeesLeavesData?.data?.data?.[0]?.leaves_info?.length}`
                    }  ${
                      index === 4 ? '' : 'border-r border-r-[#C5C5C5]'
                    } my-4 text-center`}
                    key={item?.id}
                  >
                    <p className='text-primary font-bold text-3xl'>
                      {item?.remaining_days}/{item?.total_days}
                    </p>
                    <p className='text-lg text-222222 pt-2'>
                      {item?.leave_type}
                    </p>
                    <div className='flex justify-center items-center space-x-1 xl:space-x-4 mt-2'>
                      <p className='text-A3A3A3 text-sm'>
                        Available (this month)
                      </p>
                      <p className='text-bold text-2xl text-A3A3A3'>
                        {item?.remaining_this_month < 10
                          ? `0${item?.remaining_this_month}`
                          : item?.remaining_this_month}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
          </>
        ) : (
          <div
            style={{
              backgroundImage: 'URL(' + noLeavesAllowedIlls + ')',
              backgroundSize: '100% 100%',
            }}
            className='w-full h-[20vh] flex items-center justify-center'
          >
            <p className='text-xl pb-5 pl-10 text-gray'>
              No active leave policies to show
            </p>
          </div>
        )}
      </div>
      <div className='shadow-lg border border-8B8B8B border-opacity-10 rounded-xl bg-white'>
        {getEmployeeAppliedLeaves?.data?.data?.length ? (
          <table className='w-full rounded-xl '>
            <tbody>
              <tr className='bg-FFE8E1 text-left text-18 text-222222'>
                <th className='py-4 pl-5 rounded-tl-xl'>Leave type</th>
                <th className=' pl-5'>Leave period</th>
                <th className=' pl-5'>Duration</th>
                <th className=' pl-5'>Reason</th>
                <th className=' pl-5 rounded-tr-xl'>Status</th>
              </tr>
              {getEmployeeAppliedLeaves?.data?.data?.map((item) => (
                <tr className='text-9B9B9B' key={item?.id}>
                  <td className='py-5 pl-5 text-primary'>
                    {item?.leave_type__name}{' '}
                  </td>
                  <td className='pl-5'>
                    <p>
                      {dayjs(item?.from_date).format('DD MMM YY')} to{' '}
                      {dayjs(item?.to_date).format('DD MMM YY')}
                    </p>
                  </td>
                  <td className='pl-5'>
                    <p>
                      {item?.number_of_days > 1
                        ? `${item?.number_of_days} days`
                        : `${item?.number_of_days} day`}
                    </p>
                  </td>
                  <td className='pl-5 break-all w-96'>{item?.reason}</td>
                  <td className='pl-5 '>
                    {item?.status === 'Cancelled' ? (
                      <p className='text-primary'>Cancelled</p>
                    ) : item?.status === 'Approved' ? (
                      <div>
                        <p className='text-success'>Accepted</p>
                        <p>by {item?.updated_by__first_name}.</p>
                      </div>
                    ) : item?.status === 'Declined' ? (
                      <div>
                        <p className='text-primary'>Rejected</p>
                        <p>by {item?.updated_by__first_name}.</p>
                      </div>
                    ) : (
                      <div className='flex space-x-4'>
                        <WrongIcon
                          className='cursor-pointer'
                          onClick={() => {
                            setSelectedLeaveData(item);
                            setModalName('reject');
                            setLeaveModalOpen(true);
                          }}
                        />
                        <RightIcon
                          className='cursor-pointer'
                          onClick={() => {
                            setSelectedLeaveData(item);
                            setModalName('approve');
                            setLeaveModalOpen(true);
                          }}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className='flex flex-col justify-center items-center h-[60vh]'>
            <EmptyLeavesIlls className='w-72' />
            <p className='text-lg text-gray'>No leaves to show</p>
          </div>
        )}
        <AcceptLeaveModal
          isModalOpen={isLeaveModalOpen}
          setIsModalOpen={setLeaveModalOpen}
          modalName={modalName}
          selectedLeaveData={selectedLeaveData}
        />
      </div>
    </>
  );
}
