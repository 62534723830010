import { useContext, useState } from 'react';

import { Modal, MultiSelect, Select, TextInput } from '@mantine/core';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';

import UseGetEmployeementTypes from '../hooks/useGetEmploymentTypes';
import Usegetgradeorrange from '../hooks/usegetgradeorsalary';
import Usegetgradedata from '../hooks/usegradedata';
import Usegetsalaryrangedata from '../hooks/usegetsalaryrabgedata';
import Usepostsalarycomponents from '../hooks/usepostsalarycomponents';

import { useEffect } from 'react';

import { AuthContext } from 'context/authContext';

import getSymbolFromCurrency from 'currency-symbol-map';

const SalaryComponentsModal = ({
  salaryComponentsModalOpend,
  setSalaryComponentsModalOpend,
}) => {
  const { authState } = useContext(AuthContext);
  const currencyIcon = getSymbolFromCurrency(authState?.currency);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    // setValue,
    reset,
  } = useForm();

  const defaultValue = {
    is_allowance: '',
    name: '',
    is_applicable_for_all: '',
    applicable_for: '',
    is_grade_same: '',
    diff_grades: '',
    grade_value: '',
    is_range_same: '',
    range_value: '',
    diff_ranges: '',
    Grade: [],
    FiledsS: [],
  };

  const employeementTypesData = UseGetEmployeementTypes();
  const isGradeOrRangeData = Usegetgradeorrange();
  const gradeData = Usegetgradedata();
  const salaryData = Usegetsalaryrangedata();

  const [selectAll, setSelectAll] = useState(true);

  const { fields: Grade } = useFieldArray({ control, name: 'diff_grades' });

  const { fields: FiledsS } = useFieldArray({
    control,
    name: 'SalaryRangeData',
  });

  useEffect(() => {
    if (isGradeOrRangeData?.data?.data[0].is_grade) {
      reset({
        diff_grades: gradeData?.data?.data?.map((eachGdRage) => ({
          id: eachGdRage.id,
          name: eachGdRage.name,
        })),
        name: watch('name'),
        applicable_for: watch('applicable_for'),
        is_allowance: watch('is_allowance'),
      });
    }

    // eslint-disable-next-line
  }, [selectAll]);

  useEffect(() => {
    if (!isGradeOrRangeData?.data?.data[0].is_grade) {
      reset({
        SalaryRangeData: salaryData?.data?.data?.map((eachsalarydata) => ({
          id: eachsalarydata.id,
          from_salary: eachsalarydata.from_salary,
          to_salary: eachsalarydata.to_salary,
        })),
        name: watch('name'),
        applicable_for: watch('applicable_for'),
        is_allowance: watch('is_allowance'),
      });
    }
    // eslint-disable-next-line
  }, [selectAll]);

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[750px]'>
        <LeftArrowIcon
          onClick={() => setSalaryComponentsModalOpend(false)}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className='text-primary'>Add a payroll item</p>
      </div>
    );
  };

  const closeSalaryComponentModal = () => {
    reset({ defaultValue });
    setSelectAll(true);
    setSalaryComponentsModalOpend(false);
  };

  const postSalaryComponentmutation = Usepostsalarycomponents(
    closeSalaryComponentModal
  );

  const addSalaryComponent = (data) => {
    const gradeValueOrSalaryValue = isGradeOrRangeData?.data?.data[0].is_grade
      ? { is_grade_same: selectAll }
      : { is_range_same: selectAll };

    if (gradeValueOrSalaryValue.is_range_same) {
      delete data.SalaryRangeData;
    }
    if (gradeValueOrSalaryValue.is_grade_same) {
      delete data.diff_grades;
    }
    const differData = isGradeOrRangeData?.data?.data[0].is_grade
      ? gradeValueOrSalaryValue.is_grade_same
        ? ''
        : {
            diff_grades: data.diff_grades.map((eachgddiff) => {
              return { id: eachgddiff.id, value: eachgddiff.value };
            }),
          }
      : gradeValueOrSalaryValue.is_range_same
      ? ''
      : {
          diff_ranges: data.SalaryRangeData.map((eachssdiff) => {
            return { id: eachssdiff.id, value: eachssdiff.value };
          }),
        };

    const sendRangeData = isGradeOrRangeData?.data?.data[0].is_grade
      ? gradeValueOrSalaryValue.is_grade_same
        ? {
            diff_ranges: salaryData?.data?.data?.map((eachsalarydata) => ({
              id: eachsalarydata.id,
              value: 0,
            })),
            diff_grades: gradeData?.data?.data?.map((eachGdRage) => ({
              id: eachGdRage.id,
              value: 0,
            })),
          }
        : {
            ...differData,
            diff_ranges: salaryData?.data?.data?.map((eachsalarydata) => ({
              id: eachsalarydata.id,
              value: 0,
            })),
          }
      : gradeValueOrSalaryValue.is_range_same
      ? {
          diff_ranges: salaryData?.data?.data?.map((eachsalarydata) => ({
            id: eachsalarydata.id,
            value: 0,
          })),
          diff_grades: gradeData?.data?.data?.map((eachGdRage) => ({
            id: eachGdRage.id,
            value: 0,
          })),
        }
      : {
          ...differData,
          diff_grades: gradeData?.data?.data?.map((eachGdRage) => ({
            id: eachGdRage.id,
            value: 0,
          })),
        };

    postSalaryComponentmutation.mutate({
      ...data,
      ...gradeValueOrSalaryValue,
      ...sendRangeData,
      is_applicable_for_all:
        data.applicable_for === undefined
          ? true
          : data.applicable_for.length === 0
          ? true
          : false,
      // rawData.applicable_for.length === (0 || undefined) ? true : false,
    });

    // console.log({ ...data, ...gradeValueOrSalaryValue, ...sendRangeData });

    //

    //     if (isGradeOrRangeData?.data?.data[0].is_grade) {
    //       // delete data.diff_ranges;
    //       delete data.is_range_same;
    //     } else {
    //       // delete data.diff_grades;
    //       delete data.is_grade_same;
    //     }
    //     const rawData = {
    //       ...data,
    //       ...gradeValueOrSalaryValue,
    //       ...sendRangeData,
    //     };
    //     if (rawData.is_range_same) {
    //       // delete rawData.diff_ranges;
    //       delete rawData.SalaryRangeData;
    //     } else {
    //       delete rawData.range_value;
    //       delete rawData.SalaryRangeData;
    //     }
    //     if (rawData.is_grade_same) {
    //       // delete rawData.diff_grades;
    //     } else {
    //       delete rawData.grade_value;
    //     }
    // //

    // console.log({
    //   ...rawData,
    //   is_applicable_for_all:
    //     rawData.applicable_for === undefined
    //       ? true
    //       : rawData.applicable_for.length === 0
    //       ? true
    //       : false,
    // });
    // console.log(differData, 'sdhgd', sendRangeData, 'djhdj');
    // console.log(data.is_range_same);
  };

  const getFieldArrayFormErrorMessageGrade = (index, subName) => {
    return (
      errors?.diff_grades?.length && (
        <p className='text-[#D0342C] text-sm'>
          {errors?.diff_grades[index]?.[subName]?.message}
        </p>
      )
    );
  };

  const getFieldArrayFormErrorMessageSalary = (index, subName) => {
    return (
      errors?.SalaryRangeData?.length && (
        <p className='text-[#D0342C] text-sm'>
          {errors?.SalaryRangeData[index]?.[subName]?.message}
        </p>
      )
    );
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className='text-[#D0342C] text-sm'>{errors[name].message}</p>
      )
    );
  };

  return (
    <div>
      <Modal
        opened={salaryComponentsModalOpend}
        onClose={closeSalaryComponentModal}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size='xl'
        overflow='inside'
        className='px-8'
        classNames={{
          title: 'text-lg font-semibold ',

          body: 'scroller px-5',
        }}
      >
        <div className='px-5 mt-5'>
          <form onSubmit={handleSubmit(addSalaryComponent)}>
            <div className='grid grid-cols-2 gap-3'>
              <div className=''>
                <Controller
                  control={control}
                  name='name'
                  rules={{
                    required: 'Required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Name'
                      size='md'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent  text-normal rounded pl-5',
                        label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      }}
                    />
                  )}
                />
                {getFormErrorMessage('name')}
              </div>
              <div className=''>
                <Controller
                  control={control}
                  name='is_allowance'
                  rules={{
                    required: 'Required',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label='Type'
                      size='md'
                      rightSection={<DownArrow />}
                      data={[
                        { value: 'True', label: 'Allowance' },
                        { value: 'False', label: 'Deductions' },
                      ]}
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent  text-normal rounded pl-5',
                        label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      }}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                    />
                  )}
                />
                {getFormErrorMessage('is_allowance')}
              </div>
              <div className=''>
                <Controller
                  control={control}
                  name='applicable_for'
                  rules={{
                    required: 'Required',
                  }}
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      label='Employment Type'
                      size='md'
                      rightSection={<DownArrow />}
                      data={employeementTypesData?.data?.data
                        ?.filter((element) => element?.is_active)
                        ?.map((dept) => {
                          return { value: `${dept.id}`, label: dept.name };
                        })}
                      //   className='mt-[26px]'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent  text-normal rounded pl-5',
                        label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      }}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                    />
                  )}
                />
                {getFormErrorMessage('applicable_for')}
              </div>
              <div className=''>
                <Controller
                  control={control}
                  name={
                    isGradeOrRangeData?.data?.data[0].is_grade
                      ? 'is_grade_same'
                      : 'is_range_same'
                  }
                  // rules={{
                  //   required: 'Required',
                  // }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label='Applicable For'
                      size='md'
                      // defaultValue={selectAll}
                      rightSection={<DownArrow />}
                      data={[
                        { value: 'True', label: 'All' },
                        { value: 'False', label: 'By Range' },
                      ]}
                      onChange={(value) =>
                        value === 'True'
                          ? setSelectAll(true)
                          : setSelectAll(false)
                      }
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent  text-normal rounded pl-5',
                        label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      }}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                    />
                  )}
                />
                {/* {getFormErrorMessage(
                  isGradeOrRangeData?.data?.data[0].is_grade
                    ? 'is_grade_same'
                    : 'is_range_same'
                )} */}
              </div>
              {!selectAll ? (
                <>
                  {Grade.length !== 0 || salaryData.length !== 0 ? null : (
                    <>
                      <p className='mt-5'>Ranges</p>
                      <p className='mt-5'>Select values</p>
                    </>
                  )}
                </>
              ) : null}
              {selectAll ? (
                <div className='col-span-2 mt-5'>
                  <Controller
                    control={control}
                    name={
                      isGradeOrRangeData?.data?.data[0].is_grade
                        ? 'grade_value'
                        : 'range_value'
                    }
                    rules={{
                      required: 'Required',
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Percentage of basic'
                        type='number'
                        size='md'
                        classNames={{
                          input:
                            ' text-[15px] bg-transparent  text-normal rounded pl-5',
                          label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                        }}
                      />
                    )}
                  />
                  {getFormErrorMessage(
                    isGradeOrRangeData?.data?.data[0].is_grade
                      ? 'grade_value'
                      : 'range_value'
                  )}
                </div>
              ) : (
                <>
                  {isGradeOrRangeData?.data?.data[0].is_grade ? (
                    <>
                      {Grade?.length === 0 ? (
                        <div className='flex justify-center items-center mt-5 col-span-2'>
                          <p className='text-gray text-sm'>
                            Their is no grades to show please add the grades
                          </p>
                        </div>
                      ) : (
                        <>
                          {Grade.map((fileds, index) => (
                            <>
                              <div>
                                <Controller
                                  control={control}
                                  name={`diff_grades.${index}.name`}
                                  defaultValue={Grade.name}
                                  render={({ field }) => (
                                    <TextInput
                                      {...field}
                                      size='md'
                                      defaultValue={fileds.name}
                                      readOnly
                                      classNames={{
                                        input:
                                          ' text-[15px] bg-transparent  text-normal rounded pl-5',
                                        label:
                                          'text-[#8E8E8E] text-sm font-normal ml-2',
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div>
                                <Controller
                                  control={control}
                                  name={`diff_grades.${index}.value`}
                                  defaultValue={0}
                                  rules={{
                                    required: 'Required',
                                  }}
                                  render={({ field }) => (
                                    <TextInput
                                      {...field}
                                      size='md'
                                      defaultValue={0}
                                      icon={currencyIcon}
                                      type='number'
                                      classNames={{
                                        input:
                                          ' text-[15px] bg-transparent  text-normal rounded pl-5 ml-2',
                                        label:
                                          'text-[#8E8E8E] text-sm font-normal ml-2',
                                        icon: 'text-black mr-1 ',
                                      }}
                                    />
                                  )}
                                />
                                {getFieldArrayFormErrorMessageGrade(
                                  index,
                                  `value`
                                )}
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {FiledsS.map((item, index) => (
                        <>
                          <div>
                            <Controller
                              control={control}
                              name={`SalaryRangeData.${index}.id`}
                              render={({ field }) => (
                                <TextInput
                                  {...field}
                                  size='md'
                                  value={`${item.from_salary} - ${item.to_salary}`}
                                  readOnly
                                  classNames={{
                                    input:
                                      ' text-[15px] bg-transparent  text-normal rounded pl-5',
                                    label:
                                      'text-[#8E8E8E] text-sm font-normal ml-2',
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div>
                            <Controller
                              control={control}
                              name={`SalaryRangeData.${index}.value`}
                              defaultValue={0}
                              rules={{
                                required: 'Required',
                                // min: {
                                //   value: item.from_salary,
                                //   message: 'value should be greater',
                                // },
                                max: {
                                  value: item.to_salary,
                                  message: 'value should be less range',
                                },
                              }}
                              render={({ field }) => (
                                <TextInput
                                  {...field}
                                  size='md'
                                  type='number'
                                  defaultValue={0}
                                  icon={currencyIcon}
                                  classNames={{
                                    input:
                                      ' text-[15px] bg-transparent  text-normal rounded pl-5 ml-2',
                                    label:
                                      'text-[#8E8E8E] text-sm font-normal ml-2',
                                    icon: 'text-black mr-1 ',
                                  }}
                                />
                              )}
                            />
                            {getFieldArrayFormErrorMessageSalary(
                              index,
                              `value`
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </>
              )}

              <div className='col-span-2 mx-auto mt-8 mb-5 '>
                <button className='w-24'>
                  <PrimaryButton>Save</PrimaryButton>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SalaryComponentsModal;
