import { Select, Table } from '@mantine/core';
import { useState } from 'react';
import NewCourseModal from './newCourseModal';

import { ReactComponent as DownArrowIcon } from 'assets/icons/orangeDownArrowIcon.svg';

import useGetSkillDevelopment from '../hooks/useGetSkillDevelopment';
import useUpdateSkillDevelopment from '../hooks/useUpdateSkillDevelopment';
import useDeleteSkillDevelopment from '../hooks/useDeleteSkilDevelopment';
import dayjs from 'dayjs';
import SkillDevelopmentCertificateModal from './skillDevelopmentCertificateModal';
import PreviewForPDF from './previewForPDF';
import PreviewImage from './PreviewImage';

import { ReactComponent as NoSkillDevelopment } from 'assets/icons/noSkillDevelopment.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';

const SkillDevelopment = () => {
  const [addNewCourseModal, setAddNewCourseModal] = useState(false);
  const [UploadCertificateModal, setUploadCertificateModal] = useState(false);
  const [skillDevelopmentId, setSkillDevelopmentId] = useState(null);
  const [previewPdfModal, setPreviewPdfModal] = useState(false);
  const [previewImageModal, setPreviewImageModal] = useState(false);
  const [file, setFile] = useState(null);
  const [courseName, setCourseName] = useState(null);

  const skillDevelopmentData = useGetSkillDevelopment();
  const updateSkillDevelopmentMutation = useUpdateSkillDevelopment();
  const deleteSkillDevelopment = useDeleteSkillDevelopment();

  return (
    <>
      <div >
        <div className='flex justify-between items-center'>
          <h1 className='text-[#222222] font-bold text-lg'>
            Skill development{' '}
          </h1>
          <p
            className='text-[#67A1EF] cursor-pointer'
            onClick={() => setAddNewCourseModal(true)}
          >
            Add new
          </p>
        </div>
        {skillDevelopmentData?.data?.data.length === 0 ? (
          <div className='flex justify-center flex-col items-center'>
            <NoSkillDevelopment />
            <p className='mt-2 text-primary font-semibold text-lg'>
              No content found
            </p>
          </div>
        ) : (
          <div className='mt-4'>
            <Table verticalSpacing={'lg'} highlightOnHover>
              <thead>
                <th className='bg-[#FFE5DC] rounded-tl-lg py-4'>
                  Course title
                </th>
                <th className='bg-[#FFE5DC]'>Duration</th>
                <th className='bg-[#FFE5DC]'>Date of commencement</th>
                <th className='bg-[#FFE5DC]'>Source</th>
                <th className='bg-[#FFE5DC]'>Status</th>
                <th className='bg-[#FFE5DC] rounded-tr-lg'>Certificate</th>
              </thead>
              <tbody>
                {skillDevelopmentData?.data?.data?.map((each) => (
                  <tr>
                    <td className='text-center text-primary'>{each?.title}</td>
                    <td className='text-center'>
                      {dayjs(each?.to_date).diff(each?.from_date, 'months') ===
                      0
                        ? `${dayjs(each?.to_date).diff(
                            each?.from_date,
                            'days'
                          )} days`
                        : `${dayjs(each?.to_date).diff(
                            each?.from_date,
                            'months'
                          )} months`}
                    </td>
                    <td className='text-center'>
                      {dayjs(each?.from_date).format('DD MMM YYYY')}
                    </td>
                    <td className='text-center'>{each?.source}</td>
                    <td className='flex justify-center items-center gap-5'>
                      <Select
                        name='status'
                        defaultValue={each?.status}
                        data={[
                          { value: 'Ongoing', label: 'Ongoing' },
                          { value: 'Completed', label: 'Completed' },
                          {
                            value: 'Not yet started',
                            label: 'Not yet started',
                          },
                        ]}
                        //   value={selectEvent}
                        onChange={(event) =>
                          updateSkillDevelopmentMutation.mutate({
                            status: event,
                            id: each?.id,
                            employee_id: each?.employee_id,
                          })
                        }
                        rightSection={<DownArrowIcon />}
                        className='w-44'
                        classNames={{
                          rightSection: 'pointer-events-none',
                          wrapper: 'border-primary border-b',
                          input:
                            each?.status === 'Not yet started'
                              ? 'bg-inherit text-[#B1B1B1] text-bold border-none text-lg'
                              : each?.status === 'Ongoing'
                              ? 'bg-inherit text-[#FBBB00] text-bold border-none text-lg'
                              : 'bg-inherit text-[#28B446] text-bold border-none text-lg',
                        }}
                      />
                      <DeleteIcon
                        className='self-end -mr-20 cursor-pointer'
                        onClick={() => {
                          deleteSkillDevelopment.mutate({
                            id: each?.id,
                            employee_id: each?.employee_id,
                          });
                        }}
                      />
                    </td>
                    <td
                      className={
                        each?.certificate === null
                          ? 'text-center cursor-pointer text-[#67A1EF]'
                          : 'text-center cursor-pointer text-[#FC8C67]'
                      }
                    >
                      {each?.certificate === null ? (
                        <p
                          onClick={() => {
                            setUploadCertificateModal(true);
                            setSkillDevelopmentId(each?.id);
                          }}
                        >
                          Add
                        </p>
                      ) : (
                        <p
                          onClick={() => {
                            const extension = each?.certificate
                              ?.split('.')
                              .pop();
                            if (extension === 'pdf') {
                              setPreviewPdfModal(true);
                              setFile(each?.certificate);
                              setCourseName(each?.title);
                            } else {
                              setPreviewImageModal(true);
                              setFile(each?.certificate);
                            }
                          }}
                        >
                          View
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <NewCourseModal
        addNewCourseModal={addNewCourseModal}
        setAddNewCourseModal={setAddNewCourseModal}
      />
      <SkillDevelopmentCertificateModal
        UploadCertificateModal={UploadCertificateModal}
        setUploadCertificateModal={setUploadCertificateModal}
        skillDevelopmentId={skillDevelopmentId}
        setSkillDevelopmentId={setSkillDevelopmentId}
      />
      <PreviewForPDF
        previewPdfModal={previewPdfModal}
        setPreviewPdfModal={setPreviewPdfModal}
        file={file}
        setFile={setFile}
        name={courseName}
        setCourseName={setCourseName}
      />
      <PreviewImage
        previewImageModal={previewImageModal}
        setPreviewImageModal={setPreviewImageModal}
        file={file}
        setFile={setFile}
      />
    </>
  );
};

export default SkillDevelopment;
