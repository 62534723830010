import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function UsegetCountries() {
  return useQuery('countries', async () => {
    const response = await makeApiRequest.get(apiEndPoints.COUNTRIES);

    return response.data;
  });
}
