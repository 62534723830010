import createQueryParamsForGetReq from "helper/createQueryParamsForGetReq";
import { useQuery } from "react-query";

import apiEndPoints from "services/apiEndPoints";
import makeApiRequest from "services/makeApiRequest";

export default function useGetPerformanceobjectiverating(id) {
  console.log(id,"id");
  return useQuery(
    ["objective-performance-reports", id],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.PERFORMANCE_OBJECTIVE_RATING, {
          performance_report_id: id,
        })
      );
     
      return response.data;
    },
    {
      enabled: !!id,
    }
  );
}
