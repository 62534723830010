import React, { useEffect } from 'react';
import { Modal, Select, TextInput } from '@mantine/core';
import PrimaryButton from '../../../components/primaryButton';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import shortid from 'shortid';

import useAddEmployeDesignations from '../hooks/useAddDesignation';
import useGetAllDepartments from '../hooks/useGetAllDepartments';

import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/orangeLeftArrow.svg';
import useEditEmployeDesignations from '../hooks/useEditDesignation';

export default function AddDesignationModal({
  isModalOpen,
  setIsModalOpen,
  editData,
  setEditData,
}) {
  const defaultvalues = {
    department_id: '',
    designations_list: [{ name: '' }],
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: `${editData?.name ? editData?.name : ''}`,
      department_id: `${editData?.department ? editData?.department : ''}`,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'designations_list',
  });

  const closeModal = () => {
    setIsModalOpen(false);
    if (setEditData) setEditData(null);
    reset(defaultvalues);
  };

  useEffect(() => {
    if (editData?.name) {
      reset({
        designations_list: editData?.name
          ? [{ name: editData?.name }]
          : [{ name: '' }],
        department_id: `${editData?.department ? editData?.department : ''}`,
      });
    } else {
      reset({
        department_id: '',
        designations_list: [{ name: '' }],
      });
    }
  }, [editData, reset]);

  const getAllDepartments = useGetAllDepartments();
  const addDesignations = useAddEmployeDesignations(closeModal);
  const editDesignations = useEditEmployeDesignations(closeModal);

  const formSubmitHandler = (data) => {
    if (editData?.department) {
      editDesignations.mutate(
        { ...data, id: editData.id },
        {
          onSuccess: () => {
            reset({});
            closeModal();
          },
        }
      );
    } else {
      addDesignations.mutate(data, {
        onSuccess: () => {
          reset({});
          closeModal();
        },
      });
    }
  };

  const getFieldArrayFormErrorMessages = (index, subName) => {
    return (
      errors?.diff_grades?.length && (
        <p className='text-[#D0342C] text-sm'>
          {errors?.diff_grades[index]?.[subName]?.message}
        </p>
      )
    );
  };

  const renderFormErrorMsg = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500 pt-1 pl-1'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={closeModal}
      title={
        <div className='flex w-96 justify-between'>
          <LeftArrowIcon
            className='h-5 w-5 cursor-pointer'
            onClick={closeModal}
          />{' '}
          <p>{`${editData?.department ? 'Edit' : 'Add'}`} designation</p>{' '}
          <span className='w-5 h-5'></span>
        </div>
      }
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size={500}
      radius='md'
      transitionDuration={100}
      classNames={{
        title: 'mx-auto text-primary font-semibold text-18',
        modal: 'px-10',
      }}
    >
      <div className='relative'>
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <div className='mt-5'>
            <Controller
              control={control}
              name='department_id'
              rules={{
                required: 'Required',
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  label='Department type*'
                  size='md'
                  disabled={!!editData?.department}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  rightSection={<DownArrow className='mt-1' />}
                  data={
                    getAllDepartments?.data?.data
                      ?.filter((element) => element?.is_active)
                      ?.map((dept) => {
                        return {
                          value: `${dept?.id}`,
                          label: dept?.name,
                        };
                      }) || []
                  }
                  classNames={{
                    input: 'border-primary',
                    label: 'text-neutralsGrey text-sm',
                  }}
                />
              )}
            />
            {renderFormErrorMsg('department_id')}
          </div>
          {fields?.map((item, index) => (
            <div className='' key={shortid?.generate()}>
              <div>
                <Controller
                  control={control}
                  name={`designations_list[${index}].name`}
                  // name='name'
                  rules={{
                    required: 'Required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      label='Designation name*'
                      {...field}
                      size='md'
                      className='mt-4'
                      defaultValue={item.name}
                      classNames={{
                        label: 'text-sm text-neutralsGrey',
                        input: 'border-primary bg-transparent',
                      }}
                    />
                  )}
                />
                {getFieldArrayFormErrorMessages(index, 'name')}
              </div>
              {index === 0 ? null : (
                <div className='flex justify-end'>
                  <p
                    className='text-[#67A1EF] text-sm w-fit cursor-pointer bg-white px-2'
                    onClick={() => remove(index)}
                  >
                    - Remove
                  </p>
                </div>
              )}
            </div>
          ))}
          {/* <div className='flex justify-end'>
            <p
              className='text-[#67A1EF] cursor-pointer'
              onClick={() =>
                append({
                  name: '',
                })
              }
            >
              + Add more
            </p>
          </div> */}
          <div className='flex justify-center'>
            {editData?.name ? null : (
              <button
                onClick={() =>
                  append({
                    name: '',
                  })
                }
                type='button'
                className='mt-10 mb-4 w-32 mr-4'
              >
                <PrimaryButton>
                  <p>Add more</p>
                </PrimaryButton>
              </button>
            )}
            <button className='mt-10 mb-4 w-24 ml-4'>
              <PrimaryButton>
                <p>Save</p>
              </PrimaryButton>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
