import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetsalarydata(id) {
  return useQuery(
    ['salary_data_in_organisition', id],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.SETTINGS_SALARY_RANGE, {
          req_company_id: id,
          is_active: 'True',
        })
      );

      return response.data;
    },
    {
      enabled: !!id,
    }
  );
}
