import { useContext } from 'react';
import { AuthContext } from 'context/authContext';
import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useActivities() {
  const { authState } = useContext(AuthContext);
  return useQuery('activities', async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.DASHBOARD_ACTIVITY_TRACKER, {
        user_id: authState?.user_id,
      })
    );
    return response?.data;
  });
}
