import React from 'react';
import { Tabs } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';

import Header from '../../components/Header';
// import CalenderLeaves from "./pages/leaves/leaves";
// import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import EmployeeDetails from './pages/employeeDetails';
// import PerformanceDetails from './pages/performanceDetails';
import DepartmentDesignationLayout from './pages/departmentDesignationLayout';
import CalenderLeaves from './pages/leaves/leaves';
import Holidays from './pages/leaves/components/holidays';
// import Payroll from "./pages/payroll";

export default function Settings() {
  let [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (e) => {
    setSearchParams({ tab: e });
  };

  return (
    <div className='w-full px-5 mb-8'>
      <div className='flex justify-between items-center mt-6'>
        <p className='text-xl text-A8A8A8'>Settings</p>
        <Header />
      </div>
      <div className='relative mt-12'>
        <Tabs
          active={~~searchParams.get('tab')}
          onTabChange={handleTabChange}
          defaultValue='three'
          tabPadding={21}
          classNames={{
            tabsListWrapper: 'border-0',
            tabActive: 'border-2 border-primary font-semibold px-0',
            tabControl: 'text-A8A8A8 leading-5 px-0 mr-8',
            tabLabel: 'text-18 text-[#333333]',
          }}
        >
          <Tabs.Tab label='Dept. & Designation'>
            <DepartmentDesignationLayout />
          </Tabs.Tab>
          <Tabs.Tab label='Employee details'>
            <EmployeeDetails />
          </Tabs.Tab>
          <Tabs.Tab label='Leave modules'>
            <CalenderLeaves />
          </Tabs.Tab>
          <Tabs.Tab label='Holiday types'>
            <Holidays />
          </Tabs.Tab>
        </Tabs>
      </div>
    </div>
  );
}
