import { useMutation } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import { useNavigate } from 'react-router-dom';
import { ErrorToast, SuccessToast } from 'services/toasterService';

export default function UseForgotPassword() {
  const navigate = useNavigate();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.post(
        apiEndPoints.FORGOT_PASSWORD,
        data
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        navigate('/auth/success', { replace: true });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
