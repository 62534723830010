import NoPageFound from 'pages/common/pageNotFound';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Productivity from './pages/productivity/productivity';
import Projects from './pages/projects/projects';

export default function AdminDashboard() {
  return (
    <Routes>
      <Route path='productivity' element={<Productivity />} />
      <Route path='projects' element={<Projects />} />

      {/* empty page when route not found */}
      <Route path='*' element={<NoPageFound />} />
    </Routes>
  );
}
