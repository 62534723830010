import React, { useState, useEffect, useContext } from 'react';
import Header from 'components/Header';

import { Tabs, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';

import useUpdateEmployeePersonalInfo from '../hooks/useUpdateEmployeePersonalInfo';
import useGetEmployeePersonalInfo from '../hooks/useGetEmployeePersonalInfo';

import EmployeeDetailsComponent from '../components/employeeDetailsComponent';
import EducationAndExperience from '../components/educationAndExperienceComponent';
import AvatarUpload from 'components/avatarUpload';
import FamilyAndEmergencyDetails from '../components/familyAndEmergencyDetails';

// import { ReactComponent as ImageEditIcon } from 'assets/icons/imageEdit.svg';
import { ReactComponent as DepartmentIcon } from 'assets/icons/departmentIcon.svg';
import { ReactComponent as EmployeeIdIcon } from 'assets/icons/employeeIdIcon.svg';
import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon.svg';
import { ReactComponent as GradeIcon } from 'assets/icons/gradeIcon.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/mailIcon.svg';
import { AuthContext } from 'context/authContext';

export default function UserProfile() {
  const [profilePicture, setProfilePicture] = useState();

  const { authState } = useContext(AuthContext);

  const getEmployeeData = useGetEmployeePersonalInfo();
  const updateEmployeeMutation = useUpdateEmployeePersonalInfo(null, false);

  useEffect(() => {
    if (profilePicture) {
      let formData = new FormData();
      formData.append('profile_picture', profilePicture);
      updateEmployeeMutation.mutate(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePicture]);

  return (
    <div className='mt-6 mx-6'>
      <div className='flex items-center justify-between'>
        <p className='text-20 font-semibold text-gray'>My profile</p>
        <Header />
      </div>
      <div className='min-h-44 shadow-lg mt-6 border border-8B8B8B border-opacity-10 rounded-xl p-4 bg-white'>
        <div className='flex'>
          <span className='mr-4'>
            <AvatarUpload
              imgUrl={
                authState?.profile_picture ||
                'https://dev-api.apta-hr.com/media_ats/images/noImage.svg'
              }
              setProfilePicture={setProfilePicture}
            />
          </span>
          <div>
            <p className='text-22 font-poppins tracking-widest'>
              {getEmployeeData?.data?.data?.[0]?.user__first_name?.toUpperCase()}
            </p>
            <p className='text-base -mt-1 text-gray3'>
              {getEmployeeData?.data?.data?.[0]?.designation__name}
            </p>
            <div className='flex items-center mt-2 text-gray3 space-x-12'>
              <Tooltip
                label='Employee id'
                classNames={{
                  body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                }}
                radius='md'
              >
                <div className='flex items-center'>
                  <EmployeeIdIcon className='mr-2' />
                  <p>{getEmployeeData?.data?.data?.[0]?.employee_id}</p>
                </div>
              </Tooltip>
              <div className='flex items-center'>
                <EmailIcon className='mr-2' />
                <p>{getEmployeeData?.data?.data?.[0]?.user__email}</p>
              </div>
              <Tooltip
                label='Date of joining'
                classNames={{
                  body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                }}
                radius='md'
              >
                <div className='flex items-center'>
                  <CalenderIcon className='mr-2 w-5' />
                  <p className='whitespace-nowrap'>
                    {dayjs(
                      getEmployeeData?.data?.data?.[0]?.date_of_joining
                    ).format('DD MMM YY')}
                  </p>
                </div>
              </Tooltip>
              <Tooltip
                label='Employment type & Grade'
                classNames={{
                  body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                }}
                radius='md'
              >
                <div className='flex items-center'>
                  <GradeIcon className='w-5 mr-2' />
                  <p className='whitespace-nowrap'>
                    {getEmployeeData?.data?.data?.[0]?.employment_type__name},{' '}
                    {getEmployeeData?.data?.data?.[0]?.grade__name}
                  </p>
                </div>
              </Tooltip>
              <Tooltip
                label='Department'
                classNames={{
                  body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                }}
                radius='md'
              >
                <div className='flex items-center'>
                  <DepartmentIcon className='w-5 mr-1 ml-1' />
                  <p className='whitespace-nowrap'>
                    {getEmployeeData?.data?.data?.[0]?.department__name}
                  </p>
                </div>
              </Tooltip>
              <div className='flex items-center'>
                <LocationIcon className='mr-2' />
                <p>{getEmployeeData?.data?.data?.[0]?.country}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-7 pl-1'>
        <Tabs
          tabPadding={21}
          // onTabChange={setActiveTab}
          classNames={{
            tabsListWrapper: 'border-0',
            tabActive: 'border-2 border-primary font-semibold text-18',
            tabControl: 'text-A8A8A8 leading-5 px-0 mr-6',
            tabLabel: 'text-18 text-gray',
          }}
        >
          <Tabs.Tab label='Personal information'>
            <EmployeeDetailsComponent
              employeeDetails={getEmployeeData?.data?.data?.[0]}
            />
          </Tabs.Tab>
          <Tabs.Tab label='Education and experience'>
            <EducationAndExperience />
          </Tabs.Tab>
          <Tabs.Tab label='Family and emergency '>
            <FamilyAndEmergencyDetails />
          </Tabs.Tab>
        </Tabs>
      </div>
    </div>
  );
}
