import { Select, Modal } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
// import { forwardRef } from 'react';

import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as CalanderIcon } from 'assets/icons/calanderIcon.svg';
// import { ReactComponent as MobileIcon } from 'assets/icons/mobileIcon.svg';
// import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon.svg';
// import { ReactComponent as MailIcon } from 'assets/icons/mailIcon.svg';
// import { ReactComponent as GradeIcon } from 'assets/icons/gradeIcon.svg';

import { Controller, useForm } from 'react-hook-form';
import SecondaryButton from 'components/secondaryButton';
import PrimaryButton from 'components/primaryButton';
import CreateAndEditEmployeeModal from './createAndEditEmployeeModal';
import { useEffect, useState } from 'react';

//hooks get requests
// import Usegetemployessfromteams from '../hooks/usegetemployess';
import Usegetreviewsfromsettings from '../hooks/usegetreviewtypes';
import useGetlistofEmployess from '../hooks/useGetlistofemployess';
import dayjs from 'dayjs';

const SelectEmployeeModal = ({
  selectEmployeeModal,
  setSelectEmployeeModal,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({ mode: 'onChange' });

  const [value, setValuedate] = useState([]);

  useEffect(() => {
    const objectOfReviewType = reviewTypes?.data?.data?.find(
      // eslint-disable-next-line
      (each) => each?.id == watch('review_type')
    );
    const newDate =
      objectOfReviewType?.name === 'Weekly'
        ? dayjs(value[0]).add(7, 'days')
        : objectOfReviewType?.name === 'Quarterly'
        ? dayjs(value[0]).add(3, 'months')
        : objectOfReviewType?.name === 'Annually'
        ? dayjs(value[0]).add(1, 'year')
        : null;

    setValuedate([value[0], newDate?.$d]);
    setValue('segment', value);
    // eslint-disable-next-line
  }, [value[0]]);

  useEffect(() => {
    setValue('segment', []);
    setValuedate([]);
    // eslint-disable-next-line
  }, [watch('review_type')]);

  const [reportModal, setReportModal] = useState(false);

  const [data, setData] = useState(null);

  const totalEmployess = useGetlistofEmployess();
  const reviewTypes = Usegetreviewsfromsettings();

  // const defaultValues = {
  //   Select_employee: '',
  //   review_type: '',
  //   segment: '',
  // };

  const submitForm = (data) => {
    setData({ ...data, segment: value });

    // setSelectEmployeeModal(false);
    setReportModal(true);
    // reset({ defaultValues });
  };

  return (
    <>
      <Modal
        opened={selectEmployeeModal}
        onClose={() => {
          setSelectEmployeeModal(false);
          reset();
          setValuedate([]);
        }}
        title='Create Performance Report'
        size='54%'
        centered
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        transitionDuration={100}
        overflow='inside'
        className='px-10'
        classNames={{
          title: 'text-lg font-semibold text-center mx-auto',
          body: 'scroller h-auto',
        }}
      >
        <form
          className='flex flex-col py-4'
          onSubmit={handleSubmit(submitForm)}
        >
          <Controller
            control={control}
            name='Select_employee'
            rules={{
              required: 'Required',
            }}
            render={({ field }) => (
              <Select
                {...field}
                label='Select Employee'
                // itemComponent={SelectItem}
                data={
                  totalEmployess?.data?.data?.map((eachEmployee) => {
                    return {
                      value: `${eachEmployee.id}`,
                      label: eachEmployee.user__first_name,
                    };
                  }) || []
                }
                searchable
                // maxDropdownHeight={200}
                nothingFound='Nobody here'
                // filter={(value, item) =>
                //   item.label
                //     .toLowerCase()
                //     .includes(value.toLowerCase().trim()) ||
                //   item.description
                //     .toLowerCase()
                //     .includes(value.toLowerCase().trim())
                // }
                size='lg'
                classNames={{
                  input:
                    ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5',
                  label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                }}
                rightSection={<DownArrow />}
                styles={{ rightSection: { pointerEvents: 'none' } }}
              />
            )}
          />
          {errors.Select_employee && (
            <p className='text-sm  text-[#fc0101]'>
              {errors.Select_employee.message}
            </p>
          )}

          <div className='grid grid-cols-2 gap-4 mt-8'>
            <div className=''>
              <Controller
                control={control}
                name='review_type'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label='Select Review Type'
                    size='lg'
                    data={
                      reviewTypes?.data?.data?.map((eachReview) => {
                        return {
                          value: `${eachReview.id}`,
                          label: eachReview.name,
                        };
                      }) || []
                    }
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                    }}
                    rightSection={<DownArrow />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                  />
                )}
              />
              {errors.review_type && (
                <p className='text-sm  text-[#fc0101]'>
                  {errors.review_type.message}
                </p>
              )}
            </div>
            <div>
              <Controller
                control={control}
                name='segment'
                // rules={{
                //   required: 'Required',
                // }}
                value={value}
                render={({ field }) => (
                  <DateRangePicker
                    {...field}
                    label='Review Period'
                    value={value}
                    onChange={(e) => {
                      field.onChange();
                      setValuedate(e);
                    }}
                    // withinPortal={false}
                    dropdownposition={'flip'}
                    dropdownType={'popover'}
                    classNames={{
                      input:
                        'h-[50px] text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      yearPickerControlActive: 'text-white bg-primary',
                      monthPickerControlActive: 'text-white bg-primary',
                      dropdown: '-mt-52',
                    }}
                    rightSection={<CalanderIcon />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                  />
                )}
              />
              {errors.segment && (
                <p className='text-sm  text-[#fc0101]'>
                  {errors.segment.message}
                </p>
              )}
            </div>
          </div>
          {/* 
          <hr className='mt-8 mb-4 border-1 border-[#D9D9D9]' />

          <div className='bg-[#FFF8F6] rounded-md p-4 mb-5'>
            <div className='flex  w-full mb-5'>
              <Avatar radius='xl' size='lg' color='#FB7C51' />
              <div className='w-full pl-5'>
                <div className='flex justify-between  w-full  items-center'>
                  <h1 className='text-[#000000] font-bold text-normal'>
                    Alibaba{' '}
                    <span className='text-primary font-normal text-sm'>
                      (He / Him)
                    </span>
                  </h1>
                  <p className='flex items-center text-[#8B8B8B] font-normal text-sm '>
                    Reports to
                    <Avatar
                      radius='xl'
                      size='sm'
                      color='#FB7C51'
                      className='ml-2'
                    />
                  </p>
                </div>
                <div className='flex items-center gap-3 mt-2 '>
                  <p className='flex items-center text-[#828282] font-normal text-sm'>
                    <MobileIcon className='mr-2' />
                    8106519003
                  </p>
                  <Tooltip
                    label={` alibaba@gmail.com`}
                    classNames={{
                      body: 'bg-primary',
                    }}
                    radius='md'
                  >
                    <p className='flex items-center text-[#828282] font-normal text-sm'>
                      <MailIcon className='mr-2' />
                      alibaba@gmail.com
                    </p>
                  </Tooltip>
                  <p className='flex items-center text-[#828282] font-normal text-sm'>
                    <LocationIcon className='mr-2' />
                    Hyd, India, APAC
                  </p>
                  <p className='flex items-center text-[#828282] font-normal text-sm'>
                    <GradeIcon className='mr-2' />A
                  </p>
                </div>
              </div>
            </div>

            <table style={{ width: '100%' }}>
              <thead>
                <tr
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    color: '#212121',
                    textAlign: 'center',
                  }}
                >
                  <th>DOJ</th>
                  <th>DOB</th>
                  <th>Experience</th>
                  <th>Employment type</th>
                  <th>Department</th>
                  <th>Working shift</th>
                  <th>Gross salary</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#828282',
                    textAlign: 'center',
                  }}
                >
                  <td>9 Mar 2022</td>
                  <td>5 Aug 2000</td>
                  <td>1 year</td>
                  <td>Fulltime</td>
                  <td>IT</td>
                  <td>9 AM - 10 PM (Wed, Thu)</td>
                  <td>$20, 000</td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <div className='mx-auto mt-5'>
            <button
              className='mr-2 mt-7'
              type='button'
              onClick={() => {
                setSelectEmployeeModal(false);
              }}
            >
              <SecondaryButton>Discard</SecondaryButton>
            </button>
            {value.length === 2 ? (
              <button className='mt-7'>
                <PrimaryButton>Next</PrimaryButton>
              </button>
            ) : (
              <button
                className='mt-7 opacity-50 cursor-not-allowed'
                type='button'
              >
                <PrimaryButton>Next</PrimaryButton>
              </button>
            )}
          </div>
        </form>
      </Modal>

      {totalEmployess?.data?.data.length === 0 ? null : (
        <CreateAndEditEmployeeModal
          reportModal={reportModal}
          setReportModal={setReportModal}
          sendDataToNext={data}
          setSelectEmployeeModal={setSelectEmployeeModal}
        />
      )}
    </>
  );
};

export default SelectEmployeeModal;
