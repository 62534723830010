import { Controller, useForm } from 'react-hook-form';

import { MultiSelect, Switch, TextInput } from '@mantine/core';

import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as NoSalaryComponent } from 'assets/icons/nosalarycomponent.svg';

//get data
import UseGetEmployeementTypes from '../hooks/useGetEmploymentTypes';
import Usegetgradeorrange from '../hooks/usegetgradeorsalary';
import Usegetsalarycomponentdata from '../hooks/usegetsalarycomponent';
// import Usegetgradedata from '../hooks/usegradedata';
// import Usegetsalaryrangedata from '../hooks/usegetsalaryrabgedata';

//update data

import Useupdatecompanypayrolldata from '../hooks/useupdatecompanypayroll';
import Useupdatesalarycomponents from '../hooks/useupdatesalarycomponentdata';
import Useupdatesinglesalarycomponents from '../hooks/useupdatesinglesalarycomponent';

import { AuthContext } from 'context/authContext';

import getSymbolFromCurrency from 'currency-symbol-map';
import { useContext } from 'react';

const Salarycomponents = () => {
  const { authState } = useContext(AuthContext);
  const currencyIcon = getSymbolFromCurrency(authState?.currency);
  const {
    // handleSubmit,
    formState: { errors },
    control,
    // watch,
    // setValue,
    // reset,
  } = useForm({ mode: 'onChange' });

  //get data
  const employeementTypesData = UseGetEmployeementTypes();
  // const gradeData = Usegetgradedata();
  // const salaryData = Usegetsalaryrangedata();
  const companyDetails = Usegetgradeorrange();
  const getsalarycomponentdata = Usegetsalarycomponentdata();

  //update data
  const updateComapanypayrollData = Useupdatecompanypayrolldata();
  const useupdatesalarycomponentsmutation = Useupdatesalarycomponents();
  const useupdatesinglesalarycomponentsmutation =
    Useupdatesinglesalarycomponents();

  const allowancesData = getsalarycomponentdata?.data?.data.filter(
    (eachAllowance) => eachAllowance.is_allowance === true
  );

  const deductionsData = getsalarycomponentdata?.data?.data.filter(
    (eachAllowance) => eachAllowance.is_allowance === false
  );

  // console.log(debouncedCompanyData);

  const getFieldArrayFormErrorMessageGrade = (value, index) => {
    return (
      errors?.value?.length && (
        <p className='text-xs font-semibold text-red-500 '>
          {errors?.value[index]?.message}
        </p>
      )
    );
  };
  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-xs font-semibold text-red-500 text-right'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  return (
    <>
      {employeementTypesData.isLoading ? null : (
        <div className='mt-4 bg-[#fff] p-4 px-6 min-h-[72vh] shadow-2xl rounded-xl'>
          <div className='grid grid-cols-2 gap-2'>
            {/* Basic pay*/}
            <p className='text-[#545454] font-medium text-base'>Basic Pay</p>
            <div>
              <div className='grid justify-end '>
                <Controller
                  control={control}
                  name='name'
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Percentages should not be negative',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      rightSection={<div className='text-gray3'>% of CTC</div>}
                      defaultValue={
                        companyDetails?.data?.data[0].basic_percentage
                      }
                      onKeyUp={(event) => {
                        field.onChange(event);
                        if (
                          event.target.value.trim() !== '' &&
                          event.key === 'Enter' &&
                          companyDetails?.data?.data[0].basic_percentage !==
                            event.target.value &&
                          !errors.name
                        ) {
                          updateComapanypayrollData.mutate({
                            id: companyDetails?.data?.data[0].id,
                            basic_percentage: event.target.value.trim(),
                          });
                        }
                      }}
                      type='number'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent  text-normal rounded w-32 pr-20',
                        rightSection: 'whitespace-nowrap w-fit pr-2',
                      }}
                    />
                  )}
                />
              </div>
              {getErrorMessage('name')}
            </div>
            {/*HRA*/}
            <p className='text-[#545454] font-medium text-base'>
              House Rent Allowance (HRA)
            </p>
            <div>
              <div className='grid justify-end '>
                <Controller
                  control={control}
                  name='hra_percentage'
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Percentages should not be negative',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      // {...field}
                      type='number'
                      rightSection={
                        <div className='text-gray3'>% of basic</div>
                      }
                      defaultValue={
                        companyDetails?.data?.data[0].hra_percentage
                      }
                      // onChange={() => {
                      //   console.log(watch('hra_percentage'));
                      // }}
                      onKeyUp={(event) => {
                        field.onChange(event);
                        // setValue(event.target.value);
                        // debounce(async () => {
                        //   if (event.target.value.trim() !== '') {
                        //     await updateComapanypayrollData.mutate({
                        //       id: companyDetails?.data?.data[0].id,
                        //       hra_percentage: event.target.value.trim(),
                        //     });
                        //   }
                        //   console.log('djhdsfdfh');
                        // }, 500);
                        if (
                          event.target.value.trim() !== '' &&
                          event.key === 'Enter' &&
                          companyDetails?.data?.data[0].hra_percentage !==
                            event.target.value &&
                          !errors.hra_percentage
                        ) {
                          updateComapanypayrollData.mutate({
                            id: companyDetails?.data?.data[0].id,
                            hra_percentage: event.target.value.trim(),
                          });
                        }
                      }}
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent  text-normal rounded w-32 pr-20',
                        rightSection: 'whitespace-nowrap w-fit pr-2',
                      }}
                    />
                  )}
                />
              </div>
              {getErrorMessage('hra_percentage')}
            </div>
            {/*Professional tax*/}
            <p className='text-[#545454] font-medium text-base'>
              Professional tax
            </p>
            <div className='grid justify-end '>
              <div className='flex items-center gap-4'>
                <p className='-mb-3 text-[#545454] font-medium text-sm'>
                  Restrict to
                </p>
                <Controller
                  control={control}
                  name='professional_tax_restricted'
                  // rules={{
                  //   required: 'Required',
                  // }}
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      placeholder='Select'
                      rightSection={<DownArrow />}
                      // onDropdownClose={() => {
                      //   updateComapanypayrollData.mutate({
                      //     id: companyDetails?.data?.data[0].id,
                      //     professional_tax_restricted: watch(
                      //       'professional_tax_restricted'
                      //     ),
                      //   });
                      //   // console.log(watch('professional_tax_restricted'));
                      // }}
                      // onCreate={() => {
                      //   updateComapanypayrollData.mutate({
                      //     id: companyDetails?.data?.data[0].id,
                      //     professional_tax_restricted: watch(
                      //       'professional_tax_restricted'
                      //     ),
                      //   });
                      //   // console.log(watch('professional_tax_restricted'));
                      // }}
                      onChange={(value) => {
                        updateComapanypayrollData.mutate({
                          id: companyDetails?.data?.data[0].id,
                          professional_tax_restricted:
                            value[0] === '' ? value.slice(1) : value,
                          is_professional_tax_restricted:
                            value[0] === ''
                              ? value.slice(1).length === 0
                                ? false
                                : true
                              : value.length === 0
                              ? false
                              : true,
                        });
                      }}
                      defaultValue={companyDetails?.data?.data[0].professional_tax_restricted
                        .join()
                        .split(',')}
                      data={employeementTypesData?.data?.data
                        ?.filter((element) => element?.is_active)
                        ?.map((dept) => {
                          return { value: `${dept.id}`, label: dept.name };
                        })}
                      classNames={{
                        wrapper: 'border-primary border-b-[1px]',
                        input: 'border-none w-64',
                      }}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                    />
                  )}
                />
                <div>
                  <div className='grid justify-end '>
                    <Controller
                      control={control}
                      name='professional_tax'
                      rules={{
                        required: 'Required',
                        pattern: {
                          value: /^[0-9]*$/,
                          message: 'Tax cannot be negative',
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          type='number'
                          defaultValue={
                            companyDetails?.data?.data[0].professional_tax
                          }
                          icon={currencyIcon}
                          onKeyUp={(event) => {
                            if (
                              event.key === 'Enter' &&
                              event.target.value.trim() !== '' &&
                              !errors.professional_tax
                            ) {
                              updateComapanypayrollData.mutate({
                                id: companyDetails?.data?.data[0].id,
                                professional_tax: event.target.value.trim(),
                              });
                            }
                          }}
                          classNames={{
                            input:
                              ' text-[15px] bg-transparent  text-normal rounded w-32',
                          }}
                        />
                      )}
                    />
                  </div>
                  {getErrorMessage('professional_tax')}
                </div>
              </div>
            </div>
          </div>
          <div className='mt-6'>
            <div className='flex justify-between items-center px-5 bg-[#FFE8E1] p-4 rounded'>
              <h1 className='text-primary font-semibold text-xl'>
                Other allowance
              </h1>
              {/* <p className='flex items-center gap-2 text-[#515151] text-sm'>
                Monthly
                <Controller
                  control={control}
                  name='is_allowance_monthly'
                  defaultValue={
                    companyDetails?.data?.data[0].is_allowance_monthly
                  }
                  render={({ field }) => (
                    <Switch
                      {...field}
                      defaultChecked={
                        companyDetails?.data?.data[0].is_allowance_monthly
                      }
                      onChange={() => {
                        updateComapanypayrollData.mutate({
                          id: companyDetails?.data?.data[0].id,
                          is_allowance_monthly:
                            !companyDetails?.data?.data[0].is_allowance_monthly,
                        });
                      }}
                    />
                  )}
                />
                Annually
              </p> */}
            </div>

            {/*Other allowances */}
            {allowancesData && allowancesData.length === 0 ? (
              <div className='flex flex-col justify-center  items-center my-8'>
                <NoSalaryComponent />
                <p className='text-[#AEACAC] mt-2'>No data to show</p>
              </div>
            ) : (
              <div className='px-5'>
                {allowancesData &&
                  allowancesData.map((eachallowance) => (
                    <div
                      className='flex justify-between  mt-5'
                      key={eachallowance.id}
                    >
                      <h1>{eachallowance.name}</h1>
                      <div className='grid grid-cols-7  gap-5'>
                        <div className='flex  gap-4 col-span-4 ml-auto '>
                          <p className='-mb-3 text-[#545454] font-medium text-sm pt-4'>
                            Applicable for
                          </p>
                          <Controller
                            control={control}
                            name={`applicable_for.${eachallowance.id}`}
                            // rules={{
                            //   required: 'Required',
                            // }}
                            render={({ field }) => (
                              <MultiSelect
                                {...field}
                                placeholder='Select'
                                rightSection={<DownArrow />}
                                defaultValue={eachallowance.applicable_for
                                  .join()
                                  .split(',')}
                                onChange={(value) => {
                                  useupdatesalarycomponentsmutation.mutate({
                                    id: eachallowance.id,
                                    applicable_for:
                                      value[0] === '' ? value.slice(1) : value,
                                  });
                                }}
                                data={employeementTypesData?.data?.data
                                  ?.filter((element) => element?.is_active)
                                  ?.map((dept) => {
                                    return {
                                      value: `${dept.id}`,
                                      label: dept.name,
                                    };
                                  })}
                                classNames={{
                                  wrapper: 'border-primary border-b',
                                  input: 'border-none w-60',
                                }}
                                styles={{
                                  rightSection: { pointerEvents: 'none' },
                                }}
                              />
                            )}
                          />
                        </div>

                        <p className='flex pt-4 gap-2 text-[#515151] text-sm justify-center grid-span-2'>
                          All
                          {companyDetails?.data?.data[0].is_grade ? (
                            <div>
                              <Controller
                                control={control}
                                name={`is_grade_same.{${eachallowance.id}}`}
                                // rules={{
                                //   required: 'Required',
                                // }}
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    type='number'
                                    defaultChecked={
                                      !eachallowance.is_grade_same
                                    }
                                    onChange={() => {
                                      useupdatesalarycomponentsmutation.mutate({
                                        id: eachallowance.id,
                                        is_grade_same:
                                          !eachallowance.is_grade_same,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <div>
                              <Controller
                                control={control}
                                name={`is_range_same.{${eachallowance.id}}`}
                                // rules={{
                                //   required: 'Required',
                                // }}
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    defaultChecked={
                                      !eachallowance.is_range_same
                                    }
                                    onChange={() => {
                                      useupdatesalarycomponentsmutation.mutate({
                                        id: eachallowance.id,
                                        is_range_same:
                                          !eachallowance.is_range_same,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </div>
                          )}
                          {companyDetails?.data?.data[0].is_grade
                            ? 'By grade'
                            : 'By range'}
                        </p>
                        {(
                          companyDetails?.data?.data[0].is_grade
                            ? eachallowance.is_grade_same
                            : eachallowance.is_range_same
                        ) ? (
                          <>
                            {companyDetails?.data?.data[0].is_grade ? (
                              <div className='col-span-2 ml-auto pt-2'>
                                <Controller
                                  control={control}
                                  name={`grade_value.${eachallowance.id}`}
                                  // rules={{
                                  //   required: 'Required',
                                  // }}

                                  render={({ field }) => (
                                    <TextInput
                                      {...field}
                                      type='number'
                                      defaultValue={eachallowance.grade_value}
                                      // icon={currencyIcon}
                                      rightSection={
                                        <div className='text-gray3'>
                                          % of basic
                                        </div>
                                      }
                                      onKeyUp={(event) => {
                                        if (
                                          event.key === 'Enter' &&
                                          event.target.value.trim() !== ''
                                        ) {
                                          useupdatesalarycomponentsmutation.mutate(
                                            {
                                              id: eachallowance.id,
                                              grade_value: parseInt(
                                                event.target.value.trim()
                                              ),
                                            }
                                          );
                                        }
                                      }}
                                      classNames={{
                                        input:
                                          ' text-[15px] bg-transparent  text-normal rounded w-[230px]',
                                        rightSection:
                                          'whitespace-nowrap w-fit pr-2',
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            ) : (
                              <div className='col-span-2 ml-auto pt-2'>
                                <Controller
                                  control={control}
                                  name={`range_value.${eachallowance.id}`}
                                  // rules={{
                                  //   required: 'Required',
                                  // }}

                                  render={({ field }) => (
                                    <TextInput
                                      {...field}
                                      defaultValue={eachallowance.range_value}
                                      // icon={currencyIcon}
                                      type='number'
                                      onKeyUp={(event) => {
                                        if (
                                          event.key === 'Enter' &&
                                          event.target.value.trim() !== ''
                                        ) {
                                          useupdatesalarycomponentsmutation.mutate(
                                            {
                                              id: eachallowance.id,
                                              range_value: parseInt(
                                                event.target.value.trim()
                                              ),
                                            }
                                          );
                                        }
                                      }}
                                      classNames={{
                                        input:
                                          ' text-[15px] bg-transparent  text-normal rounded w-[230px]',
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div className=' flex items-center flex-col gap-2 ml-auto col-span-2'>
                            {companyDetails?.data?.data[0].is_grade ? (
                              <>
                                <div className=' gap-2  grid grid-cols-2 '>
                                  {eachallowance.diff_grades.map(
                                    (GradeNames) => {
                                      return (
                                        <>
                                          <Controller
                                            key={GradeNames.id}
                                            control={control}
                                            name={`id.${GradeNames.id}`}
                                            // rules={{
                                            //   required: 'Required',
                                            // }}

                                            render={({ field }) => (
                                              <TextInput
                                                {...field}
                                                defaultValue={
                                                  GradeNames.grade__name
                                                }
                                                readOnly
                                                classNames={{
                                                  input:
                                                    ' text-[15px] bg-transparent  text-normal rounded w-28',
                                                }}
                                              />
                                            )}
                                          />
                                          <div className='flex flex-col gap-2'>
                                            {' '}
                                            <Controller
                                              control={control}
                                              name={`value.${GradeNames.id}`}
                                              rules={{
                                                required: 'Required',
                                                pattern: {
                                                  value: /^[0-9]*$/,
                                                  message:
                                                    'percentages should not be negative',
                                                },
                                              }}
                                              render={({ field }) => (
                                                <TextInput
                                                  {...field}
                                                  type='number'
                                                  defaultValue={
                                                    GradeNames.value
                                                  }
                                                  rightSection={
                                                    <div className='text-gray3'>
                                                      % of basic
                                                    </div>
                                                  }
                                                  onKeyUp={(event) => {
                                                    if (
                                                      event.key === 'Enter' &&
                                                      event.target.value.trim() !==
                                                        '' &&
                                                      !errors?.value
                                                    ) {
                                                      useupdatesinglesalarycomponentsmutation.mutate(
                                                        {
                                                          // id: eachallowance.id,
                                                          id: GradeNames.id,
                                                          // grade_id:
                                                          //   GradeNames.grade_id,
                                                          value: parseInt(
                                                            event.target.value.trim()
                                                          ),
                                                        }
                                                      );
                                                    }
                                                  }}
                                                  classNames={{
                                                    input:
                                                      ' text-[15px] bg-transparent  text-normal rounded w-32 pr-20',
                                                    rightSection:
                                                      'whitespace-nowrap w-fit pr-5',
                                                  }}
                                                />
                                              )}
                                            />
                                            {getFieldArrayFormErrorMessageGrade(
                                              'value',
                                              GradeNames.id
                                            )}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className=' gap-2  grid grid-cols-2 '>
                                  {eachallowance.diff_ranges.map(
                                    (GradeNames) => {
                                      return (
                                        <>
                                          <Controller
                                            key={GradeNames.id}
                                            control={control}
                                            name={`id.${GradeNames.id}`}
                                            // rules={{
                                            //   required: 'Required',
                                            // }}

                                            render={({ field }) => (
                                              <TextInput
                                                {...field}
                                                defaultValue={`${GradeNames.salary_range__from_salary} - ${GradeNames.salary_range__to_salary}`}
                                                readOnly
                                                classNames={{
                                                  input:
                                                    ' text-[15px] bg-transparent  text-normal rounded w-28',
                                                }}
                                              />
                                            )}
                                          />
                                          <div className='flex flex-col gap-2'>
                                            {' '}
                                            <Controller
                                              control={control}
                                              name={`value.${GradeNames.id}`}
                                              // rules={{
                                              //   required: 'Required',
                                              // }}

                                              render={({ field }) => (
                                                <TextInput
                                                  {...field}
                                                  type='number'
                                                  defaultValue={
                                                    GradeNames.value
                                                  }
                                                  icon={currencyIcon}
                                                  onKeyUp={(event) => {
                                                    if (
                                                      event.key === 'Enter' &&
                                                      event.target.value.trim() !==
                                                        ''
                                                    ) {
                                                      useupdatesinglesalarycomponentsmutation.mutate(
                                                        {
                                                          // id: eachallowance.id,
                                                          id: GradeNames.id,
                                                          // grade_id:
                                                          //   GradeNames.grade_id,
                                                          value: parseInt(
                                                            event.target.value.trim()
                                                          ),
                                                        }
                                                      );
                                                    }
                                                  }}
                                                  classNames={{
                                                    input:
                                                      ' text-[15px] bg-transparent  text-normal rounded w-28',
                                                  }}
                                                />
                                              )}
                                            />
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          {/*  Other detection*/}

          <div className='mt-6'>
            <div className='flex justify-between items-center px-5 bg-[#FFE8E1] p-4 rounded'>
              <h1 className='text-primary font-semibold text-xl'>
                Other deductions
              </h1>
              {/* <p className='flex items-center gap-2 text-[#515151] text-sm'>
                Monthly
                <Controller
                  control={control}
                  name='is_allowance_monthly'
                  defaultValue={
                    companyDetails?.data?.data[0].is_deduction_monthly
                  }
                  render={({ field }) => (
                    <Switch
                      {...field}
                      defaultChecked={
                        companyDetails?.data?.data[0].is_deduction_monthly
                      }
                      onChange={() => {
                        updateComapanypayrollData.mutate({
                          id: companyDetails?.data?.data[0].id,
                          is_deduction_monthly:
                            !companyDetails?.data?.data[0].is_deduction_monthly,
                        });
                      }}
                    />
                  )}
                />
                Annually
              </p> */}
            </div>
            {deductionsData && deductionsData.length === 0 ? (
              <div className='flex flex-col justify-center  items-center my-8'>
                <NoSalaryComponent />
                <p className='text-[#AEACAC] mt-2'>No data to show</p>
              </div>
            ) : (
              <div className='px-5'>
                {deductionsData &&
                  deductionsData.map((eachdeduction) => (
                    <div
                      className='flex justify-between  mt-5'
                      key={eachdeduction.id}
                    >
                      <h1>{eachdeduction.name}</h1>
                      <div className='grid grid-cols-7  gap-5'>
                        <div className='flex  gap-4 col-span-4 ml-auto '>
                          <p className='-mb-3 text-[#545454] font-medium text-sm pt-4'>
                            Applicable for
                          </p>
                          <Controller
                            control={control}
                            name={`applicable_for.${eachdeduction.id}`}
                            // rules={{
                            //   required: 'Required',
                            // }}
                            render={({ field }) => (
                              <MultiSelect
                                {...field}
                                placeholder='Select'
                                rightSection={<DownArrow />}
                                defaultValue={eachdeduction.applicable_for
                                  .join()
                                  .split(',')}
                                onChange={(value) => {
                                  useupdatesalarycomponentsmutation.mutate({
                                    id: eachdeduction.id,
                                    applicable_for:
                                      value[0] === '' ? value.slice(1) : value,
                                  });
                                }}
                                data={employeementTypesData?.data?.data?.map(
                                  (dept) => {
                                    return {
                                      value: `${dept.id}`,
                                      label: dept.name,
                                    };
                                  }
                                )}
                                classNames={{
                                  wrapper: 'border-primary border-b',
                                  input: 'border-none w-60',
                                }}
                                styles={{
                                  rightSection: { pointerEvents: 'none' },
                                }}
                              />
                            )}
                          />
                        </div>

                        <p className='flex pt-4 gap-2 text-[#515151] text-sm justify-center grid-span-2'>
                          All
                          {companyDetails?.data?.data[0].is_grade ? (
                            <div>
                              <Controller
                                control={control}
                                name={`is_grade_same.{${eachdeduction.id}}`}
                                // rules={{
                                //   required: 'Required',
                                // }}
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    type='number'
                                    defaultChecked={
                                      !eachdeduction.is_grade_same
                                    }
                                    onChange={() => {
                                      useupdatesalarycomponentsmutation.mutate({
                                        id: eachdeduction.id,
                                        is_grade_same:
                                          !eachdeduction.is_grade_same,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <div>
                              <Controller
                                control={control}
                                name={`is_range_same.{${eachdeduction.id}}`}
                                // rules={{
                                //   required: 'Required',
                                // }}
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    type='number'
                                    defaultChecked={
                                      !eachdeduction.is_range_same
                                    }
                                    onChange={() => {
                                      useupdatesalarycomponentsmutation.mutate({
                                        id: eachdeduction.id,
                                        is_range_same:
                                          !eachdeduction.is_range_same,
                                      });
                                    }}
                                  />
                                )}
                              />
                            </div>
                          )}
                          {companyDetails?.data?.data[0].is_grade
                            ? 'By grade'
                            : 'By range'}
                        </p>
                        {(
                          companyDetails?.data?.data[0].is_grade
                            ? eachdeduction.is_grade_same
                            : eachdeduction.is_range_same
                        ) ? (
                          <>
                            {companyDetails?.data?.data[0].is_grade ? (
                              <div className='col-span-2 ml-auto pt-2'>
                                <Controller
                                  control={control}
                                  name={`grade_value.${eachdeduction.id}`}
                                  // rules={{
                                  //   required: 'Required',
                                  // }}

                                  render={({ field }) => (
                                    <TextInput
                                      {...field}
                                      defaultValue={eachdeduction.grade_value}
                                      rightSection={
                                        <div className='text-gray3'>
                                          % of basic
                                        </div>
                                      }
                                      onKeyUp={(event) => {
                                        if (
                                          event.key === 'Enter' &&
                                          event.target.value.trim() !== ''
                                        ) {
                                          useupdatesalarycomponentsmutation.mutate(
                                            {
                                              id: eachdeduction.id,
                                              grade_value: parseInt(
                                                event.target.value.trim()
                                              ),
                                            }
                                          );
                                        }
                                      }}
                                      classNames={{
                                        input:
                                          ' text-[15px] bg-transparent  text-normal rounded w-[230px]',
                                        rightSection:
                                          'whitespace-nowrap pr-2 w-fit',
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            ) : (
                              <div className='col-span-2 ml-auto pt-2'>
                                <Controller
                                  control={control}
                                  name={`range_value.${eachdeduction.id}`}
                                  // rules={{
                                  //   required: 'Required',
                                  // }}

                                  render={({ field }) => (
                                    <TextInput
                                      {...field}
                                      defaultValue={eachdeduction.range_value}
                                      icon={currencyIcon}
                                      onKeyUp={(event) => {
                                        if (
                                          event.key === 'Enter' &&
                                          event.target.value.trim() !== ''
                                        ) {
                                          useupdatesalarycomponentsmutation.mutate(
                                            {
                                              id: eachdeduction.id,
                                              range_value: parseInt(
                                                event.target.value.trim()
                                              ),
                                            }
                                          );
                                        }
                                      }}
                                      classNames={{
                                        input:
                                          ' text-[15px] bg-transparent  text-normal rounded w-[230px]',
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <div className=' flex items-center flex-col gap-2 ml-auto col-span-2'>
                            {companyDetails?.data?.data[0].is_grade ? (
                              <>
                                <div className=' gap-2  grid grid-cols-2 '>
                                  {eachdeduction.diff_grades.map(
                                    (GradeNames) => {
                                      return (
                                        <>
                                          <Controller
                                            key={GradeNames.id}
                                            control={control}
                                            name={`id.${GradeNames.id}`}
                                            // rules={{
                                            //   required: 'Required',
                                            // }}

                                            render={({ field }) => (
                                              <TextInput
                                                {...field}
                                                defaultValue={
                                                  GradeNames.grade__name
                                                }
                                                readOnly
                                                classNames={{
                                                  input:
                                                    ' text-[15px] bg-transparent  text-normal rounded w-28',
                                                }}
                                              />
                                            )}
                                          />
                                          <div className='flex flex-col gap-2'>
                                            {' '}
                                            <Controller
                                              control={control}
                                              name={`value.${GradeNames.id}`}
                                              rules={{
                                                required: 'Required',
                                                pattern: {
                                                  value: /^[0-9]*$/,
                                                  message:
                                                    'percentages should not be negative',
                                                },
                                              }}
                                              render={({ field }) => (
                                                <TextInput
                                                  {...field}
                                                  type='number'
                                                  defaultValue={
                                                    GradeNames.value
                                                  }
                                                  // icon={currencyIcon}
                                                  rightSection={
                                                    <div className='text-gray3'>
                                                      % of basic
                                                    </div>
                                                  }
                                                  onKeyUp={(event) => {
                                                    if (
                                                      event.key === 'Enter' &&
                                                      event.target.value.trim() !==
                                                        '' &&
                                                      !errors?.value
                                                    ) {
                                                      useupdatesinglesalarycomponentsmutation.mutate(
                                                        {
                                                          // id: eachdeduction.id,
                                                          id: GradeNames.id,
                                                          // grade_id:
                                                          //   GradeNames.grade_id,
                                                          value: parseInt(
                                                            event.target.value.trim()
                                                          ),
                                                        }
                                                      );
                                                    }
                                                  }}
                                                  classNames={{
                                                    input:
                                                      ' text-[15px] bg-transparent  text-normal rounded w-30',
                                                    rightSection:
                                                      'whitespace-nowrap w-fit pr-10',
                                                  }}
                                                />
                                              )}
                                            />
                                            {getFieldArrayFormErrorMessageGrade(
                                              'value',
                                              GradeNames.id
                                            )}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className=' gap-2  grid grid-cols-2 '>
                                  {eachdeduction.diff_ranges.map(
                                    (GradeNames) => {
                                      return (
                                        <>
                                          <Controller
                                            key={GradeNames.id}
                                            control={control}
                                            name={`id.${GradeNames.id}`}
                                            // rules={{
                                            //   required: 'Required',
                                            // }}

                                            render={({ field }) => (
                                              <TextInput
                                                {...field}
                                                defaultValue={`${GradeNames.salary_range__from_salary} - ${GradeNames.salary_range__to_salary}`}
                                                readOnly
                                                classNames={{
                                                  input:
                                                    ' text-[15px] bg-transparent  text-normal rounded w-28',
                                                }}
                                              />
                                            )}
                                          />
                                          <div className='flex flex-col gap-2'>
                                            {' '}
                                            <Controller
                                              control={control}
                                              name={`value.${GradeNames.id}`}
                                              // rules={{
                                              //   required: 'Required',
                                              // }}

                                              render={({ field }) => (
                                                <TextInput
                                                  {...field}
                                                  type='number'
                                                  defaultValue={
                                                    GradeNames.value
                                                  }
                                                  icon={currencyIcon}
                                                  onKeyUp={(event) => {
                                                    if (
                                                      event.key === 'Enter' &&
                                                      event.target.value.trim() !==
                                                        ''
                                                    ) {
                                                      useupdatesinglesalarycomponentsmutation.mutate(
                                                        {
                                                          // id: eachdeduction.id,
                                                          id: GradeNames.id,
                                                          // grade_id:
                                                          //   GradeNames.grade_id,
                                                          value: parseInt(
                                                            event.target.value.trim()
                                                          ),
                                                        }
                                                      );
                                                    }
                                                  }}
                                                  classNames={{
                                                    input:
                                                      ' text-[15px] bg-transparent  text-normal rounded w-28',
                                                  }}
                                                />
                                              )}
                                            />
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Salarycomponents;
