import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetAllDepartments(id) {
  
  return useQuery(['super-settings-departments', id], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.ORGANISATION_DEPARTMENTS, {
        company_id: id,
      })
    );
    return response?.data;
  });
}

