import React from 'react';
import { Modal, Select, TextInput } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import useAddEmploymentType from 'pages/settings/hooks/useAddEmploymentType';
import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow2.svg';
import useOrganisationsList from 'pages/organisationSettings/hooks/useOrganisationsList';

export default function AddEmployementTypesModal({
  isModalOpen,
  setIsModalOpen,
  organisation,
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      company_id: `${organisation?.company__id}`,
    },
  });

  const closeModal = () => {
    setIsModalOpen(false);
    reset();
  };

  const organisationsList = useOrganisationsList();

  const addEmploymentTypeMutation = useAddEmploymentType(closeModal);

  const formSubmitHandler = (data) => {
    addEmploymentTypeMutation.mutate(data);
  };

  const renderFormErrorMsg = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500 pt-1 pl-1'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={closeModal}
      title={
        <div className='flex w-96 justify-between'>
          <LeftArrowIcon
            className='h-5 w-5 cursor-pointer'
            onClick={closeModal}
          />{' '}
          <p>Add employment type</p> <span className='w-5 h-5'></span>
        </div>
      }
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size={500}
      radius='md'
      transitionDuration={100}
      classNames={{
        title: 'mx-auto text-primary font-semibold text-18',
        modal: 'px-10',
      }}
    >
      <div className='relative'>
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <div className='mt-6'>
            <Controller
              control={control}
              name='company_id'
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  label='Organisation'
                  name='company_id'
                  size='md'
                  clearable
                  disabled
                  data={
                    organisationsList?.data?.data?.map((organisation) => {
                      return {
                        value: `${organisation?.company__id}`,
                        label: organisation?.company__company_name,
                      };
                    }) || []
                  }
                  rightSection={<DownArrowIcon />}
                  classNames={{
                    input: 'border-primary',
                    label: 'text-neutralsGrey',
                  }}
                  styles={{
                    rightSection: {
                      pointerEvents: 'none',
                    },
                  }}
                />
              )}
            />
            {renderFormErrorMsg('company_id')}
          </div>

          <Controller
            control={control}
            name='name'
            rules={{
              required: 'Required',
            }}
            render={({ field }) => (
              <TextInput
                label='Employment type*'
                {...field}
                size='md'
                className='mt-4'
                classNames={{
                  label: 'text-sm text-neutralsGrey',
                  input: 'border-primary bg-transparent',
                }}
              />
            )}
          />
          {renderFormErrorMsg('name')}
          <div className='flex justify-center'>
            <button className='mt-10 mb-4 w-24'>
              <PrimaryButton isLoading={addEmploymentTypeMutation?.isLoading}>
                <p>Save</p>
              </PrimaryButton>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
