import { TextInput, Timeline } from '@mantine/core';

const MySalaryTab = () => {
  return (
    <div className='min-h-[74vh] bg-[#fff] rounded-2xl shadow-2xl'>
      <div className='p-8'>
        <Timeline>
          <Timeline.Item color='orange' active>
            <h1 className='text-primary bg-[#FFE8E1] pl-4 p-2 text-lg font-semibold'>
              Current salary
            </h1>
            <div className='flex items-center mt-5'>
              <div>
                <TextInput
                  readOnly
                  label='Annual salary'
                  className='mb-1 pl-8'
                  size='xl'
                  classNames={{
                    input:
                      ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5 -mt-3',
                    label:
                      'text-[#8E8E8E] text-sm font-normal ml-2 bg-[#fff] px-2 rounded z-10 relative',
                  }}
                />
                <p className='text-[#8E8E8E] pl-8'>
                  Effective from{' '}
                  <span className='text-[#515151]'>25 May 2022, 4:44 PM</span>
                </p>
              </div>

              <div>
                <TextInput
                  label='Monthly salary'
                  className='mb-1 pl-8'
                  readOnly
                  size='xl'
                  classNames={{
                    input:
                      ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5 -mt-3',
                    label:
                      'text-[#8E8E8E] text-sm font-normal ml-2 bg-[#fff] px-2 rounded z-10 relative',
                  }}
                />
                <p className='pl-8 text-[#67A1EF]'> View salary breakup</p>
              </div>
            </div>
          </Timeline.Item>
          <Timeline.Item color='orange' active>
            <h1 className='text-primary bg-[#FFE8E1] pl-4 p-2 text-lg font-semibold'>
              Current salary
            </h1>
            <Timeline>
              <Timeline.Item active className='mt-5'>
                <h1 className='pb-4  text-lg font-semibold'>Dec 2022</h1>
                <div className='flex items-center gap-8'>
                  <div>
                    <TextInput
                      readOnly
                      label='Annual salary'
                      className='mb-1 '
                      size='xl'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5 -mt-3',
                        label:
                          'text-[#8E8E8E] text-sm font-normal ml-2 bg-[#fff] px-2 rounded z-10 relative',
                      }}
                    />
                    <p className='text-[#8E8E8E]'>
                      Effective from{' '}
                      <span className='text-[#515151]'>
                        25 May 2022, 4:44 PM
                      </span>
                    </p>
                  </div>
                  <div>
                    <TextInput
                      label='Monthly salary'
                      className='mb-1 '
                      readOnly
                      size='xl'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5 -mt-3',
                        label:
                          'text-[#8E8E8E] text-sm font-normal ml-2 bg-[#fff] px-2 rounded z-10 relative',
                      }}
                    />
                    <p className=' text-[#67A1EF]'> View salary breakup</p>
                  </div>
                </div>
              </Timeline.Item>
            </Timeline>
          </Timeline.Item>
        </Timeline>
      </div>
    </div>
  );
};

export default MySalaryTab;
