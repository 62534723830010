import React from 'react';

import useGetFamilyDetails from '../hooks/useGetFamilyDetails';
import useGetEmployeeEmergencyDetails from '../hooks/useGetEmployeeEmergencyDetails';
import useGetEmployeeMedicalDetails from '../hooks/useGetEmployeeMedicalDetails';

import { ReactComponent as UploadedIcon } from 'assets/images/uploadedIcon.svg';

export default function FamilyAndEmergency() {
  const employeeFamilyDetails = useGetFamilyDetails();
  const employeeEmergencyDetails = useGetEmployeeEmergencyDetails();
  const employeeMedicalDetails = useGetEmployeeMedicalDetails();

  return (
    <div className='shadow-lg border border-8B8B8B border-opacity-10 rounded-xl py-4 px-6 mb-4 bg-white'>
      <p className='text-primary font-medium text-xl bg-FFE8E1 px-4 py-2'>
        Emergency contacts
      </p>
      <div className='px-4 text-gray text-18'>
        <table>
          <tbody>
            <tr>
              <td className='pt-4 pb-2'>Primary contact name</td>
              <td className='px-4'>- </td>
              <td className='font-light' colSpan={4}>
                {employeeEmergencyDetails?.data?.data?.[0]?.primary_cname ||
                  'NIL'}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Relationship </td>
              <td className='px-4'>- </td>
              <td className='font-light' colSpan={4}>
                {employeeEmergencyDetails?.data?.data?.[0]?.primary_rel ||
                  'NIL'}
              </td>
            </tr>
            <tr>
              <td className=' py-3'>Contact</td>
              <td className='px-4'>- </td>
              <td className='font-light ' colSpan={4}>
                <p>
                  {employeeEmergencyDetails?.data?.data?.[0]?.primary_contact ||
                    'NIL'}
                  ,{' '}
                  {employeeEmergencyDetails?.data?.data?.[0]?.primary_alternate}
                </p>
              </td>
            </tr>
            <tr>
              <td className='pb-3 pt-6'>Secondary contact name</td>
              <td className='px-4 pb-3 pt-6'>-</td>
              <td className='font-light pb-3 pt-6' colSpan={4}>
                {employeeEmergencyDetails?.data?.data?.[0]?.secondary_cname ||
                  'NIL'}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Relationship</td>
              <td className='px-4'>- </td>
              <td className='font-light' colSpan={4}>
                {employeeEmergencyDetails?.data?.data?.[0]?.secondary_rel ||
                  'NIL'}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Contact</td>
              <td className='px-4'>- </td>
              <td className='font-light' colSpan={4}>
                {employeeEmergencyDetails?.data?.data?.[0]?.secondary_contact ||
                  'NIL'}
                ,{' '}
                {employeeEmergencyDetails?.data?.data?.[0]?.secondary_alternate}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className='text-primary font-medium text-xl bg-FFE8E1 px-4 py-2 mt-6'>
        Family information
      </p>
      <div className='px-4 text-gray text-18 mt-4'>
        {employeeFamilyDetails?.data?.data?.length ? (
          <table className=' text-left'>
            <tbody>
              <tr>
                <td className='w-48'>Name</td>
                <td className='pl-6'></td>
                <td className='pl-6'>Relationship</td>
                <td className='pl-80'>Contact</td>
              </tr>
              {employeeFamilyDetails?.data?.data?.map((item) => (
                <tr key={item?.id}>
                  <td className='pt-4 pb-2 font-light'>{item?.name}</td>
                  <td className='pl-6'>-</td>
                  <td className='font-light pt-4 pb-2 pl-8'>
                    {item?.relationship}
                  </td>
                  <td className='pt-4 pb-2 font-light pl-80'>
                    <p>
                      {item?.mobile_number}, {item?.alternate_number}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className='flex flex-col items-center justify-center'>
            <UploadedIcon className='w-60 h-28' />
            <p className='text-gray3 pt-2'>No Data to show</p>
          </div>
        )}
      </div>
      <p className='text-primary font-medium text-xl bg-FFE8E1 px-4 py-2 mt-6'>
        Medical details
      </p>
      <div className='px-4 text-gray text-18 mt-4'>
        <table className=' text-left'>
          <tbody>
            <tr>
              <td className='pb-4'>Blood group</td>
              <td className='pb-4 px-4'>-</td>
              <td className='font-light pb-4'>
                {employeeMedicalDetails?.data?.data?.[0]?.blood_group}
              </td>
              <td className='pb-4 pl-80'>Physical impairment</td>
              <td className='pb-4 px-4'>-</td>
              <td className='font-light pb-4'>
                {employeeMedicalDetails?.data?.data?.[0]?.physical_impairment
                  ? employeeMedicalDetails?.data?.data?.[0]
                      ?.physical_impairment_details
                  : 'NIL'}
              </td>
            </tr>
            <tr>
              <td className='pb-4'>Critical medical issues</td>
              <td className='pb-4 px-4'>-</td>
              <td className='font-light pb-4'>
                {employeeMedicalDetails?.data?.data?.[0]
                  ?.critical_medical_issues
                  ? employeeMedicalDetails?.data?.data?.[0]
                      ?.critical_medical_issues_details
                  : 'NIL'}
              </td>
              <td className='pb-4 pl-80'>Allergies</td>
              <td className='pb-4 px-4'>-</td>
              <td className='font-light pb-4'>
                {employeeMedicalDetails?.data?.data?.[0]?.allergies
                  ? employeeMedicalDetails?.data?.data?.[0]?.allergies_details
                  : 'NIL'}
              </td>
            </tr>
            <tr>
              <td>Family doctor name</td>
              <td className='px-4'>-</td>
              <td className='font-light'>
                {employeeMedicalDetails?.data?.data?.[0]?.family_doctor_name ||
                  'NIL'}
              </td>
              <td className='pl-80'>Family doctor contact</td>
              <td className='px-4'>-</td>
              <td className='font-light'>
                {employeeMedicalDetails?.data?.data?.[0]
                  ?.family_doctor_number || 'NIL'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
