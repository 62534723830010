import React, { createContext, useEffect, useReducer } from 'react';
export const ChatContext = createContext();

let initialState = {
  user_status: JSON.parse(localStorage.getItem('user_status')) || null,
  conversationsList: [],
  activeConversationUser: {},
  inputFieldDataForEachUser: [],
  groupSpecificUsersList: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'USER_STATUS':
      localStorage.setItem(
        'user_status',
        JSON.stringify(action.payload.status ?? false)
      );

      return {
        ...state,
        user_status: action.payload.status ?? null,
      };

    case 'SET_CONVERSATIONS':
      return {
        ...state,
        conversationsList: action.payload ?? [],
      };

    case 'SET_ACTIVE_CONVERSATION_USER':
      return {
        ...state,
        activeConversationUser: action.payload ?? [],
      };
    case 'SET_INPUT_FIELD_DATA_USER_SPECIFIC':
      return {
        ...state,
        inputFieldDataForEachUser: action.payload ?? [],
      };
    case 'SET_GROUP_SPECIFIC_USER_LIST':
      return {
        ...state,
        groupSpecificUsersList: action.payload ?? [],
      };

    default:
      return state;
  }
};

const ChatProvider = ({ children }) => {
  const [chatState, chatDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialState = { ...initialState };
  }, []);

  return (
    <ChatContext.Provider
      value={{
        chatState,
        chatDispatch,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
