// import { Input } from '@mantine/core';
import { Textarea, TextInput, Tooltip } from "@mantine/core";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import { ReactComponent as ImproveIcon } from "assets/icons/improveIcon.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/successIcon.svg";

import PrimaryButton from "components/primaryButton";
import { useEffect } from "react";

import useGetPerformanceobjectiverating from "../hooks/useGetperformanceobjectivereating";

import { data } from "pages/meetings/pages/components/inputAttendeeData";

const ViewOjectivePerformance = ({ employeePerformanceReport }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      first_kra: [
        {
          skill: "",
          expected_value: "",
          current_value: "",
          feedback: "",
          self_rating: "",
        },
      ],
    },
    mode: "onChange",
  });
  console.log(employeePerformanceReport, "employee :");
  // const objectivePerformance = useGetPerformanceobjectiverating(data);

  const { fields } = useFieldArray({
    control,
    name: "first_kra",
  });
  console.log(employeePerformanceReport, "report");
  useEffect(() => {
    if (!employeePerformanceReport?.objective_rating) return;

    const objectiveRating = employeePerformanceReport?.objective_rating;

    const mappedValues =
      objectiveRating?.map((first) => ({
        skill: first?.skill,
        current_value: first?.current_value,
        expected_value: first?.expected_value,
        feedback: first?.feedback,
        self_rating: first?.self_rating,
        objective_feedback: first?.objective_feedback,
        objective_maximum: first?.objective_maximum,
        objective_awarded: first?.objective_awarded,
      })) || [];

    // Reset the form with the mapped values
    // reset({
    //   first_kra: mappedValues,
    //   objective_feedback:
    //     employeePerformanceReport?.data?.data[0]?.objective_feedback,
    //   objective_maximum:
    //     employeePerformanceReport?.data?.data[0]?.objective_maximum,
    //   objective_awarded:
    //     employeePerformanceReport?.data?.data[0]?.objective_awarded,
    // });

    // Ensure that the form is re-rendered with the new values
    setValue("first_kra", mappedValues, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("objective_feedback",employeePerformanceReport?.objective_feedback)
    setValue("objective_maximum",employeePerformanceReport?.objective_maximum)
    setValue("objective_awarded",employeePerformanceReport?.objective_awarded)
  }, [employeePerformanceReport?.objective_rating, reset, setValue]);

  const optionsCheck = watch("first_kra");
  console.log(optionsCheck, "setvalue");
  return (
    <div>
      <div className="">
        <h1 className="text-lg font-semibold mt-10">
          Overall objective rating
        </h1>

        {/*First Field */}
        <div className="">
          {fields?.map((item, index) => (
            <>
              <div key={item.id} className="grid grid-cols-11 gap-3  my-2">
                <div className="col-span-3 py-1 ">
                  <Controller
                    control={control}
                    name={`first_kra.${index}.skill`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        classNames={{
                          input: "border-primary",
                          label: "text-neutralsGrey text-sm",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-span-2 py-1 ">
                  {" "}
                  <Controller
                    control={control}
                    name={`first_kra.${index}.expected_value`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        type="number"
                        classNames={{
                          input: "border-primary",
                          label: "text-neutralsGrey text-sm",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-span-2 py-1 ">
                  {" "}
                  <Controller
                    control={control}
                    // name='name'
                    name={`first_kra.${index}.current_value`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        type="number"
                        rightSection={
                          watch(`first_kra.${index}.expected_value`) <=
                          watch(`first_kra.${index}.current_value`) ? (
                            <SuccessIcon className="h-5 w-5" />
                          ) : (
                            <ImproveIcon className="h-5 w-5" />
                          )
                        }
                        classNames={{
                          input: "border-primary ",
                          label: "text-neutralsGrey text-[12px] ml-2 ",
                        }}
                      />
                    )}
                  />{" "}
                </div>
                <div className="col-span-2 py-1 ">
                  {" "}
                  <Tooltip
                    label={watch(`first_kra.${index}.feedback`)}
                    wrapLines
                    classNames={{
                      body: "bg-white text-gray2 shadow-tooltip pr-3 z-0",
                    }}
                    radius="md"
                  >
                    <Controller
                      control={control}
                      name={`first_kra.${index}.feedback`}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size="md"
                          readOnly
                          classNames={{
                            input: "border-primary ",
                            label: "text-neutralsGrey text-[12px] ml-2 ",
                          }}
                        />
                      )}
                    />
                  </Tooltip>
                </div>
                <div className="col-span-2 py-1 ">
                  {" "}
                  <Controller
                    control={control}
                    name={`first_kra.${index}.self_rating`}
                    rules={{
                      min: {
                        value: 0,
                        message: "value should be greate than 0 ",
                      },
                      max: {
                        value: 100,
                        message: "value should be less than 100",
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        readOnly
                        type="number"
                        // placeholder='Type and Enter'
                        rightSection={
                          parseFloat(
                            watch(`first_kra.${index}.current_value`)
                          ) <=
                          parseFloat(
                            watch(`first_kra.${index}.self_rating`)
                          ) ? (
                            <SuccessIcon className="h-5 w-5" />
                          ) : (
                            <ImproveIcon className="h-5 w-5" />
                          )
                        }
                        classNames={{
                          input: "border-primary ",
                          label: "text-neutralsGrey text-sm",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </>
          ))}
         <div className="mt-4">
                <Controller
                  control={control}
                  name="objective_feedback"
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      size="md"
                      readOnly
                      placeholder="Add Comment"
                      label="Overall comment / feedback"
                      classNames={{
                        input: "h-[75px] border-primary",
                        label: "text-primary text-sm",
                      }}
                    />
                  )}
                />
              </div>
              <div className="grid grid-cols-2 gap-3">
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="objective_maximum"
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        // value={`${parseFloat(objratingmax).toFixed(2)}`}
                        readOnly
                        disabled
                        placeholder="Maximum %"
                        type="number"
                        classNames={{
                          input: "border-primary",
                          label: "text-neutralsGrey text-sm",
                        }}
                      />
                    )}
                  />
                </div>
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="objective_awarded"
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        // value={`${parseFloat(objreatingaverageAcquired).toFixed(
                        //   2
                        // )}`}
                        type="number"
                        readOnly
                        disabled
                        placeholder="Acquired %"
                        classNames={{
                          input: "border-primary",
                          label: "text-neutralsGrey text-sm",
                        }}
                      />
                    )}
                  />
                </div>{" "}
              </div>
        </div>

        <div className="flex justify-center items-center gap-3 mt-10 mb-5">
          <button>
            <PrimaryButton>Close Report</PrimaryButton>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ViewOjectivePerformance;
