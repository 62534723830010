/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Avatar, Indicator, Tooltip } from "@mantine/core";
import { AuthContext } from "context/authContext";
import isToday from "dayjs/plugin/isToday";
import { useParams } from "react-router-dom";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";

import apiEndPoints from "services/apiEndPoints";
import truncateString from "helper/truncateString";
import { useNavigate } from "react-router-dom";
import StatusLabel from "../hooks/statusLabel";
import { ReactComponent as MoreOptionsIcon } from "assets/icons/MoreOptionsIcon.svg";
import dayjs from "dayjs";
import { ChatContext } from "context/chatContext";
import { useDebounce } from "react-use";
dayjs.extend(isToday);

function UserCard({ user, isLast, current_user_id, sendJsonMessage }) {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const { conversationRoom } = useParams();
  const { chatState, chatDispatch } = useContext(ChatContext);

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  const [show, setShow] = useState(user?.typing ?? false);
  // const [isReceivingTypingUpdate, setIsReceivingTypingUpdate] = useState(false);

  useDebounce(
    () => {
      setShow(false);
    },
    5000,
    [user?.typing]
  );

  // On componentDidMount set the timer
  useEffect(() => {
    if (user?.typing) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [user?.typing]);

  const lastUserMessage = () => {
    if (user?.message?.from_user?.id === current_user_id) {
      return `You : ${
        user?.message?.file_url ? "Sent a file" : user?.message?.content
      }`;
    } else {
      return `${
        user?.is_room && user?.message?.from_user?.username
          ? user?.message?.from_user?.username + ": "
          : ""
      } ${
        user?.message?.file_url
          ? "Sent a file"
          : user?.message?.content
          ? user?.message?.content
          : ""
      }`;
    }
  };

  useEffect(() => {
    if (user.room_name === conversationRoom) {
      const newChatList = [...chatState?.conversationsList];

      newChatList?.map((chat) => {
        if (chat.room_name === conversationRoom) {
          const newChat = {
            ...chat,
          };
          if (newChat.message) newChat.message.user_read_msg = true;
          return newChat;
        } else {
          return { ...chat };
        }
      });

      const filteredList = newChatList?.filter((value) => {
        if (value?.room_name === conversationRoom) {
          return true;
        } else {
          return !value?.is_temp_convo;
        }
      });

      chatDispatch({
        type: "SET_CONVERSATIONS",
        payload: filteredList,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.room_name, conversationRoom]);
  console.log(user, "mess");
  return (
    <div
      className={`flex w-full p-4 ${
        user.room_name === conversationRoom ? "bg-FFF2F2" : ""
      } ${isLast ? "" : "border-b"}  border-primary border-opacity-20`}
      key={user.user_id}
    >
      <div
        onClick={() => {
          navigate(
            `/app/conversations/${user.room_name}${
              user.is_room ? "?group=true" : ""
            }`
          );
        }}
        className="flex custom-indicator cursor-pointer"
      >
        {console.log(user?.status,"status")}
        {user?.status !== null ? (
          <Tooltip label={user?.status} position="bottom">
            <Indicator
              inline
              size={16}
              offset={6}
              label={StatusLabel(user?.status)}
              position="bottom-end"
              withBorder
              radius="xl"
            >
              <Avatar
                src={user?.profile_picture || ""}
                radius="xl"
                color="red"
                size={"36px"}
                classNames={{ root: "border-2 border-primary" }}
              >
                {user?.username?.[0] || "-"}
              </Avatar>
            </Indicator>
          </Tooltip>
        ) : (
          <Indicator
            inline
            size={16}
            offset={6}
            label={StatusLabel(user?.status)}
            position="bottom-end"
            withBorder
            radius="xl"
          >
            <Avatar
              src={user?.profile_picture || ""}
              radius="xl"
              color="red"
              size={"36px"}
              classNames={{ root: "border-2 border-primary" }}
            >
              {user?.username?.[0] || "-"}
            </Avatar>
          </Indicator>
        )}
      </div>
      <div className="flex flex-col flex-1 justify-around ml-2">
        <div className="flex justify-between">
          <p
            onClick={() => {
              navigate(
                `/app/conversations/${user.room_name}${
                  user.is_room ? "?group=true" : ""
                }`
              );
            }}
            className=" leading-4 cursor-pointer"
          >
            {truncateString(user?.username, 40)}
          </p>
          {user?.message ? (
            <div className="flex items-center">
              <p className="text-838383 text-xs mr-2">
                {dayjs(user?.message?.timestamp).isToday()
                  ? dayjs(user?.message?.timestamp).format("hh:mm A")
                  : dayjs(user?.message?.timestamp).format("DD/MM")}
              </p>
              {/* <span
                onClick={() => {
                  console.log('button clicked');
                }}
                className='py-1  cursor-pointer'
              >
                <MoreOptionsIcon className='hover:scale-110 mr-1' />
              </span> */}
              <Popover className="relative">
                <Popover.Button
                  ref={setReferenceElement}
                  className="cursor-pointer flex items-center ml-2 py-1 outline-none hover:scale-110"
                  tabIndex={-1}
                >
                  <MoreOptionsIcon className="" />
                </Popover.Button>

                <Popover.Panel
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="absolute z-10 w-40 max-w-lg px-4 py-2"
                >
                  <div className="overflow-hidden rounded-lg shadow-lg">
                    <div className="relative bg-white p-3">
                      <div className="flex flex-col">
                        {user?.is_hide ? (
                          <button
                            onClick={() => {
                              sendJsonMessage({
                                type: "group_hide",
                                room_name: user.room_name,
                                hide: false,
                              });
                            }}
                            type="button"
                            className="flex items-start py-1 mb-1 border-b border-black border-opacity-20"
                          >
                            Unhide
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              sendJsonMessage({
                                type: "group_hide",
                                room_name: user.room_name,
                                hide: true,
                              });
                            }}
                            type="button"
                            className="flex items-start py-1 mb-1 border-b border-black border-opacity-20"
                          >
                            Hide
                          </button>
                        )}
                        <button
                          type="button"
                          className="flex items-start py-1 opacity-40"
                        >
                          Un named
                        </button>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Popover>
            </div>
          ) : null}
        </div>
        <div
          onClick={() => {
            navigate(
              `/app/conversations/${user.room_name}${
                user.is_room ? "?group=true" : ""
              }`
            );
          }}
          className="flex mt-1 cursor-pointer"
        >
          {user?.message ? (
            show && user?.typing_user_info?.id !== authState.user_id ? (
              <p className={`text-xs `}>
                {user?.typing_user_info?.username
                  ? `${user?.typing_user_info?.username} is `
                  : " "}{" "}
                typing...
              </p>
            ) : (
              <Tooltip
                label={lastUserMessage(user?.last_msg, 60)}
                position="bottom"
              >
                <p
                  className={`text-7A7A7A text-xs ${
                    !user?.message?.user_read_msg &&
                    user?.message?.from_user?.id !== current_user_id
                      ? "font-semibold text-black"
                      : ""
                  }`}
                >
                  {truncateString(lastUserMessage(user?.last_msg, 60))}
                </p>
              </Tooltip>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default function ConversationList() {
  // const { conversationName } = useParams();

  const { authState } = useContext(AuthContext);
  const { chatState, chatDispatch } = useContext(ChatContext);

  // eslint-disable-next-line no-unused-vars
  const { readyState, sendJsonMessage } = useWebSocket(
    authState.isAuthenticated
      ? `${process.env.REACT_APP_WEB_SOCKET}${apiEndPoints.CONVERSATIONS_LIST}`
      : null,
    {
      queryParams: {
        token: authState.isAuthenticated ? authState.token : "",
      },
      onOpen: () => {
        console.log("Connected!");
      },
      onClose: () => {
        console.log("Disconnected!");
      },
      // onMessage handler
      onMessage: (e) => {
        const data = JSON.parse(e.data);
        console.log(data, "conversation list web socket");
        switch (data.type) {
          case "conversations_list":
            chatDispatch({
              type: "SET_CONVERSATIONS",
              payload: data?.conversations,
            });
            break;
          case "new_message":
            let itemToMove;

            const updatedConversations = chatState?.conversationsList?.map(
              (element) => {
                if (data.is_room) {
                  if (data.room_name === element.room_name) {
                    itemToMove = {
                      ...element,
                      message: data.message,
                    };
                    return {
                      ...element,
                      message: data.message,
                    };
                  } else {
                    return {
                      ...element,
                    };
                  }
                } else {
                  if (
                    data.user_id === element.user_id ||
                    (data.user_id === authState.user_id &&
                      data?.message?.to_user.id === element.user_id)
                  ) {
                    itemToMove = {
                      ...element,
                      message: data.message,
                    };
                    return {
                      ...element,
                      message: data.message,
                    };
                  } else {
                    return {
                      ...element,
                    };
                  }
                }
              }
            );

            const foundIdx = updatedConversations.findIndex((el) => {
              if (itemToMove.is_room) {
                return el.room_name === itemToMove.room_name;
              } else {
                return el.user_id === itemToMove.user_id;
              }
            });
            updatedConversations.splice(foundIdx, 1);
            updatedConversations.unshift(itemToMove);

            chatDispatch({
              type: "SET_CONVERSATIONS",
              payload: updatedConversations,
            });
            break;
          case "typing":
            const updatedConversationsTypingStatus =
              chatState?.conversationsList?.map((element) => {
                if (data.user_id === element.user_id) {
                  return {
                    ...element,
                    typing: data.typing,
                  };
                } else {
                  return {
                    ...element,
                  };
                }
              });
            chatDispatch({
              type: "SET_CONVERSATIONS",
              payload: updatedConversationsTypingStatus,
            });
            break;
          case "typing_room":
            if (data.is_room) {
              const updatedConversationsTypingRoomStatus =
                chatState?.conversationsList?.map((element) => {
                  if (data.room_name === element.room_name) {
                    return {
                      ...element,
                      typing: data.typing,
                      typing_user_info: data.user_info,
                    };
                  } else {
                    return {
                      ...element,
                    };
                  }
                });
              chatDispatch({
                type: "SET_CONVERSATIONS",
                payload: updatedConversationsTypingRoomStatus,
              });
            }
            break;
          // case 'send_group_info_updated':
          //   const updatedConversations2 = [...chatState?.conversationsList];
          //   const foundIdx2 = updatedConversations2.findIndex(
          //     (el) => el.room_name === data.room_name
          //   );
          //   updatedConversations2[foundIdx2] = data;
          //   chatDispatch({
          //     type: 'SET_CONVERSATIONS',
          //     payload: updatedConversations2,
          //   });
          //   break;
          // case 'group_created_message':
          //   sendJsonMessage({
          //     type: 'notify_group_create_to_group_members',
          //     room_name: data.room_name,
          //   });
          //   break;
          case "group_created_updated_info":
            const updatedConversations2 = [...chatState?.conversationsList];
            const foundIdx2 = updatedConversations2.findIndex(
              (el) => el.room_name === data.room_name
            );
            console.log(foundIdx2, "foundIdx2");

            if (foundIdx2 >= 0) {
              updatedConversations2[foundIdx2] = data;
              chatDispatch({
                type: "SET_CONVERSATIONS",
                payload: updatedConversations2,
              });
            } else {
              const newChatList = [data, ...chatState?.conversationsList];

              chatDispatch({
                type: "SET_CONVERSATIONS",
                payload: newChatList,
              });
            }

            break;
          case "group_hide_return_msg":
            if (data.hide) {
              // hide user list in the list

              const filteredHideUnhideConversationList =
                chatState?.conversationsList.filter((element) => {
                  return data.room_name !== element.room_name;
                });
              chatDispatch({
                type: "SET_CONVERSATIONS",
                payload: filteredHideUnhideConversationList,
              });
            } else {
              const hideUnhideRefreshData = [...chatState?.conversationsList];

              const foundIdx3 = hideUnhideRefreshData.findIndex(
                (el) => data.room_name === el.room_name
              );

              hideUnhideRefreshData[foundIdx3].is_hide = false;
              hideUnhideRefreshData[foundIdx3].is_temp_convo = false;

              chatDispatch({
                type: "SET_CONVERSATIONS",
                payload: hideUnhideRefreshData,
              });
            }

            break;
          // case 'user_join':
          //   setParticipants((pcpts: string[]) => {
          //     if (!pcpts.includes(data.user)) {
          //       return [...pcpts, data.user];
          //     }
          //     return pcpts;
          //   });
          //   break;
          // case 'user_leave':
          //   setParticipants((pcpts: string[]) => {
          //     const newPcpts = pcpts.filter((x) => x !== data.user);
          //     return newPcpts;
          //   });
          //   break;
          // case 'online_user_list':
          //   setParticipants(data.users);
          //   break;
          // case 'typing':
          //   updateTyping(data);
          //   break;
          default:
            console.error("Unknown message type!");
            break;
        }
      },
    }
  );

  // eslint-disable-next-line no-unused-vars
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  // useEffect(() => {
  //   if (connectionStatus === 'Open') {
  //     sendJsonMessage({
  //       type: 'read_messages',
  //     });
  //   }
  // }, [connectionStatus, sendJsonMessage]);

  return (
    <div className="flex flex-col w-full overflow-y-scroll scroll-overlay scroller">
      {chatState?.conversationsList?.map((member, index, array) => (
        <UserCard
          user={member}
          key={member?.user_id ? member?.user_id : member?.room_name}
          isLast={index === array.length - 1}
          current_user_id={authState?.user_id}
          sendJsonMessage={sendJsonMessage}
        />
      ))}
    </div>
  );
}
