import React, { useState } from 'react';
import { Timeline, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';

import EditEducationModal from './editEducationModal';

import useGetEmployeeEducationalDetails from '../hooks/useGetEducationDetails';
import useGetProfessionalExperienceData from '../hooks/useGetProfessionalExperienceData';
import useGetEmployeePersonalInfo from '../hooks/useGetEmployeePersonalInfo';

import useDeleteEmployeeEducationDetails from '../hooks/useDeleteEducationDetails';
import EditProfessionalDetailsModal from './editProfessionModal';
import useDeleteEmployeeExperienceDetails from '../hooks/useDeleteProfessionalExperience';

import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';
import { ReactComponent as EmptyEducationIlls } from 'assets/images/emptyEducationIlls.svg';
import { ReactComponent as NoExperienceIlls } from 'assets/images/noExperienceIlls.svg';
import { ReactComponent as AddIcon } from 'assets/icons/addIcon.svg';
import { ReactComponent as ActiveBullet } from 'assets/icons/activeBullet.svg';
import { ReactComponent as PdfImage } from 'assets/images/pdfImage.svg';
import UpdateEmployeeResume from './updateEmployeeResume';

export default function EducationAndExperience() {
  const [isEducationModalOpen, setEducationModalOpen] = useState(false);
  const [isProfessionalDetailsModalOpen, setProfessionalDetailsModalOpen] =
    useState(false);
  const [isResumeModalOpen, setResumeModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState('');

  const getEmployeeEducationalDetails = useGetEmployeeEducationalDetails();
  const getEmployeePersonalInfo = useGetEmployeePersonalInfo();
  const deleteEducationMutation = useDeleteEmployeeEducationDetails();
  const getEmployeeProfessionalData = useGetProfessionalExperienceData();
  const deleteExperienceMutation = useDeleteEmployeeExperienceDetails();

  const handleDeleteEducation = (data) => {
    deleteEducationMutation?.mutate({ id: data?.id });
  };

  const getCustomTimelineTitle = (data) => {
    return (
      <div className='flex justify-between mr-4'>
        <p>{data?.instituition}</p>
        <div className='flex space-x-6 items-center'>
          <EditIcon
            className='cursor-pointer'
            onClick={() => {
              setSelectedData(data);
              setEducationModalOpen(true);
            }}
          />
          <DeleteIcon
            className='cursor-pointer'
            onClick={() => handleDeleteEducation(data)}
          />
        </div>
      </div>
    );
  };

  const getCustomTimelineTitleForExperience = (data) => {
    return (
      <div className='flex justify-between mr-4'>
        <p>
          {data?.role} at {data?.organisation}
        </p>
        <div className='flex space-x-6 items-center'>
          <EditIcon
            className='cursor-pointer'
            onClick={() => {
              setSelectedData(data);
              setProfessionalDetailsModalOpen(true);
            }}
          />
          <DeleteIcon
            className='cursor-pointer'
            onClick={() => deleteExperienceMutation.mutate({ id: data?.id })}
          />
        </div>
      </div>
    );
  };

  const customDeactiveBullet = () => {
    return <span className='w-7 h-5 rounded-full bg-[#F5DDD5]'></span>;
  };

  const customActiveBullet = () => {
    return <ActiveBullet />;
  };

  function getExperienceCount(monthCount) {
    function getPlural(number, word) {
      return number === 1 ? word.one : word.other;
    }

    var months = { one: 'month', other: 'months' },
      years = { one: 'year', other: 'years' },
      m = monthCount % 12,
      y = Math.floor(monthCount / 12),
      result = [];

    y && result.push(y + ' ' + getPlural(y, years));
    m && result.push(m + ' ' + getPlural(m, months));
    return result.join(' and ');
  }

  const checkFileType = (data) => {
    if (data !== 'undefined' && data !== null) {
      const newArray = data?.split('.');
      if (newArray && newArray[newArray?.length - 1] === 'pdf') {
        return 'pdf';
      } else {
        return 'img';
      }
    } else {
      return null;
    }
  };

  return (
    <div className='shadow-lg border border-8B8B8B border-opacity-10 rounded-xl py-4 px-6 mb-8 bg-white'>
      <div className='bg-FFE8E1 bg-opacity-50 px-4 py-2 flex justify-between items-center mb-2'>
        <p className='text-primary font-medium text-xl '>My skills </p>
        <EditIcon
          className='cursor-pointer'
          onClick={() => setResumeModalOpen(true)}
        />
      </div>
      <div className='flex items-center justify-between'>
        <p className='pl-5 text-gray text-lg mb-6'>
          Upload your updated CV to view your skills matrix dashboard
        </p>
        <div className='my-4'>
          {checkFileType(getEmployeePersonalInfo?.data?.data?.[0]?.resume) ===
          'pdf' ? (
            <a
              href={getEmployeePersonalInfo?.data?.data?.[0]?.resume}
              target='_blank'
              rel='noreferrer'
            >
              <PdfImage />
            </a>
          ) : null}
        </div>
      </div>
      <div className='bg-FFE8E1 bg-opacity-50 px-4 py-2 flex justify-between items-center mb-2'>
        <p className='text-primary font-medium text-xl '>Education</p>
        <AddIcon
          className='cursor-pointer p-1'
          onClick={() => {
            setSelectedData('');
            setEducationModalOpen(true);
          }}
        />
      </div>
      <div className='mt-4 pl-6'>
        {getEmployeeEducationalDetails?.data?.data?.length ? (
          <Timeline
            bulletSize={20}
            lineWidth={2}
            classNames={{ itemTitle: 'text-gray text-18' }}
          >
            {getEmployeeEducationalDetails?.data?.data?.map((item, index) => {
              return (
                <Timeline.Item
                  title={getCustomTimelineTitle(item)}
                  key={item?.id}
                  bullet={
                    index === 0 ? customActiveBullet() : customDeactiveBullet()
                  }
                >
                  <div className='flex justify-between items-center'>
                    <div>
                      <p className='text-gray font-light'>{item?.degree}</p>
                      <p className='text-gray font-light'>
                        {dayjs(new Date(item.from_date)).format('MMM YYYY')}-
                        {dayjs(new Date(item.to_date)).format('MMM YYYY')}
                      </p>
                    </div>
                    <div className='mr-4 mt-2'>
                      <div className='flex space-x-4 justify-end'>
                        {checkFileType(item?.certificate) === 'img' ? (
                          <a
                            href={item?.certificate}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <img
                              alt='aadhar'
                              src={item?.certificate}
                              className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                            />
                          </a>
                        ) : checkFileType(item?.certificate) === 'pdf' ? (
                          <a
                            href={item?.certificate}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <PdfImage />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        ) : (
          <div className='flex justify-center items-center flex-col pt-4'>
            <EmptyEducationIlls className='w-72 h-40' />
            <p className='text-gray3 pt-3'>No Educational details to show</p>
          </div>
        )}
      </div>
      <div className='bg-FFE8E1 bg-opacity-50 px-4 py-2 flex justify-between items-center mb-2 mt-6'>
        <p className='text-primary font-medium text-xl '>
          Professional experience
        </p>
        <AddIcon
          className='cursor-pointer p-1'
          onClick={() => {
            setSelectedData('');
            setProfessionalDetailsModalOpen(true);
          }}
        />
      </div>
      <div className='mt-4 pl-6'>
        {getEmployeeProfessionalData?.data?.data?.length ? (
          <Timeline
            bulletSize={20}
            lineWidth={2}
            classNames={{
              itemTitle: 'text-gray text-18',
            }}
          >
            {getEmployeeProfessionalData?.data?.data?.map((item, index) => {
              return (
                <Timeline.Item
                  title={getCustomTimelineTitleForExperience(item)}
                  key={item?.id}
                  bullet={
                    index === 0 ? customActiveBullet() : customDeactiveBullet()
                  }
                >
                  <div className='flex justify-between items-center'>
                    <div className='flex-1'>
                      <div>
                        <p className='text-gray font-light'>
                          {dayjs(item?.from_date).format('MMM YYYY')} -{' '}
                          {dayjs(item?.to_date).format('MMM YYYY')},{' '}
                          {getExperienceCount(
                            dayjs(item?.to_date).diff(item?.from_date, 'month')
                          )}
                        </p>
                      </div>
                      <div>
                        <div className=' text-gray'>
                          <table className='w-full my-2'>
                            <tbody className='w-full text-14'>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>Employee ID </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.old_employee_id || '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    Work Email
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.work_email || '-'}
                                  </td>
                                </div>
                              </tr>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>
                                    Company Address{' '}
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.company_address || '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    Last drawn salary
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.last_drawn_salary || '-'}
                                  </td>
                                </div>
                              </tr>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>
                                    Reporting manager name{' '}
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.reporting_manager_name || '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    Reporting manager email
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.reporting_manager_email || '-'}
                                  </td>
                                </div>
                              </tr>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>
                                    Reporting manager contact number{' '}
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.reporting_manager_contact_number ||
                                      '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    HR manager name
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.hr_manager_name || '-'}
                                  </td>
                                </div>
                              </tr>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>
                                    HR manager email
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.hr_manager_email || '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    HR manager contact number
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.hr_manager_contact_number || '-'}
                                  </td>
                                </div>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className='mr-4 mt-2'>
                      <div className='flex space-x-4 justify-end'>
                        <Tooltip
                          label='Joining letter'
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                          }}
                          radius='md'
                        >
                          <div>
                            {checkFileType(item?.joining_letter) === 'img' ? (
                              <a
                                href={item?.joining_letter}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <img
                                  alt='aadhar'
                                  src={item?.joining_letter}
                                  className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                                />
                              </a>
                            ) : checkFileType(item?.joining_letter) ===
                              'pdf' ? (
                              <a
                                href={item?.joining_letter}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <PdfImage />
                              </a>
                            ) : null}
                          </div>
                        </Tooltip>
                        <Tooltip
                          label='Experience letter'
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                          }}
                          radius='md'
                        >
                          <div>
                            {checkFileType(item?.experience_letter) ===
                            'img' ? (
                              <a
                                href={item?.experience_letter}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <img
                                  alt='aadhar'
                                  src={item?.experience_letter}
                                  className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                                />
                              </a>
                            ) : checkFileType(item?.experience_letter) ===
                              'pdf' ? (
                              <a
                                href={item?.experience_letter}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <PdfImage />
                              </a>
                            ) : null}
                          </div>
                        </Tooltip>
                        <Tooltip
                          label='Relieving letter'
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                          }}
                          radius='md'
                        >
                          <div>
                            {checkFileType(item?.relieving_letter) === 'img' ? (
                              <a
                                href={item?.relieving_letter}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <img
                                  alt='aadhar'
                                  src={item?.relieving_letter}
                                  className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                                />
                              </a>
                            ) : checkFileType(item?.relieving_letter) ===
                              'pdf' ? (
                              <a
                                href={item?.relieving_letter}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <PdfImage />
                              </a>
                            ) : null}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        ) : (
          <div className='flex justify-center items-center flex-col pt-4'>
            <NoExperienceIlls className='w-72 h-40' />
            <p className='text-gray3 pt-3'>
              No Professional experience data to show
            </p>
          </div>
        )}
      </div>
      <EditEducationModal
        isModalOpen={isEducationModalOpen}
        setIsModalOpen={setEducationModalOpen}
        educationalDetails={selectedData}
      />
      <EditProfessionalDetailsModal
        isModalOpen={isProfessionalDetailsModalOpen}
        setIsModalOpen={setProfessionalDetailsModalOpen}
        experienceData={selectedData}
      />
      <UpdateEmployeeResume
        isModalOpen={isResumeModalOpen}
        setIsModalOpen={setResumeModalOpen}
        resume={getEmployeePersonalInfo?.data?.data?.[0]?.resume}
      />
    </div>
  );
}
