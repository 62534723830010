import { Modal, MultiSelect, TextInput } from '@mantine/core';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';

import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';
import FileUpload from './fileUpload';

import useGetDepartmentsForEmployees from '../../../../team/pages/myTeam/hooks/useGetDepartmentsForEmployee';
import useGetEmployementTypes from '../../../../team/pages/myTeam/hooks/useGetEmployementType';
import useGetAllGrades from '../../../../team/pages/myTeam/hooks/useGetAllGrades';
import useGetCompanyDetails from '../../../../team/pages/myTeam/hooks/useGetComapanyDetails';
import Usegetsalaryrangedata from '../../payroll/hooks/usegetsalaryrabgedata';
import Useaddpolicies from '../hooks/useaddpolicy';
import Usegetspecificpolicies from '../hooks/usegetspecificpolicydata';
import Useupdatepolicies from '../hooks/useupdatepolicies';
import { useEffect } from 'react';

const PoliciesModal = ({
  isPoliciesModalOpened,
  setIsPoliciesModalOpened,
  policyId,
  setPolicyId,
}) => {
  const defaultvalues = {
    is_applicable_for_all_emp: '',
    applicable_for_emp: [],
    is_applicable_for_all_grade: '',
    applicable_for_grade: [],
    is_applicable_for_all_salary: '',
    applicable_for_salary: [],
    is_applicable_for_all_depart: '',
    applicable_for_depart: [],
    policy_document: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    reset,
  } = useForm({ defaultvalues });

  // useEffect(() => {
  //   reset();
  //   // eslint-disable-next-line
  // }, [isPoliciesModalOpened]);

  // defalut values

  // policiesmodal on close
  const closeModal = () => {
    reset({ defaultvalues });
    setIsPoliciesModalOpened(false);
    setPolicyId(null);
  };

  const getDepartmentsForEmployee = useGetDepartmentsForEmployees();
  const getEmployementTypes = useGetEmployementTypes(isPoliciesModalOpened);
  const getAllGrades = useGetAllGrades(isPoliciesModalOpened);
  const getCompanyDetails = useGetCompanyDetails();
  const getAllSalaryes = Usegetsalaryrangedata();
  const addPoliciesmutation = Useaddpolicies(closeModal);
  const specificPolicyData = Usegetspecificpolicies(policyId);
  const updatepocicymutation = Useupdatepolicies(closeModal);
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[650px]'>
        <LeftArrowIcon
          onClick={() => {
            setIsPoliciesModalOpened(false);
            reset({ defaultvalues });
            setPolicyId(null);
          }}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className='text-primary '>
          {policyId ? 'Edit Policy' : 'Upload a policy'}
        </p>
      </div>
    );
  };

  // Sending the form Data
  const sumbitPolicyModal = (data) => {
    const formData = new FormData();
    // some instance the list first index is getting empty string to slove that slice method is used in this form
    const departmentData =
      data?.applicable_for_depart === undefined
        ? []
        : data?.applicable_for_depart[0] === ''
        ? data?.applicable_for_depart.slice(1)
        : data?.applicable_for_depart;
    formData.append('applicable_for_depart', JSON.stringify(departmentData));
    formData.append(
      'is_applicable_for_all_depart',
      departmentData.length === 0 ? 'True' : 'False'
    );
    const employeeData =
      data?.applicable_for_emp?.[0] === undefined
        ? []
        : data?.applicable_for_emp[0] === ''
        ? data?.applicable_for_emp.slice(1)
        : data?.applicable_for_emp;
    formData.append('applicable_for_emp', JSON.stringify(employeeData));
    formData.append(
      'is_applicable_for_all_emp',
      employeeData.length === 0 ? 'True' : 'False'
    );

    if (getCompanyDetails?.data?.data[0].is_grade) {
      const greadData =
        data?.applicable_for_grade[0] === undefined
          ? []
          : data?.applicable_for_grade[0] === ''
          ? data?.applicable_for_grade.slice(1)
          : data?.applicable_for_grade;
      formData.append('applicable_for_grade', JSON.stringify(greadData));
      formData.append(
        'is_applicable_for_all_grade',
        greadData.length === 0 ? 'True' : 'False'
      );
    } else {
      const salaryData =
        data?.applicable_for_salary?.[0] === undefined
          ? []
          : data?.applicable_for_salary[0] === ''
          ? data?.applicable_for_salary.slice(1)
          : data?.applicable_for_salary;
      formData.append('applicable_for_salary', JSON.stringify(salaryData));
      formData.append(
        'is_applicable_for_all_salary',
        salaryData.length === 0 ? 'True' : 'False'
      );
    }

    formData.append('name', data?.name);
    formData.append('policy_document', data?.policy_document[0]);

    if (policyId) {
      formData.append('id', policyId);
      if (
        specificPolicyData?.data?.data[0].policy_document ===
        data?.policy_document
      ) {
        formData.delete('policy_document');
      }

      updatepocicymutation.mutate(formData);
    } else {
      addPoliciesmutation.mutate(formData);
    }
  };

  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  useEffect(() => {
    if (policyId && specificPolicyData.isSuccess) {
      setValue(
        'applicable_for_emp',
        specificPolicyData?.data?.data[0].applicable_for_emp.join().split(',')
      );
      setValue(
        'applicable_for_grade',
        specificPolicyData?.data?.data[0].applicable_for_grade.join().split(',')
      );
      setValue(
        'applicable_for_salary',
        specificPolicyData?.data?.data[0].applicable_for_salary
          .join()
          .split(',')
      );
      setValue(
        'applicable_for_depart',
        specificPolicyData?.data?.data[0].applicable_for_depart
          .join()
          .split(',')
      );
      setValue('name', specificPolicyData?.data?.data[0].name);
      setValue(
        'policy_document',
        specificPolicyData?.data?.data[0].policy_document
      );
    }
    // eslint-disable-next-line
  }, [policyId, specificPolicyData?.data?.data]);

  return (
    <div>
      <Modal
        opened={isPoliciesModalOpened}
        onClose={() => closeModal()}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-8',
        }}
      >
        <form onSubmit={handleSubmit(sumbitPolicyModal)}>
          <div className='flex flex-wrap mx-8'>
            <div className='w-full py-2 pr-4 '>
              <Controller
                control={control}
                name='name'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Policy title*'
                    size='md'
                    readOnly={specificPolicyData?.data?.data[0].is_default}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('name')}
            </div>
            <div className='grid grid-cols-3 gap-4 w-full mt-4 '>
              <div>
                <Controller
                  control={control}
                  name={
                    getCompanyDetails?.data?.data[0].is_grade
                      ? 'applicable_for_grade'
                      : 'applicable_for_salary'
                  }
                  rules={{
                    required: 'Required',
                  }}
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      size='md'
                      placeholder='Grades'
                      label='Applicable for*'
                      data={
                        getCompanyDetails?.data?.data[0].is_grade
                          ? getAllGrades.isLoading
                            ? []
                            : getAllGrades?.data?.data
                                ?.filter((grade) => grade?.is_active)
                                .map((grade) => {
                                  return {
                                    value: `${grade.id}`,
                                    label: grade.name,
                                  };
                                })
                          : getAllSalaryes.isLoading
                          ? []
                          : getAllSalaryes?.data?.data.map((salary) => {
                              return {
                                value: `${salary.id}`,
                                label: `${salary.from_salary} - ${salary.to_salary}`,
                              };
                            })
                      }
                      //   data={['1', '2', '3']}
                      rightSection={<DownArrow />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      classNames={{
                        input: 'border-primary truncate min-h-12 ',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage(
                  getCompanyDetails?.data?.data[0].is_grade
                    ? 'applicable_for_grade'
                    : 'applicable_for_salary'
                )}
              </div>
              <div className='mt-[26px]'>
                <Controller
                  control={control}
                  name='applicable_for_emp'
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      size='md'
                      placeholder='Employment types'
                      data={
                        getEmployementTypes.isLoading
                          ? []
                          : getEmployementTypes?.data?.data
                              .filter((dept) => dept.is_active)
                              .map((dept) => {
                                return {
                                  value: `${dept.id}`,
                                  label: dept.name,
                                };
                              })
                      }
                      //   data={['1', '2', '3']}
                      rightSection={<DownArrow />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      classNames={{
                        input: 'border-primary truncate min-h-12 ',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('applicable_for_emp')}
              </div>
              <div className='mt-[26px]'>
                <Controller
                  control={control}
                  name='applicable_for_depart'
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      size='md'
                      placeholder='Departments'
                      data={
                        getDepartmentsForEmployee.isLoading
                          ? []
                          : getDepartmentsForEmployee?.data?.data.map(
                              (dept) => {
                                return {
                                  value: `${dept.id}`,
                                  label: dept.name,
                                };
                              }
                            )
                      }
                      //   data={['1', '2', '3']}
                      rightSection={<DownArrow />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      classNames={{
                        input: 'border-primary truncate min-h-12  ',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('applicable_for_depart')}
              </div>
            </div>

            <div className='py-2 w-full mt-4'>
              <p className='pb-1.5 text-neutralsGrey text-sm'>
                Upload document*
              </p>
              <Controller
                control={control}
                name='policy_document'
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    multiple={false}
                    setImage={onChange}
                    value={watch('policy_document')}
                  />
                )}
              />
              {getErrorMessage('policy_document')}
            </div>
            <button className='mx-auto my-8 w-32'>
              <PrimaryButton
                isLoading={
                  addPoliciesmutation?.isLoading ||
                  updatepocicymutation?.isLoading
                }
              >
                Save
              </PrimaryButton>
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
export default PoliciesModal;
