import { Modal } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';

const ViewPaySlipModal = ({
  setViewPayslipModalOpened,
  viewPayslipModalOpened,
  selectedMonth,
  currencyIcon,
}) => {
  return (
    <div>
      <Modal
        opened={viewPayslipModalOpened}
        onClose={() => {
          setViewPayslipModalOpened(false);
        }}
        title='Salary breakup'
        // withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size={'50%'}
        radius='13px'
        overflow='inside'
        className='p-8'
        classNames={{
          title: 'text-[28px] font-semibold mx-auto text-primary',
          body: 'scroller rounded-2xl',
        }}
      >
        <div className='px-10 py-5 bg-[#FFFBFA]'>
          <table className='w-full'>
            <thead>
              <tr className='text-lg'>
                <th className='text-left pl-8 w-[45%] py-4'>Details</th>
                <th className='text-center'>Monthly</th>
              </tr>
            </thead>
            <tbody className='bg-white rounded-md text-[#787878]'>
              <tr>
                <td className='text-left pl-8 w-[45%] py-2'>Basic</td>
                <td className='text-center'>
                  {currencyIcon}
                  {selectedMonth?.employee__gross_salary?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className='text-left pl-8 w-[45%] py-2'>
                  House rent allowance (H.R.A)
                </td>
                <td className='text-center'>
                  {currencyIcon}
                  {selectedMonth?.hra}
                </td>
              </tr>
              {selectedMonth?.components_values?.map(
                (type) =>
                  type.is_allowance && (
                    <tr key={type?.id}>
                      <td className='text-left pl-8 w-[45%] py-2'>
                        {type?.name}
                      </td>
                      <td className='text-center'>
                        {currencyIcon}
                        {type?.value}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
          <table className='w-full'>
            <thead>
              <tr>
                <th className='text-left pl-8 text-lg w-[45%] py-2'>
                  Deductions
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className='bg-white rounded-md text-[#787878]'>
              <tr>
                <td className='text-left pl-8 w-[45%] py-2'>
                  Professional tax
                </td>
                <td className='text-center'>
                  {currencyIcon}
                  {selectedMonth?.professional_tax}
                </td>
              </tr>
              {selectedMonth?.components_values?.map(
                (type) =>
                  !type.is_allowance && (
                    <tr key={type?.id}>
                      <td className='text-left pl-8 w-[45%] py-2'>
                        {type?.name}
                      </td>
                      <td className='text-center'>
                        {currencyIcon}
                        {type?.value}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
          <div className='my-8 flex justify-between items-center ml-9 mr-28'>
            <h1 className='text-xl font-semibold'>Net salary</h1>
            <h1 className='text-lg font-semibold text-primary'>
              {currencyIcon}
              {selectedMonth?.net_pay}
            </h1>
          </div>
        </div>

        <div className='flex items-center justify-center space-x-5'>
          <button>
            <SecondaryButton>
              <p>Generate </p>
            </SecondaryButton>
          </button>

          <button>
            <PrimaryButton>
              <p>Download </p>
            </PrimaryButton>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewPaySlipModal;
