import { Switch } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import useEditEmploymentType from 'pages/settings/hooks/useEditEmploymentType';
import React, { useState } from 'react';
import useEmploymentTypes from '../hooks/useEmploymentTypes';
import AddEmployementTypesModal from './addEmployementTypesModal';

export default function EmploymentTypes({ organisation }) {
  const [employementTypeModalOpen, setEmployementTypeModalOpen] =
    useState(false);

  const employmentTypes = useEmploymentTypes(organisation?.company__id);
  console.log(employmentTypes)
  const updateEmploeeType = useEditEmploymentType();

  return (
    <div className=''>
      {' '}
      {/* employment types */}
      <div className='flex items-center justify-between h-14 border-b border-868686 border-opacity-30 py-8 '>
        <p className='text-18'>Employment types</p>
        <button
          onClick={() => setEmployementTypeModalOpen(true)}
          className='w-36 whitespace-nowrap'
        >
          <PrimaryButton>
            <p>+ Add types</p>
          </PrimaryButton>
        </button>
      </div>
      {employmentTypes?.data?.data?.map((type) => {
        return (
          <div
            className='flex items-center justify-between  my-6'
            key={type?.id}
          >
            <p className='text-gray'>{type?.name} </p>
            <Switch
              size='md'
              checked={type?.is_active || ''}
              onChange={() => {
                updateEmploeeType.mutate({
                  id: type?.id,
                  is_active: !type?.is_active,
                });
              }}
            />
          </div>
        );
      })}
      <AddEmployementTypesModal
        isModalOpen={employementTypeModalOpen}
        setIsModalOpen={setEmployementTypeModalOpen}
        organisation={organisation}
      />
    </div>
  );
}
