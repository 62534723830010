import { Avatar, Indicator, Input, Popover } from "@mantine/core";
import React, { useContext, useState } from "react";
import { ReactComponent as DownArrowIcon } from "assets/icons/orangeDownArrowIcon.svg";
import ConversationList from "./sections/conversationList";
import ConversationContainer from "./sections/conversationContainer";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AuthContext } from "context/authContext";
import { useQuery } from "react-query";
import makeApiRequest from "services/makeApiRequest";
import apiEndPoints from "services/apiEndPoints";

import { ReactComponent as AvailableIcon } from "assets/icons/AvailableIcon.svg";
import { ReactComponent as AwayIcon } from "assets/icons/AwayIcon.svg";
import { ReactComponent as BeRightBackIcon } from "assets/icons/BeRightBackIcon.svg";
import { ReactComponent as BusyIcon } from "assets/icons/BusyIcon.svg";
import { ReactComponent as DNDIcon } from "assets/icons/DNDIcon.svg";
import { ReactComponent as OfflineIcon } from "assets/icons/OfflineIcon.svg";
import { ReactComponent as SearchIcon } from "assets/icons/searchIcon.svg";
import { ChatContext } from "context/chatContext";
import { useDebouncedValue } from "@mantine/hooks";
import truncateString from "helper/truncateString";
import StatusLabel from "./hooks/statusLabel";
import PrimaryButton from "components/primaryButton";
import CreateGroupModal from "./sections/components/createGroupModal";
import Header from "components/Header";

function UserCard({ user, isLast, setSearchValue }) {
  const navigate = useNavigate();
  const { conversationRoom } = useParams();
  const { chatState, chatDispatch } = useContext(ChatContext);

  return (
    <div
      onClick={() => {
        const userInfo = chatState?.conversationsList?.find(
          (element) => element?.room_name === user?.room_name
        );

        if (!userInfo) {
          const newChatList = [
            { ...user, is_temp_convo: true },
            ...chatState?.conversationsList,
          ];

          if (user?.message?.timestamp) {
            newChatList.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return (
                new Date(b?.message?.timestamp) -
                new Date(a?.message?.timestamp)
              );
            });
          }
          chatDispatch({
            type: "SET_CONVERSATIONS",
            payload: newChatList,
          });
        }

        setSearchValue("");
        navigate(
          `/app/conversations/${user.room_name}${
            user?.is_room ? "?group=true" : ""
          }`
        );
      }}
      className={`flex w-full p-4 ${
        user.room_name === conversationRoom ? "bg-FFF2F2" : ""
      } ${isLast ? "" : "border-b"}  border-primary border-opacity-20`}
      key={user.user_id}
    >
      <div className="flex custom-indicator cursor-pointer">
        <Indicator
          inline
          size={16}
          offset={6}
          label={StatusLabel(user?.status)}
          position="bottom-end"
          withBorder
          radius="xl"
        >
          <Avatar
            src={user?.profile_picture || ""}
            radius="xl"
            color="red"
            size={"36px"}
            classNames={{ root: "border-2 border-primary" }}
          >
            {user?.username?.[0] || "-"}
          </Avatar>
        </Indicator>
      </div>
      <div className="flex flex-col flex-1 justify-around ml-2">
        <div className="flex justify-between">
          <p className=" leading-4 cursor-pointer">
            {truncateString(user?.username, 40)}
          </p>
        </div>
        <div className="flex mt-1 cursor-pointer">
          <p className={`text-7A7A7A text-xs`}>
            {truncateString(user?.email, 60)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default function ChatMainPage() {
  const [sendJsonMessage] = useOutletContext();

  const [opened, setOpened] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [hasSearchFocus, setSearchFocus] = useState(false);

  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);

  const [debouncedSearchFocus] = useDebouncedValue(hasSearchFocus, 300);
  const [debounced] = useDebouncedValue(searchValue, 300);

  const searchUsers = useQuery(
    ["searchUsers", debounced],
    async () => {
      const response = await makeApiRequest.get(
        `${apiEndPoints.SEARCH_USERS}?search=${debounced}`
      );
      return response?.data;
    },
    { staleTime: 3000, enabled: !!debounced }
  );

  const { conversationRoom } = useParams();

  const { authState } = useContext(AuthContext);
  const { chatState } = useContext(ChatContext);

  const updateUserStatus = (status) => {
    sendJsonMessage({
      type: "user_status_update",
      status: status,
    });
    setOpened(false);
  };

  // Layout for chat
  return (
    <div className="flex flex-col w-full px-5 h-screen pt-6">
      <div className="flex justify-end">
        <Header />
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="flex">
          <div
            className={`flex space-x-2.5 cursor-pointer py-1 ${
              opened
                ? "border-b-2 border-primary"
                : "border-b-2 border-transparent"
            }`}
            onClick={() => setOpened(true)}
          >
            <Avatar
              src={authState?.profile_picture || ""}
              radius="xl"
              color="red"
              size="lg"
              classNames={{ root: "border-2 border-primary" }}
            >
              {authState?.username?.[0] || "-"}
            </Avatar>
            <div className="flex flex-col justify-around">
              <p className="text-primary leading-4 font-semibold">
                {authState?.username}
              </p>
              <div className="flex items-center">
                <p className="text-7A7A7A mr-2">{chatState.user_status}</p>
                <DownArrowIcon />
              </div>
            </div>
          </div>
          <Popover
            opened={opened}
            onClose={() => setOpened(false)}
            position="bottom"
            placement="end"
            width={200}
            radius={"lg"}
            gutter={70}
          >
            <div className="space-y-2">
              <div
                className="flex items-center space-x-3 cursor-pointer"
                onClick={() => {
                  updateUserStatus("Online");
                }}
              >
                <div className="w-5">
                  <AvailableIcon />
                </div>
                <p>Available</p>
              </div>
              <div
                className="flex items-center space-x-3 cursor-pointer"
                onClick={() => {
                  updateUserStatus("Away");
                }}
              >
                <div className="w-5">
                  <AwayIcon />
                </div>
                <p>Away</p>
              </div>
              <div
                className="flex items-center space-x-3 cursor-pointer"
                onClick={() => {
                  updateUserStatus("Be Right Back");
                }}
              >
                <div className="w-5">
                  <BeRightBackIcon />
                </div>
                <p>Be Right Back</p>
              </div>
              <div
                className="flex items-center space-x-3 cursor-pointer"
                onClick={() => {
                  updateUserStatus("Offline");
                }}
              >
                <div className="w-5">
                  <OfflineIcon />
                </div>
                <p>Offline</p>
              </div>
              <div
                className="flex items-center space-x-3 cursor-pointer"
                onClick={() => {
                  updateUserStatus("Busy");
                }}
              >
                <div className="w-5">
                  <BusyIcon />
                </div>
                <p>Busy</p>
              </div>
              <div
                className="flex items-center space-x-3 cursor-pointer"
                onClick={() => {
                  updateUserStatus("Do Not Disturb");
                }}
              >
                <div className="w-5">
                  <DNDIcon />
                </div>
                <p>Do Not Disturb</p>
              </div>
            </div>
          </Popover>
        </div>

        <div className="flex">
          <div className="mr-2">
            <div className="relative w-80 flex justify-end items-center">
              <Input
                icon={<SearchIcon />}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
                onFocus={() => setSearchFocus(true)}
                onBlur={() => setSearchFocus(false)}
                classNames={{
                  wrapper: "border-primary border-b",
                  input: "w-44 bg-light-bg border-none",
                }}
              />
              {(searchUsers?.data?.user_list.length > 0 &&
                debouncedSearchFocus) ||
              (searchUsers?.data?.room_list.length > 0 &&
                debouncedSearchFocus) ? (
                <div className="absolute bg-white z-[200] w-80 top-10 rounded shadow-lg border border-black border-opacity-20">
                  {searchUsers?.data?.user_list.length ? (
                    <>
                      <p className="text-sm mt-2 px-4">People</p>
                      {searchUsers?.data?.user_list?.map(
                        (member, index, array) => (
                          <UserCard
                            user={member}
                            key={member.room_name}
                            isLast={index === array.length - 1}
                            current_user_id={authState?.user_id}
                            setSearchValue={setSearchValue}
                          />
                        )
                      )}
                    </>
                  ) : null}
                  {searchUsers?.data?.room_list.length ? (
                    <>
                      <p className="text-sm mt-4 px-4">Groups</p>
                      {searchUsers?.data?.room_list?.map(
                        (member, index, array) => (
                          <UserCard
                            user={member}
                            key={member.room_name}
                            isLast={index === array.length - 1}
                            current_user_id={authState?.user_id}
                            setSearchValue={setSearchValue}
                          />
                        )
                      )}
                    </>
                  ) : null}
                </div>
              ) : !!debounced &&
                !searchUsers.isLoading &&
                debouncedSearchFocus ? (
                <div className="absolute p-6 bg-white z-[200] w-80 top-10 rounded shadow-lg border border-black border-opacity-20 flex justify-center items-center">
                  <p>No results found!</p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mx-2">
            <button
              onClick={() => {
                setShowCreateGroupModal(true);
              }}
              className="w-40"
            >
              <PrimaryButton>Create Group</PrimaryButton>
            </button>
          </div>
          {/* <div className='flex items-center ml-2'>
            <p
              className='font-normal text-normal text-center text-[#FB7C51] cursor-pointer hover:underline mr-4'
              onClick={() => {
                authDispatch({
                  type: 'LOGOUT',
                });
                navigate(`/auth/login`, { replace: true });
              }}
            >
              Logout
            </p>
          </div> */}
        </div>
      </div>
      <div className="flex flex-1 py-2 overflow-hidden">
        <div className="flex w-96 bg-FBF8F8 shadow-md rounded-md border border-primary border-opacity-20 min-h-full mx-2">
          <ConversationList />
        </div>
        <div className="flex flex-1 bg-FBF8F8 shadow-md rounded-md border border-primary border-opacity-20 min-h-full mx-2 p-4">
          {conversationRoom ? (
            <ConversationContainer />
          ) : (
            <div className="flex w-full justify-center items-center">
              <p>select a conversation</p>
            </div>
          )}
        </div>
      </div>
      <CreateGroupModal
        createGroupModal={showCreateGroupModal}
        setCreateGroupModal={setShowCreateGroupModal}
      />
    </div>
  );
}
