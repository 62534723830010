import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Modal, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';
import { AuthContext } from 'context/authContext';

import FileUpload from 'components/fileUpload';
import useAddEducationDetails from '../hooks/useAddEducationDetails';
import useUpdateEmployeeEducationDetails from '../hooks/useUpdateEducationDetails';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';

import SecondaryButton from 'components/secondaryButton';

export default function EditEducationModal({
  isModalOpen,
  setIsModalOpen,
  educationalDetails,
}) {
  const { authState } = useContext(AuthContext);
  const [iscloseModalTrue, setCloseModalTrue] = useState(false);

  const defaultValues = {
    instituition: '',
    degree: '',
    from_date: null,
    to_date: null,
    certificate: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    reset,
    setError,
  } = useForm({
    defaultValues,mode:"onChange"
  });

  // handle modal close
  const closeModal = () => {
    setIsModalOpen(false);
    reset();
  };

  //   custom title for modal
  const customTitle = () => {
    return (
      <>
        <div className='relative flex justify-center items-center text-xl'>
          <LeftArrowIcon
            onClick={closeModal}
            className='w-4 absolute left-0 cursor-pointer'
          />
          <p>{educationalDetails ? 'Edit' : 'Add'} details</p>
        </div>
        <p className='text-left mt-4 text-black'>Education</p>
      </>
    );
  };

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const addEducationDetailsMutation = useAddEducationDetails(
    closeModal,
    iscloseModalTrue,
    reset
  );
  const updateEducationDetails = useUpdateEmployeeEducationDetails(
    closeModal,
    iscloseModalTrue,
    defaultValues,
    reset
  );

  // handing form submit
  const onSubmit = (data) => {
    const formData = new FormData();
    if (educationalDetails?.certificate !== data?.certificate) {
      formData.append('certificate', data?.certificate[0]);
    }
    formData.append('degree', data?.degree);
    formData.append('from_date', dayjs(data?.from_date).format('YYYY-MM-DD'));
    formData.append('instituition', data?.instituition);
    formData.append('to_date', dayjs(data?.to_date).format('YYYY-MM-DD'));
    formData.append('employee_id', authState?.employee_id);
    if (data?.field_id) {
      formData.append('id', data?.field_id);
      updateEducationDetails.mutate(formData);
    } else {
      addEducationDetailsMutation.mutate(formData);
    }
  };

  const watchFromDate = watch('from_date');
  const watchToDate = watch('to_date');

  // reset form for existing data
  useEffect(() => {
    if (educationalDetails) {
      reset({
        instituition: educationalDetails?.instituition,
        degree: educationalDetails?.degree,
        from_date: new Date(educationalDetails?.from_date),
        to_date: new Date(educationalDetails?.to_date),
        field_id: educationalDetails?.id,
        certificate: educationalDetails?.certificate,
      });
    } else {
      reset({
        instituition: '',
        degree: '',
        from_date: null,
        to_date: null,
        certificate: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationalDetails]);

  useEffect(() => {
    if (watchFromDate && watchToDate) {
      if (dayjs(watchToDate).diff(watchFromDate, 'day') < 1) {
        setValue('to_date', null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFromDate, watchToDate]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-8',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-wrap '>
            <div className='w-1/2 py-2 pr-4 '>
              <Controller
                control={control}
                name='instituition'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Name of the institution*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('instituition')}
            </div>
            <div className='w-1/2 py-2 pl-4 '>
              <Controller
                control={control}
                name='degree'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Degree and Subject*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('degree')}
            </div>
            <div className='w-1/2 py-2 pr-4 '>
              <Controller
                control={control}
                name='from_date'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label='From*'
                    size='md'
                    rightSection={<CalenderIcon className='w-5' />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                      yearPickerControlActive: 'text-white bg-primary',
                      monthPickerControlActive: 'text-white bg-primary',
                    }}
                  />
                )}
              />
              {getErrorMessage('from_date')}
            </div>
            <div className='w-1/2 py-2 pl-4 '>
              <Controller
                control={control}
                name='to_date'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label='To*'
                    size='md'
                    rightSection={<CalenderIcon className='w-5' />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    minDate={dayjs(new Date(watchFromDate))
                      .add(0, 'days')
                      .toDate()}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                      yearPickerControlActive: 'text-white bg-primary',
                      monthPickerControlActive: 'text-white bg-primary',
                    }}
                  />
                )}
              />
              {getErrorMessage('from_date')}
            </div>
            <div className='py-2 w-full'>
              <p className='pb-1.5 text-neutralsGrey text-sm'>Certificate*</p>
              <Controller
                control={control}
                name='certificate'
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    multiple={false}
                    setImage={onChange}
                    value={watch('certificate')}
                    ename={"certificate"}
                    setError={setError}
                  />
                )}
              />
              {getErrorMessage('certificate')}
            </div>
          </div>
          <div className='w-full flex justify-center mt-6 space-x-5'>
            <button className='w-28' onClick={() => setCloseModalTrue(true)}>
              <SecondaryButton
                isLoading={
                  (addEducationDetailsMutation?.isLoading ||
                    updateEducationDetails?.isLoading) &&
                  iscloseModalTrue
                }
              >
                Save
              </SecondaryButton>
            </button>
            <button className='w-28' onClick={() => setCloseModalTrue(false)}>
              <PrimaryButton
                isLoading={
                  (addEducationDetailsMutation?.isLoading ||
                    updateEducationDetails?.isLoading) &&
                  !iscloseModalTrue
                }
              >
                Add new
              </PrimaryButton>
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
