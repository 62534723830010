import React, { useState } from 'react';
import { Select } from '@mantine/core';
import { Pie } from '@nivo/pie';

import useGetLeavesData from '../hooks/useGetLeavesData';

// import { ReactComponent as AttendanceImg } from 'assets/images/attendance.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import noLeavesAllowedIlls from 'assets/images/noLeavesAllowedIlls.png';

export default function TotalLeaveInfo() {
  const [filterValue, setFilterValue] = useState();
  const [yearFilterValue, setYearFilterValue] = useState(
    new Date().getFullYear()
  );

  const getTotalEmployeeLeavesData = useGetLeavesData(
    filterValue,
    yearFilterValue
  );

  function presentDaysCal(a, b) {
    return a < b ? a : a - b;
  }

  // calculating years
  let minYear = getTotalEmployeeLeavesData?.data?.years[1];
  let maxYear = getTotalEmployeeLeavesData?.data?.years[0];
  const appliedYears = [];
  if (minYear === maxYear) {
    appliedYears.push({ value: minYear, label: minYear });
  } else {
    for (let i = minYear; i <= maxYear; i++) {
      appliedYears.push({ value: minYear, label: minYear });
      minYear++;
    }
  }
  //calculating months
  let maxMonth = getTotalEmployeeLeavesData?.data?.max_month;

  let monthNames = [];
  const months = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];
  if (yearFilterValue) {
    if (yearFilterValue === maxYear) {
      for (let i = 0; i < maxMonth; i++) {
        monthNames.push(months[i]);
      }
    } else {
      monthNames = months;
    }
  }
  // console.log('Valid Months', monthNames);

  const data = [
    {
      id: 'Days on leave',
      label: 'Days on leave',
      value: getTotalEmployeeLeavesData?.data?.data?.total_leaves,
      color: '#FFB9A1',
    },
    {
      id: 'Days present',
      label: 'Days present',
      value: presentDaysCal(
        getTotalEmployeeLeavesData?.data?.data?.total_days_till_now,
        getTotalEmployeeLeavesData?.data?.data?.total_leaves
      ),

      color: '#FB0C51',
    },
  ];

  return (
    <>
      {getTotalEmployeeLeavesData?.data === undefined ? (
        <div className='bg-white shadow-four rounded-[10px] py-3 px-9 relative min-h-[280px]'>
          <p className='text-18 font-semibold text-222222'>
            Total days on leave
          </p>
          <div
            className='min-h-[220px] w-full bg-no-repeat flex items-center justify-center'
            style={{
              backgroundImage: 'URL(' + noLeavesAllowedIlls + ')',
              backgroundSize: '100% 50%',
              backgroundPosition: 'center',
            }}
          >
            <p className='text-gray3 pl-24'>No active leave modules to show</p>
          </div>
        </div>
      ) : (
        <div className='bg-white shadow-four rounded-[10px] py-3 px-9 relative min-h-[280px]'>
          <div className='flex justify-between items-center'>
            <p className='text-18 font-semibold text-222222'>
              Total days on leave
            </p>
            <Select
              placeholder='Month'
              icon={<FilterIcon />}
              rightSection={<DownArrow />}
              styles={{
                rightSection: { pointerEvents: 'none' },
              }}
              value={filterValue}
              onChange={setFilterValue}
              data={monthNames}
              classNames={{
                wrapper: 'border-primary border-b',
                input: 'w-40 border-none',
              }}
            />
            <Select
              placeholder='Year'
              icon={<FilterIcon />}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              rightSection={<DownArrow />}
              onChange={setYearFilterValue}
              clearable
              value={yearFilterValue}
              data={appliedYears}
              classNames={{
                wrapper: 'border-primary border-b',
                input: 'w-40 bg-light-bg border-none',
              }}
            />
          </div>

          <div className='flex items-center'>
            <div>
              <Pie
                width={400}
                height={220}
                data={data}
                margin={{
                  top: 40,
                  right: 80,
                  bottom: 20,
                  left: 80,
                }}
                innerRadius={0.5}
                // padAngle={0.7}
                arcLinkLabel={function (e) {
                  return e.id + ' (' + e.value + ')';
                }}
                arcLinkLabelsDiagonalLength={0}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                animate={true}
                isInteractive={true}
                motionStiffness={90}
                motionDamping={15}
                enableArcLabels={false}
                theme={{
                  tooltip: {
                    container: {
                      fontSize: '13px',
                    },
                  },
                  labels: {
                    text: { color: '#555' },
                  },
                }}
              />
            </div>
            <div className='pl-5'>
              <p className='text-gray3 leading-1'>
                Leave balance (for the month)
              </p>
              <p className='text-primary font-bold text-4xl pt-3'>
                {getTotalEmployeeLeavesData?.data?.data
                  ?.total_leaves_this_month -
                  getTotalEmployeeLeavesData?.data?.data?.total_leaves}
                /
                {
                  getTotalEmployeeLeavesData?.data?.data
                    ?.total_leaves_this_month
                }
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
