import React, { useContext, useState } from 'react';
import { Avatar, Tooltip, Popover } from '@mantine/core';
import { Link } from 'react-router-dom';
import { AuthContext } from 'context/authContext';

import { ReactComponent as DotsMenuIcon } from 'assets/icons/dotsMenu2.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon2.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobileIcon2.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mailIcon2.svg';
import { ReactComponent as EmployeesIcon } from 'assets/icons/employeesIcon.svg';
import ChangeSuperAdminModal from './changeSuperAdminModal';
import AddOrganisationModal from './addOrganisation';

export default function OrganisationCard({ item }) {
  const [opened, setOpened] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyDetails, setCompanyDetails] = useState('');
  const [isOrganisationEditModalOpen, setIsOrganisationEditModalOpen] =
    useState(false);
  const { authState } = useContext(AuthContext);

  return (
    <div className='border border-8B8B8B border-opacity-20 rounded-2xl shadow-xl relative'>
      <span
        className={`${
          item?.company__user__is_super_admin ? 'bg-[#FB0C51]' : 'bg-warning'
        } w-1.5 h-40 top-10  absolute left-0`}
      ></span>
      <div className='pl-6'>
        <div className='flex h-6'>
          {authState?.company_id === item?.company__id ? (
            <span className='w-12 h-6 flex items-center justify-center ml-auto text-white text-xs bg-primary rounded-tr-2xl'>
              You
            </span>
          ) : null}
        </div>
        <div className='flex justify-between items-center -mt-2'>
          <Link
            to={`/app/organisation-settings/admin-profile/${item?.company__id}`}
          >
            <div
              className='flex space-x-3 items-center select-none cursor-pointer'
              // onClick={() => {
              //   setOrganisationId(item?.company__id);
              //   navigate(
              //     `/app/organisation-settings/admin-profile/${item?.company__id}`,
              //     { replace: true }
              //   );
              // }}
            >
              <Avatar
                src={item?.company__company_logo || ''}
                size={52}
                color='red'
                radius={50}
                className='capitalize'
                classNames={{
                  image: 'object-contain',
                }}
              >
                {item?.company__company_name[0]?.toUpperCase()}
              </Avatar>
              <p className='text-333333 font-semibold'>
                {item?.company__company_name}
              </p>
            </div>
          </Link>
          <div className='px-4'>
            <Popover
              opened={opened}
              onClose={() => setOpened(false)}
              target={
                <DotsMenuIcon
                  className='cursor-pointer px-1 w-4'
                  onClick={() => {
                    setCompanyDetails(item);
                    setOpened(true);
                  }}
                />
              }
              width={220}
              position='bottom-end'
            >
              <div className='select-none'>
                <p
                  className='text-gray hover:bg-[#f2f2f2] cursor-pointer py-1'
                  onClick={() => {
                    setOpened(false);
                    setIsOrganisationEditModalOpen(true);
                  }}
                >
                  Edit
                </p>
                {authState?.company_id !== item?.company__id ? (
                  <p
                    className='text-67A1EF hover:bg-[#f2f2f2] cursor-pointer py-1 mt-1.5'
                    onClick={() => {
                      setOpened(false);
                      setIsModalOpen(true);
                    }}
                  >
                    Make as parent company
                  </p>
                ) : null}
              </div>
            </Popover>
          </div>
        </div>
        <div className='pl-4 my-2 space-y-2'>
          <div className=' flex items-center space-x-3 text-gray3'>
            <LocationIcon className='w-4' />
            <p>{item?.company__country}</p>
          </div>
          <div className='flex items-center space-x-3 text-gray3'>
            <MobileIcon className='w-4' />
            <p>{item?.company__mobile_number}</p>
          </div>
          <div className='flex items-center space-x-3 text-gray3'>
            <span className='w-4'>
              <MailIcon className='w-4' />
            </span>
            <p className='w-80 truncate'>{item?.company__user__email}</p>
          </div>
        </div>
        <div className='flex justify-between items-center pr-4 pb-2'>
          <div className='flex space-x-2 items-center'>
            <Avatar
              src={''}
              size={44}
              color='red'
              radius={50}
              className='capitalize'
            >
              {item?.company__user__first_name[0]?.toUpperCase()}
            </Avatar>
            <div>
              <p className='text-md text-[#707070]'>
                {item?.company__user__first_name}
              </p>
              <p className='text-xs text-[#969696]'>Admin</p>
            </div>
          </div>
          <Tooltip
            label={'Employees count'}
            classNames={{
              body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
            }}
            radius='md'
          >
            <div className='flex space-x-1 items-center'>
              <EmployeesIcon className='w-7' />
              <p className='text-gray3'> {item?.emp_count} </p>
            </div>
          </Tooltip>
        </div>
      </div>
      <ChangeSuperAdminModal
        companyDetails={companyDetails}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <AddOrganisationModal
        isModalOpen={isOrganisationEditModalOpen}
        setIsModalOpen={setIsOrganisationEditModalOpen}
        companyDetails={companyDetails}
        formName='Edit'
      />
    </div>
  );
}
