import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import UseGetMeetinInfo from '../hooks/useGetMeetingInfo';
export default function MeetingRoom() {
  const location = useLocation();
  const navigate = useNavigate();
  const parsed = queryString.parse(location.search);
  console.log('parsed:', parsed);
  const meetingInfo = UseGetMeetinInfo(parsed?.room)?.data?.data;

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const jitsiContainerStyle = {
    display: 'block',
    width: '100%',
    height: '88vh',
  };

  let jitsiAPI = null;

  function startConference() {
    try {
      const domain = 'jitsi.aptagrim.com';
      const options = {
        roomName: parsed?.room,
        height: '100%',
        jwt: parsed?.token,
        parentNode: document.getElementById('jitsi-container'),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false,
        },
        configOverwrite: {
          disableSimulcast: false,
          toolbarButtons: [
            'camera',
            'chat',
            'closedcaptions',
            'desktop',
            'download',
            // 'embedmeeting',
            'etherpad',
            // 'feedback',
            'filmstrip',
            'fullscreen',
            'hangup',
            // 'help',
            // 'invite',
            // 'livestreaming',
            'microphone',
            'mute-everyone',
            'mute-video-everyone',
            'participants-pane',
            'profile',
            'raisehand',
            // 'recording',
            // 'security',
            'select-background',
            'settings',
            'shareaudio',
            'sharedvideo',
            'shortcuts',
            'stats',
            'tileview',
            'toggle-camera',
            'videoquality',
            '__end',
          ],
        },
      };

      jitsiAPI = new window.JitsiMeetExternalAPI(domain, options);

      jitsiAPI.addEventListener('readyToClose', handelClose);
    } catch (error) {
      console.error('Failed to load Jitsi API', error);
    }
  }

  const handelClose = () => {
    navigate('/meetingRoom/success');
  };
  useEffect(() => {
    if (meetingInfo?.meeting_status !== 'in_progress') {
      navigate(-1);
    }
  }, [navigate, meetingInfo?.meeting_status]);
  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) {
      startConference();
    } else {
      alert('Jitsi Meet API script not loaded');
    }

    return () => jitsiAPI.dispose?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.JitsiMeetExternalAPI]);

  return (
    <div className='bg-gray-50 min-h-85vh flex items-center justify-center w-full max-w-main-page mx-auto'>
      <div className='flex mx-10 w-full'>
        <div style={containerStyle}>
          <div id='jitsi-container' style={jitsiContainerStyle} />
        </div>
      </div>
    </div>
  );
}
