import React from 'react';
import { Modal, TextInput } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../primaryButton';

import useChangePasswordMutation from './useUpdatePasswordMutation';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/infoIconBlue.svg';

export default function ChangePasswordModal({
  isChangePasswordModalOpen,
  setChangePasswordModalOpen,
}) {
  const defaultValues = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const handleModalClose = () => {
    setChangePasswordModalOpen(false);
    reset();
  };

 
  const changePasswordMutation = useChangePasswordMutation(handleModalClose);

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[550px]'>
        <LeftArrowIcon
          onClick={() => handleModalClose()}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className='text-primary'>Change password</p>
      </div>
    );
  };

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const onSubmit = (data) => {
    delete data?.confirm_password;
    changePasswordMutation.mutate(data);
  };

  return (
    <div>
      <Modal
        opened={isChangePasswordModalOpen}
        onClose={() => handleModalClose()}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size='lg'
        overflow='inside'
        className='px-8'
        classNames={{
          title: 'text-lg font-semibold ',

          body: 'scroller',
        }}
      >
        <div className='w-96 mx-auto'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Controller
                control={control}
                name='old_password'
                rules={{
                  required: 'Required',

                  // validate1: (value) =>
                  //   value === watch('new_password') ||
                  //   '',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Old password'
                    size='sm'
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ',
                    }}
                  />
                )}
              />

              {getErrorMessage('old_password')}
            </div>

            <div className='my-3'>
              <Controller
                control={control}
                name='new_password'
                rules={{
                  required: 'Required',
                  
                  validate: (value) =>
                    value !== watch('old_password') ||
                    'Old and New one is same',

                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/,
                    message: 'should match with requirement pattern',
                  },
                  minLength: {
                    value: 8,
                    message: 'Minimum length is 8',
                  },
                  maxLength: {
                    value: 16,
                    message: 'maximum length is 16',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='New password'
                    size='sm'
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ',
                    }}
                  />
                )}
              />
              {getErrorMessage('new_password')}
            </div>

            <div className=''>
              <Controller
                control={control}
                name='confirm_password'
                rules={{
                  required: 'Required',
                  validate: (value) =>
                    value === watch('new_password') ||
                    'The new passwords do not match',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Confirm password'
                    size='sm'
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ',
                    }}
                  />
                )}
              />
              {getErrorMessage('confirm_password')}
            </div>

            <div className='flex mt-2'>
              <span className='w-4 mr-1'>
                <InfoIcon className='w-3' />
              </span>
              <p className='text-gray3 text-sm '>
                Should contain at least one lowercase, one uppercase, one
                numeric, one special character and minimum 8-16 characters.
              </p>
            </div>
            <div className='flex justify-center'>
              <button className='w-28 mt-6'>
                <PrimaryButton isLoading={changePasswordMutation?.isLoading}>
                  Save
                </PrimaryButton>
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
