import React from 'react';
import { Tabs } from '@mantine/core';

import PerformanceReview from './performanceReview';
import Appraisal from './appraisal';

export default function Performance() {
  return (
    <div className='shadow-lg border border-8B8B8B border-opacity-10 rounded-xl py-4 px-6 mb-8 bg-white'>
      <Tabs
        tabPadding={21}
        classNames={{
          tabsListWrapper: 'border-0',
          tabActive: 'border-2 border-primary font-semibold text-18',
          tabControl: 'text-A8A8A8 leading-5 px-0 mr-6',
          tabLabel: 'text-18 text-gray',
        }}
      >
        <Tabs.Tab label='Performance review'>
          <PerformanceReview />
        </Tabs.Tab>
        <Tabs.Tab label='Appraisal' disabled className='opacity-50'>
          <Appraisal />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}
