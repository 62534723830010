import { Avatar, Checkbox, Table } from '@mantine/core';

import { ReactComponent as RightMoveIcon } from 'assets/icons/rightcircleIcon.svg';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import SpecificEmployeeModal from '../components/specificemployeemodal';
import { AuthContext } from 'context/authContext';

import getSymbolFromCurrency from 'currency-symbol-map';

const ActiveEmployeePayrollTab = ({
  employeeDetails,
  employeeCount,
  setEmployeeCount,
  employessNetSalary,
  setEmployessNetSalary,
  setEmployeeIds,
  employeeIds,
}) => {
  const { authState } = useContext(AuthContext);
  const currencyIcon = getSymbolFromCurrency(authState?.currency);
  const [singleEmployeeModalOpened, setSingleEmployeeModalOpened] =
    useState(false);

  const { register, watch, setValue } = useForm();

  const [payrollIdemp, setPayrollIdemp] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [selectAll, setselectAll] = useState(false);

  useEffect(() => {
    if (selectAll) {
      setEmployeeCount(0);
      setEmployessNetSalary(0);

      const emmm = employeeDetails?.employees.map((em) => {
        return em.id;
      });

      // let totalAmount = 0

      // const employeenetSalary =employeeDetails?.employees.map((em) => )

      const totalData = { employeetotalpay: 0 };
      // eslint-disable-next-line
      const totalAmount = employeeDetails?.employees.forEach((n1) => {
        totalData.employeetotalpay += n1.net_pay ?? 0;
      });

      setEmployeeCount(emmm.length);
      setEmployessNetSalary(totalData.employeetotalpay);
      setEmployeeIds(emmm);
    } else {
      setEmployeeCount(0);
      setEmployessNetSalary(0);
      setEmployessNetSalary(0);
      // eslint-disable-next-line
      const emmm = employeeDetails?.employees.map((em) => {
        setValue(`checkbox.${em?.employee_id}`, false);
      });
      setEmployeeIds([]);
    }
    // eslint-disable-next-line
  }, [selectAll]);

  const rows = employeeDetails?.employees.map((eachEmployee) => (
    <>
      <tr className="text-[#848383]">
        {employeeDetails?.payroll__is_applicable_for_all ? null : (
          <td>
            <Checkbox
              {...register(`checkbox.${eachEmployee?.employee_id}`)}
              size="xs"
              className="pl-1"
              disabled={selectAll}
              checked={
                watch(`checkbox.${eachEmployee?.employee_id}`) || selectAll
              }
              onClick={() => {
                // !watch(`checkbox.${eachEmployee?.employee_id}`)
                //   ? setEmployeeCount(employeeCount + 1)
                //   : setEmployeeCount(employeeCount - 1);

                if (!watch(`checkbox.${eachEmployee?.employee_id}`)) {
                  setEmployeeCount(employeeCount + 1);
                  setEmployessNetSalary(
                    employessNetSalary + eachEmployee.net_pay
                  );
                  setEmployeeIds([...employeeIds, eachEmployee?.id]);
                } else {
                  setEmployeeCount(employeeCount - 1);
                  setEmployessNetSalary(
                    employessNetSalary - eachEmployee.net_pay
                  );
                  setEmployeeIds(
                    employeeIds.filter((item) => item !== eachEmployee?.id)
                  );
                }
              }}
            />
          </td>
        )}
        <td>
          <div className="flex items-center pl-5">
            <Avatar
              radius="lg"
              color="red"
              size="35px"
              src={eachEmployee?.profile_picture}
            >
              {eachEmployee?.user__first_name[0]?.toUpperCase()}
            </Avatar>
            <div className="ml-3">
              <p className="">{eachEmployee?.user__first_name}</p>
              <p className="">{eachEmployee?.employee_id}</p>
            </div>
          </div>
        </td>
        <td className="text-center">{eachEmployee?.total_paid_days}</td>
        <td className="text-center">
          {' '}
          {currencyIcon}
          {parseFloat(eachEmployee?.gross_salary).toFixed(2)}
        </td>
        <td className="text-center">
          {currencyIcon}
          {eachEmployee?.total_allowance}
        </td>
        <td className="text-center">
          {currencyIcon}
          {eachEmployee?.total_deduction}
        </td>
        <td className="text-center">
          {currencyIcon}
          {parseFloat(eachEmployee?.net_pay).toFixed(2)}
        </td>
        <td>
          <div className="flex justify-center items-center">
            <RightMoveIcon
              className="cursor-pointer h-6 w-6"
              onClick={() => {
                setSingleEmployeeModalOpened(true);
                setPayrollIdemp(employeeDetails?.payroll__id);
                setEmployeeId(eachEmployee?.id);
              }}
            />
          </div>
        </td>
      </tr>
    </>
  ));

  return (
    <>
      <div className="mt-2">
        <Table verticalSpacing="xs" highlightOnHover>
          {/* <ScrollArea style={{ maxHeight: 400, minHeight: 100 }}> */}
          <thead>
            <tr>
              {employeeDetails?.payroll__is_applicable_for_all ? null : (
                <th
                  className="bg-[#FFE8E1] rounded-tl-xl"
                  style={{ textAlign: 'center' }}
                >
                  <Checkbox
                    {...register('select_all')}
                    size="xs"
                    className="pl-1"
                    onChange={() => setselectAll(!selectAll)}
                  />
                </th>
              )}

              <th
                className={
                  employeeDetails?.payroll__is_applicable_for_all
                    ? 'bg-[#FFE8E1] rounded-tl-xl'
                    : 'bg-[#FFE8E1] '
                }
                style={{ textAlign: 'center' }}
              >
                Name of the employee
              </th>
              <th className="bg-[#FFE8E1] " style={{ textAlign: 'center' }}>
                Total paid days
              </th>
              <th className="bg-[#FFE8E1] " style={{ textAlign: 'center' }}>
                Gross pay
              </th>
              <th className="bg-[#FFE8E1] " style={{ textAlign: 'center' }}>
                Allowance
              </th>
              <th className="bg-[#FFE8E1] " style={{ textAlign: 'center' }}>
                Deducation
              </th>
              <th className="bg-[#FFE8E1] " style={{ textAlign: 'center' }}>
                Net pay
              </th>
              <th
                className="bg-[#FFE8E1] rounded-tr-xl"
                style={{ textAlign: 'center' }}
              >
                <p>
                  One-time earnings <br />
                  /deducation
                </p>
              </th>
            </tr>
          </thead>

          <tbody className="overflow-y-scroll w-full max-h-20 border-none">
            {rows}
          </tbody>
          {/* </ScrollArea> */}
        </Table>
      </div>
      <SpecificEmployeeModal
        singleEmployeeModalOpened={singleEmployeeModalOpened}
        setSingleEmployeeModalOpened={setSingleEmployeeModalOpened}
        payrollIdemp={payrollIdemp}
        setPayrollIdemp={setPayrollIdemp}
        employeeId={employeeId}
        setEmployeeId={setEmployeeId}
      />
    </>
  );
};

export default ActiveEmployeePayrollTab;
