import Header from 'components/Header';
import { AuthContext } from 'context/authContext';
import React, { useContext, useEffect, useState } from 'react';
import ActivityTracker from './components/activityTracker';
import Attendance from './components/attendance';
import FirstLoginModal from './components/firstLoginModal';
import Leaves from './components/leaves';
import Meetings from './components/meetings';
import UpcomingEvents from './components/upcomingEvents';

export default function Productivity() {
  const [isFirstLoginModalOpen, setIsFirstLoginModalOpen] = useState(false);

  const { authState } = useContext(AuthContext);

  useEffect(() => {
    setTimeout(() => {
      setIsFirstLoginModalOpen(authState?.first_login);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='px-5'>
      <div className='flex justify-between items-center mt-6'>
        <p className='text-xl text-A8A8A8'>
          Dashboard &#60;
          <span className='text-gray font-semibold'> Productivity</span>
        </p>
        <Header />
      </div>

      <div className='mt-8'>
        <div className='mb-6'>
          <p className='text-22 font-semibold text-[#4E4E4E]'>
            Productivity Dashboard
          </p>
        </div>

        <div className='mb-7 space-y-5'>
          <div className='grid grid-cols-2 gap-x-5'>
            <Attendance />
            <ActivityTracker />
          </div>

          <div className='grid grid-cols-2 gap-x-5'>
            <Leaves />
            <UpcomingEvents />
          </div>

          <Meetings />
        </div>
      </div>
      <FirstLoginModal
        isFirstLoginModalOpen={isFirstLoginModalOpen}
        setIsFirstLoginModalOpen={setIsFirstLoginModalOpen}
      />
    </div>
  );
}
