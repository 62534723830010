import React from 'react';
import { Loader } from '@mantine/core';

export default function SecondaryButton({ children, isLoading }) {
  if (isLoading) {
    return (
      <div className='border-primary border text-white rounded-md  py-2 px-4 h-10 font-semibold flex items-center justify-center'>
        <Loader color={'#FB7C51'} size={'sm'} />
      </div>
    );
  }
  return (
    <div className='flex items-center justify-center text-sm font-semibold py-2 px-4 border border-primary text-primary rounded-md cursor-pointer'>
      {children}
    </div>
    
  );
}
