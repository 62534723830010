import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import AuthProvider from './context/authContext';
import ChatProvider from 'context/chatContext';
import { MantineProvider } from '@mantine/core';

import AppRoutes from './routes/routes';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  });

  return (
    <>
      <AuthProvider>
        <ChatProvider>
          <QueryClientProvider client={queryClient}>
            <MantineProvider
              theme={{
                colors: {
                  brand: [
                    '#FED0C1',
                    '#FDC6B4',
                    '#FDBDA8',
                    '#FDB49B',
                    '#FDAA8F',
                    '#FCA182',
                    '#FC9776',
                    '#FC8E69',
                    '#FC845D',
                    '#FB7C51',
                  ],
                },
                primaryColor: 'brand',
                fontFamily: 'Lato, sans-serif',
              }}
            >
              <Toaster
                position='top-center'
                reverseOrder={false}
                containerStyle={{
                  position: 'fixed',
                }}
                toastOptions={{
                  duration: 3000,
                  success: {
                    style: {
                      maxWidth: '384px',
                    },
                  },
                  error: {
                    style: {
                      maxWidth: '384px',
                    },
                  },
                }}
              />
              <AppRoutes />
            </MantineProvider>
          </QueryClientProvider>
        </ChatProvider>
      </AuthProvider>
    </>
  );
}

export default App;
