import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetDepartmentsForEmployees(company_id) {
  return useQuery(['get-employees-departments', company_id], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.DEPARTMENTS_FOR_EMPLOYEE, {
        is_active: 'True',
        req_company_id: company_id,
      })
    );
    return res.data;
  });
}
