import Header from "components/Header";
import { Tabs, Input, Select } from "@mantine/core";

import { ReactComponent as SearchIcon } from "../../../../assets/icons/searchIcon.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filterIcon.svg";
import { ReactComponent as DownArrow } from "../../../../assets/icons/downArrow.svg";
import PrimaryButton from "components/primaryButton";
import ActiveReports from "./components/activeReports";
import AppraisalReports from "./components/apparisalreports";
import useGetperformancereport from "./hooks/useGetperformancereports";
import useGetApparisal from "./hooks/useGetapparisal";

import { useState } from "react";
import SelectEmployeeModal from "./components/selectEmployeeModal";

import AppraisalSelectEmployeeModal from "./components/apparisalselectemployeemodal";

const Performance = () => {
  const [selectEmployeeModal, setSelectEmployeeModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [apprasialSelectEmployeeModal, setApprasialSelectEmployeeModal] =
    useState(false);

  const [performanceSearch, setPerformanceSearch] = useState(null);
  const [apparisalSearch, setApparisalSearch] = useState(null);
  const [performanceDraftFilter, setPerformanceDraftFilter] = useState("False");
  const [apparisalDraftFilter, setApparisalDraftFilter] = useState("False");

  const [performanceYearFilter, setPerformanceYearFilter] = useState(null);
  const [apparisalYearFilter, setApparisalYearFilter] = useState(null);
  const performanceReports = useGetperformancereport();
  let minYear = performanceReports?.data?.years?.length
    ? performanceReports?.data?.years[1]
    : null;
  let maxYear = performanceReports?.data?.years?.length
    ? performanceReports?.data?.years[0]
    : null;
  const performanceYears = [];
  if (minYear === maxYear) {
    performanceYears.push({ value: minYear, label: minYear });
  } else {
    for (let i = minYear; i <= maxYear; i++) {
      performanceYears.push({ value: minYear, label: minYear });
      minYear++;
    }
  }

  const appraisalReports = useGetApparisal();
  let minYearA = appraisalReports?.data?.years[1];
  let maxYearA = appraisalReports?.data?.years[0];
  const ApraisalYears = [];
  if (minYear === maxYear) {
    ApraisalYears.push({ value: minYearA, label: minYearA });
  } else {
    for (let i = minYearA; i <= maxYearA; i++) {
      ApraisalYears.push({ value: minYearA, label: minYearA });
      minYearA++;
    }
  }

  return (
    <>
      <div className="px-5">
        <div className="flex justify-between items-center mt-6">
          <p className="text-xl text-A8A8A8">
            Team &#60;{" "}
            <span className="text-gray font-semibold">Performance</span>
          </p>
          <Header />
        </div>
        <div className="mt-8 relative">
          <div>
            <Tabs
              tabPadding={21}
              onTabChange={setActiveTab}
              classNames={{
                tabsListWrapper: "border-0",
                tabActive: "border-2 border-primary font-semibold text-18",
                tabControl: "text-A8A8A8 leading-5 px-0 mr-4",
                tabLabel: "text-18 text-gray",
              }}
            >
              <Tabs.Tab label="Performance reports">
                <ActiveReports
                  performanceSearch={performanceSearch}
                  performanceDraftFilter={performanceDraftFilter}
                  performanceYearFilter={performanceYearFilter}
                />
              </Tabs.Tab>
              <Tabs.Tab label="Appraisal">
                <AppraisalReports
                  apparisalSearch={apparisalSearch}
                  apparisalDraftFilter={apparisalDraftFilter}
                  apparisalYearFilter={apparisalYearFilter}
                />
              </Tabs.Tab>
            </Tabs>
          </div>
          {/* search and filter */}
          <div className="absolute top-2 right-0">
            {activeTab === 0 ? (
              <div className="flex items-center space-x-4">
                <Input
                  icon={<SearchIcon />}
                  placeholder="Search"
                  value={performanceSearch}
                  onChange={(e) => setPerformanceSearch(e.target.value)}
                  classNames={{
                    wrapper: "border-primary border-b",
                    input: "w-36 bg-light-bg border-none",
                  }}
                />
                <Select
                  // placeholder='Active reports'
                  icon={<FilterIcon />}
                  rightSection={<DownArrow />}
                  value={performanceDraftFilter}
                  onChange={setPerformanceDraftFilter}
                  data={[
                    { label: "Active", value: "False" },
                    { label: "Draft", value: "True" },
                  ]}
                  classNames={{
                    wrapper: "border-primary border-b",
                    input: "w-36 bg-light-bg border-none",
                  }}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                />
                <Select
                  placeholder="Year"
                  icon={<FilterIcon />}
                  rightSection={<DownArrow />}
                  // inputFormat='MM/YYYY'
                  // labelFormat='MM/YYYY'
                  data={
                    performanceYears[0]?.value
                      ? performanceYears
                      : [
                          {
                            value: "no data",
                            label: "No data",
                            disabled: true,
                          },
                        ]
                  }
                  clearable
                  value={performanceYearFilter}
                  onChange={setPerformanceYearFilter}
                  classNames={{
                    wrapper: "border-primary border-b",
                    input: "w-36 bg-light-bg border-none",
                  }}
                  styles={{
                    rightSection: { pointerEvents: "none" },
                    monthPickerControlActive: { color: "red" },
                    yearPickerControlActive: {
                      color: "red",
                      backgroundcolor: "#ffffff",
                    },
                  }}
                />
                {activeTab === 0 ? (
                  <button onClick={() => setSelectEmployeeModal(true)}>
                    <PrimaryButton>+ Create new</PrimaryButton>
                  </button>
                ) : (
                  <button onClick={() => setApprasialSelectEmployeeModal(true)}>
                    <PrimaryButton>+ Add new</PrimaryButton>
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Input
                  icon={<SearchIcon />}
                  placeholder="Search"
                  value={apparisalSearch}
                  onChange={(e) => {
                    setApparisalSearch(e.target.value);
                  }}
                  classNames={{
                    wrapper: "border-primary border-b",
                    input: "w-36 bg-light-bg border-none",
                  }}
                />
                <Select
                  // placeholder='Active reports'
                  icon={<FilterIcon />}
                  rightSection={<DownArrow />}
                  value={apparisalDraftFilter}
                  onChange={setApparisalDraftFilter}
                  data={[
                    { label: "Active", value: "False" },
                    { label: "Draft", value: "True" },
                  ]}
                  classNames={{
                    wrapper: "border-primary border-b",
                    input: "w-36 bg-light-bg border-none",
                  }}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                />
                <Select
                  placeholder="Year"
                  icon={<FilterIcon />}
                  rightSection={<DownArrow />}
                  // inputFormat='MM/YYYY'
                  // labelFormat='MM/YYYY'
                  value={apparisalYearFilter}
                  data={
                    ApraisalYears[0]?.value
                      ? ApraisalYears
                      : [
                          {
                            value: "no data",
                            label: "No data",
                            disabled: true,
                          },
                        ]
                  }
                  onChange={setApparisalYearFilter}
                  clearable
                  classNames={{
                    wrapper: "border-primary border-b",
                    input: "w-36 bg-light-bg border-none",
                  }}
                  styles={{
                    rightSection: { pointerEvents: "none" },
                    monthPickerControlActive: { color: "red" },
                    yearPickerControlActive: {
                      color: "red",
                      backgroundcolor: "#ffffff",
                    },
                  }}
                />
                {activeTab === 0 ? (
                  <button onClick={() => setSelectEmployeeModal(true)}>
                    <PrimaryButton>+ Create new</PrimaryButton>
                  </button>
                ) : (
                  <button onClick={() => setApprasialSelectEmployeeModal(true)}>
                    <PrimaryButton>+ Add new</PrimaryButton>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <SelectEmployeeModal
        setSelectEmployeeModal={setSelectEmployeeModal}
        selectEmployeeModal={selectEmployeeModal}
      />
      <AppraisalSelectEmployeeModal
        setApprasialSelectEmployeeModal={setApprasialSelectEmployeeModal}
        apprasialSelectEmployeeModal={apprasialSelectEmployeeModal}
      />
    </>
  );
};

export default Performance;
