import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetgradeData() {
  const { company_id } = useParams();
  return useQuery(['grade_data_from_setting_in_organisition'], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.SETTINGS_GRADES, {
        req_company_id: company_id,
        is_active: 'True',
      })
    );

    return response.data;
  });
}
