import PrimaryButton from 'components/primaryButton';
import dayjs from 'dayjs';
import { ReactComponent as InfoIlls } from 'assets/images/infoIlls.svg';
import { ReactComponent as LinkExpired } from 'assets/images/LinkExpired.svg';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import UseGetMeetinInfo from '../hooks/useGetMeetingInfo';

export default function MeetingInfo() {
  const location = useLocation();
  const meetingData = queryString.parse(location.search);
  console.log(meetingData);

  const meetingInfo = UseGetMeetinInfo(meetingData?.room)?.data?.data;
  return (
    <div className='bg-[#F9FAFB] mx-auto w-full  min-h-[85vh] h-full flex items-center justify-center '>
      {meetingInfo?.meeting_status === 'finished' ? (
        <div className='flex w-full h-full  justify-center items-center flex-col gap-y-6'>
          <LinkExpired className='w-20 h-20 mx-auto' />
          <p className='w-32rem text-xl font-medium text-center mt-4'>
            Oops! you missed the meeting
            <br />
            <span className='text-primary'> Link expired</span>
          </p>
        </div>
      ) : (
        <div className='flex w-full h-full  justify-center items-center flex-col gap-y-4'>
          <div>
            <p className='w-32rem text-primary text-xl font-semibold text-center'>{`${meetingInfo?.meeting_title}`}</p>
            <p className='w-28rem text-lg font-medium text-center'>
              has been scheduled for <br />
              <span className='text-primary text-xl'>
                {' '}
                {dayjs(meetingInfo?.for_date)
                  .locale('en')
                  .format('ddd MMM D YYYY')}
                {' , '}
                {dayjs(`2000-01-01 ${meetingInfo?.from_time}`).format(
                  'hh:mm a'
                )}{' '}
                {` - `}
                {dayjs(`2000-01-01 ${meetingInfo?.to_time}`).format('hh:mm a')}
              </span>
            </p>
          </div>
          {meetingInfo?.meeting_status === 'in_progress' ? (
            <PrimaryButton>
              {meetingData?.token ? (
                <Link
                  to={`/meetingRoom/meeting?room=${meetingData?.room}&token=${meetingData?.token}`}
                >
                  Join Meeting
                </Link>
              ) : (
                <Link to={`/meetingRoom/meeting?room=${meetingData?.room}`}>
                  Join Meeting
                </Link>
              )}
            </PrimaryButton>
          ) : (
            <div className='bg-primary opacity-60 text-white rounded-md py-2 px-4 cursor-not-allowed font-medium flex items-center justify-center hover:bg-[#e4643a]'>
              Join Meeting
            </div>
          )}
          <div className='flex justify-center items-center gap-x-2'>
            <InfoIlls className='w-4 h-4' />
            <p className='text-gray3 text-sm '>
              Please ensure your microphone and webcam are enabled.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
