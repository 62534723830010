import NoPageFound from 'pages/common/pageNotFound';

import MeetingRoom from './Pages/MeetingRoom';
import MeetingRoomHeader from './Pages/components/MeetingRoomHeader';
import { Route, Routes } from 'react-router-dom';
import MeetingSuccess from './Pages/MeetingSuccess';
import MeetingInfo from './Pages/MeetingInfo';

export default function Meetingroom() {
  return (
    <div>
      <Routes>
        <Route element={<MeetingRoomHeader />}>
          <Route path='/' element={<MeetingInfo />} />

          <Route path='/meeting' element={<MeetingRoom />} />
          <Route path='/success' element={<MeetingSuccess />} />

          {/* empty page when route not found */}
          <Route path='*' element={<NoPageFound />} />
        </Route>
      </Routes>
    </div>
  );
}
