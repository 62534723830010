import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

import { useParams } from 'react-router-dom';

export default function useGetEmployeesForOrganisation(status) {
  const { company_id } = useParams();
  return useQuery(['admin-employee-details', status], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.LIST_OF_EMPLOYEES, {
        req_company_id: company_id,
        user__is_active: status,
      })
    );
    return response?.data;
  });
}
