import { Tabs } from '@mantine/core';
import React, { useState } from 'react';
// import Employeedesignations from './employeedesignations';
import EmploymentGrades from './employmentGrades';
import EmploymentTypes from './employmentTypes';
import WorkingHours from './workingHours';

export default function TabsEmployeeDetails({ organisation }) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className='px-6' key={organisation.company__id}>
      <Tabs
        active={activeTab}
        onTabChange={setActiveTab}
        tabPadding={21}
        classNames={{
          tabsListWrapper: 'border-0',
          tabActive: 'border-2 border-primary font-semibold px-0',
          tabControl: 'text-A8A8A8 leading-5 px-0 mr-8',
          tabLabel: 'text-[#333333] text-base',
        }}
      >
        <Tabs.Tab label='Work timings'>
          <WorkingHours organisation={organisation} />
        </Tabs.Tab>
        <Tabs.Tab label='Employment types'>
          <EmploymentTypes organisation={organisation} />
        </Tabs.Tab>
        <Tabs.Tab label='Employment grades'>
          <EmploymentGrades organisation={organisation} />
        </Tabs.Tab>
        {/* <Tabs.Tab label='Employment designation'>
              <Employeedesignations  organisation={organisation} />
            </Tabs.Tab> */}
      </Tabs>
    </div>
  );
}
