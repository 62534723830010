import { Switch } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import useCompanyDetails from '../hooks/useCompanyDetails';

import { ReactComponent as MinusIcon } from 'assets/icons/minusIcon.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clockIcon.svg';
// import { ErrorToast } from 'services/toasterService';
import useEditCompanyDetails from 'pages/settings/hooks/useEditCompanyDetails';

export default function WorkingHours({ organisation }) {
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [debouncedForFromTime] = useDebouncedValue(fromTime, 1000);
  const [debouncedForToTime] = useDebouncedValue(toTime, 1000);

  // Working Timings

  const getAllCompanyDetails = useCompanyDetails(organisation?.company__id);
  const companyDetails = getAllCompanyDetails?.data?.data?.[0];
  const editCompanyDetailsMutation = useEditCompanyDetails();

  // updating working hours
  useEffect(() => {
    if (debouncedForFromTime && debouncedForToTime) {
      // if (
      //   debouncedForFromTime < debouncedForToTime &&
      //   (debouncedForFromTime !== companyDetails?.from_time ||
      //     debouncedForToTime !== companyDetails?.to_time)
      // ) {
      if (
        companyDetails?.from_time?.slice(0, 5) !== debouncedForFromTime ||
        companyDetails?.to_time?.slice(0, 5) !== debouncedForToTime
      ) {
        editCompanyDetailsMutation.mutate({
          company_id: companyDetails?.id,
          from_time: debouncedForFromTime,
          to_time: debouncedForToTime,
        });
      }
      // } else {
      //   ErrorToast({ text: 'Please select Valid time' });
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedForFromTime, debouncedForToTime]);
  useEffect(() => {
    setFromTime(companyDetails?.from_time?.slice(0, 5));
    setToTime(companyDetails?.to_time?.slice(0, 5));
  }, [companyDetails]);

  // use below for validation

  // useEffect(() => {
  //   if (debouncedForFromTime && debouncedForToTime) {
  //     if (
  //       debouncedForFromTime < debouncedForToTime &&
  //       (debouncedForFromTime !== companyDetails?.from_time ||
  //         debouncedForToTime !== companyDetails?.to_time)
  //     ) {
  //       if (
  //         companyDetails?.from_time?.slice(0, 5) !== debouncedForFromTime ||
  //         companyDetails?.to_time?.slice(0, 5) !== debouncedForToTime
  //       ) {
  //         editCompanyDetailsMutation.mutate({
  //           company_id: companyDetails?.id,
  //           from_time: debouncedForFromTime,
  //           to_time: debouncedForToTime,
  //         });
  //       }
  //     } else {
  //       ErrorToast({ text: 'Please select Valid time' });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedForFromTime, debouncedForToTime]);

  return (
    <div className='mt-6'>
      <div className='flex items-center justify-between'>
        <p className='text-[#434343]'>Working timings are same for all</p>
        <Switch
          size='md'
          checked={companyDetails?.are_timings_same || ''}
          onChange={() => {
            editCompanyDetailsMutation.mutate({
              company_id: companyDetails?.id,
              are_timings_same: !companyDetails?.are_timings_same,
            });
          }}
        />
      </div>
      {/* This needs to visible only when the above "Switch" is ACTIVE */}
      {companyDetails?.are_timings_same && (
        <div className='flex items-center justify-between mt-4 mb-8'>
          <p className='text-[#434343]'>If same, please select the timings</p>
          <div className='space-x-6 flex items-center'>
            <div>
              <p className='text-sm text-gray pl-1'>From</p>
              <div className='relative flex items-center'>
                <input
                  type='time'
                  value={fromTime}
                  onChange={(e) => setFromTime(e.target.value)}
                  className='pr-8 px-2 border-neutralsGrey border h-8 border-opacity-30 rounded-md'
                />
                <ClockIcon className='absolute right-3 ' />
              </div>
            </div>
            <MinusIcon className='mt-5' />
            <div>
              <p className='text-sm text-gray pl-1'>To</p>
              <div className='relative flex items-center'>
                <input
                  type='time'
                  value={toTime}
                  onChange={(e) => setToTime(e.target.value)}
                  className='pr-8 px-2 border-neutralsGrey border h-8 border-opacity-30 rounded-md'
                />
                <ClockIcon className='absolute right-3 ' />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
