import React,{useState} from 'react'
import { Avatar, AvatarsGroup, Popover } from '@mantine/core';

function ReportingManagerPop({employeeDepartment}) {
    const [isPopoverOpened, setPopOverOpened] = useState(false);
    
  return (
    <div  className='flex space-x-3 cursor-pointer justify-center items-center'
    onClick={() => setPopOverOpened(true)}> 
    <AvatarsGroup
    limit={4}
    total={
      employeeDepartment?.reporting_managers
        ?.length
    }
    classNames={{
      truncated: 'bg-FFE8E1',
    }}
  >
    {employeeDepartment?.reporting_managers?.map(
      (reportingManager) => {
        return (
          <Avatar
            key={reportingManager?.id}
            src={
              reportingManager?.profile_picture
            }
            color={'red'}
            radius='xl'
            size={'md'}
            classNames={{
              placeholder: 'bg-FFE8E1',
            }}
          >
            {reportingManager?.user__first_name[0]?.toUpperCase()}
          </Avatar>
        );
      }
    )}
  </AvatarsGroup>  
    <Popover
    opened={isPopoverOpened}
    onClose={() => setPopOverOpened(false)}
    width={260}
    position='bottom'
    placement='center'
    title='	Reporting managers'
    classNames={{
      body: '-mt-3',
      inner:
        'py-2 max-h-48 overflow-y-scroll scroller',
      header:
        'border-b-0 text-accent font-semibold text-primary',
    }}
  >
    {employeeDepartment?.reporting_managers?.map(
      (employee) => {
        return (
          <div
            className='flex space-x-2 pb-4 '
            key={employee?.id}
          >
            <Avatar
              src={employee?.profile_picture}
              color='red'
              radius='xl'
              size={'md'}
              classNames={{
                placeholder: 'text-xl',
              }}
              className='w-10 h-10 capitalize'
            >
              {employee?.user__first_name[0]}
            </Avatar>
            <div className='text-xs'>
              <p className='text-121212'>
                {employee?.user__first_name}
              </p>
              <p className='text-868686'>
                {employee?.designation__name}
              </p>
            </div>
          </div>
        );
      }
    )}
  </Popover>
  </div>

  )
}

export default ReportingManagerPop
