import React from 'react';

import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { useState } from 'react';
import useGetAllDepartments from 'pages/organisationSettings/hooks/useGetAllDepartments';
import DepartmentModal from './departmentModal';
import { Tabs } from '@mantine/core';
import Employeedesignations from '../employeeDetails/components/employeedesignations';
import PrimaryButton from 'components/primaryButton';
import AddDesignationModal from '../employeeDetails/components/addDesignationModal';

function Departments({ company_id }) {
  const [editData, setEditData] = useState(null);

  const [activeTab, setActiveTab] = useState(0);

  const departmentsData = useGetAllDepartments(company_id);

  const [addDepartmentModal, setAddDepartmentModal] = useState(false);
  const [isAddDesignationModalOpen, setDesignationModalOpen] = useState(false);

  return (
    <>
      <div className='mt-5 relative'>
        <div>
          <Tabs
            // active={~~searchParams.get('tab')}
            tabPadding={21}
            // onTabChange={handleTabChange}
            onTabChange={setActiveTab}
            classNames={{
              tabsListWrapper: 'border-0',
              tabActive: 'border-2 border-primary font-semibold text-18',
              tabControl: 'text-A8A8A8 leading-5 px-0 mr-4',
              tabLabel: 'text-18 text-gray',
            }}
          >
            <Tabs.Tab label='Departments'>
              <div className='shadow-xl border border-8B8B8B border-opacity-20 rounded-xl overflow-hidden mb-8 bg-white min-h-[70vh] departments-super-admin'>
                {/* <div className='flex px-10 bg-FFE8E1 h-16 items-center text-333333 font-semibold'>
        <div className='flex w-1/4'>Organisation name</div>
        <div className='flex w-1/4'>Location</div>
        <div className='flex w-2/6'>Departments</div>
        <div className='flex w-1/6 justify-center'>Action</div>
      </div> */}
                {departmentsData?.data?.data?.length === 0 ? (
                  <div className='bg-white h-96 flex flex-col items-center justify-center space-y-10 rounded-3xl'>
                    <div>
                      <p className='text-center mt-2'>No data to show</p>
                    </div>
                  </div>
                ) : (
                  departmentsData?.data?.data?.map((organisation, index) => {
                    return (
                      <div className=' bg-white rounded-t-lg'>
                        <table className='w-full'>
                          <thead className='bg-FFE8E1'>
                            <tr className='text-333333  rounded-t-lg px-6 flex'>
                              <th className='py-5 w-1/4  text-left'>S.No</th>
                              <th className='py-5 w-7/12  text-left'>
                                Department name
                              </th>
                              <th className='py-5 w-1/6 '>Action</th>
                            </tr>
                          </thead>
                        </table>

                        {organisation?.departments.length === 0 ? (
                          <div className='h-96 flex flex-col items-center justify-center space-y-10 rounded-3xl'>
                            <div>
                              <p className='text-center mt-2'>
                                No data to show
                              </p>
                            </div>
                          </div>
                        ) : (
                          <table className='w-full departments-two'>
                            <tbody>
                              {organisation?.departments?.map(
                                (department, index, array) => {
                                  return (
                                    <tr
                                      key={department?.id}
                                      className={`flex px-6 text-868686 ${
                                        array.length !== index + 1
                                          ? 'border-b border-[#CECECE] border-opacity-50'
                                          : ''
                                      }`}
                                    >
                                      <td className='py-5 w-1/4'>
                                        {index < 9 && '0'}
                                        {index + 1}
                                      </td>
                                      <td className='py-5 w-7/12'>
                                        {department?.name}
                                      </td>
                                      <td className='py-5 w-1/6 text-center'>
                                        <div className='flex items-center justify-center space-x-4'>
                                          <EditIcon
                                            onClick={() => {
                                              setEditData(department);
                                              setAddDepartmentModal(true);
                                            }}
                                            className='cursor-pointer'
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </Tabs.Tab>
            <Tabs.Tab label='Designation'>
              <Employeedesignations organisation={company_id} />
            </Tabs.Tab>
          </Tabs>
        </div>
        <div className='absolute top-2 right-0 flex items-center gap-3'>
          {activeTab === 0 ? (
            <button
              onClick={() => {
                setAddDepartmentModal(true);
              }}
            >
              <PrimaryButton>+ Add department</PrimaryButton>
            </button>
          ) : (
            <button onClick={() => setDesignationModalOpen(true)}>
              <PrimaryButton>+ Add designation</PrimaryButton>
            </button>
          )}
        </div>
      </div>
      <DepartmentModal
        isModalOpen={addDepartmentModal}
        setIsModalOpen={setAddDepartmentModal}
        isEditData={editData}
        setEditData={setEditData}
        company_id={company_id}
        // departmentDetails={selectedDepartment}
      />
      <AddDesignationModal
        isModalOpen={isAddDesignationModalOpen}
        setIsModalOpen={setDesignationModalOpen}
        organisation={company_id}
      />
    </>
  );
}

export default Departments;
