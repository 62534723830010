import { useMutation, useQueryClient } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import { ErrorToast, SuccessToast } from 'services/toasterService';
import { useContext } from 'react';
import { AuthContext } from 'context/authContext';

export default function useEditCompanyDetails() {
  const { authDispatch } = useContext(AuthContext);
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(
        apiEndPoints.COMPANY_DETAILS,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        console.log('UPDATECURRENCY', data?.data);
        authDispatch({
          type: 'UPDATECURRENCY',
          payload: data?.data?.currency,
        });
        queryClient.invalidateQueries('company-details');
        queryClient.invalidateQueries('company-details-specific');
        queryClient.invalidateQueries('salary_component_data');
        SuccessToast({ text: data?.message });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
