import React, { createContext, useEffect, useReducer } from "react";
export const AuthContext = createContext();

let initialState = {
  isAuthenticated: JSON.parse(localStorage.getItem("token")) ? true : false,
  token: JSON.parse(localStorage.getItem("token")) || null,
  email: JSON.parse(localStorage.getItem("email")) || null,
  username: JSON.parse(localStorage.getItem("username")) || null,
  is_admin: JSON.parse(localStorage.getItem("is_admin")) || null,
  is_subadmin: JSON.parse(localStorage.getItem("is_subadmin")) || null,
  is_super_admin: JSON.parse(localStorage.getItem("is_super_admin")) || null,
  organisation_id: JSON.parse(localStorage.getItem("organisation_id")) || null,
  employee_id: JSON.parse(localStorage.getItem("employee_id")) || null,
  profile_picture: JSON.parse(localStorage.getItem("profile_picture")) || null,
  user_id: JSON.parse(localStorage.getItem("user_id")) || null,
  currency: JSON.parse(localStorage.getItem("currency")) || null,
  company_logo: JSON.parse(localStorage.getItem("company_logo")) || null,
  company_id: JSON.parse(localStorage.getItem("company_id")) || null,
  first_login: JSON.parse(localStorage.getItem("first_login")) || null,
  can_add_employees:
    JSON.parse(localStorage.getItem("can_add_employees")) || null,
  unreadmsgscount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    /**
     * LOGIN
     *
     * as name suggest we will set the user information and preference to the local storage
     * Beacuse local storage only store String, we have to convert everything to string using JSON.stringify() method
     *
     * read more https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
     */
    case "UPDATE_UNREAD_MSGS_COUNT":
      return {
        ...state,
        unreadmsgscount: action.payload,
      };
    case "LOGIN":
      localStorage.setItem(
        "token",
        JSON.stringify(action.payload.token ?? false)
      );
      localStorage.setItem(
        "email",
        JSON.stringify(action.payload.email ?? false)
      );
      localStorage.setItem(
        "username",
        JSON.stringify(action.payload.name ?? false)
      );
      localStorage.setItem(
        "is_admin",
        JSON.stringify(action.payload.is_admin ?? false)
      );
      localStorage.setItem(
        "is_subadmin",
        JSON.stringify(action.payload.is_subadmin ?? false)
      );
      localStorage.setItem(
        "is_super_admin",
        JSON.stringify(action.payload.is_super_admin ?? false)
      );
      localStorage.setItem(
        "employee_id",
        JSON.stringify(action.payload.employee_id ?? false)
      );
      localStorage.setItem(
        "profile_picture",
        JSON.stringify(action.payload.profile_picture ?? false)
      );
      localStorage.setItem(
        "user_id",
        JSON.stringify(action.payload.user_id ?? false)
      );
      localStorage.setItem(
        "currency",
        JSON.stringify(action.payload.currency ?? false)
      );
      localStorage.setItem(
        "organisation_id",
        JSON.stringify(action.payload.organisation_id ?? false)
      );
      localStorage.setItem(
        "company_logo",
        JSON.stringify(action.payload.company_logo ?? false)
      );
      localStorage.setItem(
        "company_id",
        JSON.stringify(action.payload.company_id ?? false)
      );
      localStorage.setItem(
        "first_login",
        JSON.stringify(action.payload.first_login ?? false)
      );
      localStorage.setItem(
        "can_add_employees",
        JSON.stringify(
          action.payload?.user_permissions?.length
            ? action.payload?.user_permissions?.[0]?.can_add_employees
            : false
        )
      );

      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token ?? null,
        email: action.payload.email ?? null,
        username: action.payload.name ?? null,
        is_admin: action.payload.is_admin ?? null,
        is_subadmin: action.payload.is_subadmin ?? null,
        is_super_admin: action.payload.is_super_admin ?? null,
        organisation_id: action.payload.organisation_id ?? null,
        employee_id: action.payload.employee_id ?? null,
        profile_picture: action.payload.profile_picture ?? null,
        user_id: action.payload.user_id ?? null,
        currency: action.payload.currency ?? null,
        company_logo: action.payload.company_logo ?? null,
        company_id: action.payload.company_id ?? null,
        first_login: action.payload.first_login ?? null,
        can_add_employees: action.payload?.user_permissions?.length
          ? action.payload?.user_permissions[0]?.can_add_employees
          : null,
      };

    // update company logo
    case "UPDATECOMPANYLOGO":
      localStorage.setItem("company_logo", JSON.stringify(action.payload));
      return {
        ...state,
        company_logo: action.payload,
      };

    // update first login
    case "UPDATE_FIRSTLOGIN":
      localStorage.setItem("first_login", JSON.stringify(action.payload));
      return {
        ...state,
        first_login: action.payload,
      };

    // update user name
    case "UPDATE_PROFILE_NAME":
      localStorage.setItem("username", JSON.stringify(action.payload));
      return {
        ...state,
        username: action.payload,
      };

    /**
     * UPDATEPROFILEIMAGE
     *
     * This reducer is responsible for updating the profile picture
     * this is usefull when user update his/her profile,
     * after updating the profile info we will get back the new profile picture URL,
     * then we will update our local version with the updated url from server
     *
     */

    case "UPDATEPROFILEIMAGE":
      localStorage.setItem("profile_picture", JSON.stringify(action.payload));
      return {
        ...state,
        profile_picture: action.payload,
      };

    // update company currency

    case "UPDATECURRENCY":
      localStorage.setItem("currency", JSON.stringify(action.payload));
      return {
        ...state,
        currency: action.payload,
      };

    /**
     * LOGOUT
     *
     * This reducer is responsible for logging the user out
     * and clearing the browser localstorage after that
     *
     */

    case "LOGOUT":
      localStorage.clear();

      return {
        isAuthenticated: false,
        token: null,
        email: null,
        username: null,
        is_admin: false,
        is_subadmin: false,
        is_super_admin: false,
        organisation_id: null,
        employee_id: null,
        profile_picture: null,
        user_id: null,
        currency: null,
        company_logo: null,
        company_id: null,
        first_login: false,
        can_add_employees: false,
        unreadmsgscount: 0,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialState = { ...initialState };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        authDispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
