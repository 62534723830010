import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

import { useParams } from 'react-router-dom';

export default function useGetAdminCompanyDetails() {
  const { company_id } = useParams();
  return useQuery('admin-company-details', async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.COMPANY_DETAILS, {
        company_id: company_id,
      })
    );
    return response?.data;
  });
}
