import { useMutation, useQueryClient } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { ErrorToast, SuccessToast } from 'services/toasterService';

export default function useDeleteEmployeeEducationDetails() {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await makeApiRequest.delete(
        apiEndPoints.EMPLOYEE_EDUCATION_API,
        { data: data }
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('get-employee-educational-details');
        SuccessToast({ text: data?.message });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
