import React, { useContext } from 'react';
import { Modal, TextInput } from '@mantine/core';
import { AuthContext } from 'context/authContext';
// import { ReactComponent as DeleteEventBg } from 'assets/images/DeleteEventBg.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/crossIcon.svg';
import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';

import PrimaryButton from 'components/primaryButton';
// import useDeleteCalendarEvent from '../hooks/useDeleteCalendarEvent';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mantine/dates';

import useAddSkillTarget from '../hooks/useAddSkillTarget';
import dayjs from 'dayjs';
// import useUpdateSkilltarget from '../hooks/useUpdateSkillTarget';

export default function NewSKillTargetModal({
  addSkillTarget,
  setAddSkillTarget,
  selectEventToDelete,
}) {
  const { authState } = useContext(AuthContext);

  const defaultvalues = {
    title: '',
    from_date: '',
    to_date: '',
  };
  const closeModal = () => {
    setAddSkillTarget(false);
    reset(defaultvalues);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const addSkillTargetMutation = useAddSkillTarget(closeModal);
  const addNewTarget = (data) => {
    console.log(data);
    addSkillTargetMutation.mutate({
      name: data?.target_skill,
      employee_id: authState?.employee_id,
      start_date: dayjs(data?.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(data?.to_date).format('YYYY-MM-DD'),
    });
  };

  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };
  //   const deleteCalendarEventMutation = useDeleteCalendarEvent(closeModal);

  return (
    <Modal
      opened={addSkillTarget}
      onClose={closeModal}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      overflow='inside'
      centered
      size={'lg'}
      radius='md'
      transitionDuration={100}
      withCloseButton={false}
      classNames={{
        modal: 'p-0',
        body: 'mt-10 mb-14',
      }}
    >
      <div className='space-y-4 relative'>
        <p className='text-xl leading-7 font-semibold text-[#000] text-center'>
          Add a target to enhance your skill
        </p>
        <div className='px-12'>
          <form onSubmit={handleSubmit(addNewTarget)}>
            <div className='mt-10'>
              <Controller
                name='target_skill'
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    placeholder='Type your new target skill'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      placeholder: 'text-neutralsGrey text-[25px] pl-2',
                    }}
                  />
                )}
              />
              {getErrorMessage('target_skill')}
            </div>
            <div className='flex space-x-6 mt-7'>
              <div className='w-1/2'>
                <Controller
                  name='start_date'
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      placeholder='Start date'
                      size='md'
                      // error={
                      //   isDisabledError
                      //     ? 'Select From* date less than or equal to* date'
                      //     : ''
                      // }
                      rightSection={<CalenderIcon className='w-5' />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      classNames={{
                        input: 'border-primary h-12',
                        placeholder: 'text-neutralsGrey text-sm',
                        yearPickerControlActive: 'text-white bg-primary',
                        monthPickerControlActive: 'text-white bg-primary',
                      }}
                    />
                  )}
                />
                {getErrorMessage('start_date')}
              </div>
              <div className='w-1/2'>
                <Controller
                  name='to_date'
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      placeholder='Expected end date'
                      size='md'
                      // error={
                      //   isDisabledError
                      //     ? 'Select From* date less than or equal to* date'
                      //     : ''
                      // }
                      rightSection={<CalenderIcon className='w-5' />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      classNames={{
                        input: 'border-primary h-12',
                        placeholder: 'text-neutralsGrey text-sm',
                        yearPickerControlActive: 'text-white bg-primary',
                        monthPickerControlActive: 'text-white bg-primary',
                      }}
                    />
                  )}
                />
                {getErrorMessage('end_date')}
              </div>
            </div>
            <div className='flex space-x-6 justify-center mt-12'>
              <button className='w-32'>
                <PrimaryButton>Save</PrimaryButton>
              </button>
            </div>
          </form>
        </div>
      </div>
      <CloseIcon
        className='w-12 h-12 absolute top-8 right-3 cursor-pointer'
        onClick={closeModal}
      />
    </Modal>
  );
}
