import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';
import dayjs from 'dayjs';

import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';
import { Controller } from 'react-hook-form';

export default function OrganisationSecondStep({
  prevStep,
  control,
  handleSubmit,
  onFirstStepSubmit,
  errors,
  watch,
}) {
  const [isDateGreater, setIsDateGreater] = useState(false);
  const [isDisabledError, setIsDisabledError] = useState(false);
  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500 pt-0.5'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  //validation for fy_from_date to be < or = to fy_to_date
  useEffect(() => {
    if (watch('fy_from_date') > watch('fy_to_date')) {
      setIsDateGreater(true);
      setIsDisabledError(true);
    } else {
      setIsDateGreater(false);
      setIsDisabledError(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('fy_from_date'), watch('fy_to_date')]);

  return (
    <div className='mx-5'>
      <p className='text-gray3'>Financial year type</p>
      <form onSubmit={handleSubmit(onFirstStepSubmit)}>
        <div className='py-2'>
          <Controller
            control={control}
            name='is_fy_fiscal'
            rules={{
              required: 'Required',
            }}
            render={({ field }) => (
              <RadioGroup
                {...field}
                classNames={{ label: 'text-gray2 text-lg' }}
              >
                <Radio value='false' label='Calendar year' />
                <Radio value='true' label='Fiscal year' />
              </RadioGroup>
            )}
          />
        </div>
        <div className='my-2'>
          {watch('is_fy_fiscal') === 'false' ? (
            <TextInput
              value='Starts on 1st January and ends on 31st December of the same year'
              readOnly
              classNames={{ input: 'h-12 text-gray3' }}
            />
          ) : (
            <div className='flex space-x-6'>
              <div className='w-1/2'>
                <Controller
                  control={control}
                  name='fy_from_date'
                  rules={{
                    required: 'Required',
                  }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label='From*'
                      size='md'
                      dropdownType='modal' // type can be popover
                      rightSection={<CalenderIcon className='w-5' />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      classNames={{
                        input: 'border-primary h-12',
                        label: 'text-neutralsGrey text-sm',
                        yearPickerControlActive: 'text-white bg-primary',
                        monthPickerControlActive: 'text-white bg-primary',
                      }}
                    />
                  )}
                />
                {getErrorMessage('fy_from_date')}
              </div>
              <div className='w-1/2'>
                <Controller
                  control={control}
                  name='fy_to_date'
                  rules={{
                    required: 'Required',
                  }}
                  render={({ field }) => (
                    <DatePicker
                      dropdownType='modal' // type can be popover
                      {...field}
                      label='To*'
                      disabled={!watch('fy_from_date')}
                      size='md'
                      error={
                        watch('fy_to_date') && isDisabledError
                          ? '* Invalid date entered'
                          : watch('fy_from_date') && !watch('fy_to_date')
                          ? 'Required'
                          : ''
                      }
                      rightSection={<CalenderIcon className='w-5' />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      minDate={dayjs(watch('fy_from_date')).toDate()}
                      classNames={{
                        input: 'border-primary h-12',
                        label: 'text-neutralsGrey text-sm',
                        yearPickerControlActive: 'text-white bg-primary',
                        monthPickerControlActive: 'text-white bg-primary',
                      }}
                    />
                  )}
                />
                {getErrorMessage('fy_to_date')}
              </div>
            </div>
          )}
        </div>
        <div className='flex space-x-6 justify-center mt-12'>
          <div className='w-32' onClick={() => prevStep()}>
            <SecondaryButton>Back</SecondaryButton>
          </div>
          <button className='w-32' disabled={isDateGreater ? true : false}>
            <PrimaryButton>Next</PrimaryButton>
          </button>
        </div>
      </form>
    </div>
  );
}
