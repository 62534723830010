import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { AuthContext } from 'context/authContext';

const CheckAuthPage = () => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffectOnce(() => {
    if (authState.isAuthenticated) {
      if (authState?.is_admin) {
        navigate('/app/admin/dashboard/productivity', { replace: true });
      } else {
        navigate('/app/employee/profile', { replace: true });
      }
    } else {
      navigate('/auth/login', { replace: true });
    }
  });

  return (
    <div className='flex justify-center items-center relative h-screen w-screen'>
      <p>Loading please wait...</p>
    </div>
  );
};

export default CheckAuthPage;
