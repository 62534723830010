import React from 'react';
import LoginImage from '../../../assets/images/LoginPageImage.png';
import { ReactComponent as Subicon } from 'assets/icons/subImage.svg';
import { ReactComponent as AptaSmallIcon } from '../../../assets/icons/aptaSmallIcon.svg';
import { PasswordInput, TextInput, Checkbox, Button } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useLogin from '../hooks/useLogin';
import PrimaryButton from 'components/primaryButton';

const LoginPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const loginMutation = useLogin();

  const login = (data) => {
    loginMutation.mutate({ ...data, re_login: true });
  };

  return (
    <div
      style={{
        backgroundImage: 'URL(' + LoginImage + ')',
        backgroundSize: 'cover',
      }}
      className='h-[100vh]  bg-center bg-cover'
    >
      <div className='bg-[#FFF] w-full py-[15px] px-[50px] flex justify-between items-center shadow-lg'>
        <AptaSmallIcon className='w-24 h-12' />
        <Button
          onClick={() => {
            navigate('/auth/signup', { replace: true });
          }}
          className='h-9 w-28  bg-[#FB7C51] rounded text-[#FFFFFF] font-normal text-base'
        >
          Sign up
        </Button>
      </div>
      <div className='w-[100%] h-[80%] flex justify-between items-center'>
        <div className=' ml-10 p-4 rounded-lg w-[35%]'>
          <h1 className='text-[#FB7C51] font-bold text-2xl text-center mb-10'>
            Hey there! Welcome back
          </h1>
          <form onSubmit={handleSubmit(login)}>
            <div className='flex justify-center flex-col'>
              <TextInput
                {...register('username_or_number', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter valid email',
                  },
                })}
                label='Email'
                className='mb-1'
                size='lg'
                classNames={{
                  input:
                    ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm font-normal ml-2 bg-[#FDFCFC] px-2 rounded z-10 relative',
                }}
              />
              {errors.username_or_number && (
                <p className='text-sm  text-[#fc0101]'>
                  {errors.username_or_number.message}
                </p>
              )}

              <PasswordInput
                {...register('password', { required: 'Password is required' })}
                label='Password'
                className='mb-1 mt-4'
                size='lg'
                classNames={{
                  innerInput:
                    'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded-lg pl-5 ',
                  input:
                    'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded-lg pl-5 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm font-normal ml-2 bg-[#FDFCFC] px-2 rounded z-10 relative',
                }}
              />
              {errors.password && (
                <p className='text-sm  text-[#fc0101]'>
                  {errors.password.message}
                </p>
              )}

              <div className='mt-6 flex justify-between items-center'>
                <Checkbox
                  color='orange'
                  label='Remember me'
                  size='sm'
                  classNames={{
                    label:
                      'font-normal text-normal text-center text-[#515151] cursor-pointer',
                    input: ' cursor-pointer border-[#FB7C51]',
                  }}
                />
                <p
                  className='font-normal text-normal text-center text-[#FB7C51] cursor-pointer'
                  onClick={() =>
                    navigate('/auth/forgot-password', { replace: true })
                  }
                >
                  Forgot Password?
                </p>
              </div>

              <button className='h-11 w-36 mx-auto mt-6 '>
                <PrimaryButton isLoading={loginMutation?.isLoading}>
                  Login
                </PrimaryButton>
              </button>
            </div>
          </form>
        </div>
        <div>
          <Subicon className='w-[45vw] h-[80%] ml-auto 2xl:h-full 2xl:w-full' />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
