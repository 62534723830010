import React from "react";
import { Modal, TextInput } from "@mantine/core";
import { useForm, Controller } from "react-hook-form";
import PrimaryButton from "../../../components/primaryButton";

import useAddSalaryGrade from "../hooks/useAddSalaryGrade";

import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/orangeLeftArrow.svg";

export default function AddGradeModal({ isModalOpen, setIsModalOpen }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const closeModal = () => {
    setIsModalOpen(false);
    reset();
  };

  const addSalaryGradeMutation = useAddSalaryGrade(closeModal);

  const formSubmitHandler = (data) => {
    addSalaryGradeMutation.mutate(data);
  };

  const renderFormErrorMsg = (name) => {
    return (
      errors[name] && (
        <p className=" text-sm font-semibold text-red-500 pt-1 pl-1">
          {errors[name]?.message}
        </p>
      )
    );
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={closeModal}
      title={"Add Grade"}
      withCloseButton={false}
      overlayColor={"#F8F7F799"}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size={500}
      radius="md"
      transitionDuration={100}
      classNames={{
        title: "mx-auto text-primary font-semibold text-18",
        modal: "px-10",
      }}
    >
      <div className="relative">
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <Controller
            control={control}
            name="name"
            rules={{
              required: "Required",
            }}
            render={({ field }) => (
              <TextInput
                label="Grade name*"
                {...field}
                size="md"
                className="mt-10"
                classNames={{
                  label: "text-sm text-neutralsGrey",
                  input: "border-primary bg-transparent",
                }}
              />
            )}
          />
          {renderFormErrorMsg("name")}
          <div className="flex justify-center">
            <button className="mt-10 mb-4 w-24">
              <PrimaryButton isLoading={addSalaryGradeMutation?.isLoading}>
                <p>Save</p>
              </PrimaryButton>
            </button>
          </div>
        </form>
        <LeftArrowIcon
          className="absolute -top-[62px] h-5 w-5 cursor-pointer"
          onClick={closeModal}
        />
      </div>
    </Modal>
  );
}
