import React from 'react';

export default function TeamCardLoader() {
  return (
    <div className='pr-6 2xl:pr-8 pb-2 w-1/4'>
      <div className='w-full h-48 2xl:h-52 shadow-lg rounded-xl p-4 border border-8B8B8B border-opacity-20 mb-4'>
        <div className='flex'>
          <div class='rounded-full bg-slate-200 h-12 w-12'></div>
          <div className='ml-2'>
            <div class='rounded-md bg-slate-200 h-5 w-44'></div>
            <div class='rounded-md bg-slate-200 h-4 w-36 mt-2'></div>
          </div>
        </div>
        <div className='space-y-4 mt-5 ml-2'>
          <div class='rounded-md bg-slate-200 h-4 w-44'></div>
          <div class='rounded-md bg-slate-200 h-4 w-44'></div>
          <div class='rounded-md bg-slate-200 h-4 w-44'></div>
        </div>
      </div>
    </div>
  );
}
