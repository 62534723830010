import React, { useState, useEffect } from 'react';
import { Modal, Switch, Chips, Chip } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';

import useUpdateWorkingHours from '../hooks/useUpdateWorkingHours';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import PrimaryButton from 'components/primaryButton';

export default function EditWorkingHoursModal({
  isModalOpen,
  setIsModalOpen,
  employeeData,
}) {
  const {
    control,
    handleSubmit,
    // formState: { errors },
    reset,
    getValues,
  } = useForm();

  const [selectedDays, setSelectedDays] = useState(['monday']);
  const [isWorkingHoursSame, setIsWorkingHoursSame] = useState(false);
  const [employeeId, setEmployeeId] = useState('');

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p> Edit working hours</p>
      </div>
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateTeamMemberMutation = useUpdateWorkingHours(closeModal);

  const onSubmit = (data) => {
    let workingDetails = {};
    selectedDays?.map((day) => {
      if (isWorkingHoursSame) {
        return (
          (workingDetails['id'] = employeeId),
          (workingDetails['working_hours_same'] = true),
          (workingDetails[`is_${day}`] = true),
          (workingDetails['general_from'] = data?.general_from),
          (workingDetails['general_to'] = data?.general_to)
        );
      } else {
        return (
          (workingDetails['id'] = employeeId),
          (workingDetails['working_hours_same'] = false),
          (workingDetails[`is_${day}`] = true),
          (workingDetails[`${day}_from`] = data[`${day}_from`]),
          (workingDetails[`${day}_to`] = data[`${day}_to`])
        );
      }
    });
    updateTeamMemberMutation.mutate(workingDetails);
  };

  //   reset  from data
  useEffect(() => {
    setEmployeeId(employeeData?.[0]?.employee_wdt__id);
    setIsWorkingHoursSame(employeeData?.[0]?.employee_wdt__working_hours_same);
    let daysArray = [];
    if (employeeData?.[0]?.employee_wdt__is_monday) {
      daysArray.push('monday');
    }
    if (employeeData?.[0]?.employee_wdt__is_tuesday) {
      daysArray.push('tuesday');
    }
    if (employeeData?.[0]?.employee_wdt__is_wednesday) {
      daysArray.push('wednesday');
    }
    if (employeeData?.[0]?.employee_wdt__is_thursday) {
      daysArray.push('thursday');
    }
    if (employeeData?.[0]?.employee_wdt__is_friday) {
      daysArray.push('friday');
    }
    if (employeeData?.[0]?.employee_wdt__is_saturday) {
      daysArray.push('saturday');
    }
    if (employeeData?.[0]?.employee_wdt__is_sunday) {
      daysArray.push('sunday');
    }
    setSelectedDays(daysArray);

    if (employeeData?.[0]?.employee_wdt__working_hours_same) {
      reset({
        ...getValues,
        general_from: employeeData?.[0]?.employee_wdt__general_from,
        general_to: employeeData?.[0]?.employee_wdt__general_to,
      });
    } else {
      reset({
        ...getValues,
        monday_from: employeeData?.[0]?.employee_wdt__monday_from,
        monday_to: employeeData?.[0]?.employee_wdt__monday_to,
        tuesday_from: employeeData?.[0]?.employee_wdt__tuesday_from,
        tuesday_to: employeeData?.[0]?.employee_wdt__tuesday_to,
        wednesday_from: employeeData?.[0]?.employee_wdt__wednesday_from,
        wednesday_to: employeeData?.[0]?.employee_wdt__wednesday_to,
        thursday_from: employeeData?.[0]?.employee_wdt__thursday_from,
        thursday_to: employeeData?.[0]?.employee_wdt__thursday_to,
        friday_from: employeeData?.[0]?.employee_wdt__friday_from,
        friday_to: employeeData?.[0]?.employee_wdt__friday_to,
        saturday_from: employeeData?.[0]?.employee_wdt__saturday_from,
        saturday_to: employeeData?.[0]?.employee_wdt__saturday_to,
        sunday_from: employeeData?.[0]?.employee_wdt__sunday_from,
        sunday_to: employeeData?.[0]?.employee_wdt__sunday_to,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData]);

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title={customTitle()}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size='xl'
      radius='md'
      overflow='inside'
      transitionDuration={100}
      classNames={{
        title: 'w-full text-primary font-semibold text-18',
        modal: 'pl-10',
        body: 'scroller -mr-4 pr-2',
      }}
    >
      <div className='w-full pt-4'>
        <div className='text-neutralsGrey text-sm flex justify-between pb-2 mx-4'>
          <p>Working days &amp; timings</p>
          <div className='flex space-x-2'>
            <p>Working hours are same in each day</p>
            <Switch
              size='sm'
              checked={isWorkingHoursSame}
              onChange={(e) => {
                setIsWorkingHoursSame(e.currentTarget.checked);
              }}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='bg-FFF5F2 min-h-40 mt-2 p-4 mx-4'>
            <div className='flex justify-between'>
              <p className='text-base text-414141'>Select working days</p>
              <div>
                <Chips
                  multiple={true}
                  value={selectedDays}
                  onChange={setSelectedDays}
                  classNames={{
                    label:
                      'p-0 w-8 text-center rounded-md font-medium text-primary',
                    iconWrapper: 'hidden',
                    checkIcon: 'hidden',
                    checked: 'text-[#ffffff] bg-primary hover:bg-primary',
                  }}
                >
                  <Chip value='monday'>M</Chip>
                  <Chip value='tuesday'>T</Chip>
                  <Chip value='wednesday'>W</Chip>
                  <Chip value='thursday'>TH</Chip>
                  <Chip value='friday'>F</Chip>
                  <Chip value='saturday'>SA</Chip>
                  <Chip value='sunday'>S</Chip>
                </Chips>
              </div>
            </div>
            <hr className='border-primary border my-4 border-opacity-20' />
            {isWorkingHoursSame ? (
              <div className='flex item-center justify-between'>
                <p className='text-base text-414141 pt-1'>Timings</p>
                <div className='flex items-center space-x-4 mb-4'>
                  <Controller
                    control={control}
                    name='general_from'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                  <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                  <Controller
                    control={control}
                    name='general_to'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                </div>
              </div>
            ) : (
              <>
                {selectedDays.includes('monday') ? (
                  <div className='flex item-center justify-between'>
                    <p className='text-base text-414141 pt-1'>Monday</p>
                    <div className='flex items-center space-x-4 mb-4'>
                      <Controller
                        control={control}
                        name='monday_from'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            type='time'
                            {...field}
                            className='px-2 py-1 border border-neutralsGrey rounded-md'
                          />
                        )}
                      />
                      <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                      <Controller
                        control={control}
                        name='monday_to'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
                {selectedDays.includes('tuesday') ? (
                  <div className='flex item-center justify-between'>
                    <p className='text-base text-414141 pt-1'>Tuesday</p>
                    <div className='flex items-center space-x-4 mb-4'>
                      <Controller
                        control={control}
                        name='tuesday_from'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                      <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                      <Controller
                        control={control}
                        name='tuesday_to'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
                {selectedDays.includes('wednesday') ? (
                  <div className='flex item-center justify-between'>
                    <p className='text-base text-414141 pt-1'>Wednesday</p>
                    <div className='flex items-center space-x-4 mb-4'>
                      <Controller
                        control={control}
                        name='wednesday_from'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                      <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                      <Controller
                        control={control}
                        name='wednesday_to'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
                {selectedDays.includes('thursday') ? (
                  <div className='flex item-center justify-between'>
                    <p className='text-base text-414141 pt-1'>Thursday</p>
                    <div className='flex items-center space-x-4 mb-4'>
                      <Controller
                        control={control}
                        name='thursday_from'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                      <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                      <Controller
                        control={control}
                        name='thursday_to'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
                {selectedDays.includes('friday') ? (
                  <div className='flex item-center justify-between'>
                    <p className='text-base text-414141 pt-1'>Friday</p>
                    <div className='flex items-center space-x-4 mb-4'>
                      <Controller
                        control={control}
                        name='friday_from'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                      <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                      <Controller
                        control={control}
                        name='friday_to'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
                {selectedDays.includes('saturday') ? (
                  <div className='flex item-center justify-between'>
                    <p className='text-base text-414141 pt-1'>Saturday</p>
                    <div className='flex items-center space-x-4 mb-4'>
                      <Controller
                        control={control}
                        name='saturday_from'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                      <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                      <Controller
                        control={control}
                        name='saturday_to'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
                {selectedDays.includes('sunday') ? (
                  <div className='flex item-center justify-between'>
                    <p className='text-base text-414141 pt-1'>Sunday</p>
                    <div className='flex items-center space-x-4 mb-4'>
                      <Controller
                        control={control}
                        name='sunday_from'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                      <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                      <Controller
                        control={control}
                        name='sunday_to'
                        rules={{ required: 'required' }}
                        render={({ field }) => (
                          <input
                            {...field}
                            type='time'
                            className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className='flex justify-center mt-6'>
            <button className='w-28'>
              <PrimaryButton isLoading={updateTeamMemberMutation?.isLoading}>
                Save
              </PrimaryButton>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
