import { Avatar, Modal, Tooltip } from '@mantine/core';

import { ReactComponent as MobileIcon } from 'assets/icons/mobileIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mailIcon.svg';
import { ReactComponent as GradeIcon } from 'assets/icons/gradeIcon.svg';
import EmployeePerformanceFormField from './employeePerformanceformField';
import dayjs from 'dayjs';

const EmployeePerformanceModal = ({
  employeePerformancemodalOpened,
  setEmployeePerformancemodalOpened,
  specificPerformanceReport,
  setSpecificPerformanceReport,
}) => {
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[850px]'>
        <LeftArrowIcon
          onClick={() => setEmployeePerformancemodalOpened(false)}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className=''>Performance report</p>
      </div>
    );
  };

  return (
    <Modal
      opened={employeePerformancemodalOpened}
      onClose={() => {
        setEmployeePerformancemodalOpened(false);
        setSpecificPerformanceReport(null);
      }}
      title={customTitle()}
      withCloseButton={false}
      // title='Creating performance report'
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      centered
      overlayOpacity={0.5}
      transitionDuration={1}
      size={'70%'}
      overflow='inside'
      className='px-8'
      classNames={{
        title: 'text-lg font-semibold ',

        body: 'scroller',
      }}
    >
      <div className='mx-5 my-5 flex justify-between items-center'>
        <h1 className='text-primary text-base'>
          {specificPerformanceReport?.review_type__name}{' '}
          {`(${dayjs(specificPerformanceReport?.segment_from).format(
            'DD/MMM/YYYY'
          )} - ${dayjs(specificPerformanceReport?.segment_to).format(
            'DD/MMM/YYYY'
          )})`}
        </h1>
        <div className='flex items-center gap-3'>
          <p className='text-primary'>
            Created on :{' '}
            <span className='font-medium text-[#828282]'>{`${dayjs(
              specificPerformanceReport?.created_date
            ).format('DD MMM YYYY')}`}</span>
          </p>
          <p className='text-primary flex '>
            Created by :{' '}
            <Tooltip
              label={specificPerformanceReport?.created_by__first_name}
              classNames={{
                body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
              }}
              radius='md'
            >
              <Avatar
                radius='xl'
                size='sm'
                color='#FB7C51'
                className='ml-2'
                src={
                  specificPerformanceReport?.created_by__user_employee__profile_picture
                }
              >
                {specificPerformanceReport?.created_by__first_name
                  .substring(0, 1)
                  .toUpperCase()}
              </Avatar>
            </Tooltip>
          </p>
        </div>
      </div>
      <div className='bg-[#FFF8F6] rounded-md p-4 mx-4 '>
        <div className='flex  w-full '>
          {/* <Tooltip
            label={specificPerformanceReport?.employee__user__first_name}
            classNames={{
              body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
            }}
            radius='md'
          > */}
          <Avatar
            radius='xl'
            size='lg'
            color='#FB7C51'
            src={specificPerformanceReport?.employee__profile_picture}
          >
            {specificPerformanceReport?.employee__user__first_name
              ?.substring(0, 1)
              .toUpperCase()}
          </Avatar>
          {/* </Tooltip> */}
          <div className='w-full pl-5'>
            <div className='flex justify-between items-center  w-full '>
              <h1 className='text-[#000000] font-bold text-normal'>
                {specificPerformanceReport?.employee__user__first_name}
                <span className='text-primary font-normal text-sm'>
                  {specificPerformanceReport?.employee__preferred_pronoun}
                </span>
              </h1>
              <div className='flex items-center gap-2 mb-1'>
                <p className='text-primary flex '>
                  Reports to :{' '}
                  {specificPerformanceReport?.employee__reports_to__first_name ? (
                    <Tooltip
                      label={
                        specificPerformanceReport?.employee__reports_to__first_name
                      }
                      classNames={{
                        body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                      }}
                      radius='md'
                    >
                      <Avatar
                        radius='xl'
                        size='sm'
                        color='#FB7C51'
                        src={
                          specificPerformanceReport?.employee__reports_to__user_employee__profile_picture
                        }
                      >
                        {specificPerformanceReport?.employee__reports_to__first_name
                          ?.substring(0, 1)
                          .toUpperCase()}
                      </Avatar>
                    </Tooltip>
                  ) : (
                    'None'
                  )}
                </p>
              </div>
            </div>
            <div className='flex items-center justify-between  '>
              <p className='text-[#A8A8A8] font-normal text-base'>
                {/* {totalEmployess?.data?.data?.[0]?.designation} */}
                {specificPerformanceReport?.employee__designation__name}
              </p>
              <div className=' flex  items-center gap-2'>
                <p className='flex items-center text-[#828282] font-normal text-sm'>
                  <MobileIcon className='mr-2 h-4 w-4' />
                  {specificPerformanceReport?.employee__user__mobile_number}
                </p>
                <Tooltip
                  label={specificPerformanceReport?.employee__user__email}
                  classNames={{
                    body: 'bg-primary',
                  }}
                  radius='md'
                >
                  <p className='flex items-center text-[#828282] font-normal text-sm'>
                    <MailIcon className='mr-2 h-4 w-4' />
                    {specificPerformanceReport?.employee__user__email}
                  </p>
                </Tooltip>
                <p className='flex items-center text-[#828282] font-normal text-sm'>
                  <LocationIcon className='mr-2 h-4 w-4' />
                  {specificPerformanceReport?.employee__country}
                </p>
                <p className='flex items-center text-[#828282] font-normal text-sm'>
                  <GradeIcon className='mr-2 h-4 w-4' />
                  {specificPerformanceReport?.employee__grade__name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5 relative mx-4'>
        <EmployeePerformanceFormField
          specificPerformanceReport={specificPerformanceReport}
          setEmployeePerformancemodalOpened={setEmployeePerformancemodalOpened}
        />
      </div>
    </Modal>
  );
};

export default EmployeePerformanceModal;
