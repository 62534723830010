import React from 'react';
import { Modal } from '@mantine/core';

import useEditAppliedLeave from '../hooks/useUpdateLeave';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/crossIcon.svg';
import { ReactComponent as ApproveLeaveIlls } from 'assets/images/approveLeaveIlls.svg';
import { ReactComponent as RejectLeaveIlls } from 'assets/images/rejectLeaveIlls.svg';
import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';

export default function AcceptLeaveModal({
  isModalOpen,
  setIsModalOpen,
  modalName,
  selectedLeaveData,
}) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateLeaveMutation = useEditAppliedLeave(closeModal);

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-12 absolute right-0 cursor-pointer'
        />
      </div>
    );
  };

  const handleMutation = () => {
    if (modalName === 'approve') {
      const finalData = {
        id: selectedLeaveData?.id,
        status: 'Approved',
        is_approved: true,
      };
      updateLeaveMutation?.mutate(finalData);
    } else {
      const finalData = {
        id: selectedLeaveData?.id,
        status: 'Declined',
        is_approved: false,
      };
      updateLeaveMutation?.mutate(finalData);
    }
  };

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='lg'
        radius='md'
        overflow='inside'
        trapFocus={false}
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 mt-4',
          body: 'scroller',
        }}
      >
        <p className='text-center text-xl text-neutralsBlack'>
          Are you sure you want to{' '}
          <span className='font-semibold'>{modalName}</span> the leave?
        </p>
        {modalName === 'approve' ? (
          <ApproveLeaveIlls className='w-full my-3' />
        ) : (
          <RejectLeaveIlls className='w-full my-3' />
        )}
        <div className='flex justify-center space-x-4 mb-4'>
          <button className='w-28' onClick={() => handleMutation()}>
            <SecondaryButton isLoading={updateLeaveMutation?.isLoading}>
              Yes
            </SecondaryButton>
          </button>
          <button className='w-28' onClick={() => closeModal()}>
            <PrimaryButton>No</PrimaryButton>
          </button>
        </div>
      </Modal>
    </div>
  );
}
