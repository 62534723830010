import { Modal } from '@mantine/core';

import { ReactComponent as Deletepolicy } from 'assets/icons/deletepolicy.svg';
import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';
import Usedeletepolicies from '../hooks/Usedelectepolicy';

const DeletePolicy = ({
  setdeletepolicymodal,
  deletepolicymodal,
  policyId,
  setPolicyId,
}) => {
  const closeModal = () => {
    setdeletepolicymodal(false);
    setPolicyId(null);
  };
  const deletePolicy = Usedeletepolicies(closeModal);

  return (
    <div>
      <Modal
        opened={deletepolicymodal}
        onClose={() => {
          setdeletepolicymodal(false);
          setPolicyId(null);
        }}
        // title={customTitle()}
        // withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10 h-[62vh]',
          body: 'scroller -mr-4 pr-8',
        }}
      >
        <div className='flex justify-center items-center flex-col'>
          <p className='text-[ #515151]'>
            Are you sure you want to delete the policy document?
          </p>
          <Deletepolicy className='w-full' />
          <div className='gap-4 flex my-8'>
            <button
              className='w-32'
              onClick={() => {
                deletePolicy.mutate({ id: policyId });
              }}
            >
              <SecondaryButton>Yes</SecondaryButton>
            </button>
            <button
              className='w-32'
              onClick={() => {
                setdeletepolicymodal(false);
                setPolicyId(null);
              }}
            >
              <PrimaryButton>No</PrimaryButton>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeletePolicy;
