import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetEmployeeSalaryDetails(id, isModalOpen) {
  return useQuery(
    ['get-employee-salary-details'],
    async () => {
      const res = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_SALARY_DETAILS, {
          employee_id: id,
        })
      );
      return res.data;
    },
    {
      enabled: id && isModalOpen ? true : false,
    }
  );
}
