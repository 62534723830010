import { useContext } from 'react';
import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import { AuthContext } from 'context/authContext';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetEmployeeAppliedLeaves(
  leaveTypeFilter,
  monthFilterValue,
  yearFilterValue
) {
  const { authState } = useContext(AuthContext);
  return useQuery(
    [
      'get-employee-applied-leaves',
      leaveTypeFilter,
      monthFilterValue,
      yearFilterValue,
    ],
    async () => {
      const res = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_APPLIED_LEAVES, {
          employee_id: authState?.employee_id,
          leave_type_id: leaveTypeFilter,
          month: monthFilterValue,
          from_date__year: yearFilterValue,
        })
      );
      return res.data;
    }
  );
}
