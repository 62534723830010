import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useOrganisationsList() {
  return useQuery(
    'super-organisations-list',
    async () => {
      const response = await makeApiRequest.get(
        apiEndPoints.ORGANISATIONS_LIST
      );
      return response?.data;
    },
    { staleTime: 3000 }
  );
}
