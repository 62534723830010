import { ReactComponent as AvailableIcon } from 'assets/icons/AvailableIcon.svg';
import { ReactComponent as AwayIcon } from 'assets/icons/AwayIcon.svg';
import { ReactComponent as BeRightBackIcon } from 'assets/icons/BeRightBackIcon.svg';
import { ReactComponent as BusyIcon } from 'assets/icons/BusyIcon.svg';
import { ReactComponent as DNDIcon } from 'assets/icons/DNDIcon.svg';
import { ReactComponent as OfflineIcon } from 'assets/icons/OfflineIcon.svg';

export default function StatusLabel(status, smallSize = false) {
  return status === 'Online' ? (
    <AvailableIcon
      className={`border-2 border-white rounded-full bg-white ${
        !smallSize ? 'w-4 h-4' : 'w-3 h-3'
      }`}
    />
  ) : status === 'Away' ? (
    <AwayIcon
      className={`border-2 border-white rounded-full bg-white ${
        !smallSize ? 'w-4 h-4' : 'w-3 h-3'
      }`}
    />
  ) : status === 'Be Right Back' ? (
    <BeRightBackIcon
      className={`border-2 border-white rounded-full bg-white ${
        !smallSize ? 'w-4 h-4' : 'w-3 h-3'
      }`}
    />
  ) : status === 'Busy' ? (
    <BusyIcon
      className={`border-2 border-white rounded-full bg-white ${
        !smallSize ? 'w-4 h-4' : 'w-3 h-3'
      }`}
    />
  ) : status === 'Offline' ? (
    <OfflineIcon
      className={`border-2 border-white rounded-full bg-white ${
        !smallSize ? 'w-4 h-4' : 'w-3 h-3'
      }`}
    />
  ) : status === 'Do Not Disturb' ? (
    <DNDIcon
      className={`border-2 border-white rounded-full bg-white ${
        !smallSize ? 'w-4 h-4' : 'w-3 h-3'
      }`}
    />
  ) : (
    ''
  );
}
