import { Avatar, Modal, TextInput } from '@mantine/core';
import { useState, useEffect, useRef, useContext } from 'react';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as PluscircleIcon } from 'assets/icons/pluscircleIcon.svg';
import { ReactComponent as MinuscircleIcon } from 'assets/icons/minuscircleIcon.svg';

import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';

import Usegetspecificpayrolldata from '../hooks/usegetspecificpayrolldata';
import Usepostspecificemployeesalary from '../hooks/usepostspecificemployeesalary';
import { AuthContext } from 'context/authContext';

import getSymbolFromCurrency from 'currency-symbol-map';

// import { formDirtyValues } from 'helper/RHFgetDirtyField';

const SpecificEmployeeModal = ({
  singleEmployeeModalOpened,
  setSingleEmployeeModalOpened,
  payrollIdemp,
  setPayrollIdemp,
  employeeId,
  setEmployeeId,
}) => {
  const { authState } = useContext(AuthContext);
  const currencyIcon = getSymbolFromCurrency(authState?.currency);
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields: formDirtyfields },
    reset,
  } = useForm({ mode: 'onChange' });
  const dirtyFields = useRef();
  // const { ComponentsData } = useFieldArray({
  //   control,
  //   name: 'components_values',
  // });

  // console.log(watch('deduct_pay_for_days'));

  const specificPayrollData = Usegetspecificpayrolldata(
    payrollIdemp,
    employeeId
  );

  let index = specificPayrollData?.data?.data?.employees?.findIndex(
    (emp) => emp?.id === employeeId
  );

  // console.log('specificPayrollData', specificPayrollData?.data);
  // console.log(specificPayrollData?.data?.data?.employees[index]);

  // const allowanceData =
  //   specificPayrollData?.data?.data?.employees[index].components_values.filter(
  //     (eachal) => eachal.is_allowance === true
  //   );

  // const deductionData =
  //   specificPayrollData?.data?.data?.employees[index].components_values.filter(
  //     (eachal) => eachal.is_allowance === false
  //   );

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 mt-3'>
        <LeftArrowIcon
          onClick={() => {
            setSingleEmployeeModalOpened(false);
            setPayrollIdemp(null);
            setEmployeeId(null);
            setAllowance(null);
            reset({});
          }}
          className='w-4 absolute left-0 cursor-pointer'
        />
      </div>
    );
  };

  const [allowance, setAllowance] = useState(null);
  const [netPay, setNetPay] = useState(0);

  // console.log(allowance);

  useEffect(() => {
    setAllowance(
      specificPayrollData?.data?.data?.employees[index].components_values
    );
    // eslint-disable-next-line
    setNetPay(specificPayrollData?.data?.data?.employees[index].net_pay);
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    specificPayrollData?.data?.data?.employees[index].components_values,
    singleEmployeeModalOpened,
  ]);

  // console.log(allowance);
  // const res1 = allowance?.filter((page1) => {
  //   return !specificPayrollData?.data?.data?.employees[index].components_values.find(
  //     (page2) => page1.component_removed === page2.component_removed
  //   );
  // });

  // const res1 =
  //   allowance &&
  //   allowance ===
  //     specificPayrollData?.data?.data?.employees[index].components_values;

  const res1 =
    parseFloat(specificPayrollData?.data?.data?.employees[index].net_pay) ===
    parseFloat(netPay);

  // console.log(
  //   res1,
  //   parseFloat(specificPayrollData?.data?.data?.employees[index].net_pay),
  //   parseFloat(netPay)
  // );

  // console.log(
  //   res1,
  //   allowance,
  //   specificPayrollData?.data?.data?.employees[index].components_values
  // );

  const specificEmployeeModal = () => {
    setSingleEmployeeModalOpened(false);
    setPayrollIdemp(null);
    setEmployeeId(null);
    setAllowance(null);
    reset({});
  };

  const postSpecificEmployeeSalaryMutation = Usepostspecificemployeesalary(
    specificEmployeeModal
  );

  const submitform = (data) => {
    // console.log({
    //   ...data,
    //   payroll_id: payrollIdemp,
    //   employee_id: employeeId,
    //   components: allowance.map((eachgddiff) => {
    //     return {
    //       id: eachgddiff.id,
    //       is_removed: eachgddiff.component_removed,
    //     };
    //   }),
    // });
    // const onlyDirtyFieldObj = formDirtyValues(dirtyFields.current, data);

    if (!specificPayrollData?.data?.data?.payroll__is_approved) {
      postSpecificEmployeeSalaryMutation.mutate({
        ...data,
        payroll_id: payrollIdemp,
        employee_id: employeeId,
        components: allowance.map((eachgddiff) => {
          return {
            id: eachgddiff.id,
            is_removed: eachgddiff.component_removed,
          };
        }),
      });
    }
  };

  useEffect(() => {
    dirtyFields.current = formDirtyfields;
  }, [formDirtyfields]);

  return (
    <div>
      <Modal
        opened={singleEmployeeModalOpened}
        onClose={specificEmployeeModal}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size='38%'
        overflow='inside'
        className='p-8'
        classNames={{
          title: 'text-lg font-semibold ',

          body: 'scroller',
        }}
      >
        {specificPayrollData.isLoading ? (
          // loader
          <div className='animate-pulse pt-4'>
            <div className='flex space-x-4'>
              <div className='rounded-full bg-slate-200 h-12 w-12'></div>
              <div className='flex justify-between w-96'>
                <div>
                  <div className='rounded-md bg-slate-200 h-5 w-60 mb-2'></div>
                  <div className='rounded-md bg-slate-200 h-5 w-40'></div>
                </div>
                <div>
                  <div className='rounded-md bg-slate-200 h-12 w-16'></div>
                </div>
              </div>
            </div>
            <div className='rounded-md bg-slate-200 h-16 w-full mt-5'></div>
            <div className='rounded-md bg-slate-200 h-16 w-full mt-5'></div>
            <div className='rounded-md bg-slate-200 h-10 w-32 mx-auto mt-5'></div>
          </div>
        ) : (
          <form onSubmit={handleSubmit(submitform)}>
            <div className='grid grid-cols-2 '>
              {/*profile and net salary*/}
              <div className='flex items-center mt-4 '>
                <Avatar
                  src={
                    specificPayrollData?.data?.data?.employees[index]
                      .profile_picture
                  }
                  radius='lg'
                  color='#FB7C51'
                  size='35px'
                >
                  {
                    specificPayrollData?.data?.data?.employees[index]
                      .user__first_name[0]
                  }
                </Avatar>
                <div className='ml-3'>
                  <p className='text-[#848383]'>
                    {' '}
                    {
                      specificPayrollData?.data?.data?.employees[index]
                        .user__first_name
                    }
                  </p>
                  <p className='text-[#848383] text-sm'>
                    {
                      specificPayrollData?.data?.data?.employees[index]
                        .employee_id
                    }
                  </p>
                </div>
              </div>
              {/*days*/}
              <div className='mt-6 justify-self-end text-right'>
                <p className='font-semibold'>Net pay</p>
                <p className='text-[#848383] text-sm'>
                  {currencyIcon}
                  {parseFloat(netPay).toFixed(2)}
                </p>
              </div>
              <p className='text-[#848383] text-normal mt-3'>Paid days</p>
              <p className='text-[#848383] mt-3 justify-self-end text-right'>
                {
                  specificPayrollData?.data?.data?.employees[index]
                    .total_paid_days
                }
              </p>
              <p className='text-[#848383] my-1 text-normal '>LOP days</p>
              <p className='text-[#848383] my-1 justify-self-end text-right'>
                {' '}
                {specificPayrollData?.data?.data?.employees[index].lop_days}
              </p>
              <p className='text-[#848383] text-normal '>
                Deduct payment for (days){' '}
              </p>
              {/* <p className='text-[#848383]  justify-self-end text-right'>05</p> */}
              <button className='hidden'>sdfdf</button>
              {specificPayrollData?.data?.data?.payroll__is_approved ? (
                <p className='text-[#848383] my-1 justify-self-end text-right'>
                  {' '}
                  {specificPayrollData?.data?.data?.employees[index]
                    .deduct_pay_for_days ?? 0}
                </p>
              ) : (
                <div className='justify-self-end text-right'>
                  <div className='flex justify-end '>
                    <Controller
                      control={control}
                      name='deduct_pay_for_days'
                      defaultValue={
                        specificPayrollData?.data?.data?.employees[index]
                          .deduct_pay_for_days ?? 0
                      }
                      rules={{
                        // required: 'required',
                        max: {
                          value:
                            parseFloat(
                              specificPayrollData?.data?.data?.employees[index]
                                .total_paid_days
                            ) +
                            parseFloat(
                              specificPayrollData?.data?.data?.employees[index]
                                .lop_days
                            ),
                          message: `Days should be less than  ${
                            parseFloat(
                              specificPayrollData?.data?.data?.employees[index]
                                .total_paid_days
                            ) +
                            parseFloat(
                              specificPayrollData?.data?.data?.employees[index]
                                .lop_days
                            )
                          }`,
                        },
                        min: {
                          value: 0,
                          message: 'Days should be 0 or greater than 0',
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          // {...field}
                          type='number'
                          defaultValue={
                            specificPayrollData?.data?.data?.employees[index]
                              .deduct_pay_for_days ?? 0
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            e.target.value &&
                              setNetPay(
                                parseFloat(
                                  specificPayrollData?.data?.data?.employees[
                                    index
                                  ].deduct_pay_for_days
                                ) > parseFloat(e.target.value)
                                  ? parseFloat(
                                      specificPayrollData?.data?.data
                                        ?.employees[index].net_pay
                                    ) +
                                      (parseFloat(
                                        specificPayrollData?.data?.data
                                          ?.employees[index].deduct_pay_for_days
                                      ) -
                                        parseFloat(e.target.value)) *
                                        parseFloat(
                                          specificPayrollData?.data?.data
                                            ?.employees[index]
                                            .each_day_salary ?? 0
                                        )
                                  : specificPayrollData?.data?.data?.employees[
                                      index
                                    ].deduct_pay_for_days ===
                                    parseFloat(e.target.value)
                                  ? parseFloat(
                                      specificPayrollData?.data?.data
                                        ?.employees[index].net_pay
                                    )
                                  : parseFloat(
                                      specificPayrollData?.data?.data
                                        ?.employees[index].net_pay
                                    ) -
                                    (parseFloat(e.target.value) -
                                      parseFloat(
                                        specificPayrollData?.data?.data
                                          ?.employees[index].deduct_pay_for_days
                                      )) *
                                      parseFloat(
                                        specificPayrollData?.data?.data
                                          ?.employees[index].each_day_salary ??
                                          0
                                      )
                              );
                          }}
                          className=' justify-self-end text-right'
                          classNames={{
                            input:
                              ' text-[15px] bg-transparent  text-normal rounded w-20',
                          }}
                        />
                      )}
                    />
                  </div>
                  {errors.deduct_pay_for_days && (
                    <p className='text-sm text-[Inter] text-[#fc0101] text-right'>
                      {errors.deduct_pay_for_days.message}
                    </p>
                  )}
                </div>
              )}
              {/*Salary details*/}
              <h1 className='col-span-2 font-semibold my-2'>Salary details</h1>
              <p className='text-[#848383] my-1 text-normal '>Basic</p>
              <p className='text-[#848383] my-1 justify-self-end text-right'>
                {currencyIcon}
                {parseFloat(
                  specificPayrollData?.data?.data?.employees[index].gross_salary
                ).toFixed(2)}
              </p>
              <p className='text-[#848383] my-1 text-normal '>
                House rent allowance (H.R.A)
              </p>
              <p className='text-[#848383] my-1 justify-self-end text-right'>
                {currencyIcon}
                {parseFloat(
                  specificPayrollData?.data?.data?.employees[index].hra
                ).toFixed(2)}
              </p>

              {allowance &&
                allowance.map((eachDeduction) => (
                  <>
                    {eachDeduction.component_removed ? (
                      <p
                        className={
                          eachDeduction?.is_allowance
                            ? 'opacity-50 text-[#848383] my-1 text-normal  '
                            : 'opacity-50 text-[#848383] my-1 text-normal '
                        }
                      >
                        {eachDeduction.name}
                      </p>
                    ) : (
                      <p className='text-[#848383] my-1 text-normal '>
                        {eachDeduction.name}
                      </p>
                    )}

                    <p className='text-[#848383] my-1 justify-self-end text-right flex items-center gap-2'>
                      {specificPayrollData?.data?.data
                        ?.payroll__is_approved ? null : (
                        <>
                          {eachDeduction.component_removed ? (
                            <PluscircleIcon
                              className='cursor-pointer'
                              onClick={() => {
                                setAllowance(
                                  allowance.map((item) =>
                                    item.id === eachDeduction.id
                                      ? {
                                          ...item,
                                          component_removed:
                                            !item.component_removed,
                                        }
                                      : item
                                  )
                                );
                                // eslint-disable-next-line
                                {
                                  eachDeduction.is_allowance
                                    ? setNetPay(
                                        parseFloat(netPay) +
                                          parseFloat(`${eachDeduction.value}`)
                                      )
                                    : setNetPay(
                                        parseFloat(netPay) -
                                          parseFloat(`${eachDeduction.value}`)
                                      );
                                }
                              }}
                            />
                          ) : (
                            <MinuscircleIcon
                              className='cursor-pointer'
                              onClick={() => {
                                setAllowance(
                                  allowance.map((item) =>
                                    item.id === eachDeduction.id
                                      ? {
                                          ...item,
                                          component_removed:
                                            !item.component_removed,
                                        }
                                      : item
                                  )
                                );
                                // is_allowance
                                // eslint-disable-next-line
                                {
                                  eachDeduction.is_allowance
                                    ? setNetPay(
                                        parseFloat(netPay) -
                                          parseFloat(`${eachDeduction.value}`)
                                      )
                                    : setNetPay(
                                        parseFloat(netPay) +
                                          parseFloat(`${eachDeduction.value}`)
                                      );
                                }
                              }}
                            />
                          )}
                        </>
                      )}
                      {eachDeduction.component_removed ? (
                        <p className='opacity-50'>
                          {currencyIcon}
                          {eachDeduction.value}
                        </p>
                      ) : (
                        <p>
                          {currencyIcon}
                          {eachDeduction.value}
                        </p>
                      )}
                    </p>
                  </>
                ))}

              {specificPayrollData?.data?.data?.payroll__is_approved ? null : (
                <button
                  disabled={res1}
                  className={
                    !res1
                      ? 'mx-auto col-span-2 my-3'
                      : 'mx-auto col-span-2 my-3 opacity-50 cursor-not-allowed'
                  }
                >
                  <PrimaryButton>Save changes</PrimaryButton>
                </button>
              )}
              {/* {console.log(!isDirty || !res1)} */}
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default SpecificEmployeeModal;
