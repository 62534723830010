import React, { useEffect, useState } from 'react';
import { Group, Modal, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import PrimaryButton from 'components/primaryButton';

import useUpdateEmployeePersonalInfo from '../hooks/useUpdateEmployeePersonalInfo';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as OrangePlusImg } from 'assets/images/orangePlusImg.svg';
import { ReactComponent as UploadedIcon } from 'assets/images/uploadedIcon.svg';

export default function UpdateEmployeeResume({
  isModalOpen,
  setIsModalOpen,
  resume,
}) {
  const [image, setImage] = useState('');
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const closeModal = () => {
    setIsModalOpen(false);
    setImage('');
    setErrorMessage('');
  };

  const updateEmployeeResumeMutation =
    useUpdateEmployeePersonalInfo(closeModal);

  //   custom title for modal
  const customTitle = () => {
    return (
      <>
        <div className='relative flex justify-center items-center text-xl'>
          <LeftArrowIcon
            onClick={closeModal}
            className='w-4 absolute left-0 cursor-pointer'
          />
          <p>Upload CV</p>
        </div>
      </>
    );
  };

  // custom dropzone
  const dropzoneChildren = () => {
    return (
      <div className='flex flex-col items-center py-2'>
        <OrangePlusImg />
        <p className='text-neutralsGrey pt-2'>
          click to upload or simple drag and drop
        </p>
      </div>
    );
  };

  //   dropzone if file selected
  const fileSelected = () => (
    <Group
      position='center'
      spacing='xl'
      style={{ minHeight: 90, pointerEvents: 'none' }}
      className='flex flex-col'
    >
      {/* <XlssucessImage /> */}
      <UploadedIcon />
      <div className='-mt-4 text-sm'>
        <Text size='xs' inline className='text-[#828282]'>
          {image[0]?.name ? image[0]?.name : fileName}
        </Text>
      </div>
    </Group>
  );

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('resume', image[0]);
    if (image !== resume) {
      updateEmployeeResumeMutation.mutate(formData);
    }
  };

  useEffect(() => {
    setImage(resume);
    if (resume && !resume[0].name) {
      let companyLogoNameArr = resume?.split('/');
      setFileName(companyLogoNameArr?.slice(-1)?.pop());
    }
  }, [resume]);

  useEffect(() => {
    setErrorMessage('');
  }, [image]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='lg'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-8',
        }}
      >
        <div className=' my-4'>
          <Dropzone
            onDrop={(files) => setImage(files)}
            onReject={(files) => setErrorMessage(files)}
            maxSize={'5000000'}
            accept={['application/pdf']}
            classNames={{
              root: 'border-primary border-opacity-80',
            }}
            multiple={false}
            >
            {!image ? () => dropzoneChildren() : () => fileSelected()}
          </Dropzone>
          <p className='text-sm pt-1 pl-1 text-red-500'>
            {errorMessage &&
            errorMessage?.[0]?.errors?.[0]?.message ===
              'File type must be application/pdf'
              ? 'File type must be pdf'
              : errorMessage?.[0]?.errors?.[0]
              ? ' File must be less than 5mb'
              : ''}
          </p>
          <div className='flex justify-center mt-8'>
            <button
              className={`${image ? '' : 'opacity-50'} w-32`}
              disabled={!image}
              onClick={() => handleSubmit()}
            >
              <PrimaryButton
                isLoading={updateEmployeeResumeMutation?.isLoading}
              >
                Save
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
