import React from 'react';
import { Modal } from '@mantine/core';
import useChangeSuperAdmin from '../hooks/useChangeSuperAdmin';

import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';

import changeSuperAdminIlls from 'assets/images/changeSuperAdminIlls.png';

export default function ChangeSuperAdminModal({
  isModalOpen,
  setIsModalOpen,
  companyDetails,
}) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const changeSuperAdminMutation = useChangeSuperAdmin(closeModal);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => closeModal()}
        title={''}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-2',
        }}
      >
        <div>
          <p className='text-neutralsBlack text-xl text-center'>
            Do you intend to make <span className='text-primary'>
              {companyDetails?.company__company_name}
            </span> the parent company ?
          </p>
          <p className='text-[#979797] text-center w-3/4 mx-auto pt-4'>
            On making it a parent company you will be logged out of the account
            and <span className='font-bold text-lg'>
              {companyDetails?.company__company_name}
            </span> will become the super admin
          </p>
          <div className='pt-4'>
            <img src={changeSuperAdminIlls} alt='' />
          </div>
          <div className='flex space-x-8 justify-center mt-12 mb-4'>
            <button
              className='w-32'
              onClick={() =>
                changeSuperAdminMutation.mutate({
                  company_id: companyDetails?.company__id,
                })
              }
            >
              <SecondaryButton isLoading={changeSuperAdminMutation?.isLoading}>
                Yes
              </SecondaryButton>
            </button>
            <button className='w-32' onClick={() => closeModal()}>
              <PrimaryButton>No</PrimaryButton>
            </button>
            <button></button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
