import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetDesignations(id) {
  return useQuery(
    ['get-designations', id],
    async () => {
      const res = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_DESIGNATIONS, {
          department_id: id,
        })
      );
      return res.data;
    },
    {
      enabled: id && (id !== 'undefined' || id !== undefined) ? true : false,
    }
  );
}
