import React from 'react';
import { ReactComponent as AttendanceImg } from 'assets/images/attendance.svg';

export default function Attendance() {
  return (
    <div className='bg-white shadow-four rounded-[10px] py-5 px-9 relative min-h-[280px]'>
      <p className='text-18 font-semibold text-222222'>Attendance</p>
      <div className='flex flex-col items-center absolute left-1/2 top-1/2 -translate-y-1/2	-translate-x-1/2'>
        <div>
          <AttendanceImg />
          <p className='text-gray3 mt-3 text-center'>coming soon</p>
        </div>
      </div>
    </div>
  );
}
