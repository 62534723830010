// import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetemployeepolicies(searchInput) {
  return useQuery(['employee-policies', searchInput], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_PLCICIES, {
        search: searchInput,
      })
    );

    return response.data;
  });
}
