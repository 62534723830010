import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useGetAllGrades() {
  return useQuery('settings-salary-grades', async () => {
    const response = await makeApiRequest.get(
      apiEndPoints.SETTINGS_GRADES
    );
    return response?.data;
  });
}
