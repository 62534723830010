import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetpolicies(id) {
  return useQuery(['super-settings-policies', id], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.ORGANISATIONS_POLICY_LIST, {
        company_id: id,
      })
    );
    return response?.data;
  });
}
