import { Table } from '@mantine/core';

import { ReactComponent as RightIcon } from 'assets/icons/rightIconcircle.svg';
import { ReactComponent as NoPayrollData } from 'assets/icons/noPayrollData.svg';
import { ReactComponent as EightIcon } from 'assets/icons/editIcon.svg';
import Usegetpayroll from '../hooks/usegetpayroll';
import CreatePayrollModal from './createpayrollmodal';
import { useContext, useState } from 'react';
import ApprovePayroll from './aprovepayrollmodal';
import { AuthContext } from 'context/authContext';

import getSymbolFromCurrency from 'currency-symbol-map';

const RunPayrollTable = ({ filterDate }) => {
  const { authState } = useContext(AuthContext);
  const currencyIcon = getSymbolFromCurrency(authState?.currency);
  const fromAndToData =
    filterDate.length === 2
      ? filterDate[1] !== null
        ? {
            fromDate: `${filterDate[0].getFullYear()}-${String(
              filterDate[0].getMonth() + 1
            ).padStart(2, '0')}-${String(filterDate[0].getDate()).padStart(
              2,
              '0'
            )}`,
            toDate: `${filterDate[1].getFullYear()}-${String(
              filterDate[1].getMonth() + 1
            ).padStart(2, '0')}-${String(filterDate[1].getDate()).padStart(
              2,
              '0'
            )}`,
          }
        : { fromDate: null, toDate: null }
      : { fromDate: null, toDate: null };

  const getPayrollData = Usegetpayroll(fromAndToData);

  const [isCreatePayrollOpened, setIsCreatePayrollOpened] = useState(false);
  const [approvePayrollOpened, setApprovePayrollOpened] = useState(false);
  const [payrollId, setPayrollId] = useState(null);
  // getPayrollData.isLoading
  const rows = getPayrollData.isLoading ? (
    <>
      <tr>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
        <td class=' rounded-md p-4 max-w-sm gap-4 mt-5'>
          <div class='animate-pulse flex space-x-4'>
            <div class='flex-1 space-y-6 py-1 justify-center items-center flex'>
              <div class='h-2 bg-slate-200 rounded w-20 '></div>
            </div>
          </div>
        </td>
      </tr>
    </>
  ) : (
    getPayrollData?.data?.data.map((eachpayroll) => (
      <tr
        key={eachpayroll.id}
        className={
          eachpayroll.is_approved ? 'text-[#848383]' : 'text-[#FB7C51]'
        }
      >
        <td className='text-center'>
          {eachpayroll.from_date} - {eachpayroll.to_date}
        </td>
        <td className='text-center'>
          {' '}
          {currencyIcon}
          {parseFloat(eachpayroll.total_gross_pay).toFixed(2) ?? 0}
        </td>
        <td className='text-center'>
          {currencyIcon}
          {parseFloat(eachpayroll.total_net_pay).toFixed(2) ?? 0}
        </td>
        <td className='text-center'>{eachpayroll.payment_date}</td>
        <td className='text-center'>{eachpayroll.total_employees ?? 0}</td>
        <td
          className={
            eachpayroll.is_approved
              ? 'flex justify-center items-cente text-[#28B446]  font-medium'
              : 'flex justify-center items-cente text-[#F8CA54]  font-medium'
          }
        >
          {eachpayroll.is_approved ? 'Approved' : 'Pending'}{' '}
          <EightIcon
            className={
              eachpayroll.is_approved
                ? 'ml-2 cursor-not-allowed opacity-50 '
                : 'ml-2 cursor-pointer mt-[2px]'
            }
            onClick={
              eachpayroll.is_approved
                ? null
                : () => {
                    setIsCreatePayrollOpened(true);
                    setPayrollId(eachpayroll.id);
                  }
            }
          />
          <RightIcon
            className='ml-2 cursor-pointer '
            onClick={() => {
              setApprovePayrollOpened(true);
              setPayrollId(eachpayroll.id);
            }}
          />
        </td>
      </tr>
    ))
  );
  return (
    <>
      <div
        className={
          getPayrollData?.data?.data.length === 0
            ? null
            : 'bg-[#ffffff] min-h-[70vh] rounded-lg mt-4 shadow-2xl'
        }
      >
        <Table verticalSpacing='lg' highlightOnHover>
          <thead>
            <tr>
              <th
                className='bg-[#FFE8E1] rounded-tl-xl'
                style={{ textAlign: 'center' }}
              >
                Payroll period
              </th>
              <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                Gross pay
              </th>
              <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                Employees’ net pay
              </th>
              <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                Payment date
              </th>
              <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                Employee count
              </th>
              <th
                className='bg-[#FFE8E1] rounded-tr-xl'
                style={{ textAlign: 'center' }}
              >
                Status
              </th>
            </tr>
          </thead>

          <tbody className=' w-full max-h-20 border-none'>{rows}</tbody>
        </Table>
      </div>
      {getPayrollData?.data?.data.length === 0 ? (
        <div className='flex items-center h-[65vh]'>
          <NoPayrollData />
        </div>
      ) : null}
      <CreatePayrollModal
        isCreatePayrollOpened={isCreatePayrollOpened}
        setIsCreatePayrollOpened={setIsCreatePayrollOpened}
        setPayrollId={setPayrollId}
        payrollId={payrollId}
      />
      <ApprovePayroll
        approvePayrollOpened={approvePayrollOpened}
        setApprovePayrollOpened={setApprovePayrollOpened}
        setPayrollId={setPayrollId}
        payrollId={payrollId}
      />
    </>
  );
};

export default RunPayrollTable;
