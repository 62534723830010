import { useContext } from "react";
import { useQuery } from "react-query";
import apiEndPoints from "services/apiEndPoints";
import makeApiRequest from "services/makeApiRequest";

import { AuthContext } from "context/authContext";

import createQueryParamsForGetReq from "helper/createQueryParamsForGetReq";

export default function useGetEmployeeLeaves() {
  const { authState } = useContext(AuthContext);
  return useQuery(["get-employee-leaves"], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_LEAVES_API, {
        id: authState?.employee_id,
      })
    );
    return res.data;
  });
}
