import React, { useContext } from 'react';
import { AuthContext } from 'context/authContext';
import { TreeNode } from 'react-organizational-chart';
import { Avatar, Divider } from '@mantine/core';

// Here we are using Recursion to check if the children has any children.
// If the children does not have any inner children, then we will just send that empolyee/children/item.
// If the children has inner children, then we send the employee/children/item
//   and call the TreeCard component again.
export default function TreeCard({ child }) {
  const { authState } = useContext(AuthContext);

  // Base condition
  if (child?.children?.length === 0) {
    return (
      <TreeNode
        key={child?.id}
        label={
          <div className='m-4 relative'>
            <div
              className={`flex items-center justify-center space-x-3 p-4 ${
                child?.id === authState?.employee_id && 'bg-[#FFE8E1]'
              }`}
            >
              <Avatar
                src={child?.profile_picture}
                size='45px'
                color='red'
                radius='xl'
                className='capitalize'
              >
                {child?.name[0]}
              </Avatar>
              <div className='text-left'>
                <p className='text-[#4D4D4D]'>{child?.name}</p>
                <p className='text-[#8D8D8D]'>{child?.designation__name}</p>
              </div>
            </div>
            <Divider className='w-120 border-2 rounded border-primary absolute top-0 -left-1.5' />
            <Divider
              orientation='vertical'
              className='w-120h border-2 border-primary absolute -top-2'
            />
            <Divider
              orientation='vertical'
              className='w-120h border-2 border-primary absolute -top-2 right-0'
            />
            <Divider className='w-120 border-2 border-primary absolute bottom-0 -left-1.5' />
          </div>
        }
      ></TreeNode>
    );
  } else {
    return (
      <>
        <TreeNode
          key={child?.id}
          label={
            <div className='m-4 relative w-fit mx-auto'>
              <div
                className={`flex items-center justify-center space-x-3 p-4 ${
                  child?.id === authState?.employee_id && 'bg-[#FFE8E1]'
                }`}
              >
                <Avatar
                  src={child?.profile_picture}
                  size='45px'
                  color='red'
                  radius='xl'
                  className='capitalize'
                >
                  {child?.name[0]}
                </Avatar>
                <div className='text-left'>
                  <p className='text-[#4D4D4D]'>{child?.name}</p>
                  <p className='text-[#8D8D8D]'>{child?.designation__name}</p>
                </div>
              </div>
              <Divider className='w-120 border-2 rounded border-primary absolute top-0 -left-1.5' />
              <Divider
                orientation='vertical'
                className='w-120h border-2 border-primary absolute -top-2'
              />
              <Divider
                orientation='vertical'
                className='w-120h border-2 border-primary absolute -top-2 right-0'
              />
              <Divider className='w-120 border-2 border-primary absolute bottom-0 -left-1.5' />
            </div>
          }
        >
          {child?.children?.map((innerChild) => {
            return <TreeCard key={innerChild?.id} child={innerChild} />;
          })}
        </TreeNode>
      </>
    );
  }
}
