import React, { useEffect } from 'react';
import { Modal, Select, TextInput } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import DepartmentBgImg from 'assets/images/departmentModalBg.svg';
import useAddDepartment from '../../hooks/useAddDepartment';
import useEditDepartment from '../../hooks/useEditDepartment';
import useOrganisationsList from 'pages/organisationSettings/hooks/useOrganisationsList';
import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow2.svg';

export default function DepartmentModal({
  isModalOpen,
  setIsModalOpen,
  isEditData,
  setEditData,
  company_id,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    setValue,
  } = useForm();

  const defaultvalues = { inputValue: '', req_company_id: '' };

  const closeModal = () => {
    reset(defaultvalues);
    setEditData(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isEditData) {
      reset({
        inputValue: isEditData?.name || '',
        req_company_id: `${isEditData?.company_id}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditData]);
  useEffect(() => {
    setValue('req_company_id', `${company_id}`);
    // eslint-disable-next-line
  }, [company_id, isModalOpen]);

  const addDepartmentMutation = useAddDepartment(closeModal);
  const editDepartmentMutation = useEditDepartment(closeModal);

  const organisationsList = useOrganisationsList();

  const formSubmitHandler = (data) => {
    if (!isEditData) {
      addDepartmentMutation.mutate({
        name: data.inputValue,
        req_company_id: data.req_company_id,
      });
    }

    if (isEditData) {
      isDirty &&
        editDepartmentMutation.mutate({
          id: isEditData?.id,
          name: data?.inputValue,
          // req_company_id: data.company_id,
        });
    }
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={closeModal}
      title={isEditData ? 'Edit department' : 'Add a department'}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size={500}
      radius='md'
      transitionDuration={100}
      classNames={{
        title: 'mx-auto text-primary font-semibold text-18',
        modal: 'px-10',
      }}
      styles={{
        modal: {
          backgroundImage: `url(${DepartmentBgImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '300px 50px',
          backgroundSize: '150px',
        },
      }}
    >
      <div className='relative'>
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <div className='mt-6'>
            <Controller
              control={control}
              name='req_company_id'
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  label='Organisation'
                  name='req_company_id'
                  size='md'
                  clearable
                  disabled
                  data={
                    organisationsList?.data?.data?.map((organisation) => {
                      return {
                        value: `${organisation?.company__id}`,
                        label: organisation?.company__company_name,
                      };
                    }) || []
                  }
                  rightSection={<DownArrowIcon />}
                  classNames={{
                    input: 'border-primary',
                    label: 'text-neutralsGrey',
                  }}
                  styles={{
                    rightSection: {
                      pointerEvents: 'none',
                    },
                  }}
                />
              )}
            />

            {errors.req_company_id && (
              <p className='text-sm text-red-500 font-semibold'>* Required</p>
            )}
          </div>
          <Controller
            control={control}
            name='inputValue'
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <TextInput
                {...field}
                label='Name'
                size='md'
                placeholder={
                  isEditData ? 'Edit department' : 'Add a department'
                }
                className='mt-4'
                classNames={{
                  label: 'text-sm text-neutralsGrey',
                  input: 'border-primary bg-transparent',
                }}
              />
            )}
          />

          {errors.inputValue && (
            <p className='text-sm text-red-500 font-semibold'>* Required</p>
          )}

          <div className='flex justify-center'>
            <button className='mt-8 mb-2 w-24'>
              <PrimaryButton
                isLoading={
                  addDepartmentMutation?.isLoading ||
                  editDepartmentMutation?.isLoading
                }
              >
                <p>Save</p>
              </PrimaryButton>
            </button>
          </div>
        </form>
        <LeftArrowIcon
          className='absolute -top-[62px] h-5 w-5 cursor-pointer mt-5'
          onClick={closeModal}
        />
      </div>
    </Modal>
  );
}
