import { Modal, TextInput } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import { useForm } from 'react-hook-form';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';

import UseAddHolidayType from '../hooks/useaddholidays';

const HolidayTypeModal = ({
  isHolidayModalOpened,
  setIsHolidayModalOpened,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    // watch,
    reset,
  } = useForm();
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[550px]'>
        <LeftArrowIcon
          onClick={() => setIsHolidayModalOpened(false)}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className='text-primary'>Add a holiday type</p>
      </div>
    );
  };

  const closeHolidaymodal = () => {
    setIsHolidayModalOpened(false);
    reset({ name: '' });
  };

  // mutations

  const addHolidayMutation = UseAddHolidayType(closeHolidaymodal);

  const saveHoliday = (data) => {
    addHolidayMutation.mutate(data);
  };

  return (
    <div>
      <Modal
        opened={isHolidayModalOpened}
        onClose={closeHolidaymodal}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size='lg'
        overflow='inside'
        className='px-8'
        classNames={{
          title: 'text-lg font-semibold ',
          body: 'scroller',
        }}
      >
        <form
          className='flex justify-center flex-col mt-5'
          onSubmit={handleSubmit(saveHoliday)}
        >
          <div>
            <TextInput
              {...register('name', { required: 'Required' })}
              label='Name'
              className='w-96 mx-auto'
              classNames={{
                input:
                  'h-[50px] text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5',
                label: 'text-[#8E8E8E] text-sm font-normal ml-2',
              }}

              //   styles={{ rightSection: { pointerEvents: 'none' } }}
            />
            {errors.name && (
              <p className='text-sm  text-[#fc0101] ml-[110px]'>
                {errors.name.message}
              </p>
            )}
          </div>
          <button className='mt-20 mb-5  mx-auto w-24 '>
            <PrimaryButton>Save</PrimaryButton>
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default HolidayTypeModal;
