import { useMutation, useQueryClient } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { ErrorToast, SuccessToast } from 'services/toasterService';

export default function useUpdateSkillDevelopment(cb) {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(
        apiEndPoints.SKILL_DEVELOPMENT_API,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data?.message });

        queryClient.invalidateQueries('employee-skills-development');

        cb();
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
