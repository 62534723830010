import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowScroll } from '@mantine/hooks';

const ScrollToTop = (props) => {
  const location = useLocation();
  const [, scrollTo] = useWindowScroll();
  useEffect(() => {
    scrollTo({ y: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);
  return <>{props.children}</>;
};
export default ScrollToTop;
