import { useInfiniteQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetLeavePolicy() {
  return useInfiniteQuery(
    ['get-leave-policy'],
    async ({ pageParam }) => {
      const res = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.POLICIES_API, {
          name: 'Leave Policy',
        })
      );
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.last_page ? undefined : lastPage?.last_id;
      },
    }
  );
}
