import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetDomains() {
  return useQuery('Domains-profile', async () => {
    const response = await makeApiRequest.get(apiEndPoints.DOMAIN_API);

    return response.data;
  });
}
