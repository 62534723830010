import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useUpcomingEvents() {
  return useQuery('dashboard-upcoming-events', async () => {
    const response = await makeApiRequest.get(
      apiEndPoints.DASHBOARD_UPCOMING_EVENTS
    );
    return response?.data;
  });
}
