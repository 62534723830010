import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/crossIcon2.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';

import { Select } from '@mantine/core';
import { useState } from 'react';
import NewSKillTargetModal from './newSkillTargetModal';

// import useGetAllSkillTarget from '../hooks/useGetAllSkillTarget';

const SkillTarget = () => {
  const [addSkillTarget, setAddSkillTarget] = useState(false);
  return (
    <div>
      <div className='flex justify-between items-center'>
        <h1 className='text-[#222222] font-bold text-lg'>Skill target</h1>
        <div className='flex items-center gap-3'>
          <p
            className='text-[#67A1EF] cursor-pointer '
            onClick={() => setAddSkillTarget(!addSkillTarget)}
          >
            Add new
          </p>
          <Select
            data={[
              '2020',
              '2021',
              '2022',
              '2023',
              '2024',
              '2025',
              '2026',
              '2027',
              '2028',
              '2029',
              '2030',
            ]}
            // value={filterValue}
            // onChange={setFilterValue}
            icon={<FilterIcon />}
            // clearable
            rightSection={<DownArrowIcon />}
            className='w-40'
            classNames={{
              rightSection: 'pointer-events-none',
              wrapper: 'border-primary border-b',
              input: '  bg-light-bg border-none',
            }}
          />
        </div>
      </div>
      <div className='mt-4 flex justify-between items-center'>
        <p className='text-[#707070]'>
          Editing
          <br />
          22 May 22 - 22 Jun 22
        </p>
        <div className='flex justify-center items-center gap-3'>
          <Select
            name='status'
            data={[
              { value: 'Inprogress', label: 'Inprogress' },
              { value: 'Add to my skill set', label: 'Add to my skill set' },
            ]}
            //   value={selectEvent}
            //   onChange={(event) => setSelectEvent(event)}
            // icon={<FilterIcon />}
            rightSection={<DownArrowIcon />}
            // value={filterYear}
            // onChange={setFilterYear}
            className='w-44'
            classNames={{
              rightSection: 'pointer-events-none',
              wrapper: 'border-primary border-b',
              input: 'bg-inherit text-[#28B446] text-bold border-none',
            }}
          />
          <EditIcon
            className='h-5 w-5 -mb-4 cursor-pointer'
            onClick={() => setAddSkillTarget(!addSkillTarget)}
          />
          <CrossIcon className='h-4 w-4 -mb-4 cursor-pointer' />
        </div>
      </div>
      <NewSKillTargetModal
        addSkillTarget={addSkillTarget}
        setAddSkillTarget={setAddSkillTarget}
      />
    </div>
  );
};

export default SkillTarget;
