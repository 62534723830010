import React, { useEffect, useState } from 'react';
import { Dropzone } from '@mantine/dropzone';
import { Group, Text } from '@mantine/core';

import { ReactComponent as OrangePlusImg } from 'assets/images/orangePlusImg.svg';
import { ReactComponent as UploadedIcon } from 'assets/images/uploadedIcon.svg';

export default function FileUpload({ multiple, setImage, value }) {
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const dropzoneChildren = () => {
    return (
      <div className='flex flex-col items-center py-2'>
        <OrangePlusImg />
        <p className='text-neutralsGrey pt-2'>
          click to upload or simple drag and drop
        </p>
        <p className='text-neutralsGrey pt-2 text-sm'>
          Only *.pdf files less than 5Mb are accepted
        </p>
      </div>
    );
  };

  const fileSelected = () => (
    <Group
      position='center'
      spacing='xl'
      style={{ minHeight: 90, pointerEvents: 'none' }}
      className='flex flex-col'
    >
      {/* <XlssucessImage /> */}
      <UploadedIcon />
      <div className='-mt-4 text-sm'>
        <Text size='xs' inline className='text-[#828282]'>
          {value[0].name ? value[0].name : fileName}
        </Text>
      </div>
    </Group>
  );

  useEffect(() => {
    if (value && !value[0].name) {
      let companyLogoNameArr = value?.split('/');
      setFileName(companyLogoNameArr?.slice(-1)?.pop());
    }
  }, [value]);

  return (
    <div>
      <Dropzone
        onDrop={(files) => {
          setImage(files);
          setErrorMessage('');
        }}
        onReject={(files) => {
          setImage(null);
          setErrorMessage(files);
        }}
        maxSize={5000000}
        accept={['application/pdf']}
        classNames={{
          root: 'border-primary border-opacity-80',
        }}
        multiple={multiple}
      >
        {!value ? () => dropzoneChildren() : () => fileSelected()}
      </Dropzone>
      <p className='text-sm pt-1 pl-1 text-red-500'>
        {errorMessage &&
        errorMessage?.[0]?.errors?.[0]?.message ===
          'File type must be application/pdf'
          ? 'Only *.pdf files are accepted'
          : errorMessage?.[0]?.errors?.[0]
          ? ' File must be less than 5mb'
          : ''}
      </p>
    </div>
  );
}
