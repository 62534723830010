import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { useParams } from 'react-router-dom';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetProfessionalExperienceDetails() {
  const { id } = useParams();

  return useQuery(['employee-ProfessionalExperience'], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(
        apiEndPoints.EMPLOYEE_PROFESSIONAL_EXPERIENCE,
        {
          employee_id: id,
        }
      )
    );
    return res.data;
  });
}
