import React, { useState } from 'react';
import PrimaryButton from 'components/primaryButton';
import { Select } from '@mantine/core';
import dayjs from 'dayjs';
import CancelLeaveRequest from '../components/cancelLeaveRequest';
import Header from 'components/Header';

import useGetEmployeeLeaves from '../hooks/useGetEmployeeLeaves';
import useGetEmployeeAppliedLeaves from '../hooks/useGetEmployeeAppliedLeaves';
import ApplyLeaveModal from '../components/applyLeaveModal';

import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as WrongIcon } from 'assets/icons/wrongIcon.svg';
import { ReactComponent as EmptyLeavesIlls } from 'assets/images/emptyLeaveIlls.svg';
import noLeavesAllowedIlls from 'assets/images/noLeavesAllowedIlls.png';
import useGetLeavePolicy from '../hooks/useGetLeavePolicy';
import ViewPolicyModal from 'pages/organisation/pages/keyPolicies/components/viewpolicymodal';

export default function EmployeeLeaves() {
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [leaveTypeFilter, setLeaveTypeFilter] = useState('');
  const [monthFilterValue, setMonthFilterValue] = useState('');
  const [yearFilterValue, setYearFilterValue] = useState(
    new Date().getFullYear()
  );
  const [modalName, setModalName] = useState('Apply');
  const [selectedLeaveData, setSelectedLeaveData] = useState('');
  const [isCancelRequestModalOpen, setIsCancelRequestModalOpen] =
    useState(false);
  const [viewLeavePolicyModal, setLeavePolicyModal] = useState(false);

  const employeesLeavesData = useGetEmployeeLeaves();

  const getEmployeeAppliedLeaves = useGetEmployeeAppliedLeaves(
    leaveTypeFilter,
    monthFilterValue,
    yearFilterValue
  );

  const getLeaveTypes = () => {
    const finalArray = [{ value: null, label: 'All' }];
    employeesLeavesData?.data?.data?.[0]?.leaves_info?.map((leave) => {
      finalArray.push({
        value: `${leave.id}`,
        label: leave.leave_type,
      });
      return true;
    });
    return finalArray;
  };

  const getPoliciesData = useGetLeavePolicy();
  // calculating years
  let minYear = getEmployeeAppliedLeaves?.data?.years[1];
  let maxYear = getEmployeeAppliedLeaves?.data?.years[0];
  // console.log('minYear:', minYear, 'maxYear:', maxYear);

  const appliedYears = [];
  if (minYear === maxYear) {
    appliedYears.push({ value: minYear, label: minYear });
  } else {
    for (let i = minYear; i <= maxYear; i++) {
      appliedYears.push({ value: minYear, label: minYear });
      minYear++;
    }
  }

  //calculating months
  let maxMonth = getEmployeeAppliedLeaves?.data?.max_month;

  let monthNames = [];
  const months = [
    { value: null, label: 'All' },
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];
  if (yearFilterValue) {
    if (yearFilterValue === maxYear) {
      monthNames.push({ value: null, label: 'All' });
      for (let i = 1; i <= maxMonth; i++) {
        monthNames.push(months[i]);
      }
    } else if (maxMonth === null) {
      monthNames = [
        {
          value: 'no data',
          label: 'No data',
          disabled: true,
        },
      ];
    } else {
      monthNames = months;
    }
  }
  // console.log('maxMonth', maxMonth);

  return (
    <div className='px-5'>
      <div className=' mt-6 flex justify-between items-center'>
        <p className='text-xl text-A8A8A8'>
          Calendar &#60; <span className='text-gray font-semibold'>Leaves</span>
        </p>
        <Header />
      </div>
      <div className='w-full flex items-center space-x-8 mt-8'>
        {getPoliciesData?.data?.pages[0]?.data[0]?.policy_document ? (
          <p
            className='ml-auto text-67A1EF text-18 select-none cursor-pointer'
            onClick={() => setLeavePolicyModal(true)}
          >
            View leave policy
          </p>
        ) : (
          <p className='ml-auto text-67A1EF text-18 select-none cursor-not-allowed opacity-50'>
            View leave policy
          </p>
        )}
        <button
          onClick={() => {
            setModalName('Apply');
            setSelectedLeaveData('');
            setIsLeaveModalOpen(true);
          }}
          className={`${
            !employeesLeavesData?.data?.data?.length ? 'opacity-60' : ''
          }`}
          disabled={!employeesLeavesData?.data?.data?.length}
        >
          <PrimaryButton>+ Apply leave</PrimaryButton>
        </button>
      </div>
      {employeesLeavesData?.isLoading ? (
        <div className='py-4 min-h-[22vh] shadow-lg mt-6 border border-8B8B8B border-opacity-10 rounded-xl bg-white flex flex-wrap'>
          <div className='w-1/4 animate-pulse flex flex-col items-center justify-center'>
            <div className='rounded-lg bg-slate-100 h-16 w-40'></div>
            <div className='rounded-lg bg-slate-100 h-8 w-56 mt-5'></div>
          </div>
          <div className='w-1/4 animate-pulse flex flex-col items-center justify-center'>
            <div className='rounded-lg bg-slate-100 h-16 w-40'></div>
            <div className='rounded-lg bg-slate-100 h-8 w-56 mt-5'></div>
          </div>
          <div className='w-1/4 animate-pulse flex flex-col items-center justify-center'>
            <div className='rounded-lg bg-slate-100 h-16 w-40'></div>
            <div className='rounded-lg bg-slate-100 h-8 w-56 mt-5'></div>
          </div>
          <div className='w-1/4 animate-pulse flex flex-col items-center justify-center'>
            <div className='rounded-lg bg-slate-100 h-16 w-40'></div>
            <div className='rounded-lg bg-slate-100 h-8 w-56 mt-5'></div>
          </div>
        </div>
      ) : (
        <div className='py-4 min-h-[22vh] shadow-lg mt-6 border border-8B8B8B border-opacity-10 rounded-xl bg-white flex flex-wrap'>
          {employeesLeavesData?.data?.data?.[0]?.leaves_info?.length ? (
            <>
              {employeesLeavesData?.data?.data?.[0]?.leaves_info?.map(
                (item, index) => {
                  return (
                    <div
                      className={`${
                        employeesLeavesData?.data?.data?.[0]?.leaves_info
                          ?.length > 5
                          ? 'w-1/5'
                          : employeesLeavesData?.data?.data?.[0]?.leaves_info
                              ?.length === 1
                          ? 'w-full'
                          : `w-1/${employeesLeavesData?.data?.data?.[0]?.leaves_info?.length}`
                      }  ${
                        index === 4 ? '' : 'border-r border-r-[#C5C5C5]'
                      } my-4 text-center`}
                      key={item?.id}
                    >
                      <p className='text-primary font-bold text-3xl'>
                        {item?.remaining_days}/{item?.total_days}
                      </p>
                      <p className='text-lg text-222222 pt-2'>
                        {item?.leave_type}
                      </p>
                      <div className='flex justify-center items-center space-x-1 xl:space-x-4 mt-2'>
                        <p className='text-A3A3A3 text-sm'>
                          Available (this month)
                        </p>
                        <p className='text-bold text-2xl text-A3A3A3'>
                          {item?.remaining_this_month < 10
                            ? `0${item?.remaining_this_month}`
                            : item?.remaining_this_month}
                        </p>
                      </div>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            <div
              style={{
                backgroundImage: 'URL(' + noLeavesAllowedIlls + ')',
                backgroundSize: '100% 100%',
              }}
              className='w-full h-[20vh] flex items-center justify-center'
            >
              <p className='text-xl pb-5 pl-10 text-gray'>
                No active leave policies to show
              </p>
            </div>
          )}
        </div>
      )}
      <div className='mt-8 px-2 flex items-center justify-between'>
        <p className='text-22 font-semibold text-222222 '>Applied leaves</p>
        {getEmployeeAppliedLeaves?.data?.data?.length ||
        leaveTypeFilter?.length ||
        monthFilterValue ||
        yearFilterValue ? (
          <div className='flex space-x-4'>
            <Select
              placeholder='Leave type'
              icon={<FilterIcon />}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              rightSection={<DownArrow />}
              onChange={setLeaveTypeFilter}
              data={
                !employeesLeavesData?.data?.data?.[0]?.leaves_info?.length
                  ? []
                  : getLeaveTypes()
              }
              classNames={{
                wrapper: 'border-primary border-b',
                input: 'w-40 bg-light-bg border-none',
                dropdown: 'h-40',
              }}
            />
            <Select
              placeholder='Month'
              icon={<FilterIcon />}
              onChange={setMonthFilterValue}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              rightSection={<DownArrow />}
              clearable
              data={monthNames}
              classNames={{
                wrapper: 'border-primary border-b',
                input: 'w-40 bg-light-bg border-none',
              }}
            />
            <Select
              placeholder='Year'
              icon={<FilterIcon />}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              rightSection={<DownArrow />}
              onChange={setYearFilterValue}
              clearable
              data={
                appliedYears[0]?.value
                  ? appliedYears
                  : [
                      {
                        value: 'no data',
                        label: 'No data',
                        disabled: true,
                      },
                    ]
              }
              classNames={{
                wrapper: 'border-primary border-b',
                input: 'w-40 bg-light-bg border-none',
              }}
            />
          </div>
        ) : null}
      </div>
      {getEmployeeAppliedLeaves?.data?.data?.length ? (
        <div className='my-6'>
          <table className='w-full rounded-xl bg-white shadow-lg'>
            <tbody>
              <tr className='bg-FFE8E1 text-left text-18 text-222222'>
                <th className='py-4 pl-5 rounded-tl-xl'>Leave type </th>
                <th className=' pl-5'>From</th>
                <th className=' pl-5'>To</th>
                <th className=' pl-5'>Duration</th>
                <th className=' pl-5'>Reason</th>
                <th className=' pl-5'>Leave status</th>
                <th className=' pl-8 rounded-tr-xl'>Action</th>
              </tr>
              {getEmployeeAppliedLeaves?.data?.data?.map((item, index) => (
                <tr className='text-9B9B9B' key={index}>
                  <td>
                    <div className='flex space-x-1 items-center pl-4'>
                      <p className='pl-1 text-primary'>
                        {item?.leave_type__name}
                      </p>
                    </div>
                  </td>
                  <td className=' pl-5 '>
                    {dayjs(item?.from_date).format('DD MMM YY')}
                  </td>
                  <td className='py-5 pl-5'>
                    {dayjs(item?.to_date).format('DD MMM YY')}
                  </td>
                  <td className='pl-5'>
                    <div>
                      <p>
                        {item?.number_of_days < 2
                          ? `${item?.number_of_days} day`
                          : `${item?.number_of_days} days`}
                      </p>
                    </div>
                  </td>
                  <td className='py-5 pl-5 break-all w-72'>{item?.reason}</td>
                  <td className='pl-5 '>
                    {item?.status === 'Pending' ||
                    item?.status === 'Cancelled' ? (
                      <p className='text-warning'>{item?.status}</p>
                    ) : item?.status === 'Approved' ? (
                      <div>
                        <p className='text-success'>{item?.status}</p>
                        <p>by {item?.updated_by__first_name}.</p>
                      </div>
                    ) : (
                      <div>
                        <p className='text-[#BC2C18]'>{item?.status}</p>
                        <p>by {item?.updated_by__first_name}.</p>
                      </div>
                    )}
                  </td>
                  <td className='pl-8 text-primary'>
                    {item?.status === 'Pending' ? (
                      <div className='flex space-x-6 items-center'>
                        <EditIcon
                          className='w-5 cursor-pointer'
                          onClick={() => {
                            setModalName('Edit');
                            setSelectedLeaveData(item);
                            setIsLeaveModalOpen(true);
                          }}
                        />
                        <WrongIcon
                          className='w-5 cursor-pointer'
                          onClick={() => {
                            setSelectedLeaveData(item);
                            setIsCancelRequestModalOpen(true);
                          }}
                        />
                      </div>
                    ) : (
                      <div className='flex space-x-6 opacity-30 items-center'>
                        <EditIcon className='w-5' />
                        <WrongIcon className='w-5' />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='flex flex-col justify-center items-center h-[40vh] 2xl:h-[50vh] rounded-xl bg-white shadow-lg my-6 border border-8B8B8B border-opacity-10'>
          <EmptyLeavesIlls className='w-72' />
          <p className='text-lg text-gray'>No applied leaves to show</p>
        </div>
      )}
      <ApplyLeaveModal
        isModalOpen={isLeaveModalOpen}
        setIsModalOpen={setIsLeaveModalOpen}
        employeeLeavesInfo={employeesLeavesData?.data?.data?.[0]?.leaves_info}
        leavePolicyInfo={employeesLeavesData?.data?.data[1]}
        modalName={modalName}
        setSelectedLeaveData={setSelectedLeaveData}
        selectedLeaveData={selectedLeaveData}
      />
      <CancelLeaveRequest
        isModalOpen={isCancelRequestModalOpen}
        setIsModalOpen={setIsCancelRequestModalOpen}
        leaveData={selectedLeaveData}
      />

      <ViewPolicyModal
        viewPolicyModal={viewLeavePolicyModal}
        setViewPolicyModal={setLeavePolicyModal}
        policyDocument={
          getPoliciesData?.data?.pages[0]?.data[0]?.policy_document
        }
        name='Leave policy'
      />
    </div>
  );
}
