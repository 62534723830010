import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useGetAllDepartments(id, dataArray) {
  return useQuery(['settings-departments', id, dataArray], async () => {
    const response = await makeApiRequest.get(`${apiEndPoints.SETTINGS_DEPARTMENTS}?req_company_id=${id}`
    );
    return response?.data;
  });
}
