import { useInfiniteQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useContext } from 'react';
import { AuthContext } from 'context/authContext';

export default function useGetAllTeamMembers(id, isActive, searchValue) {
  const { authState } = useContext(AuthContext);

  const [searchParams] = useSearchParams();
  const filterValue = searchParams.get('filterValue');

  return useInfiniteQuery(
    ['get-all-team-members', id, searchValue, filterValue],
    async ({ pageParam }) => {
      const res = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.TEAM_MEMBERS_API, {
          id: id,
          user__is_active: isActive,
          department: filterValue,
          search: searchValue,
          id__lt: pageParam,
          reports_to_id: authState?.is_admin ? undefined : authState?.user_id,
        })
      );
      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.last_page ? undefined : lastPage?.last_id;
      },
      enabled: !!authState?.is_admin || !!authState?.is_subadmin,
    }
  );
}
