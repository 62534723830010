import { useMutation, useQueryClient } from "react-query";
import apiEndPoints from "services/apiEndPoints";
import makeApiRequest from "services/makeApiRequest";
import { SuccessToast, ErrorToast } from "services/toasterService";

export default function useApplyLeave(closeModal, reset) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.post(
        apiEndPoints.EMPLOYEE_LEAVES_API,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        closeModal();
        reset({});
        SuccessToast({ text: data?.message });
        queryClient.invalidateQueries("get-employee-applied-leaves");
        queryClient.invalidateQueries("get-employee-leaves");
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
