import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetListofOrganisitions(id) {
  return useQuery(['grade_data_from_setting_in_organisition', id], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.LIST_OF_ORGANISATION_API, {
        company_id: id,
      })
    );

    return response.data;
  });
}
