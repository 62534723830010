import React from 'react';

import useGetListofOrganisitions from 'pages/organisationSettings/hooks/useGetlistoforganisitions';
import TabsEmployeeDetails from './components/tabsEmployeeDetails';
// import DepartmentModal from './departmentModal';

function EmployeeDetailsTab({ company_id }) {
  const listOfOrganisitions = useGetListofOrganisitions(company_id);

  return (
    <div className='shadow-xl border border-8B8B8B border-opacity-20 rounded-xl overflow-hidden mb-8 bg-white min-h-[70vh] departments-super-admin'>
      {/* <div className='flex px-10 bg-FFE8E1 h-16 items-center text-333333 font-semibold'>
        <div className='flex w-1/4'>Organisation name</div>
        <div className='flex w-1/4'>Location</div>
        <div className='flex w-2/6'></div>
        <div className='flex w-1/6 justify-center'>Action</div>
      </div> */}
      {/* <Accordion iconPosition='right' icon={false} transitionDuration={300}> */}
      {listOfOrganisitions?.data?.data?.length === 0 ? (
        <div className='bg-white h-96 flex flex-col items-center justify-center space-y-10 rounded-3xl'>
          <div>
            <p className='text-center mt-2'>No data to show</p>
          </div>
        </div>
      ) : (
        listOfOrganisitions?.data?.data?.map((organisation, index) => {
          return (
            // <Accordion.Item
            //   sx={{
            //     backgroundColor: '#FAFAFA',
            //     margin: '1rem',
            //     borderBottom: 0,
            //     borderRadius: '0.25rem',
            //   }}
            //   label={
            //     <AccordionItem
            //       openedItemID={openedItemID}
            //       organisation={organisation}
            //     />
            //   }
            //   onClick={() => {
            //     setOpenedItemID(organisation?.id);
            //   }}
            // >
            <div className='p-2 bg-white'>
              <TabsEmployeeDetails organisation={organisation} />
            </div>
            // </Accordion.Item>
          );
        })
      )}
      {/* </Accordion> */}

      {/* <DepartmentModal
        isModalOpen={addDepartmentModal}
        setIsModalOpen={setAddDepartmentModal}
        isEditData={editData}
        setEditData={setEditData}
        // departmentDetails={selectedDepartment}
      /> */}
    </div>
  );
}

export default EmployeeDetailsTab;
