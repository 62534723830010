import { AuthContext } from 'context/authContext';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetMySkills() {
  const { authState } = useContext(AuthContext);

  return useQuery(['employee-skills'], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.MY_SKILLS_API, {
        employee_id: authState?.employee_id,
      })
    );

    return response.data;
  });
}
