import {
  Checkbox,
  Modal,
  TextInput,
  Switch,
  MultiSelect,
  Select,
} from '@mantine/core';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';

import PrimaryButton from 'components/primaryButton';

import UseGetEmployeementTypes from '../hooks/useGetEmploymentTypes';

import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { useSearchParams } from 'react-router-dom';

import Useaddleavetype from '../hooks/useAddLeaveType';

const AddLeaveTypeModal = ({ setaddleavetypemodal, addleavetypemodal }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({ mode: 'onChange' });

  const defaultValues = {
    activate_after_days: '',
    at_a_time_max_limit: '',
    at_a_time_min_limit: '',
    carry_forward_days: '',
    is_active_after_days: false,
    is_carry_forward: false,
    is_paid: false,
    monthly_days: '',
    name: '',
    restricted_employment: '',
    should_be_applied_before_days: '',
    total_days: '',
  };

  const [searchParams] = useSearchParams();
  //   console.log(searchParams.get('id'));

  const closeleavetypemodal = () => {
    setaddleavetypemodal(false);
    reset(defaultValues);
  };

  const employeementTypesData = UseGetEmployeementTypes();
  const addleaveTypemutation = Useaddleavetype(closeleavetypemodal);
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[750px]'>
        <LeftArrowIcon
          onClick={() => setaddleavetypemodal(false)}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className='text-primary'>Adding leave type</p>
      </div>
    );
  };

  const renderFormErrorMsg = (err) => {
    return (
      err && (
        <p className='text-xs mt-1 text-error text-red-600'>{err.message}</p>
      )
    );
  };

  const addLeaveType = (data) => {
    const incrementData =
      data.increment === '0'
        ? { increment_alternate: false, increment_monthly: false }
        : data.increment === '1'
        ? { increment_alternate: true, increment_monthly: false }
        : data.increment === '2'
        ? { increment_alternate: false, increment_monthly: true }
        : null;

    delete data.increment;
    if (data.restricted_employment === undefined) {
      delete data.restricted_employment;
    }
    if (data.restricted_employment === '') {
      delete data.restricted_employment;
    }
    if (data.carry_forward_days === '') {
      delete data.carry_forward_days;
    }
    if (data.activate_after_days === '') {
      delete data.activate_after_days;
    }

    addleaveTypemutation.mutate({
      ...data,
      ...incrementData,
      leave_module_id: searchParams.get('id'),
      is_active: true,
    });
  };

  return (
    <Modal
      opened={addleavetypemodal}
      onClose={closeleavetypemodal}
      title={customTitle()}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      centered
      overlayOpacity={0.5}
      transitionDuration={100}
      size='xl'
      overflow='inside'
      className='px-8'
      classNames={{
        title: 'text-lg font-semibold ',

        body: 'scroller px-5',
      }}
    >
      {employeementTypesData.isLoading ? null : (
        <form onSubmit={handleSubmit(addLeaveType)}>
          <div className='grid grid-cols-2 gap-4'>
            <div className='grid col-span-2'>
              <div>
                <Controller
                  control={control}
                  name='name'
                  rules={{ required: 'Required' }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Leave Name *'
                      size='md'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                        label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      }}
                    />
                  )}
                />
                {renderFormErrorMsg(errors.name)}
              </div>
            </div>
            <div className='grid col-span-2 justify-end'>
              <div className='flex items-center gap-3'>
                <Controller
                  control={control}
                  name='is_paid'
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label='Paid Leave'
                      classNames={{
                        label: 'text-[#8E8E8E] text-sm font-normal',
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='is_notice_period'
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label='Notice Period'
                      classNames={{
                        label: 'text-[#8E8E8E] text-sm font-normal',
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='is_avail_in_probation'
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label='Probation'
                      classNames={{
                        label: 'text-[#8E8E8E] text-sm font-normal',
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='is_work_from_home'
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label='W.F.H'
                      classNames={{
                        label: 'text-[#8E8E8E] text-sm font-normal',
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className=''>
              <Controller
                control={control}
                name='total_days'
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[0-9]+[0-9]*$/,
                    message: 'Only numbers are allowed',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Total Leave Count *'
                    type='number'
                    size='md'
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                    }}
                  />
                )}
              />
              {renderFormErrorMsg(errors.total_days)}
            </div>
            <div className=''>
              <Controller
                control={control}
                name='monthly_days'
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[0-9]+[0-9]*$/,
                    message: 'Only numbers are allowed',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Monthly Limit *'
                    type='number'
                    size='md'
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                    }}
                  />
                )}
              />
              {renderFormErrorMsg(errors.monthly_days)}
            </div>
            <div className=''>
              <Controller
                control={control}
                name='should_be_applied_before_days'
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[0-9]+[0-9]*$/,
                    message: 'Only numbers are allowed',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Apply Before (days) *'
                    type='number'
                    size='md'
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                    }}
                  />
                )}
              />
              {renderFormErrorMsg(errors.should_be_applied_before_days)}
            </div>
            <div className='grid grid-cols-2 gap-3'>
              <div>
                <Controller
                  control={control}
                  name='at_a_time_min_limit'
                  rules={{
                    required: 'Required',
                    min: {
                      value: 1,
                      message: 'Value must greater than 1',
                    },
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                    validate: (value) =>
                      value <= parseInt(watch(`total_days`)) ||
                      'value must be less than leave count',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Min Apply Limit *'
                      type='number'
                      size='md'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                        label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      }}
                    />
                  )}
                />
                {renderFormErrorMsg(errors.at_a_time_min_limit)}
              </div>
              <div>
                <Controller
                  control={control}
                  name='at_a_time_max_limit'
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                    validate: (value) =>
                      (value >= parseInt(watch('at_a_time_min_limit'))) &
                        (value <= parseInt(watch('monthly_days'))) &
                        (value <= parseInt(watch(`total_days`))) ||
                      'Max Apply Limit must be greater than min apply Limit and less than leave count & monthly limit',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Max Apply Limit *'
                      disabled={!watch('at_a_time_min_limit')}
                      type='number'
                      size='md'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                        label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      }}
                    />
                  )}
                />
                {renderFormErrorMsg(errors.at_a_time_max_limit)}
              </div>
            </div>

            <div>
              <Controller
                control={control}
                name='restricted_employment'
                // rules={{ required: 'Required' }}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    data={employeementTypesData?.data?.data
                      ?.filter((dept) => dept?.is_active)
                      .map((dept) => {
                        return { value: `${dept.id}`, label: dept.name };
                      })}
                    label='Restrict Employment Type'
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    rightSection={<DownArrow />}
                    nothingFound='No data to show'
                    size='md'
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                    }}
                  />
                )}
              />
              {renderFormErrorMsg(errors.restricted_employment)}
            </div>

            <div>
              <Controller
                control={control}
                name='increment'
                defaultValue={'0'}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    data={[
                      { value: '0', label: 'No Increment' },
                      { value: '1', label: 'Increment Alternate' },
                      { value: '2', label: 'Increment Monthly' },
                    ]}
                    label='Increment'
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    rightSection={<DownArrow />}
                    size='md'
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                    }}
                  />
                )}
              />
              {renderFormErrorMsg(errors.increment)}
            </div>
            <div className='flex items-center gap-3 '>
              <Controller
                control={control}
                name='is_carry_forward'
                defaultValue={false}
                render={({ field }) => <Switch {...field} className='pt-5' />}
              />
              <div className='w-full'>
                <Controller
                  control={control}
                  name={watch('is_carry_forward') ? 'carry_forward_days' : ''}
                  rules={
                    watch('is_carry_forward')
                      ? {
                          required: 'Required',
                          pattern: {
                            value: /^[0-9]+[0-9]*$/,
                            message: 'Only numbers are allowed',
                          },
                        }
                      : null
                  }
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Carry Forward in ( % )'
                      size='md'
                      type='number'
                      defaultValue={0}
                      disabled={!watch('is_carry_forward')}
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                        label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      }}
                    />
                  )}
                />
                {renderFormErrorMsg(errors.carry_forward_days)}
              </div>
            </div>
            <div className='flex items-center gap-3 w-full'>
              <Controller
                control={control}
                name='is_active_after_days'
                defaultValue={false}
                render={({ field }) => <Switch {...field} className='pt-4' />}
              />
              <div>
                <Controller
                  control={control}
                  name={
                    watch('is_active_after_days') ? 'activate_after_days' : ''
                  }
                  rules={
                    watch('is_active_after_days')
                      ? {
                          required: 'Required',
                          pattern: {
                            value: /^[0-9]+[0-9]*$/,
                            message: 'Only numbers are allowed',
                          },
                        }
                      : null
                  }
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Active For Employees After (days)'
                      size='md'
                      type='number'
                      defaultValue={0}
                      disabled={!watch('is_active_after_days')}
                      className='w-72'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5',
                        label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      }}
                    />
                  )}
                />
                {renderFormErrorMsg(errors.activate_after_days)}
              </div>
            </div>
          </div>
          <div className='flex justify-center mt-10 mb-5'>
            <button className='  w-32'>
              <PrimaryButton isLoading={addleaveTypemutation.isLoading}>
                Save
              </PrimaryButton>
            </button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddLeaveTypeModal;
