import React, { useState } from 'react';
import { Tabs } from '@mantine/core';
import ActiveOrInactiveEmployees from './activeOrInactiveEmployees';

export default function EmployeeDetails() {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className='bg-white shadow-lg border border-gray border-opacity-10 py-4 px-6 rounded-lg'>
      <Tabs
        tabPadding={21}
        onTabChange={setActiveTab}
        classNames={{
          tabsListWrapper: 'border-0',
          tabActive: 'border-2 border-primary font-semibold text-base',
          tabControl: 'text-A8A8A8 leading-5 px-0 mr-4',
          tabLabel: 'text-base text-gray',
        }}
      >
        <Tabs.Tab label='Active Employees'>
          <ActiveOrInactiveEmployees activeTab={activeTab} />
        </Tabs.Tab>
        <Tabs.Tab label='Inactive Employees'>
          <ActiveOrInactiveEmployees activeTab={activeTab} />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}
