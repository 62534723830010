import TeamMemberProfile from 'pages/team/pages/myTeam/components/teamMemberProfile';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminProfile from './pages/adminProfile';
import OrganisationSettingsMain from './pages/organisationSettings';

export default function OrganisationSettings() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<OrganisationSettingsMain />} />
        <Route path='/admin-profile/:company_id' element={<AdminProfile />} />
        <Route
          path='/admin-profile/:company_id/:id'
          element={<TeamMemberProfile />}
        />
      </Routes>
    </div>
  );
}
