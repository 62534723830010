import React, { useState } from 'react';
import { Avatar } from '@mantine/core';

import useGetAllAppliedLeavesForAdmin from '../hooks/useGetAllAppliedLeaves';
import AcceptLeaveModal from './acceptOrRejectLeaveModal';
import dateFormatter from 'helper/dateFormatter';

import { ReactComponent as RightIcon } from 'assets/icons/rightIcon.svg';
import { ReactComponent as WrongIcon } from 'assets/icons/wrongIcon.svg';
import { ReactComponent as EmptyLeavesIlls } from 'assets/images/emptyLeaveIlls.svg';

export default function NewLeaveRequest({
  selectedMonthFilterValue,
  searchValue,
  yearFilterValue,
}) {
  const [isAcceptLeaveModalOpen, setIsAcceptLeaveModalOpen] = useState(false);
  const [modalName, setModalName] = useState('approve');
  const [selectedLeaveData, setSelectedLeaveData] = useState('');

  const getAppliedLeavesUnderPending = useGetAllAppliedLeavesForAdmin(
    'Pending',
    null,
    selectedMonthFilterValue,
    searchValue,
    yearFilterValue
  );

  return (
    <div className='shadow-lg border border-8B8B8B border-opacity-10 rounded-xl bg-white my-4  h-[65vh] 2xl:h-[80vh] overflow-y-scroll scroller'>
      {getAppliedLeavesUnderPending?.data?.data?.length ? (
        <table className='w-full rounded-xl '>
          <thead>
            <tr className='bg-FFE8E1 text-left text-18 text-222222 sticky top-0 z-20'>
              <th className='py-4 pl-5 rounded-tl-xl'>Name </th>
              <th className=' pl-5'>Dept.</th>
              <th className=' pl-5'>Leave type</th>
              <th className=' pl-5'>Leave period</th>
              <th className=' pl-5'>Duration</th>
              <th className=' pl-5'>Reason</th>
              <th className=' pl-8 rounded-tr-xl'>Action</th>
            </tr>
          </thead>
          <tbody>
            {getAppliedLeavesUnderPending?.data?.data?.map((item) => (
              <tr className='text-9B9B9B' key={item.id}>
                <td>
                  <div className='flex space-x-1 items-center pl-4'>
                    <Avatar
                      radius='xl'
                      color={'red'}
                      classNames={{
                        image: 'border border-primary rounded-full',
                        placeholder: 'border border-primary rounded-full',
                      }}
                      src={item?.employee__profile_picture}
                    >
                      {item?.employee__user__first_name[0].toUpperCase()}
                    </Avatar>
                    <p className='pl-1'>{item?.employee__user__first_name}</p>
                  </div>
                </td>
                <td className='py-6 pl-5 text-primary'>
                  {item?.employee__department__name}
                </td>
                <td className='py-5 pl-5 text-primary'>
                  {item?.leave_type__name}
                </td>
                <td className='pl-5'>
                  <div>
                    <p>{dateFormatter(item?.from_date, 'DD MMM YY')} to</p>
                    <p>{dateFormatter(item?.to_date, 'DD MMM YY')}</p>
                  </div>
                </td>
                <td className='pl-5'>
                  {item?.number_of_days === 1
                    ? `${item?.number_of_days} day`
                    : `${item?.number_of_days} days`}
                </td>
                <td className='py-5 pl-5 break-all w-72'>{item?.reason}</td>
                <td className='pl-8 text-primary'>
                  <div className='flex space-x-4'>
                    <WrongIcon
                      className='cursor-pointer'
                      id='reject'
                      type='button'
                      onClick={() => {
                        setModalName('reject');
                        setSelectedLeaveData(item);
                        setIsAcceptLeaveModalOpen(true);
                      }}
                    />
                    <RightIcon
                      id='accept'
                      type='button'
                      className='cursor-pointer'
                      onClick={() => {
                        setModalName('approve');
                        setSelectedLeaveData(item);
                        setIsAcceptLeaveModalOpen(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className='flex flex-col justify-center items-center h-[60vh]'>
          <EmptyLeavesIlls className='w-72' />
          <p className='text-lg text-gray'>No leave requests to show</p>
        </div>
      )}
      <AcceptLeaveModal
        isModalOpen={isAcceptLeaveModalOpen}
        setIsModalOpen={setIsAcceptLeaveModalOpen}
        modalName={modalName}
        selectedLeaveData={selectedLeaveData}
      />
    </div>
  );
}
