import React, { useEffect, useState } from 'react';
import { Group, Modal, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import PrimaryButton from 'components/primaryButton';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as OrangePlusImg } from 'assets/images/orangePlusImg.svg';
import { ReactComponent as UploadedIcon } from 'assets/images/uploadedIcon.svg';
import usePostBulkUploadEmployees from 'pages/profile/hooks/usePostBulkUploadEmployees';

export default function BulkUploadEmployees({ isModalOpen, setIsModalOpen }) {
  const [file, setFile] = useState('');
  // const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const closeModal = () => {
    setIsModalOpen(false);
    setFile('');
    setErrorMessage('');
  };

  const updateEmployeeResumeMutation = usePostBulkUploadEmployees(closeModal);

  //   custom title for modal
  const customTitle = () => {
    return (
      <>
        <div className='relative flex justify-center items-center text-xl'>
          <LeftArrowIcon
            onClick={closeModal}
            className='w-4 absolute left-0 cursor-pointer'
          />
          <p>Bulk Upload Team Members</p>
        </div>
      </>
    );
  };

  // custom dropzone
  const dropzoneChildren = () => {
    return (
      <div className='flex flex-col items-center py-2'>
        <OrangePlusImg />
        <p className='text-neutralsGrey pt-2'>
          click to upload or simple drag and drop
        </p>
      </div>
    );
  };

  //   dropzone if file selected
  const fileSelected = () => (
    <Group
      position='center'
      spacing='xl'
      style={{ minHeight: 90, pointerEvents: 'none' }}
      className='flex flex-col'
    >
      {/* <XlssucessImage /> */}
      <UploadedIcon />
      <div className='-mt-4 text-sm'>
        <Text size='xs' inline className='text-[#828282]'>
          {file[0]?.name}
        </Text>
      </div>
    </Group>
  );

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('file', file[0]);
    updateEmployeeResumeMutation.mutate(formData);
  };

  useEffect(() => {
    setErrorMessage('');
  }, [file]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='lg'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-8',
        }}
      >
        <div className=' my-4'>
          <Dropzone
            onDrop={(files) => {
              setFile(files);
              // console.log(files, 'files');
            }}
            onReject={(files) => {
              setErrorMessage(files);
            }}
            maxSize={'5000000'}
            accept={[
              '.csv',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
            ]}
            classNames={{
              root: 'border-primary border-opacity-80',
            }}
            multiple={false}
          >
            {!file ? () => dropzoneChildren() : () => fileSelected()}
          </Dropzone>
          <p className='text-sm pt-1 pl-1 text-red-500'>
            {errorMessage &&
            errorMessage?.[0]?.errors?.[0]?.code === 'file-invalid-type'
              ? 'File type must be one of .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              : errorMessage?.[0]?.errors?.[0]
              ? ' File must be less than 5mb'
              : ''}
          </p>
          <div className='flex justify-center mt-8'>
            <a
              href='https://stgaptahr.blob.core.windows.net/aptai/BulkUploadEmployees.csv'
              download
            >
              <button className={`w-44 mr-2`}>
                <PrimaryButton>Download Template</PrimaryButton>
              </button>
            </a>
            <button
              className={`${file ? '' : 'opacity-50'} w-32 ml-2`}
              disabled={!file}
              onClick={() => handleSubmit()}
            >
              <PrimaryButton
                isLoading={updateEmployeeResumeMutation?.isLoading}
              >
                Submit
              </PrimaryButton>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
