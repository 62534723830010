// import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetlistofemployessinpolicy(
  id,
  searchInput,
  byStateFilter,
  byGradeFilter,
  byDepartmentFilter
) {
  return useQuery(
    [
      'policies-get-data-specific',
      id,
      searchInput,
      byStateFilter,
      byGradeFilter,
      byDepartmentFilter,
    ],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_LIST_IN_POLICY, {
          policy_id: id,
          search: searchInput,
          employee__grade__name: byGradeFilter,
          is_signed: byStateFilter,
          employee__department__name: byDepartmentFilter,
        })
      );

      return response.data;
    }
  );
}
