import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetAllEmployees(id) {
  return useQuery(['get-all-employees-data', id], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.TEAM_MEMBERS_API, {
        id: id,
      })
    );
    return res.data;
  });
}
