import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
export default function UseGetMeetinInfo(meeting_id) {
  return useQuery('get-meeting-info', async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.GET_MEETING_INFO, {
        meeting_id: meeting_id,
      })
    );
    return response?.data;
  });
}
