import React from 'react';
import { Timeline, TextInput } from '@mantine/core';

export default function Appraisal() {
  const customActiveBullet = () => {
    return <span className='w-7 h-5 rounded-full bg-[#F5DDD5]'></span>;
  };

  const customTitle = (name) => {
    return (
      <div className='bg-FFE8E1 text-primary px-2 py-2 text-xl'>{name}</div>
    );
  };

  return (
    <div className='pb-6'>
      <Timeline
        active={1}
        bulletSize={20}
        lineWidth={2}
        classNames={{
          itemTitle: 'text-lg font-semibold',
        }}
      >
        <Timeline.Item title={customTitle('Current salary')}>
          <div className='flex pb-4 pt-2'>
            <div>
              <TextInput
                defaultValue={'$ 5,40,000'}
                readOnly
                size='xl'
                label='Annual salary'
                classNames={{
                  input: 'border-primary w-64 rounded-lg mr-4 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm ml-4 font-normal ml-2 bg-white px-1.5 z-10 relative',
                }}
              />
              <p className='text-sm pt-2 pl-2 text-neutralsGrey'>
                Effective from{' '}
                <span className='text-neutralsBlack'>25 May 2022, 4:44 PM</span>
              </p>
            </div>
            <div>
              <TextInput
                defaultValue={'$ 45,000'}
                readOnly
                size='xl'
                label='Monthly salary'
                classNames={{
                  input: 'border-primary w-64 rounded-lg mr-4 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm ml-4 font-normal ml-2 bg-white px-1.5 z-10 relative',
                }}
              />
              <p className='text-sm pt-2 pr-4 text-67A1EF text-right select-none cursor-pointer'>
                View salary breakup
              </p>
            </div>
          </div>
        </Timeline.Item>
        <Timeline.Item title={customTitle('Past salary')}></Timeline.Item>
        <Timeline.Item title='Dec 2022' bullet={customActiveBullet()}>
          <div className='flex'>
            <div>
              <TextInput
                defaultValue={'$ 5,40,000'}
                readOnly
                size='xl'
                label='Annual salary'
                classNames={{
                  input: 'border-primary w-64 rounded-lg mr-4 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm ml-4 font-normal ml-2 bg-white px-1.5 z-10 relative',
                }}
              />
              <p className='text-sm pt-2 pl-2 text-neutralsGrey'>
                Effective from{' '}
                <span className='text-neutralsBlack'>25 May 2022, 4:44 PM</span>
              </p>
            </div>
            <div>
              <TextInput
                defaultValue={'$ 45,000'}
                readOnly
                size='xl'
                label='Monthly salary'
                classNames={{
                  input: 'border-primary w-64 rounded-lg mr-4 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm ml-4 font-normal ml-2 bg-white px-1.5 z-10 relative',
                }}
              />
            </div>
          </div>
        </Timeline.Item>
        <Timeline.Item title='Nov 2022' bullet={customActiveBullet()}>
          <div className='flex'>
            <div>
              <TextInput
                defaultValue={'$ 5,40,000'}
                readOnly
                size='xl'
                label='Annual salary'
                classNames={{
                  input: 'border-primary w-64 rounded-lg mr-4 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm ml-4 font-normal ml-2 bg-white px-1.5 z-10 relative',
                }}
              />
              <p className='text-sm pt-2 pl-2 text-neutralsGrey'>
                Effective from{' '}
                <span className='text-neutralsBlack'>25 May 2022, 4:44 PM</span>
              </p>
            </div>
            <div>
              <TextInput
                defaultValue={'$ 45,000'}
                readOnly
                size='xl'
                label='Monthly salary'
                classNames={{
                  input: 'border-primary w-64 rounded-lg mr-4 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm ml-4 font-normal ml-2 bg-white px-1.5 z-10 relative',
                }}
              />
            </div>
          </div>
        </Timeline.Item>
      </Timeline>
    </div>
  );
}
