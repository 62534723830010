import React, { useContext, useEffect, useState } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import {
  Switch,
  Tabs,
  Input,
  Select,
  Collapse,
  Avatar,
  Popover,
  Tooltip,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import dayjs from 'dayjs';
// import getSymbolFromCurrency from 'currency-symbol-map';

import SecondaryButton from 'components/secondaryButton';
import EditSalaryModal from './editSalaryModal';
import SeeTeamMemberPermisions from '../components/seeTeamMemberPermisions';
import AddTeamMember from './addTeamMember';
import EditWorkingHoursModal from './editWorkingHoursModal';
import DetailsComponent from './detailsComponent';
import Leaves from './leaves';
import Attendance from './attendance';
import FamilyAndEmergency from './emergency';
import Performance from './performance';
import { AuthContext } from 'context/authContext';
import ActivateOrDeactivateEmployeeModal from './activateOrdDeactivateEmployeeModal';

import useGetAllEmployees from '../hooks/useGetEmployees';
import useGetEmployeeLeavesExcel from '../hooks/useGetEmployeeLeaveExcel';

import { ReactComponent as BackArrowIcon } from 'assets/icons/backArrowIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobileIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mailIcon.svg';
import { ReactComponent as GradeIcon } from 'assets/icons/gradeIcon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/timeIcon.svg';
import { ReactComponent as DepartmentIcon } from 'assets/icons/departmentIcon.svg';
import { ReactComponent as EmployeeIdIcon } from 'assets/icons/employeeIdIcon.svg';
// import { ReactComponent as EmployeeSalIcon } from 'assets/icons/employeeSalIcon.svg';
import { ReactComponent as OffTime } from 'assets/icons/offTime.svg';
import { ReactComponent as DownLoadIcon } from 'assets/icons/downloadcircleIcon.svg';
import SkillMatrix from './skillMatrix';
import Header from 'components/Header';

export default function TeamMemberProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useContext(AuthContext);
  const [isDetailsCollapseOpen, setDetailsdetailsCollapseOpen] =
    useState(false);
  const [isDeactiveModalOpen, setDeactiveModalOpen] = useState(false);
  const [isUserActive, setUserIsActive] = useState(false);
  const [isPopoverOpened, setPopoverOpened] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isEditWorkingHoursModalOpen, setEditWorkingHoursModalOpen] =
    useState(false);
  const [isEditSalaryModalOpen, setEditSalaryModalOpen] = useState(false);

  /* const currencyIcon = getSymbolFromCurrency(authState?.currency); */

  const [filterValue, setFiltervalue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [isLeavesFileDownload, setIsLeavesFileDownload] = useState(false);

  // state for opening and closing the see permmisions modal
  const [seeTeamMemberPermisionsModal, setseeTeamMemberPermisionsModal] =
    useState(false);

  const [debounced] = useDebouncedValue(searchValue, 300);

  const getEmployeeData = useGetAllEmployees(id);
  const getEmployeeLeavesFile = useGetEmployeeLeavesExcel(isLeavesFileDownload);

  useEffect(() => {
    setUserIsActive(getEmployeeData?.data?.data?.[0]?.user__is_active);
  }, [getEmployeeData?.data?.data]);

  const getOffDays = () => {
    let daysArray = [];
    if (!getEmployeeData?.data?.data?.[0]?.employee_wdt__is_monday) {
      daysArray.push('Mon');
    }
    if (!getEmployeeData?.data?.data?.[0]?.employee_wdt__is_tuesday) {
      daysArray.push('Tue');
    }
    if (!getEmployeeData?.data?.data?.[0]?.employee_wdt__is_wednesday) {
      daysArray.push('Wed');
    }
    if (!getEmployeeData?.data?.data?.[0]?.employee_wdt__is_thursday) {
      daysArray.push('Thu');
    }
    if (!getEmployeeData?.data?.data?.[0]?.employee_wdt__is_friday) {
      daysArray.push('Fri');
    }
    if (!getEmployeeData?.data?.data?.[0]?.employee_wdt__is_saturday) {
      daysArray.push('Sat');
    }
    if (!getEmployeeData?.data?.data?.[0]?.employee_wdt__is_sunday) {
      daysArray.push('Sun');
    }
    let weekoffDays = daysArray.toString();
    return weekoffDays;
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (e) => {
    setSearchParams({ tab: e });
  };

  const customTooltip = (data) => {
    return (
      <div className='flex items-center'>
        <Avatar
          src={data?.[0]?.reports_to__user_employee__profile_picture}
          alt=' '
          size={'md'}
          className='rounded-full  mr-2 '
          color={'red'}
        >
          {data?.[0]?.reports_to__first_name[0]?.toUpperCase()}
        </Avatar>
        <div>
          <p className='font-semibold text-lg'>
            {data?.[0]?.reports_to__first_name}
          </p>
          <p className='text-sm -mt-1'>
            {data?.[0]?.reports_to__user_employee__designation__name}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className='px-5'>
      <div className='flex justify-between items-center mt-9'>
        {/* differentating for super admin and admin */}
        {location?.pathname?.split('/')?.[2] !== 'team' ? (
          <p className='text-xl text-A8A8A8'>
            Organisation Settings &#60; Admin profile &#60;{' '}
            <span className='text-gray font-semibold'>Employee Profile</span>
          </p>
        ) : (
          <>
            <p className='text-xl text-A8A8A8'>
              Team &#60;{' '}
              <span className='text-gray font-semibold'>My team</span>
            </p>
            <Header />
          </>
        )}
      </div>
      <div className='min-h-44 shadow-lg mt-6 border border-8B8B8B border-opacity-10 rounded-xl p-4 bg-white'>
        <div className='flex space-x-3 items-center'>
          <BackArrowIcon
            className='w-4 cursor-pointer'
            onClick={() => navigate('/app/team/my-team')}
          />
          <p className='text-22 font-bold text-333333'>Profile</p>
        </div>
        {getEmployeeData?.isLoading ? (
          // loader
          <div className='pt-4'>
            <div className='flex'>
              <div className='rounded-full bg-slate-200 h-16 w-16'></div>
              <div className='pl-4 pt-2 flex justify-between w-full'>
                <div>
                  <div className='rounded-sm bg-slate-200 h-6 w-60 mb-2'></div>
                  <div className='rounded-sm bg-slate-200 h-5 w-36'></div>
                </div>
                <div className='rounded-sm bg-slate-200 h-10 w-28 mr-2'></div>
              </div>
            </div>
            <div className='pl-2 pt-2 flex items-center space-x-10 mt-2'>
              <div className='rounded-md bg-slate-200 h-5 w-40'></div>
              <div className='rounded-md bg-slate-200 h-5 w-40'></div>
              <div className='rounded-md bg-slate-200 h-5 w-40'></div>
              <div className='rounded-md bg-slate-200 h-5 w-40'></div>
              <div className='rounded-md bg-slate-200 h-5 w-40'></div>
            </div>
          </div>
        ) : (
          <div className='mt-4 flex'>
            <Avatar
              src={getEmployeeData?.data?.data?.[0]?.profile_picture}
              size='lg'
              color={'red'}
              className='rounded-full mr-2'
            >
              {getEmployeeData?.data?.data?.[0]?.user__first_name[0]?.toUpperCase()}
            </Avatar>
            <div className='w-full'>
              <div className='flex items-center justify-between'>
                <div>
                  <p className='text-2xl font-bold'>
                    {getEmployeeData?.data?.data?.[0]?.user__first_name}{' '}
                    {/* <span className='text-base text-primary font-normal'>
                    (He / Him)
                  </span> */}
                  </p>
                  <p className='text-md text-gray3'>
                    {getEmployeeData?.data?.data?.[0]?.designation__name}
                  </p>
                </div>

                <div className='flex items-center ml-auto'>
                  {authState?.is_admin ? (
                    <>
                      {getEmployeeData?.data?.data?.[0]
                        ?.reports_to__first_name ? (
                        <>
                          <p className='text-8B8B8B mr-1'>Reports to</p>
                          <span className='w-6 mr-4'>
                            <Tooltip
                              label={customTooltip(getEmployeeData?.data?.data)}
                              classNames={{
                                body: 'bg-white text-gray2 shadow-tooltip',
                              }}
                            >
                              <Avatar
                                src={
                                  getEmployeeData?.data?.data?.[0]
                                    ?.reports_to__user_employee__profile_picture
                                }
                                size='sm'
                                color={'red'}
                                className='rounded-full mr-2 mt-2'
                              >
                                {getEmployeeData?.data?.data?.[0]?.reports_to__first_name?.[0]?.toUpperCase()}
                              </Avatar>
                            </Tooltip>
                          </span>
                        </>
                      ) : null}

                      {location?.pathname?.split('/')?.[2] === 'team' ? (
                        <EditIcon
                          className='w-5 cursor-pointer'
                          onClick={() => setEditModalOpen(true)}
                        />
                      ) : null}
                    </>
                  ) : null}
                  <a
                    className='ml-4'
                    href={`${getEmployeeData?.data?.data?.[0]?.resume}`}
                    download
                  >
                    <SecondaryButton>View CV</SecondaryButton>
                  </a>
                  {/* <button className='ml-4'>
                    <SecondaryButton>View CV</SecondaryButton>
                  </button> */}
                </div>
              </div>
              <div className='flex flex-col mt-3 -ml-12 text-gray3'>
                <div className='flex flex-row justify-between'>
                  <div className='flex items-center'>
                    <MobileIcon className='mr-2' />
                    {getEmployeeData?.data?.data?.[0]?.user__mobile_number
                      .length > 10 ? (
                      <Tooltip
                        label={
                          getEmployeeData?.data?.data?.[0]?.user__mobile_number
                        }
                        classNames={{
                          body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                        }}
                        radius='md'
                      >
                        <p className='mr-5 w-28 truncate'>
                          {
                            getEmployeeData?.data?.data?.[0]
                              ?.user__mobile_number
                          }
                        </p>
                      </Tooltip>
                    ) : (
                      <p className='mr-5 w-28'>
                        {getEmployeeData?.data?.data?.[0]?.user__mobile_number}
                      </p>
                    )}
                    <MailIcon className='mr-2' />
                    {getEmployeeData?.data?.data?.[0]?.user__email.length >
                    29 ? (
                      <Tooltip
                        label={getEmployeeData?.data?.data?.[0]?.user__email}
                        classNames={{
                          body: 'bg-white text-gray2 shadow-tooltip',
                        }}
                        radius='md'
                      >
                        <p className='mr-5 w-52 truncate'>
                          {getEmployeeData?.data?.data?.[0]?.user__email}
                        </p>
                      </Tooltip>
                    ) : (
                      <p className='mr-5 w-52 '>
                        {getEmployeeData?.data?.data?.[0]?.user__email}
                      </p>
                    )}

                    <LocationIcon className='mr-2 ml-1' />
                    <p className='mr-5 w-40'>
                      {getEmployeeData?.data?.data?.[0]?.country}
                    </p>

                    <Tooltip
                      label='Employment type & grade'
                      width={130}
                      wrapLines
                      classNames={{
                        body: 'bg-white text-gray2 shadow-tooltip',
                      }}
                    >
                      <div className='w-52 flex items-center'>
                        <GradeIcon className='w-5 mr-1' />
                        <p className='w-52'>
                          {
                            getEmployeeData?.data?.data?.[0]
                              ?.employment_type__name
                          }
                          , {getEmployeeData?.data?.data?.[0]?.grade__name}
                        </p>
                      </div>
                    </Tooltip>
                    <Tooltip
                      label='Department'
                      classNames={{
                        body: 'bg-white text-gray2 shadow-tooltip',
                      }}
                    >
                      <div className='flex items-center'>
                        <DepartmentIcon className='w-5 mr-1 ml-1' />
                        <p>
                          {getEmployeeData?.data?.data?.[0]?.department__name}
                        </p>
                      </div>
                    </Tooltip>
                  </div>

                  <div className='flex items-center space-x-4'>
                    {authState?.is_admin ? (
                      <span className='pt-1'>
                        <Switch
                          size='sm'
                          checked={isUserActive}
                          onChange={() => {
                            setDeactiveModalOpen(true);
                          }}
                          classNames={{
                            input: 'cursor-pointer',
                          }}
                        />
                      </span>
                    ) : null}
                    <span
                      className='cursor-pointer p-1'
                      onClick={() =>
                        setDetailsdetailsCollapseOpen(!isDetailsCollapseOpen)
                      }
                    >
                      <DownArrow
                        className={`${
                          isDetailsCollapseOpen ? 'rotate-180 ' : ''
                        } mt-1`}
                      />
                    </span>
                  </div>
                </div>
                <div>
                  <Collapse
                    in={isDetailsCollapseOpen}
                    transitionDuration={200}
                    transitionTimingFunction='linear'
                  >
                    <div className='flex items-center mt-4 justify-between'>
                      <div className='flex items-center'>
                        <Tooltip
                          label='Employee id'
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip',
                          }}
                        >
                          <div className='flex items-center'>
                            <EmployeeIdIcon className='mr-2' />
                            <p className='mr-5 w-28'>
                              {getEmployeeData?.data?.data?.[0]?.employee_id}
                            </p>
                          </div>
                        </Tooltip>
                        <Tooltip
                          label='Date of joining'
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                          }}
                          radius='md'
                        >
                          <div className='flex '>
                            <CalenderIcon className='mr-2 w-5' />
                            <p className='mr-5 w-52'>
                              {dayjs(
                                getEmployeeData?.data?.data?.[0]
                                  ?.date_of_joining
                              ).format('DD MMM YYYY')}
                            </p>
                          </div>
                        </Tooltip>
                        {/* 
                         This code is commented as per issue AG-548
                        
                        <EmployeeSalIcon className='mr-2' />
                          <div className='w-40 mr-5 flex items-center justify-start'>
                            <p className='w-31 truncate whitespace-nowrap'>
                              {currencyIcon}{' '}
                              {getEmployeeData?.data?.data?.[0]?.gross_salary}
                              /Annually
                            </p>
                            <EditIcon
                              className='cursor-pointer'
                              onClick={() => setEditSalaryModalOpen(true)}
                            />
                          </div> */}

                        <div className='flex items-center'>
                          <TimeIcon className='w-5' />
                          {getEmployeeData?.data?.data?.[0]
                            ?.employee_wdt__working_hours_same ? (
                            <div className='flex w-48 space-x-1 items-center'>
                              <p>
                                {
                                  getEmployeeData?.data?.data?.[0]
                                    ?.employee_wdt__general_from
                                }
                                -
                                {
                                  getEmployeeData?.data?.data?.[0]
                                    ?.employee_wdt__general_to
                                }
                              </p>
                              {authState?.is_admin ? (
                                <EditIcon
                                  className='w-5 cursor-pointer'
                                  onClick={() => {
                                    setEditWorkingHoursModalOpen(true);
                                  }}
                                />
                              ) : null}
                            </div>
                          ) : (
                            <span className='w-44 flex items-center'>
                              <p
                                className='w-fit text-primary underline cursor-pointer select-none'
                                onClick={() => setPopoverOpened((o) => !o)}
                              >
                                View & edit
                              </p>
                              <Popover
                                opened={isPopoverOpened}
                                onClose={() => setPopoverOpened(false)}
                                width={300}
                                position='bottom'
                                classNames={{
                                  wrapper: 'mt-3',
                                }}
                              >
                                <div>
                                  <div className='flex justify-between'>
                                    <p className='text-gray pb-2'>
                                      Working days and timings
                                    </p>
                                    {authState?.is_admin ? (
                                      <EditIcon
                                        className='w-5 cursor-pointer'
                                        onClick={() => {
                                          setPopoverOpened(false);
                                          setEditWorkingHoursModalOpen(true);
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                  <div className='text-sm space-y-1'>
                                    {getEmployeeData?.data?.data?.[0]
                                      ?.employee_wdt__is_monday ? (
                                      <div className='flex pb-1'>
                                        <p className='text-gray w-2/5'>
                                          Monday
                                        </p>
                                        <p className='px-4 text-gray2'>:</p>
                                        <p className='text-primary whitespace-nowrap'>
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__monday_from
                                          }{' '}
                                          -{' '}
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__monday_to
                                          }
                                        </p>
                                      </div>
                                    ) : null}
                                    {getEmployeeData?.data?.data?.[0]
                                      ?.employee_wdt__is_tuesday ? (
                                      <div className='flex pb-1'>
                                        <p className='text-gray w-2/5'>
                                          Tuesday
                                        </p>
                                        <p className='px-4 text-gray2'>:</p>
                                        <p className='text-primary whitespace-nowrap'>
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__tuesday_from
                                          }{' '}
                                          -{' '}
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__tuesday_to
                                          }
                                        </p>
                                      </div>
                                    ) : null}
                                    {getEmployeeData?.data?.data?.[0]
                                      ?.employee_wdt__is_wednesday ? (
                                      <div className='flex pb-1'>
                                        <p className='text-gray w-2/5'>
                                          Wednesday
                                        </p>
                                        <p className='px-4 text-gray2'>:</p>
                                        <p className='text-primary whitespace-nowrap'>
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__wednesday_from
                                          }{' '}
                                          -{' '}
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__wednesday_to
                                          }
                                        </p>
                                      </div>
                                    ) : null}
                                    {getEmployeeData?.data?.data?.[0]
                                      ?.employee_wdt__is_thursday ? (
                                      <div className='flex pb-1'>
                                        <p className='text-gray w-2/5'>
                                          Thursday
                                        </p>
                                        <p className='px-4 text-gray2'>:</p>
                                        <p className='text-primary whitespace-nowrap'>
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__thursday_from
                                          }{' '}
                                          -{' '}
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__thursday_to
                                          }
                                        </p>
                                      </div>
                                    ) : null}
                                    {getEmployeeData?.data?.data?.[0]
                                      ?.employee_wdt__is_friday ? (
                                      <div className='flex pb-1'>
                                        <p className='text-gray w-2/5'>
                                          Friday
                                        </p>
                                        <p className='px-4 text-gray2'>:</p>
                                        <p className='text-primary whitespace-nowrap'>
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__friday_from
                                          }{' '}
                                          -{' '}
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__friday_to
                                          }
                                        </p>
                                      </div>
                                    ) : null}
                                    {getEmployeeData?.data?.data?.[0]
                                      ?.employee_wdt__is_saturday ? (
                                      <div className='flex pb-1'>
                                        <p className='text-gray w-2/5'>
                                          Saturday
                                        </p>
                                        <p className='px-4 text-gray2'>:</p>
                                        <p className='text-primary whitespace-nowrap'>
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__saturday_from
                                          }{' '}
                                          -{' '}
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__saturday_to
                                          }
                                        </p>
                                      </div>
                                    ) : null}
                                    {getEmployeeData?.data?.data?.[0]
                                      ?.employee_wdt__is_sunday ? (
                                      <div className='flex pb-1'>
                                        <p className='text-gray w-2/5'>
                                          Sunday
                                        </p>
                                        <p className='px-4 text-gray2'>:</p>
                                        <p className='text-primary whitespace-nowrap'>
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__sunday_from
                                          }{' '}
                                          -{' '}
                                          {
                                            getEmployeeData?.data?.data?.[0]
                                              ?.employee_wdt__sunday_to
                                          }
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Popover>
                            </span>
                          )}
                        </div>
                        <Tooltip
                          label='Off days'
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip',
                          }}
                        >
                          <div className='flex items-center'>
                            <OffTime className='w-5 mr-1' />
                            <p>{getOffDays() || '--'}</p>
                          </div>
                        </Tooltip>
                      </div>
                      {authState?.is_admin ? (
                        <div className=''>
                          <p
                            className='text-[#67A1EF] text-sm cursor-pointer'
                            onClick={() => {
                              setseeTeamMemberPermisionsModal(true);
                            }}
                          >
                            view & edit permissions
                          </p>
                        </div>
                      ) : (
                        <div className=''>
                          <p
                            className='text-[#67A1EF] text-sm cursor-pointer'
                            onClick={() => {
                              setseeTeamMemberPermisionsModal(true);
                            }}
                          >
                            view permissions
                          </p>
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
              </div>
              {/* <div className='mt-3 -ml-12 text-gray3 flex items-start justify-between'>
                <div className='flex items-center space-x-4'>
                  {authState?.is_admin ? (
                    <span className='pt-1'>
                      <Switch
                        size='sm'
                        checked={isUserActive}
                        onChange={() => {
                          setDeactiveModalOpen(true);
                        }}
                        classNames={{
                          input: 'cursor-pointer',
                        }}
                      />
                    </span>
                  ) : null}
                  <span
                    className='cursor-pointer p-1'
                    onClick={() =>
                      setDetailsdetailsCollapseOpen(!isDetailsCollapseOpen)
                    }
                  >
                    <DownArrow
                      className={`${
                        isDetailsCollapseOpen ? 'rotate-180 ' : ''
                      } mt-1`}
                    />
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
      <div className='pl-1 pt-5 relative'>
        <Tabs
          tabPadding={21}
          active={~~searchParams.get('tab')}
          onTabChange={handleTabChange}
          classNames={{
            tabsListWrapper: 'border-0',
            tabActive:
              'border-2 border-primary font-semibold text-18 ease-linear duration-500',
            tabControl: 'text-A8A8A8 leading-5 px-0 mr-6',
            tabLabel: 'text-18 text-gray',
          }}
        >
          <Tabs.Tab label='Details'>
            <DetailsComponent />
          </Tabs.Tab>
          <Tabs.Tab label='Leaves'>
            <Leaves
              filterValue={filterValue}
              searchValue={debounced}
              setIsLeavesFileDownload={setIsLeavesFileDownload}
            />
          </Tabs.Tab>
          <Tabs.Tab label='Attendance' disabled className='opacity-50'>
            <Attendance />
          </Tabs.Tab>
          <Tabs.Tab label='Performance'>
            <Performance />
          </Tabs.Tab>
          <Tabs.Tab label='Family &amp; emergency '>
            <FamilyAndEmergency />
          </Tabs.Tab>
          <Tabs.Tab label='Skill matrix'>
            <SkillMatrix />
          </Tabs.Tab>
        </Tabs>
        {~~searchParams.get('tab') === 1 || ~~searchParams.get('tab') === 2 ? (
          <div className='absolute top-6 right-0'>
            <div className='flex items-center space-x-4'>
              <Input
                icon={<SearchIcon />}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder='Search'
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-36 bg-light-bg border-none',
                }}
              />
              <Select
                placeholder='Month'
                icon={<FilterIcon />}
                clearable
                onChange={setFiltervalue}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                rightSection={<DownArrow />}
                data={[
                  { value: '', label: 'All' },
                  { value: '1', label: 'January' },
                  { value: '2', label: 'February' },
                  { value: '3', label: 'March' },
                  { value: '4', label: 'April' },
                  { value: '5', label: 'May' },
                  { value: '6', label: 'June' },
                  { value: '7', label: 'July' },
                  { value: '8', label: 'August' },
                  { value: '9', label: 'September' },
                  { value: '10', label: 'October' },
                  { value: '11', label: 'November' },
                  { value: '12', label: 'December' },
                ]}
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-40 bg-light-bg border-none',
                }}
              />
              {isLeavesFileDownload && (
                <Tooltip
                  label='Download Leaves'
                  classNames={{
                    body: 'bg-white text-gray2 shadow-tooltip',
                  }}
                >
                  <a href={getEmployeeLeavesFile?.data?.data} download>
                    <DownLoadIcon className='h-7 w-7 cursor-pointer mt-2' />
                  </a>
                </Tooltip>
              )}
              {~~searchParams.get('tab') === 2 ? (
                <Select
                  placeholder='Year'
                  icon={<FilterIcon />}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  rightSection={<DownArrow />}
                  data={[
                    { value: '2022', label: '2022' },
                    { value: '2021', label: '2021' },
                  ]}
                  classNames={{
                    wrapper: 'border-primary border-b',
                    input: 'w-40 bg-light-bg border-none',
                  }}
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <ActivateOrDeactivateEmployeeModal
        isDeactiveModalOpen={isDeactiveModalOpen}
        setDeactiveModalOpen={setDeactiveModalOpen}
        setUserIsActive={setUserIsActive}
        isUserActive={isUserActive}
        id={id}
      />
      <AddTeamMember
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setEditModalOpen}
        name='Edit'
        employeeDetails={getEmployeeData?.data?.data?.[0]}
      />
      <EditWorkingHoursModal
        isModalOpen={isEditWorkingHoursModalOpen}
        setIsModalOpen={setEditWorkingHoursModalOpen}
        employeeData={getEmployeeData?.data?.data}
      />
      <EditSalaryModal
        isModalOpen={isEditSalaryModalOpen}
        setIsModalOpen={setEditSalaryModalOpen}
        employee_id={id}
      />
      {seeTeamMemberPermisionsModal && (
        <SeeTeamMemberPermisions
          seeTeamMemberPermisionsModal={seeTeamMemberPermisionsModal}
          setseeTeamMemberPermisionsModal={setseeTeamMemberPermisionsModal}
          isAdmin={authState?.is_admin}
        />
      )}
    </div>
  );
}
