import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetholidaystypes() {
  return useQuery(
    ['get-holidays-types'],
    async () => {
      const response = await makeApiRequest.get(apiEndPoints.HOLIDAY_TYPES_API);
      return response.data;
    },
    { staleTime: 3000 }
  );
}
