import React, { useState } from 'react';
import { Avatar, AvatarsGroup, Popover } from '@mantine/core';

export default function DepartmentEmployees({ employeeDepartment }) {
  const [openPopover, setOpenPopover] = useState(false);
  return (
    <div className='flex space-x-5 relative justify-center items-center'>
      <AvatarsGroup
        limit={3}
        total={employeeDepartment?.employees?.length}
        className=' cursor-pointer'
        size={'md'}
        classNames={{
          truncated: 'bg-FFE8E1 text-red',
        }}
        onClick={() => setOpenPopover(true)}
      >
        {employeeDepartment?.employees?.map(
          (employee, index) =>
            index < 3 && (
              <Avatar
                key={employee?.id}
                src={employee?.profile_picture}
                radius='xl'
                size={'md'}
                color={'red'}
                classNames={{
                  placeholder: 'bg-FFE8E1',
                }}
              >
                {employee?.user__first_name[0]?.toUpperCase()}
              </Avatar>
            )
        )}
      </AvatarsGroup>
      <Popover
        opened={openPopover}
        onClose={() => setOpenPopover(false)}
        width={260}
        position='bottom'
        placement='center'
        title={'Employees'}
        classNames={{
          body: '-mt-3',
          inner: 'py-2 max-h-48 overflow-y-scroll scroller',
          header: 'border-b-0 text-accent font-semibold text-primary',
        }}
      >
        {employeeDepartment?.employees?.map((employee) => {
          return (
            <div className='flex space-x-2 pb-4' key={employee?.id}>
              <Avatar
                src={employee?.profile_picture}
                color='red'
                radius='xl'
                size={'md'}
                classNames={{
                  placeholder: 'text-xl',
                }}
                className='w-10 h-10 capitalize'
              >
                {employee?.user__first_name[0]}
              </Avatar>
              <div className='text-xs'>
                <p className='text-121212'>{employee?.user__first_name}</p>
                <p className='text-868686'>{employee?.designation__name}</p>
              </div>
            </div>
          );
        })}
      </Popover>
    </div>
  );
}
