import { Outlet } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/Logo.svg';
export default function MeetingRoomHeader() {
  return (
    <div>
      <div className='h-20 bg-white shadow-md sticky z-10 top-0 py-2'>
        <Logo className='w-24 h-12 ml-6' />
      </div>
      <Outlet />
    </div>
  );
}
