import NoPageFound from 'pages/common/pageNotFound';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import EmployeeDashboard from './pages/employeeDashboard';
import EmployeePerformance from './pages/employeeperformance/employeePerformance';

function Dashboard() {
  return (
    <div>
      <Routes>
        <Route path='/dashboard' element={<EmployeeDashboard />} />
        <Route path='/performance' element={<EmployeePerformance />} />

        {/* empty page when route not found */}
        <Route path='*' element={<NoPageFound />} />
      </Routes>
    </div>
  );
}

export default Dashboard;
