import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
// import { AuthContext } from 'context/authContext';
// import { useContext } from 'react';

export default function useGetOrganisationData() {
  //   const { authState } = useContext(AuthContext);
  return useQuery(['get-organisation-data'], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.ORGANISATION_API)
    );
    return res.data;
  });
}
