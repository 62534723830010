import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetsalaryrangedata() {
  return useQuery(['salary_data'], async () => {
    const response = await makeApiRequest.get(createQueryParamsForGetReq(

    
      apiEndPoints.SETTINGS_SALARY_RANGE,{is_active:'True'})
    );

    return response.data;
  });
}
