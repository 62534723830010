import React, { useState } from 'react';
import dayjs from 'dayjs';

import EditEmployeePersonalData from './editEmployeePersonalInfo';
import useGetEmployeeBankDetails from '../hooks/useGetEmployeeBankDetails';
import EditEmployeeBankDetailsModal from './editBankDetailsModal';
import EditOtherDetailsOfEmployee from './editOtherDetailsOfEmployee';

import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minusIcon.svg';
import { ReactComponent as PdfImage } from 'assets/images/pdfImage.svg';
import dateFormatter from 'helper/dateFormatter';

export default function EmployeeDetailsComponent({ employeeDetails }) {
  const [isPersonalDataEditModalOpen, setPersonalDataEditModalOpen] =
    useState(false);

  const [isEmployeeBankDetailsModalOpen, setIsEmployeeBankDetailsModalOpen] =
    useState(false);
  const [isOtherDataEditModalOpen, setOtherDataEditModalOpen] = useState(false);

  const getEmployeeBankDetails = useGetEmployeeBankDetails();

  const blankData = () => {
    return (
      <span className='text-primary font-semibold text-base'>
        {' '}
        <MinusIcon />
        {/* N/A */}
      </span>
    );
  };

  const checkFileType = (data) => {
    if (data !== 'undefined' && data !== null) {
      const newArray = data?.split('.');
      if (newArray && newArray[newArray?.length - 1] === 'pdf') {
        return 'pdf';
      } else {
        return 'img';
      }
    } else {
      return null;
    }
  };

  return (
    <div className='shadow-lg border border-8B8B8B border-opacity-10 rounded-xl py-4 px-6 mb-8 bg-white'>
      <div className='bg-FFE8E1 bg-opacity-50 px-4 py-2 flex justify-between items-center mb-2'>
        <p className='text-primary font-medium text-xl '>Personal details</p>
        <EditIcon
          className='cursor-pointer'
          onClick={() => setPersonalDataEditModalOpen(true)}
        />
      </div>
      <div className='px-4 text-gray text-18'>
        <table className='w-full'>
          <tbody>
            <tr>
              <td className='py-2 w-36'>Contact & Whatsapp</td>
              <td className='px-4 w-16'>: </td>
              <td className='font-medium text-67A1EF w-9/12' colSpan={4}>
                {employeeDetails?.user__mobile_number}
                {employeeDetails?.whatsapp_number !==
                  employeeDetails?.user__mobile_number &&
                employeeDetails?.whatsapp_number !== null
                  ? `, ${employeeDetails?.whatsapp_number}`
                  : ''}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Personal email</td>
              <td className='px-4'>: </td>
              <td className='text-67A1EF ' colSpan={4}>
                {employeeDetails?.personal_email || blankData()}
              </td>
            </tr>
            <tr>
              <td className=' py-3'>DOB </td>
              <td className='px-4'>: </td>
              <td className='font-light ' colSpan={4}>
                {(employeeDetails?.date_of_birth &&
                  dateFormatter(
                    employeeDetails?.date_of_birth,
                    'DD MMM YYYY'
                  )) ||
                  blankData()}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Gender</td>
              <td className='px-4'>:</td>
              <td className='font-light' colSpan={4}>
                {employeeDetails?.gender}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Marital status</td>
              <td className='px-4'>: </td>
              <td className='font-light' colSpan={4}>
                {employeeDetails?.marital_status || blankData()}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Preferred pronoun</td>
              <td className='px-4'>: </td>
              <td className='font-light' colSpan={4}>
                {employeeDetails?.preferred_pronoun || blankData()}
              </td>
            </tr>
            <tr>
              <td className='pt-3 pb-5'>Address </td>
              <td className='px-4'>: </td>
              <td className='font-light' colSpan={4}>
                {employeeDetails?.address || blankData()}
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <div className='bg-FFE8E1 bg-opacity-50 -ml-4 -mr-4 px-4 py-2 flex justify-between items-center mt-4'>
                  <p className='text-primary font-medium text-xl '>
                    Other details
                  </p>
                  <EditIcon
                    className='cursor-pointer'
                    onClick={() => setOtherDataEditModalOpen(true)}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className='  pt-3 pb-5'>Reports to </td>
              <td className='px-4 pt-3 pb-5'>: </td>
              <td colSpan={4} className='pt-3 pb-5'>
                <div>
                  <p className='text-gray'>
                    {employeeDetails?.reports_to__first_name ||
                      'Reports to none'}
                  </p>
                  <p className='font-light text-sm'>
                    {
                      employeeDetails?.reports_to__user_employee__designation__name
                    }
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td className='pb-3 pt-5 w-36'>Nationality</td>
              <td className='px-4 pb-3 pt-5 w-16'>: </td>
              <td className='font-light pb-3 pt-5 w-5/12'>
                {employeeDetails?.nationality || blankData()}
              </td>
              <td></td>
            </tr>
            <tr>
              <td className='pb-3 pt-5'>
                <p className='whitespace-nowrap'>Aadhaar ID</p>
              </td>
              <td className='px-4 pb-3 pt-5'>: </td>
              <td className='font-light pb-3 pt-5'>
                {employeeDetails?.aadhar_card || blankData()}
              </td>
              <td className='py-2' colSpan={3}>
                <div className='flex space-x-4 justify-end'>
                  {checkFileType(employeeDetails?.aadhar_card_image) ===
                  'img' ? (
                    <a
                      href={employeeDetails?.aadhar_card_image}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        alt='aadhar'
                        src={employeeDetails?.aadhar_card_image}
                        className='border-primary border w-20 h-16 rounded-md border-opacity-60 object-contain'
                      />
                    </a>
                  ) : checkFileType(employeeDetails?.aadhar_card_image) ===
                    'pdf' ? (
                    <a
                      href={employeeDetails?.aadhar_card_image}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <PdfImage />
                    </a>
                  ) : null}
                </div>
              </td>
            </tr>
            <tr>
              <td className='pb-3 pt-5'>
                <p className='whitespace-nowrap'>Passport no.</p>
              </td>
              <td className='px-4 pb-3 pt-5'>: </td>
              <td className='font-light pb-3 pt-5'>
                {employeeDetails?.passport_number || blankData()}
              </td>
              <td rowSpan={2} colSpan={3}>
                <div className='flex space-x-4 justify-end'>
                  {checkFileType(employeeDetails?.passport_image_1) ===
                  'img' ? (
                    <a
                      href={employeeDetails?.passport_image_1}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        alt='passport'
                        src={employeeDetails?.passport_image_1}
                        className='border-primary border w-20 h-16 rounded-md border-opacity-60 object-contain'
                      />
                    </a>
                  ) : checkFileType(employeeDetails?.passport_image_1) ===
                    'pdf' ? (
                    <a
                      href={employeeDetails?.passport_image_1}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <PdfImage />
                    </a>
                  ) : null}
                  {checkFileType(employeeDetails?.passport_image_2) ===
                  'img' ? (
                    <a
                      href={employeeDetails?.passport_image_2}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        alt='pancard'
                        src={employeeDetails?.passport_image_2}
                        className='border-primary border w-20 h-16 rounded-md border-opacity-60 object-contain'
                      />
                    </a>
                  ) : checkFileType(employeeDetails?.passport_image_2) ===
                    'pdf' ? (
                    <a
                      href={employeeDetails?.passport_image_2}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <PdfImage />
                    </a>
                  ) : null}
                </div>
              </td>
            </tr>
            <tr>
              <td className='pt-1 pb-5'>Passport exp date</td>
              <td className='px-4 pb-5'>: </td>
              <td className='font-light pb-5'>
                {employeeDetails?.passport_expiry_date
                  ? dayjs(employeeDetails?.passport_expiry_date).format(
                      'DD MMM YY'
                    )
                  : blankData()}
              </td>
            </tr>
            <tr>
              <td className='py-3'>PAN ID</td>
              <td className='px-4'>: </td>
              <td className='font-light'>
                {employeeDetails?.pan_card || blankData()}
              </td>
              <td className='py-2' colSpan={3}>
                <div className='flex space-x-4 justify-end'>
                  {checkFileType(employeeDetails?.pan_card_image) === 'img' ? (
                    <a
                      href={employeeDetails?.pan_card_image}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        alt=''
                        src={employeeDetails?.pan_card_image}
                        className='border-primary border w-20 h-16 rounded-md border-opacity-60 object-contain'
                      />
                    </a>
                  ) : checkFileType(employeeDetails?.pan_card_image) ===
                    'pdf' ? (
                    <a
                      href={employeeDetails?.pan_card_image}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <PdfImage />
                    </a>
                  ) : null}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <div className='bg-FFE8E1 bg-opacity-50 px-4 py-2 -ml-4 -mr-4 flex justify-between items-center mt-4'>
                  <p className='text-primary font-medium text-xl '>
                    Bank details
                  </p>
                  <EditIcon
                    className='cursor-pointer'
                    onClick={() => setIsEmployeeBankDetailsModalOpen(true)}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className='pt-5 pb-3'>Bank name </td>
              <td className='px-4 pt-5 pb-3'>: </td>
              <td className='font-light pt-5 pb-3'>
                {getEmployeeBankDetails?.data?.data?.length
                  ? getEmployeeBankDetails?.data?.data?.[0]?.bank_name
                  : blankData()}
              </td>
              <td className='pt-5 pb-3 whitespace-nowrap'>
                Account holder name
              </td>
              <td className='px- pt-5 pb-3'>: </td>
              <td className='font-light pt-5 pb-3'>
                {getEmployeeBankDetails?.data?.data?.length
                  ? getEmployeeBankDetails?.data?.data?.[0]?.account_holder_name
                  : blankData()}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Bank account no.</td>
              <td className='px-4'>: </td>
              <td className='font-light'>
                {getEmployeeBankDetails?.data?.data?.length
                  ? getEmployeeBankDetails?.data?.data?.[0]?.account_no
                  : blankData()}
              </td>
              <td className='py-3'>IFSC code</td>
              <td className='pr-10'>: </td>
              <td className='font-light'>
                {getEmployeeBankDetails?.data?.data?.length
                  ? getEmployeeBankDetails?.data?.data?.[0]?.ifsc_code
                  : blankData()}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Bank location</td>
              <td className='px-4'>: </td>
              <td className='font-light'>
                {getEmployeeBankDetails?.data?.data?.length
                  ? getEmployeeBankDetails?.data?.data?.[0]?.bank_location
                  : blankData()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <EditEmployeePersonalData
        isModalOpen={isPersonalDataEditModalOpen}
        setIsModalOpen={setPersonalDataEditModalOpen}
        employeeDetails={employeeDetails}
      />
      <EditEmployeeBankDetailsModal
        isModalOpen={isEmployeeBankDetailsModalOpen}
        setIsModalOpen={setIsEmployeeBankDetailsModalOpen}
        employeeId={employeeDetails?.id}
        employeeBankDetails={getEmployeeBankDetails?.data?.data}
      />
      <EditOtherDetailsOfEmployee
        isModalOpen={isOtherDataEditModalOpen}
        setIsModalOpen={setOtherDataEditModalOpen}
        employeeDetails={employeeDetails}
      />
    </div>
  );
}
