import React, { useState } from 'react';
import { ReactComponent as ActivityTrackerImg } from 'assets/images/activityTracker.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/crossIcon2.svg';
import PrimaryButton from 'components/primaryButton';
import { Drawer, TextInput } from '@mantine/core';
import useAddActivity from '../hooks/useAddActivity';
import useActivities from '../hooks/useActivities';
import { AuthContext } from 'context/authContext';
import { useContext } from 'react';
import ActivityItem from './ActivityItem';

export default function ActivityTracker() {
  const [showInput, setShowInput] = useState(false);
  const [openAllActivities, setOpenAllActivities] = useState(false);

  const {
    authState: { user_id },
  } = useContext(AuthContext);

  const getAllActivities = useActivities();
  const addActivityMutation = useAddActivity();

  return (
    <div className='bg-white shadow-four rounded-[10px] py-5 h-[280px] flex flex-col justify-between'>
      <div className='flex items-center justify-between px-9'>
        <p className='text-18 font-semibold text-222222'>Activity tracker</p>
        <button
          className='text-primary font-semibold'
          onClick={() => setOpenAllActivities(true)}
        >
          View all
        </button>
      </div>

      {getAllActivities?.data?.data?.length === 0 ? (
        <div className='flex flex-col items-center my-5'>
          <ActivityTrackerImg className='mx-auto' />
          <p className='text-gray3 mt-3 text-center'>
            Add tasks to your to-do list
          </p>
        </div>
      ) : (
        <div className='h-[65%] overflow-y-scroll scroller space-y-3 pl-9'>
          {getAllActivities?.data?.data?.map((activity) => {
            return <ActivityItem key={activity?.id} activity={activity} />;
          })}
        </div>
      )}

      {showInput ? (
        <TextInput
          placeholder='Start typing here and press enter'
          className='transition-all easy-linear w-full px-9'
          classNames={{
            input: 'border-0 border-b rounded-none border-primary mt-1',
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter' && event.target.value !== '') {
              addActivityMutation.mutate({
                user_id,
                task: event.target.value,
              });
              event.target.value = '';
            }
          }}
        />
      ) : (
        <button onClick={() => setShowInput(true)} className='w-28 mx-auto'>
          <PrimaryButton>
            <p className='w-28'>Add now</p>
          </PrimaryButton>
        </button>
      )}

      <Drawer
        opened={openAllActivities}
        onClose={() => setOpenAllActivities(false)}
        position='right'
        size='40%'
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        withCloseButton={false}
      >
        <div className='h-full flex flex-col justify-between px-8 relative'>
          <div className='h-[90%]'>
            <p className='text-[26px] leading-8 mt-10 mb-5'>Activity tracker</p>

            {getAllActivities?.data?.data?.length === 0 ? (
              <div className='flex flex-col items-center my-40'>
                <ActivityTrackerImg className='mx-auto' />
                <p className='text-gray3 mt-3 text-center'>
                  Add tasks to your to-do list
                </p>
              </div>
            ) : (
              <div className='h-[86%] overflow-y-scroll scroller space-y-5'>
                {getAllActivities?.data?.data?.map((activity) => {
                  return (
                    <ActivityItem key={activity?.id} activity={activity} />
                  );
                })}
              </div>
            )}
          </div>

          <div className='pb-4'>
            <TextInput
              placeholder='Start typing here and press enter'
              className='transition-all easy-linear w-full'
              classNames={{
                input: 'border-0 border-b rounded-none border-primary ',
              }}
              onKeyUp={(event) => {
                if (event.key === 'Enter' && event.target.value !== '') {
                  addActivityMutation.mutate({
                    user_id,
                    task: event.target.value,
                  });
                  event.target.value = '';
                }
              }}
            />
          </div>
          <CrossIcon
            className='absolute right-10 cursor-pointer w-5 h-5 top-4'
            onClick={() => setOpenAllActivities(false)}
          />
        </div>
      </Drawer>
    </div>
  );
}
