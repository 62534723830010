import { Modal } from '@mantine/core';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';
import FileUpload from 'components/fileUpload';

// import useUpdateSkillDevelopment from '../hooks/useUpdateSkillDevelopment';
import useUpdateSkillDevelopmentInTeams from 'pages/team/pages/myTeam/hooks/useUpdateSkillDevelopment';

const SkillDevelopmentCertificateModal = ({
  UploadCertificateModal,
  setUploadCertificateModal,
  skillDevelopmentId,
  setSkillDevelopmentId,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const closeModal = () => {
    setUploadCertificateModal(false);
    setSkillDevelopmentId(null);
    reset({ file: '' });
  };

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 my-4'>
        <LeftArrowIcon
          onClick={() => {
            setUploadCertificateModal(false);
            closeModal();
          }}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>Upload a file</p>
      </div>
    );
  };

  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const updateCertificateMutation =
    useUpdateSkillDevelopmentInTeams(closeModal);

  const uploadFile = (data) => {
    const formData = new FormData();

    formData.append('certificate', data?.file[0]);
    formData.append('id', skillDevelopmentId);
    updateCertificateMutation.mutate(formData);
  };

  return (
    <Modal
      opened={UploadCertificateModal}
      onClose={closeModal}
      title={customTitle()}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size={700}
      radius='md'
      overflow='inside'
      transitionDuration={100}
      classNames={{
        title: 'w-full  font-semibold text-18',
        modal: 'pl-10',
        body: 'scroller -mr-4 pr-2',
      }}
    >
      <form onSubmit={handleSubmit(uploadFile)}>
        <div className='py-2 w-full'>
          <Controller
            control={control}
            name='file'
            rules={{
              required: 'Required',
            }}
            render={({ field: { onChange } }) => (
              <FileUpload
                multiple={false}
                setImage={onChange}
                value={watch('file')}
              />
            )}
          />
          {getErrorMessage('certificate')}
        </div>
        {/* <UploadFileModal /> */}
        <div className='flex justify-center items-center my-5'>
          <button className=' w-28'>
            <PrimaryButton>Save</PrimaryButton>
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SkillDevelopmentCertificateModal;
