import { Switch } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import useEditSalaryGrade from 'pages/settings/hooks/useEditSalaryGrade';
import React, { useState } from 'react';
import useGetAllGrades from '../hooks/useGetAllGrades';
import AddGradeModal from './addGradeModal';

export default function EmploymentGrades({ organisation }) {
  const [isAddGradeModal, setAddGradeModalOpen] = useState(false);

  const allSalaryGrades = useGetAllGrades(organisation?.company__id);
  const updateEmployeeGrade = useEditSalaryGrade();
  

  return (
    <div className=''>
      {' '}
      {/* employment grades */}
      <div className='flex items-center justify-between h-14 border-b border-868686 border-opacity-30 py-8 '>
        <p className='text-18'>Employment grades</p>
        <button
          onClick={() => setAddGradeModalOpen(true)}
          className='w-36 whitespace-nowrap'
        >
          <PrimaryButton>
            <p>+ Add grades</p>
          </PrimaryButton>
        </button>
      </div>
      {allSalaryGrades?.data?.data?.map((type) => {
        return (
          <div
            className='flex items-center justify-between  my-6'
            key={type?.id}
          >
            <p className='text-gray'>{type?.name} </p>
            <Switch
              size='md'
              checked={type?.is_active || ''}
              onChange={() => {
                updateEmployeeGrade.mutate({
                  id: type?.id,
                  is_active: !type?.is_active,
                });
              }}
            />
          </div>
        );
      })}
      <AddGradeModal
        isModalOpen={isAddGradeModal}
        setIsModalOpen={setAddGradeModalOpen}
        organisation={organisation}
      />
    </div>
  );
}
