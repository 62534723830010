import React from 'react';
import Header from 'components/Header';
import CalendarWrapper from './components/calendar-wrapper';

export default function OrganisationCalender() {
  return (
    <div className='px-5'>
      <div className='flex items-center justify-between mt-6'>
        <p className='text-xl text-A8A8A8'>
          Organisation &#60;
          <span className='text-gray font-semibold'>
            {' '}
            Organisation Calendar
          </span>
        </p>
        <Header />
      </div>
      <CalendarWrapper />
    </div>
  );
}
