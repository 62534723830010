import { useContext, useEffect, useState } from 'react';

import { Tabs, Modal, Input, Select, Loader } from '@mantine/core';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/downloadcircleIcon.svg';
import { ReactComponent as WhiteRightIcon } from 'assets/icons/whiterightIcon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import ActiveEmployeePayrollTab from './activeEmployeeTab';

import Usegetspecificpayrolldata from '../hooks/usegetspecificpayrolldata';
import UseGetEmployeementTypes from '../hooks/useGetEmploymentTypes';
import Usegetspecificpayrollheaderdata from '../hooks/usegetspecificpayrollheaderdata';

import UsepostApprovepayroll from '../hooks/usepostApprovepayroll';
import { AuthContext } from 'context/authContext';

import getSymbolFromCurrency from 'currency-symbol-map';

const ApprovePayroll = ({
  setApprovePayrollOpened,
  approvePayrollOpened,
  setPayrollId,
  payrollId,
}) => {
  const { authState } = useContext(AuthContext);
  const currencyIcon = getSymbolFromCurrency(authState?.currency);
  const employeementTypesData = UseGetEmployeementTypes();
  const approvepayrollmutation = UsepostApprovepayroll();

  const [searchText, setSearchText] = useState(null);
  const [filterType, setFilterType] = useState();

  const [employeeCount, setEmployeeCount] = useState(0);
  const [employessNetSalary, setEmployessNetSalary] = useState(0);
  const [employeeIds, setEmployeeIds] = useState([]);

  useEffect(() => {
    setSearchText(null);
    setFilterType();
    setEmployeeCount(0);
    setEmployessNetSalary(0);
  }, [approvePayrollOpened]);

  const specificPayrollData = Usegetspecificpayrolldata(
    payrollId,
    null,
    searchText,
    filterType
  );

  const headerData = Usegetspecificpayrollheaderdata(payrollId);

  // console.log(specificPayrollData);
  // payroll__is_applicable_for_all

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 mt-3'>
        <LeftArrowIcon
          onClick={() => setApprovePayrollOpened(false)}
          className='w-4 absolute left-0 cursor-pointer'
        />
      </div>
    );
  };

  const aprovePayroll = () => {
    // eslint-disable-next-line
    {
      specificPayrollData?.data?.data?.payroll__is_applicable_for_all
        ? approvepayrollmutation.mutate({
            payroll_id: payrollId,
          })
        : approvepayrollmutation.mutate({
            payroll_id: payrollId,
            employees: employeeIds,
          });
    }
  };

  const getEmployementTypesData = () => {
    const finalArray = [{ value: null, label: 'All' }];
    employeementTypesData?.data?.data &&
      employeementTypesData?.data?.data?.map((dept) => {
        finalArray.push({ value: `${dept.id}`, label: dept.name });
        return true;
      });
    return finalArray;
  };

  return (
    <div>
      
      <Modal
        opened={approvePayrollOpened}
        onClose={() => {
          setApprovePayrollOpened(false);
          setPayrollId(null);
        }}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size={'80%'}
        overflow='inside'
        className='p-8'
        classNames={{
          title: 'text-lg font-semibold ',

          body: 'scroller',
        }}
      >
        <div className='px-10 mt-2 w-full'>
          {/*header*/}
          <div className='flex items-center justify-between'>
            <h1 className='text-primary font-semibold text-xl'>
              Processing payroll
            </h1>
            <div className='flex items-center gap-3'>
              <DownloadIcon className='h-7 w-7 cursor-pointer' />
              {specificPayrollData?.data?.data?.payroll__is_approved ? (
                <button className='flex items-center bg-[#28B446] text-[#ffffff] h-10 justify-center rounded-md w-32 border-[#F8CA54] cursor-not-allowed gap-1'>
                  <WhiteRightIcon />
                  Approved
                </button>
              ) : (
                <>
                  {specificPayrollData?.data?.data
                    ?.payroll__is_applicable_for_all ? (
                    <button
                      className='flex items-center bg-[#F8CA54] text-[#ffffff] h-10 justify-center rounded-md w-32 border-[#F8CA54] gap-1'
                      onClick={aprovePayroll}
                    >
                      {approvepayrollmutation.isLoading ? (
                        <Loader size='sm' color='green' />
                      ) : (
                        <>
                          <WhiteRightIcon />
                          Approve
                        </>
                      )}
                    </button>
                  ) : employeeIds.length === 0 ? (
                    <button className='flex items-center bg-[#F8CA54] text-[#ffffff] h-10 justify-center rounded-md w-32 border-[#F8CA54] opacity-50 cursor-not-allowed gap-1'>
                      <WhiteRightIcon />
                      Approve
                    </button>
                  ) : (
                    <button
                      className='flex items-center bg-[#F8CA54] text-[#ffffff] h-10 justify-center rounded-md w-32 border-[#F8CA54] gap-1'
                      onClick={aprovePayroll}
                    >
                      {approvepayrollmutation.isLoading ? (
                        <Loader size='sm' color='green' />
                      ) : (
                        <>
                          <WhiteRightIcon />
                          Approve
                        </>
                      )}
                    </button>
                  )}{' '}
                </>
              )}
            </div>
          </div>

          {/*Employees net pay*/}
          <div className='my-8 bg-[#FBF7F6] p-5 rounded-lg'>
            <table className='w-full'>
              <thead>
                <tr>
                  <th>Employees’ net pay</th>
                  <th>Payroll period</th>
                  <th>Payment date</th>
                  <th>No. of employees</th>
                </tr>
              </thead>
              <tbody className='w-full text-primary font-semibold'>
                <td className='text-center py-2'>
                  {headerData?.data?.data?.payroll__is_approved ? (
                    `${currencyIcon}${specificPayrollData?.data?.data?.total_net_pay}`
                  ) : (
                    <>
                      {headerData?.data?.data?.payroll__is_applicable_for_all
                        ? `${currencyIcon}${
                            specificPayrollData?.data?.data?.total_net_pay ?? 0
                          }`
                        : parseFloat(employessNetSalary).toFixed(2)}{' '}
                    </>
                  )}
                </td>
                <td className='text-center'>
                  {specificPayrollData?.data?.data?.payroll__from} -{' '}
                  {specificPayrollData?.data?.data?.payroll__to}
                </td>
                <td className='text-center'>
                  {specificPayrollData?.data?.data?.payroll__payment_date}
                </td>
                <td className='text-center'>
                  {headerData?.data?.data?.payroll__is_approved ? (
                    `${specificPayrollData?.data?.data?.total_employees}`
                  ) : (
                    <>
                      {headerData?.data?.data?.payroll__is_applicable_for_all
                        ? `${specificPayrollData?.data?.data?.total_employees}`
                        : employeeCount}
                    </>
                  )}
                </td>
              </tbody>
            </table>
          </div>

          {/*tabs*/}

          <div className='relative'>
            <Tabs
              tabPadding={21}
              classNames={{
                tabsListWrapper: 'border-0',
                tabActive: ' font-semibold text-normal',
                tabControl: 'text-A8A8A8 leading-5 px-0 mr-4',
                tabLabel: 'text-normal ',
              }}
            >
              <Tabs.Tab label='Active employees'>
                <ActiveEmployeePayrollTab
                  employeeDetails={specificPayrollData?.data?.data}
                  employeeCount={employeeCount}
                  setEmployeeCount={setEmployeeCount}
                  employessNetSalary={employessNetSalary}
                  setEmployessNetSalary={setEmployessNetSalary}
                  employeeIds={employeeIds}
                  setEmployeeIds={setEmployeeIds}
                />{' '}
              </Tabs.Tab>
            </Tabs>
            <div className='absolute top-2 right-0'>
              <div className='flex items-center space-x-4'>
                <Input
                  icon={<SearchIcon />}
                  placeholder='Search'
                  value={searchText}
                  onChange={(event) => setSearchText(event.currentTarget.value)}
                  classNames={{
                    wrapper: 'border-primary border-b',
                    input: 'w-36 border-none',
                  }}
                  rightSection={
                    <Loader
                      size='xs'
                      className={`${
                        searchText && specificPayrollData.isLoading
                          ? 'visible'
                          : 'invisible'
                      }`}
                    />
                  }
                />
                <Select
                  // placeholder=''
                  icon={<FilterIcon />}
                  rightSection={<DownArrow />}
                  onChange={setFilterType}
                  data={getEmployementTypesData() || []}
                  classNames={{
                    wrapper: 'border-primary border-b',
                    input: 'w-40 border-none',
                  }}
                />
                <DownloadIcon className='h-7 w-7 cursor-pointer' />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ApprovePayroll;
