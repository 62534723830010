import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import BasePrivateLayout from "../pages/baseprivatelayout";
import Teams from "../pages/team";
import Dashboard from "../pages/dashboard";
import Settings from "../pages/settings";
import Organisation from "pages/organisation/organisation";
import AdminProfileDetails from "../pages/profile/pages/profileDetails";
import AdminCalender from "pages/adminCalender/calender";
import EmployeeCalendar from "pages/employeeCalendar/calender";

import ScrollToTop from "./scrollToTop";

import Auth from "../pages/auth/auth";
import CheckAuthPage from "pages/checkAuthPage";
import UserProfile from "pages/profile/pages/employeeProfile";
import AdminDashboard from "pages/adminDashboard/adminDashboard";
import Blogs from "pages/blogs/index";
import EmployeeBlog from "pages/employeeBlog";
import NoPageFound from "pages/common/pageNotFound";
import ComponentLevelErrorBoundary from "pages/common/componentLevelErrorBoundry";
import OrganisationSettings from "pages/organisationSettings";
import Repository from "pages/repository";
import Projects from "pages/projects";
import Mail from "pages/mail/mail";
import Meetings from "pages/meetings";
import ChatMainPage from "pages/chat";
import Meetingroom from "pages/meetingRoom";

function AppRoutes() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <ComponentLevelErrorBoundary>
          <Routes>
            <Route index element={<CheckAuthPage />} />
            <Route path="/auth/*" element={<Auth />} />
            <Route path="/app/*" element={<BasePrivateLayout />}>
              <Route path="admin/dashboard/*" element={<AdminDashboard />} />
              <Route path="employee/analytics/*" element={<Dashboard />} />
              {/* <Route path='projects/*' element={<Projects />} /> */}
              <Route path="team/*" element={<Teams />} />
              {/* <Route path='repository/*' element={<Repository />} /> */}
              {/* <Route path='meetings/*' element={<Meetings />} /> */}

              <Route path="settings" element={<Settings />} />
              <Route
                path="organisation-settings/*"
                element={<OrganisationSettings />}
              />
              <Route path="organisation/*" element={<Organisation />} />
              <Route path="admin/*" element={<AdminProfileDetails />} />
              <Route path="employee/*" element={<UserProfile />} />
              <Route path="admin/my-calender/*" element={<AdminCalender />} />
              <Route
                path="employee/my-calender/*"
                element={<EmployeeCalendar />}
              />
              {/* <Route path="blogs" element={<Blogs />} /> */}
              {/* <Route path="employee/blogs" element={<EmployeeBlog />} /> */}
              {/* <Route path="mail/*" element={<Mail />} /> */}

              <Route path="conversations" element={<ChatMainPage />} />
              <Route
                path="conversations/:conversationRoom"
                element={<ChatMainPage />}
              />

              {/* empty page when route not found */}
              <Route path="*" element={<NoPageFound />} />
            </Route>
            {/* empty page when route not found */}
            <Route path="meetingRoom/*" element={<Meetingroom />} />
            <Route path="*" element={<NoPageFound />} />
          </Routes>
        </ComponentLevelErrorBoundary>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default AppRoutes;
