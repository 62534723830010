// import { Input } from '@mantine/core';
import { Select, Textarea, TextInput, Tooltip } from '@mantine/core';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { ReactComponent as ImproveIcon } from 'assets/icons/improveIcon.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/successIcon.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow2.svg';

// import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
// import { ReactComponent as AddIcon } from 'assets/icons/pluswithcirclebg.svg';
// import { ReactComponent as DeleteIcon } from 'assets/icons/minuswithcirclebg.svg';
// import { ReactComponent as StackupIcon } from 'assets/icons/circlestackupIcon.svg';
// import SecondaryButton from 'components/secondaryButton';
import PrimaryButton from 'components/primaryButton';
import { useEffect } from 'react';
// import { ReactComponent as TickIcon } from 'assets/icons/circletickIcon.svg';

//hooks
// import UsegetskillsfromSettings from '../hooks/usegetskills';

// import useGetperformancereportemployee from '../hooks/useGetperformancereports';

import useGetPerformanceobjectiveratingemployee from '../hooks/useGetperformanceobjectivereatingemployee';
import useGetPerformancebehaviouralratingemployee from '../hooks/useGetperformancebehaviouralratingemployee';
import useGetPerformancekragoalsemployee from '../hooks/useGetperformancekragoalsemployee';
import useGetperformancereportemployee from '../hooks/useGetperformancereports';
import useUpdatebehaviourlemployee from '../hooks/useUpdatebehaviouralemployee';
import useUpdateobjectiveemployee from '../hooks/useUpdateobjectiveemployee';

const EmployeePerformanceFormField = ({
  specificPerformanceReport,
  setEmployeePerformancemodalOpened,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      first_kra: [
        {
          skill: '',
          expected_value: '',
          current_value: '',
          feedback: '',
          self_rating: '',
        },
      ],
      second_kra: [
        { skill: '', expected_value: '', feedback: '', self_rating: '' },
      ],
      expected_kra: [
        { skill: '', objective: '', expected_value: '', self_rating: '' },
      ],
    },
    mode: 'onChange',
  });

  const {
    fields: FirstFields,
    // prepend: FirstPrepend,
    // remove: FirstRemove,
  } = useFieldArray({
    control,
    name: 'first_kra',
  });

  const {
    fields: SecondFields,
    // prepend: SecondPrepend,
    // remove: SecondRemove,
  } = useFieldArray({
    control,
    name: 'second_kra',
  });
  const {
    fields: ThirdFields,
    // prepend: ThirdPrepend,
    // remove: ThirdRemove,
  } = useFieldArray({
    control,
    name: 'expected_kra',
  });

  // calculation
  // const [objratingmax, setobjratingmax] = useState(0);
  // const [objreatingaverageAcquired, setobjreatingaverageAcquired] = useState(0);

  // const [behaviouralMax, setBehaviouralMax] = useState(0);
  // const [behaviouralAcquired, setBehaviouralAcquired] = useState(0);

  // useEffect(() => {
  //   if (watch('first_kra')) {
  //     const averagemax = watch('first_kra').reduce(
  //       (total, next) => parseFloat(total) + parseFloat(next.expected_value),
  //       0
  //     );
  //     const averageAcquired =
  //       watch('first_kra').reduce(
  //         (total, next) => parseFloat(total) + parseFloat(next.current_value),
  //         0
  //       ) / averagemax;
  //     setobjratingmax((averagemax / averagemax) * 100);
  //     setobjreatingaverageAcquired(averageAcquired * 100);
  //   }
  //   if (watch('second_kra')) {
  //     const behaviouralmax = watch('second_kra').reduce(
  //       (total, next) => parseFloat(total) + parseFloat(next.expected_value),
  //       0
  //     );
  //     const behaviouralAcquiredavg =
  //       watch('second_kra').reduce(
  //         (total, next) => parseFloat(total) + parseFloat(next.current_value),
  //         0
  //       ) / behaviouralmax;
  //     setBehaviouralMax((behaviouralmax / behaviouralmax) * 100);
  //     setBehaviouralAcquired(behaviouralAcquiredavg * 100);
  //   }
  //   // eslint-disable-next-line
  // }, [watch()]);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className='text-[#D0342C] text-sm'>{errors[name].message}</p>
      )
    );
  };

  const getFieldArrayFormErrorMessageFirst = (index, subName) => {
    return (
      errors?.first_kra?.length && (
        <p className='text-[#D0342C] text-sm'>
          {errors?.first_kra[index]?.[subName]?.message}
        </p>
      )
    );
  };
  const getFieldArrayFormErrorMessageSecond = (index, subName) => {
    return (
      errors?.second_kra?.length && (
        <p className='text-[#D0342C] text-sm'>
          {errors?.second_kra[index]?.[subName]?.message}
        </p>
      )
    );
  };
  const getFieldArrayFormErrorMessageThird = (index, subName) => {
    return (
      errors?.expected_kra?.length && (
        <p className='text-[#D0342C] text-sm'>
          {errors?.expected_kra[index]?.[subName]?.message}
        </p>
      )
    );
  };

  // console.log(watch('first_kra'));

  // const getSkilldataFromsetting = UsegetskillsfromSettings();

  const objectivePerformance = useGetPerformanceobjectiveratingemployee(
    specificPerformanceReport?.id
  );
  const behaviouralPerformance = useGetPerformancebehaviouralratingemployee(
    specificPerformanceReport?.id
  );
  const kraGoals = useGetPerformancekragoalsemployee(
    specificPerformanceReport?.id
  );
  const overAllPerformance = useGetperformancereportemployee({
    employeeId: specificPerformanceReport?.employee__employee_id,
    id: specificPerformanceReport?.id,
  });

  const updateBehavioural = useUpdatebehaviourlemployee();
  const updateObjective = useUpdateobjectiveemployee();
  // console.log(objectivePerformance, behaviouralPerformance, kraGoals);

  useEffect(() => {
    reset({
      first_kra: objectivePerformance?.data?.data.map((first) => ({
        skill: first?.skill,
        current_value: first?.current_value,
        expected_value: first?.expected_value,
        feedback: first?.feedback,
        self_rating: first?.self_rating,
        object_id: `${first?.id}`,
      })),
      second_kra: behaviouralPerformance?.data?.data.map((first) => ({
        skill: first?.skill,
        current_value: first?.current_value,
        expected_value: first?.expected_value,
        feedback: first?.feedback,
        self_rating: first?.self_rating,
        object_id: `${first?.id}`,
      })),
      expected_kra: kraGoals?.data?.data.map((first) => ({
        skill: first?.skill,
        expected_value: first?.expected_value,
        objective: first?.objective,
        self_rating: '',
        object_id: `${first?.id}`,
      })),
    });
    setValue(
      'objective_feedback',
      overAllPerformance?.data?.data[0]?.objective_feedback
    );
    setValue(
      'objective_maximum',
      overAllPerformance?.data?.data[0]?.objective_maximum
    );
    setValue(
      'objective_awarded',
      overAllPerformance?.data?.data[0]?.objective_awarded
    );
    setValue(
      'behavioural_feedback',
      overAllPerformance?.data?.data[0]?.behavioural_feedback
    );
    setValue(
      'behavioural_maximum',
      overAllPerformance?.data?.data[0]?.behavioural_maximum
    );
    setValue(
      'behavioural_awarded',
      overAllPerformance?.data?.data[0]?.behavioural_awarded
    );
    setValue(
      'overall_feedback',
      overAllPerformance?.data?.data[0]?.overall_feedback
    );
    setValue(
      'overall_maximum',
      overAllPerformance?.data?.data[0]?.overall_maximum
    );
    setValue(
      'overall_awarded',
      overAllPerformance?.data?.data[0]?.overall_awarded
    );
    // eslint-disable-next-line
  }, [
    overAllPerformance?.data?.data,
    objectivePerformance?.data?.data,
    behaviouralPerformance?.data?.data,
    kraGoals?.data?.data,
  ]);

  //submit data in pot request
  const submitPerformanceReport = (data) => {
    // console.log(data);
    setEmployeePerformancemodalOpened(false);
  };

  // onKeyUp={(event) => {
  //   if (
  //     event.key === 'Enter' &&
  //     event.target.value.trim() !== ''
  //   ) {
  //     updateComapanypayrollData.mutate({
  //       id: companyDetails?.data?.data[0].id,
  //       professional_tax: event.target.value.trim(),
  //     });
  //   }
  // }}

  return (
    <div>
      <div className='flex space-x-4'>
        {' '}
        <div>
          <form onSubmit={handleSubmit()}>
            {/* Overall objective rating */}
            <h1 className='text-lg font-semibold mt-10'>
              Overall objective rating
            </h1>

            <div className='grid grid-cols-12 mt-7 -mb-3'>
              <p className='text-sm text-primary pb-1.5 col-span-4'>
                Objective description
              </p>
              <p className='text-sm text-primary pb-1.5 w-72 col-span-2 -ml-12'>
                Worth
              </p>
              <p className='text-sm text-primary pb-1.5 col-span-2 -ml-8'>
                Awarded
              </p>
              <p className='text-sm text-primary pb-1.5 col-span-2 -ml-5'>
                Comments / feedback
              </p>
              <p className='text-sm text-primary pb-1.5 col-span-2 -ml-1'>
                Self rating
              </p>
            </div>
            {/*First Field */}
            <div>
              {FirstFields.map((item, index) => (
                <div key={item.id} className='grid grid-cols-11 gap-3  my-2'>
                  <div className='col-span-3 py-1 '>
                    <Controller
                      control={control}
                      name={`first_kra.${index}.skill`}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          placeholder='Type a skill '
                          size='md'
                          readOnly
                          classNames={{
                            input: 'border-primary',
                            label: 'text-neutralsGrey text-sm',
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageFirst(index, 'skill')}
                  </div>
                  <div className='col-span-2 py-1 '>
                    {' '}
                    <Controller
                      control={control}
                      name={`first_kra.${index}.expected_value`}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size='md'
                          readOnly
                          type='number'
                          classNames={{
                            input: 'border-primary',
                            label: 'text-neutralsGrey text-sm',
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageFirst(
                      index,
                      'expected_value'
                    )}
                  </div>
                  <div className='col-span-2 py-1 '>
                    {' '}
                    <Controller
                      control={control}
                      // name='name'
                      name={`first_kra.${index}.current_value`}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size='md'
                          type='number'
                          rightSection={
                            parseInt(
                              watch(`first_kra.${index}.expected_value`)
                            ) <=
                            parseInt(
                              watch(`first_kra.${index}.current_value`)
                            ) ? (
                              <SuccessIcon className='h-5 w-5' />
                            ) : (
                              <ImproveIcon className='h-5 w-5' />
                            )
                          }
                          readOnly
                          classNames={{
                            input: 'border-primary ',
                            label: 'text-neutralsGrey text-[12px] ml-2 ',
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageFirst(index, 'current_value')}
                  </div>
                  <div className='col-span-2 py-1 '>
                    <Tooltip
                      label={watch(`first_kra.${index}.feedback`)}
                      wrapLines
                      classNames={{
                        body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                      }}
                      radius='md'
                    >
                      {' '}
                      <Controller
                        control={control}
                        name={`first_kra.${index}.feedback`}
                        render={({ field }) => (
                          <TextInput
                            {...field}
                            size='md'
                            readOnly
                            classNames={{
                              input: 'border-primary ',
                              label: 'text-neutralsGrey text-[12px] ml-2 ',
                            }}
                          />
                        )}
                      />
                      {getFieldArrayFormErrorMessageFirst(index, 'feedback')}
                    </Tooltip>
                  </div>
                  {/* <StackupIcon className='h-6 w-6' /> */}
                  {/* <TickIcon className='h-6 w-6' /> */}
                  {/* {index === 0 ? (
                      <button type='button' onClick={() => FirstPrepend()}>
                        <AddIcon className='h-8 w-8' />
                      </button>
                    ) : (
                      <button type='button' onClick={() => FirstRemove(index)}>
                        <DeleteIcon className='h-8 w-8' />
                      </button>
                    )} */}
                  <div className='col-span-2 py-1 '>
                    {' '}
                    <Controller
                      control={control}
                      name={`first_kra.${index}.self_rating`}
                      rules={{
                        min: {
                          value: 0,
                          message: 'value should be greate than 0 ',
                        },
                        max: {
                          value: watch(`first_kra.${index}.expected_value`),
                          message: `value should be less than ${watch(
                            `first_kra.${index}.expected_value`
                          )}`,
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size='md'
                          type='number'
                          placeholder='Type and Enter'
                          rightSection={
                            parseFloat(
                              watch(`first_kra.${index}.expected_value`)
                            ) <=
                            parseFloat(
                              watch(`first_kra.${index}.self_rating`)
                            ) ? (
                              <SuccessIcon className='h-5 w-5' />
                            ) : (
                              <ImproveIcon className='h-5 w-5' />
                            )
                          }
                          onKeyUp={(event) => {
                            if (
                              !getFieldArrayFormErrorMessageFirst(
                                index,
                                'self_rating'
                              )
                            ) {
                              if (
                                event.key === 'Enter' &&
                                event.target.value.trim() !== ''
                              ) {
                                updateObjective.mutate({
                                  id: item.object_id,
                                  self_rating: event.target.value.trim(),
                                });
                              }
                            }
                          }}
                          classNames={{
                            input: 'border-primary text-sm',
                            label: 'text-neutralsGrey text-sm',
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageFirst(index, 'self_rating')}
                  </div>
                </div>
              ))}
            </div>
            {/* Comment section */}
            <div className='mt-4'>
              <Controller
                control={control}
                name='objective_feedback'
                render={({ field }) => (
                  <Textarea
                    {...field}
                    size='md'
                    readOnly
                    placeholder='Add Comment'
                    label='Overall comment / feedback'
                    classNames={{
                      input: 'h-[75px] border-primary',
                      label: 'text-primary text-sm',
                    }}
                  />
                )}
              />
              {getFormErrorMessage('objective_feedback')}
            </div>
            <div className='grid grid-cols-2 gap-3'>
              <div className='mt-3'>
                <Controller
                  control={control}
                  name='objective_maximum'
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size='md'
                      // value={`${parseFloat(objratingmax).toFixed(2)}`}
                      readOnly
                      placeholder='Maximum %'
                      type='number'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getFormErrorMessage('objective_maximum')}
              </div>
              <div className='mt-3'>
                <Controller
                  control={control}
                  name='objective_awarded'
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size='md'
                      // value={`${parseFloat(objreatingaverageAcquired).toFixed(
                      //   2
                      // )}`}
                      type='number'
                      readOnly
                      placeholder='Acquired %'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getFormErrorMessage('objective_awarded')}
              </div>{' '}
            </div>
            {/* Overall behavioural assessment rating */}
            <h1 className='text-lg font-semibold mt-10'>
              Overall behavioural assessment rating
            </h1>

            {/*Second Field */}
            <div className='grid grid-cols-12 mt-7 -mb-3'>
              <p className='text-sm text-primary pb-1.5 col-span-4'>
                Objective description
              </p>
              <p className='text-sm text-primary pb-1.5 w-72 col-span-2 -ml-12'>
                Worth
              </p>
              <p className='text-sm text-primary pb-1.5 col-span-2 -ml-8'>
                Awarded
              </p>
              <p className='text-sm text-primary pb-1.5 col-span-2 -ml-5'>
                Comments / feedback
              </p>
              <p className='text-sm text-primary pb-1.5 col-span-2 -ml-1'>
                Self rating
              </p>
            </div>
            {SecondFields.map((item, index) => (
              <div key={item.id} className='grid grid-cols-11 gap-3 '>
                <div className='col-span-3 py-1 mt-3'>
                  <Controller
                    control={control}
                    name={`second_kra.${index}.skill`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        readOnly
                        placeholder='Type a skill '
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getFieldArrayFormErrorMessageSecond(index, 'skill')}
                </div>
                <div className='col-span-2 py-1 mt-3'>
                  {' '}
                  <Controller
                    control={control}
                    name={`second_kra.${index}.expected_value`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        readOnly
                        size='md'
                        type='number'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getFieldArrayFormErrorMessageSecond(index, 'expected_value')}
                </div>
                <div className='col-span-2 py-1 mt-3'>
                  {' '}
                  <Controller
                    control={control}
                    name={`second_kra.${index}.current_value`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        readOnly
                        size='md'
                        rightSection={
                          parseInt(
                            watch(`second_kra.${index}.expected_value`)
                          ) <=
                          parseInt(
                            watch(`second_kra.${index}.current_value`)
                          ) ? (
                            <SuccessIcon className='h-5 w-5' />
                          ) : (
                            <ImproveIcon className='h-5 w-5' />
                          )
                        }
                        type='number'
                        classNames={{
                          input: 'border-primary ',
                          label: 'text-neutralsGrey text-[12px] ml-2 ',
                        }}
                      />
                    )}
                  />
                  {getFieldArrayFormErrorMessageSecond(index, 'current_value')}
                </div>
                <div className='col-span-2 py-1 mt-3'>
                  {' '}
                  <Tooltip
                    label={watch(`second_kra.${index}.feedback`)}
                    wrapLines
                    classNames={{
                      body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                    }}
                    radius='md'
                  >
                    <Controller
                      control={control}
                      name={`second_kra.${index}.feedback`}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size='md'
                          readOnly
                          classNames={{
                            input: 'border-primary ',
                            label: 'text-neutralsGrey text-[12px] ml-2 ',
                          }}
                        />
                      )}
                    />
                  </Tooltip>
                  {getFieldArrayFormErrorMessageSecond(index, 'feedback')}
                </div>
                {/* <StackupIcon className='h-6 w-6' /> */}
                {/* <TickIcon className='h-6 w-6' /> */}
                {/* {index === 0 ? (
                    <button type='button' onClick={() => SecondPrepend()}>
                      <AddIcon className='h-8 w-8' />
                    </button>
                  ) : (
                    <button type='button' onClick={() => SecondRemove(index)}>
                      <DeleteIcon className='h-8 w-8' />
                    </button>
                  )} */}
                <div className='col-span-2 py-1 mt-3'>
                  {' '}
                  <Controller
                    control={control}
                    name={`second_kra.${index}.self_rating`}
                    rules={{
                      min: {
                        value: 0,
                        message: 'value should be greate than 0 ',
                      },
                      max: {
                        value: watch(`second_kra.${index}.expected_value`),
                        message: `value should be less than ${watch(
                          `second_kra.${index}.expected_value`
                        )}`,
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size='md'
                        type='number'
                        placeholder='Type and Enter'
                        rightSection={
                          parseInt(
                            watch(`second_kra.${index}.expected_value`)
                          ) <=
                          parseInt(watch(`second_kra.${index}.self_rating`)) ? (
                            <SuccessIcon className='h-5 w-5' />
                          ) : (
                            <ImproveIcon className='h-5 w-5' />
                          )
                        }
                        onKeyUp={(event) => {
                          if (
                            !getFieldArrayFormErrorMessageSecond(
                              index,
                              'self_rating'
                            )
                          ) {
                            if (
                              event.key === 'Enter' &&
                              event.target.value.trim() !== ''
                            ) {
                              updateBehavioural.mutate({
                                id: item.object_id,
                                self_rating: event.target.value.trim(),
                              });
                            }
                          }
                        }}
                        classNames={{
                          input: 'border-primary text-sm',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getFieldArrayFormErrorMessageSecond(index, 'self_rating')}
                </div>
              </div>
            ))}
            {/* Comment section */}
            <div className='mt-4'>
              <Controller
                control={control}
                name='behavioural_feedback'
                render={({ field }) => (
                  <Textarea
                    {...field}
                    readOnly
                    size='md'
                    label='Overall comment / feedback'
                    placeholder='Add Comment'
                    classNames={{
                      input: 'h-[75px] border-primary',
                      label: 'text-primary text-sm',
                    }}
                  />
                )}
              />
              {getFormErrorMessage('behavioural_feedback')}
            </div>
            <div className='grid grid-cols-2 gap-3'>
              <div className='mt-3'>
                <Controller
                  control={control}
                  name='behavioural_maximum'
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size='md'
                      placeholder='Maximum %'
                      type='number'
                      readOnly
                      classNames={{
                        input: 'border-primary ',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getFormErrorMessage('behavioural_maximum')}
              </div>
              <div className='mt-3'>
                <Controller
                  control={control}
                  name='behavioural_awarded'
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size='md'
                      readOnly
                      placeholder='Acquired %'
                      type='number'
                      classNames={{
                        input: 'border-primary ',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getFormErrorMessage('behavioural_awarded')}
              </div>{' '}
            </div>
            <div className='flex justify-between items-center mt-10'>
              <h1 className='text-lg font-semibold mt-4'>
                Set KRA / goals for next review
              </h1>
            </div>
            {/* Third Field */}
            <div>
              {ThirdFields.map((item, index) => (
                <div key={item.id} className='grid grid-cols-12 gap-3 '>
                  <div className='col-span-5 py-1 mt-3'>
                    <Controller
                      control={control}
                      name={`expected_kra.${index}.skill`}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          readOnly
                          placeholder='Type a skill '
                          size='md'
                          classNames={{
                            input: 'border-primary',
                            label: 'text-neutralsGrey text-sm',
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageThird(index, 'skill')}
                  </div>
                  <div className='col-span-4 py-1 mt-3'>
                    <Controller
                      control={control}
                      // name='name'
                      name={`expected_kra.${index}.objective`}
                      rules={{
                        required: 'Required',
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder='Type a skill '
                          readOnly
                          // disabled
                          rightSection={<DownArrowIcon />}
                          styles={{
                            rightSection: {
                              pointerEvents: 'none',
                            },
                          }}
                          size='md'
                          data={[
                            { value: 'Behavioural', label: 'Behavioural' },
                            { value: 'Objective', label: 'Objective' },
                          ]}
                          classNames={{
                            input: 'border-primary',
                            label: 'text-neutralsGrey text-sm',
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageThird(index, 'objective')}
                  </div>
                  <div className='col-span-3 py-1 mt-3'>
                    {' '}
                    <Controller
                      control={control}
                      name={`expected_kra.${index}.expected_value`}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size='md'
                          readOnly
                          type='number'
                          classNames={{
                            input: 'border-primary',
                            label: 'text-neutralsGrey text-sm',
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageThird(
                      index,
                      'expected_value'
                    )}
                  </div>

                  {/* <StackupIcon className='h-6 w-6' /> */}
                  {/* <TickIcon className='h-6 w-6' /> */}
                  {/* {index === 0 ? (
                      <button type='button' onClick={() => ThirdPrepend()}>
                        <AddIcon className='h-8 w-8' />
                      </button>
                    ) : (
                      <button type='button' onClick={() => ThirdRemove(index)}>
                        <DeleteIcon className='h-8 w-8' />
                      </button>
                    )} */}
                </div>
              ))}
            </div>
            <h1 className='text-lg font-semibold mt-10 '>
              Overall performance summary
            </h1>
            {/* Comment section */}
            <div className='mt-4'>
              <Controller
                control={control}
                name='overall_feedback'
                render={({ field }) => (
                  <Textarea
                    {...field}
                    size='md'
                    placeholder='Type here'
                    readOnly
                    classNames={{
                      input: 'h-[75px] border-primary',
                      label: 'text-primary text-sm',
                    }}
                  />
                )}
              />
              {getFormErrorMessage('overall_feedback')}
            </div>
            <div className='grid grid-cols-2 gap-3'>
              <div className=' py-1 mt-3'>
                {' '}
                <Controller
                  control={control}
                  name='overall_maximum'
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size='md'
                      type='number'
                      readOnly
                      label='Overall maximum'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-primary text-sm',
                      }}
                    />
                  )}
                />
                {getFormErrorMessage('overall_maximum')}
              </div>
              <div className=' py-1 mt-3'>
                {' '}
                <Controller
                  control={control}
                  name='overall_awarded'
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size='md'
                      type='number'
                      readOnly
                      label='Overall secured'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-primary text-sm',
                      }}
                    />
                  )}
                />
                {getFormErrorMessage('overall_awarded')}
              </div>
            </div>
            <div className='flex justify-center items-center gap-3 mt-10 mb-5'>
              <button type='button' onClick={submitPerformanceReport}>
                <PrimaryButton>Close report</PrimaryButton>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployeePerformanceFormField;
