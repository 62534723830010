import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function usePayslips(year) {
  return useQuery(['payslips', year], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_PAYSLIPS, {
        year: year,
      })
    );
    return response.data;
  });
}
