import React, { useEffect } from 'react';
import { TextInput, Textarea, Select } from '@mantine/core';
import { Controller } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';

import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow2.svg';
import useGetCountries from '../hooks/useGetCountriesData';

export default function OrganisationFirstStep({
  handleSubmit,
  control,
  errors,
  onFirstStepSubmit,
  formName,
  watch,
  setValue,
}) {
  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500 pt-0.5'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const countriesData = useGetCountries();

  const watchCountry = watch('country');

  useEffect(() => {
    countriesData?.data?.data?.map((country) => {
      if (country?.common_name === watchCountry) {
        setValue('country_code', country?.phone_code);
      }
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCountry]);

  return (
    <div>
      <form onSubmit={handleSubmit(onFirstStepSubmit)}>
        <div className='flex flex-wrap space-y-2 mx-4'>
          <div className='w-1/2 mt-2 pr-4'>
            <Controller
              control={control}
              name='first_name'
              rules={{
                required: 'Required',
              }}
              render={({ field }) => (
                <TextInput
                  disabled={formName === 'Edit'}
                  {...field}
                  label='Full Name (Account owner)*'
                  size='sm'
                  classNames={{
                    input:
                      ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-3 h-12',
                    label: 'text-[#8E8E8E] text-sm font-normal ',
                  }}
                />
              )}
            />
            {getErrorMessage('first_name')}
          </div>
          <div className='w-1/2 pl-4'>
            <Controller
              control={control}
              name='company_name'
              rules={{
                required: 'Required',
              }}
              render={({ field }) => (
                <TextInput
                  disabled={formName === 'Edit'}
                  {...field}
                  label='Company / Organisation name*'
                  size='sm'
                  classNames={{
                    input:
                      ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-3 h-12',
                    label: 'text-[#8E8E8E] text-sm font-normal ',
                  }}
                />
              )}
            />
            {getErrorMessage('company_name')}
          </div>
          <div className='w-1/2 pr-4'>
            <Controller
              control={control}
              name='email'
              rules={{
                required: 'Required',
                pattern: {
                  value:
                    /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  disabled={formName === 'Edit'}
                  label='Email*'
                  size='sm'
                  classNames={{
                    input:
                      ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-3 h-12',
                    label: 'text-[#8E8E8E] text-sm font-normal ',
                  }}
                />
              )}
            />
            {getErrorMessage('email')}
          </div>
          <div className='w-1/2 pl-4'>
            <Controller
              control={control}
              name='country'
              rules={{
                required: 'Required',
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  searchable
                  data={
                    !countriesData?.data?.data?.length
                      ? []
                      : countriesData?.data?.data?.map((country) => {
                          return {
                            value: country.common_name,
                            label: country.common_name,
                          };
                        })
                  }
                  size='md'
                  label='Country*'
                  rightSection={<DownArrowIcon />}
                  styles={{
                    rightSection: {
                      pointerEvents: 'none',
                    },
                  }}
                  classNames={{
                    input:
                      ' text-[15px] bg-transparent border-[#FB7C51] text-normal rounded pl-3 h-12',
                    label: 'text-[#8E8E8E] text-sm font-normal ',
                  }}
                />
              )}
            />
            {getErrorMessage('country')}
          </div>
          <div className='w-1/2 pr-4'>
            <p className='text-[#8E8E8E] text-sm font-normal  pb-1 pt-1.5'>
              Company contact number*
            </p>
            <div className='flex'>
              <div className='w-1/3'>
                <Controller
                  control={control}
                  name='country_code'
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      searchable
                      readOnly
                      size='md'
                      // label=' country code'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent border-[#FB7C51] text-normal rounded pl-3 h-12',
                        label: 'text-[#8E8E8E] text-sm font-normal ',
                      }}
                    />
                  )}
                />
              </div>
              <div className='w-4/5 pl-4'>
                <Controller
                  control={control}
                  name='mobile_number'
                  rules={{
                    required: 'Required',
                    pattern: /^\d{1,14}$/i,
                    // pattern:/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i  this pattern is also valid
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      // label='Company contact number*'
                      size='sm'
                      type='number'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent border-[#FB7C51] text-normal rounded pl-3 h-12',
                        label: 'text-[#8E8E8E] text-sm font-normal ',
                      }}
                    />
                  )}
                />
                {getErrorMessage('mobile_number')}
                {errors?.mobile_number?.type === 'pattern' && (
                  <p className=' text-sm font-semibold text-red-500 pt-0.5'>
                    invalid number
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className='w-1/2 pl-4'>
            <Controller
              control={control}
              name='city'
              rules={{
                required: 'Required',
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label='City*'
                  size='sm'
                  classNames={{
                    input:
                      ' text-[15px] bg-transparent border-[#FB7C51] text-normal rounded pl-3 h-12',
                    label: 'text-[#8E8E8E] text-sm font-normal ',
                  }}
                />
                // <Select
                //   {...field}
                //   searchable
                //   creatable={true}
                //   getCreateLabel={(query) => `+ Create ${query}`}
                //   data={[{ value: 'sa', label: 'asdsa' }]}
                //   size='md'
                //   label='City'
                //   rightSection={<DownArrowIcon />}
                //   styles={{
                //     rightSection: {
                //       pointerEvents: 'none',
                //     },
                //   }}
                //   classNames={{
                //     input:
                //       ' text-[15px] bg-transparent border-[#FB7C51] text-normal rounded pl-3 h-12',
                //     label: 'text-[#8E8E8E] text-sm font-normal ',
                //   }}
                // />
              )}
            />
            {getErrorMessage('city')}
          </div>
          <div className='w-full'>
            <Controller
              control={control}
              name='company_address'
              rules={{
                required: 'Required',
              }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  label='Company Address*'
                  size='sm'
                  classNames={{
                    input:
                      ' text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-3 h-20 scroller',
                    label: 'text-[#8E8E8E] text-sm font-normal ',
                  }}
                />
              )}
            />
            {getErrorMessage('company_address')}
          </div>
        </div>
        <div className='flex justify-center mt-5'>
          <button className='w-32'>
            <PrimaryButton>Next</PrimaryButton>
          </button>
        </div>
      </form>
    </div>
  );
}
