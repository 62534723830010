import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PoliciesModal from './newpoliciesmodal';

import pdfIcon from 'assets/images/pdfIconk.png';

import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eyeIcon.svg';

import DeletePolicy from './deletepolicymodal';
import { Tooltip } from '@mantine/core';
import ViewPolicyModal from './viewpolicymodal';

export default function PolicyCardComponent({ eachpolicy }) {
  const [isPoliciesModalOpened, setIsPoliciesModalOpened] = useState(false);
  const [policyId, setPolicyId] = useState(null);
  const [deletepolicymodal, setdeletepolicymodal] = useState(false);
  const [viewPolicyModal, setViewPolicyModal] = useState(false);
  const [policyDocument, setPolicyDocument] = useState(null);

  const navigate = useNavigate();

  return (
    <>
      <div
        className='w-100% bg-[#fff] shadow-2xl rounded-xl h-48 border border-gray3 border-opacity-20'
        key={eachpolicy.id}
      >
        <div
          className='h-[70%]  rounded-t-xl flex justify-end items-start gap-3 p-4'
          style={{
            backgroundImage: 'URL(' + pdfIcon + ')',
            // backgroundSize: '100% 70%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {eachpolicy?.policy_document ? (
            <EyeIcon
              className='mb-1 h-5 w-5 cursor-pointer'
              onClick={() => {
                setViewPolicyModal(true);
                setPolicyDocument(eachpolicy.policy_document);
              }}
            />
          ) : (
            <EyeIcon className='opacity-50 cursor-not-allowed mb-1 h-5 w-5' />
          )}

          <EditIcon
            className='cursor-pointer'
            onClick={() => {
              setIsPoliciesModalOpened(true);
              setPolicyId(eachpolicy.id);
            }}
          />
          {eachpolicy.is_default ? (
            <DeleteIcon className='opacity-50 cursor-not-allowed' />
          ) : (
            <DeleteIcon
              className='cursor-pointer'
              onClick={() => {
                // deletePolicy.mutate({ id: eachpolicy.id });
                setdeletepolicymodal(true);
                setPolicyId(eachpolicy.id);
              }}
            />
          )}
        </div>
        <div className='text-lg flex h-16 p-4 justify-between items-center'>
          {eachpolicy.name?.length > 19 ? (
            <Tooltip
              label={eachpolicy.name}
              width={220}
              wrapLines
              classNames={{
                body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
              }}
              radius='md'
            >
              <h1 className='text-lg font-medium w-40 truncate'>
                {eachpolicy.name}
              </h1>
            </Tooltip>
          ) : (
            <h1 className='text-lg font-medium w-40 truncate'>
              {eachpolicy.name}
            </h1>
          )}
          <p
            className='font-normal text-[#28B446] text-sm cursor-pointer'
            onClick={() =>
              navigate(`/app/organisation/key-policies/${eachpolicy.id}`, {
                replace: true,
              })
            }
          >
            See signatures
          </p>
        </div>
      </div>
      <PoliciesModal
        setIsPoliciesModalOpened={setIsPoliciesModalOpened}
        isPoliciesModalOpened={isPoliciesModalOpened}
        policyId={policyId}
        setPolicyId={setPolicyId}
      />
      <DeletePolicy
        setdeletepolicymodal={setdeletepolicymodal}
        deletepolicymodal={deletepolicymodal}
        policyId={policyId}
        setPolicyId={setPolicyId}
      />
      <ViewPolicyModal
        viewPolicyModal={viewPolicyModal}
        setViewPolicyModal={setViewPolicyModal}
        policyDocument={policyDocument}
        setPolicyDocument={setPolicyDocument}
        name={eachpolicy.name}
      />
    </>
  );
}
