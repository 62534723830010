import { useState } from 'react';
import { Select, Tabs } from '@mantine/core';
import Header from 'components/Header';
// import PrimaryButton from 'components/primaryButton';

// import { ReactComponent as SearchIcon } from '../../../../assets/icons/searchIcon.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filterIcon.svg';
import { ReactComponent as DownArrow } from '../../../../assets/icons/downArrow.svg';
import PayslipsTab from './pages/paysliptab';
import MySalaryTab from './pages/mysalarytab';

export default function Payslips() {
  //   const [activeTab, setActiveTab] = useState(0);
  const [payslipsYear, setPayslipsYear] = useState('');

  return (
    <div>
      <div className='px-5 pb-9'>
        <div className='flex justify-between items-center mt-6'>
          <p className='text-xl text-A8A8A8'>
            Organisation &#60;{' '}
            <span className='text-gray font-semibold'>Pay slips</span>
          </p>
          <Header />
        </div>
        {/* empty illustration */}

        <div className='mt-8 relative'>
          <div className='select-none'>
            <Tabs
              tabPadding={21}
              //   onTabChange={setActiveTab}
              // label=''.
              // variant='pills'
              classNames={{
                tabsListWrapper: 'border-0',

                tabActive: 'border-2 border-primary font-semibold text-18',
                tabControl: 'text-A8A8A8 leading-5 px-0 mr-4',
                tabLabel: 'text-18 text-gray  bg-transparent',
              }}
            >
              <Tabs.Tab
                label='Payslips'
                classNames={{ tabsListWrapper: 'border-0' }}
              >
                <PayslipsTab selectedYear={payslipsYear} />
              </Tabs.Tab>
              <Tabs.Tab label='My salary' disabled className='opacity-50'>
                <MySalaryTab />
              </Tabs.Tab>
              {/* <Tabs.Tab label='Income tax'>NULL</Tabs.Tab> */}
            </Tabs>
          </div>
          {/* search and filter */}
          <div className='absolute top-0 right-0'>
            <div className='flex items-center space-x-4'>
              {/* <TextInput
                icon={<SearchIcon />}
                placeholder='Search'
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-36 bg-light-bg border-none',
                }}
              /> */}
              <Select
                placeholder='Year'
                icon={<FilterIcon />}
                rightSection={<DownArrow />}
                clearable
                value={payslipsYear}
                onChange={(event) => {
                  setPayslipsYear(event);
                }}
                data={[
                  '2020',
                  '2021',
                  '2022',
                  '2023',
                  '2024',
                  '2025',
                  '2026',
                  '2027',
                  '2028',
                  '2029',
                ]}
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-40 bg-light-bg border-none',
                }}
                maxDropdownHeight='150px'
              />
            </div>
          </div>
          <div className='flex items-center fixed right-12  bottom-4'>
            <span className='w-3 h-3 bg-primary rounded-sm mr-2'></span>
            <p className='text-8B8B8B'>Reporting manager</p>
          </div>
        </div>
      </div>
    </div>
  );
}
