import {
  Avatar,
  Indicator,
  Modal,
  MultiSelect,
  Select,
  Tooltip,
  TextInput,
} from '@mantine/core';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow2.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/crossIcon.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logoutIcon.svg';

// import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { ErrorToast, SuccessToast } from 'services/toasterService';
import truncateString from 'helper/truncateString';
import StatusLabel from 'pages/chat/hooks/statusLabel';
import PrimaryButton from 'components/primaryButton';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/authContext';
// import { ChatContext } from 'context/chatContext';

const UpdateGroupModal = ({
  updateGroupModal,
  setUpdateGroupModal,
  groupInfo,
  conversationContainerSendJsonMessage,
  setUserHasWritePermission,
}) => {
  const queryClient = useQueryClient();
  // const queryClient = useQueryClient();
  // const { chatState, chatDispatch } = useContext(ChatContext);
  const [newAddTeamMembers, setNewAddTeamMembers] = useState([]);
  const { authState } = useContext(AuthContext);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    // watch,
    reset,
  } = useForm({ mode: 'onchange' });

  // useEffect(() => {
  //   reset({});

  //   // eslint-disable-next-line
  // }, [updateGroupModal]);

  useEffect(() => {
    reset({
      name: groupInfo.name,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInfo.name]);

  // const defaultValues = {
  //   name: '',
  // };

  const closeModal = () => {
    setUpdateGroupModal(false);
    // reset({ defaultValues });
  };

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 my-4'>
        <LeftArrowIcon
          onClick={() => {
            setUpdateGroupModal(false);
            closeModal();
          }}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>Update Group</p>
      </div>
    );
  };

  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const groupInformation = useQuery(
    ['current-chat-group-info', groupInfo.room_id],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(
          `${apiEndPoints.UPDATE_GROUP}${groupInfo.room_id}/`,
          {}
        )
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        reset({
          name: data.name,
        });
        setUserHasWritePermission(data?.user_group_permission ?? false);
      },
    }
  );

  const updateGroupmutation = useMutation(
    async (data) => {
      const response = await makeApiRequest.post(
        `${apiEndPoints.UPDATE_GROUP}${groupInfo.room_id}/`,
        { ...data, room_name: groupInfo.room_id }
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data?.message });
        queryClient.invalidateQueries('current-chat-group-info');
        queryClient.invalidateQueries('chat-group-members-addons');
        conversationContainerSendJsonMessage({
          type: 'group_info_updated',
        });
        // closeModal();
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
  // const getFolderData = useGetFolders(groupID);
  // const addNewEmployee = useAddNewEmpolyeeFolder();
  // const removeEmployee = useDeleteEmployeeFromFolder();

  const onSubmitCreateGroupForm = (data) => {
    let finalData = { name: data?.name };
    if (newAddTeamMembers?.length > 0) {
      finalData = {
        ...finalData,
        group_members: [...newAddTeamMembers],
        group_members_status: 'add',
      };
      updateGroupmutation.mutate(finalData, {
        onSuccess: () => {
          setNewAddTeamMembers([]);
        },
      });
    } else {
      updateGroupmutation.mutate(finalData);
    }
  };

  // const getAdminMemberStatus = (userID) => {
  //   if(groupInformation?.data?.admin.includes(userID)) {
  //     return 'admin'
  //   }
  // }

  // console.log('groupInformation,', groupInformation?.data?.group_members);

  const groupMembersList = useQuery(
    ['chat-group-members-addons', groupInfo.room_id],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.USERS_LIST, {
          filter_group_members: groupInfo.room_id,
        })
      );

      return response.data;
    }
  );

  const getDropdownData = () => {
    const dataArray = [];
    groupMembersList?.data?.data?.forEach((employee) => {
      dataArray.push({
        image: employee?.profile_picture,
        label: employee?.username,
        value: `${employee?.user_id}`,
        description: employee?.email,
      });
    });
    return dataArray;
  };

  //   custom dropdown item for select
  const SelectItem = ({
    activeStatus,
    value,
    label,
    image,
    name,
    description,
    ...others
  }) => {
    return (
      <div className='flex px-5 '>
        <div {...others} className='flex items-center py-2 cursor-pointer'>
          <Avatar src={image} color='red'>
            {label[0]?.toUpperCase()}
          </Avatar>
          <div className='pl-1 '>
            <p className='text-18 '>{label} </p>
            <p className='text-xs text-gray3 pt-1 '>{description} </p>
          </div>
        </div>
        {/* <p className='ml-auto'>
          {' '}
          {activeStatus ? (
            <span className='ml-3 text-[12px] text-[#28B446] bg-[#c1f3cc] py-1 px-2 rounded-lg '>
              Active
            </span>
          ) : (
            <span className='ml-3 text-[12px] text-[#D0342C] bg-[#f7bab6] py-1 px-2 rounded-lg'>
              Inactive
            </span>
          )}
        </p> */}
      </div>
    );
  };

  return (
    <Modal
      opened={updateGroupModal}
      onClose={() => {
        setUpdateGroupModal(false);
      }}
      title={customTitle()}
      withCloseButton={false}
      closeOnClickOutside={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size={600}
      radius='md'
      overflow='inside'
      transitionDuration={100}
      classNames={{
        title: 'w-full  font-semibold text-18',
        modal: 'px-10',
        body: 'scroller -mr-4 ',
      }}
    >
      <form className='px-4' onSubmit={handleSubmit(onSubmitCreateGroupForm)}>
        <div className='pb-2'>
          <Controller
            control={control}
            name='name'
            rules={{
              required: 'Required',
              pattern: {
                value: /^[ A-Za-z0-9_@.#&+(-)]*$/,
                message: 'Input must be an alphabets or integers',
              },
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                label='Group Name'
                size='md'
                readOnly={!groupInformation?.data?.req_user_is_admin}
                classNames={{
                  input: 'border-primary',
                  label: 'text-neutralsGrey text-sm',
                }}
              />
            )}
          />
          {getErrorMessage('name')}
        </div>

        <div className='flex items-end w-full'>
          <div className='flex-1 py-2'>
            <MultiSelect
              name='group_members'
              size='md'
              itemComponent={SelectItem}
              label='Add Group Members'
              placeholder='Search or select'
              searchable
              disabled={!groupInformation?.data?.req_user_is_admin}
              data={
                !groupMembersList?.data?.data?.length ? [] : getDropdownData()
              }
              value={newAddTeamMembers}
              onChange={(value) => {
                setNewAddTeamMembers(value);
              }}
              rightSection={<DownArrowIcon />}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              classNames={{
                input: 'border-primary',
                label: 'text-neutralsGrey text-sm',
              }}
            />
          </div>
          <div className='py-2'>
            <button
              type='button'
              onClick={() => {
                updateGroupmutation.mutate(
                  {
                    group_members: [...newAddTeamMembers],
                    group_members_status: 'add',
                  },
                  {
                    onSuccess: () => {
                      setNewAddTeamMembers([]);
                    },
                  }
                );
              }}
              disabled={!newAddTeamMembers?.length}
              className={`ml-2 pb-0.5 ${
                newAddTeamMembers?.length ? '' : 'opacity-60 cursor-not-allowed'
              }`}
            >
              <PrimaryButton>Add</PrimaryButton>
            </button>
          </div>
        </div>

        <div className='overflow-y-scroll scroller max-h-96'>
          <div className='flex flex-col pt-2'>
            {groupInformation?.data?.group_members?.map((member) => {
              if (!member.is_active) return false;

              return (
                <div
                  key={member?.room_member?.user_id}
                  className='flex justify-between w-full my-2'
                >
                  <div className='flex items-center'>
                    <div className='flex custom-indicator '>
                      <Indicator
                        inline
                        size={16}
                        offset={6}
                        label={StatusLabel(member?.room_member?.status)}
                        position='bottom-end'
                        withBorder
                        radius='xl'
                      >
                        <Avatar
                          src={member?.room_member?.profile_picture || ''}
                          radius='xl'
                          color='red'
                          size={'36px'}
                          classNames={{ root: 'border-2 border-primary' }}
                        >
                          {member?.room_member?.username?.[0] || '-'}
                        </Avatar>
                      </Indicator>
                    </div>
                    <div className='flex flex-col flex-1 justify-around ml-2'>
                      <div className='flex justify-between'>
                        <p className=' leading-4 '>
                          {truncateString(member?.room_member?.username, 40)}
                        </p>
                      </div>
                      <div className='flex mt-1 '>
                        <p className={`text-7A7A7A text-xs`}>
                          {truncateString(member?.room_member?.email, 60)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <Select
                      data={[
                        { value: 'admin', label: 'Admin' },
                        { value: 'member', label: 'Member' },
                      ]}
                      disabled={!groupInformation?.data?.req_user_is_admin}
                      value={member.is_admin ? 'admin' : 'member'}
                      onChange={(value) => {
                        if (!!value) {
                          updateGroupmutation.mutate({
                            group_admin: member?.room_member?.user_id,
                            group_admin_status: `${
                              member.is_admin ? 'remove' : 'add'
                            }`,
                          });
                        }
                      }}
                      clearable
                      rightSection={<DownArrowIcon />}
                      placeholder='By priority'
                      className='w-40'
                      classNames={{
                        rightSection: 'pointer-events-none',
                        wrapper: 'border-primary border-b',
                        input: '  bg-light-bg border-none',
                      }}
                    />
                    <button
                      onClick={() => {
                        if (
                          authState.user_id === member?.room_member?.user_id
                        ) {
                          updateGroupmutation.mutate({
                            group_members: [member?.room_member?.user_id],
                            group_members_status: 'remove',
                            user_left_status: 'remove',
                          });
                        } else {
                          updateGroupmutation.mutate({
                            group_members: [member?.room_member?.user_id],
                            group_members_status: 'remove',
                          });
                        }
                      }}
                      disabled={
                        !groupInformation?.data?.req_user_is_admin &&
                        authState.user_id !== member?.room_member?.user_id
                      }
                      type='button'
                      className={`${
                        !groupInformation?.data?.req_user_is_admin &&
                        authState.user_id !== member?.room_member?.user_id
                          ? 'opacity-60'
                          : ''
                      } ml-3`}
                    >
                      {authState.user_id === member?.room_member?.user_id &&
                      groupInformation?.data?.req_user_is_admin ? (
                        <Tooltip
                          label='Leave Group'
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                          }}
                          radius='md'
                        >
                          <LogoutIcon className='w-5 h-5  ml-2 mr-2' />
                        </Tooltip>
                      ) : groupInformation?.data?.req_user_is_admin ? (
                        <Tooltip
                          label='Remove'
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                          }}
                          radius='md'
                        >
                          <CrossIcon className='w-9 h-9 ' />
                        </Tooltip>
                      ) : authState.user_id === member?.room_member?.user_id ? (
                        <Tooltip
                          label='Leave Group'
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                          }}
                          radius='md'
                        >
                          <LogoutIcon className='w-5 h-5  mr-4' />
                        </Tooltip>
                      ) : (
                        <div className='w-9 h-9 '></div>
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className='flex justify-center items-center mt-8 mb-4'>
          <button
            disabled={!isDirty}
            className={` ${isDirty ? '' : 'opacity-60 cursor-not-allowed'}`}
          >
            <PrimaryButton>Update</PrimaryButton>
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateGroupModal;
