import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useGetAllGrades(id) {
  return useQuery(['settings-salary-grades', id], async () => {
    const response = await makeApiRequest.get(`${apiEndPoints.SETTINGS_GRADES}?req_company_id=${id}`
    );
    return response?.data;
  });
}
