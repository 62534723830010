import { Switch, Table } from '@mantine/core';

// import { ReactComponent as SearchIcon } from '../../../../assets/icons/searchIcon.svg';
import { ReactComponent as RightIcon } from 'assets/icons/rightIconcircle.svg';
// import { ReactComponent as EmptyLeaves } from 'assets/icons/emptyleavesIcon.svg';
// import { ReactComponent as MinusIcon } from 'assets/icons/minusIcon.svg';
import { ReactComponent as EmptyLeavesIlls } from 'assets/images/emptyLeaveIlls.svg';

import PrimaryButton from 'components/primaryButton';
import { useState } from 'react';
import AddAndEditModuleModal from './components/addandeditmodulemodal';

import UseGetLeaveModule from './hooks/usegetleavemodule';
import UseUpdateLeaveModule from './hooks/useupdateleavemodule';

// import Holidays from './components/holidays';

import { Link } from 'react-router-dom';
import dateFormatter from 'helper/dateFormatter';

const CalenderLeaves = () => {
  const [leavemodule, setLeavemodule] = useState(false);
  const [disabled, setdisabled] = useState(true);
  // const [activeTab, setActiveTab] = useState(0);
  // const [searchInput, setSearchInput] = useState('');
  const updateleavemodulemutation = UseUpdateLeaveModule();
  const getModuleData = UseGetLeaveModule(setdisabled);

  const rows = getModuleData?.data?.data.map((eachmodule) => {
    return (
      <tr
        className={
          eachmodule.is_active
            ? 'text-primary text-3xl bg-[#fff]'
            : 'text-[#848383] text-3xl bg-[#fff]'
        }
        key={eachmodule.id}
      >
        <td className='w-80 '>
          <p className='pl-5'>
            {dateFormatter(eachmodule.from_date, 'DD MMM YY')} -{' '}
            {dateFormatter(eachmodule.to_date, 'DD MMM YY')}
          </p>
        </td>
        <td>
          {eachmodule.total_leaves.length === 0 ? (
            <p className='-mt-2 font-bold text-sm'>____</p>
          ) : (
            eachmodule.total_leaves
          )}
        </td>
        <td>
          {eachmodule.leave_types.length === 0 ? (
            <p className='-mt-2 font-bold text-xm'>____</p>
          ) : (
            eachmodule.leave_types.join(', ')
          )}
        </td>

        <td>{dateFormatter(eachmodule.created_date, 'DD MMM YY') ?? '----'}</td>

        <td>
          {' '}
          <div className='flex items-center'>
            <Switch
              checked={eachmodule.is_active}
              disabled={!eachmodule.is_active && !disabled}
              onChange={() => {
                updateleavemodulemutation.mutate({
                  id: eachmodule.id,
                  is_active: !eachmodule.is_active,
                });
                setdisabled(!disabled);
              }}
            />
            {eachmodule.is_active ? (
              <Link
                to={{
                  pathname: `/app/admin/my-calender/leavecalender/`,
                  search: `?id=${eachmodule.id}`,
                }}
                state={{ eachmodule: eachmodule }}
              >
                <RightIcon
                  className='cursor-pointer ml-4'
                  // disabled={eachmodule.is_active || disabled ? false : true}
                  // onClick={() =>
                  //   navigate(
                  //     `/app/my-calender/leavecalender/?id=${eachmodule.id}`,
                  //     {
                  //       replace: true,
                  //     }
                  //   )
                  // }
                />
              </Link>
            ) : (
              <RightIcon className='cursor-not-allowed ml-4 opacity-50' />
            )}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      {getModuleData?.data?.data?.length ? (
        <div>
          <div className=' shadow-2xl min-h-[70vh] bg-[#fff] rounded-2xl'>
            <Table verticalSpacing='lg' highlightOnHover>
              <thead>
                <tr>
                  <th className='bg-[#FFE8E1] rounded-tl-xl '>
                    <p className='pl-5'>Leave Module</p>
                  </th>
                  <th className='bg-[#FFE8E1]'>Total Leaves</th>
                  <th className='bg-[#FFE8E1]'>Leave Types</th>
                  <th className='bg-[#FFE8E1]'>Initiated On</th>
                  <th className='bg-[#FFE8E1] rounded-tr-xl'>Action</th>
                </tr>
              </thead>

              <tbody className=' w-full max-h-20 border-none'>{rows}</tbody>
            </Table>
          </div>
          {/* Search and add leavemodal */}
          <div className='absolute top-2 right-0'>
            <div className='flex justify-between items-center gap-4 '>
              <button type='button' onClick={() => setLeavemodule(true)}>
                <PrimaryButton>+ Add a leave module</PrimaryButton>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className=' bg-white min-h-[400px] px-6 border border-gray border-opacity-10 rounded-lg shadow-lg'>
          <div className='flex flex-col justify-center items-center h-[60vh]'>
            <EmptyLeavesIlls className='w-72' />
            <p
              className='text-lg text-gray -mt-4
            '
            >
              No leave modules to show
            </p>
            <div className='flex justify-between items-center mt-6'>
              <button type='button' onClick={() => setLeavemodule(true)}>
                <PrimaryButton>+ Add a leave module</PrimaryButton>
              </button>
            </div>
          </div>
        </div>
      )}
      <AddAndEditModuleModal
        leavemodule={leavemodule}
        setLeavemodule={setLeavemodule}
      />
    </>
  );
};

export default CalenderLeaves;
