import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import { AuthContext } from 'context/authContext';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useContext } from 'react';

export default function useGetEmployeeEducationalDetails() {
    const { authState } = useContext(AuthContext);
  return useQuery(['get-employee-educational-details'], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_EDUCATION_API, {
        employee_id: authState?.employee_id
      })
    );
    return res.data;
  });
}
