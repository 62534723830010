import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useCompanyDetails() {
  return useQuery('company-details', async () => {
    const response = await makeApiRequest.get(apiEndPoints.COMPANY_DETAILS);
    return response?.data;
  });
}
