import { useQuery } from "react-query";
import makeApiRequest from "services/makeApiRequest";
import apiEndPoints from "services/apiEndPoints";

export default function useGetDesignations(id) {
  return useQuery(["get-employee-designations", id], async () => {
    const response = await makeApiRequest.get(`${apiEndPoints.EMPLOYEE_DESIGNATIONS}?department__company_id=${id}`
    );
    return response?.data;
  });
}
