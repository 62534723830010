import { useMutation, useQueryClient } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { SuccessToast, ErrorToast } from 'services/toasterService';

export default function Useupdatesinglesalarycomponents() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(
        apiEndPoints.COMPONENTSDISTRIBUTIONAPI,
        data
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        // cb();
        queryClient.invalidateQueries('salary_component_data');
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
