import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function UsegetgradesfromSettings() {
  return useQuery(['grades-data-from-settings'], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.SETTINGS_GRADES, {})
    );

    return response.data;
  });
}
