import React, { useState } from 'react';
import PrimaryButton from '../../../components/primaryButton';
import { ReactComponent as EditIcon } from '../../../assets/icons/editIcon.svg';
import { ReactComponent as NoDepartmentsImg } from 'assets/images/NoDepartments.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minusIcon.svg';
// import ProfilePic from '../../../assets/images/profilePic.png';
import { Switch, } from '@mantine/core';
import DepartmentModal from '../components/departmentModal';
import useGetAllDepartments from '../hooks/useGetAllDepartments';
import useEditDepartment from '../hooks/useEditDepartment';
import useGetDepartmentEmployees from '../hooks/useGetDepartmentEmployees';
import DepartmentEmployees from '../components/departmentEmployees';
import ReportingManagerPop from '../components/reportingManagerPop';

export default function Departments() {
  const [isEditDepartmentModalOpen, setIsEditDepartmentModalOpen] =
    useState(false);
  const [addDepartmentModal, setAddDepartmentModal] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState();

  const departmentsData = useGetAllDepartments();
  const editDepartmentMutation = useEditDepartment();

  // Reporting managers and employees associated with particular departments.
  const departmentEmployees = useGetDepartmentEmployees();

  return (
    <div className=' bg-white min-h-[400px] px-6'>
      <table className='w-full'>
        <thead>
          <tr className='text-primary'>
            <th
              className='py-5 bg-FFE8E1 rounded-l-lg'
              style={{ textAlign: 'center' }}
            >
              S.No
            </th>
            <th className='py-5 bg-FFE8E1 pl-8'>Department name</th>
            <th className='py-5 bg-FFE8E1' style={{ textAlign: 'center' }}>
              Reporting managers available
            </th>
            <th className='py-5 bg-FFE8E1' style={{ textAlign: 'center' }}>
              Employees
            </th>
            <th
              className='py-5 bg-FFE8E1 rounded-r-lg pr-8'
              style={{ textAlign: 'center' }}
            >
              Action
            </th>
          </tr>
        </thead>
      </table>

      {departmentsData?.data?.data?.length === 0 ? (
        <div className='bg-white h-96 flex flex-col items-center justify-center space-y-10 rounded-3xl'>
          <div>
            <NoDepartmentsImg />
            <p className='text-center mt-2'>No data to show</p>
          </div>
          <button onClick={() => setAddDepartmentModal(true)}>
            <PrimaryButton>
              <p>+ Add department</p>
            </PrimaryButton>
          </button>
        </div>
      ) : (
        <table className='w-full bg-white departments-two'>
          <tbody>
            {departmentsData?.data?.data?.map((department, index) => {
              return (
                <tr key={department?.id}>
                  <td className='py-5 text-center'>
                    {index < 9 && '0'}
                    {index + 1}
                  </td>
                  <td className='py-5 pl-9'>{department?.name}</td>
                  {departmentEmployees?.data?.data?.map(
                    // eslint-disable-next-line
                    (employeeDepartment) => {
                      if (employeeDepartment?.name === department?.name) {
                        if (
                          employeeDepartment?.reporting_managers?.length === 0
                        ) {
                          return (
                            <React.Fragment key={employeeDepartment?.id}>
                              <td>
                                <p className='flex justify-center items-center'>
                                  <MinusIcon />
                                </p>
                              </td>
                              <td>
                                <div className='flex justify-center items-center'>
                                  {employeeDepartment?.employees?.length ===
                                  0 ? (
                                    <MinusIcon />
                                  ) : (
                                    <DepartmentEmployees
                                      employeeDepartment={employeeDepartment}
                                    />
                                  )}
                                </div>
                              </td>
                            </React.Fragment>
                          );
                        }
                        return (
                          <React.Fragment key={employeeDepartment?.id}>
                            <td>
                              
                              <ReportingManagerPop employeeDepartment={employeeDepartment} />
                            </td>

                            <td className='py-5'>
                              <DepartmentEmployees
                                employeeDepartment={employeeDepartment}
                              />
                            </td>
                          </React.Fragment>
                        );
                      }
                    }
                  )}
                  {/* <td className='py-5'>
                    <div className='flex items-center justify-start'>
                      <div className='flex cursor-pointer'>
                        <Avatar
                          radius='xl'
                          className='w-10 h-10 bg-orange-400'
                          color='orange'
                        />
                        <Avatar
                          radius='xl'
                          className='w-10 h-10 rounded-full transform -translate-x-4'
                          color='orange'
                        />
                        <Avatar
                          radius='xl'
                          className='w-10 h-10 rounded-full transform -translate-x-8'
                          color='orange'
                        />
                        <Avatar
                          radius='xl'
                          className='w-10 h-10 rounded-full transform -translate-x-12'
                          color='orange'
                        >
                          50
                        </Avatar>
                      </div>
                      <p className='cursor-pointer text-67A1EF'>see all</p>
                    </div>
                  </td> */}
                  <td className='py-5'>
                    <div className='flex items-center space-x-4'>
                      <EditIcon
                        className='cursor-pointer'
                        onClick={() => {
                          setSelectedDepartment(department);
                          setIsEditDepartmentModalOpen(true);
                        }}
                      />
                      <Switch
                        defaultChecked={department?.is_active}
                        onChange={() => {
                          editDepartmentMutation.mutate({
                            id: department?.id,
                            is_active: !department?.is_active,
                          });
                        }}
                        classNames={{
                          input: 'cursor-pointer',
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {/* Modal for adding departments when there are none!! */}
      <DepartmentModal
        isModalOpen={addDepartmentModal}
        setIsModalOpen={setAddDepartmentModal}
        modalHeader={'Add a department'}
      />

      {/* Modal for editing departments */}
      <DepartmentModal
        isModalOpen={isEditDepartmentModalOpen}
        setIsModalOpen={setIsEditDepartmentModalOpen}
        modalHeader={'Edit department'}
        departmentDetails={selectedDepartment}
      />
    </div>
  );
}
