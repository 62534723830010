import forgotPasswordImage from '../../../assets/images/forgotPasswordImage.png';
import { ReactComponent as AptaSmallIcon } from '../../../assets/icons/aptaSmallIcon.svg';
import { useForm } from 'react-hook-form';

import { TextInput } from '@mantine/core';

import UseSendOtp from '../hooks/usesendotp';

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const sendOtpMutation = UseSendOtp(watch('email_or_number'));

  const submitEmail = (data) => {
    // console.log(data);
    sendOtpMutation.mutate({ ...data, resend: false });
  };

  return (
    <div
      style={{
        backgroundImage: 'URL(' + forgotPasswordImage + ')',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat, repeat',
        backgroundPosition: '90% 90%',
      }}
      className='h-screen bg-center  bg-no-repeat w-[100%] bg-cover '
    >
      <div className='bg-[#FFF] w-full py-[18px] px-[50px] shadow-lg'>
        <AptaSmallIcon className='w-24 h-12' />
      </div>

      <div className='flex flex-col justify-center items-center h-[85%]'>
        <h1 className='text-primary font-bold text-3xl'>Forgot Password?</h1>
        <p className='text-[#636363] font-normal text-lg mb-10'>
          To reset your password, please enter your registered email
        </p>
        <form onSubmit={handleSubmit(submitEmail)}>
          <div className='flex flex-col justify-center'>
            <TextInput
              {...register('email_or_number', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter valid email',
                },
              })}
              label='Enter Email ID'
              className='mb-1 w-80'
              size='lg'
              classNames={{
                input:
                  ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5 -mt-3 ',
                label:
                  'text-[#8E8E8E] text-sm font-normal ml-2 bg-[#FDFCFC] px-2 rounded z-10 relative',
              }}
            />
            {errors.email_or_number && (
              <p className='text-sm  text-[#fc0101]'>
                {errors.email_or_number.message}
              </p>
            )}

            <button className='h-10 w-36 mx-auto bg-[#FB7C51] rounded-lg mt-12 text-[#FFFFFF] font-normal text-lg hover:bg-[#e2643a] hover:shadow-lg'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
