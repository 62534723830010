import { Select, Table } from '@mantine/core';
import React, { useState } from 'react';

import useGetMySkillsInAdmin from '../hooks/useGetemployeeSkills';
import useGetSkillDevelopmentInAdmin from '../hooks/useGetEmployeeSkillDevelopmentData';
import useUpdateSkillDevelopmentInTeams from '../hooks/useUpdateSkillDevelopment';
import useDeleteSkillDevelopmentInTeams from '../hooks/useDeleteSkillDevelopment';
import dayjs from 'dayjs';

import { ReactComponent as NoSkill } from 'assets/icons/noSkills.svg';
import { ReactComponent as NoSkillDevelopment } from 'assets/icons/noSkillDevelopment.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/orangeDownArrowIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';

import PrimaryButton from 'components/primaryButton';
import NewCourseModal from './newCourseModal';
import SkillDevelopmentCertificateModal from 'pages/dashboard/pages/employeeSkillMatrix/components/skillDevelopmentCertificateModal';
import PreviewForPDF from 'pages/dashboard/pages/employeeSkillMatrix/components/previewForPDF';
import PreviewImage from 'pages/dashboard/pages/employeeSkillMatrix/components/PreviewImage';

export default function SkillMatrix() {
  const getSkillsData = useGetMySkillsInAdmin();
  const getSkillDevelopmentData = useGetSkillDevelopmentInAdmin();
  const updateSkillDevelopment = useUpdateSkillDevelopmentInTeams();
  const deleteSkillDevelopment = useDeleteSkillDevelopmentInTeams();

  const [addNewCourseModal, setAddNewCourseModal] = useState(false);
  const [UploadCertificateModal, setUploadCertificateModal] = useState(false);
  const [skillDevelopmentId, setSkillDevelopmentId] = useState(null);
  const [previewPdfModal, setPreviewPdfModal] = useState(false);
  const [previewImageModal, setPreviewImageModal] = useState(false);
  const [file, setFile] = useState(null);
  const [courseName, setCourseName] = useState(null);
  return (
    <>
      <div>
        <p className='text-xl text-222222 font-semibold pb-2'>Skills</p>
        {getSkillsData?.data?.data.length === 0 ? (
          <div className='flex justify-center flex-col items-center shadow-four rounded-xl p-2'>
            <NoSkill className='h-44 w-44' />
            <h1 className='text-primary mt-1 font-bold -mr-7'>
              No Skills to show
            </h1>
          </div>
        ) : (
          <div className='max-h-28 flex flex-wrap overflow-y-scroll scroller gap-4'>
            {getSkillsData?.data?.data?.map((eachSkill) => {
              return (
                <>
                  {eachSkill?.from_resume_parser ? (
                    <p className='bg-[#FFEFEA] py-1 px-2 rounded-lg flex items-center gap-3 text-lg'>
                      {eachSkill?.name}{' '}
                    </p>
                  ) : (
                    <p className='bg-[#F2F2F2] py-1 px-2 rounded-lg flex items-center gap-3 text-lg'>
                      {eachSkill?.name}{' '}
                    </p>
                  )}
                </>
              );
            })}
          </div>
        )}
        {/* <p className='text-xl text-222222 font-semibold pb-2 mt-4'>
        Skill targets
      </p> */}
        {/* <div className='max-h-80 overflow-y-scroll scroller'>
        <table className='w-full'>
          <tbody>
            <tr className='bg-FFE8E1 text-gray sticky top-0 text-lg'>
              <td className='p-4'>Skill</td>
              <td className='pl-32'>Start & end date</td>
              <td>Duration</td>
              <td>Progress</td>
            </tr>
            <tr className='text-787878 text-lg'>
              <td className='p-4'>Editing</td>
              <td className='pl-32'>22 May 22 - 22 May 22</td>
              <td>6 months</td>
              <td className='text-warning'>Inprogress</td>
            </tr>
            <tr className='text-787878 text-lg'>
              <td className='p-4'>Editing</td>
              <td className='pl-32'>22 May 22 - 22 May 22</td>
              <td>6 months</td>
              <td className='text-warning'>Inprogress</td>
            </tr>
            <tr className='text-787878 text-lg'>
              <td className='p-4'>Editing</td>
              <td className='pl-32'>22 May 22 - 22 May 22</td>
              <td className='pr-12'>6 months</td>
              <td className='text-warning'>Inprogress</td>
            </tr>
          </tbody>
        </table>
      </div> */}
        <div className='flex justify-between items-center mt-3'>
          <p className='text-xl text-222222 font-semibold pb-2 mt-4'>
            Skill development
          </p>
          <button className='my-2' onClick={() => setAddNewCourseModal(true)}>
            <PrimaryButton>+ Add New</PrimaryButton>
          </button>
        </div>
        {getSkillDevelopmentData?.data?.data.length === 0 ? (
          <div className='flex justify-center items-center flex-col shadow-four rounded-xl p-4'>
            <NoSkillDevelopment className='h-44 w-44' />
            <p className='mt-2 text-primary font-semibold text-lg'>
              No content found
            </p>
          </div>
        ) : (
          <div className='max-h-80  py-4'>
            <Table highlightOnHover verticalSpacing={'lg'}>
              <thead>
                <tr>
                  <th
                    className='bg-[#FFE8E1] py-4 rounded-tl-xl'
                    style={{ textAlign: 'center' }}
                  >
                    Course title
                  </th>
                  <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                    Duration
                  </th>
                  <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                    Date of commencement
                  </th>
                  <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                    Source
                  </th>
                  <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                    Status
                  </th>
                  <th
                    className='bg-[#FFE8E1] rounded-tr-xl'
                    style={{ textAlign: 'center' }}
                  >
                    Certificate
                  </th>
                </tr>
              </thead>
              <tbody>
                {getSkillDevelopmentData?.data?.data?.map((each) => (
                  <tr>
                    <td className='text-center text-primary'>{each?.title}</td>
                    <td className='text-center'>
                      {dayjs(each?.from_date).diff(each?.to_date, 'months') ===
                      0
                        ? `${dayjs(each?.to_date).diff(
                            each?.from_date,
                            'days'
                          )} days`
                        : `${dayjs(each?.to_date).diff(
                            each?.from_date,
                            'months'
                          )} months`}
                    </td>
                    <td className='text-center'>
                      {' '}
                      {dayjs(each?.to_date).format('DD MMM YYYY')}
                    </td>
                    <td className='text-center'>{each?.source}</td>
                    <td className='flex justify-center items-center gap-5'>
                      <Select
                        name='status'
                        defaultValue={each?.status}
                        data={[
                          { value: 'Ongoing', label: 'Ongoing' },
                          { value: 'Completed', label: 'Completed' },
                          {
                            value: 'Not yet started',
                            label: 'Not yet started',
                          },
                        ]}
                        //   value={selectEvent}
                        onChange={(event) =>
                          updateSkillDevelopment.mutate({
                            status: event,
                            id: each?.id,
                            employee_id: each?.employee_id,
                          })
                        }
                        rightSection={<DownArrowIcon />}
                        className='w-44'
                        classNames={{
                          rightSection: 'pointer-events-none',
                          wrapper: 'border-primary border-b',
                          input:
                            each?.status === 'Not yet started'
                              ? 'bg-inherit text-[#B1B1B1] text-bold border-none text-base'
                              : each?.status === 'Ongoing'
                              ? 'bg-inherit text-[#FBBB00] text-bold border-none text-base'
                              : 'bg-inherit text-[#28B446] text-bold border-none text-base',
                        }}
                      />
                      <DeleteIcon
                        className='self-end -mr-20 cursor-pointer'
                        onClick={() => {
                          deleteSkillDevelopment.mutate({
                            id: each?.id,
                            employee_id: each?.employee_id,
                          });
                        }}
                      />
                    </td>
                    <td
                      className={
                        each?.certificate === null
                          ? 'text-center cursor-pointer text-[#67A1EF]'
                          : 'text-center cursor-pointer text-[#FC8C67]'
                      }
                    >
                      {each?.certificate === null ? (
                        <p
                          onClick={() => {
                            setUploadCertificateModal(true);
                            setSkillDevelopmentId(each?.id);
                          }}
                        >
                          Add
                        </p>
                      ) : (
                        <p
                          onClick={() => {
                            const extension = each?.certificate
                              ?.split('.')
                              .pop();
                            if (extension === 'pdf') {
                              setPreviewPdfModal(true);
                              setFile(each?.certificate);
                              setCourseName(each?.title);
                            } else {
                              setPreviewImageModal(true);
                              setFile(each?.certificate);
                            }
                          }}
                        >
                          View
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <NewCourseModal
        addNewCourseModal={addNewCourseModal}
        setAddNewCourseModal={setAddNewCourseModal}
      />
      <SkillDevelopmentCertificateModal
        UploadCertificateModal={UploadCertificateModal}
        setUploadCertificateModal={setUploadCertificateModal}
        skillDevelopmentId={skillDevelopmentId}
        setSkillDevelopmentId={setSkillDevelopmentId}
      />
      <PreviewForPDF
        previewPdfModal={previewPdfModal}
        setPreviewPdfModal={setPreviewPdfModal}
        file={file}
        setFile={setFile}
        name={courseName}
        setCourseName={setCourseName}
      />
      <PreviewImage
        previewImageModal={previewImageModal}
        setPreviewImageModal={setPreviewImageModal}
        file={file}
        setFile={setFile}
      />
    </>
  );
}
