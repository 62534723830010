import { Loader } from '@mantine/core';
import React from 'react';

export default function PrimaryButton({ children, isLoading, disabled }) {
  if (isLoading) {
    return (
      <div className='bg-primary text-white rounded-md h-10 py-2 cursor-not-allowed px-4 font-semibold flex items-center justify-center'>
        <Loader color={'white'} size={'sm'} />
      </div>
    );
  }
  return (
    <div
      className={`bg-primary text-white rounded-md py-2 px-4 ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } font-medium flex items-center justify-center hover:bg-[#e4643a] `}
    >
      {children}
    </div>
  );
}
