import React from 'react';

import { ReactComponent as AttendanceImg } from 'assets/images/attendance.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/checkedIcon.svg';

import { ReactComponent as ClockIcon } from 'assets/icons/clockIcon.svg';

import { ReactComponent as AttachmentIcon } from 'assets/icons/attachmentIcon.svg';
import { ReactComponent as GrayClock } from 'assets/icons/grayClockIcon.svg';

import useGetTodaysTasks from '../hooks/useGetTodayTasks';
import { Avatar, Popover, Progress } from '@mantine/core';
import { useState } from 'react';
import dayjs from 'dayjs';

const currentDate = dayjs().$d;

export default function TodaysTask() {
  const tasksData = useGetTodaysTasks();

  const [teamMemberPopoverOpened, setTeamMemberPopoverOpened] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    <div className='bg-white shadow-four rounded-[10px] h-[720px] mt-6 py-5 px-9 overflow-y-auto scroller'>
      <p className='text-18 font-semibold text-222222'>Today’s Tasks</p>
      {tasksData?.data?.data?.length === 0 ? (
        <div className='mt-20'>
          <AttendanceImg className='mx-auto' />
          <p className='text-gray3 mt-3 text-center'>No task's to show</p>
        </div>
      ) : (
        <div className='mt-5'>
          {tasksData?.data?.data?.map((eachtask) => (
            <div key={eachtask.id}>
              <h1 className='text-[#000] opacity-80 font-semibold text-lg'>
                {eachtask?.name}
              </h1>
              <p className='text-[#3F3C3C] py-2 text-sm'>{eachtask?.notes}</p>

              <div className='flex relative'>
                {eachtask?.members?.length === 0 ? null : (
                  <Avatar
                    onClick={() => {
                      setSelectedRow(eachtask);
                      setTeamMemberPopoverOpened(true);
                    }}
                    src={eachtask?.members[0]?.employee__profile_picture}
                    color='#EEF3F1'
                    radius='xl'
                    classNames={{
                      placeholder: 'text-xl',
                    }}
                    className='w-8 h-9 border-2 border-primary border-opacity-50'
                  >
                    {eachtask?.members[0]?.employee__user__first_name?.substring(
                      0,
                      1
                    )}
                  </Avatar>
                )}
                {eachtask?.members?.length > 1 ? (
                  <Avatar
                    onClick={() => {
                      setSelectedRow(eachtask);
                      setTeamMemberPopoverOpened(true);
                    }}
                    src={eachtask?.members[1]?.employee__profile_picture}
                    color='#EEF3F1'
                    radius='xl'
                    classNames={{
                      placeholder: 'text-xl',
                    }}
                    className='w-8 h-9 transform -translate-x-4 border-2 border-primary border-opacity-50'
                  >
                    {eachtask?.members[1]?.employee__user__first_name?.substring(
                      0,
                      1
                    )}
                  </Avatar>
                ) : null}
                {eachtask?.members?.length > 2 ? (
                  <Avatar
                    onClick={() => {
                      setSelectedRow(eachtask);
                      setTeamMemberPopoverOpened(true);
                    }}
                    src={eachtask?.members[2]?.employee__profile_picture}
                    color='#EEF3F1'
                    radius='xl'
                    classNames={{
                      placeholder: 'text-xl',
                    }}
                    className='w-8 h-9 transform -translate-x-8 border-2 border-primary border-opacity-50'
                  >
                    {eachtask?.members[2]?.employee__user__first_name?.substring(
                      0,
                      1
                    )}
                  </Avatar>
                ) : null}
                {eachtask?.members?.length > 3 ? (
                  <span
                    className='w-8 h-9 flex justify-center items-center rounded-full bg-primary transform -translate-x-12 text-white select-none border-2 border-primary border-opacity-50'
                    onClick={() => {
                      setSelectedRow(eachtask);
                      setTeamMemberPopoverOpened(true);
                    }}
                  >
                    +{eachtask?.members?.length - 3}
                  </span>
                ) : null}
                {eachtask === selectedRow ? (
                  <Popover
                    opened={teamMemberPopoverOpened}
                    onClose={() => setTeamMemberPopoverOpened(false)}
                    width={220}
                    title='Team Members'
                    position='bottom'
                    placement='center'
                    radius='sm'
                    classNames={{
                      inner: 'py-2 max-h-48 overflow-y-scroll scroller',
                      header: 'border-b-0 text-accent font-medium',
                    }}
                    className='z-0'
                  >
                    {eachtask?.members?.map((member) => {
                      return (
                        <div
                          className='flex space-x-2 pb-4'
                          key={member?.employee__id}
                        >
                          <Avatar
                            src={member?.employee__profile_picture}
                            color='#EEF3F1'
                            radius='xl'
                            classNames={{
                              placeholder: 'text-xl',
                            }}
                            className='w-10 h-10'
                          >
                            {member?.employee__user__first_name?.substring(
                              0,
                              1
                            )}
                          </Avatar>
                          <div className='text-xs mt-1'>
                            <p className='text-121212'>
                              {member?.employee__user__first_name}
                            </p>
                            <p className='text-868686'>
                              {member?.employee__designation__name}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </Popover>
                ) : null}
              </div>
              <Progress
                color={
                  eachtask?.progress === 'Not started'
                    ? '#D9E1E7'
                    : eachtask?.progress === 'Completed'
                    ? '#28B446'
                    : '#F8CA54'
                }
                value={
                  eachtask?.progress === 'Not started'
                    ? 0
                    : eachtask?.progress === 'Completed'
                    ? 100
                    : 50
                }
                className='my-2'
              />
              <div className='flex justify-end items-center gap-4'>
                <p className='gap-2 flex items-center text-[#809FB8]'>
                  <AttachmentIcon />
                  {eachtask?.total_attachments}
                </p>
                <p className='gap-2 flex items-center text-[#809FB8]'>
                  <CheckedIcon />
                  {`${eachtask?.total_checklist_count}/${eachtask?.members?.length}`}
                </p>

                {eachtask?.progress === 'Completed' ? (
                  <p className='gap-2 flex items-center text-primary bg-[#FFE5DC] px-3 py-1 rounded-xl text-sm'>
                    <ClockIcon /> Done
                  </p>
                ) : (
                  <p className='gap-2 flex items-center text-[#809FB8] bg-[#ddeaf5] px-3 py-1 rounded-xl text-sm'>
                    <GrayClock />
                    {dayjs(eachtask?.end_date).diff(currentDate, 'days') > 0
                      ? dayjs(eachtask?.end_date).diff(currentDate, 'days')
                      : 0}{' '}
                    days left
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
