import React, { useState } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import useCalendarEvents from '../hooks/useCalendarEvents';
import EventComponent from './calendarEvents';
import Toolbar from './toolBar';
import EventModal from './eventModal';
import DeleteEventModal from './deleteEventModal';
import dayjs from 'dayjs';

export default function CalendarWrapper() {
  const allCalendarEvents = useCalendarEvents();

  const [isCalenderEventModalOpen, setIsCalenderEventModalOpen] =
    useState(false);
    const [selectedEventId, setSelectedEventId] = useState();
  const [openDeleteEventModal, setOpenDeleteEventModal] = useState(false);
  
  const [selectEventToDelete, setSelectEventToDelete] = useState();

  const addTimeToDate = (date, time) => {
    if (!time) {
      return new Date(`${date} 00:00:00`);
    } else {
      const combinedDay = new Date(`${date} ${time}`);
      return combinedDay;
    }
  };

  const events = allCalendarEvents?.data?.data?.map((event) => {
    return {
      id: event?.id,
      start: event?.is_birthday
        ? dayjs(new Date(event?.for_date)).add(
            new Date().getFullYear() - new Date(event?.for_date).getFullYear(),
            'years'
          ).$d
        : new Date(
            `${event?.for_date}T${dayjs('2019-01-25' + event?.from_time).format(
              'HH:mm:ss.sss'
            )}Z`
          ),

      // Here we are checking if its birthday or not
      // If it is Birthday, then we will replace the year with current year
      // 28-08-1998  -> 28-08-2022, so that its visible on the calendar. If not, its not visible.
      // If it is not a Birthday, then we are checking if end date and start date are same.
      // If they are same, then the end date will be combination of end date date and end date time.
      // If they are not same, then we are assuming its more than one day event.
      end: event?.is_birthday
        ? dayjs(new Date(event?.for_date)).add(
            new Date().getFullYear() - new Date(event?.for_date).getFullYear(),
            'years'
          ).$d
        : event?.for_date === event?.to_date
        ? addTimeToDate(event?.to_date, event?.to_time)
        : new Date(event?.to_date),
      title: event?.is_birthday
        ? event?.birthday_employee__user__first_name
        : event?.name,
      allDay: event?.all_day_event || event?.is_holiday,
      holidayType: event?.holiday_type_id,
      from_time: event?.from_time,
      to_time: event?.to_time,
      isHoliday: event?.is_holiday,
      isBirthday: event?.is_birthday,
      createdBy: event?.created_by_id,
    };
  });

  const localizer = momentLocalizer(moment);

  return (
    <div className='my-10'>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        components={{
          eventWrapper: (props) => (
            <EventComponent
              {...props}
              setSelectedEventId={setSelectedEventId}
              setSelectEventToDelete={setSelectEventToDelete}
              setIsCalenderEventModalOpen={setIsCalenderEventModalOpen}
              setOpenDeleteEventModal={setOpenDeleteEventModal}
            />
          ),
          toolbar: Toolbar,
        }}
        views={['month', 'agenda', 'day']}
        style={{ height: 900 }}
      />

      <EventModal
        openModal={isCalenderEventModalOpen}
        setOpenModal={setIsCalenderEventModalOpen}
        selectedEvent={selectedEventId}
        modalTitle={'Edit event'}
      />

      <DeleteEventModal
        openModal={openDeleteEventModal}
        setOpenModal={setOpenDeleteEventModal}
        selectEventToDelete={selectEventToDelete}
      />
    </div>
  );
}
