import { Avatar, Table, Tooltip } from '@mantine/core';

import { ReactComponent as EyeIcon } from 'assets/icons/eyeIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/downloadIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as NoPerformanceReports } from 'assets/icons/noPerfromanceReports.svg';

import useGetApparisal from '../hooks/useGetapparisal';
import dayjs from 'dayjs';
import ViewApparisalModal from './viewapparisalreport';
import { useState } from 'react';
import ApparisalModal from './apparisalmodal';

const AppraisalReports = ({
  apparisalSearch,
  apparisalDraftFilter,
  apparisalYearFilter,
}) => {
  const [ViewApparisalModalOpened, setViewApparisalModalOpened] =
    useState(false);
  const [apparisalDataEmployee, setApparisalDataEmployee] = useState(null);
  const [addApparisalData, setAddApparisalData] = useState(false);
  const apparisalData = useGetApparisal(
    null,
    apparisalSearch,
    apparisalDraftFilter,
    apparisalYearFilter
  );

  const rows = apparisalData?.data?.data.map((eachApparisal) => (
    <>
      <tr>
        <td>
          <div className='flex justify-center items-center'>
            <Avatar
              radius='xl'
              color='#FB7C51'
              src={eachApparisal?.employee__profile_picture}
            >
              {eachApparisal?.employee__user__first_name.substring(0, 1)}
            </Avatar>
            <div className='pl-3 text-[#848383] '>
              {eachApparisal?.employee__user__first_name.length > 30 ? (
                <Tooltip
                  label={eachApparisal?.employee__user__first_name}
                  classNames={{
                    body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                  }}
                  radius='md'
                >
                  <p className='text-base  w-52 truncate'>
                    {eachApparisal?.employee__user__first_name}
                  </p>
                </Tooltip>
              ) : (
                <p className='text-base w-52'>
                  {eachApparisal?.employee__user__first_name}
                </p>
              )}
              <p className='text-normal'>
                {eachApparisal?.employee__designation__name}
              </p>
            </div>
          </div>
        </td>

        <td className=''>
          <div className='text-[#848383] self-start text-base '>
            {eachApparisal?.employee__user__email.length > 29 ? (
              <Tooltip
                label={eachApparisal?.employee__user__email}
                classNames={{
                  body: 'bg-white text-gray2 shadow-tooltip',
                }}
                radius='md'
              >
                <p className='self-start mr-5 w-52 truncate'>
                  {eachApparisal?.employee__user__email}
                </p>
              </Tooltip>
            ) : (
              <p className='self-start mr-5 w-52 '>
                {eachApparisal?.employee__user__email}
              </p>
            )}
            <p>{eachApparisal?.employee__user__mobile_number}</p>
          </div>
        </td>
        <td>
          <div className='text-center text-[#848383] text-base'>
            {eachApparisal?.employee__employee_id}
          </div>
        </td>
        <td>
          <div className='text-center text-[#848383] text-lg'>
            {eachApparisal?.employee__department__name}
          </div>
        </td>
        <td>
          <div className='text-center text-[#848383] text-base '>
            {eachApparisal?.review_type__name}
            {/* <p>{`${dayjs(eachApparisal?.segment_from).format("YYYY")}`}</p> */}
          </div>
        </td>
        <td>
          <div className='text-center text-[#848383] text-base'>
            {`${dayjs(eachApparisal?.segment_from).format('DD MMM YYYY')}`}
            <br />
            {`${dayjs(eachApparisal?.segment_to).format('DD MMM YYYY')}`}
          </div>
        </td>
        <td>
          <div className='text-center text-[#848383] text-base'>
            {eachApparisal?.hike_percentage}%
          </div>
        </td>
        <td>
          <div className='flex items-center justify-center gap-2'>
            <EyeIcon
              className='cursor-pointer'
              onClick={() => {
                setViewApparisalModalOpened(true);
                setApparisalDataEmployee(eachApparisal);
              }}
            />
            {eachApparisal?.is_draft ? (
              <EditIcon
                className='cursor-pointer'
                onClick={() => {
                  setApparisalDataEmployee(eachApparisal);
                  setAddApparisalData(true);
                }}
              />
            ) : null}
            {/* <EditIcon className='cursor-pointer' />{' '} */}
            <DownloadIcon className='cursor-pointer opacity-50' />
          </div>
        </td>
      </tr>
    </>
  ));
  return (
    <>
      <div className='mt-5 bg-[#ffffff] min-h-[80vh] rounded-2xl shadow-3xl'>
        {apparisalData?.data?.data.length === 0 ? (
          <div className='flex justify-center items-center flex-col bg-[#ffffff] min-h-[70vh] rounded-2xl shadow-3xl'>
            <NoPerformanceReports />
            <p className='mt-2 text-[#535353]'>
              Seems like you have no appraisal reports yet!
            </p>
          </div>
        ) : (
          <div className='shadow-lg border border-8B8B8B border-opacity-10  rounded-xl bg-white my-4 h-[73vh] 2xl:h-[73vh] overflow-y-scroll scroller'>
            <Table verticalSpacing='md' highlightOnHover>
              <thead>
                <tr className='sticky top-0 z-20'>
                  <th
                    className='bg-[#FFE8E1] rounded-tl-xl '
                    style={{ textAlign: 'center' }}
                  >
                    Name & designation
                  </th>
                  <th className='bg-[#FFE8E1]'>Contact details</th>
                  <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                    Employee ID
                  </th>
                  <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                    Dept.
                  </th>
                  <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                    Appraisal type
                  </th>
                  <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                    Initiated and
                    <br /> scheduled date
                  </th>
                  <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                    Allocated hike <br /> percentage
                  </th>
                  <th
                    className='bg-[#FFE8E1] rounded-tr-xl'
                    style={{ textAlign: 'center' }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className=' w-full max-h-20 border-none'>{rows}</tbody>
            </Table>
          </div>
        )}
      </div>
      {apparisalData?.data?.data.length === 0 ? null : (
        <>
          <ViewApparisalModal
            setViewApparisalModalOpened={setViewApparisalModalOpened}
            ViewApparisalModalOpened={ViewApparisalModalOpened}
            apparisalDataEmployee={apparisalDataEmployee}
          />
          <ApparisalModal
            apparisalDataEmployee={apparisalDataEmployee}
            addApparisalData={addApparisalData}
            setAddApparisalData={setAddApparisalData}
          />
        </>
      )}
    </>
  );
};

export default AppraisalReports;
