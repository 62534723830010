import React from 'react';
import { Avatar, Tooltip } from '@mantine/core';

import { ReactComponent as EyeIcon } from 'assets/icons/eyeIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon2.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/downloadIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { useState } from 'react';
import PoliciesModal from './components/newpoliciesmodal';
import useGetpolicies from './hooks/usegetpolicyies';
// import DepartmentModal from './departmentModal';
import dayjs from 'dayjs';
import DeletePolicy from 'pages/organisation/pages/keyPolicies/components/deletepolicymodal';
import ViewPolicyModal from 'pages/organisation/pages/keyPolicies/components/viewpolicymodal';
import { saveAs } from 'file-saver';
// import { AuthContext } from 'context/authContext';

function Policies({ addPolicyModal, setAddPolicyModal, company_id }) {
  const policiesData = useGetpolicies(company_id);
  // const { authState } = useContext(AuthContext);

  // const [isPoliciesModalOpened, setIsPoliciesModalOpened] = useState(false);
  const [policyId, setPolicyId] = useState(null);
  const [deletepolicymodal, setdeletepolicymodal] = useState(false);
  const [viewPolicyModal, setViewPolicyModal] = useState(false);
  const [policyDocument, setPolicyDocument] = useState(null);

  const trimString = function (string, length) {
    return string.length > length
      ? string.substring(0, length) + '...'
      : string;
  };

  const convertToString = (array, value = 300) => {
    if (array.length) {
      return trimString(array.map((element) => element.name).toString(), value);
    } else {
      return '-';
    }
  };

  return (
    <div className='shadow-xl border border-8B8B8B border-opacity-20 rounded-xl overflow-hidden mb-8 bg-white min-h-[70vh] departments-super-admin'>
      {policiesData?.data?.data?.length === 0 ? (
        <div className='bg-white h-96 flex flex-col items-center justify-center space-y-10 rounded-3xl'>
          <div>
            <p className='text-center mt-2'>No data to show</p>
          </div>
        </div>
      ) : (
        policiesData?.data?.data?.map((organisation, index) => {
          return (
            <div className=' bg-white'>
              <table className='w-full px-2'>
                <thead className='bg-FFE8E1'>
                  <tr className='text-333333  rounded-t-lg px-6 flex'>
                    <th className='py-5 w-4/12  text-left'>Policy name</th>
                    <th className='py-5 w-4/12  text-left'>
                      <div className='flex flex-col'>
                        <p className=''>Applicable for</p>
                        {/* <div className='flex'>
                            <div className='w-1/2 text-center'>
                              Types
                            </div>
                            <div className='w-1/2 text-center'>
                              Grades
                            </div>
                          </div> */}
                      </div>
                    </th>
                    <th className='py-5 w-2/12  text-left'>Uploaded on</th>
                    <th className='py-5 w-2/12  '>Created by</th>
                    <th className='py-5 w-2/12 '>Action</th>
                  </tr>
                </thead>
              </table>

              {organisation?.policies?.length === 0 ? (
                <div className=' h-96 flex flex-col items-center justify-center space-y-10 rounded-3xl'>
                  <div>
                    <p className='text-center mt-2'>No data to show</p>
                  </div>
                </div>
              ) : (
                <table className='w-full  departments-two'>
                  <tbody>
                    {organisation?.policies?.map((policy, index, array) => {
                      return (
                        <tr
                          key={policy?.id}
                          className={`flex px-6 text-868686 ${
                            array.length !== index + 1
                              ? 'border-b border-[#CECECE] border-opacity-50'
                              : ''
                          }`}
                        >
                          <td className='py-5 w-4/12  text-left'>
                            {policy?.name}
                          </td>
                          <td className='py-5 w-4/12  text-left'>
                            {policy?.is_applicable_for_all_emp ? (
                              'All'
                            ) : policy?.applicable_for_emp?.length ? (
                              <Tooltip
                                label={convertToString(
                                  policy?.applicable_for_emp
                                )}
                                classNames={{
                                  body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                                }}
                                radius='md'
                              >
                                {' '}
                                <span>
                                  {convertToString(
                                    policy?.applicable_for_emp,
                                    50
                                  )}
                                </span>{' '}
                              </Tooltip>
                            ) : (
                              '-'
                            )}
                            ,(Grades -
                            {policy?.is_applicable_for_all_grade ? (
                              'All'
                            ) : policy?.applicable_for_grade?.length ? (
                              <Tooltip
                                label={convertToString(
                                  policy?.applicable_for_grade
                                )}
                                classNames={{
                                  body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                                }}
                                radius='md'
                              >
                                {' '}
                                <span>
                                  {convertToString(
                                    policy?.applicable_for_grade,
                                    50
                                  )}
                                </span>{' '}
                              </Tooltip>
                            ) : (
                              '-'
                            )}
                            )
                          </td>
                          {/* <td className='py-5 flex items-center justify-center w-2/12'>
                             
                            </td> */}
                          <td className='py-5 w-2/12  text-left'>
                            {dayjs(policy?.created_date).format('DD MMM YYYY')}
                          </td>
                          <td className='py-5 flex items-center justify-center w-2/12'>
                            By{' '}
                            <Avatar
                              src={''}
                              size={32}
                              color='red'
                              radius={50}
                              className='capitalize ml-2'
                            >
                              A
                            </Avatar>
                          </td>
                          <td className='py-5 flex items-center justify-center w-2/12 '>
                            <div className='flex items-center justify-center space-x-4'>
                              <EyeIcon
                                onClick={() => {
                                  // if (policy?.policy_document) {
                                  setViewPolicyModal(true);
                                  setPolicyDocument(policy?.policy_document);
                                  // }
                                }}
                                className={`cursor-pointer`}
                              />
                              {/* {policy?.is_default ? (
                                
                              ) : (
                                <EditIcon
                                  className={`opacity-40 cursor-not-allowed`}
                                />
                              )} */}
                              <EditIcon
                                className='cursor-pointer'
                                onClick={() => {
                                  setAddPolicyModal(true);
                                  setPolicyId(policy.id);
                                }}
                              />

                              <DownloadIcon
                                onClick={() => {
                                  if (policy?.policy_document) {
                                    saveAs(
                                      policy?.policy_document,
                                      policy?.name
                                    );
                                  }
                                }}
                                className={`${
                                  policy?.policy_document
                                    ? 'cursor-pointer'
                                    : 'opacity-40 cursor-not-allowed'
                                }`}
                              />
                              <DeleteIcon
                                onClick={() => {
                                  if (!policy?.is_default) {
                                    setdeletepolicymodal(true);
                                    setPolicyId(policy.id);
                                  }
                                }}
                                className={`${
                                  policy?.is_default
                                    ? 'opacity-40 cursor-not-allowed'
                                    : 'cursor-pointer'
                                }`}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          );
        })
      )}

      <PoliciesModal
        setIsPoliciesModalOpened={setAddPolicyModal}
        isPoliciesModalOpened={addPolicyModal}
        company_id={company_id}
        policyId={policyId}
        setPolicyId={setPolicyId}
      />

      <DeletePolicy
        setdeletepolicymodal={setdeletepolicymodal}
        deletepolicymodal={deletepolicymodal}
        policyId={policyId}
        setPolicyId={setPolicyId}
      />
      <ViewPolicyModal
        viewPolicyModal={viewPolicyModal}
        setViewPolicyModal={setViewPolicyModal}
        policyDocument={policyDocument}
        setPolicyDocument={setPolicyDocument}
        // name={eachpolicy.name}
      />
    </div>
  );
}

export default Policies;
