// import { Input } from '@mantine/core';
import { Select, Textarea, TextInput } from "@mantine/core";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import { ReactComponent as ImproveIcon } from "assets/icons/improveIcon.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/successIcon.svg";
// import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
// import { ReactComponent as CalanderIcon } from 'assets/icons/calanderIcon.svg';
// import { ReactComponent as AddIcon } from 'assets/icons/pluswithcirclebg.svg';
import { ReactComponent as DeleteIcon } from "assets/icons/minuswithcirclebg.svg";
// import { ReactComponent as StackupIcon } from 'assets/icons/circlestackupIcon.svg';
import SecondaryButton from "components/secondaryButton";
import PrimaryButton from "components/primaryButton";
import { useState, useEffect, useContext } from "react";
// import ScheduleReviewMeating from './schedulereview';
// import { DatePicker } from '@mantine/dates';
// import moment from 'moment';
import dayjs from "dayjs";
// import { ReactComponent as TickIcon } from 'assets/icons/circletickIcon.svg';

//hooks
// import UsegetskillsfromSettings from '../hooks/usegetskills';

import useAddperformanceReport from "../hooks/useAddperformanceReport";
import AddingObjects from "./addingobjects";
import AddingObjectsKra from "./addingKra";

import useGetPerformanceobjectiverating from "../hooks/useGetperformanceobjectivereating";
import useGetPerformancebehaviouralrating from "../hooks/useGetperformancebehaviouralrating";
import useUpdatePerformancereport from "../hooks/useUpdatePerformancereport";
import useGetPerformancekragoals from "../hooks/useGetperformancekragoals";
import { AuthContext } from "context/authContext";

const ProfessionalExcellenceFields = ({
  sendDataToNext,
  setReportModal,
  setSelectEmployeeModal,
  employeedraftreport,
}) => {
  const { authState } = useContext(AuthContext);
  const {
    handleSubmit,
    formState: { errors },
    control,
    // setValue,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      // first_kra: [
      //   { skill: '', expected_value: '', current_value: '', feedback: '' },
      // ],
      // second_kra: [{ skill: '', expected_value: '', feedback: '' }],
      // expected_kra: [{ skill: '', objective: '', expected_value: '' }],
    },
    mode: "onChange",
  });

  const {
    fields: FirstFields,
    prepend: FirstPrepend,
    remove: FirstRemove,
  } = useFieldArray({
    control,
    name: "first_kra",
  });

  const {
    fields: SecondFields,
    prepend: SecondPrepend,
    remove: SecondRemove,
  } = useFieldArray({
    control,
    name: "second_kra",
  });

  const {
    fields: ThirdFields,
    prepend: ThirdPrepend,
    remove: ThirdRemove,
  } = useFieldArray({
    control,
    name: "expected_kra",
  });

  // const [opened, setOpened] = useState(false);
  // const [reviewMeatings, setReviewMeating] = useState(false);

  // calculation
  const [objratingmax, setobjratingmax] = useState(0);
  const [objreatingaverageAcquired, setobjreatingaverageAcquired] = useState(0);

  const [behaviouralMax, setBehaviouralMax] = useState(0);
  const [behaviouralAcquired, setBehaviouralAcquired] = useState(0);

  const [overallMax, setOverAllMax] = useState(0);
  const [overallAcquired, setOverallAcquired] = useState(0);

  useEffect(() => {
    if (watch("first_kra")) {
      const averagemax = watch("first_kra").reduce(
        (total, next) => parseFloat(total) + parseFloat(next.expected_value),
        0
      );
      const averageAcquired =
        watch("first_kra").reduce(
          (total, next) => parseFloat(total) + parseFloat(next.current_value),
          0
        ) / averagemax;
      setobjratingmax((averagemax / averagemax) * 100 || 0);
      setobjreatingaverageAcquired(averageAcquired * 100 || 0);
    }
    if (watch("second_kra")) {
      const behaviouralmax = watch("second_kra").reduce(
        (total, next) => parseFloat(total) + parseFloat(next.expected_value),
        0
      );
      const behaviouralAcquiredavg =
        watch("second_kra").reduce(
          (total, next) => parseFloat(total) + parseFloat(next.current_value),
          0
        ) / behaviouralmax;
      setBehaviouralMax((behaviouralmax / behaviouralmax) * 100 || 0);
      setBehaviouralAcquired(behaviouralAcquiredavg * 100 || 0);
    }

    const overAllPerformanceMax =
      ((parseFloat(objratingmax) + parseFloat(behaviouralMax)) /
        (parseFloat(objratingmax) + parseFloat(behaviouralMax))) *
        100 || 0;

    const checktheDevider =
      objreatingaverageAcquired === 0 || behaviouralAcquired === 0 ? 1 : 2;

    const overAllPerformanceAcquiredavg =
      (parseFloat(objreatingaverageAcquired) + parseInt(behaviouralAcquired)) /
      checktheDevider;

    setOverAllMax(overAllPerformanceMax || 0);
    setOverallAcquired(overAllPerformanceAcquiredavg || 0);

    // eslint-disable-next-line
  }, [watch()]);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className="text-[#D0342C] text-sm">{errors[name].message}</p>
      )
    );
  };

  const getFieldArrayFormErrorMessageFirst = (index, subName) => {
    return (
      errors?.first_kra?.length && (
        <p className="text-[#D0342C] text-sm">
          {errors?.first_kra[index]?.[subName]?.message}
        </p>
      )
    );
  };
  const getFieldArrayFormErrorMessageSecond = (index, subName) => {
    return (
      errors?.second_kra?.length && (
        <p className="text-[#D0342C] text-sm">
          {errors?.second_kra[index]?.[subName]?.message}
        </p>
      )
    );
  };
  const getFieldArrayFormErrorMessageThird = (index, subName) => {
    return (
      errors?.expected_kra?.length && (
        <p className="text-[#D0342C] text-sm">
          {errors?.expected_kra[index]?.[subName]?.message}
        </p>
      )
    );
  };

  // const getSkilldataFromsetting = UsegetskillsfromSettings();

  const closeModal = () => {
    setReportModal(false);
    setSelectEmployeeModal(false);
    setReportModal(false);
    reset({});
  };
  const addPerformanceReportMutation = useAddperformanceReport(closeModal);
  const updatePerformanceReportMutation =
    useUpdatePerformancereport(closeModal);

  const submitPerformanceReport = (data) => {
    console.log(data, "form data");

    // if (data?.is_review_meeting === false) {
    //   delete data?.shadule_date;
    //   delete data?.shadule_time;
    // }

    let rawData = {};

    if (employeedraftreport?.data?.data[0].id) {
      const final_first_kra = [];

      data?.first_kra.forEach((element) => {
        if (!element.hasOwnProperty("is_new")) {
          final_first_kra.push({
            ...element,

            is_new: true,

            is_removed: false,
          });
        }
        objectivePerformanceDraftData?.forEach((secondElement) => {
          if (
            element.id !== secondElement.id &&
            !data?.first_kra.find((x) => x.id === secondElement.id) &&
            !final_first_kra.find((x) => x.id === secondElement.id)
          ) {
            final_first_kra.push({
              ...secondElement,

              is_removed: true,
            });
          } else if (element.id === secondElement.id) {
            final_first_kra.push({
              ...element,
            });
          }
        });
      });

      const final_second_kra = [];

      data?.second_kra.forEach((element) => {
        if (!element.hasOwnProperty("is_new")) {
          final_second_kra.push({
            ...element,
            is_new: true,
            is_removed: false,
          });
        }
        behaviouralPerformanceDraftData.forEach((secondElement) => {
          if (
            element.id !== secondElement.id &&
            !data?.second_kra.find((x) => x.id === secondElement.id) &&
            !final_second_kra.find((x) => x.id === secondElement.id)
          ) {
            final_second_kra.push({
              ...secondElement,

              is_removed: true,
            });
          } else if (element.id === secondElement.id) {
            final_second_kra.push({
              ...element,
            });
          }
        });
      });

      const final_expected_kra = [];

      data?.expected_kra.forEach((element) => {
        if (!element.hasOwnProperty("is_new")) {
          final_expected_kra.push({
            ...element,
            is_new: true,
            is_removed: false,
          });
        }
        kraGoalsDraftData.forEach((secondElement) => {
          if (
            element.id !== secondElement.id &&
            !data?.expected_kra.find((x) => x.id === secondElement.id) &&
            !final_expected_kra.find((x) => x.id === secondElement.id)
          ) {
            final_expected_kra.push({
              ...secondElement,

              is_removed: true,
            });
          } else if (element.id === secondElement.id) {
            final_expected_kra.push({
              ...element,
            });
          }
        });
      });

      rawData = {
        ...data,
        objective_maximum: objratingmax,
        objective_awarded: objreatingaverageAcquired,
        behavioural_maximum: behaviouralMax,
        behavioural_awarded: behaviouralAcquired,
        overall_maximum: overallMax,
        overall_awarded: overallAcquired,
        id: employeedraftreport?.data?.data[0].id,
        review_type_id: employeedraftreport?.data?.data[0]?.review_type_id,
        segment_from: dayjs(
          employeedraftreport?.data?.data[0]?.segment_from
        ).format("YYYY-MM-DD"),
        segment_to: dayjs(
          employeedraftreport?.data?.data[0]?.segment_to
        ).format("YYYY-MM-DD"),
        objective_rating: final_first_kra,
        behavioural_rating: final_second_kra,
        kra_goals: final_expected_kra,
        created_by_id: authState?.user_id,
        is_draft: false,
      };

      delete rawData.first_kra;
      delete rawData.expected_kra;
      delete rawData.second_kra;
      // delete rawData?.shadule_time;
      // delete rawData?.shadule_date;

      updatePerformanceReportMutation.mutate(rawData);
    } else {
      rawData = {
        ...data,
        objective_maximum: objratingmax,
        objective_awarded: objreatingaverageAcquired,
        behavioural_maximum: behaviouralMax,
        behavioural_awarded: behaviouralAcquired,
        overall_maximum: overallMax,
        overall_awarded: overallAcquired,
        employee_id: sendDataToNext?.Select_employee,
        review_type_id: sendDataToNext?.review_type,
        segment_from: dayjs(sendDataToNext?.segment[0]).format("YYYY-MM-DD"),
        segment_to: dayjs(sendDataToNext?.segment[1]).format("YYYY-MM-DD"),
        objective_rating: data?.first_kra,
        behavioural_rating: data?.second_kra,
        kra_goals: data?.expected_kra?.map((each) => {
          return {
            expected_value: each?.expected_value,
            skill: each?.skill,
            objective: each?.objective,
          };
        }),
        created_by_id: authState?.user_id,
        is_draft: false,
      };

      delete rawData.first_kra;
      delete rawData.expected_kra;
      delete rawData.second_kra;
      // delete rawData?.shadule_time;
      // delete rawData?.shadule_date;

      addPerformanceReportMutation.mutate(rawData);
    }
  };

  const saveAsDraft = (data) => {
    let rawData = {};

    if (employeedraftreport?.data?.data[0].id) {
      const final_first_kra = [];

      data?.first_kra.forEach((element) => {
        if (!element.hasOwnProperty("is_new")) {
          final_first_kra.push({
            ...element,
            is_new: true,
            is_removed: false,
          });
        }
        objectivePerformanceDraftData?.forEach((secondElement) => {
          if (
            element.id !== secondElement.id &&
            !data?.first_kra.find((x) => x.id === secondElement.id) &&
            !final_first_kra.find((x) => x.id === secondElement.id)
          ) {
            final_first_kra.push({
              ...secondElement,

              is_removed: true,
            });
          } else if (element.id === secondElement.id) {
            final_first_kra.push({
              ...element,
            });
          }
        });
      });

      const final_second_kra = [];

      data?.second_kra.forEach((element) => {
        if (!element.hasOwnProperty("is_new")) {
          final_second_kra.push({
            ...element,
            is_new: true,
            is_removed: false,
          });
        }
        behaviouralPerformanceDraftData.forEach((secondElement) => {
          if (
            element.id !== secondElement.id &&
            !data?.second_kra.find((x) => x.id === secondElement.id) &&
            !final_second_kra.find((x) => x.id === secondElement.id)
          ) {
            final_second_kra.push({
              ...secondElement,

              is_removed: true,
            });
          } else if (element.id === secondElement.id) {
            final_second_kra.push({
              ...element,
            });
          }
        });
      });

      const final_expected_kra = [];

      data?.expected_kra.forEach((element) => {
        if (!element.hasOwnProperty("is_new")) {
          final_expected_kra.push({
            ...element,
            is_new: true,
            is_removed: false,
          });
        }
        kraGoalsDraftData.forEach((secondElement) => {
          if (
            element.id !== secondElement.id &&
            !data?.expected_kra.find((x) => x.id === secondElement.id) &&
            !final_expected_kra.find((x) => x.id === secondElement.id)
          ) {
            final_expected_kra.push({
              ...secondElement,

              is_removed: true,
            });
          } else if (element.id === secondElement.id) {
            final_expected_kra.push({
              ...element,
            });
          }
        });
      });

      rawData = {
        ...data,
        objective_maximum: objratingmax,
        objective_awarded: objreatingaverageAcquired,
        behavioural_maximum: behaviouralMax,
        behavioural_awarded: behaviouralAcquired,
        overall_maximum: overallMax,
        overall_awarded: overallAcquired,
        id: employeedraftreport?.data?.data[0].id,
        review_type_id: employeedraftreport?.data?.data[0]?.review_type_id,
        segment_from: dayjs(
          employeedraftreport?.data?.data[0]?.segment_from
        ).format("YYYY-MM-DD"),
        segment_to: dayjs(
          employeedraftreport?.data?.data[0]?.segment_to
        ).format("YYYY-MM-DD"),
        objective_rating: final_first_kra,
        behavioural_rating: final_second_kra,
        kra_goals: final_expected_kra,
        created_by_id: authState?.user_id,
        is_draft: true,
      };

      delete rawData.first_kra;
      delete rawData.expected_kra;
      delete rawData.second_kra;
      // delete rawData?.shadule_time;
      // delete rawData?.shadule_date;

      updatePerformanceReportMutation.mutate(rawData);
    } else {
      rawData = {
        ...data,
        objective_maximum: objratingmax,
        objective_awarded: objreatingaverageAcquired,
        behavioural_maximum: behaviouralMax,
        behavioural_awarded: behaviouralAcquired,
        overall_maximum: overallMax,
        overall_awarded: overallAcquired,
        employee_id: sendDataToNext?.Select_employee,
        review_type_id: sendDataToNext?.review_type,
        segment_from: dayjs(sendDataToNext?.segment[0]).format("YYYY-MM-DD"),
        segment_to: dayjs(sendDataToNext?.segment[1]).format("YYYY-MM-DD"),
        objective_rating: data?.first_kra,
        behavioural_rating: data?.second_kra,
        kra_goals: data?.expected_kra.map((each) => {
          return {
            expected_value: each?.expected_value,
            skill: each?.skill,
            objective: each?.objective,
          };
        }),
        is_draft: true,
        created_by_id: authState?.user_id,
      };

      delete rawData.first_kra;
      delete rawData.expected_kra;
      delete rawData.second_kra;
      // delete rawData?.shadule_time;
      // delete rawData?.shadule_date;

      addPerformanceReportMutation.mutate(rawData);
    }
  };

  // get request of objective, behavioral and kra goals
  const objectivePerformance = useGetPerformanceobjectiverating(
    employeedraftreport?.data?.data[0].id
  );
  const behaviouralPerformance = useGetPerformancebehaviouralrating(
    employeedraftreport?.data?.data[0].id
  );
  const kraGoals = useGetPerformancekragoals(
    employeedraftreport?.data?.data[0].id
  );

  // setting the default values of each get request of objective, behavioral and kra goals
  const objectivePerformanceDraftData = employeedraftreport
    ? objectivePerformance?.data?.data.map((each) => ({
        is_new: false,
        is_removed: false,
        ...each,
      }))
    : null;

  const behaviouralPerformanceDraftData = employeedraftreport
    ? behaviouralPerformance?.data?.data.map((each) => {
        return { is_new: false, is_removed: false, ...each };
      })
    : null;

  const kraGoalsDraftData = employeedraftreport
    ? kraGoals?.data?.data.map((each) => {
        return { is_new: false, is_removed: false, ...each };
      })
    : null;

  // setting the values to the  from on bases of get request
  useEffect(() => {
    reset({
      first_kra: objectivePerformanceDraftData?.map((first) => ({
        skill: first?.skill,
        current_value: first?.current_value,
        expected_value: first?.expected_value,
        feedback: first?.feedback,
        self_rating: first?.self_rating,
        id: first?.id,
        is_new: false,
        is_removed: false,
      })),
      second_kra: behaviouralPerformanceDraftData?.map((first) => ({
        skill: first?.skill,
        current_value: first?.current_value,
        expected_value: first?.expected_value,
        feedback: first?.feedback,
        self_rating: first?.self_rating,
        id: first?.id,
        is_new: false,
        is_removed: false,
      })),
      expected_kra: kraGoalsDraftData?.map((first) => ({
        skill: first?.skill,
        expected_value: first?.expected_value,
        objective: first?.objective,
        id: first?.id,
        is_new: false,
        is_removed: false,
      })),
    });
    setValue(
      "objective_feedback",
      employeedraftreport?.data?.data[0].objective_feedback
    );
    setValue(
      "objective_maximum",
      employeedraftreport?.data?.data[0].objective_maximum
    );
    setValue(
      "objective_awarded",
      employeedraftreport?.data?.data[0].objective_awarded
    );
    setValue(
      "behavioural_feedback",
      employeedraftreport?.data?.data[0].behavioural_feedback
    );
    setValue(
      "behavioural_maximum",
      employeedraftreport?.data?.data[0].behavioural_maximum
    );
    setValue(
      "behavioural_awarded",
      employeedraftreport?.data?.data[0].behavioural_awarded
    );
    setValue(
      "overall_feedback",
      employeedraftreport?.data?.data[0].overall_feedback
    );
    setValue(
      "overall_maximum",
      employeedraftreport?.data?.data[0].overall_maximum
    );
    setValue(
      "overall_awarded",
      employeedraftreport?.data?.data[0].overall_awarded
    );
    // eslint-disable-next-line
  }, [
    employeedraftreport?.data?.data,
    objectivePerformance?.data?.data,
    behaviouralPerformance?.data?.data,
    kraGoals?.data?.data,
  ]);

  return (
    <div>
      <div className="flex space-x-4">
        {" "}
        <div>
          <form onSubmit={handleSubmit(submitPerformanceReport)}>
            <h1 className="text-lg font-semibold my-5">
              Overall objective rating
            </h1>
            <div className="grid grid-cols-11 mt-7 -mb-3">
              <p className="text-sm text-primary pb-1.5 col-span-3">
                Objective description *
              </p>
              <p className="text-sm text-primary pb-1.5 w-72 col-span-2 ml-2">
                Worth *
              </p>
              <p className="text-sm text-primary pb-1.5 col-span-2 ml-2">
                Awarded *
              </p>
              <p className="text-sm text-primary pb-1.5 col-span-3 ml-2">
                Comments / feedback (if any)
              </p>
            </div>
            {/*First Field */}

            <AddingObjects appendedTo={FirstPrepend} />

            <div>
              {FirstFields?.map((item, index) => (
                <div key={item.id} className="grid grid-cols-11 gap-3  my-2">
                  <div className="col-span-3 py-1 ">
                    <Controller
                      control={control}
                      // name='name'
                      name={`first_kra.${index}.skill`}
                      rules={{
                        required: "Required",
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          placeholder="Type a skill "
                          size="md"
                          classNames={{
                            input: "border-primary",
                            label: "text-neutralsGrey text-sm",
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageFirst(index, "skill")}
                  </div>
                  <div className="col-span-2 py-1 ">
                    {" "}
                    <Controller
                      control={control}
                      // name='name'
                      name={`first_kra.${index}.expected_value`}
                      rules={{
                        required: "Required",
                        max: {
                          value: 100,
                          message: "Max value is 100 ",
                        },
                        min: {
                          value: 1,
                          message: "Min value is 1",
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size="md"
                          type="number"
                          classNames={{
                            input: "border-primary",
                            label: "text-neutralsGrey text-sm",
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageFirst(
                      index,
                      "expected_value"
                    )}
                  </div>
                  <div className="col-span-2 py-1 ">
                    {" "}
                    <Controller
                      control={control}
                      // name='name'
                      name={`first_kra.${index}.current_value`}
                      rules={{
                        required: "Required",
                        max: {
                          value: 100,
                          message: "Max value is 100 ",
                        },
                        min: {
                          value: 0,
                          message: "Min value is 1",
                        },
                        validate: (value) =>
                          parseFloat(value) <=
                            parseFloat(
                              watch(`first_kra.${index}.expected_value`)
                            ) || "value must be less than Worth value",
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size="md"
                          type="number"
                          rightSection={
                            parseFloat(
                              watch(`first_kra.${index}.expected_value`)
                            ) <=
                            parseFloat(
                              watch(`first_kra.${index}.current_value`)
                            ) ? (
                              <SuccessIcon className="h-5 w-5" />
                            ) : (
                              <ImproveIcon className="h-5 w-5" />
                            )
                          }
                          classNames={{
                            input: "border-primary ",
                            label: "text-neutralsGrey text-[12px] ml-2 ",
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageFirst(index, "current_value")}
                  </div>
                  <div className="col-span-3 py-1 ">
                    {" "}
                    <Controller
                      control={control}
                      // name='name'
                      name={`first_kra.${index}.feedback`}
                      // rules={{
                      //   required: 'Required',
                      // }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size="md"
                          classNames={{
                            input: "border-primary ",
                            label: "text-neutralsGrey text-[12px] ml-2 ",
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageFirst(index, "feedback")}
                  </div>
                  <div className="py-1  grid grid-cols-1 justify-center mx-auto item-center">
                    {/* <StackupIcon className='h-6 w-6' /> */}
                    {/* <TickIcon className='h-6 w-6' /> */}
                    {/* {index === 0 ? (
                      <button type='button' onClick={() => FirstPrepend()}>
                        <AddIcon className='h-8 w-8' />
                      </button>
                    ) : ( */}
                    <button
                      type="button"
                      onClick={() => {
                        FirstRemove(index);
                      }}
                    >
                      <DeleteIcon className="h-8 w-8" />
                    </button>
                    {/* )} */}
                  </div>
                </div>
              ))}
            </div>
            {/* Comment section */}
            <div className="mt-4 my-2">
              <Controller
                control={control}
                // name='name'
                name="objective_feedback"
                // rules={{
                //   required: 'Required',
                // }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    size="md"
                    placeholder="Add Comment"
                    label="Overall comment / feedback"
                    classNames={{
                      input: "h-[75px] border-primary",
                      label: "text-primary text-sm",
                    }}
                  />
                )}
              />
              {getFormErrorMessage("objective_feedback")}
            </div>

            <div className="grid grid-cols-2 gap-3">
              <div className="mt-3">
                <Controller
                  control={control}
                  value={`${parseFloat(objratingmax).toFixed(2)}`}
                  // name='name'
                  name="objective_maximum"
                  // rules={{
                  //   required: 'Required',
                  // }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size="md"
                      value={`${parseFloat(objratingmax).toFixed(2)}`}
                      readOnly
                      disabled
                      placeholder="Maximum %"
                      type="number"
                      classNames={{
                        input: "",
                        label: "text-neutralsGrey text-sm",
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("objective_maximum")}
              </div>
              <div className="mt-3">
                <Controller
                  control={control}
                  // name='name'
                  value={`${parseFloat(objreatingaverageAcquired).toFixed(2)}`}
                  name="objective_awarded"
                  // rules={{
                  //   required: 'Required',
                  // }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size="md"
                      value={`${parseFloat(objreatingaverageAcquired).toFixed(
                        2
                      )}`}
                      type="number"
                      readOnly
                      disabled
                      placeholder="Acquired %"
                      classNames={{
                        input: "",
                        label: "text-neutralsGrey text-sm",
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("objective_awarded")}
              </div>{" "}
            </div>
            {/* Overall behavioural assessment rating */}
            <h1 className="text-lg font-semibold mt-10 my-4">
              Overall behavioural assessment rating
            </h1>

            {/*Second Field */}
            <div className="grid grid-cols-11 mt-7 -mb-3">
              <p className="text-sm text-primary pb-1.5 col-span-3">
                Objective description *
              </p>
              <p className="text-sm text-primary pb-1.5 w-72 col-span-2 ml-2">
                Worth *
              </p>
              <p className="text-sm text-primary pb-1.5 col-span-2 ml-2">
                Awarded *
              </p>
              <p className="text-sm text-primary pb-1.5 col-span-3 ml-2">
                Comments / feedback (if any)
              </p>
            </div>
            <AddingObjects appendedTo={SecondPrepend} />
            {SecondFields.map((item, index) => (
              <div key={item.id} className="grid grid-cols-11 gap-3 ">
                <div className="col-span-3 py-1 mt-3">
                  <Controller
                    control={control}
                    // name='name'
                    name={`second_kra.${index}.skill`}
                    rules={{
                      required: "Required",
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        placeholder="Type a skill "
                        size="md"
                        classNames={{
                          input: "border-primary",
                          label: "text-neutralsGrey text-sm",
                        }}
                      />
                    )}
                  />
                  {getFieldArrayFormErrorMessageSecond(index, "skill")}
                </div>

                <div className="col-span-2 py-1 mt-3">
                  {" "}
                  <Controller
                    control={control}
                    // name='name'
                    name={`second_kra.${index}.expected_value`}
                    rules={{
                      required: "Required",
                      max: {
                        value: 100,
                        message: "Max value is 100 ",
                      },
                      min: {
                        value: 0,
                        message: "Min value is 1",
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        type="number"
                        classNames={{
                          input: "border-primary",
                          label: "text-neutralsGrey text-sm",
                        }}
                      />
                    )}
                  />
                  {getFieldArrayFormErrorMessageSecond(index, "expected_value")}
                </div>
                <div className="col-span-2 py-1 mt-3">
                  {" "}
                  <Controller
                    control={control}
                    // name='name'
                    name={`second_kra.${index}.current_value`}
                    rules={{
                      required: "Required",
                      max: {
                        value: 100,
                        message: "Max value is 100 ",
                      },
                      min: {
                        value: 0,
                        message: "Min value is 1",
                      },
                      validate: (value) =>
                        parseFloat(value) <=
                          parseFloat(
                            watch(`second_kra.${index}.expected_value`)
                          ) || "value must be less than Worth value",
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        type="number"
                        rightSection={
                          parseFloat(
                            watch(`second_kra.${index}.expected_value`)
                          ) <=
                          parseFloat(
                            watch(`second_kra.${index}.current_value`)
                          ) ? (
                            <SuccessIcon className="h-5 w-5" />
                          ) : (
                            <ImproveIcon className="h-5 w-5" />
                          )
                        }
                        classNames={{
                          input: "border-primary ",
                          label: "text-neutralsGrey text-[12px] ml-2 ",
                        }}
                      />
                    )}
                  />
                  {getFieldArrayFormErrorMessageSecond(index, "current_value")}
                </div>
                <div className="col-span-3 py-1 mt-3">
                  {" "}
                  <Controller
                    control={control}
                    // name='name'
                    name={`second_kra.${index}.feedback`}
                    // rules={{
                    //   required: 'Required',
                    // }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        classNames={{
                          input: "border-primary ",
                          label: "text-neutralsGrey text-[12px] ml-2 ",
                        }}
                      />
                    )}
                  />
                  {getFieldArrayFormErrorMessageSecond(index, "feedback")}
                </div>
                <div className="py-1 mt-3 grid grid-cols-1 justify-center mx-auto items-center">
                  {/* <StackupIcon className='h-6 w-6' /> */}
                  {/* <TickIcon className='h-6 w-6' /> */}
                  {/* {index === 0 ? (
                    <button type='button' onClick={() => SecondPrepend()}>
                      <AddIcon className='h-8 w-8' />
                    </button>
                  ) : ( */}
                  <button type="button" onClick={() => SecondRemove(index)}>
                    <DeleteIcon className="h-8 w-8" />
                  </button>
                  {/* )} */}
                </div>
              </div>
            ))}
            {/* Comment section */}
            <div className="mt-4 my-2">
              <Controller
                control={control}
                // name='name'
                name="behavioural_feedback"
                // rules={{
                //   required: 'Required',
                // }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    size="md"
                    label="Overall comment / feedback"
                    placeholder="Add Comment"
                    classNames={{
                      input: "h-[75px] border-primary",
                      label: "text-primary text-sm",
                    }}
                  />
                )}
              />
              {getFormErrorMessage("behavioural_feedback")}
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className="mt-3">
                <Controller
                  control={control}
                  // name='name'
                  name="behavioural_maximum"
                  value={`${parseFloat(behaviouralMax).toFixed(2)}`}
                  // rules={{
                  //   required: 'Required',
                  // }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size="md"
                      placeholder="Maximum %"
                      type="number"
                      readOnly
                      disabled
                      value={`${parseFloat(behaviouralMax).toFixed(2)}`}
                      classNames={{
                        input: "",
                        label: "text-neutralsGrey text-sm",
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("behavioural_maximum")}
              </div>
              <div className="mt-3">
                <Controller
                  control={control}
                  // name='name'
                  value={`${parseFloat(behaviouralAcquired).toFixed(2)}`}
                  name="behavioural_awarded"
                  // rules={{
                  //   required: 'Required',
                  // }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size="md"
                      value={`${parseFloat(behaviouralAcquired).toFixed(2)}`}
                      readOnly
                      disabled
                      placeholder="Acquired %"
                      type="number"
                      classNames={{
                        input: "",
                        label: "text-neutralsGrey text-sm",
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("behavioural_awarded")}
              </div>{" "}
            </div>
            <div className="flex justify-between items-center mt-10">
              <h1 className="text-lg font-semibold mt-4 my-2">
                Set KRA / goals for next review{" "}
                <span className="text-primary">*</span>
              </h1>
              {/* <div className=' flex items-center gap-3'>
                <p className='text-sm text-[#3b54e4] opacity-50 cursor-not-allowed font-medium'>
                  Select a review period
                </p>
                <p className='text-sm text-[#A9A9A9]'>
                  Schedule an review meeting
                </p>

                <Popover
                  opened={reviewMeatings}
                  // onClose={() => setOpened(false)}
                  target={
                    <Controller
                      control={control}
                      name='is_review_meeting'
                      defaultValue={false}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={opened}
                          onClick={() => {
                            setOpened(true);
                            setReviewMeating(true);
                          }}
                        />
                      )}
                    />
                  }
                  width={400}
                  position='bottom'
                  withArrow
                >
                  <div>
                    <h1 className='text-lg font-semibold text-center'>
                      Scheduling performance review{' '}
                    </h1>

                    <div className='grid grid-cols-2 gap-3'>
                      <div className=' py-1 mt-3'>
                        <Controller
                          control={control}
                          // name='name'
                          name='shadule_date'
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              placeholder='Pick a date'
                              rightSection={<CalanderIcon />}
                              styles={{
                                rightSection: { pointerEvents: 'none' },
                              }}
                              size='md'
                              classNames={{
                                input: 'border-primary',
                                label: 'text-neutralsGrey text-sm',
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className=' py-1 mt-3'>
                        <Controller
                          control={control}
                          // name='name'
                          name='shadule_time'
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <TextInput
                              {...field}
                              placeholder='Type a skill '
                              type='time'
                              size='md'
                              classNames={{
                                input: 'border-primary',
                                label: 'text-neutralsGrey text-sm',
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className='flex justify-center items-center gap-1 my-5'>
                      <button
                        onClick={() => {
                          // setValue('shadule_time', '');
                          // setValue('shadule_date', null);
                          setReviewMeating(false);
                          setOpened(false);
                        }}
                      >
                        <SecondaryButton>Cancle</SecondaryButton>
                      </button>
                      <button onClick={() => setReviewMeating(false)}>
                        <PrimaryButton>Save</PrimaryButton>
                      </button>
                    </div>
                  </div>
                </Popover>
              </div> */}
            </div>
            {/* Third Field */}
            <AddingObjectsKra appendedTo={ThirdPrepend} />

            <div>
              {ThirdFields.map((item, index) => (
                <div key={item.id} className="grid grid-cols-9 gap-3 ">
                  <div className="col-span-4 py-1 mt-3">
                    <Controller
                      control={control}
                      // name='name'
                      name={`expected_kra.${index}.skill`}
                      rules={{
                        required: "Required",
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          placeholder="Type a skill "
                          size="md"
                          classNames={{
                            input: "border-primary",
                            label: "text-neutralsGrey text-sm",
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageThird(index, "skill")}
                  </div>
                  <div className="col-span-2 py-1 mt-3">
                    <Controller
                      control={control}
                      // name='name'
                      name={`expected_kra.${index}.objective`}
                      rules={{
                        required: "Required",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Type a skill "
                          size="md"
                          data={[
                            { value: "Behavioural", label: "Behavioural" },
                            { value: "Objective", label: "Objective" },
                          ]}
                          classNames={{
                            input: "border-primary",
                            label: "text-neutralsGrey text-sm",
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageThird(index, "objective")}
                  </div>
                  <div className="col-span-2 py-1 mt-3">
                    {" "}
                    <Controller
                      control={control}
                      // name='name'
                      name={`expected_kra.${index}.expected_value`}
                      rules={{
                        required: "Required",
                        max: {
                          value: 100,
                          message: "Max value is 100 ",
                        },
                        min: {
                          value: 0,
                          message: "Min value is 0",
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          size="md"
                          type="number"
                          classNames={{
                            input: "border-primary",
                            label: "text-neutralsGrey text-sm",
                          }}
                        />
                      )}
                    />
                    {getFieldArrayFormErrorMessageThird(
                      index,
                      "expected_value"
                    )}
                  </div>

                  <div className="py-1 mt-3 grid grid-cols-1 justify-center mx-auto items-center">
                    {/* <StackupIcon className='h-6 w-6' /> */}
                    {/* <TickIcon className='h-6 w-6' /> */}
                    {/* {index === 0 ? (
                      <button type='button' onClick={() => ThirdPrepend()}>
                        <AddIcon className='h-8 w-8' />
                      </button>
                    ) : ( */}
                    <button type="button" onClick={() => ThirdRemove(index)}>
                      <DeleteIcon className="h-8 w-8" />
                    </button>
                    {/* )} */}
                  </div>
                </div>
              ))}
            </div>
            <h1 className="text-lg font-semibold mt-10  my-8">
              Overall performance summary{" "}
              <span className="text-primary">*</span>
            </h1>
            {/* Comment section */}
            <div className="mt-4">
              <Controller
                control={control}
                // name='name'
                name="overall_feedback"
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    size="md"
                    placeholder="Type here"
                    classNames={{
                      input: "h-[75px] border-primary",
                      label: "text-primary text-sm",
                    }}
                  />
                )}
              />
              {getFormErrorMessage("overall_feedback")}
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className=" py-1 mt-3">
                {" "}
                <Controller
                  control={control}
                  // name='name'
                  value={`${parseFloat(overallMax).toFixed(2)}`}
                  name="overall_maximum"
                  // rules={{
                  //   required: 'Required',
                  // }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size="md"
                      type="number"
                      label="Overall Score"
                      value={`${parseFloat(overallMax).toFixed(2)}`}
                      readOnly
                      disabled
                      classNames={{
                        // input: 'border-primary',
                        label: "text-primary text-sm",
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("overall_maximum")}
              </div>
              <div className=" py-1 mt-3">
                {" "}
                <Controller
                  control={control}
                  // name='name'
                  value={`${parseFloat(overallAcquired).toFixed(2)}`}
                  name="overall_awarded"
                  // rules={{
                  //   required: 'Required',
                  // }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size="md"
                      type="number"
                      label="Overall Score secured"
                      value={`${parseFloat(overallAcquired).toFixed(2)}`}
                      readOnly
                      disabled
                      classNames={{
                        // input: 'border-primary',
                        label: "text-primary text-sm",
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("overall_awarded")}
              </div>
            </div>
            <div className="flex justify-center items-center gap-3 mt-10 mb-5">
              <button type="button" onClick={handleSubmit(saveAsDraft)}>
                <SecondaryButton>Save as draft</SecondaryButton>
              </button>
              <button>
                <PrimaryButton>Generate report</PrimaryButton>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalExcellenceFields;
