import { AuthContext } from 'context/authContext';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetlistofEmployess() {
     const { authState } = useContext(AuthContext);
  return useQuery(['employess-List' ], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.LIST_OF_EMPLOYEES, {
        user__is_active: 'True',
        reports_to_id: authState?.is_subadmin ? authState?.user_id  : null 
      })
    );

    return response.data;
  });
}
