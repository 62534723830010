import React, { Fragment } from "react";
import { useState, useEffect, useContext } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { TextInput, Textarea } from "@mantine/core";
import { ReactComponent as DeleteIcon } from "assets/icons/minuswithcirclebg.svg";
import { ReactComponent as CrossIcon2 } from "assets/icons/crossIcon2.svg";
import SecondaryButton from "components/secondaryButton";
import PrimaryButton from "components/primaryButton";
import useAddperformanceReport from "../hooks/useAddperformanceReport";
import useUpdatePerformancereport from "../hooks/useUpdatePerformancereport";
import dayjs from "dayjs";
import { AuthContext } from "context/authContext";
import useGetPerformanceobjectiverating from "../hooks/useGetperformanceobjectivereating";
const PerformanceReport = ({
  sendDataToNext,
  setReportModal,
  setSelectEmployeeModal,
  employeedraftreport,
}) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      reports: [
        {
          objective_name: "Overall objective rating",
          options: [
            {
              skill: "",
              expected_value: "",
              current_value: "",
              feedback: "",
            },
          ],
        },
      ],
    },
    mode: "onChange",
  });
  const { authState } = useContext(AuthContext);
  console.log(authState, "auth");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "reports",
  });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className="text-[#D0342C] text-sm">{errors[name].message}</p>
      )
    );
  };

  const getFieldArrayFormErrorMessageFirst = (index, subName) => {
    return (
      errors?.reports?.length && (
        <p>{errors?.reports?.[index]?.options?.[0]?.current_value?.message}</p>
      )
    );
  };
  console.log(errors?.reports, "error");
  const handleNext = () => {
    append({
      objective_name: "Overall objective rating",
      options: [
        {
          skill: "",
          expected_value: "",
          current_value: "",
          feedback: "",
        },
      ],
    });
  };

  const addPerformanceReportMutation = useAddperformanceReport();
  const updatePerformanceReportMutation = useUpdatePerformancereport();
  const [objectiveName, setObjectiveName] = useState("object");
  const submitPerformanceReport = (data) => {
    console.log(data, "form data");

    let rawData = {};

    if (employeedraftreport?.data?.data[0].id) {
      const final_reports = [];

      data?.reports.forEach((element) => {
        if (!element.hasOwnProperty("is_new")) {
          final_reports.push({
            ...element,

            is_new: true,

            is_removed: false,
          });
        }
        objectivePerformanceDraftData?.forEach((secondElement) => {
          if (
            element.id !== secondElement.id &&
            !data?.reports.find((x) => x.id === secondElement.id) &&
            !final_reports.find((x) => x.id === secondElement.id)
          ) {
            final_reports.push({
              ...secondElement,

              is_removed: true,
            });
          } else if (element.id === secondElement.id) {
            final_reports.push({
              ...element,
            });
          }
        });
      });

      rawData = {
        ...data,

        id: employeedraftreport?.data?.data[0].id,
        review_type_id: employeedraftreport?.data?.data[0]?.review_type_id,
        segment_from: dayjs(
          employeedraftreport?.data?.data[0]?.segment_from
        ).format("YYYY-MM-DD"),
        segment_to: dayjs(
          employeedraftreport?.data?.data[0]?.segment_to
        ).format("YYYY-MM-DD"),
        // objective_rating: [...final_reports, { objective_name: objectiveName }],
        objective_rating: final_reports,

        employee_id: authState?.employee_id,
        created_by_id: authState?.user_id,
        is_draft: false,
      };

      delete rawData.reports;
      delete rawData.expected_kra;
      delete rawData.second_kra;
      // delete rawData?.shadule_time;
      // delete rawData?.shadule_date;

      updatePerformanceReportMutation.mutate(rawData);
    } else {
      rawData = {
        ...data,

        employee_id: sendDataToNext?.Select_employee,
        review_type_id: sendDataToNext?.review_type,
        segment_from: dayjs(sendDataToNext?.segment[0]).format("YYYY-MM-DD"),
        segment_to: dayjs(sendDataToNext?.segment[1]).format("YYYY-MM-DD"),
        objective_rating: data?.reports,
        is_draft: false,

        // objective_rating: [...data?.reports, { objective_name: objectiveName }],
      };

      delete rawData.reports;
      delete rawData.expected_kra;
      delete rawData.second_kra;
      // delete rawData?.shadule_time;
      // delete rawData?.shadule_date;

      addPerformanceReportMutation.mutate(rawData);
    }
    console.log(rawData, "rawData");
  };

  const saveAsDraft = (data) => {
    let rawData = {};

    if (employeedraftreport?.data?.data[0].id) {
      const final_reports = [];

      data?.reports.forEach((element) => {
        if (!element.hasOwnProperty("is_new")) {
          final_reports.push({
            ...element,
            is_new: true,
            is_removed: false,
          });
        }
        objectivePerformanceDraftData?.forEach((secondElement) => {
          if (
            element.id !== secondElement.id &&
            !data?.reports.find((x) => x.id === secondElement.id) &&
            !final_reports.find((x) => x.id === secondElement.id)
          ) {
            final_reports.push({
              ...secondElement,

              is_removed: true,
            });
          } else if (element.id === secondElement.id) {
            final_reports.push({
              ...element,
            });
          }
        });
      });

      rawData = {
        ...data,

        id: employeedraftreport?.data?.data[0].id,
        review_type_id: employeedraftreport?.data?.data[0]?.review_type_id,
        segment_from: dayjs(
          employeedraftreport?.data?.data[0]?.segment_from
        ).format("YYYY-MM-DD"),
        segment_to: dayjs(
          employeedraftreport?.data?.data[0]?.segment_to
        ).format("YYYY-MM-DD"),
        objective_rating: final_reports,
        employee_id: authState?.employee_id,
        // objective_rating: [...final_reports, { objective_name: objectiveName }],
        is_draft: true,
      };

      delete rawData.reports;

      // delete rawData?.shadule_time;
      // delete rawData?.shadule_date;

      updatePerformanceReportMutation.mutate(rawData);
    } else {
      rawData = {
        ...data,

        employee_id: sendDataToNext?.Select_employee,
        review_type_id: sendDataToNext?.review_type,
        segment_from: dayjs(sendDataToNext?.segment[0]).format("YYYY-MM-DD"),
        segment_to: dayjs(sendDataToNext?.segment[1]).format("YYYY-MM-DD"),
        objective_rating: data?.reports,

        is_draft: true,
        created_by_id: authState?.user_id,
      };

      delete rawData.reports;

      // delete rawData?.shadule_time;
      // delete rawData?.shadule_date;

      addPerformanceReportMutation.mutate(rawData);
    }
  };
  const objectivePerformance = useGetPerformanceobjectiverating(
    employeedraftreport?.data?.data[0].id
  );
  const objectivePerformanceDraftData = employeedraftreport
    ? objectivePerformance?.data?.data.map((each) => ({
        is_new: false,
        is_removed: false,
        ...each,
      }))
    : null;

  return (
    <div>
      <form onSubmit={handleSubmit(submitPerformanceReport)}>
        {fields?.map((field, index) => {
          return (
            <Fragment key={field.id}>
              {/* <h1 className="text-lg font-semibold my-5">
                Overall objective rating
              </h1> */}
              {/* <input
                type="text"
                value={objectiveName}
                onChange={(e) => setObjectiveName(e.target.value)}
                placeholder="Overall objective rating"
                className="text-lg font-semibold my-5 p-2"
              /> */}
              <div className="flex  justify-between items-center">
                <div className=" mr-7">
                  <Controller
                    control={control}
                    name={`reports.${index}.objective_name`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        placeholder="Overall objective rating"
                        classNames={{
                          input: "border-none font-bold  text-xl ",
                          label: "text-primary ",
                        }}
                      />
                    )}
                  />
                  {getFormErrorMessage("objective_name")}
                </div>
                {index > 0 && (
                  <button onClick={() => remove(index)}>
                    <CrossIcon2 className=" h-5 w-5" />
                  </button>
                )}
              </div>
              <PerformanceFields
                control={control}
                reportIdx={index}
                isValid={isValid}
              />

              <div className="mt-4 my-2">
                <Controller
                  control={control}
                  name={`reports.${index}.objective_feedback`}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      size="md"
                      placeholder="Add Comment"
                      label="Overall comment / feedback"
                      classNames={{
                        input: "h-[75px] border-primary",
                        label: "text-primary text-sm",
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("objective_feedback")}
              </div>

              <div className="grid grid-cols-2 gap-3">
                <div className="mt-3">
                  <Controller
                    control={control}
                    name={`reports.${index}.objective_maximum`}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        readOnly
                        disabled
                        placeholder="Maximum %"
                        type="number"
                        classNames={{
                          input: "",
                          label: "text-neutralsGrey text-sm",
                        }}
                      />
                    )}
                  />
                  {getFormErrorMessage("objective_maximum")}
                </div>
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="objective_awarded"
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        size="md"
                        readOnly
                        disabled
                        placeholder="Acquired %"
                        type="number"
                        classNames={{
                          input: "",
                          label: "text-primary text-sm",
                        }}
                      />
                    )}
                  />
                  {getFormErrorMessage("objective_awarded")}
                </div>
              </div>
            </Fragment>
          );
        })}

        <div className="flex justify-center items-center gap-3 mt-10 mb-5">
          <button type="button" onClick={handleSubmit(saveAsDraft)}>
            <SecondaryButton>Save as draft</SecondaryButton>
          </button>
          <button>
            <PrimaryButton>Generate report</PrimaryButton>
          </button>
        </div>
      </form>
      <div className=" flex justify-center mt-4">
        <button
          type="button"
          onClick={handleNext}
          className="flex justify-center items-center bg-primary p-3 rounded-md text-center text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

const PerformanceFields = ({
  control,
  reportIdx,
  isValid,
  setValue,
  removeField,
  getFieldArrayFormErrorMessageFirst
}) => {
  const { fields, append } = useFieldArray({
    control,
    name: `reports.${reportIdx}.options`,
  });

  const handleDelete = (index) => {
    removeField(index);
  };

  const handleSave = (index) => {
    append({
      skill: "",
      expected_value: "",
      current_value: "",
      feedback: "",
      // objective_feedback: "",
      // objective_awarded: "",
    });
    setValue(
      `reports.${reportIdx}.options.${index}.objective_name`,
      "Overall objective rating"
    );
  };

  return fields.map((field, index) => (
    <div key={field.id} className="grid grid-cols-11 gap-3 my-2">
      <div className="col-span-3 py-1">
        <Controller
          control={control}
          name={`reports.${reportIdx}.options.${index}.skill`}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Objective description *"
              placeholder="Type a skill"
              size="md"
              classNames={{
                input: "",
                label: "text-primary text-sm",
              }}
            />
          )}
          
        />
        
      </div>
      <div className="col-span-2 py-1">
        <Controller
          control={control}
          name={`reports.${reportIdx}.options.${index}.expected_value`}
          rules={{
            required: "Required",
            max: { value: 100, message: "Max value is 100 " },
            min: { value: 1, message: "Min value is 1" },
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Worth *"
              size="md"
              type="number"
              classNames={{
                input: "",
                label: "text-primary text-sm",
              }}
            />
          )}
        />
      </div>
      <div className="col-span-2 py-1">
        <Controller
          control={control}
          name={`reports.${reportIdx}.options.${index}.current_value`}
          rules={{
            required: "Required",
            max: { value: 100, message: "Max value is 100 " },
            min: { value: 0, message: "Min value is 1" },
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Awarded *"
              size="md"
              type="number"
              classNames={{
                input: "",
                label: "text-primary text-sm",
              }}
            />
          )}
        />
      </div>
      <div className="col-span-3 py-1">
        <Controller
          control={control}
          name={`reports.${reportIdx}.options.${index}.feedback`}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Comments / feedback (if any)"
              size="md"
              classNames={{
                input: "",
                label: "text-primary text-sm",
              }}
            />
          )}
        />
      </div>
      <div className="flex justify-center items-center">
        {" "}
        {index === 0 && (
          <button
            type="button"
            className="bg-primary flex justify-center text-white items-center p-2 rounded-md"
            onClick={() => handleSave(index)}
            disabled={!isValid}
          >
            Save
          </button>
        )}
        {index > 0 && (
          <button
            type="button"
            className=" text-white flex justify-center items-center p-2 rounded-md ml-2"
            onClick={() => handleDelete(index)}
          >
            <DeleteIcon className="h-8 w-8 " />
          </button>
        )}
      </div>
    </div>
  ));
};

export default PerformanceReport;
