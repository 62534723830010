import React from 'react';
import NoPageFound from 'pages/common/pageNotFound';
import { Route, Routes } from 'react-router-dom';
import MeetingsTabs from './pages/meetings';

function Meetings() {
  return (
    <div>
      <Routes>
        <Route path='/*' element={<MeetingsTabs />} />

        {/* empty page when route not found */}
        <Route path='*' element={<NoPageFound />} />
      </Routes>
    </div>
  );
}

export default Meetings;
