import { Modal } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';

const TermsAndCondition = ({
  termsAndConditionsModal,
  setTermsAndConditionsModal,
}) => {
  return (
    <div>
      <Modal
        opened={termsAndConditionsModal}
        onClose={() => setTermsAndConditionsModal(false)}
        title={'Terms and conditions'}
        // withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size={'80%'}
        overflow='inside'
        className='px-8'
        classNames={{
          title: 'text-2xl font-bold mx-auto text-primary',
          body: 'scroller rounded-2xl',
        }}
      >
        <div className='p-5 '>
          <h1 className='text-lg'>Background</h1>
          <p className='mt-2 text-[#848383]'>
            These Terms and Conditions, together with any and all other
            documents referred to herein, set out the terms of use under which
            you may use this website,  www.app.apta-hr+.com (“Our Site”). Please
            read these Terms and Conditions carefully and ensure that you
            understand them. Your agreement to comply with and be bound by these
            Terms and Conditions is deemed to occur upon your first use of Our
            Site and you will be required to read and accept these Terms and
            Conditions when signing up for an Account. If you do not agree to
            comply with and be bound by these Terms and Conditions, you must
            stop using Our Site immediately.
          </p>
          {/* Definitions and Interpretation start */}
          <div className='my-2 mt-4'>
            <h1 className='text-xl font-bold '>
              <span className='mr-4'>1</span>Definitions and Interpretation
            </h1>
            <div className='p-4 pl-6'>
              <p className='text-[#848383]'>
                <span className='mr-3'>1.1</span>In these Terms and Conditions,
                unless the context otherwise requires, the following expressions
                have the following meanings:
              </p>

              <div className='pl-8 p-4 grid grid-cols-12 gap-y-3'>
                <p className='text-[17px] font-bold opacity-70 w-32 col-span-2'>
                  “Account”
                </p>
                <p className='w-full col-span-10 text-[#848383]'>
                  means an account required for a User to access certain areas
                  of Our Site
                </p>
                <p className='text-[17px] font-bold opacity-70 w-32 col-span-2'>
                  “Content”
                </p>
                <p className='w-full col-span-10 text-[#848383]'>
                  means any and all text, images, audio, video, scripts, code,
                  software, databases and any other form of information capable
                  of being stored on a computer that appears on,or forms part
                  of, Our Site
                </p>
                <p className='text-[17px] font-bold opacity-70 w-32 col-span-2'>
                  “Our Site”
                </p>
                <p className='w-full col-span-10 text-[#848383]'>
                  means this website, www.app.apta-hr+.com and a reference to
                  “Our Site” includes reference to any and all Content included
                  there in (including User content, unless expressly stated
                  otherwise);
                </p>
                <p className='text-[17px] font-bold opacity-70 w-32 col-span-2'>
                  “User”
                </p>
                <p className='w-full col-span-10 text-[#848383]'>
                  means a user of Our Site
                </p>
                <p className='text-[17px] font-bold opacity-70 w-32 col-span-2'>
                  “We/Us/Our”
                </p>
                <p className='w-full col-span-10 text-[#848383]'>
                  means Aptagrim Limited a company, whose registered address is
                  MCR Ayodhya, plot 200, level 3, Madinaguda, Hyderabad, India
                  500049.
                </p>
              </div>
            </div>
          </div>
          {/* Definitions and Interpretation ended */}
          {/* Information About Us start */}
          <div className='my-2 '>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>2</span>Information About Us
            </h1>
            <div className='pl-6 p-4 pt-2'>
              <p className='text-[#848383] my-1'>
                <span className='mr-3'>2.1</span>Our Site is operated by
                Aptagrim Limited.
              </p>
              <p className='text-[#848383] my-1'>
                <span className='mr-3'>2.2</span>
                Registered address: MCR Ayodhya, plot 200, level 3, Madinaguda,
                Hyderabad, India 500049.
              </p>
              <p className='text-[#848383] my-1'>
                <span className='mr-3'>2.3</span>
                Email address:{' '}
                <span className='text-primary'>info@aptagrim.com</span>
              </p>
            </div>
          </div>
          {/* Information About Us ended */}
          {/* Accounts start */}
          <div>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>3</span>Accounts
            </h1>
            <div className='p-4 pl-6 pt-2 '>
              {' '}
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-3 w-7'>3.1</span>
                <p>
                  Certain parts of Our Site (including the ability to submit
                  User Content) may require an Account in order to access them.
                </p>
              </p>
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-3 w-10'>3.2</span>
                When creating an Account, the information you provide must be
                accurate and complete. If any of your information changes at a
                later date, it is your responsibility to ensure that your
                Account is kept up-to-date.
              </p>
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-3 w-20'>3.3</span>We require that you choose a
                strong password for your Account, consisting of at least 8
                characters, e.g. "a combination of lowercase and uppercase
                letters, numbers, and symbols". It is your responsibility to
                keep your password safe. You must not share your Account with
                anyone else. If you believe your Account is being used without
                your permission, please contact Us immediately at 
                support@aptagrim.com. We will not be liable for any unauthorised
                use of your Account.
              </p>
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-2 w-7'>3.4</span>You must not use anyone else’s
                Account without the express permission of the User to whom the
                Account belongs.
              </p>
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-2 w-12'>3.5</span>If you wish to close your
                Account, you may do so at any time. Closing your Account will
                result in the removal of your information. Closing your Account
                will also remove access to any areas of Our Site requiring an
                Account for access.
              </p>
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-2 w-7'>3.6</span>If you close and delete your
                Account, any User Content you have submitted to Our Site will
                also be deleted.
              </p>
            </div>
          </div>
          {/* Accounts ended */}
          {/* Intellectual Property Rights and Our Site start */}
          {/* <div>
            
          </div> */}
          <div class='my-4'>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>4</span>Intellectual Property Rights and
              Our Site
            </h1>
            <div class='p-4 pl-6 pt-2 '>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-20'>4.1</span>With the exception of User
                Content, all Content on Our Site and the copyright and other
                intellectual property rights subsisting in that Content, unless
                specifically labelled otherwise, belongs to or has been licensed
                by Us. All Content (including User Content) is protected by
                applicable Indian and international intellectual property 
                Rights and User Content.
              </p>
            </div>
          </div>
          {/* Intellectual Property Rights and Our Site ends */}
          {/* Acceptable Usage Policy start */}
          <div class=' my-4'>
            <p class='text-xl font-bold'>
              <span class='mr-3'>5</span> Acceptable Usage Policy
            </p>
            <div class='p-4 pl-6 pt-2 '>
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-2 w-7'>5.1</span>You may only use Our Site in a
                manner that is lawful and Specifically:
              </p>
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.1.1</span>you must ensure that you
                comply fully with any and all applicable local, national, and
                international laws and/or regulations;
              </p>
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.1.2</span>you must not use Our Site in
                any way, or for any purpose, that is unlawful or fraudulent;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class=' w-28 -mr-4'>5.1.3</span>you must not use Our Site
                to knowingly send, upload, or in any other way transmit data
                that contains any form of virus or other malware, or any other
                code designed to adversely affect computer hardware, software,
                or data of any kind; and
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.1.4</span>you must not use Our Site in
                any way, or for any purpose, that is intended to harm any person
                or persons in any way.
              </p>{' '}
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-2 w-9'>5.2</span>When submitting User Content
                (or communicating in any other way using Our Site), you must not
                submit, communicate or otherwise do anything that:
              </p>
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.2.1</span>is sexually explicit;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.2.2</span>is obscene, deliberately
                offensive, hateful, or otherwise inflammatory;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.2.3</span>promotes violence;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.2.4</span>promotes or assists in any
                form of unlawful activity;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class=' w-14 -mr-[0px]'>5.2.5</span>discriminates against,
                or is in any way defamatory of, any person, group or class of
                persons, race, sex, religion, nationality, disability, sexual
                orientation, or age;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.2.6</span>is intended or otherwise
                likely to threaten, harass, annoy, alarm, inconvenience, upset,
                or embarrass another person;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.2.7</span>is calculated or otherwise
                likely to deceive;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-14'>5.2.8</span>is intended or otherwise
                likely to infringe (or threaten to infringe) another person’s
                right to privacy or otherwise uses their personal data in a way
                that you do not have a right to;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class=' w-14 -mr-2'>5.2.9</span>misleadingly impersonates
                any person or otherwise misrepresents your identity or
                affiliation in a way that is calculated to deceive;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-11'>5.2.10</span>implies any form of
                affiliation with Us where none exists;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-16'>5.2.11</span>infringes, or assists in
                the infringement of, the intellectual property rights
                (including, but not limited to, copyright, patents, trade marks
                and database rights) of any other party; or
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-12'>5.2.12</span>is in breach of any legal
                duty owed to a third party including, but not limited to,
                contractual duties and duties of confidence.
              </p>{' '}
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-2 w-14'>5.3</span>We reserve the right to
                suspend or terminate your Account and/or your access to Our Site
                if you materially breach the provisions of this Clause or any of
                the other provisions of these Terms and Conditions.
                Specifically, we may take one or more of the following actions:
              </p>
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.3.1</span>suspend, whether temporarily
                or permanently, your Account and/or your right to access Our
                Site;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.3.2</span>remove any of your User
                Content which violates this Acceptable Usage Policy;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.3.3</span>issue you with a written
                warning;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class=' w-12 -mr-[0]'>5.3.4</span>take legal proceedings
                against you for reimbursement of any and all relevant costs on
                an indemnity basis resulting from your breach;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.3.5</span>take further legal action
                against you as appropriate;
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.3.6</span>disclose such information to
                law enforcement authorities as required or as we deem reasonably
                necessary; and/or
              </p>{' '}
              <p class='text-[#848383]   pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>5.3.7</span>any other actions which we
                deem reasonably appropriate (and lawful).
              </p>{' '}
              <p class='text-[#848383]  my-3 flex items-start'>
                <span class='mr-2 w-10'>5.4</span>We hereby exclude any and all
                liability arising out of any actions (including, but not limited
                to those set out above) that we may take in response to breaches
                of these Terms and Conditions.
              </p>
            </div>
          </div>
          {/* Acceptable Usage Policy end */}
          {/* Links to Our Site start */}
          <div class=' my-4'>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>6</span>Links to Our Site
            </h1>
            <div class='p-4 pl-6 pt-2 '>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-7'>6.1</span>You may link to Our Site
                provided that:
              </p>
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.1.1</span>you do so in a fair and legal
                manner;
              </p>
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.1.2</span>you do not do so in a manner
                that suggests any form of association, endorsement or approval
                on Our part where none exists;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.1.3</span>you do not use any logos or
                trade marks displayed on Our Site without Our express written
                permission; and
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.1.4</span>you do not do so in a way
                that is calculated to damage Our reputation or to take unfair
                advantage of it.
              </p>{' '}
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-9'>6.2</span>
                <span>
                  {' '}
                  Framing or embedding of Our Site on other websites is not
                  permitted without Our express written permission. Please
                  contact Us
                  <span class='text-primary ml-1'>info@aptagrim.com</span> for
                  further information.
                </span>
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-7'>6.3</span>You may not link to Our Site
                from any other site the content of which contains material that:
              </p>
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.3.1</span>is sexually explicit;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.3.2</span>is obscene, deliberately
                offensive, hateful or otherwise inflammatory;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.3.3</span>promotes violence;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.3.4</span>promotes or assists in any
                form of unlawful activity;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-12'>6.3.5</span>Discriminates against, or is
                in any way defamatory of, any person, group or class of persons,
                race, sex, religion, nationality, disability, sexual
                orientation, or age;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.3.6</span>is intended or is otherwise
                likely to threaten, harass, annoy, alarm, inconvenience, upset,
                or embarrass another person;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.3.7</span>is calculated or is otherwise
                likely to deceive;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-9'>6.3.8</span>is intended or is otherwise
                likely to infringe (or to threaten to infringe) another person’s
                privacy;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-20'>6.3.9</span>misleadingly impersonates
                any person or otherwise misrepresents the identity or
                affiliation of a particular person in a way that is calculated
                to deceive (obvious parodies are not included in this definition
                provided that they do not fall within any of the other
                provisions of this sub-Clause 9.4);
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-12'>6.3.10</span>implies any form of
                affiliation with Us where none exists;
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-16'>6.3.11</span>infringes, or assists in
                the infringement of, the intellectual property rights
                (including, but not limited to, copyright, patents, trade marks
                an database rights) of any other party; or
              </p>{' '}
              <p class='text-[#848383]  pl-8 my-3 flex items-start'>
                <span class='mr-2 w-12'>6.3.12</span>is made in breach of any
                legal duty owed to a third party including, but not limited to,
                contractual duties and duties of confidence.
              </p>{' '}
            </div>
          </div>
          {/* Links to Our Site Ended */}
          {/* Advertising started */}
          <div class=' my-4'>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>7</span>Advertising
            </h1>

            <div class='p-4 pl-6 pt-2'>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-9'>7.1</span>We may feature advertising on
                Our Site and we reserve the right to display advertising on the
                same page as any User Content.
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-9'>7.2</span>You agree that you will not
                attempt to remove or hide any advertising using HTML/CSS or by
                any other method.
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-9'>7.3</span>We are not responsible for the
                content of any advertising on Our Site.
              </p>
            </div>
            {/* Advertising Ended */}
            {/* Disclaimers and Legal Rights started */}

            <div class='my-4'>
              <h1 className='text-xl font-bold'>
                <span className='mr-4'>8</span> Disclaimers and Legal Rights
              </h1>
              <div class='p-4 pl-6 pt-2'>
                <p class='text-[#848383] my-3 flex items-start'>
                  <span class='mr-2 w-12'>8.1</span>Nothing on Our Site
                  constitutes advice on which you should rely. It is provided
                  for general information purposes only. Professional or
                  specialist advice should always be sought before taking any
                  action relating to the job match reports and advice produced
                  on Our site.
                </p>
                <p class='text-[#848383] my-3 flex items-start'>
                  <span class='mr-2 w-12'>8.2</span>We make reasonable efforts
                  to ensure that Our Content on Our Site is complete, accurate,
                  and up-to-date. We do not, however, make any representations,
                  warranties or guarantees (whether express or implied) that
                  such Content is complete, accurate, or up-to-date.
                </p>
                <p class='text-[#848383] my-3 flex items-start'>
                  <span class='mr-2 w-12'>8.3</span>We are not responsible for
                  the content or accuracy of, or for any opinions, views, or
                  values expressed in User Content. Any such opinions, views or
                  values are those of the relevant User and do not reflect Our
                  opinions, views, or values in any way.
                </p>
              </div>
            </div>
          </div>
          {/* Disclaimers and Legal Rights ended */}
          {/* Our Liability started */}
          <div class='my-4'>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>9</span> Our Liability
            </h1>
            <div class='p-4 pl-6 pt-2'>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-20'>9.1</span>To the fullest extent
                permissible by law, we accept no liability to any User for any
                loss or damage, whether foreseeable or otherwise, in contract,
                tort (including negligence), for breach of statutory duty, or
                otherwise, arising out of or in connection with the use of (or
                inability to use) Our Site or the use of or reliance upon any
                Content (whether it is provided by Us or whether it is User
                Content) included on Our Site.
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='-mr-3 w-20'>9.2</span>To the fullest extent
                permissible by law, we exclude all representations, warranties,
                and guarantees (whether express or implied) that may apply to
                Our Site or any Content (including User Content) included on Our
                Site.
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='-mr-0 w-20'>9.3</span>Our Site is intended for
                non-commercial use only. If you are a business user, we accept
                no liability for loss of profits, sales, business or revenue;
                loss of business opportunity, good will or reputation; loss of
                anticipated savings; business interruption; or for any indirect
                or consequential loss or damage.
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-24'>9.4</span>We exercise all reasonable
                skill and care to ensure that Our Site is free from viruses and
                other malware. Subject to sub-Clause 12.3, we accept no
                liability for any loss or damage resulting from a virus or other
                malware, a distributed denial of service attack, or other
                harmful material or event that may adversely affect your
                hardware, software, data or other material that occurs as a
                result of your use of Our Site (including the downloading of any
                Content(including User Content) from it) or any other site
                referred to on Our Site.
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='-mr-0 w-20'>9.5</span>We neither assume nor accept
                responsibility or liability arising out of any disruption or
                non-availability of Our Site resulting from external causes
                including,but not limited to, ISP equipment failure, host
                equipment failure, communications network failure, natural
                events, acts of war, or legal restrictions and censorship.
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='-mr-0 w-24'>9.6</span>Nothing in these Terms and
                Conditions excludes or restricts Our liability for fraud or
                fraudulent misrepresentation, for death or personal injury
                resulting from negligence, or for any other forms of liability
                which cannot be excluded or restricted by law. For full details
                of consumers’ legal rights, including those relating to digital
                content, please contact your local Citizens’ Advice Bureau or
                Trading Standards Office.
              </p>
            </div>
          </div>
          {/* Our Liability started ended */}
          {/* Communications from Us started */}
          <div class=' my-4'>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>10</span> Communications from Us
            </h1>
            <div class='p-4 pl-6 pt-2'>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-16'>10.1</span>If you have an Account, we
                may from time to time send you important notices by email. Such
                notices may relate to matters including, but not limited to,
                service changes, changes to these Terms and Conditions, and
                changes to your Account.
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-14'>10.2</span>We will never send you
                marketing emails of any kind without your express consent. If
                you do give such consent, you may opt out at any time. Any and
                all marketing emails sent by Us include an unsubscribe link.
              </p>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-10'>10.3</span>
                <span>
                  For questions or complaints about communications from Us
                  (including, but not limited to marketing emails), please
                  contact Us via{' '}
                  <span class='text-primary ml-1'>info@aptagrim.com</span>{' '}
                </span>
              </p>
            </div>
          </div>
          {/* Communications from Us ended */}
          {/* Changes to these Terms and Conditions started */}
          <div className='my-4'>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>11</span> Changes to these Terms and
              Conditions
            </h1>
            <div className='p-4 pl-6 pt-2'>
              <p className='text-[#848383] my-3 flex items-start'>
                <span className='mr-2 w-20'>11.1</span>We may alter these Terms
                and Conditions at any time. If we do so, details of the changes
                will be highlighted at the top of this page. Any such changes
                will become binding on you upon your first use of Our Site after
                the changes have been implemented. You are therefore advised to
                check this page from time to time.
              </p>
              <p className='text-[#848383] my-3 flex items-start'>
                <span className='-mr-0 w-14'>11.2</span>In the event of any
                conflict between the current version of these Terms and
                Conditions and any previous version(s), the provisions current
                and in effect shall prevail unless it is expressly stated
                otherwise.
              </p>
            </div>
          </div>
          {/* Changes to these Terms and Conditions ended */}
          {/* Cancellation Fees start */}
          <div class='my-4'>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>12</span> Cancellation Fees
            </h1>
            <div class='p-4 pl-6 pt-2'>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-9'>12.1</span>There is no cancellation fee,
                however there is no partial refunds.
              </p>
            </div>
          </div>
          {/* Cancellation Fees end */}
          {/* Contacting Us start */}
          <div class=' my-4'>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>13</span> Contacting Us
            </h1>
            <div class='p-4 pl-6 pt-2'>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-20'>13.1</span>We may alter these Terms and
                Conditions at any time. If we do so, details of the changes will
                be highlighted at the top of this page. Any such changes will
                become binding on you upon your first use of Our Site after the
                changes have been implemented. You are therefore advised to
                check this page from time to time.
              </p>
            </div>
          </div>
          {/* Contacting Us end */}
          {/* Law and Jurisdiction start */}
          <div class=' my-4'>
            <h1 className='text-xl font-bold'>
              <span className='mr-4'>14</span> Law and Jurisdiction
            </h1>
            <div class='p-4 pl-6 pt-2'>
              <p class='text-[#848383] my-3 flex items-start'>
                <span class='mr-2 w-12'>14.1</span>These Terms and Conditions,
                and the relationship between you and Us (whether contractual or
                otherwise) shall be governed by the laws of India.
              </p>
            </div>
          </div>
          {/* Law and Jurisdiction ended */}
          <div className='flex items-end justify-center my-8 '>
            <button
              className='w-28'
              onClick={() => {
                setTermsAndConditionsModal(false);
              }}
            >
              <PrimaryButton>Back</PrimaryButton>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TermsAndCondition;
