import { AuthContext } from 'context/authContext';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetperformancereportInTeamsProfile() {
  const { authState } = useContext(AuthContext);
  const { id } = useParams();

  return useQuery(['performance-report-in-admin'], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.PERFORMANCE_BY_ADMIN, {
        employee_id: id,
        is_draft: 'False',

        employee__reports_to_id: authState?.is_admin
          ? undefined
          : authState?.user_id,
        employee__company_id: authState?.company_id,
      })
    );

    return response.data;
  });
}
