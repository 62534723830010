import {
  Modal,
  Avatar,
  Tooltip,
  Select,
  TextInput,
  Textarea,
} from '@mantine/core';

import dayjs from 'dayjs';

import { ReactComponent as MobileIcon } from 'assets/icons/mobileIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mailIcon.svg';
import { ReactComponent as GradeIcon } from 'assets/icons/gradeIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';

// hooks
//get datas
// import UsegetskillsfromSettings from '../hooks/usegetskills';
import UsegetgradesfromSettings from '../hooks/useGetgradesfromsettings';
import useGetApparisal from '../hooks/useGetapparisal';

import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';
import { useEffect } from 'react';

const ViewApparisalModal = ({
  ViewApparisalModalOpened,
  setViewApparisalModalOpened,
  apparisalDataEmployee,
}) => {
  const { control, setValue } = useForm({ mode: 'onChange' });

  const gradesData = UsegetgradesfromSettings();
  const getapparisaldata = useGetApparisal(apparisalDataEmployee?.id);

  useEffect(() => {
    setValue('current_salary', getapparisaldata?.data?.data[0]?.current_salary);
    setValue('hiked_salary', getapparisaldata?.data?.data[0]?.hiked_salary);
    setValue('description', getapparisaldata?.data?.data[0]?.description);
    setValue(
      'current_grade_id',
      `${getapparisaldata?.data?.data[0]?.current_grade}`
    );
    setValue(
      'hike_percentage',
      getapparisaldata?.data?.data[0]?.hike_percentage
    );
    // eslint-disable-next-line
  }, [getapparisaldata?.data?.data]);

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[850px]'>
        <LeftArrowIcon
          onClick={() => setViewApparisalModalOpened(false)}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className=''>Appraisal report</p>
      </div>
    );
  };

  return (
    <Modal
      opened={ViewApparisalModalOpened}
      onClose={() => {
        setViewApparisalModalOpened(false);
      }}
      title={customTitle()}
      withCloseButton={false}
      // title='Creating performance report'
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      centered
      overlayOpacity={0.5}
      transitionDuration={1}
      size={'70%'}
      overflow='inside'
      className='px-8'
      classNames={{
        title: 'text-lg font-semibold ',

        body: 'scroller',
      }}
    >
      <div className='bg-[#FFF8F6] rounded-md p-4 mx-4 '>
        <div className='flex  w-full '>
          <Avatar
            radius='xl'
            size='lg'
            color='#FB7C51'
            src={apparisalDataEmployee?.employee__profile_picture}
          >
            {apparisalDataEmployee?.employee__user__first_name[0]?.toUpperCase()}
          </Avatar>
          <div className='w-full pl-5'>
            <div className='flex justify-between items-center  w-full '>
              <h1 className='text-[#000000] font-bold text-normal'>
                {apparisalDataEmployee?.employee__user__first_name}
                <span className='text-primary font-normal text-sm'></span>
              </h1>
              <div className='flex items-center gap-2 mb-1'>
                <p className='font-normal text-sm'>
                  {/* {dayjs.apparisalEmployeData.segment[0].format('MMM YYYY')} */}
                  {`${dayjs(apparisalDataEmployee?.segment_from).format(
                    'DD MMM YY'
                  )} - ${dayjs(apparisalDataEmployee?.segment_to).format(
                    'DD MMM YY'
                  )}`}
                </p>
                <p className='text-primary font-normal text-base'>
                  {apparisalDataEmployee?.review_type__name}
                </p>
              </div>
            </div>
            <div className='flex items-center justify-between  '>
              <p className='text-[#A8A8A8] font-normal text-base'>
                {apparisalDataEmployee?.employee__designation__name}
              </p>
              <div className=' flex  items-center gap-2'>
                <p className='flex items-center text-[#828282] font-normal text-sm'>
                  <MobileIcon className='mr-2 h-4 w-4' />
                  {apparisalDataEmployee?.employee__user__mobile_number}
                </p>
                <Tooltip
                  label={apparisalDataEmployee?.employee__user__email}
                  classNames={{
                    body: 'bg-primary',
                  }}
                  radius='md'
                >
                  <p className='flex items-center text-[#828282] truncate font-normal text-sm'>
                    <MailIcon className='mr-2 h-4 w-4' />
                    {apparisalDataEmployee?.employee__user__email.slice(0, 30) +
                      '...'}
                  </p>
                </Tooltip>
                <p className='flex items-center text-[#828282] font-normal text-sm'>
                  <LocationIcon className='mr-2 h-4 w-4' />
                  {apparisalDataEmployee?.employee__department__name}
                </p>
                <p className='flex items-center text-[#828282] font-normal text-sm'>
                  <GradeIcon className='mr-2 h-4 w-4' />
                  {apparisalDataEmployee?.employee__grade__name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5 relative mx-4'>
        <from>
          <div className='grid grid-cols-3 gap-3'>
            <div>
              <Controller
                control={control}
                name='current_salary'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    size='md'
                    type='number'
                    // value={getapparisaldata?.data?.data?.current_salary}
                    readOnly
                    label='Gross Annual Salary'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-[#8E8E8E] text-sm',
                    }}
                    {...field}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name='hike_percentage'
                rules={{
                  required: 'Required',
                  min: {
                    value: 0,
                    message: 'min value is 0',
                  },
                  max: {
                    value: 100,
                    message: 'max value is 100',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    size='md'
                    type='number'
                    label='Hike percentage'
                    readOnly
                    classNames={{
                      input: 'border-primary',
                      label: 'text-[#8E8E8E] text-sm',
                    }}
                    {...field}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name='hiked_salary'
                render={({ field }) => (
                  <TextInput
                    {...field}
                    // value={parseFloat(`${newSalary}`).toFixed(2)}
                    size='md'
                    readOnly
                    type='number'
                    label='New gross annual salary'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-[#8E8E8E] text-sm',
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name='current_grade_id'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <Select
                    size='md'
                    label='Grade'
                    rightSection={<DownArrow />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    data={
                      gradesData?.data?.data?.map((eachGrade) => {
                        return {
                          value: `${eachGrade.id}`,
                          label: eachGrade.name,
                        };
                      }) || []
                    }
                    classNames={{
                      input: 'border-primary',
                      label: 'text-[#8E8E8E] text-sm',
                    }}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className='mt-8'>
            <Controller
              control={control}
              // name='name'
              name='description'
              rules={{
                required: 'Required',
              }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  size='md'
                  readOnly
                  label='Description'
                  placeholder='Type here'
                  classNames={{
                    input: 'h-[75px] border-primary',
                    label: 'text-[#000] text-normal font-bold',
                  }}
                />
              )}
            />
          </div>
          <div className='flex justify-center items-center my-7'>
            <button
              type='button'
              onClick={() => setViewApparisalModalOpened(false)}
            >
              <PrimaryButton>Close</PrimaryButton>
            </button>
          </div>
        </from>
      </div>
    </Modal>
  );
};
export default ViewApparisalModal;
