import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useEmploymentTypes() {
  return useQuery('settings-employment-types', async () => {
    const response = await makeApiRequest.get(
      apiEndPoints.SETTINGS_EMPLOYMENT_TYPES
    );
    return response?.data;
  });
}
