import React, { useContext } from 'react';
import { Modal } from '@mantine/core';

import PrimaryButton from './primaryButton';
import SecondaryButton from './secondaryButton';
import { AuthContext } from 'context/authContext';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoutIlls } from 'assets/images/logoutIlls.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/crossIcon.svg';

export default function LogoutModal({ isLogoutModalOpen, setLogoutModalOpen }) {
  const handleModalClose = () => {
    setLogoutModalOpen(false);
  };

  const navigate = useNavigate();

  const { authDispatch } = useContext(AuthContext);

  const handelLogout = () => {
    authDispatch({
      type: 'LOGOUT',
    });
    navigate(`/auth/login`, { replace: true });
  };
  return (
    <div>
      <Modal
        opened={isLogoutModalOpen}
        onClose={() => handleModalClose()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size='lg'
        overflow='inside'
        className='px-8'
        classNames={{
          title: 'text-lg font-semibold ',
          body: 'scroller',
        }}
      >
        <div>
          <CrossIcon
            className='w-10 ml-auto cursor-pointer'
            onClick={() => handleModalClose()}
          />
        </div>
        <div>
          <LogoutIlls className='mx-auto' />
        </div>
        <p className='text-center text-xl text-neutralsBlack pt-10'>
          Are you sure you want to <span className='font-bold'>Logout</span>?
        </p>
        <div className='flex justify-center space-x-6 mt-8 mb-4'>
          <button onClick={() => handleModalClose()} className='w-32'>
            <PrimaryButton>No</PrimaryButton>
          </button>
          <button className='w-32' onClick={() => handelLogout()}>
            <SecondaryButton>Yes</SecondaryButton>
          </button>
        </div>
      </Modal>
    </div>
  );
}
