import { Input, Select, Tabs } from '@mantine/core';
import Header from 'components/Header';

import { ReactComponent as SearchIcon } from '../../../../assets/icons/searchIcon.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filterIcon.svg';
import { ReactComponent as DownArrow } from '../../../../assets/icons/downArrow.svg';
// import { DatePicker } from '@mantine/dates';
import EmployeePerformanceTable from './components/employeeperformancetable';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from 'context/authContext';
import EmployeeSkillMatrix from '../employeeSkillMatrix/employeeSkillMatrix';
import useGetperformancereportemployee from './hooks/useGetperformancereports';

const EmployeePerformance = () => {
  const [performanceYearFilter, setPerformanceYearFilter] = useState(null);
  const [performanceSearch, setPerformanceSearch] = useState(null);
  const { authState } = useContext(AuthContext);
  const { employee_id } = authState;

  const [activeTab, setActiveTab] = useState(0);
  const performanceReports = useGetperformancereportemployee({
    employeeId: employee_id,
  });
  let minYear = performanceReports?.data?.years[1];
  let maxYear = performanceReports?.data?.years[0];
  const performanceYears = [];
  if (minYear === maxYear) {
    performanceYears.push({ value: minYear, label: minYear });
  } else {
    for (let i = minYear; i <= maxYear; i++) {
      performanceYears.push({ value: minYear, label: minYear });
      minYear++;
    }
  }

  return (
    <div className='px-5'>
      <div className='flex justify-between items-center mt-6'>
        <p className='text-xl text-A8A8A8'>
          My analytics &#60;{' '}
          <span className='text-gray font-semibold'>Performance</span>
        </p>
        <Header />
      </div>
      <div className='mt-8 relative'>
        <div>
          <Tabs
            tabPadding={21}
            onTabChange={setActiveTab}
            classNames={{
              tabsListWrapper: 'border-0',
              tabActive: 'border-2 border-primary font-semibold text-18',
              tabControl: 'text-A8A8A8 leading-5 px-0 mr-4',
              tabLabel: 'text-18 text-gray',
            }}
          >
            <Tabs.Tab label='Performance reports'>
              <EmployeePerformanceTable
                performanceYearFilter={performanceYearFilter}
                performanceSearch={performanceSearch}
                employee_id={employee_id}
              />
            </Tabs.Tab>
            <Tabs.Tab label='Skill matrix'>
              <EmployeeSkillMatrix />
            </Tabs.Tab>
          </Tabs>
        </div>

        {/* search and filter */}
        <div className='absolute top-2 right-0'>
          {activeTab === 0 ? (
            <div className='flex items-center space-x-4'>
              <Input
                icon={<SearchIcon />}
                placeholder='Search'
                value={performanceSearch}
                onChange={(e) => setPerformanceSearch(e.target.value)}
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-36 bg-light-bg border-none',
                }}
              />

              <Select
                placeholder='Year'
                icon={<FilterIcon />}
                rightSection={<DownArrow />}
                // inputFormat='MM/YYYY'
                // labelFormat='MM/YYYY'
                data={
                  performanceYears[0]?.value
                    ? performanceYears
                    : [
                        {
                          value: 'no data',
                          label: 'No data',
                          disabled: true,
                        },
                      ]
                }
                clearable
                value={performanceYearFilter}
                onChange={setPerformanceYearFilter}
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-36 bg-light-bg border-none',
                }}
                styles={{
                  rightSection: { pointerEvents: 'none' },
                  monthPickerControlActive: { color: 'red' },
                  yearPickerControlActive: {
                    color: 'red',
                    backgroundcolor: '#ffffff',
                  },
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EmployeePerformance;
