import React, { useEffect, useState } from 'react';
import { Modal, Select, TextInput } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';

import PrimaryButton from 'components/primaryButton';

import useUpdateFamilyMembersDetails from '../hooks/useUpdateFamilyMember';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';

export default function EditEmployeeFamilyDetailsModal({
  isModalOpen,
  setIsModalOpen,
  familyData,
}) {
  const [relationshipData, setRelationshipData] = useState([
    { value: 'Father', label: 'Father' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Sister', label: 'Sister' },
  ]);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  //   custom title for modal
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>Edit family details</p>
      </div>
    );
  };

  const defaultValues = {
    id: '',
    employee_id: '',
    name: '',
    relationship: '',
    mobile_number: '',
    alternate_number: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({ defaultValues });

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const updateFamilyMembersMutation = useUpdateFamilyMembersDetails(closeModal);

  const onSubmit = (data) => {
    updateFamilyMembersMutation.mutate(data);
  };

  useEffect(() => {
    reset({
      id: familyData?.id,
      employee_id: familyData?.employee_id,
      name: familyData?.name,
      relationship: familyData?.relationship,
      mobile_number: familyData?.mobile_number,
      alternate_number: familyData?.alternate_number,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyData]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-2',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-wrap'>
            <div className='w-1/2 py-2 px-4'>
              <Controller
                control={control}
                name='name'
                rules={{
                  required: 'required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Name*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('name')}
            </div>
            <div className='w-1/2 py-2 px-4'>
              <Controller
                control={control}
                name='relationship'
                rules={{
                  required: 'required',
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label='Relationship* '
                    data={relationshipData}
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) => {
                      const item = { value: query, label: query };
                      setRelationshipData((current) => [...current, item]);
                      return item;
                    }}
                    size='md'
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    rightSection={<DownArrow className='mt-1' />}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('relationship')}
            </div>
            <div className='w-1/2 py-2 px-4'>
              <Controller
                control={control}
                name='mobile_number'
                rules={{
                  required: 'required',
                  pattern: {
                    value: /^[0-9]+[0-9]*$/,
                    message: 'Only numbers are allowed',
                  },
                  // minLength: {
                  //   value: 6,
                  //   message: 'minimum of 6 digits',
                  // },
                  maxLength: {
                    value: 16,
                    message: 'maximum of 16 digits only',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Contact*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('mobile_number')}
            </div>
            <div className='w-1/2 py-2 px-4'>
              <Controller
                control={control}
                name='alternate_number'
                rules={{
                  pattern: {
                    value: /^[0-9]+[0-9]*$/,
                    message: 'Only numbers are allowed',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Alternate contact'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('alternate_number')}
            </div>
          </div>
          <div className='flex justify-center w-full mt-8'>
            <button className='w-28'>
              <PrimaryButton isLoading={updateFamilyMembersMutation?.isLoading}>
                Save
              </PrimaryButton>
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
