import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as NoTeamMemberImg } from 'assets/images/noTeamMember2.svg';
import useGetTeamMembersLeaves from '../hooks/useGetTeamMembersLeaves';
import EmployeeCard from './EmployeeCard';
import { AuthContext } from 'context/authContext';

export default function Leaves() {
  const [filteredTeamMembersData, setFilteredTeamMemberData] = useState([]);

  const { authState } = useContext(AuthContext);
  const allTeamMembersLeaves = useGetTeamMembersLeaves();

  useEffect(() => {
    let newDataArray = allTeamMembersLeaves?.data?.data?.filter(
      (employee) => employee?.employee_id !== authState?.employee_id
    );
    setFilteredTeamMemberData(newDataArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTeamMembersLeaves?.data?.data]);

  return (
    <div className='bg-white shadow-four rounded-[10px] h-[350px] relative'>
      <p className='text-18 font-semibold text-222222 py-5 px-9'>
        Team members on leave
      </p>

      {filteredTeamMembersData?.length === 0 ||
      filteredTeamMembersData === undefined ? (
        <div className='flex flex-col items-center absolute left-1/2 top-1/2 -translate-y-1/2	-translate-x-1/2'>
          <NoTeamMemberImg />
          <p className='text-gray2'>All your team members are present</p>
        </div>
      ) : (
        <div className='h-[80%] overflow-y-scroll scroller px-5'>
          <div className='space-y-6'>
            {filteredTeamMembersData?.map((employee) => {
              return <EmployeeCard key={employee?.id} employee={employee} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
}
