import { Avatar, Modal, MultiSelect, TextInput } from '@mantine/core';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow2.svg';

import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { ErrorToast, SuccessToast } from 'services/toasterService';
// import { ChatContext } from 'context/chatContext';

const CreateGroupModal = ({ createGroupModal, setCreateGroupModal }) => {
  // const queryClient = useQueryClient();
  // const { chatState, chatDispatch } = useContext(ChatContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
    // watch,
    reset,
    // setValue,
  } = useForm({ mode: 'onchange' });

  useEffect(() => {
    reset({});
    // eslint-disable-next-line
  }, [createGroupModal]);

  const defaultValues = {
    name: '',
    group_members: '',
  };

  const closeModal = () => {
    setCreateGroupModal(false);
    reset({ defaultValues });
  };

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 my-4'>
        <LeftArrowIcon
          onClick={() => {
            setCreateGroupModal(false);
            closeModal();
          }}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>{'Create Group'}</p>
      </div>
    );
  };

  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const groupMembersList = useQuery(['chat-group-members'], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.USERS_LIST, {})
    );

    return response.data;
  });

  const createGroupMutation = useMutation(
    async (data) => {
      const response = await makeApiRequest.post(
        apiEndPoints.CREATE_GROUP,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        // if (data?.room_info?.room_name) {
        //   const newChatList = [
        //     data?.room_info,
        //     ...chatState?.conversationsList,
        //   ];

        //   chatDispatch({
        //     type: 'SET_CONVERSATIONS',
        //     payload: newChatList,
        //   });
        // }
        SuccessToast({ text: data?.message });
        closeModal();
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );

  const onSubmitCreateGroupForm = (data) => {
    createGroupMutation.mutate(data);
  };

  // const changeHandler = (data) => {
  //   setValue('group_members', data);
  // };

  const getDropdownData = () => {
    const dataArray = [];
    groupMembersList?.data?.data?.forEach((employee) => {
      dataArray.push({
        image: employee?.profile_picture,
        label: employee?.username,
        value: `${employee?.user_id}`,
        description: employee?.email,
      });
    });
    return dataArray;
  };

  //   custom dropdown item for select
  const SelectItem = ({
    activeStatus,
    value,
    label,
    image,
    name,
    description,
    ...others
  }) => {
    return (
      <div className='flex px-5 '>
        <div {...others} className='flex items-center py-2 cursor-pointer'>
          <Avatar src={image} color='red'>
            {label[0]?.toUpperCase()}
          </Avatar>
          <div className='pl-1 '>
            <p className='text-18 '>{label} </p>
            <p className='text-xs text-gray3 pt-1 '>{description} </p>
          </div>
        </div>
        {/* <p className='ml-auto'>
          {' '}
          {activeStatus ? (
            <span className='ml-3 text-[12px] text-[#28B446] bg-[#c1f3cc] py-1 px-2 rounded-lg '>
              Active
            </span>
          ) : (
            <span className='ml-3 text-[12px] text-[#D0342C] bg-[#f7bab6] py-1 px-2 rounded-lg'>
              Inactive
            </span>
          )}
        </p> */}
      </div>
    );
  };

  return (
    <Modal
      opened={createGroupModal}
      onClose={() => {
        setCreateGroupModal(false);
      }}
      title={customTitle()}
      withCloseButton={false}
      closeOnClickOutside={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size={600}
      radius='md'
      overflow='inside'
      transitionDuration={100}
      classNames={{
        title: 'w-full  font-semibold text-18',
        modal: 'px-10',
        body: 'scroller -mr-4 ',
      }}
    >
      <form className='px-4' onSubmit={handleSubmit(onSubmitCreateGroupForm)}>
        <div>
          <Controller
            control={control}
            name='name'
            rules={{
              required: 'Required',
              pattern: {
                value: /^[ A-Za-z0-9_@.#&+(-)]*$/,
                message: 'Input must be an alphabets or integers',
              },
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                label='Group Name'
                size='md'
                classNames={{
                  input: 'border-primary',
                  label: 'text-neutralsGrey text-sm',
                }}
              />
            )}
          />
          {getErrorMessage('name')}
        </div>

        <div className='w-full py-2'>
          <Controller
            control={control}
            name='group_members'
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <MultiSelect
                {...field}
                size='md'
                itemComponent={SelectItem}
                label='Add Group Members'
                placeholder='Search or select'
                searchable
                data={
                  !groupMembersList?.data?.data?.length ? [] : getDropdownData()
                }
                rightSection={<DownArrowIcon />}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                classNames={{
                  input: 'border-primary',
                  label: 'text-neutralsGrey text-sm',
                }}
              />
            )}
          />
          {getErrorMessage('group_members')}
        </div>

        {/* <div className='flex items-center gap-2'>
          {showprofilesData?.map((eachprofile) => {
            return (
              <Tooltip
                label={
                  <div>
                    <p className='text-gray'>
                      Name: {eachprofile?.user__first_name}
                    </p>
                    <p className='text-gray'>
                      Department: {eachprofile?.department__name}
                    </p>
                  </div>
                }
                wrapLines
                classNames={{
                  body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                }}
                radius='md'
              >
                <Avatar
                  src={eachprofile?.profile_picture}
                  radius='lg'
                  color='#FB7C51'
                  className='w-10 h-10'
                >
                  {eachprofile?.user__first_name?.substring(0, 1)}
                </Avatar>
              </Tooltip>
            );
          })} 
        </div>*/}
        <div className='flex justify-center items-center my-4'>
          <button>
            <PrimaryButton>Create</PrimaryButton>
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateGroupModal;
