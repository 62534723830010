import { Avatar, Table, Tooltip } from "@mantine/core";

import { ReactComponent as EyeIcon } from "assets/icons/eyeIcon.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/downloadIcon.svg";
import { ReactComponent as EditIcon } from "assets/icons/editIcon.svg";
import { ReactComponent as NoPerformanceReports } from "assets/icons/noPerfromanceReports.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/deleteIcon2.svg";
import useGetperformancereport from "../hooks/useGetperformancereports";
import dayjs from "dayjs";
import { useState } from "react";
import ViewPerformanceModal from "./viewPerformancereportmodal";
import CreateAndEditEmployeeModal from "./createAndEditEmployeeModal";
import useDeletePerformance from "../hooks/useDeletePerformance";
import ViewOjectivePerformance from "./viewObjectivePerformance";
const ActiveReports = ({
  performanceSearch,
  performanceDraftFilter,
  performanceYearFilter,
}) => {
  const [employeePerformancemodalOpened, setEmployeePerformancemodalOpened] =
    useState(false);
  const [employeeId, setEmployeeId] = useState(null);

  const [reportModal, setReportModal] = useState(false);
  const [performanceReport, setPerformanceReport] = useState(null);

  const performanceReports = useGetperformancereport(
    null,
    performanceSearch,
    performanceDraftFilter,
    performanceYearFilter
  );
  const deletPerformance = useDeletePerformance();
  console.log(performanceReport, "drgfdg");
  const rows = performanceReports?.data?.data.map((eachPerformance) => (
    <>
      <tr>
        <td>
          <div className="flex justify-center items-center">
            <Avatar
              radius="xl"
              color="#FB7C51"
              src={eachPerformance?.employee__profile_picture}
            >
              {eachPerformance?.employee__user__first_name
                .substring(0, 1)
                ?.toUpperCase()}
            </Avatar>
            <div className="pl-3 text-[#848383] ">
              {eachPerformance?.employee__user__first_name.length > 30 ? (
                <Tooltip
                  label={eachPerformance?.employee__user__first_name}
                  classNames={{
                    body: "bg-white text-gray2 shadow-tooltip pr-3 z-0",
                  }}
                  radius="md"
                >
                  <p className="text-base  w-52 truncate">
                    {eachPerformance?.employee__user__first_name}
                  </p>
                </Tooltip>
              ) : (
                <p className="text-base w-52">
                  {eachPerformance?.employee__user__first_name}
                </p>
              )}
              <p className="text-normal">
                {eachPerformance?.employee__designation__name}
              </p>
            </div>
          </div>
        </td>
        <td>
          <div className=" text-[#848383] text-base mt-4">
            {eachPerformance?.employee__user__email.length > 29 ? (
              <Tooltip
                label={eachPerformance?.employee__user__email}
                classNames={{
                  body: "bg-white text-gray2 shadow-tooltip",
                }}
                radius="md"
              >
                <p className="self-start mr-5 w-52 truncate">
                  {eachPerformance?.employee__user__email}
                </p>
              </Tooltip>
            ) : (
              <p className="self-start mr-5 w-52 ">
                {eachPerformance?.employee__user__email}
              </p>
            )}
            <p>{eachPerformance?.employee__user__mobile_number}</p>
          </div>
        </td>
        <td>
          <div className="text-center text-[#848383] text-base">
            {eachPerformance?.employee__employee_id}
          </div>
        </td>
        <td>
          <div className="text-center text-[#848383] text-lg">
            {eachPerformance?.employee__department__name}
          </div>
        </td>
        <td>
          <div className="text-[#848383] text-normal  text-base mb-7 flex flex-col items-center justify-center">
            <p className="m-0 text-center">
              {eachPerformance?.review_type__name}
            </p>
            <p>
              {" "}
              {`${dayjs(eachPerformance?.segment_from).format(
                " MMM YYYY"
              )} - ${dayjs(eachPerformance?.segment_to).format(" MMM YYYY")}`}
            </p>
          </div>
        </td>

        <td>
          <div className="text-center text-[#848383] text-base">
            {`${dayjs(eachPerformance?.created_date).format("DD MMM YYYY")}`}
          </div>
        </td>
        <td>
          <div
            className={
              eachPerformance?.is_seen
                ? "text-center text-[#28B446] text-lg"
                : "text-center text-[#FF9F2D] text-lg"
            }
          >
            {eachPerformance?.is_seen ? "Acknowledged" : "Unseen "}
          </div>
        </td>

        <td>
          <div className="flex items-center justify-evenly gap-2">
            <EyeIcon
              className="cursor-pointer"
              onClick={() => {
                setEmployeePerformancemodalOpened(true);
                setEmployeeId(eachPerformance?.id);
                setPerformanceReport(eachPerformance);
              }}
            />

            {eachPerformance?.is_draft ? (
              <EditIcon
                className="cursor-pointer"
                onClick={() => {
                  setReportModal(true);
                  setEmployeeId(eachPerformance?.id);
                }}
              />
            ) : null}
            <button
              onClick={() => {
                deletPerformance.mutate({
                  id: eachPerformance?.id,
                });
              }}
            >
              {" "}
              <DeleteIcon className="cursor-pointer" />
            </button>
          </div>
        </td>
      </tr>
    </>
  ));

  // {employeePerformancemodalOpened }

  return (
    <>
      <div className="mt-5 bg-[#ffffff] min-h-[80vh] rounded-2xl  shadow-3xl">
        {performanceReports?.data?.data.length === 0 ? (
          <div className="flex justify-center items-center  flex-col bg-[#ffffff] min-h-[70vh]  rounded-2xl shadow-3xl">
            <NoPerformanceReports />
            <p className="mt-2 text-[#535353]">
              Seems like you have no performance reports yet!
            </p>
          </div>
        ) : (
          <div className="shadow-lg border border-8B8B8B border-opacity-10  rounded-xl bg-white my-4 h-[73vh] 2xl:h-[73vh] overflow-y-scroll scroller">
            <Table verticalSpacing="md" highlightOnHover>
              <thead>
                <tr className="sticky top-0 z-20">
                  <th
                    className="bg-[#FFE8E1] rounded-tl-xl "
                    style={{ textAlign: "center" }}
                  >
                    Name & designation
                  </th>
                  <th className="bg-[#FFE8E1] w-5">Contact details</th>
                  <th className="bg-[#FFE8E1]" style={{ textAlign: "center" }}>
                    Employee ID
                  </th>
                  <th className="bg-[#FFE8E1]" style={{ textAlign: "center" }}>
                    Dept.
                  </th>
                  <th className="bg-[#FFE8E1]" style={{ textAlign: "center" }}>
                    Review type
                  </th>
                  <th className="bg-[#FFE8E1]" style={{ textAlign: "center" }}>
                    Date
                  </th>
                  <th className="bg-[#FFE8E1]" style={{ textAlign: "center" }}>
                    Status
                  </th>
                  <th
                    className="bg-[#FFE8E1] rounded-tr-xl"
                    style={{ textAlign: "center" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className=" w-full max-h-20 border-none">{rows}</tbody>
            </Table>
          </div>
        )}
      </div>
      {performanceReports?.data?.data.length === 0 ? null : (
        <>
          <ViewPerformanceModal
            employeePerformancemodalOpened={employeePerformancemodalOpened}
            setEmployeePerformancemodalOpened={
              setEmployeePerformancemodalOpened
            }
            employeeId={employeeId}
            setEmployeeId={setEmployeeId}
            employeePerformanceReport={performanceReport}
          />

          <CreateAndEditEmployeeModal
            reportModal={reportModal}
            setReportModal={setReportModal}
            employeeId={employeeId}
            setEmployeeId={setEmployeeId}
          />
        </>
      )}
    </>
  );
};

export default ActiveReports;
