import { useContext } from 'react';
import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { AuthContext } from 'context/authContext';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetAllAppliedLeavesForAdmin(
  pendingStatus,
  status,
  selectedMonthFilterValue,
  searchValue,
  yearFilterValue
) {
  const { authState } = useContext(AuthContext);
  return useQuery(
    [
      'get-all-Applied-leaves-for-admin',
      pendingStatus,
      status,
      selectedMonthFilterValue,
      searchValue,
      yearFilterValue,
    ],
    async () => {
      const res = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_APPLIED_LEAVES, {
          status: pendingStatus,
          status__ne: status,
          month: selectedMonthFilterValue,
          from_date__year: yearFilterValue,
          search: searchValue,
          employee__reports_to_id: authState?.is_admin
            ? undefined
            : authState?.user_id,
        })
      );
      return res.data;
    }
  );
}
