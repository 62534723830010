import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useGetAllDepartments() {
  return useQuery('settings-departments', async () => {
    const response = await makeApiRequest.get(
      apiEndPoints.SETTINGS_DEPARTMENTS
    );
    return response?.data;
  });
}
