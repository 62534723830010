import { Tabs } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';

import { ReactComponent as FilterIcon } from '../../../../assets/icons/filterIcon.svg';
import { ReactComponent as CalanderIcon } from 'assets/icons/calanderIcon.svg';
import RunPayrollTable from './components/runpayrolltable';
import { useState } from 'react';
import PrimaryButton from 'components/primaryButton';
import CreatePayrollModal from './components/createpayrollmodal';
import Salarycomponents from './components/salarycomponents';
import SalaryComponentsModal from './components/salarycomponentsModal';
import { useNavigate } from 'react-router';
import Header from 'components/Header';
import { useSearchParams } from 'react-router-dom';

const Payroll = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreatePayrollOpened, setIsCreatePayrollOpened] = useState(false);
  const [filterDate, setFilterDate] = useState([]);
  const [salaryComponentsModalOpend, setSalaryComponentsModalOpend] =
    useState(false);

  const navigate = useNavigate();

  // const getPayrollData = Usegetpayroll(filterDate);

  // console.log(filterDate);

  const handleTabChange = (e) => {
    setSearchParams({ tab: e });
  };

  return (
    <>
      <div className='px-5 mt-6'>
        <div className='flex items-center justify-between'>
          <p
            className='text-xl text-A8A8A8'
            onClick={() =>
              navigate('/app/organisation/payroll', { replace: true })
            }
          >
            Organisation &#60;{' '}
            <span className='text-gray font-semibold'>Payroll</span>
          </p>
          <Header />
        </div>
        <div className='mt-8 relative select-none'>
          <div>
            <Tabs
              active={~~searchParams.get('tab')}
              tabPadding={21}
              onTabChange={handleTabChange}
              classNames={{
                tabsListWrapper: 'border-0',
                tabActive: 'border-2 border-primary font-semibold text-18',
                tabControl: 'text-A8A8A8 leading-5 px-0 mr-4',
                tabLabel: 'text-18 text-gray',
              }}
            >
              <Tabs.Tab label='Employee salary'>
                <RunPayrollTable filterDate={filterDate} />
              </Tabs.Tab>
              <Tabs.Tab label='Salary components'>
                <Salarycomponents />
              </Tabs.Tab>
            </Tabs>
          </div>
          {/* search and filter */}
          <div className='absolute top-2 right-0'>
            {~~searchParams.get('tab') === 0 ? (
              <div className='flex items-center space-x-4'>
                <DateRangePicker
                  icon={<FilterIcon />}
                  rightSection={<CalanderIcon />}
                  inputFormat='DD/MM/YYYY'
                  labelFormat='DD/MM/YYYY'
                  placeholder='Pick Date Range'
                  onChange={(value) => {
                    setFilterDate(value);
                  }}
                  classNames={{
                    wrapper: 'border-primary border-b',
                    input: 'w-72 bg-light-bg border-none',
                  }}
                  styles={{
                    rightSection: { pointerEvents: 'none' },
                    monthPickerControlActive: { color: 'red' },
                    yearPickerControlActive: {
                      color: 'red',
                      backgroundcolor: '#ffffff',
                    },
                  }}
                />
                <button onClick={() => setIsCreatePayrollOpened(true)}>
                  <PrimaryButton>+ Create payroll</PrimaryButton>
                </button>
              </div>
            ) : (
              <div className='flex items-center space-x-4'>
                <p
                  className='text-[#67A1EF] font-normal text-base cursor-pointer'
                  onClick={() => {
                    navigate('/app/settings?tab=1', { replace: true });
                  }}
                >
                  Edit grade
                </p>
                <button onClick={() => setSalaryComponentsModalOpend(true)}>
                  <PrimaryButton>+ Add an item</PrimaryButton>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <CreatePayrollModal
        isCreatePayrollOpened={isCreatePayrollOpened}
        setIsCreatePayrollOpened={setIsCreatePayrollOpened}
      />
      <SalaryComponentsModal
        setSalaryComponentsModalOpend={setSalaryComponentsModalOpend}
        salaryComponentsModalOpend={salaryComponentsModalOpend}
      />
    </>
  );
};

export default Payroll;
