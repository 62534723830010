import React, { useContext, useState } from 'react';
import { Modal, Select, TextInput } from '@mantine/core';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';

import useAddFamilyMembersDetails from '../hooks/useAddFamilyMembersDetails';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { AuthContext } from 'context/authContext';

export default function AddEmployeeFamilyDetailsModal({
  isModalOpen,
  setIsModalOpen,
}) {
  const { authState } = useContext(AuthContext);
  const [relationshipData, setRelationshipData] = useState([
    { value: 'Father', label: 'Father' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Sister', label: 'Sister' },
  ]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //   custom title for modal
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>Add family details</p>
      </div>
    );
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      test: [
        { name: '', relationship: '', mobile_number: '', alternate_number: '' },
      ],
    },
  });

  // error message
  const getErrorMessage = (index, name) => {
    return (
      errors?.test?.length && (
        <p className='text-[#D0342C] text-sm'>
          {errors?.test[index]?.[name]?.message}
        </p>
      )
    );
  };

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'test', // unique name for your Field Array
  });

  const addFamilyMembersMutation = useAddFamilyMembersDetails(
    closeModal,
    reset
  );

  const onSubmit = (data) => {
    const family_members = [];
    data?.test?.map((item) => family_members.push(item));
    const finalData = {
      employee_id: authState.employee_id,
      family_members,
    };
    addFamilyMembersMutation.mutate(finalData);
  };

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-2',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields?.map((item, index) => (
            <div
              className='p-5 border border-[#E0E0E0] mr-6 mt-4 mb-9'
              key={index}
            >
              {index === 0 ? null : (
                <div className='flex justify-end -mt-9 pt-0.5'>
                  <p
                    className='text-[#67A1EF] w-fit cursor-pointer bg-white px-2'
                    onClick={() => remove(index)}
                  >
                    - Remove
                  </p>
                </div>
              )}
              <div className='flex flex-wrap'>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name={`test[${index}].name`}
                    rules={{
                      required: 'required',
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Name*'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage(index, 'name')}
                </div>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name={`test[${index}].relationship`}
                    rules={{
                      required: 'required',
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Relationship* '
                        data={relationshipData}
                        searchable
                        creatable
                        getCreateLabel={(query) => `+ Create ${query}`}
                        onCreate={(query) => {
                          const item = { value: query, label: query };
                          setRelationshipData((current) => [...current, item]);
                          return item;
                        }}
                        size='md'
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<DownArrow className='mt-1' />}
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage(index, 'relationship')}
                </div>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name={`test[${index}].mobile_number`}
                    rules={{
                      required: 'required',
                      pattern: {
                        value: /^[0-9]+[0-9]*$/,
                        message: 'Only numbers are allowed',
                      },
                      // minLength: {
                        //   value: 6,
                        //   message: 'minimum of 6 digits',
                        // },
                      maxLength: {
                        value: 16,
                        message: 'maximum of 16 digits only',
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Contact*'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage(index, 'mobile_number')}
                </div>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name={`test[${index}].alternate_number`}
                    rules={{
                      pattern: {
                        value: /^[0-9]+[0-9]*$/,
                        message: 'Only numbers are allowed',
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Alternate contact'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage(index, 'alternate_number')}
                </div>
              </div>
            </div>
          ))}
          <p
            className='text-[#67A1EF] -mt-5 w-fit cursor-pointer select-none'
            onClick={
              () =>
                fields.length < 3 &&
                append({
                  name: '',
                  relationship: '',
                  mobile_number: '',
                  alternate_number: '',
                })

              // onClick={() => {
              //   append({
              //     name: '',
              //     relationship: '',
              //     mobile_number: '',
              //     alternate_number: '',
              //   });
              // }}
            }
          >
            + Add more
          </p>
          <div className='flex justify-center w-full mt-8'>
            <button className='w-28'>
              <PrimaryButton isLoading={addFamilyMembersMutation?.isLoading}>
                Save
              </PrimaryButton>
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
