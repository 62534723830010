import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

const ChatBotInstance = axios.create({
  baseURL: `${process.env.CHATBOT_BASE_URL}`,
});

ChatBotInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  let jwt = `jwt ${token}`;

  //  Have to uncomment letter for jwt token

  if (token) {
    config.headers.Authorization = jwt;
  } else {
    config.headers.Authorization = null;
  }

  return config;
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  let jwt = `jwt ${JSON.parse(token)}`;

  if (token) {
    config.headers.Authorization = jwt;
  } else {
    config.headers.Authorization = null;
  }

  return config;
});
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    let errTxt = JSON.parse(error?.request?.response);
    if (
      errTxt.detail === 'Error decoding signature.' ||
      errTxt.detail === 'Signature has expired.' ||
      errTxt.detail === 'You do not have permission to perform this action.'
    ) {
      setTimeout(function () {
        window.location.replace('/auth/login');

        localStorage.clear();
      }, 1000);
    }
    return Promise.reject(error);
  }
);
export default instance;
export { ChatBotInstance };
