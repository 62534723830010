import React, { useContext, useEffect, useState } from 'react';
import { Modal, TextInput } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';
import getSymbolFromCurrency from 'currency-symbol-map';
import { AuthContext } from 'context/authContext';
import useGetEmployeeSalaryDetails from '../hooks/useGetEmployeeSalaryDetails';
import useUpdateSalaryDetails from '../hooks/useUpdateEmployeeSalaryDetails';

export default function EditSalaryModal({
  isModalOpen,
  setIsModalOpen,
  employee_id,
}) {
  const { authState } = useContext(AuthContext);

  const currencyIcon = getSymbolFromCurrency(authState?.currency);
  const getEmployeeSalaryDetails = useGetEmployeeSalaryDetails(
    employee_id,
    isModalOpen
  );
  const updateEmployeeSalaryDetails = useUpdateSalaryDetails(setIsModalOpen);

  const [basicPay, setBasicPay] = useState(0);

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const defaultValues = {
    other_allowances: '',
    basic_pay: '',
    hra: '',
    ctc: 0,
  };

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    reset,
  } = useForm({ defaultValues });

  // basic pay
  useEffect(() => {
    setBasicPay((watch('basic_pay') * watch('ctc')) / 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('ctc'), watch('basic_pay')]);

  // component total
  const payWithAllowances = () => {
    let basicPay = (watch('basic_pay') * watch('ctc')) / 100;
    let hra = (watch('hra') * basicPay) / 100;
    let otherAllowances = 0;

    getEmployeeSalaryDetails?.data?.data[0]?.components?.map(
      (item) =>
        (otherAllowances =
          otherAllowances + (watch(`${item?.component}`) * basicPay) / 100)
    );
    return basicPay + hra + otherAllowances;
  };

  useEffect(() => {
    reset({
      other_allowances:
        getEmployeeSalaryDetails?.data?.data[0]?.other_allowances,
      basic_pay: getEmployeeSalaryDetails?.data?.data[0]?.basic_pay,
      hra: getEmployeeSalaryDetails?.data?.data[0]?.hra,
      ctc: getEmployeeSalaryDetails?.data?.data[0]?.ctc,
    });
    getEmployeeSalaryDetails?.data?.data[0]?.components?.map((item) =>
      setValue(`${item?.component}`, item?.value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeeSalaryDetails?.data?.data]);

  const onSubmit = (data) => {
    const allowancesData =
      getEmployeeSalaryDetails?.data?.data[0]?.components?.map((item) => {
        return { id: item?.component, value: data[item?.component] };
      });

    const finalData = {
      employee_id: employee_id,
      other_allowances: data?.other_allowances,
      basic_pay: data?.basic_pay,
      hra: data?.hra,
      ctc: data?.ctc,
      components: allowancesData,
    };
    updateEmployeeSalaryDetails.mutate(finalData);
  };

  return (
    <div onSubmit={handleSubmit(onSubmit)}>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='lg'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-4',
        }}
      >
        <form>
          <div className='ml-4 mr-8 mt-4'>
            <p className='text-18 font-semibold mb-4'>
              Employee salary break-up
            </p>
            <Controller
              control={control}
              name='ctc'
              rules={{
                required: 'Required',
                pattern: {
                  value: /^[0-9]+[0-9]*$/,
                  message: 'Only numbers are allowed',
                },
              }}
              render={({ field }) => (
                <TextInput
                  label='Salary (CTC)*'
                  {...field}
                  size='md'
                  icon={currencyIcon}
                  classNames={{
                    input: 'border-primary',
                    label: 'text-neutralsGrey text-sm',
                  }}
                />
              )}
            />
            {getErrorMessage('ctc')}
            <div className='flex items-center justify-between mt-8'>
              <p className='font-semibold'>Basic Pay</p>
              <div>
                <Controller
                  control={control}
                  name='basic_pay'
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Only positive numbers are allowed',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      type='number'
                      rightSection={<div className='text-white'>% of CTC</div>}
                      size='md'
                      classNames={{
                        input: 'border-primary w-40',
                        rightSection:
                          'whitespace-nowrap w-fit px-5 bg-primary rounded-r-md',
                      }}
                    />
                  )}
                />
                {getErrorMessage('basic_pay')}
              </div>
            </div>
            <p className='text-primary pt-1 text-right'>
              {currencyIcon} {basicPay}
            </p>
            <div className='flex items-center justify-between mt-8'>
              <p className='font-semibold'>House Rent Allowance (HRA)</p>
              <div>
                <Controller
                  control={control}
                  name='hra'
                  rules={{
                    required: 'Required',
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Only positive numbers are allowed',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      size='md'
                      {...field}
                      type='number'
                      rightSection={
                        <div className='text-white'>% of basic</div>
                      }
                      classNames={{
                        input: 'border-primary w-40',
                        rightSection:
                          'whitespace-nowrap w-fit px-4 bg-primary rounded-r-md',
                      }}
                    />
                  )}
                />
                {getErrorMessage('hra')}
              </div>
            </div>
            <p className='text-primary pt-1 text-right'>
              {currencyIcon}
              {(watch('hra') * basicPay) / 100}
            </p>
            <div className='flex justify-between items-center mt-8'>
              <p className='font-semibold'>Other allowances</p>
              <p>
                Break-up of{' '}
                <span className='text-primary'>
                  {Math.round(
                    100 -
                      ((basicPay + (watch('hra') * basicPay) / 100) /
                        watch('ctc')) *
                        100 || 0
                  )}{' '}
                  %
                </span>
              </p>
            </div>
            {getEmployeeSalaryDetails?.data?.data[0]?.components?.map(
              (item, index) => (
                <div className=' my-8' key={index}>
                  <div className='flex items-center justify-between'>
                    <p className=' text-gray'>{item?.component__name}</p>
                    <Controller
                      control={control}
                      name={`${item?.component}`}
                      rules={{
                        required: 'Required',
                        pattern: {
                          value: /^[0-9]*$/,
                          message: 'Only positive numbers are allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          size='md'
                          type='number'
                          {...field}
                          defaultValue={item?.value}
                          rightSection={
                            <div className='text-white'>% of basic</div>
                          }
                          classNames={{
                            input: 'border-primary w-40',
                            rightSection:
                              'whitespace-nowrap w-fit px-5 bg-primary rounded-r-md',
                          }}
                        />
                      )}
                    />
                  </div>
                  <p className='text-primary pt-1 text-right'>
                    {currencyIcon}{' '}
                    {(watch(`${item?.component}`) * basicPay) / 100}
                  </p>
                </div>
              )
            )}
            <div className='flex items-center justify-between mt-4'>
              <p className=' text-gray'>Fixed allowances</p>
              <TextInput
                size='md'
                readOnly
                value={
                  watch('ctc') - payWithAllowances() < 0
                    ? 0
                    : watch('ctc') - payWithAllowances()
                }
                type='number'
                icon={currencyIcon}
                classNames={{
                  input: 'border-primary w-40',
                }}
              />
            </div>
            <div className='flex justify-between pt-8'>
              <p className='font-semibold'>Calculated components total</p>
              <p className='font-bold text-xl'>
                {currencyIcon} {payWithAllowances()}
              </p>
            </div>
            {payWithAllowances() > watch('ctc') && (
              <div className='flex justify-between pt-1'>
                <p className='text-gray3'>
                  Total amount is exceeding the overall CTC.{' '}
                </p>
                <p className='font-bold text-lg text-[#F61919]'>
                  -{payWithAllowances() - watch('ctc')}
                </p>
              </div>
            )}
            <p className='text-gray3 pt-10'>
              NOTE : Any changes made to the salary components will not be
              updated in the settings, and will remain unique to the employee (
              vice versa for an added employee).
            </p>
            <div className='flex justify-center space-x-5 mt-8 mb-4'>
              <div className='flex justify-center '>
                <button
                  className='w-32'
                  disabled={payWithAllowances() > watch('ctc')}
                >
                  <PrimaryButton
                    isLoading={updateEmployeeSalaryDetails?.isLoading}
                  >
                    Save
                  </PrimaryButton>
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}
