import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as RightIcon } from 'assets/icons/correctIcon.svg';
import { ReactComponent as EmptyLeaves } from 'assets/icons/emptyleavesIcon.svg';

import {
  Switch,
  TextInput,
  Checkbox,
  MultiSelect,
  Tooltip,
  Loader,
  Select,
} from '@mantine/core';
import { useEffect, useState } from 'react';

import UseGetEmployeementTypes from '../hooks/useGetEmploymentTypes';

import Usegetleavestypes from '../hooks/usegetleavetypes';
import UseUpdateLeaveType from '../hooks/useupdareleavetype';

import { Controller, useForm, useFieldArray } from 'react-hook-form';

import { useSearchParams } from 'react-router-dom';

const LeaveTypes = () => {
  const [searchParams] = useSearchParams();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({ mode: 'onChange' });

  const { fields } = useFieldArray({ control, name: 'leavesTypes' });

  const [isEnabled, setisEnabled] = useState(false);
  const [selectedcontainer, setselectedcontainer] = useState();
  const savechangesinleaveType = () => {
    setisEnabled(false);
  };
  const updateLeaveTypemuration = UseUpdateLeaveType(savechangesinleaveType);
  const employeementTypesData = UseGetEmployeementTypes();

  const leavestypesdata = Usegetleavestypes(
    searchParams.get('id'),
    savechangesinleaveType
  );

  useEffect(() => {
    reset({
      leavesTypes: leavestypesdata?.data?.data.map((typeLeave) => ({
        activate_after_days: typeLeave.activate_after_days,
        at_a_time_max_limit: typeLeave.at_a_time_max_limit,
        at_a_time_min_limit: typeLeave.at_a_time_min_limit,
        carry_forward_days: typeLeave.carry_forward_days,
        monthly_days: typeLeave.monthly_days,
        name: typeLeave.name,
        total_days: typeLeave.total_days,
        should_be_applied_before_days: typeLeave.should_be_applied_before_days,
        is_carry_forward: typeLeave.is_carry_forward,
        is_active_after_days: typeLeave.is_active_after_days,
        is_paid: typeLeave.is_paid,
        leave_module_id: typeLeave.leave_module_id,
        is_active: typeLeave.is_active,
        id: typeLeave.id,
        increment_alternate: typeLeave.increment_alternate,
        increment_monthly: typeLeave.increment_monthly,
        is_avail_in_probation: typeLeave.is_avail_in_probation,
        is_notice_period: typeLeave.is_notice_period,
        is_work_from_home: typeLeave.is_work_from_home,
        restricted_employment: typeLeave.restricted_employment
          .toString()
          .split(','),
      })),
    });
    // eslint-disable-next-line
  }, [leavestypesdata?.data?.data]);

  const submitlevetypeData = (data) => {
    const incrementData =
      data?.leavesTypes[selectedcontainer].increment === '0'
        ? { increment_alternate: false, increment_monthly: false }
        : data?.leavesTypes[selectedcontainer].increment === '1'
        ? { increment_alternate: true, increment_monthly: false }
        : data?.leavesTypes[selectedcontainer].increment === '2'
        ? { increment_alternate: false, increment_monthly: true }
        : null;

    delete data?.leavesTypes[selectedcontainer].increment;

    updateLeaveTypemuration.mutate({
      ...data?.leavesTypes[selectedcontainer],
      ...incrementData,
      restricted_employment:
        data?.leavesTypes[selectedcontainer].restricted_employment[0] === ''
          ? data?.leavesTypes[selectedcontainer].restricted_employment.slice(1)
          : data?.leavesTypes[selectedcontainer].restricted_employment,
    });
  };

  const getFieldArrayFormErrorMessage = (index, subName) => {
    return (
      errors?.leavesTypes?.length && (
        <p className='text-[#D0342C] text-sm'>
          {errors?.leavesTypes[index]?.[subName]?.message}
        </p>
      )
    );
  };

  // getting ristricted employees list
  const getRistrictedEmployeesList = (index) => {
    const ristrictedEmployessList = [];
    employeementTypesData?.data?.data?.map((each) => {
      if (
        leavestypesdata?.data?.data[index]?.restricted_employment.includes(
          each.id
        )
      ) {
        ristrictedEmployessList.push(each?.name);
      }
      return true;
    });
    return ristrictedEmployessList;
  };

  return (
    <div>
      {leavestypesdata.isLoading && employeementTypesData.isLoading ? (
        <div className='h-[70vh] flex justify-center items-center'>
          <Loader variant='dots' />
        </div>
      ) : (
        <>
          {leavestypesdata?.data?.data.length === 0 ? (
            <div className=' flex justify-center items-center flex-col h-[65vh]'>
              <EmptyLeaves />
              <p className='mt-2 mb-3 text-[#535353] text-center text-normal font-medium'>
                {' '}
                No Leaves types to show
              </p>
            </div>
          ) : (
            <>
              {fields.map((fields, index) => (
                <form
                  onSubmit={handleSubmit(submitlevetypeData)}
                  key={fields.id}
                >
                  <div className='mt-5'>
                    <div className='flex justify-between items-center bg-[#FFF3EF] p-4 rounded mb-3'>
                      <p className='text-primary font-semibold text-lg '>
                        {isEnabled && selectedcontainer === index ? (
                          <div>
                            <Controller
                              control={control}
                              name={`leavesTypes.${index}.name`}
                              rules={{
                                required: 'Required',
                              }}
                              render={({ field }) => (
                                <TextInput
                                  {...field}
                                  placeholder='Enter Leave Name'
                                  size='xs'
                                  disabled={
                                    index === selectedcontainer
                                      ? !isEnabled
                                      : true
                                  }
                                  classNames={{
                                    input:
                                      ' text-[15px] bg-transparent   text-normal rounded-lg pl-5',
                                    label:
                                      'text-primary text-normal font-normal ml-2',
                                  }}
                                />
                              )}
                            />
                            {getFieldArrayFormErrorMessage(index, 'name')}
                          </div>
                        ) : (
                          `${fields?.name}`
                        )}
                      </p>
                      <div className='flex items-center gap-4'>
                        <Controller
                          control={control}
                          name={`leavesTypes.${index}.is_paid`}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              defaultChecked={fields.is_paid}
                              label='Paid leave'
                              disabled={
                                index === selectedcontainer ? !isEnabled : true
                              }
                              classNames={{
                                label: fields.is_active
                                  ? 'font-normal '
                                  : 'text-[#5f5f5f] font-normal ',
                              }}
                            />
                          )}
                        />
                        {fields.is_active ? (
                          <>
                            {isEnabled && selectedcontainer === index ? (
                              <button>
                                <Tooltip
                                  label='Save'
                                  classNames={{
                                    body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                                  }}
                                  radius='md'
                                >
                                  <RightIcon
                                    // onClick={() => {
                                    //   setisEnabled(false);
                                    // }}
                                    className='ml-3 cursor-pointer'
                                  />
                                </Tooltip>
                              </button>
                            ) : (
                              <Tooltip
                                label='Edit'
                                classNames={{
                                  body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                                }}
                                radius='md'
                              >
                                <EditIcon
                                  className='ml-3 cursor-pointer'
                                  onClick={() => {
                                    setisEnabled(true);
                                    setselectedcontainer(index);
                                  }}
                                />
                              </Tooltip>
                            )}{' '}
                          </>
                        ) : (
                          <Tooltip
                            label='Please Activate Leave Type To Edit'
                            classNames={{
                              body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                            }}
                            radius='md'
                          >
                            <EditIcon className='ml-3 cursor-pointer' />
                          </Tooltip>
                        )}
                        <Tooltip
                          label={fields?.is_active ? 'Active' : 'Deactive'}
                          classNames={{
                            body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                          }}
                          radius='md'
                        >
                          <Controller
                            control={control}
                            name={`leavesTypes.${index}.is_active`}
                            render={({ field }) => (
                              <Switch
                                {...field}
                                onChange={() => {
                                  updateLeaveTypemuration.mutate({
                                    leave_module_id: fields.leave_module_id,
                                    is_active: !fields.is_active,
                                    id: leavestypesdata?.data?.data[index].id,
                                  });
                                }}
                                defaultChecked={fields.is_active}
                              />
                            )}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className='grid grid-cols-4 items-center gap-4 px-8 mt-5'>
                      <p
                        className={
                          fields.is_active
                            ? 'font-normal'
                            : 'text-[#5f5f5f] font-normal'
                        }
                      >
                        Leave count
                      </p>
                      <div>
                        {isEnabled && selectedcontainer === index ? (
                          <div>
                            <Controller
                              control={control}
                              name={`leavesTypes.${index}.total_days`}
                              rules={{
                                required: 'Required',
                                pattern: {
                                  value: /^[0-9]+[0-9]*$/,
                                  message: 'Only numbers are allowed',
                                },
                              }}
                              render={({ field }) => (
                                <TextInput
                                  {...field}
                                  placeholder='0 days'
                                  type='number'
                                  size='md'
                                  disabled={
                                    index === selectedcontainer
                                      ? !isEnabled
                                      : true
                                  }
                                  classNames={{
                                    input:
                                      ' text-[15px] bg-transparent   text-normal rounded-lg pl-5',
                                    label:
                                      'text-[#5f5f5f] text-sm font-normal ml-2',
                                  }}
                                />
                              )}
                            />
                            {getFieldArrayFormErrorMessage(index, 'total_days')}
                          </div>
                        ) : (
                          <p
                            className={
                              fields.is_active
                                ? 'font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                                : 'text-[#5f5f5f] font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                            }
                          >
                            {leavestypesdata?.data?.data[index].total_days}
                          </p>
                        )}
                      </div>
                      <p
                        className={
                          fields.is_active
                            ? 'font-normal'
                            : 'text-[#5f5f5f] font-normal'
                        }
                      >
                        Monthly limit
                      </p>
                      {isEnabled && selectedcontainer === index ? (
                        <div>
                          <Controller
                            control={control}
                            name={`leavesTypes.${index}.monthly_days`}
                            rules={{
                              required: 'Required',
                              pattern: {
                                value: /^[0-9]+[0-9]*$/,
                                message: 'Only numbers are allowed',
                              },
                              validate: (value) =>
                                value <=
                                  parseInt(
                                    watch(`leavesTypes.${index}.total_days`)
                                  ) || 'value must be less than leave count',
                            }}
                            render={({ field }) => (
                              <TextInput
                                {...field}
                                placeholder='0 days'
                                type='number'
                                size='md'
                                disabled={
                                  index === selectedcontainer
                                    ? !isEnabled
                                    : true
                                }
                                classNames={{
                                  input:
                                    ' text-[15px] bg-transparent  text-normal rounded-lg pl-5',
                                  label:
                                    'text-[#5f5f5f] text-sm font-normal ml-2',
                                }}
                              />
                            )}
                          />
                          {getFieldArrayFormErrorMessage(index, 'monthly_days')}
                        </div>
                      ) : (
                        <p
                          className={
                            fields.is_active
                              ? 'font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                              : 'text-[#5f5f5f] font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                          }
                        >
                          {leavestypesdata?.data?.data[index].monthly_days}
                        </p>
                      )}
                      <p
                        className={
                          fields.is_active
                            ? 'font-normal'
                            : 'text-[#5f5f5f] font-normal'
                        }
                      >
                        Apply before (days)
                      </p>
                      {isEnabled && selectedcontainer === index ? (
                        <div>
                          <Controller
                            control={control}
                            name={`leavesTypes.${index}.should_be_applied_before_days`}
                            rules={{
                              required: 'Required',
                              pattern: {
                                value: /^[0-9]+[0-9]*$/,
                                message: 'Only numbers are allowed',
                              },
                            }}
                            render={({ field }) => (
                              <TextInput
                                {...field}
                                size='md'
                                type='number'
                                disabled={
                                  index === selectedcontainer
                                    ? !isEnabled
                                    : true
                                }
                                placeholder='0 days'
                                className='w-full'
                                classNames={{
                                  input:
                                    ' text-[15px] bg-transparent   text-normal rounded-lg pl-5',
                                  label:
                                    'text-[#5f5f5f] text-sm font-normal ml-2',
                                }}
                              />
                            )}
                          />
                          {getFieldArrayFormErrorMessage(
                            index,
                            'should_be_applied_before_days'
                          )}
                        </div>
                      ) : (
                        <p
                          className={
                            fields.is_active
                              ? 'font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                              : 'text-[#5f5f5f] font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                          }
                        >
                          {
                            leavestypesdata?.data?.data[index]
                              .should_be_applied_before_days
                          }
                        </p>
                      )}
                      <p
                        className={
                          fields.is_active
                            ? 'font-normal'
                            : 'text-[#5f5f5f] font-normal'
                        }
                      >
                        Apply limit (Min days)
                      </p>
                      {isEnabled && selectedcontainer === index ? (
                        <div>
                          <Controller
                            control={control}
                            name={`leavesTypes.${index}.at_a_time_min_limit`}
                            rules={{
                              required: 'Required',
                              min: {
                                value: 1,
                                message: 'Value must greater than 1',
                              },
                              pattern: {
                                value: /^[0-9]+[0-9]*$/,
                                message: 'Only numbers are allowed',
                              },
                              validate: (value) =>
                                value <=
                                  parseInt(
                                    watch(`leavesTypes.${index}.total_days`)
                                  ) || 'value must be less than leave count',
                            }}
                            render={({ field }) => (
                              <TextInput
                                {...field}
                                size='md'
                                type='number'
                                disabled={
                                  index === selectedcontainer
                                    ? !isEnabled
                                    : true
                                }
                                placeholder='0 days'
                                classNames={{
                                  input:
                                    ' text-[15px] bg-transparent   text-normal rounded-lg pl-5',
                                  label:
                                    'text-[#5f5f5f] text-sm font-normal ml-2',
                                }}
                              />
                            )}
                          />
                          {getFieldArrayFormErrorMessage(
                            index,
                            'at_a_time_min_limit'
                          )}
                        </div>
                      ) : (
                        <p
                          className={
                            fields.is_active
                              ? 'font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                              : 'text-[#5f5f5f] font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                          }
                        >
                          {
                            leavestypesdata?.data?.data[index]
                              .at_a_time_min_limit
                          }
                        </p>
                      )}
                      <p
                        className={
                          fields.is_active
                            ? 'font-normal'
                            : 'text-[#5f5f5f] font-normal'
                        }
                      >
                        Apply limit (Max Days)
                      </p>
                      {isEnabled && selectedcontainer === index ? (
                        <div>
                          <Controller
                            control={control}
                            name={`leavesTypes.${index}.at_a_time_max_limit`}
                            rules={{
                              required: 'Required',
                              pattern: {
                                value: /^[0-9]+[0-9]*$/,
                                message: 'Only numbers are allowed',
                              },
                              validate: (value) =>
                                (value >=
                                  parseInt(
                                    watch(
                                      `leavesTypes.${index}.at_a_time_min_limit`
                                    )
                                  )) &
                                  (value <=
                                    parseInt(
                                      watch(`leavesTypes.${index}.monthly_days`)
                                    )) &
                                  (value <=
                                    parseInt(
                                      watch(`leavesTypes.${index}.total_days`)
                                    )) ||
                                'value must be greater than min value and less than leave count & monthly limit',
                            }}
                            render={({ field }) => {
                              return (
                                <TextInput
                                  {...field}
                                  placeholder='0 days'
                                  type='number'
                                  size='md'
                                  disabled={
                                    index === selectedcontainer
                                      ? !isEnabled
                                      : true
                                  }
                                  classNames={{
                                    input:
                                      ' text-[15px] bg-transparent   text-normal rounded-lg pl-5',
                                    label:
                                      'text-[#5f5f5f] text-sm font-normal ml-2',
                                  }}
                                />
                              );
                            }}
                          />
                          {getFieldArrayFormErrorMessage(
                            index,
                            'at_a_time_max_limit'
                          )}
                        </div>
                      ) : (
                        <p
                          className={
                            fields.is_active
                              ? 'font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                              : 'text-[#5f5f5f] font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                          }
                        >
                          {
                            leavestypesdata?.data?.data[index]
                              .at_a_time_max_limit
                          }
                        </p>
                      )}

                      <p
                        className={
                          fields.is_active
                            ? 'font-normal  flex items-center'
                            : 'text-[#5f5f5f] font-normal  flex items-center'
                        }
                      >
                        Leave active after{' '}
                        <Controller
                          control={control}
                          name={`leavesTypes.${index}.is_active_after_days`}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              className='ml-3'
                              defaultChecked={fields.is_active_after_days}
                              onChange={(e) => {
                                if (
                                  !e.target.checked &&
                                  !watch(
                                    `leavesTypes.${index}.activate_after_days`
                                  )
                                ) {
                                  setValue(
                                    `leavesTypes.${index}.activate_after_days`,
                                    240
                                  );
                                }
                                field.onChange(e);
                              }}
                              disabled={
                                index === selectedcontainer ? !isEnabled : true
                              }
                            />
                          )}
                        />
                      </p>
                      {isEnabled && selectedcontainer === index ? (
                        <div>
                          <Controller
                            control={control}
                            name={`leavesTypes.${index}.activate_after_days`}
                            rules={
                              watch(`leavesTypes.${index}.is_active_after_days`)
                                ? {
                                    required: 'Required',
                                    pattern: {
                                      value: /^[0-9]+[0-9]*$/,
                                      message: 'Only numbers are allowed',
                                    },
                                  }
                                : null
                            }
                            render={({ field }) => (
                              <TextInput
                                {...field}
                                size='md'
                                type='number'
                                disabled={
                                  watch(
                                    `leavesTypes.${index}.is_active_after_days`
                                  ) && index === selectedcontainer
                                    ? !isEnabled
                                    : true
                                }
                                className='w-full'
                                placeholder='0 days'
                                classNames={{
                                  input:
                                    ' text-[15px] bg-transparent  text-normal rounded-lg pl-5',
                                  label:
                                    'text-[#5f5f5f] text-sm font-normal ml-2',
                                }}
                              />
                            )}
                          />
                          {getFieldArrayFormErrorMessage(
                            index,
                            'activate_after_days'
                          )}
                        </div>
                      ) : (
                        <p
                          className={
                            fields.is_active && fields.is_active_after_days
                              ? 'font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                              : 'text-[#5f5f5f] font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                          }
                        >
                          {
                            leavestypesdata?.data?.data[index]
                              .activate_after_days
                          }
                        </p>
                      )}
                      <p
                        className={
                          fields.is_active
                            ? 'font-normal'
                            : 'text-[#5f5f5f] font-normal'
                        }
                      >
                        Restricted for
                      </p>
                      {isEnabled && selectedcontainer === index ? (
                        <div>
                          <Controller
                            control={control}
                            name={`leavesTypes.${index}.restricted_employment`}
                            render={({ field }) => (
                              <MultiSelect
                                {...field}
                                data={
                                  employeementTypesData?.data?.data
                                    ?.filter((element) => element?.is_active)
                                    ?.map((dept) => {
                                      return {
                                        value: `${dept.id}`,
                                        label: dept.name,
                                      };
                                    }) || [{ value: `0`, label: 'full time' }]
                                }
                                placeholder='Pick Restricted for'
                                styles={{
                                  rightSection: { pointerEvents: 'none' },
                                }}
                                rightSection={<DownArrow />}
                                size='md'
                                disabled={
                                  index === selectedcontainer
                                    ? !isEnabled
                                    : true
                                }
                                classNames={{
                                  input:
                                    ' text-[15px] bg-transparent   text-normal rounded-lg pl-5',
                                  label:
                                    'text-[#5f5f5f] text-sm font-normal ml-2',
                                }}
                              />
                            )}
                          />
                          {getFieldArrayFormErrorMessage(
                            index,
                            'restricted_employment'
                          )}
                        </div>
                      ) : (
                        <p
                          className={
                            fields.is_active
                              ? 'font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                              : 'text-[#5f5f5f] font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                          }
                        >
                          {/* eslint-disable-next-line*/}
                          {leavestypesdata?.data?.data[index]
                            .restricted_employment.length === 0 ? (
                            <p>N/A</p>
                          ) : (
                            <>{getRistrictedEmployeesList(index)?.join(', ')}</>
                          )}
                        </p>
                      )}
                      {/* {console.log(
                        leavestypesdata?.data?.data[index].restricted_employment
                      )} */}
                      {/* {console.log(
                        employeementTypesData?.data?.data.map((each) => {
                          if (
                            leavestypesdata?.data?.data[
                              index
                            ].restricted_employment.includes(each.id)
                          ) {
                            return each.name;
                          }
                        })
                      )} */}
                      <p
                        className={
                          fields.is_active
                            ? 'font-normal  flex items-center gap-3'
                            : 'text-[#5f5f5f] font-normal  flex items-center gap-3'
                        }
                      >
                        Carry forward{' '}
                        <Controller
                          control={control}
                          name={`leavesTypes.${index}.is_carry_forward`}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              defaultChecked={fields.is_carry_forward}
                              onChange={(e) => {
                                if (
                                  !e.target.checked &&
                                  !watch(
                                    `leavesTypes.${index}.carry_forward_days`
                                  )
                                ) {
                                  setValue(
                                    `leavesTypes.${index}.carry_forward_days`,
                                    0
                                  );
                                }
                                field.onChange(e);
                              }}
                              disabled={
                                index === selectedcontainer ? !isEnabled : true
                              }
                            />
                          )}
                        />
                      </p>
                      {isEnabled && selectedcontainer === index ? (
                        <div>
                          <Controller
                            control={control}
                            name={`leavesTypes.${index}.carry_forward_days`}
                            defaultValue={0}
                            rules={
                              watch(`leavesTypes.${index}.is_carry_forward`)
                                ? {
                                    required: 'Required',
                                    pattern: {
                                      value: /^[0-9]+[0-9]*$/,
                                      message: 'Only numbers are allowed',
                                    },
                                  }
                                : null
                            }
                            render={({ field }) => (
                              <TextInput
                                {...field}
                                placeholder='0 days'
                                size='md'
                                type='number'
                                disabled={
                                  watch(
                                    `leavesTypes.${index}.is_carry_forward`
                                  ) && index === selectedcontainer
                                    ? !isEnabled
                                    : true
                                }
                                classNames={{
                                  input:
                                    ' text-[15px] bg-transparent   text-normal rounded-lg pl-5',
                                  label:
                                    'text-[#5f5f5f] text-sm font-normal ml-2',
                                }}
                              />
                            )}
                          />
                          {getFieldArrayFormErrorMessage(
                            index,
                            'carry_forward_days'
                          )}
                        </div>
                      ) : (
                        <p
                          className={
                            fields.is_active && fields.is_carry_forward
                              ? 'font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                              : 'text-[#5f5f5f] font-normal border border-[#d7d9db] pl-4 p-1 rounded-md'
                          }
                        >
                          {
                            leavestypesdata?.data?.data[index]
                              .carry_forward_days
                          }
                        </p>
                      )}
                      <p
                        className={
                          fields.is_active
                            ? 'font-normal  flex items-center gap-3'
                            : 'text-[#5f5f5f] font-normal  flex items-center gap-3'
                        }
                      >
                        Increments
                      </p>
                      {isEnabled && selectedcontainer === index ? (
                        <div>
                          <Controller
                            control={control}
                            name={`leavesTypes.${index}.increment`}
                            // 'increment'
                            defaultValue={
                              leavestypesdata?.data?.data[index]
                                .increment_alternate === true &&
                              leavestypesdata?.data?.data[index]
                                .increment_monthly === false
                                ? '1'
                                : leavestypesdata?.data?.data[index]
                                    .increment_alternate === false &&
                                  leavestypesdata?.data?.data[index]
                                    .increment_monthly === true
                                ? '2'
                                : '0'
                            }
                            rules={{ required: 'Required' }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                data={[
                                  { value: '0', label: 'No Increment' },
                                  { value: '1', label: 'Increment Alternate' },
                                  { value: '2', label: 'Increment Monthly' },
                                ]}
                                styles={{
                                  rightSection: { pointerEvents: 'none' },
                                }}
                                disabled={
                                  index === selectedcontainer
                                    ? !isEnabled
                                    : true
                                }
                                rightSection={<DownArrow />}
                                size='md'
                                classNames={{
                                  input:
                                    ' text-[15px] bg-transparent  text-normal rounded pl-5',
                                  label:
                                    'text-[#8E8E8E] text-sm font-normal ml-2',
                                }}
                              />
                            )}
                          />
                          {getFieldArrayFormErrorMessage(index, 'increment')}
                        </div>
                      ) : (
                        <p
                          className={
                            fields.is_active
                              ? 'font-normal border border-[#d7d9db] p-1 rounded-md pl-4'
                              : 'text-[#5f5f5f] font-normal border border-[#d7d9db]  p-1 rounded-md pl-4'
                          }
                        >
                          {leavestypesdata?.data?.data[index]
                            .increment_alternate === true &&
                          leavestypesdata?.data?.data[index]
                            .increment_monthly === false
                            ? 'Increment Alternate'
                            : leavestypesdata?.data?.data[index]
                                .increment_alternate === false &&
                              leavestypesdata?.data?.data[index]
                                .increment_monthly === true
                            ? 'Increment Monthly'
                            : 'No Increment'}
                        </p>
                      )}

                      <p className='text-[#5f5f5f] font-normal flex items-center gap-3 col-span-4  justify-end'>
                        <Controller
                          control={control}
                          name={`leavesTypes.${index}.is_avail_in_probation`}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              defaultChecked={fields.is_avail_in_probation}
                              label='Probation'
                              disabled={
                                index === selectedcontainer ? !isEnabled : true
                              }
                              classNames={{
                                label: fields.is_active
                                  ? 'font-normal '
                                  : 'text-[#5f5f5f] font-normal ',
                              }}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`leavesTypes.${index}.is_notice_period`}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              defaultChecked={fields.is_notice_period}
                              label='Notice Period'
                              disabled={
                                index === selectedcontainer ? !isEnabled : true
                              }
                              classNames={{
                                label: fields.is_active
                                  ? 'font-normal '
                                  : 'text-[#5f5f5f] font-normal ',
                              }}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`leavesTypes.${index}.is_work_from_home`}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              defaultChecked={fields.is_work_from_home}
                              label='W.F.H'
                              disabled={
                                index === selectedcontainer ? !isEnabled : true
                              }
                              classNames={{
                                label: fields.is_active
                                  ? 'font-normal '
                                  : 'text-[#5f5f5f] font-normal ',
                              }}
                            />
                          )}
                        />
                      </p>
                    </div>
                  </div>
                </form>
              ))}{' '}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LeaveTypes;
