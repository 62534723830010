import { useMutation, useQueryClient } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import { ErrorToast, SuccessToast } from 'services/toasterService';

export default function useDeleteCalendarEvent(closeModal) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.delete(
        apiEndPoints.CALENDAR_EVENTS,
        {
          data: data,
        }
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('calendar-events');
        closeModal();
        SuccessToast({ text: data?.message });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
