import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { useParams } from 'react-router-dom';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetEmployeeLeaves() {
  const { id, company_id } = useParams();
  return useQuery(['get-employee-leaves-in-admin'], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_LEAVES_API, {
        id: id,
        req_company_id: company_id,
      })
    );
    return res.data;
  });
}
