import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
export default function UseGetEmployeementTypes() {
  return useQuery(['get-employeement-leaves'], async () => {
    const response = await makeApiRequest.get(
      apiEndPoints.SETTINGS_EMPLOYMENT_TYPES
    );
    return response.data;
  });
}
