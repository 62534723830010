import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function UseGetLeaveModule(cb) {
  return useQuery(
    ['leave_module'],
    async () => {
      const response = await makeApiRequest.get(apiEndPoints.LEAVEMODULE);

      return response.data;
    },
    {
      onSuccess: (data) => {
        // console.log(data);
        data?.data?.filter((each) =>
          each.is_active === true ? cb(false) : null
        );
      },
    }
  );
}
