import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse, ScrollArea } from "@mantine/core";
import { AuthContext } from "context/authContext";

import { ReactComponent as SmallLogo } from "../assets/smallLogo.svg";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import { ReactComponent as WhiteMenuIcon } from "../assets/icons/whiteMenuIcon.svg";
import { ReactComponent as MenuThree } from "../assets/icons/menu3.svg";
import { ReactComponent as OrangeMenuIcon } from "../assets/icons/orangeMenuIcon.svg";
import { ReactComponent as ProjectsIcon } from "../assets/icons/projectsIcon.svg";
import { ReactComponent as ProjectsIconTwo } from "../assets/icons/projectsIcon2.svg";
import { ReactComponent as ProjectsIconThree } from "../assets/icons/projects3.svg";
import { ReactComponent as RepoIcon } from "../assets/icons/repoIcon.svg";
import { ReactComponent as RepoThree } from "../assets/icons/repo3.svg";
import { ReactComponent as CalenderIcon } from "../assets/icons/calenderIcon.svg";
import { ReactComponent as CalenderIconTwo } from "../assets/icons/calenderIcon2.svg";
import { ReactComponent as CalenderIconThree } from "../assets/icons/calender3.svg";
import { ReactComponent as OrganisationIcon } from "../assets/icons/organisationIcon.svg";
import { ReactComponent as OrganisationThree } from "../assets/icons/organisation3.svg";
import { ReactComponent as OrganisationTwo } from "../assets/icons/organisation2.svg";
import { ReactComponent as RepoTwo } from "../assets/icons/repoTwo.svg";
import { ReactComponent as MeetIcon } from "../assets/icons/MeetIcon.svg";
import { ReactComponent as MeetThree } from "../assets/icons/meet3.svg";
import { ReactComponent as MeetWhiteIcon } from "../assets/icons/meetPlus.svg";
import { ReactComponent as TeamTwo } from "../assets/icons/teamIcon2.svg";
import { ReactComponent as TeamIcon } from "../assets/icons/teamIcon.svg";
import { ReactComponent as TeamIconThree } from "../assets/icons/teamIcon3.svg";
// import { ReactComponent as SidebarArrow } from '../assets/icons/sidebarArrow.svg';
import { ReactComponent as DownArrow } from "../assets/icons/downArrow.svg";
import { ReactComponent as StoryWhiteIcon } from "../assets/icons/whiteStoryIcon.svg";
import { ReactComponent as StoryRedIcon } from "../assets/icons/storyRedIcon.svg";
import { ReactComponent as StoryIcon } from "../assets/icons/storyIcon.svg";

function Sidebar() {
  const location = useLocation();
  const path = location.pathname.split("/");

  const { authState } = useContext(AuthContext);

  const [isSidebarOpened, setSideBarOpened] = useState(false);
  const [triggerCollapse, setTriggerCollapse] = useState("");

  const handleSideBar = (data) => {
    setSideBarOpened(data);
    if (!data) {
      setTriggerCollapse("");
    }
  };

  useEffect(() => {
    if (isSidebarOpened) {
      if (path[3] === "analytics" || path[3] === "dashboard") {
        setTriggerCollapse(path[3]);
      } else if (
        path[2] === "team" ||
        path[2] === "organisation" ||
        path[2] === "repository" ||
        path[2] === "projects" ||
        path[2] === "meetings"
      ) {
        setTriggerCollapse(path[2]);
      }
    } else {
      setTriggerCollapse("");
    }
  }, [path, isSidebarOpened]);

  // setting the url for logo respect to admin and employee
  let logoComponent;

  if (isSidebarOpened) {
    logoComponent = authState?.is_admin ? (
      <Link to="admin/dashboard/productivity">
        <Logo className="w-24 mx-auto h-10" />
      </Link>
    ) : (
      <Link to="employee/analytics/dashboard">
        <Logo className="w-24 mx-auto h-10" />
      </Link>
    );
  } else {
    logoComponent = <SmallLogo className="p-1 h-10" />;
  }
  return (
    <div
      className={`shadow-one h-screen rounded-r-3xl  ease-linear duration-300 absolute z-[80] overflow-hidden select-none ${
        isSidebarOpened ? "w-64 bg-white" : "w-16 bg-primary"
      }`}
    >
      <div
        className="h-full"
        onMouseEnter={() => handleSideBar(true)}
        onMouseLeave={() => handleSideBar(false)}
      >
        <div className="bg-FAFAFA rounded-tr-3xl flex justify-center items-center py-4">
          {logoComponent}
        </div>
        <ScrollArea
          style={{ height: "80vh" }}
          styles={(theme) => ({
            scrollbar: {
              '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
                backgroundColor: "#FB7C51",
              },
            },
          })}
          className="pb-8"
        >
          <div
            className={`${
              isSidebarOpened
                ? "px-3"
                : "flex flex-col justify-center items-center"
            } mt-10`}
          >
            {/* dashboard for admin */}
            {authState?.is_admin ? (
              <>
                {" "}
                <div className="pb-1" id="dashboard">
                  {path[3] === "dashboard" ? (
                    <Link to="admin/dashboard/productivity">
                      {isSidebarOpened ? (
                        <div
                          className="bg-primary p-2 rounded-md flex items-center space-x-3"
                          onClick={() => setTriggerCollapse("dashboard")}
                        >
                          <span className="w-6">
                            <WhiteMenuIcon className="w-6" />
                          </span>
                          <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                            Dashboard
                          </p>
                        </div>
                      ) : (
                        <div className="p-2 flex">
                          <WhiteMenuIcon className="w-6" />
                        </div>
                      )}
                    </Link>
                  ) : (
                    <Link to="admin/dashboard/productivity">
                      <div
                        className={`${
                          isSidebarOpened ? "flex items-center space-x-3" : ""
                        } p-2`}
                        onClick={() => setTriggerCollapse("dashboard")}
                      >
                        <span className="w-6">
                          {isSidebarOpened ? (
                            <OrangeMenuIcon className="w-6" />
                          ) : (
                            <MenuThree className="w-6" />
                          )}
                        </span>
                        {isSidebarOpened ? (
                          <>
                            <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                              Dashboard
                            </p>
                            <DownArrow />
                          </>
                        ) : null}
                      </div>
                    </Link>
                  )}
                </div>
                <Collapse
                  in={triggerCollapse === "dashboard" ? true : false}
                  transitionDuration={200}
                  transitionTimingFunction="linear"
                >
                  <div className="pl-10 space-y-1 text-gray text-sm ml-2">
                    <p>
                      <Link
                        to="admin/dashboard/productivity"
                        className={`text-gray pt-1 ${
                          path[4] === "productivity" ? "font-bold" : ""
                        }`}
                      >
                        Productivity
                      </Link>
                    </p>
                  </div>
                </Collapse>
              </>
            ) : null}

            {/* dashboard for employees */}
            {!authState?.is_admin ? (
              <>
                <div className="pb-1" id="analytics">
                  {path[3] === "analytics" ? (
                    <Link to="employee/analytics/dashboard">
                      <>
                        {isSidebarOpened ? (
                          <div
                            className="bg-primary p-2 rounded-md flex items-center space-x-3"
                            onClick={() => setTriggerCollapse("analytics")}
                          >
                            <span className="w-6">
                              <WhiteMenuIcon className="w-6" />
                            </span>
                            <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                              My analytics
                            </p>
                          </div>
                        ) : (
                          <div className="p-2 flex">
                            <WhiteMenuIcon className="w-6" />
                          </div>
                        )}
                      </>
                    </Link>
                  ) : (
                    <Link to="employee/analytics/dashboard">
                      <div
                        className={`${
                          isSidebarOpened ? "flex items-center space-x-3" : ""
                        } p-2`}
                        onClick={() => setTriggerCollapse("analytics")}
                      >
                        <span className="w-6">
                          {isSidebarOpened ? (
                            <OrangeMenuIcon className="w-6" />
                          ) : (
                            <MenuThree className="w-6" />
                          )}
                        </span>
                        {isSidebarOpened ? (
                          <>
                            <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                              My analytics
                            </p>
                            <DownArrow />
                          </>
                        ) : null}
                      </div>
                    </Link>
                  )}
                </div>
                <Collapse
                  in={triggerCollapse === "analytics" ? true : false}
                  transitionDuration={200}
                  transitionTimingFunction="linear"
                >
                  <div className="pl-10 space-y-1 text-gray text-sm ml-2">
                    <Link to="employee/analytics/dashboard">
                      <p
                        className={
                          path[4] === "dashboard" ? "font-bold pb-1" : " pb-1"
                        }
                      >
                        Dashboard
                      </p>
                    </Link>
                    <Link to="employee/analytics/performance">
                      <p
                        className={path[4] === "performance" ? "font-bold" : ""}
                      >
                        Performance
                      </p>
                    </Link>
                  </div>
                </Collapse>{" "}
              </>
            ) : null}

            {/* Team */}
            {authState?.is_admin ||
            authState?.is_subadmin ||
            authState?.can_add_employees ? (
              <>
                <div
                  className="pb-1.5 mt-3"
                  id="team"
                  onClick={() => setTriggerCollapse("team")}
                >
                  {path[2] === "team" ? (
                    <Link to="team/my-team">
                      {isSidebarOpened ? (
                        <div className="bg-primary p-2 rounded-md flex items-center space-x-3">
                          <span className="w-6">
                            <TeamTwo className="w-6" />
                          </span>
                          <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                            Team
                          </p>
                        </div>
                      ) : (
                        <div className="p-2 flex">
                          <TeamTwo className="w-6" />
                        </div>
                      )}
                    </Link>
                  ) : (
                    <Link to="team/my-team">
                      <div
                        className={`${
                          isSidebarOpened ? "flex items-center space-x-3" : ""
                        } p-2`}
                      >
                        <span className="w-6">
                          {isSidebarOpened ? (
                            <TeamIcon className="w-6" />
                          ) : (
                            <TeamIconThree className="w-6" />
                          )}
                        </span>
                        {isSidebarOpened ? (
                          <>
                            <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                              Team
                            </p>
                            <DownArrow />
                          </>
                        ) : null}
                      </div>
                    </Link>
                  )}
                </div>
                <Collapse
                  in={triggerCollapse === "team" ? true : false}
                  transitionDuration={200}
                  transitionTimingFunction="linear"
                >
                  <div className="pl-10 space-y-1 text-gray text-sm  ml-2">
                    <Link to="team/my-team">
                      <p
                        className={`${
                          path[3] === "my-team" ? "font-bold" : ""
                        }  whitespace-nowrap`}
                      >
                        My team
                      </p>
                    </Link>
                    {authState?.is_admin || authState?.is_subadmin ? (
                      <>
                        <Link to="team/manage-leaves">
                          <p
                            className={`${
                              path[3] === "manage-leaves" ? "font-bold" : ""
                            }  whitespace-nowrap pt-1`}
                          >
                            Manage leaves
                          </p>
                        </Link>
                        <Link to="team/performance">
                          <p
                            className={`${
                              path[3] === "performance" ? "font-bold" : ""
                            }  whitespace-nowrap pt-1`}
                          >
                            Performance
                          </p>
                        </Link>
                      </>
                    ) : null}
                  </div>
                </Collapse>
              </>
            ) : null}

            {/* projects */}
            {/* <>
              <div
                className="pb-1.5 mt-3"
                id="projects"
                onClick={() => setTriggerCollapse("projects")}
              >
                {path[2] === "projects" ? (
                  <Link to="projects/my-projects">
                    {isSidebarOpened ? (
                      <div className="bg-primary p-2 rounded-md flex items-center space-x-3">
                        <span className="w-6">
                          <ProjectsIconTwo className="w-5" />
                        </span>
                        <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                          Projects
                        </p>
                      </div>
                    ) : (
                      <div className="p-2 flex">
                        <ProjectsIconTwo className="w-5" />
                      </div>
                    )}
                  </Link>
                ) : (
                  <Link to="projects/my-projects">
                    <div
                      className={`${
                        isSidebarOpened ? "flex items-center space-x-3" : ""
                      } p-2`}
                    >
                      <span className="w-6">
                        {isSidebarOpened ? (
                          <ProjectsIcon className="w-6" />
                        ) : (
                          <ProjectsIconThree className="w-5" />
                        )}
                      </span>
                      {isSidebarOpened ? (
                        <>
                          <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                            Projects
                          </p>
                          <DownArrow />
                        </>
                      ) : null}
                    </div>
                  </Link>
                )}
              </div>
              <Collapse
                in={triggerCollapse === "projects" ? true : false}
                transitionDuration={200}
                transitionTimingFunction="linear"
              >
                <div className="pl-10 space-y-1 text-gray text-sm  ml-2">
                  <Link to="projects/my-projects">
                    <p
                      className={`${
                        path[3] === "my-projects" ? "font-bold" : ""
                      }  whitespace-nowrap`}
                    >
                      My projects
                    </p>
                  </Link>
                  <Link to="projects/task-board">
                    <p
                      className={`${
                        path[3] === "task-board" ? "font-bold" : ""
                      }  whitespace-nowrap pt-1`}
                    >
                      Task board
                    </p>
                  </Link>
                </div>
              </Collapse>
            </> */}

            {/* repository */}
            <>
              {/* <div
                className="pb-1.5 mt-3 "
                id="repository"
                onClick={() => setTriggerCollapse("repository")}
              >
                {path[2] === "repository" ? (
                  <Link to="repository">
                    <>
                      {isSidebarOpened ? (
                        <div className="bg-primary p-2 rounded-md flex items-center space-x-3">
                          <span className="w-6">
                            <RepoTwo className="w-6" />
                          </span>
                          <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                            Repository
                          </p>
                        </div>
                      ) : (
                        <div className="p-2 flex">
                          <RepoTwo className="w-6" />
                        </div>
                      )}
                    </>
                  </Link>
                ) : (
                  <Link to="repository">
                    <div
                      className={`${
                        isSidebarOpened ? "flex items-center space-x-3" : ""
                      } p-2`}
                    >
                      <span className="w-6">
                        {isSidebarOpened ? (
                          <RepoIcon className="w-6" />
                        ) : (
                          <RepoThree className="w-6" />
                        )}
                      </span>
                      {isSidebarOpened ? (
                        <>
                          <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                            Repository
                          </p>
                          {
                            // in future if more sub menus are added to repository uncomment the below line
                            // <DownArrow />
                          }
                        </>
                      ) : null}
                    </div>
                  </Link>
                )}
              </div> */}
              {/* <Collapse
              in={triggerCollapse === 'repository' ? true : false}
              transitionDuration={200}
              transitionTimingFunction='linear'
            >
              <div className='pl-10 space-y-1 text-gray text-sm  ml-2'>
                <Link to='repository/files'>
                  <p
                    className={`${
                      path[3] === 'files' ? 'font-bold' : ''
                    }  whitespace-nowrap`}
                  >
                    My files
                  </p>
                </Link>
              </div>
            </Collapse> */}
            </>

            {/* meeting */}
            {/* <>
              <div
                className="pb-1.5 mt-3"
                id="meetings"
                onClick={() => setTriggerCollapse("meetings")}
              >
                {path[2] === "meetings" ? (
                  <Link to="meetings/calender">
                    <>
                      {isSidebarOpened ? (
                        <div className="bg-primary p-2 rounded-md flex items-center space-x-3">
                          <span className="w-6">
                            <MeetWhiteIcon className="w-6" />
                          </span>
                          <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                            Meetings
                          </p>
                        </div>
                      ) : (
                        <div className="p-2 flex">
                          <MeetWhiteIcon className="w-6" />
                        </div>
                      )}
                    </>
                  </Link>
                ) : (
                  <Link to="meetings/calender">
                    <div
                      className={`${
                        isSidebarOpened ? "flex items-center space-x-3" : ""
                      } p-2`}
                    >
                      <span className="w-6">
                        {isSidebarOpened ? (
                          <MeetIcon className="w-6" />
                        ) : (
                          <MeetThree className="w-6" />
                        )}
                      </span>
                      {isSidebarOpened ? (
                        <>
                          <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                            Meetings
                          </p>
                          <DownArrow />
                        </>
                      ) : null}
                    </div>
                  </Link>
                )}
              </div>
              <Collapse
                in={triggerCollapse === "meetings" ? true : false}
                transitionDuration={200}
                transitionTimingFunction="linear"
              >
                <div className="pl-10 space-y-1 text-gray text-sm  ml-2">
                  <Link to="meetings/calender">
                    <p
                      className={`text-gray pt-1 ${
                        path[3] === "calender" ? "font-bold" : ""
                      }  whitespace-nowrap`}
                    >
                      My calender
                    </p>
                  </Link>
                </div>
              </Collapse>
            </> */}
            {/* calender */}
            {authState?.is_admin ? null : (
              <div
                className="pb-1.5 mt-3"
                id="calender"
                onClick={() => setTriggerCollapse("my-calender")}
              >
                {path[3] === "my-calender" ? (
                  <Link
                    to={
                      authState?.is_admin
                        ? "admin/my-calender/leaves"
                        : "employee/my-calender/leaves"
                    }
                  >
                    {isSidebarOpened ? (
                      <div className="bg-primary p-2 rounded-md flex items-center space-x-3">
                        <span className="w-6">
                          <CalenderIconTwo className="w-6" />
                        </span>
                        <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                          {authState?.is_admin ? "Leaves & holidays" : "Leaves"}
                        </p>
                      </div>
                    ) : (
                      <div className="p-2 flex">
                        <CalenderIconTwo className="w-6" />
                      </div>
                    )}
                  </Link>
                ) : (
                  <Link
                    to={
                      authState?.is_admin
                        ? "admin/my-calender/leaves"
                        : "employee/my-calender/leaves"
                    }
                  >
                    <div
                      className={`${
                        isSidebarOpened ? "flex items-center space-x-3" : ""
                      } p-2`}
                    >
                      <span className="w-6">
                        {isSidebarOpened ? (
                          <CalenderIcon className="w-6" />
                        ) : (
                          <CalenderIconThree className="w-6" />
                        )}
                      </span>
                      {isSidebarOpened ? (
                        <>
                          <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                            {authState?.is_admin
                              ? "Leaves & holidays"
                              : "Leaves"}
                          </p>
                          {/* <DownArrow /> */}
                        </>
                      ) : null}
                    </div>
                  </Link>
                )}
              </div>
            )}
            {/* <Collapse
            in={calenderCollapse}
            transitionDuration={200}
            transitionTimingFunction='linear'
          >
            {authState?.is_admin ? (
              <div className='pl-10 space-y-1 text-gray text-sm '>
                {authState?.is_admin ? (
                  <Link to='admin/my-calender/leaves'>
                    <p
                      className={`${
                        path[4] === 'leaves' || path[4] === 'leavecalender'
                          ? 'font-medium'
                          : 'font-light'
                      }  whitespace-nowrap`}
                    >
                      Leaves & Holidays
                    </p>
                  </Link>
                ) : null}

                {
                  // Removed Organisation calender as there should be only one Calender all over the application
                  // <Link to='organisation/organisation-calender'>
                  //   <p
                  //     className={`${
                  //       path[3] === 'organisation-calender' ? 'font-bold' : ''
                  //     }  whitespace-nowrap pt-1`}
                  //   >
                  //     Organisation calendar
                  //   </p>
                  // </Link>
                }
              </div>
            ) : (
              <div className='pl-10 space-y-1 text-gray text-sm '>
                {!authState?.is_admin ? (
                  <Link to='employee/my-calender/leaves'>
                    <p
                      className={`${
                        path[4] === 'leaves' ? 'font-medium' : 'font-light'
                      }  whitespace-nowrap`}
                    >
                      Leaves
                    </p>
                  </Link>
                ) : null}

                {
                  // Removed Organisation calender as there should be only one Calender all over the application
                  // <Link to='organisation/organisation-calender'>
                  //   <p
                  //     className={`${
                  //       path[3] === 'organisation-calender' ? 'font-bold' : ''
                  //     }  whitespace-nowrap pt-1`}
                  //   >
                  //     Organisation calendar
                  //   </p>
                  // </Link>
                }
              </div>
            )}
          </Collapse> */}

            {/* organisation */}
            <div className="pb-1.5 mt-3" id="organisation">
              {path[2] === "organisation" ? (
                <Link to={"organisation/chart"}>
                  {isSidebarOpened ? (
                    <div
                      className="bg-primary p-2 rounded-md flex items-center space-x-3"
                      onClick={() => setTriggerCollapse("organisation")}
                    >
                      <span className="w-6">
                        <OrganisationTwo className="w-6" />
                      </span>
                      <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                        Organisation
                      </p>
                    </div>
                  ) : (
                    <div className="p-2 flex">
                      <OrganisationTwo className="w-6" />
                    </div>
                  )}
                </Link>
              ) : (
                <Link to="organisation/chart">
                  <div
                    className={`${
                      isSidebarOpened ? "flex items-center space-x-3" : ""
                    } p-2`}
                    onClick={() => setTriggerCollapse("organisation")}
                  >
                    <span className="w-6">
                      {isSidebarOpened ? (
                        <OrganisationIcon className="w-6 p-0.5" />
                      ) : (
                        <OrganisationThree className="w-6 p-0.5" />
                      )}
                    </span>
                    {isSidebarOpened ? (
                      <>
                        <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                          Organisation
                        </p>
                        <DownArrow />
                      </>
                    ) : null}
                  </div>
                </Link>
              )}
            </div>
            <Collapse
              in={triggerCollapse === "organisation" ? true : false}
              transitionDuration={200}
              transitionTimingFunction="linear"
            >
              <div className="pl-10 space-y-1 text-gray text-sm  ml-2">
                <Link to="organisation/chart">
                  <p
                    className={`${
                      path[3] === "chart" ? "font-bold" : ""
                    }  whitespace-nowrap`}
                  >
                    Organisation chart
                  </p>
                </Link>
                <Link to="organisation/organisation-calender">
                  <p
                    className={`${
                      path[3] === "organisation-calender" ? "font-bold" : ""
                    }  whitespace-nowrap pt-1`}
                  >
                    Organisation calendar
                  </p>
                </Link>
                {/* {authState?.is_admin ? (
                  <Link to='organisation/payroll'>
                    <p
                      className={`${
                        path[3] === 'payroll' ? 'font-bold' : ''
                      }  whitespace-nowrap pt-1`}
                    >
                      Payroll
                    </p>
                  </Link>
                ) : (
                  <Link to='organisation/payslips'>
                    <p
                      className={`${
                        path[3] === 'payslips' ? 'font-bold' : ''
                      }  whitespace-nowrap pt-1`}
                    >
                      Payslips
                    </p>
                  </Link>
                )} */}
                {authState?.is_admin ? (
                  <Link to="organisation/key-policies">
                    <p
                      className={`${
                        path[3] === "key-policies" ? "font-bold" : ""
                      }  whitespace-nowrap pt-1 `}
                    >
                      Key policies
                    </p>
                  </Link>
                ) : (
                  <Link to="organisation/employee/key-policies">
                    <p
                      className={`${
                        path[4] === "key-policies" ? "font-bold" : ""
                      }  whitespace-nowrap pt-1 `}
                    >
                      Key policies
                    </p>
                  </Link>
                )}
              </div>
            </Collapse>
          </div>
        </ScrollArea>
        {/* story board */}
        {/* <div
          className={`pb-1.5 absolute bottom-4 ${
            isSidebarOpened
              ? "px-3"
              : "flex flex-col justify-center items-center  left-2.5"
          } mt-10`}
          id="blogs"
        >
          {path[2] === "blogs" ? (
            <>
              {authState?.is_admin ? (
                <Link to="blogs">
                  <>
                    {isSidebarOpened ? (
                      <div className="bg-primary p-2 rounded-md flex items-center space-x-3">
                        <span className="w-6">
                          <StoryWhiteIcon className="w-6" />
                        </span>
                        <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                          Submit a story
                        </p>
                      </div>
                    ) : (
                      <div className="p-2 flex">
                        <StoryWhiteIcon className="w-6" />
                      </div>
                    )}
                  </>
                </Link>
              ) : (
                <Link to="employee/blogs/">
                  <>
                    {isSidebarOpened ? (
                      <div className="bg-primary p-2 rounded-md flex items-center space-x-3">
                        <span className="w-6">
                          <StoryWhiteIcon className="w-6" />
                        </span>
                        <p className="whitespace-nowrap text-white font-medium font-poppins tracking-normal">
                          Submit a story
                        </p>
                      </div>
                    ) : (
                      <div className="p-2 flex">
                        <StoryWhiteIcon className="w-6" />
                      </div>
                    )}
                  </>
                </Link>
              )}
            </>
          ) : (
            <>
              {authState?.is_admin ? (
                <Link to="blogs">
                  <div
                    className={`${
                      isSidebarOpened ? "flex items-center space-x-3" : ""
                    } p-2`}
                  >
                    <span className="w-6">
                      {isSidebarOpened ? (
                        <StoryIcon className="w-6" />
                      ) : (
                        <StoryRedIcon className="w-5" />
                      )}
                    </span>
                    {isSidebarOpened ? (
                      <>
                        <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                          Submit a story
                        </p>
                      </>
                    ) : null}
                  </div>
                </Link>
              ) : (
                <Link to="employee/blogs/">
                  <div
                    className={`${
                      isSidebarOpened ? "flex items-center space-x-3" : ""
                    } p-2`}
                  >
                    <span className="w-6">
                      {isSidebarOpened ? (
                        <StoryIcon className="w-6" />
                      ) : (
                        <StoryRedIcon className="w-5" />
                      )}
                    </span>
                    {isSidebarOpened ? (
                      <>
                        <p className="whitespace-nowrap font-medium font-poppins tracking-normal">
                          Submit a story
                        </p>
                      </>
                    ) : null}
                  </div>
                </Link>
              )}
            </>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
