import React, { useState } from 'react';
import moment from 'moment';
import EventModal from '../components/eventModal';
import { ReactComponent as InfoIconBlueIcon } from 'assets/icons/infoIconBlue.svg';

export default function Toolbar(props) {
  const [openEventModal, setOpenEventModal] = useState(false);

  const goToToday = () => {
    const now = new Date();
    let mDate = props.date;
    props.date.setMonth(now.getMonth());
    props.date.setYear(now.getFullYear());
    // props.date.setDate(now.getDate());
    const currentDate = new Date(mDate.getFullYear(), mDate.getMonth(), 1);
    props.onNavigate('current', currentDate);
  };

  const month = (onlyMonth) => {
    const date = moment(props.date);
    const day = date.format('DD');
    let month = date.format('MMMM');
    let year = date.format('YYYY');

    if (props?.view === 'day') {
      return (
        <span className='rbc-toolbar-label rbc-date'>
          <span>{`${day} ${month}, ${year}`}</span>
        </span>
      );
    }

    if (onlyMonth) {
      return (
        <span className='rbc-toolbar-label rbc-date'>
          <span>{`${month}`}</span>
        </span>
      );
    }
    return (
      <span className='rbc-toolbar-label rbc-date'>
        <span>{`${month} ${year}`}</span>
      </span>
    );
  };

  const getCustomToolbar = () => {
    const goToMonthView = () => {
      props.onView('month');
    };
    const goToAgendaView = () => {
      props.onView('agenda');
    };

    const goToBack = () => {
      let view = props?.view;
      let mDate = props.date;
      let newDate;
      if (view === 'month' || view === 'agenda') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      } else if (view === 'week') {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 1,
          1
        );
      }
      props.onNavigate('prev', newDate);
    };

    const goToNext = () => {
      let view = props?.view;
      let mDate = props.date;
      let newDate;
      if (view === 'month' || view === 'agenda') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      } else if (view === 'week') {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 1,
          1
        );
      }
      props.onNavigate('next', newDate);
    };

    return (
      <div className='rbc-toolbar block'>
        <div className='rbc-toolbar-item-2 flex items-center justify-between'>
          <div className='rbc-btn-group'>
            <button className='label-filter-off' onClick={goToBack}>
              <span>Previous</span>
            </button>

            <button className='label-filter-off' onClick={goToToday}>
              {/* {month(true)} */}
              This Month
            </button>

            <button className='label-filter-off' onClick={goToNext}>
              Next
            </button>
          </div>

          <div>
            <p className='text-primary font-bold text-[26px]'>{month()}</p>
          </div>

          <div className='flex'>
            <div className='flex items-center space-x-2 mr-3'>
              <InfoIconBlueIcon />
              <p className='text-sm text-[#4E4E4E] font-semibold'>
                Edit in preview
              </p>
            </div>

            <div className='rbc-btn-group'>
              <button className='' onClick={goToMonthView}>
                <span className='label-filter-off'>Month</span>
              </button>

              <button className='' onClick={goToAgendaView}>
                <span className='label-filter-off'>Agenda</span>
              </button>
            </div>

            <button
              onClick={() => setOpenEventModal(true)}
              style={{
                backgroundColor: '#FB7C51',
                color: 'white',
                border: 0,
              }}
            >
              + Add an event
            </button>
          </div>
        </div>
        <EventModal
          openModal={openEventModal}
          setOpenModal={setOpenEventModal}
          modalTitle={'Add an event'}
        />
      </div>
    );
  };

  return <>{getCustomToolbar()}</>;
}
