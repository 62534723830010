import { useState, useEffect, useContext } from 'react';
import {
  MultiSelect,
  Select,
  Switch,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';

import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as CalanderIcon } from 'assets/icons/calanderIcon.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clockIcon.svg';

import { DatePicker } from '@mantine/dates';
import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';
import dateFormatter from 'helper/dateFormatter';

import Usegetemployessfromteams from '../hooks/usegetemployess';
import UsegetgradesfromSettings from '../hooks/useGetgradesfromsettings';
import useAddApparisal from '../hooks/useAddapparisal';
import useGetApparisal from '../hooks/useGetapparisal';
import useUpdateApparisal from '../hooks/useUpdateapparisalreport';
import dayjs from 'dayjs';
import { AuthContext } from 'context/authContext';

const ApparisalFormField = ({
  apparisalEmployeData,
  setAddApparisalData,
  setApprasialSelectEmployeeModal,
  apparisalDataEmployee,
  employeeGrossSalary,
}) => {
  const { authState } = useContext(AuthContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({ mode: 'onChange' });

  const closeModal = () => {
    setAddApparisalData(false);
    setApprasialSelectEmployeeModal(false);
  };

  const getapparisaldata = useGetApparisal(apparisalDataEmployee?.id);
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    if (!apparisalDataEmployee) {
      setValue('current_salary', employeeGrossSalary);
    }
    // eslint-disable-next-line
  }, [employeeGrossSalary]);

  useEffect(() => {
    if (apparisalDataEmployee) {
      setValue(
        'current_salary',
        getapparisaldata?.data?.data[0].current_salary
      );
      setValue('hiked_salary', getapparisaldata?.data?.data[0].hiked_salary);
      setValue('description', getapparisaldata?.data?.data[0].description);
      setValue(
        'current_grade_id',
        `${getapparisaldata?.data?.data[0].current_grade}`
      );
      setValue(
        'hike_percentage',
        getapparisaldata?.data?.data[0].hike_percentage
      );
    }
    // eslint-disable-next-line
  }, [apparisalDataEmployee, getapparisaldata?.data?.data]);

  const totalEmployess = Usegetemployessfromteams();
  const gradesData = UsegetgradesfromSettings();
  const addApparisalMutation = useAddApparisal(closeModal);
  const updateApparisalmutation = useUpdateApparisal(closeModal);

  const [newSalary, setNewSalary] = useState(0);
  const [isInvalid, setIsInvalid] = useState(false);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className='text-[#D0342C] text-sm'>{errors[name].message}</p>
      )
    );
  };
  useEffect(() => {
    if (watch('meating_to') > watch('Meating_From')) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
    // eslint-disable-next-line
  }, [watch('meating_to')]);
  useEffect(() => {
    const percentageamount =
      (watch('hike_percentage') / 100) * watch('current_salary');

    setNewSalary(
      parseFloat(percentageamount) + parseFloat(watch('current_salary'))
    );
    // eslint-disable-next-line
  }, [watch('hike_percentage')]);

  const submitApparissal = (data) => {
    data = {
      ...data,
      appraisal_meeting: {
        created_by_id: authState?.user_id,
        description: data.description,
        for_date: dateFormatter(data?.Meating_date, 'YYYY-MM-DD'),
        from_time: data?.Meating_From,
        location: data?.location ? data?.location : 'Online',
        name: data?.Meeting_title,
        required_attendes: data?.Required_attendees,
        to_date: dateFormatter(data?.Meating_date, 'YYYY-MM-DD'),
        to_time: data?.meating_to,
        type: 'meeting',
      },
    };
  
    delete data?.Meating_From;
    delete data?.Meating_date;
    delete data?.Meeting_title;
    delete data?.Required_attendees;
    delete data?.apparisal_meating;
    delete data?.is_online;
    delete data?.location;
    delete data?.meating_to;
    if (!watch('apparisal_meating')) {
      delete data.appraisal_meeting;
    }

    apparisalDataEmployee
      ? updateApparisalmutation.mutate({
          ...data,
          id: apparisalDataEmployee?.id,
          hiked_salary: newSalary,
          employee_id: apparisalEmployeData?.Select_employee,
          review_type_id: apparisalEmployeData?.review_type,
          created_by_id: authState?.user_id,
          segment_from: apparisalDataEmployee
            ? apparisalDataEmployee?.segment_from
            : dayjs(`${apparisalEmployeData?.segment[0]}`).format('YYYY-MM-DD'),
          segment_to: apparisalDataEmployee
            ? apparisalDataEmployee?.segment_to
            : dayjs(`${apparisalEmployeData?.segment[1]}`).format('YYYY-MM-DD'),
          is_draft: 'False',
        })
      : addApparisalMutation.mutate({
          ...data,
          hiked_salary: newSalary,
          employee_id: apparisalEmployeData?.Select_employee,
          review_type_id: apparisalEmployeData?.review_type,
          created_by_id: authState?.user_id,
          segment_from: dayjs(`${apparisalEmployeData?.segment[0]}`).format(
            'YYYY-MM-DD'
          ),
          segment_to: dayjs(`${apparisalEmployeData?.segment[1]}`).format(
            'YYYY-MM-DD'
          ),
          is_draft: 'False',
        });
  };

  const saveAsDraft = (data) => {
    delete data?.Meating_From;
    delete data?.Meating_date;
    delete data?.Meeting_title;
    delete data?.Required_attendees;
    delete data?.apparisal_meating;
    delete data?.is_online;
    delete data?.location;
    delete data?.meating_to;
    apparisalDataEmployee
      ? updateApparisalmutation.mutate({
          ...data,
          id: apparisalDataEmployee?.id,
          hiked_salary: newSalary,
          employee_id: apparisalEmployeData?.Select_employee,
          review_type_id: apparisalEmployeData?.review_type,
          created_by_id: authState?.user_id,
          segment_from: apparisalDataEmployee
            ? apparisalDataEmployee?.segment_from
            : dayjs(`${apparisalEmployeData?.segment[0]}`).format('YYYY-MM-DD'),
          segment_to: apparisalDataEmployee
            ? apparisalDataEmployee?.segment_to
            : dayjs(`${apparisalEmployeData?.segment[1]}`).format('YYYY-MM-DD'),
          is_draft: 'True',
        })
      : addApparisalMutation.mutate({
          ...data,
          hiked_salary: newSalary,
          employee_id: apparisalEmployeData?.Select_employee,
          review_type_id: apparisalEmployeData?.review_type,
          created_by_id: authState?.user_id,
          segment_from: dayjs(`${apparisalEmployeData?.segment[0]}`).format(
            'YYYY-MM-DD'
          ),
          segment_to: dayjs(`${apparisalEmployeData?.segment[1]}`).format(
            'YYYY-MM-DD'
          ),
          is_draft: 'True',
        });
  };
  // calculating difference between start and end time in meeting
  const diffTime = (fromtime, totime) => {
    const ft = dayjs(`2000-01-01 ${fromtime}`);
    const tt = dayjs(`2000-01-01 ${totime}`);
    const mins = tt.diff(ft, 'minutes', true);
    var totalHours = parseInt(mins / 60);
    var totalMins = dayjs().minute(mins).$m;
    return totalHours
      ? totalHours + 'hrs.' + totalMins + 'mins'
      : totalMins + 'mins';
  };
  return (
    <div className='mt-8'>
      <form onSubmit={handleSubmit(submitApparissal)}>
        <div className='grid grid-cols-3 gap-3'>
          <div>
            <Controller
              control={control}
              name='current_salary'
              rules={{
                required: 'Required',
              }}
              render={({ field }) => (
                <TextInput
                  size='md'
                  type='number'
                  readOnly
                  label='Gross Annual Salary'
                  classNames={{
                    input: 'border-primary',
                    label: 'text-[#8E8E8E] text-sm',
                  }}
                  {...field}
                />
              )}
            />
            {getFormErrorMessage('current_salary')}
          </div>
          <div>
            <Controller
              control={control}
              name='hike_percentage'
              rules={{
                required: 'Required',
                min: {
                  value: 0,
                  message: 'min value is 0',
                },
                max: {
                  value: 100,
                  message: 'max value is 100',
                },
              }}
              render={({ field }) => (
                <TextInput
                  size='md'
                  type='number'
                  label='Hike percentage*'
                  classNames={{
                    input: 'border-primary',
                    label: 'text-[#8E8E8E] text-sm',
                  }}
                  {...field}
                />
              )}
            />
            {getFormErrorMessage('hike_percentage')}
          </div>
          <div>
            <Controller
              control={control}
              name='hiked_salary'
              render={({ field }) => (
                <TextInput
                  {...field}
                  value={parseFloat(`${newSalary}`).toFixed(2)}
                  size='md'
                  readOnly
                  type='number'
                  label='New gross annual salary'
                  classNames={{
                    input: 'border-primary',
                    label: 'text-[#8E8E8E] text-sm',
                  }}
                />
              )}
            />
            {getFormErrorMessage('hiked_salary')}
          </div>
          <div>
            <Controller
              control={control}
              name='current_grade_id'
              rules={{
                required: 'Required',
              }}
              render={({ field }) => (
                <Select
                  size='md'
                  label='Grade*'
                  rightSection={<DownArrow />}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  data={
                    gradesData?.data?.data
                      ?.filter((element) => element?.is_active)
                      ?.map((eachGrade) => {
                        return {
                          value: `${eachGrade.id}`,
                          label: eachGrade.name,
                        };
                      }) || []
                  }
                  classNames={{
                    input: 'border-primary',
                    label: 'text-[#8E8E8E] text-sm',
                  }}
                  {...field}
                />
              )}
            />
            {getFormErrorMessage('current_grade_id')}
          </div>
        </div>
        {/* Schedule an appraisal meeting */}
        <div className='flex justify-between item-center mt-10'>
          <h1 className='text-lg font-semibold '>
            Schedule an appraisal meeting
          </h1>
          <Controller
            control={control}
            name='apparisal_meating'
            render={({ field }) => <Switch {...field} />}
          />
        </div>
        <div
          className={
            watch('apparisal_meating') ? null : 'opacity-50 cursor-not-allowed'
          }
        >
          <div className='mt-4'>
            <div>
              <Controller
                control={control}
                name='Meeting_title'
                // rules={{
                //   required: 'Required',
                // }}
                render={({ field }) => (
                  <TextInput
                    size='md'
                    label='Meeting title'
                    disabled={!watch('apparisal_meating')}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-[#8E8E8E] text-sm',
                    }}
                    {...field}
                  />
                )}
              />
              {getFormErrorMessage('Meeting_title')}
            </div>
            <div className='mt-4'>
              <Controller
                control={control}
                name='Required_attendees'
                // rules={{
                //   required: 'Required',
                // }}
                render={({ field }) => (
                  <MultiSelect
                    size='md'
                    label='Required attendees'
                    data={
                      totalEmployess?.data?.data.map((eachEmployee) => {
                        return {
                          value: `${eachEmployee?.user__email}`,
                          label: eachEmployee?.user__first_name,
                        };
                      }) || []
                    }
                    searchable
                    disabled={!watch('apparisal_meating')}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-[#8E8E8E] text-sm',
                    }}
                    rightSection={<DownArrow />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    {...field}
                  />
                )}
              />
              {getFormErrorMessage('Required_attendees')}
            </div>
            <div className='grid grid-cols-10 gap-3 mt-4 '>
              <div className='col-span-3'>
                <Controller
                  control={control}
                  name='Meating_date'
                  //   rules={{
                  //     required: 'Required',
                  //   }}
                  render={({ field }) => (
                    <DatePicker
                      size='md'
                      label='Schedule on'
                      minDate={dayjs(new Date()).toDate()}
                      disabled={!watch('apparisal_meating')}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-[#8E8E8E] text-sm',
                      }}
                      rightSection={<CalanderIcon />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage('Meating_date')}
              </div>
              <div className='col-span-3'>
                <Controller
                  control={control}
                  name='Meating_From'
                  rules={
                    {
                      //     required: 'Required',
                      //  validate:()=>getValues('Meating_From')<getValues('meating_to')|| "invalid time"
                    }
                  }
                  render={({ field }) => (
                    <TextInput
                      size='md'
                      label='From'
                      disabled={!watch('apparisal_meating')}
                      type='time'
                      rightSection={<ClockIcon />}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-[#8E8E8E] text-sm',
                      }}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage('Meating_From')}
              </div>
              <div className='col-span-3'>
                <Controller
                  control={control}
                  name='meating_to'
                  rules={{
                    // required: 'Required',

                    validate: () =>
                      !watch('apparisal_meating') |
                        (getValues('Meating_From') < getValues('meating_to')) ||
                      'invalid time',
                  }}
                  render={({ field }) => (
                    <TextInput
                      size='md'
                      label='To'
                      disabled={!watch('apparisal_meating')}
                      type='time'
                      rightSection={<ClockIcon />}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-[#8E8E8E] text-sm',
                      }}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage('meating_to')}
              </div>

              {
                //calculating time difference
                watch('Meating_From') && watch('meating_to') && isInvalid && (
                  <p className='mt-10 text-center text-xs '>
                    {diffTime(watch('Meating_From'), watch('meating_to'))}
                  </p>
                )
              }
            </div>
          </div>
          <div className='flex justify-between item-center mt-10 mb-2'>
            <p className='text-sm text-[#8E8E8E]'>Location</p>
            <p className='gap-3 flex text-sm text-[#8E8E8E]'>
              {' '}
              Online
              <Controller
                control={control}
                name='is_online'
                defaultValue='true'
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={isChecked}
                    onChange={(event) =>
                      setChecked(event.currentTarget.checked)
                    }
                  />
                )}
              />
            </p>
          </div>
          <div>
            <Controller
              control={control}
              name='location'
              //   rules={{
              //     required: 'Required',
              //   }}
              render={({ field }) => (
                <TextInput
                  size='md'
                  disabled={isChecked}
                  classNames={{
                    input: 'border-primary',
                    label: 'text-[#8E8E8E] text-sm',
                  }}
                  {...field}
                />
              )}
            />
            {getFormErrorMessage('location')}
          </div>
        </div>

        <div className='mt-8'>
          <Controller
            control={control}
            // name='name'
            name='description'
            rules={{
              required: 'Required',
            }}
            render={({ field }) => (
              <Textarea
                {...field}
                size='md'
                label='Description*'
                placeholder='Type here'
                classNames={{
                  input: 'h-[75px] border-primary',
                  label: 'text-[#000] text-normal font-bold',
                }}
              />
            )}
          />
          {getFormErrorMessage('description')}
        </div>
        <div className='flex justify-center items-center gap-4 my-6 mt-8'>
          <button type='button' onClick={handleSubmit(saveAsDraft)}>
            <SecondaryButton>Save as draft</SecondaryButton>
          </button>
          <button>
            {/* <PrimaryButton>Generate report</PrimaryButton> */}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ApparisalFormField;
