import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetspecificpayrolldata(
  payrollId,
  employeeId,
  searchText,
  filterType
) {
  //   console.log(payrollId, employeeId);
  return useQuery(
    ['employee-payroll-data', payrollId, employeeId, searchText, filterType],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_PAYROLL_API, {
          payroll_id: payrollId,
          id: employeeId,
          search: searchText,
          employment_type_id: filterType,
        })
      );

      return response.data;
    },
    {
      enabled: !!payrollId,
    }
  );
}
