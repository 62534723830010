import { useMutation, useQueryClient } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { SuccessToast, ErrorToast } from 'services/toasterService';

export default function Useupdatethepolicybyemployee(cb) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(
        apiEndPoints.EMPLOYEE_PLCICIES,
        data
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('employee-policies');
        cb();
        SuccessToast({ text: data.message });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
