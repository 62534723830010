import { Input, Loader, Tooltip } from '@mantine/core';

import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { ReactComponent as Nopolicies } from 'assets/icons/nopolicies.svg';
import pdfIcon from 'assets/images/pdfIconk.png';
import updateimage from 'assets/images/updatebackgroundIcon.png';
import { useState } from 'react';
import EmployeePoliciModal from './components/employeepolicimodal';
import Usegetemployeepolicies from './hooks/usegetemployeepolicies';
import Header from 'components/Header';

const EmployeeKeyPolicies = () => {
  const [searchInput, setSearchInput] = useState(null);
  const getemployeepolicies = Usegetemployeepolicies(searchInput);

  const [openmodalemployee, setOpenModalEmployee] = useState(false);
  const [file, setFile] = useState(null);
  const [policiId, setPoliciId] = useState(null);
  const [isPolicySigned, setIsPolicySigned] = useState('');
  const [isUpdated, setIsUpdated] = useState('');

  return (
    <>
      <div className='px-5'>
        <div className='flex justify-between items-center mt-6'>
          <p className='text-xl text-A8A8A8'>
            Organisation &#60;{' '}
            <span className='text-gray font-semibold'>Key policies</span>
          </p>
          <Header />
        </div>
        <div className='w-40 ml-auto mt-6'>
          <Input
            icon={<SearchIcon />}
            placeholder='Search'
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
            classNames={{
              wrapper: 'border-primary border-b',
              input: 'border-none bg-light-bg',
            }}
            rightSection={
              <Loader
                size='xs'
                className={`${
                  searchInput && getemployeepolicies.isLoading
                    ? 'visible'
                    : 'invisible'
                }`}
              />
            }
          />
        </div>
        {getemployeepolicies?.data?.data.length === 0 ? (
          <div className='mt-8 bg-[#fff] h-[80vh] shadow-xl rounded-3xl flex flex-col justify-center items-center'>
            <Nopolicies />
            <p className='mt-2 text-[#535353]'>No policy document added yet</p>
          </div>
        ) : (
          <div className='grid grid-cols-4 mx-4 mt-8 gap-4'>
            {getemployeepolicies?.data?.data.map((eachpolicy) => {
              return (
                <div
                  className={
                    eachpolicy?.policy_document
                      ? 'w-100% bg-[#fff] shadow-2xl rounded-xl min-h-[200px] cursor-pointer'
                      : 'w-100% bg-[#fff] shadow-2xl rounded-xl min-h-[200px] cursor-not-allowed opacity-60'
                  }
                  onClick={() => {
                    if (eachpolicy.policy_document) {
                      setOpenModalEmployee(true);
                      setFile(eachpolicy.policy_document);
                      setPoliciId(eachpolicy.id);
                      setIsPolicySigned(eachpolicy?.is_signed);
                      setIsUpdated(eachpolicy?.is_updated);
                    }
                  }}
                  key={eachpolicy.id}
                >
                  <div
                    className='h-[70%]  rounded-t-xl flex justify-between items-start gap-3'
                    style={{
                      backgroundImage: 'URL(' + pdfIcon + ')',
                      // backgroundSize: '100% 70%',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    {!eachpolicy.is_signed ? (
                      <div
                        className='w-[40%] flex justify-center '
                        style={{
                          backgroundImage: 'URL(' + updateimage + ')',
                          backgroundSize: '100% 100%',
                          backgroundPosition: '100% 100%',
                        }}
                      >
                        <h1 className='p-2 text-[#FFFFFF]'>
                          {eachpolicy?.is_updated ? 'Updated' : 'New'}
                        </h1>
                      </div>
                    ) : null}
                    {/* <p className='p-2 justify-end text-right'>10 mins read</p> */}
                  </div>
                  <div className='text-lg flex  p-4 justify-between items-center'>
                    {eachpolicy.name?.length > 19 ? (
                      <Tooltip
                        label={eachpolicy.name}
                        width={220}
                        wrapLines
                        classNames={{
                          body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                        }}
                        radius='md'
                      >
                        <h1 className='text-lg font-medium w-40 truncate'>
                          {eachpolicy.name}
                        </h1>
                      </Tooltip>
                    ) : (
                      <h1 className='text-lg font-medium w-40 truncate'>
                        {eachpolicy.name}
                      </h1>
                    )}
                    {eachpolicy.is_signed ? (
                      <p
                        className='font-normal text-[#28B446] text-sm cursor-pointer'
                        //   onClick={() =>
                        //     navigate(
                        //       `/app/organisation/key-policies/${eachpolicy.id}`,
                        //       {
                        //         replace: true,
                        //       }
                        //     )
                        //   }
                      >
                        Signed
                      </p>
                    ) : (
                      <p
                        className='font-normal text-[#FBBB00] text-sm '
                        //   onClick={() =>
                        //     navigate(
                        //       `/app/organisation/key-policies/${eachpolicy.id}`,
                        //       {
                        //         replace: true,
                        //       }
                        //     )
                        //   }
                      >
                        Pending
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <EmployeePoliciModal
        setOpenModalEmployee={setOpenModalEmployee}
        openmodalemployee={openmodalemployee}
        file={file}
        setFile={setFile}
        setPoliciId={setPoliciId}
        policiId={policiId}
        isPolicySigned={isPolicySigned}
        isUpdated={isUpdated}
      />
    </>
  );
};

export default EmployeeKeyPolicies;
