import React, { useState } from 'react';
import { Select } from '@mantine/core';
import { ReactComponent as UpcomingEventsImg } from 'assets/images/upcomingEvents.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow.svg';
import useUpcomingEvents from '../hooks/useUpcomingEvents';
import EventCard from './EventCard';

export default function UpcomingEvents() {
  const [selectEvent, setSelectEvent] = useState('Events');

  const allUpcomingEvents = useUpcomingEvents();

  return (
    <div className='bg-white shadow-four rounded-[10px] relative h-[350px]'>
      <div className='flex items-center justify-between py-3 px-9'>
        <p className='text-18 font-semibold text-222222'>What's coming up</p>
        <Select
          data={['Birthdays', 'Events', 'Holidays', 'Rookies']}
          value={selectEvent}
          onChange={(event) => setSelectEvent(event)}
          icon={<FilterIcon />}
          rightSection={<DownArrowIcon />}
          className='w-40'
          classNames={{
            rightSection: 'pointer-events-none',
            input: 'border-0 border-b-2 rounded-none border-primary',
          }}
        />
      </div>

      {allUpcomingEvents?.isSuccess &&
      allUpcomingEvents?.data?.data?.[selectEvent.toLocaleLowerCase()]
        ?.length === 0 ? (
        <div className='flex flex-col items-center absolute left-1/2 top-1/2 -translate-y-1/2	-translate-x-1/2 mt-10'>
          <UpcomingEventsImg />
          <p className='text-gray2 mt-10'>No data to show</p>
        </div>
      ) : (
        <div className='h-[80%] overflow-y-scroll scroller space-y-6 px-9'>
          {allUpcomingEvents?.data?.data?.[
            selectEvent.toLocaleLowerCase()
          ]?.map((event) => {
            return (
              <EventCard
                key={event?.id}
                event={event}
                eventType={selectEvent}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
