import { ErrorBoundary } from 'react-error-boundary';

import PrimaryButton from 'components/primaryButton';

import { ReactComponent as SomethngWentWrongIlls } from 'assets/images/SomethngWentWrongIlls.svg';
import { useState } from 'react';
import { Modal } from '@mantine/core';

const FallBackUI = () => {
  const [isModalOpen, setModalOpen] = useState(true);
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setModalOpen(true)}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      centered
      overlayOpacity={0.5}
      transitionDuration={100}
      size='90%'
      classNames={{
        title: 'text-lg font-semibold',
        body: 'scroller',
      }}
    >
      <div className='w-full'>
        <div className='bg-white shadow-lg mx-auto max-w-main-page w-10/12 mt-10 py-4 rounded h-auto absolute transform -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2'>
          <div className='w-1/3 ml-10'>
            <SomethngWentWrongIlls />
          </div>
          <p className='text-2xl text-center my-3 mt-10'>Unable to connect!</p>
          <p className='text-lg text-center'>
            Something went wrong, Please try again later.
          </p>
          <div className='flex justify-center my-6'>
            <button className='mx-auto w-32' onClick={refreshPage}>
              <PrimaryButton>Refresh</PrimaryButton>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ComponentLevelErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={FallBackUI}>{children}</ErrorBoundary>
  );
};

export default ComponentLevelErrorBoundary;
