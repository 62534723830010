import { AuthContext } from 'context/authContext';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { SuccessToast, ErrorToast } from 'services/toasterService';

export default function useUpdateEmployeePersonalInfo(
  closeModal,
  isModalOpen = true
) {
  const queryClient = useQueryClient();

  const { authDispatch } = useContext(AuthContext);
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(
        apiEndPoints.EMPLOYEE_PERSONAL_INFO_API,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data?.message });
        if (isModalOpen) {
          closeModal();
        }
        if (data?.data) {
          authDispatch({
            type: 'UPDATEPROFILEIMAGE',
            payload: data?.data,
          });
        }
        queryClient.invalidateQueries('get-employee-personal-info');
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
