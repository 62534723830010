
import { useMutation, useQueryClient } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { SuccessToast, ErrorToast } from 'services/toasterService';

export default function useUpdatePerformancereport(cb) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(apiEndPoints.PERFORMANCE_UPDATE_API, data);
      return response?.data;

    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data?.message });
        cb();
        queryClient.invalidateQueries('performance-report-in-admin');
        
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      
      },
    }
    
  );
}
