import toast from 'react-hot-toast';

const SuccessToast = ({ text }) => {
  toast.success(<p>{text}</p>);
};

const ErrorToast = ({ text }) => {
  toast.error(<p>{text}</p>);
};

export { SuccessToast, ErrorToast };
