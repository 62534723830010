import React from 'react';
import { Modal } from '@mantine/core';
import { ReactComponent as DeleteEventBg } from 'assets/images/DeleteEventBg.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/crossIcon.svg';
import PrimaryButton from 'components/primaryButton';
import useDeleteCalendarEvent from '../hooks/useDeleteCalendarEvent';

export default function DeleteEventModal({
  openModal,
  setOpenModal,
  selectEventToDelete,
}) {
  const closeModal = () => {
    setOpenModal(false);
  };

  const deleteCalendarEventMutation = useDeleteCalendarEvent(closeModal);

  return (
    <Modal
      opened={openModal}
      onClose={setOpenModal}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      overflow='inside'
      centered
      size={'lg'}
      radius='md'
      transitionDuration={100}
      withCloseButton={false}
      classNames={{
        modal: 'p-0',
        body: 'mt-10 mb-14',
      }}
    >
      <div className='space-y-4 relative'>
        <p className='text-2xl leading-7 font-normal text-neutralsBlack text-center'>
          Are you sure you want to <span className='font-bold'>delete</span>
          <br /> the event?
        </p>
        <DeleteEventBg className='w-full' />
        <div className='text-center space-x-7'>
          <button
            className='w-36 border p-1.5 rounded border-primary text-primary'
            onClick={() => {
              deleteCalendarEventMutation.mutate({
                id: selectEventToDelete,
              });
            }}
          >
            Yes
          </button>
          <button className='w-36' onClick={closeModal}>
            <PrimaryButton>
              <p>No</p>
            </PrimaryButton>
          </button>
        </div>
      </div>

      <CloseIcon
        className='w-12 h-12 absolute top-3 right-3 cursor-pointer'
        onClick={closeModal}
      />
    </Modal>
  );
}
