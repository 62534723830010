import { Route, Routes } from 'react-router-dom';
import Chart from './pages/chart/chart';
import EmployeeKeyPolicies from './pages/employeekeypolicies/employeepolicies';
import SpecificPolicy from './pages/keyPolicies/components/specificpolicy';
import KeyPolicies from './pages/keyPolicies/keypolicies';
import Payroll from './pages/payroll/payroll';
import Payslips from './pages/payslips/payslip';
import OrganisationCalender from 'pages/adminCalender/pages/organisation-calendar/calendar';
import NoPageFound from 'pages/common/pageNotFound';

const Organisation = () => {
  return (
    <Routes>
      <Route path='payroll' element={<Payroll />} />
      <Route path='payslips' element={<Payslips />} />
      <Route path='chart' element={<Chart />} />
      <Route path='key-policies' element={<KeyPolicies />} />
      <Route path='key-policies/:id' element={<SpecificPolicy />} />
      <Route path='employee/key-policies' element={<EmployeeKeyPolicies />} />
      <Route path='organisation-calender' element={<OrganisationCalender />} />

      {/* empty page when route not found */}
      <Route path='*' element={<NoPageFound />} />
    </Routes>
  );
};

export default Organisation;
