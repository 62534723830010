import { Tabs } from "@mantine/core";
import PrimaryButton from "components/primaryButton";
import React, { useState } from "react";
import DepartmentModal from "../components/departmentModal";
import useGetAllDepartments from "../hooks/useGetAllDepartments";
import Departments from "./departments";
import Designations from "./designations";

export default function DepartmentDesignationLayout() {
  const [activeTab, setActiveTab] = useState(0);
  const [departmentModalOpen, setDepartmentModalOpen] = useState(false);

  const departmentsData = useGetAllDepartments();

  return (
    <div className="bg-white shadow-three departments relative">
      <Tabs
        active={activeTab}
        onTabChange={setActiveTab}
        tabPadding={21}
        classNames={{
          tabsListWrapper: "border-0 px-6 pt-4",
          tabActive: "border-2 border-primary font-semibold px-0",
          tabControl: "text-A8A8A8 leading-5 px-0 mr-8",
          tabLabel: "text-base text-[#333333]",
        }}
      >
        <Tabs.Tab label="Department">
          <Departments />
        </Tabs.Tab>
        <Tabs.Tab label="Designation">
          <Designations />
        </Tabs.Tab>
      </Tabs>

      <div className="flex items-center space-x-4 absolute top-0 right-0">
        <div className="px-6 pt-4">
          {activeTab === 0 && departmentsData?.data?.data?.length !== 0 && (
            <button onClick={() => setDepartmentModalOpen(true)}>
              <PrimaryButton>
                <p>+ Add department</p>
              </PrimaryButton>
            </button>
          )}
        </div>
        </div>


       {/* Modal for adding department */}
       <DepartmentModal
        isModalOpen={departmentModalOpen}
        setIsModalOpen={setDepartmentModalOpen}
        modalHeader={"Add a department"}
      />
    </div>
  );
}
