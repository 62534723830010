import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Modal, Select, Textarea, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm, Controller } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';

import useApplyLeave from '../hooks/useApplyLeave';
import useEditAppliedLeave from '../hooks/useEditAppliedLeave';
import useGetEmployeeAppliedLeaves from '../hooks/useGetEmployeeAppliedLeaves';

import { AuthContext } from 'context/authContext';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/crossIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';

export default function ApplyLeaveModal({
  isModalOpen,
  setIsModalOpen,
  employeeLeavesInfo,
  modalName,
  selectedLeaveData,
  setSelectedLeaveData,
  validLeaves,
  leavePolicyInfo,
}) {
  const [selectedLeave, setSelectedLeave] = useState('');
  const [leaveCount, setLeaveCount] = useState('');
  const [renderCount, setRenderCount] = useState(0);
  const { authState } = useContext(AuthContext);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLeaveData('');
    setRenderCount(0);
  };

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-12 absolute right-0 cursor-pointer'
        />
        <p>{modalName} leave</p>
      </div>
    );
  };

  const defaultValues = {
    leave_type_id: '',
    number_of_days: '',
    from_date: '',
    to_date: '',
    reason: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const applyLeaveMutation = useApplyLeave(closeModal, reset);
  const editAppliedLeaveMutation = useEditAppliedLeave(closeModal, reset);

  const onSubmit = (data) => {
    const finalData = {
      leave_type_id: data?.leave_type_id,
      number_of_days: leaveCount,
      from_date: dayjs(data?.from_date).format('YYYY-MM-DD'),
      to_date: dayjs(data?.to_date).format('YYYY-MM-DD'),
      reason: data?.reason,
    };

    if (modalName === 'Apply') {
      finalData['employee_id'] = authState?.employee_id;
      applyLeaveMutation.mutate(finalData);
    } else {
      finalData['id'] = selectedLeaveData?.id;
      editAppliedLeaveMutation.mutate(finalData);
    }
  };

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const watchLeaveType = watch('leave_type_id');
  const watchFormDate = watch('from_date');
  const watchToDate = watch('to_date');

  useEffect(() => {
    setRenderCount(renderCount + 1);
    employeeLeavesInfo?.map((item) => {
      if (~~item?.id === ~~watchLeaveType) {
        setSelectedLeave(item);
      }
      return true;
    });
    if (modalName === 'Edit' && renderCount > 1) {
      setValue('from_date', null);
      setValue('to_date', null);
      setLeaveCount('');
    }
    if (modalName === 'Apply' || renderCount > 1) {
      setValue('from_date', null);
      setValue('to_date', null);
      setLeaveCount('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLeaveType]);

  // updating leave count
  useEffect(() => {
    let count;
    if (watchFormDate && watchToDate) {
      count = ~~dayjs(watchToDate).diff(watchFormDate, 'day') + 1;
    }

    // console.log(watchFormDate, watchToDate, count);

    if (
      count > selectedLeave?.remaining_this_month ||
      count < selectedLeave?.at_a_time_min_limit
    ) {
      // setValue('from_date', null);
      setValue('to_date', null);
      setLeaveCount('');
    } else {
      setLeaveCount(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFormDate, watchToDate]);

  useEffect(() => {
    if (selectedLeaveData) {
      setRenderCount(1);
      setSelectedLeave(selectedLeaveData);
      /* console.log(
        selectedLeaveData?.from_date,
        new Date(new Date(selectedLeaveData?.from_date).toLocaleDateString()),
        new Date(selectedLeaveData?.from_date).toLocaleDateString(),
        'hello'
      ); */

      reset({
        leave_type_id: `${selectedLeaveData?.leave_type_id}`,
        number_of_days: selectedLeaveData?.number_of_days,
        from_date: dayjs(selectedLeaveData?.from_date).toDate(),
        to_date: dayjs(selectedLeaveData?.to_date).toDate(),
        reason: selectedLeaveData?.reason,
      });
      setLeaveCount(selectedLeaveData?.number_of_days);
    } else {
      setSelectedLeave('');
      reset({
        leave_type_id: '',
        number_of_days: '',
        from_date: '',
        to_date: '',
        reason: '',
      });
      setLeaveCount('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeaveData]);

  const getDropdownValues = () => {
    const newArray = [];
    employeeLeavesInfo?.map((item) => {
      if (item?.remaining_this_month > 0) {
        newArray.push({
          value: `${item?.id}`,
          label: item?.leave_type,
        });
      }
      return true;
    });
    return newArray;
  };
  const getEmployeeAppliedLeaves = useGetEmployeeAppliedLeaves();
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  const leaves = getEmployeeAppliedLeaves?.data?.data
    ?.filter((leave) => leave.is_approved)
    .map((item) => {
      return getDatesInRange(
        new Date(item?.from_date),
        new Date(item?.to_date)
      );
    });

  if (leaves) {
    var alldates = leaves.flat(Infinity);

    alldates = alldates.map((leave) => {
      return dayjs(leave).format('YYYY-MM-DD');
    });
  }
  console.log(
    'selectedLeave?.at_a_time_max_limit',
    selectedLeave?.at_a_time_max_limit
  );
  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-2',
        }}
      >
        <div className='mr-10'>
          <div className='py-2 mt-2 rounded-lg border-opacity-10 bg-[#FFF6F3] flex flex-wrap'>
            {employeeLeavesInfo?.map((item, index) => {
              return (
                <div
                  className={`${
                    employeeLeavesInfo?.length === 1
                      ? 'w-full'
                      : employeeLeavesInfo?.length > 4
                      ? 'w-1/4'
                      : `w-1/${employeeLeavesInfo?.length}`
                  }  ${
                    index === 3 ? '' : 'border-r border-r-[#C5C5C5]'
                  } my-2 text-center`}
                  key={item?.id}
                >
                  <p className='text-primary font-bold text-2xl'>
                    {item?.remaining_days}/
                    <span className='opacity-50'>{item?.total_days}</span>
                  </p>
                  <p className='text-lg text-222222 pt-1'>{item?.leave_type}</p>
                  {/* <div className='flex justify-center items-center space-x-1 mt-1'>
                    <p className='text-A3A3A3 text-xs'>
                      Available (this month)
                    </p>
                    <p className='text-bold text-lg text-A3A3A3'>
                      {item?.remaining_this_month < 10
                        ? `0${item?.remaining_this_month}`
                        : item?.remaining_this_month}
                    </p>
                  </div> */}
                </div>
              );
            })}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mt-6 flex flex-wrap space-y-4'>
              <div className='w-full'>
                <Controller
                  control={control}
                  name='leave_type_id'
                  rules={{ required: 'required' }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label='Leave type*'
                      size='md'
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      rightSection={<DownArrow className='mt-1' />}
                      data={getDropdownValues()}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('leave_type_id')}
              </div>
              {/* {console.log(
                dayjs(new Date()).toDate(),
                'dayjs(new Date()).toDate()'
              )} */}
              <div className='w-1/3 pr-4'>
                <Controller
                  control={control}
                  name='from_date'
                  rules={{ required: 'required' }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label='From*'
                      size='md'
                      disabled={!watch('leave_type_id')}
                      dropdownType='modal' //type can be popover also
                      rightSection={<CalenderIcon className='w-5' />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      minDate={
                        dayjs(new Date())
                          .add(
                            selectedLeave?.should_be_applied_before_days,
                            'days'
                          )
                          .toDate()

                        // selectedLeave?.is_approved?selectedLeave.to_date:null
                      }
                      // initialMonth={dayjs(new Date()).toDate()}
                      excludeDate={(date) =>
                        alldates.indexOf(dayjs(date).format('YYYY-MM-DD')) !==
                        -1
                      }
                      maxDate={dayjs(
                        leavePolicyInfo?.leave_module_to_date
                      ).toDate()}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                        yearPickerControlActive: 'text-white bg-primary',
                        monthPickerControlActive: 'text-white bg-primary',
                      }}
                    />
                  )}
                />
                {getErrorMessage('from_date')}
              </div>
              <div className='w-1/3'>
                <Controller
                  control={control}
                  name='to_date'
                  rules={{ required: 'required' }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label='To*'
                      size='md'
                      disabled={!watch('from_date')}
                      dropdownType='modal' //type can be popover also
                      rightSection={<CalenderIcon className='w-5' />}
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      excludeDate={(date) =>
                        alldates.indexOf(dayjs(date).format('YYYY-MM-DD')) !==
                        -1
                      }
                      // initialMonth={dayjs(watch('from_date')).toDate()}
                      minDate={dayjs(new Date(watchFormDate))
                        // .startOf('month')
                        .add(selectedLeave?.at_a_time_min_limit - 1, 'days')
                        .toDate()}
                      maxDate={dayjs(new Date(watchFormDate))
                        // .endOf('month')
                        // .subtract(5, 'days')
                        .add(
                          dayjs(new Date(watchFormDate)).daysInMonth() -
                            dayjs(new Date(watchFormDate)).date() >=
                            selectedLeave?.at_a_time_max_limit
                            ? selectedLeave?.at_a_time_max_limit - 1
                            : dayjs(new Date(watchFormDate)).daysInMonth() -
                                dayjs(new Date(watchFormDate)).date(),
                          'days'
                        )
                        .toDate()}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                        yearPickerControlActive: 'text-white bg-primary',
                        monthPickerControlActive: 'text-white bg-primary',
                      }}
                    />
                  )}
                />
                {console.log(
                  dayjs(new Date(watchFormDate)).daysInMonth() -
                    dayjs(new Date(watchFormDate)).date() >=
                    selectedLeave?.at_a_time_max_limit
                    ? selectedLeave?.at_a_time_max_limit - 1
                    : dayjs(new Date(watchFormDate)).daysInMonth() -
                        dayjs(new Date(watchFormDate)).date()
                )}
                {getErrorMessage('to_date')}
              </div>
              <div className='w-1/3 pl-4'>
                <Controller
                  control={control}
                  name='number_of_days'
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Leave count*'
                      size='md'
                      value={leaveCount}
                      readOnly
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('number_of_days')}
              </div>
              <div className='w-full'>
                <Controller
                  control={control}
                  name='reason'
                  rules={{ required: 'required' }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      label='Reason*'
                      minRows={3}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('reason')}
              </div>
            </div>
            <div className='w-full flex'>
              <button className='w-32 mt-6 mx-auto'>
                <PrimaryButton
                  isLoading={
                    applyLeaveMutation?.isLoading ||
                    editAppliedLeaveMutation?.isLoading
                  }
                >
                  Apply
                </PrimaryButton>
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
