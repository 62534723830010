import forgotPasswordOtpImage from '../../../assets/images/forgotPasswordOtpImage.png';
import { ReactComponent as AptaSmallIcon } from '../../../assets/icons/aptaSmallIcon.svg';
import { ReactComponent as OtpIcon } from 'assets/icons/OtpIcon.svg';
import OtpInput from 'react-otp-input';
import { useForm, Controller } from 'react-hook-form';
import OtpTimer from './otpTimer';
import { useSearchParams } from 'react-router-dom';

import UseVerifyAccount from '../hooks/useverifyaccount';

const VerifySignup = () => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const verifyOtpMutation = UseVerifyAccount();
  const [searchParams] = useSearchParams();

  const submitOtp = (data) => {
    verifyOtpMutation.mutate({
      email_or_number: searchParams.get('email'),
      ...data,
    });
  };

  return (
    <div
      style={{
        backgroundImage: 'URL(' + forgotPasswordOtpImage + ')',
        backgroundSize: '100% 100%',
      }}
      className='h-screen bg-center  bg-no-repeat w-[100%] bg-cover'
    >
      <div className='bg-[#FFF] w-full py-[20px] px-[50px] flex justify-between items-center shadow-lg'>
        <AptaSmallIcon className='w-24 h-12' />
        {/* <button
          onClick={() => {
            navigate('/auth/signup', { replace: true });
          }}
          className='h-10 w-32  bg-[#FB7C51] rounded-lg text-[#FFFFFF] font-normal text-base hover:bg-[#e2643a] hover:shadow-lg'
        >
          Sign up
        </button> */}
      </div>

      <div className='h-[85%] flex justify-center flex-col items-center'>
        <div className='bg-[#ffffff] shadow-xl rounded-lg p-4 flex justify-center flex-col items-center w-[50%]'>
          <h1 className='text-primary font-bold text-2xl mb-3'>
            Verify Account
          </h1>
          <p className='text-center font-normal text-sm text-[Roboto] mb-2'>
            You will receive an email with 6 - digit verification code allowing
            you to verify your account.
            <br /> Please make sure to check your spam and trash if you can’t
            find the email.
            {/* <br /> If mail ID is incorrect{' '}
            <span
              className='text-primary cursor-pointer'
              onClick={() =>
                navigate('/auth/forgot-password', { replace: true })
              }
            >
              click here
            </span> */}
          </p>
          <OtpIcon className='h-52 w-52 mb-2' />
          <form
            className='flex flex-col justify-center align-middle'
            onSubmit={handleSubmit(submitOtp)}
          >
            <Controller
              control={control}
              name='otp'
              rules={{
                required: 'OTP must be filled field!',
              }}
              render={({ field: { onChange, value } }) => (
                <OtpInput
                  value={value}
                  onChange={(otp) => onChange(otp)}
                  numInputs={6}
                  separator={<span> </span>}
                  focusStyle={'border'}
                  inputStyle={{
                    width: '50px',
                    background: '#fff',
                    borderRadius: '6px',

                    height: '50px ',
                    boxShadow: '-1px 4px 8px 2px rgba(251, 124, 81, 0.2)',
                    margin: '8px',
                    fontSize: '18px',
                  }}
                />
              )}
            />
            {errors.otp && (
              <p className='text-sm  text-[#fc0101] '>{errors?.otp?.message}</p>
            )}

            <button className='h-10 w-32 mx-auto bg-[#FB7C51] rounded-lg mt-4 mb-5 text-[#FFFFFF] font-normal text-lg hover:bg-[#e2643a] hover:shadow-lg'>
              Submit
            </button>
            <OtpTimer className='item-center' reset={reset}/>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifySignup;
