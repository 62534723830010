import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetsalarycomponentdata() {
  return useQuery(['salary_component_data'], async () => {
    const response = await makeApiRequest.get(apiEndPoints.SALARY_COMPONENTS);

    return response.data;
  });
}
