import { AuthContext } from 'context/authContext';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetApparisal(
  id,
  apparisalSearch,
  apparisalDraftFilter,
  apparisalYearFilter
) {
  const { authState } = useContext(AuthContext);
  return useQuery(
    [
      'apparisal-admin',
      id,
      apparisalSearch,
      apparisalDraftFilter,
      apparisalYearFilter,
    ],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.APPARISAL, {
          id: id,
          search: apparisalSearch,
          is_draft: apparisalDraftFilter,
          employee__reports_to_id: authState?.is_admin
            ? undefined
            : authState?.user_id,
          created_date__year: apparisalYearFilter,
          employee__company_id: authState?.company_id,
        })
      );

      return response.data;
    }
  );
}
