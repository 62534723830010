import React, { useEffect, useState } from 'react';
import {
  Modal,
  Select,
  TextInput,
  Textarea,
  Avatar,
  Checkbox,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { Controller, useForm } from 'react-hook-form';

import useUpdateEmployeePersonalInfo from '../hooks/useUpdateEmployeePersonalInfo';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import PrimaryButton from 'components/primaryButton';
import dayjs from 'dayjs';

export default function EditEmployeePersonalData({
  isModalOpen,
  setIsModalOpen,
  employeeDetails,
}) {
  const [isWhatsappNumberSame, setIsWhatsappNumberSame] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateEmployeeMutation = useUpdateEmployeePersonalInfo(closeModal);

  //   custom title for modal
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>Edit details</p>
      </div>
    );
  };

  //   default values for form
  const defaultValues = {
    personal_email: '',
    mobile_number: '',
    whatsapp_number: '',
    date_of_birth: '',
    gender: '',
    marital_status: '',
    preferred_pronoun: '',
    address: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    // watch,
    control,
    // getValues,
    reset,
  } = useForm({ defaultValues,mode:"onChange" });

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  //   handle form submit
  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append('address', data?.address);
    formData.append('gender', data?.gender);
    formData.append('marital_status', data?.marital_status);
    formData.append('personal_email', data?.personal_email);
    formData.append('preferred_pronoun', data?.preferred_pronoun);
    formData.append(
      'date_of_birth',
      dayjs(data?.date_of_birth).format('YYYY-MM-DD')
    );
    if (isWhatsappNumberSame) {
      formData.append('whatsapp_number', data?.mobile_number);
    } else {
      formData.append('whatsapp_number', data?.whatsapp_number);
    }
    updateEmployeeMutation.mutate(formData);
  };

  useEffect(() => {
    reset({
      mobile_number: employeeDetails?.user__mobile_number,
      personal_email: employeeDetails?.personal_email || '',
      whatsapp_number: employeeDetails?.whatsapp_number || '',
      date_of_birth: new Date(employeeDetails?.date_of_birth) || '',
      gender: employeeDetails?.gender || '',
      marital_status: employeeDetails?.marital_status || '',
      preferred_pronoun: employeeDetails?.preferred_pronoun || '',
      address: employeeDetails?.address || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeDetails]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-2',
        }}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex space-x-4 items-center mb-3 mx-4'>
              <Avatar
                src={employeeDetails?.profile_picture}
                size='xl'
                color={'red'}
                className='rounded-full'
              ></Avatar>
              <div>
                <p className='text-22 font-semibold'>
                  {employeeDetails?.user__first_name}
                </p>
                <p className='text-base text-gray3'>
                  {employeeDetails?.designation__name}
                </p>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='mobile_number'
                  rules={{
                    required: 'required',
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Mobile number*'
                      size='md'
                      disabled='true'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('mobile_number')}
                <div className='mt-3'>
                  <Checkbox
                    defaultChecked={isWhatsappNumberSame}
                    size='sm'
                    onChange={(event) =>
                      setIsWhatsappNumberSame(event.currentTarget.checked)
                    }
                    label='This number has Whatsapp'
                    classNames={{
                      input: 'cursor-pointer',
                      label: 'text-neutralsGrey',
                    }}
                  />
                </div>
              </div>
              {isWhatsappNumberSame ? null : (
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='whatsapp_number'
                    rules={{
                      required: 'required',
                      pattern: {
                        value: /^[0-9]+[0-9]*$/,
                        message: 'Only numbers are allowed',
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Whatsapp number*'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('whatsapp_number')}
                </div>
              )}
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='personal_email'
                  rules={{
                    required: 'required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Please enter valid email',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Personal email*'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('personal_email')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='date_of_birth'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label='Date of birth (DOB)*'
                      size='md'
                      rightSection={<CalenderIcon className='w-5' />}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                        yearPickerControlActive: 'text-white bg-primary',
                        monthPickerControlActive: 'text-white bg-primary',
                      }}
                    />
                  )}
                />
                {getErrorMessage('date_of_birth')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='gender'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label='Gender* '
                      size='md'
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      rightSection={<DownArrow className='mt-1' />}
                      data={[
                        { value: 'M', label: 'Male' },
                        { value: 'F', label: 'Female' },
                        { value: 'O', label: 'Others' },
                      ]}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('gender')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='marital_status'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label='Marital status* '
                      size='md'
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      rightSection={<DownArrow className='mt-1' />}
                      data={[
                        { value: 'single', label: 'Single' },
                        { value: 'married', label: 'Married' },
                      ]}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('marital_status')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='preferred_pronoun'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label='Preferred pronouns* '
                      size='md'
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      rightSection={<DownArrow className='mt-1' />}
                      data={[
                        { value: 'He', label: 'He' },
                        { value: 'She', label: 'She' },
                      ]}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('preferred_pronoun')}
              </div>
              <div className='w-full py-2 px-4'>
                <Controller
                  control={control}
                  name='address'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      label='Address*'
                      minRows={3}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('address')}
              </div>
              <div className='flex justify-center w-full mt-3'>
                <button className='w-28'>
                  <PrimaryButton isLoading={updateEmployeeMutation?.isLoading}>
                    Save
                  </PrimaryButton>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
