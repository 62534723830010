import React from 'react';
import { ReactComponent as CalendarImg } from 'assets/images/calendarDark.svg';

export default function Meetings() {
  return (
    <div className='bg-white shadow-four rounded-[10px] py-5 px-9'>
      <p className='text-20 font-semibold'>Meeting schedule</p>
      <p className='text-gray3'>Select a date to see the schedule</p>
      <div className='mt-5 flex flex-col items-center'>
        <CalendarImg />
        <p className='text-gray3 mt-7'>No Meetings to show</p>
      </div>
    </div>
  );
}
