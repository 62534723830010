import React, { Fragment } from 'react';
import TeamCard from './teamCard';

import { Loader } from '@mantine/core';

import useGetAllTeamMembers from '../hooks/useGetAllTeamMembers';
import TeamCardLoader from './teamCardLoader';

import noTeamMemberImg from 'assets/images/noTeamMemberImg.png';

export default function ActiveTeamMembers({ searchValue }) {
  const getTeamMembersData = useGetAllTeamMembers(null, 'True', searchValue);

  return (
    <div className=' mt-4 select-none'>
      {getTeamMembersData?.isLoading || getTeamMembersData?.isFetching ? (
        <div className='flex flex-cols-4 flex-wrap'>
          <TeamCardLoader />
          <TeamCardLoader />
          <TeamCardLoader />
          <TeamCardLoader />
          <TeamCardLoader />
        </div>
      ) : (
        <>
          {getTeamMembersData?.data?.pages[0]?.data?.length ? (
            <div className='flex flex-cols-4 flex-wrap'>
              {getTeamMembersData?.data?.pages?.map((pages, index) => (
                <Fragment key={index}>
                  {pages?.data?.map((eachEmployeeData) => {
                    return (
                      <div key={eachEmployeeData.id} className='w-1/4'>
                        <TeamCard employeeData={eachEmployeeData} />
                      </div>
                    );
                  })}
                </Fragment>
              ))}
            </div>
          ) : (
            <div className='h-[65vh] bg-white rounded-xl flex flex-col justify-center items-center'>
              <img src={noTeamMemberImg} alt='' />
              <p className='text-xl text-gray2 mt-4'>
                No active employees to show
              </p>{' '}
            </div>
          )}
        </>
      )}
      {getTeamMembersData?.hasNextPage ? (
        <div className='flex justify-center my-4'>
          <button
            className='bg-[#FFA88B] w-32 text-gray2 py-3 text-sm rounded-full'
            onClick={() => getTeamMembersData.fetchNextPage()}
          >
            {getTeamMembersData?.isFetching ? (
              <Loader color='#ffffff' size={'sm'} className='mx-auto' />
            ) : (
              'View more'
            )}
          </button>
        </div>
      ) : null}
    </div>
  );
}
