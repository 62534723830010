import React, { useContext } from 'react';
import { Modal, TextInput } from '@mantine/core';
import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/crossIcon.svg';
import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mantine/dates';
import { AuthContext } from 'context/authContext';

import useAddSkillDevelopment from '../hooks/useAddSkillDevelopment';
import dayjs from 'dayjs';

export default function NewCourseModal({
  addNewCourseModal,
  setAddNewCourseModal,
  selectEventToDelete,
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch
  } = useForm({mode:"onChange"});

  const defaultvalues = {
    title: '',
    from_date: '',
    to_date: '',
    source: '',
  };
  const closeModal = () => {
    setAddNewCourseModal(false);
    reset(defaultvalues);
  };

  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const { authState } = useContext(AuthContext);

  const addSkillDevelopmentMutation = useAddSkillDevelopment(closeModal);

  const addNewCourse = (data) => {
    // console.log({ ...data, employee_id: authState?.employee_id });
    addSkillDevelopmentMutation.mutate({
      ...data,
      employee_id: authState?.employee_id,
      from_date: dayjs(data?.from_date).format('YYYY-MM-DD'),
      to_date: dayjs(data?.to_date).format('YYYY-MM-DD'),
    });
  };

  return (
    <Modal
      opened={addNewCourseModal}
      onClose={closeModal}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      overflow='inside'
      centered
      size={'lg'}
      radius='md'
      transitionDuration={100}
      withCloseButton={false}
      classNames={{
        modal: 'p-0',
        body: 'mt-10 mb-14',
      }}
    >
      <div className='space-y-4 relative'>
        <p className='text-xl leading-7 font-semibold text-[#000] text-center '>
          Add a course
        </p>
        <form onSubmit={handleSubmit(addNewCourse)}>
          <div className='my-8 grid grid-cols-2 gap-4 px-8'>
            <div className='col-span-2'>
              <Controller
                name='title'
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextInput
                    // label='Salary (CTC)*'
                    {...field}
                    label='Course title'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('title')}
            </div>
            <div>
              <Controller
                control={control}
                name='from_date'
                rules={{
                  required: 'Required',
                }}
                // defaultValue={new Date()}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label='Start date'
                    dropdownType="modal" // can be popover also
                    size='md'
                    rightSection={<CalenderIcon className='h-5 w-5' />}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey',
                      rightSection: 'pointer-events-none',
                    }}
                  />
                )}
              />
              {getErrorMessage('from_date')}
            </div>
            <div>
              <Controller
                control={control}
                name='to_date'
               
                
                rules={{
                  required: 'Required',validate:()=>watch('from_date')<=watch('to_date')||"Invalid date selected"
                }}
                // defaultValue={new Date()}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label='End date'
                    size='md'
                    dropdownType="modal" // can be popover also
                    minDate={dayjs(watch('from_date')).toDate()}
                    rightSection={<CalenderIcon className='h-5 w-5' />}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey',
                      rightSection: 'pointer-events-none',
                    }}
                  />
                )}
              />
              {getErrorMessage('to_date')}
            </div>
            <div className='col-span-2'>
              <Controller
                name='source'
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextInput
                    // label='Salary (CTC)*'
                    {...field}
                    label='Source'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('source')}
            </div>
          </div>
          <div className='flex justify-center items-center'>
            <button>
              <PrimaryButton>Save</PrimaryButton>
            </button>
          </div>
        </form>
      </div>

      <CloseIcon
        className='w-12 h-12 absolute top-3 right-3 cursor-pointer'
        onClick={closeModal}
      />
    </Modal>
  );
}
