import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetemployessfromteams(id) {
  return useQuery(['employess-from-teams', id], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.TEAM_MEMBERS_API, {
        user__is_active: 'True',
        id: id,
      })
    );

    return response.data;
  });
}
