import { Switch, TextInput } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import HolidayTypeModal from './holidaytypemodal';

import Usegetholidaystypes from '../hooks/usegetholidaytypes';
import Useupdateholidaytype from '../hooks/useUpdateholidaytype';

import { ReactComponent as EmptyLeaves } from 'assets/icons/emptyleavesIcon.svg';

const Holidays = () => {
  const getHolidaysData = Usegetholidaystypes();
  const updateHolidaysMutation = Useupdateholidaytype();
  const {
    // handleSubmit,
    formState: { errors },
    register,
    // watch,
    // reset,
  } = useForm();

  const [isHolidayModalOpened, setIsHolidayModalOpened] = useState(false);
  // const [textvalue, setTextValue] = useState(null);
  return (
    <>
      <div className=' bg-[white] min-h-[70vh] p-8 rounded-2xl shadow-2xl'>
        <div className='flex items-center justify-between bg-[#FFE8E1] p-3 rounded-lg'>
          <h1 className='text-primary font-semibold text-lg'>
            Edit Type of Holiday
          </h1>
          <button
            onClick={() => {
              setIsHolidayModalOpened(true);
            }}
          >
            <PrimaryButton>+ Add a holiday type</PrimaryButton>
          </button>
        </div>
        <div className='px-6 bg-white'>
          {getHolidaysData?.data?.data.length === 0 ? (
            <div className=' w-full bg-[#FFF] flex-col flex justify-center items-center h-[320px] mt-5'>
              <EmptyLeaves />
              <p className='mt-2 mb-3 text-[#535353] text-center text-normal font-medium'>
                Seems like you didn’t add any holidays yet
              </p>{' '}
            </div>
          ) : (
            <>
              {getHolidaysData?.data?.data.map((eachHoliday) => (
                <div
                  className='flex items-center justify-between my-5'
                  key={eachHoliday.id}
                >
                  <div>
                    <TextInput
                      {...register(`name.${eachHoliday.id}`, {
                        required: 'Required',
                      })}
                      className='w-72'
                      defaultValue={eachHoliday.name}
                      size='lg'
                      classNames={{
                        input:
                          ' text-[15px] bg-transparent  text-base rounded pl-5',
                      }}
                      onKeyDown={(event) => {
                        if (
                          event.key === 'Enter' &&
                          event.target.value.trim() !== ''
                        ) {
                          updateHolidaysMutation.mutate({
                            id: eachHoliday.id,
                            name: event.target.value.trim(),
                          });
                        }
                      }}
                    />
                    {errors.name && (
                      <p className='text-sm text-[#fc0101] ml-[110px]'>
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                  <Switch
                    size='md'
                    {...register(`is_active.${eachHoliday.id}`)}
                    defaultChecked={eachHoliday.is_active}
                    onChange={() => {
                      updateHolidaysMutation.mutate({
                        id: eachHoliday.id,
                        is_active: !eachHoliday.is_active,
                      });
                    }}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <HolidayTypeModal
        setIsHolidayModalOpened={setIsHolidayModalOpened}
        isHolidayModalOpened={isHolidayModalOpened}
      />
    </>
  );
};

export default Holidays;
