import Header from 'components/Header';
import PrimaryButton from 'components/primaryButton';
import { useState } from 'react';
import AddLeaveTypeModal from './addleavetypesmodal';

import LeaveTypes from './typeofleaves';

import { useLocation } from 'react-router';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrowIcon } from 'assets/icons/backArrowIcon.svg';
import dayjs from 'dayjs';

const LeaveCalendar = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const [addleavetypemodal, setaddleavetypemodal] = useState(false);
  return (
    <>
      <div className='px-5 mb-5'>
        <div className='flex justify-between items-center mt-6'>
          <p className='text-xl text-A8A8A8'>
            Settings &#60;
            <span className='text-gray font-semibold'> Leave types</span>
          </p>
          <Header />
        </div>
        <div className='bg-[#fff] min-h-[80vh] mt-5 rounded-3xl shadow-2xl p-4'>
          <div className='flex justify-between items-center mb-10'>
            <div className='flex space-x-4 items-center'>
              <BackArrowIcon
                className='w-4 cursor-pointer'
                onClick={() => navigate(-1)}
              />
              <h1 className='text-[#000] font-semibold text-xl'>
                Leave calendar{' '}
                {dayjs(location.state.eachmodule.from_date).format(
                  'DD MMM YYYY'
                )}{' '}
                -{' '}
                {dayjs(location.state.eachmodule.to_date).format('DD MMM YYYY')}
              </h1>
            </div>
            <button onClick={() => setaddleavetypemodal(true)}>
              <PrimaryButton>+ Add a leave type</PrimaryButton>
            </button>
          </div>
          <div className='mt-4'>
            <LeaveTypes />
          </div>
        </div>
      </div>
      <AddLeaveTypeModal
        addleavetypemodal={addleavetypemodal}
        setaddleavetypemodal={setaddleavetypemodal}
      />
    </>
  );
};
export default LeaveCalendar;
