import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { AuthContext } from 'context/authContext';
import { useContext } from 'react';
export default function useGetEmployeeMedicalDetails() {
  const { authState } = useContext(AuthContext);
  return useQuery(['get-employee-medical-details'], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_MEDICAL_DETAILS_API, {
        employee_id: authState?.employee_id,
      })
    );
    return res.data;
  });
}
