import { useMutation, useQueryClient } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { SuccessToast, ErrorToast } from 'services/toasterService';

export default function useUpdateSalaryDetails(closeModal) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(
        apiEndPoints.EMPLOYEE_SALARY_DETAILS,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data?.message });
        queryClient.invalidateQueries('get-employee-salary-details');
        queryClient.invalidateQueries('get-all-employees-data');
        closeModal();
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
