import { Modal, Tooltip } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';

import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { ReactComponent as DownArrow } from 'assets/icons/downloadcircleIcon.svg';
import { ReactComponent as ZoomIn } from 'assets/icons/zoominIcon.svg';
import { ReactComponent as Zoomout } from 'assets/icons/zoomoutIcon.svg';

import Useupdatethepolicybyemployee from '../hooks/useupdatethepolicibyemployee';
import { saveAs } from 'file-saver';

const EmployeePoliciModal = ({
  openmodalemployee,
  setOpenModalEmployee,
  file,
  policiId,
  setPoliciId,
  isPolicySigned,
  isUpdated,
}) => {
  const closeModal = () => {
    setOpenModalEmployee(false);
    setPageNumber(1);
    setPoliciId(null);
    setZoomValue(12);
  };

  const aggreeandsignthepolicymutation =
    Useupdatethepolicybyemployee(closeModal);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomValue, setZoomValue] = useState(12);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const aggreeButton = () => {
    aggreeandsignthepolicymutation.mutate({
      policy_id: policiId,
    });
  };
  return (
    <div className=''>
      <Modal
        opened={openmodalemployee}
        onClose={closeModal}
        title={
          isUpdated ? (
            <p className='text-18 text-gray leading-7'>
              The document has been{' '}
              <span className='text-primary'>modified</span> since it was signed{' '}
              <br />
              Please acknowledge the updated document
            </p>
          ) : (
            <p className='text-18 text-gray leading-7'>
              Please acknowledge the following document
            </p>
          )
        }
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size={'80%'}
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full font-normal text-10 text-center',
          modal: 'pl-10 h-[90vh]',
          body: 'scroller -mr-4 pr-8 h-[85vh]',
        }}
      >
        <div className='relative'>
          <div className='w-[100%] flex flex-col justify-center items-center'>
            <div className=''>
              <div className='flex justify-center items-center'>
                <Document
                  file={{
                    url: `${file}`,
                  }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={pageNumber}
                    scale={zoomValue / 10}
                    // key={`page_${index + 1}`}
                  />
                </Document>
                <div className='flex flex-col gap-5 justify-end w-20'>
                  <button
                    onClick={() => {
                      if (zoomValue >= 9 && zoomValue < 15) {
                        setZoomValue(zoomValue + 1);
                      }
                    }}
                  >
                    <Zoomout className='w-7 h-7 hover:w-9 hover:h-9' />
                  </button>
                  <button
                    onClick={() => {
                      if (zoomValue > 9 && zoomValue <= 15) {
                        setZoomValue(zoomValue - 1);
                      }
                    }}
                  >
                    <ZoomIn className='w-7 h-7 hover:w-9 hover:h-9' />
                  </button>
                </div>
              </div>
              <nav>
                <div className='gap-4 flex justify-between'>
                  <button
                    onClick={goToPrevPage}
                    className={
                      pageNumber === 1 ? 'opacity-50 cursor-not-allowed' : ''
                    }
                  >
                    <SecondaryButton>Previous Page</SecondaryButton>
                  </button>
                  <button
                    onClick={goToNextPage}
                    className={
                      pageNumber === numPages
                        ? 'opacity-50 cursor-not-allowed -mr-20'
                        : '-mr-20'
                    }
                  >
                    {' '}
                    <SecondaryButton>Next Page</SecondaryButton>
                  </button>
                </div>
                <div className='gap-4 flex justify-between mt-4'>
                  <p className='self-end'>
                    Page {pageNumber} of {numPages}
                  </p>
                  <Tooltip
                    label='Download'
                    classNames={{
                      body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                    }}
                    radius='md'
                  >
                    <DownArrow
                      className='w-7 h-7 cursor-pointer -mr-20'
                      onClick={() => saveAs(file)}
                    />
                  </Tooltip>
                </div>
              </nav>
            </div>
          </div>{' '}
          {isPolicySigned ? null : (
            <div className='flex justify-center space-x-5'>
              <button onClick={closeModal} className='w-32'>
                <SecondaryButton>Cancel</SecondaryButton>
              </button>

              <button onClick={aggreeButton}>
                <PrimaryButton>Agree and sign</PrimaryButton>
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EmployeePoliciModal;
