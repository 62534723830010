import React, { useEffect } from 'react';
import { Modal, TextInput } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mantine/dates';

import PrimaryButton from 'components/primaryButton';
import FileUpload from 'components/fileUpload';

import useUpdateEmployeePersonalInfo from '../hooks/useUpdateEmployeePersonalInfo';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import dayjs from 'dayjs';

export default function EditOtherDetailsOfEmployee({
  isModalOpen,
  setIsModalOpen,
  employeeDetails,
}) {
  const closeModal = () => {
    setIsModalOpen(false);
    reset();
  };

  //   custom title for modal
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>Edit details</p>
      </div>
    );
  };

  const defaultValues = {
    nationality: '',
    aadhar_card: '',
    aadhar_card_image: '',
    passport_number: '',
    passport_expiry_date: new Date(),
    passport_image_1: '',
    pan_card: '',
    pan_card_image: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
    setError,
  } = useForm({ defaultValues, mode: 'onChange' });

  const updateEmployeeDetails = useUpdateEmployeePersonalInfo(closeModal);

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const onSubmit = (data) => {
    console.log(data?.passport_expiry_date);
    const formData = new FormData();
    formData.append('nationality', data?.nationality);
    formData.append('aadhar_card', data?.aadhar_card);
    formData.append(
      'passport_number',
      data?.passport_number ? data?.passport_number : ''
    );
    formData.append(
      'passport_expiry_date',
      data?.passport_expiry_date
        ? dayjs(data?.passport_expiry_date).format('YYYY-MM-DD')
        : ''
    );
    formData.append('pan_card', data?.pan_card ? data?.pan_card : '');
    formData.append('aadhar_card_image', data?.aadhar_card_image?.[0]);
    formData.append('passport_image_1', data?.passport_image_1?.[0]);
    formData.append('pan_card_image', data?.pan_card_image?.[0]);

    if (data?.aadhar_card_image === employeeDetails?.aadhar_card_image) {
      formData.delete('aadhar_card_image');
    }
    if (data?.passport_image_1 === employeeDetails?.passport_image_1) {
      formData.delete('passport_image_1');
    }
    if (data?.pan_card_image === employeeDetails?.pan_card_image) {
      formData.delete('pan_card_image');
    }
    updateEmployeeDetails.mutate(formData);
  };

  useEffect(() => {
    reset({
      nationality: employeeDetails?.nationality,
      aadhar_card: employeeDetails?.aadhar_card,
      aadhar_card_image: employeeDetails?.aadhar_card_image,
      passport_number: employeeDetails?.passport_number,
      passport_expiry_date: employeeDetails?.passport_expiry_date
        ? new Date(employeeDetails?.passport_expiry_date)
        : null,
      pan_card: employeeDetails?.pan_card,
      passport_image_1: employeeDetails?.passport_image_1,
      pan_card_image: employeeDetails?.pan_card_image,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeDetails]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-8',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-wrap'>
            <div className='w-full py-2 '>
              <Controller
                control={control}
                name='nationality'
                rules={{
                  required: 'required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Nationality*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('nationality')}
            </div>
            <div className='w-full py-2'>
              <Controller
                control={control}
                name='aadhar_card'
                rules={{
                  required: 'required',
                  pattern: {
                    value: /^[0-9]+[0-9]*$/,
                    message: 'Only numbers are allowed',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Aadhaar ID*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('aadhar_card')}
            </div>
            <div className='py-2 w-full'>
              <p className='pb-1.5 text-neutralsGrey text-sm'>
                Soft copy / Image of the document (Max Size: 5MB)
              </p>
              <Controller
                control={control}
                name='aadhar_card_image'
                rules={{
                  required: 'required',
                }}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    multiple={false}
                    setImage={onChange}
                    value={watch('aadhar_card_image')}
                    ename={'aadhar_card_image'}
                    setError={setError}
                  />
                )}
              />

              {getErrorMessage('aadhar_card_image')}
            </div>
            <hr className='my-6 border-[#ECECEC] w-full' />
            <div className='w-1/2 pr-4 py-2 '>
              <Controller
                control={control}
                name='passport_number'
                // rules={{
                //   required: 'required',
                // }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Passport no.'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('passport_number')}
            </div>
            <div className='w-1/2 pl-4 py-2'>
              <Controller
                control={control}
                name='passport_expiry_date'
                // rules={{
                //   required: 'required',
                // }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label='Passport exp. date'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                      yearPickerControlActive: 'text-white bg-primary',
                      monthPickerControlActive: 'text-white bg-primary',
                    }}
                  />
                )}
              />
              {getErrorMessage('passport_expiry_date')}
            </div>
            <div className='py-2 w-full'>
              <p className='pb-1.5 text-neutralsGrey text-sm'>
                Soft copy / Image of the document (Max Size: 5MB)
              </p>
              <Controller
                control={control}
                name='passport_image_1'
                // rules={{
                //   required: 'required',
                // }}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    multiple={false}
                    setImage={onChange}
                    value={watch('passport_image_1')}
                    ename={'passport_image_1'}
                    setError={setError}
                  />
                )}
              />
              {getErrorMessage('passport_image_1')}
            </div>
            <hr className='my-6 border-[#ECECEC] w-full' />
            <div className='w-full py-2'>
              <Controller
                control={control}
                name='pan_card'
                // rules={{
                //   required: 'required',
                // }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='PAN ID'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('pan_card')}
            </div>
            <div className='py-2 w-full'>
              <p className='pb-1.5 text-neutralsGrey text-sm'>
                Soft copy / Image of the document (Max Size: 5MB)
              </p>
              <Controller
                control={control}
                name='pan_card_image'
                // rules={{
                //   required: 'required',
                // }}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    multiple={false}
                    setImage={onChange}
                    value={watch('pan_card_image')}
                    ename={'pan_card_image'}
                    setError={setError}
                  />
                )}
              />
              {getErrorMessage('pan_card_image')}
            </div>
            <div className='w-full flex justify-center mt-6'>
              <button className={'w-28'}>
                <PrimaryButton isLoading={updateEmployeeDetails?.isLoading}>
                  Save
                </PrimaryButton>
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}
