import { useMutation, useQueryClient } from "react-query";
import makeApiRequest from "services/makeApiRequest";
import apiEndPoints from "services/apiEndPoints";
import { ErrorToast, SuccessToast } from "services/toasterService";

export default function useAddEmploymentType(closeModal) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.post(
        apiEndPoints.SETTINGS_EMPLOYMENT_TYPES,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        closeModal();
        queryClient.invalidateQueries("settings-employment-types");
        SuccessToast({ text: data?.message });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
