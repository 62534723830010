import React from 'react';
import { Avatar, Tooltip } from '@mantine/core';
import dateFormatter from 'helper/dateFormatter';

export default function EmployeeCard({ employee }) {
  const fromDate = dateFormatter(employee?.from_date, 'DD MMM YYYY');
  const toDate = dateFormatter(employee?.to_date, 'DD MMM YYYY');
  const todayDate = dateFormatter(new Date(), 'DD MMM YYYY');

  return (
    <div className='flex items-start justify-between'>
      <div className='flex items-center'>
        <Avatar
          src={employee?.employee__profile_picture}
          size={64}
          color='red'
          radius={50}
          className='capitalize'
        >
          {employee?.employee__user__first_name[0]}
        </Avatar>

        <div className='ml-5'>
          <p className='text-18 text-[#707070]'>
            {employee?.employee__user__first_name}
          </p>
          <p className='text-[15px] text-[#969696]'>
            {employee?.employee__designation__name}
          </p>
          <p></p>
        </div>
      </div>

      <div className='mt-1'>
        {fromDate === todayDate ||
        toDate === todayDate ||
        (dateFormatter(new Date(), 'YYYY-MM-DD') >=
          dateFormatter(new Date(employee?.from_date), 'YYYY-MM-DD') &&
          dateFormatter(new Date(), 'YYYY-MM-DD') <=
            dateFormatter(new Date(employee?.to_date), 'YYYY-MM-DD')) ? (
          <Tooltip
            label={`${fromDate}-${toDate}`}
            classNames={{
              body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
            }}
            radius='md'
          >
            <p className='text-[#28B446]'>Today</p>
          </Tooltip>
        ) : fromDate === toDate ? (
          <p>
            <span className='text-primary'>{fromDate.slice(0, 2)}</span>
            {fromDate.slice(2)}
          </p>
        ) : (
          <div>
            <p>
              <span className='text-primary'>{fromDate.slice(0, 2)}</span>
              {fromDate.slice(2)} -
              <span className='text-primary'> {toDate.slice(0, 2)}</span>
              {toDate.slice(2)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
