import { AuthContext } from 'context/authContext';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetperformancereport(
  employeeId,
  performanceSearch,
  performanceDraftFilter,
  performanceYearFilter
) {
  const { authState } = useContext(AuthContext);

  return useQuery(
    [
      'performance-report-in-admin',
      employeeId,
      performanceSearch,
      performanceDraftFilter,
      performanceYearFilter,
    ],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.PERFORMANCE_BY_ADMIN, {
          id: employeeId,
          search: performanceSearch,
          is_draft: performanceDraftFilter,
          employee__reports_to_id: authState?.is_admin
            ? undefined
            : authState?.user_id,
          employee__company_id: authState?.company_id,
          created_date__year: performanceYearFilter,
        })
      );

      return response.data;
    }
  );
}
