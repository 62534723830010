import React, { useState } from 'react';
import PrimaryButton from 'components/primaryButton';
import OrganisationCard from './organisationCard';
import AddOrganisationModal from './addOrganisation';

import useGetOrganisationData from '../hooks/useGetOrganisationData';

export default function OrganisationStructure() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getOrganisationDetails = useGetOrganisationData();

  return (
    <div className='shadow-xl border border-8B8B8B border-opacity-20 rounded-xl py-4 px-6 mb-8 bg-white min-h-[70vh]'>
      <div className='flex items-center justify-between'>
        <p className='text-xl text-333333 font-semibold'>Organisations</p>
        <div className='flex items-center space-x-8'>
          {/* <p className='text-67A1EF'>View organisation chart</p> */}
          <button onClick={() => setIsModalOpen(true)}>
            <PrimaryButton>+ Add organisations </PrimaryButton>
          </button>
        </div>
      </div>
      <div className='flex flex-wrap mt-8'>
        {getOrganisationDetails?.data?.data?.map((item) => (
          <div key={item?.id} className='w-1/3 pr-10 pb-8'>
            <OrganisationCard item={item} />
          </div>
        ))}
      </div>

      <AddOrganisationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
}
