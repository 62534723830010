import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetLeavesData(filterValue, yearFilterValue) {
  return useQuery(
    ['employee-dashbaord-leaves-data', filterValue, yearFilterValue],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_DASHBOARD_LEAVES, {
          month: filterValue,
          from_date__year: yearFilterValue,
        })
      );
      return response?.data;
    }
  );
}
