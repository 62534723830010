import React from 'react';

export default function Attendance() {
  return (
    <div className='shadow-lg border border-8B8B8B border-opacity-10 rounded-xl bg-white'>
      <table className='w-full rounded-xl '>
        <tbody>
          <tr className='bg-FFE8E1 text-left text-18 text-222222'>
            <th className='py-4 pl-5 rounded-tl-xl'>Date</th>
            <th className=' pl-5'>Punch in</th>
            <th className=' pl-5'>Punch out</th>
            <th className=' pl-5'>Total working hours</th>
            <th className=' pl-5 rounded-tr-xl'>Status</th>
          </tr>
          <tr className='text-9B9B9B'>
            <td className='py-5 pl-5 text-primary'>22 May 22 </td>
            <td className='pl-5'>9:00 AM</td>
            <td className='pl-5'>5:00 PM</td>
            <td className='pl-5'>8:00:00</td>
            <td className='pl-5 text-primary'>1:00:00 late</td>
          </tr>
          <tr className='text-9B9B9B'>
            <td className='py-5 pl-5 text-primary'>22 May 22 </td>
            <td className='pl-5'>9:00 AM</td>
            <td className='pl-5'>5:00 PM</td>
            <td className='pl-5'>8:00:00</td>
            <td className='pl-5 text-success'>On time</td>
          </tr>
          <tr className='text-9B9B9B'>
            <td className='py-5 pl-5 text-primary'>22 May 22 </td>
            <td className='pl-5'>9:00 AM</td>
            <td className='pl-5'>5:00 PM</td>
            <td className='pl-5'>8:00:00</td>
            <td className='pl-5 text-primary'>1:00:00 late</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
