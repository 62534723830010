import React, { useState } from 'react';
import {
  PasswordInput,
  TextInput,
  Checkbox,
  Select,
  Tooltip,
} from '@mantine/core';
import { useForm, Controller} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import UsegetCountries from '../hooks/usegetcountries';
import PrimaryButton from 'components/primaryButton';

import LoginImage from '../../../assets/images/LoginPageImage.png';
import { ReactComponent as Subicon } from 'assets/icons/subImage.svg';
import { ReactComponent as AptaSmallIcon } from '../../../assets/icons/aptaSmallIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/infoIconBlue.svg';

import UseSignUp from '../hooks/usesignup';
import TermsAndCondition from './termsandconditions';

const SignUp = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const countriesData = UsegetCountries();
  const signupMutation = UseSignUp(watch('email'));

  const setContactNumber = !!watch('country')
    ? countriesData?.data?.data.filter(
        (name) => name.common_name === watch('country')
      )
    : '';

  const signupData = (data) => {
    signupMutation.mutate({
      ...data,
      country_code: setContactNumber[0].phone_code,
      timezone_info: new Date(),
    });
  };

  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);

  return (
    <>
      <div
        style={{
          backgroundImage: 'URL(' + LoginImage + ')',
          backgroundSize: 'cover',
        }}
        className='h-[100vh] bg-center bg-cover static  top-0 overflow-y-scroll scroller'
      >
        <div className='bg-[#ffffff] w-full py-[15px] px-[50px] flex justify-between items-center sticky top-0 shadow-lg'>
          <AptaSmallIcon className='w-24 h-12' />
          <button
            onClick={() => {
              navigate('/auth/login', { replace: true });
            }}
            className='h-9 w-24  bg-[#FB7C51] rounded text-[#FFFFFF] font-normal text-base'
          >
            Login
          </button>
        </div>
        <div className='w-[100%] h-[85%] flex justify-between items-center  '>
          <div className=' ml-20  rounded-lg w-[35%] '>
            <form onSubmit={handleSubmit(signupData)} className='w-full'>
              <div className='grid grid-cols-2 gap-x-3 gap-1'>
                <h1 className='text-[#FB7C51] font-bold text-2xl text-center  col-span-2 mb-2 mt-28'>
                  Let’s get started
                </h1>
                <div className='col-span-2'>
                  <Controller
                    control={control}
                    name='first_name'
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Full Name *'
                        size='md'
                        variant='filled'
                        classNames={{
                          input:
                            'text-[15px] bg-transparent border-[#FB7C51] z-10 text-sm rounded pl-5 -mt-3 ',
                          label:
                            'text-[#8E8E8E] text-xs  font-normal ml-2 bg-[#fff8f6] px-2 rounded z-10 relative',
                        }}
                      />
                    )}
                  />
                  {errors.first_name && (
                    <p className='text-sm  text-[#fc0101]'>
                      {errors.first_name.message}
                    </p>
                  )}
                </div>

                {/* <div>
              <Controller
                control={control}
                name='last_name'
                // rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Last Name'
                    // placeholder='Last Name'
                    className='mb-1'
                    size='md'
                    classNames={{
                      input:
                        'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5 -mt-3',
                      label:
                        'text-[#8E8E8E] text-xs font-normal ml-2 bg-[#fff8f6] px-2 rounded z-10 relative',
                    }}
                  />
                )}
              />
              {errors.last_name && (
                <p className='text-sm  text-[#fc0101]'>
                  {errors.last_name.message}
                </p>
              )}
            </div> */}

                <div className='col-span-2'>
                  <Controller
                    control={control}
                    name='company_name'
                    rules={{ required: 'Required' }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Company *'
                        // placeholder='Company'
                        className='mb-1'
                        size='md'
                        classNames={{
                          input:
                            'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5 -mt-3',
                          label:
                            'text-[#8E8E8E] text-xs font-normal ml-2 bg-[#fff8f6] px-2 rounded z-10 relative',
                        }}
                      />
                    )}
                  />
                  {errors.company_name && (
                    <p className='text-sm  text-[#fc0101]'>
                      {errors.company_name.message}
                    </p>
                  )}
                </div>

                <div className='col-span-2 grid grid-cols-5 gap-2'>
                  {countriesData.isLoading ? (
                    <Controller
                      control={control}
                      name='country'
                      rules={{ required: 'Required' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label='Country *'
                          // placeholder='country'
                          size='md'
                          searchable
                          className='col-span-2'
                          classNames={{
                            input:
                              'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5 -mt-3',
                            label:
                              'text-[#8E8E8E] text-xs font-normal ml-2 bg-[#fff8f6] px-2 rounded z-10 relative',
                          }}
                          data={[{ value: '', label: '' }]}
                        />
                      )}
                    />
                  ) : (
                    <div className='col-span-2'>
                      <Controller
                        control={control}
                        name='country'
                        rules={{ required: 'Required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label='Country *'
                            // placeholder='country'

                            size='md'
                            searchable
                            classNames={{
                              input:
                                'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5 -mt-3',
                              label:
                                'text-[#8E8E8E] text-xs font-normal ml-2 bg-[#fff8f6] px-2 rounded z-10 relative',
                            }}
                            data={
                              !countriesData?.data?.data?.length
                                ? []
                                : countriesData?.data?.data?.map((dept) => {
                                    return {
                                      value: dept.common_name,
                                      label: dept.common_name,
                                    };
                                  })
                            }
                            // data={[
                            //   { value: 'react', label: 'React' },
                            //   { value: 'ng', label: 'Angular' },
                            //   { value: 'svelte', label: 'Svelte' },
                            //   { value: 'vue', label: 'Vue' },
                            // ]}
                          />
                        )}
                      />
                      {errors.country && (
                        <p className='text-sm  text-[#fc0101]'>
                          {errors.country.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div className='col-span-1'>
                    <Controller
                      control={control}
                      name='country_code'
                      // rules={{ required: 'Required' }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          value={
                            !!watch('country')
                              ? setContactNumber[0].phone_code
                              : ''
                          }
                          readOnly
                          size='md'
                          className='mt-[14px]'
                          classNames={{
                            input:
                              'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5',
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className='col-span-2'>
                    <Controller
                      control={control}
                      name='mobile_number'
                      rules={{
                        required: 'Required',
                        pattern: {
                          value: /^[0-9]+[0-9]*$/,
                          message: 'Only numbers are allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          label='Contact Number *'
                          // placeholder='Contact Number'
                          className='mb-1'
                          size='md'
                          classNames={{
                            input:
                              'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5 -mt-3',
                            label:
                              'text-[#8E8E8E] text-xs font-normal ml-2 bg-[#fff8f6] px-2 rounded z-10 relative',
                          }}
                        />
                      )}
                    />
                    {errors.mobile_number && (
                      <p className='text-sm  text-[#fc0101]'>
                        {errors.mobile_number.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='col-span-2'>
                  <Controller
                    control={control}
                    name='email'
                    rules={{
                      required: 'Required',
                      pattern: {
                        value:
                          /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/i,
                        message: 'Invalid email address',
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Email *'
                        // placeholder='Email'
                        className='mb-1'
                        size='md'
                        classNames={{
                          input:
                            'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5 -mt-3',
                          label:
                            'text-[#8E8E8E] text-xs font-normal ml-2 bg-[#fff8f6] px-2 rounded z-10 relative',
                        }}
                      />
                    )}
                  />
                  {errors.email && (
                    <p className='text-sm  text-[#fc0101]'>
                      {errors.email.message}
                    </p>
                  )}
                </div>

                <div className='flex col-span-2 items-center -mr-6 space-x-2'>
                  <div className='w-full'>
                    <Controller
                      control={control}
                      name='password'
                      rules={{
                        required: 'Required',
                        pattern: {
                          value:
                            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/,
                          message: 'should match with requirement pattern',
                        },
                        minLength: {
                          value: 8,
                          message: 'minimum of 8 characters',
                        },
                        maxLength: {
                          value: 16,
                          message: 'maximum of 16 characters only',
                        },
                      }}
                      render={({ field }) => (
                        <PasswordInput
                          {...field}
                          label='Password *'
                          // placeholder='Password'
                          className='mb-1'
                          size='md'
                          classNames={{
                            innerInput:
                              'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5 ',
                            input:
                              'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5 -mt-3',
                            label:
                              'text-[#8E8E8E] text-xs font-normal ml-2 bg-[#fff8f6] px-2 rounded z-10 relative',
                          }}
                        />
                      )}
                    />
                    {errors.password && (
                      <p className='text-sm  text-[#fc0101]'>
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                  <Tooltip
                    label='Should contain at least one lowercase, one uppercase, one
                  numeric, one special character and minimum 8-16 characters.'
                    width={200}
                    wrapLines
                    classNames={{
                      body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                    }}
                    radius='md'
                  >
                    <InfoIcon className='mt-3 cursor-pointer' />
                  </Tooltip>
                </div>

                {/* <div className='col-span-2'>
              <Controller
                control={control}
                name='Referral_Code'
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Referral Code'
                    // placeholder='Referral Code'
                    className='mb-1'
                    size='md'
                    classNames={{
                      input:
                        'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded pl-5 -mt-3',
                      label:
                        'text-[#8E8E8E] text-xs font-normal ml-2 bg-[#fff8f6] px-2 rounded z-10 relative',
                    }}
                  />
                )}
              />
              {errors.Referral_Code && (
                <p className='text-sm  text-[#fc0101]'>
                  {errors.Referral_Code.message}
                </p>
              )}
            </div> */}
                <div className='col-span-2 mx-auto mt-2 flex items-center'>
                  <Checkbox
                    color='#FB7C51'
                    checked={termsAndConditions}
                    onChange={(e) =>
                      setTermsAndConditions(e.currentTarget.checked)
                    }
                    size='sm'
                    classNames={{
                      label:
                        ' text-base text-center text-[#515151] cursor-pointer',
                      input: ' cursor-pointer border-[#FB7C51]',
                    }}
                  />
                  <p
                    className='ml-2 text-sm text-center text-[#828282] cursor-pointer flex items-center whitespace-nowrap'
                    onClick={() => setTermsAndConditions(!termsAndConditions)}
                  >
                    By signing up, I agree to the{' '}
                  </p>
                  <p
                    className='text-[#229af0] cursor-pointer pl-2'
                    onClick={() => setTermsAndConditionsModal(true)}
                  >
                    terms and conditions
                  </p>
                </div>
              </div>

              <div className='flex justify-center items-center'>
                {' '}
                {termsAndConditions ? (
                  <button className='h-9 w-32  bg-[#FB7C51] rounded  mt-5 mb-5 '>
                    <PrimaryButton> Sign up now </PrimaryButton>
                  </button>
                ) : (
                  <button
                    className='h-9 w-32  bg-[#FB7C51] rounded  mt-5 mb-5 opacity-50 cursor-not-allowed'
                    disabled
                    type='button'
                  >
                    <PrimaryButton> Sign up now </PrimaryButton>
                  </button>
                )}
              </div>
              <p className=' mb-5 text-[#6B6B6B] text-base font-semibold text-center'>
                Already have an account?{' '}
                <span
                  className='text-primary cursor-pointer'
                  onClick={() => navigate('/auth/login', { replace: true })}
                >
                  login here
                </span>
              </p>
            </form>
          </div>
          <div>
            <Subicon className='w-[45vw] h-[80%] ml-auto  2xl:h-full 2xl:w-full' />
          </div>
        </div>
      </div>
      <TermsAndCondition
        termsAndConditionsModal={termsAndConditionsModal}
        setTermsAndConditionsModal={setTermsAndConditionsModal}
      />
    </>
  );
};

export default SignUp;
