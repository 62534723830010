import React from 'react';
import Header from 'components/Header';

import EmployeeAttendance from '../components/attendance';
import TotalLeaveInfo from '../components/totalDaysOnLeave';
import TeamMembersLeaves from '../components/leaves';
import UpcomingEvents from '../components/upcomingEvents';
import TodaysTask from '../components/todaysTask';

function EmployeeDashboard() {
  return (
    <div className='px-5'>
      <div className='flex justify-between items-center mt-6'>
        <p className='text-xl text-A8A8A8'>
          My analytics &#60;{' '}
          <span className='text-gray font-semibold'>Dashboard</span>
        </p>
        <Header />
      </div>
      <div className='mb-6 mt-8 flex justify-between items-center'>
        <p className='text-22 font-semibold text-[#4E4E4E]'>Dashboard</p>
      </div>
      <div className='w-full flex'>
        <div className='w-1/2 pr-3'>
          <EmployeeAttendance />
        </div>
        <div className='w-1/2 pl-3'>
          <TotalLeaveInfo />
        </div>
      </div>
      <div className='flex'>
        <div className='mt-6 w-3/5 pr-3'>
          <TeamMembersLeaves />
          <UpcomingEvents />
        </div>
        <div className='w-2/5 pl-3 mb-5'>
          <TodaysTask />
        </div>
      </div>
    </div>
  );
}

export default EmployeeDashboard;
