import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Tooltip } from '@mantine/core';
import { AuthContext } from 'context/authContext';

import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import AddTeamMember from './addTeamMember';

export default function TeamCard({ employeeData }) {
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const { authState } = useContext(AuthContext);

  const customTooltip = (data) => {
    return (
      <div className='flex items-center'>
        <Avatar
          src={data?.reports_to__user_employee__profile_picture}
          alt=' '
          color='red'
          size={'md'}
          className='rounded-full mr-1 border-primary border'
        >
          {data?.reports_to__first_name[0]?.toUpperCase()}
        </Avatar>
        <div>
          <p className='font-medium text-lg'>{data?.reports_to__first_name}</p>
          <p className='text-sm -mt-1'>
            {data?.reports_to__user_employee__designation__name}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className='pr-6 2xl:pr-8 pb-2 w-full'>
      <div className='w-full h-48 2xl:h-52 shadow-lg rounded-xl p-4 border border-8B8B8B border-opacity-20 mb-4 relative bg-white'>
        <div>
          <div className='flex'>
            <Avatar
              src={employeeData?.profile_picture}
              alt=' '
              color='red'
              size={45}
              className='rounded-full mr-2'
            >
              {employeeData?.user__first_name?.[0]?.toUpperCase()}
            </Avatar>
            <Link to={`/app/team/my-team/${employeeData?.id}`}>
              <div className='cursor-pointer'>
                {employeeData?.user__first_name.length > 20 ? (
                  <Tooltip
                    label={employeeData?.user__first_name}
                    classNames={{
                      body: 'bg-white text-gray2 shadow-tooltip text-lg pr-3 z-0',
                    }}
                    radius='md'
                  >
                    <p className='text-xl font-semibold whitespace-nowrap w-40 truncate'>
                      {employeeData?.user__first_name}
                    </p>
                  </Tooltip>
                ) : (
                  <p className='text-xl font-semibold whitespace-nowrap '>
                    {employeeData?.user__first_name}
                  </p>
                )}
                <p className='text-base text-gray3'>
                  {employeeData?.designation__name}
                </p>
              </div>
            </Link>
            {authState?.is_admin ? (
              <span className='w-3 ml-auto'>
                <EditIcon
                  className=' mt-1.5 cursor-pointer'
                  onClick={() => setEditModalOpen(true)}
                />
              </span>
            ) : null}
          </div>
          <div className='text-8B8B8B pt-3 2xl:pt-5 text-[15px]'>
            <p>{employeeData?.employee_id}</p>
            {employeeData?.user__email.length > 30 ? (
              <Tooltip
                label={employeeData?.user__email}
                classNames={{
                  body: 'bg-white text-gray2 shadow-tooltip  pr-3 z-0',
                }}
                radius='md'
              >
                <p className='pt-1.5  w-40 truncate'>
                  {employeeData?.user__email}
                </p>
              </Tooltip>
            ) : (
              <p className='pt-1.5 '>{employeeData?.user__email}</p>
            )}
            <div className='flex justify-between pt-2.5 items-center'>
              <p className='w-50 truncate'>
                {employeeData?.user__mobile_number}
              </p>
              {employeeData?.reports_to__first_name && authState?.is_admin ? (
                <div className='flex space-x-1.5 items-center'>
                  <span>Reports to </span>
                  <Tooltip
                    label={customTooltip(employeeData)}
                    classNames={{
                      body: 'bg-white text-gray2 shadow-tooltip pr-3',
                    }}
                  >
                    <Avatar
                      src={
                        employeeData?.reports_to__user_employee__profile_picture
                      }
                      alt=' '
                      color={'red'}
                      size={'sm'}
                      className='rounded-full'
                    >
                      {employeeData?.reports_to__first_name[0]?.toUpperCase()}
                    </Avatar>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* Reporting manager indication */}
        {employeeData?.user__is_subadmin ? (
          <span className='w-1.5 h-20 bg-primary absolute top-16 right-0'></span>
        ) : null}
      </div>
      <AddTeamMember
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setEditModalOpen}
        name='Edit'
        employeeDetails={employeeData}
      />
    </div>
  );
}
