import React, { useContext, useEffect } from 'react';
import { Checkbox, Modal, Select, TextInput } from '@mantine/core';
import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow2.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clockIcon.svg';

// import CalendarImage from 'assets/images/calendar.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calanderIcon.svg';
import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mantine/dates';
import dateFormatter from 'helper/dateFormatter';
import useCalendarEvents from '../hooks/useCalendarEvents';

import useGetHolidayTypes from '../hooks/useGetHolidayTypes';
import useAddCalendarEvent from '../hooks/useAddCalendarEvent';
import useEditCalendarEvent from '../hooks/useEditCalendarEvent';
import { AuthContext } from 'context/authContext';
import dayjs from 'dayjs';

export default function EventModal({
  openModal,
  setOpenModal,
  modalTitle,
  selectedEvent,
}) {
  const { authState } = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    reset,
    setValue,
    // setError,
  } = useForm({ mode: 'onChange' });

  const closeModal = () => {
    setOpenModal(false);
    reset({});
  };
  // useEffect(()=>{
  //   setError('holiday_type_id',{type:"custom",message:"Required"})
  //    // eslint-disable-next-line
  // },[watch('is_holiday')])
  const submitHandler = (data) => {
    const for_date = dateFormatter(data?.for_date, 'YYYY-MM-DD');
    const to_date = dateFormatter(data?.to_date, 'YYYY-MM-DD');
    if (modalTitle === 'Add an event') {
      if (data?.is_holiday) {
        delete data['all_day_event'];
        delete data['mandatory'];
        delete data['from_time'];
        delete data['to_time'];
        addCalendarEventMutation.mutate({
          ...data,
          for_date,
          to_date,
          created_by_id: authState?.user_id,
          type: 'event',
        });
      } else {
        if (data?.all_day_event) {
          delete data['from_time'];
          delete data['to_time'];
          addCalendarEventMutation.mutate({
            ...data,
            for_date,
            to_date,
            created_by_id: authState?.user_id,
            type: 'event',
          });
        } else {
          addCalendarEventMutation.mutate({
            ...data,
            for_date,
            to_date,
            created_by_id: authState?.user_id,
            type: 'event',
          });
        }
      }
    }

    if (modalTitle === 'Edit event') {
      const newData = Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [key, data[key]])
      );

      const for_date = dateFormatter(data?.for_date, 'YYYY-MM-DD');
      const to_date = dateFormatter(data?.to_date, 'YYYY-MM-DD');
      Object.keys(dirtyFields)?.length !== 0 &&
        editCalendarEventMutation.mutate({
          id: selectedEvent,
          ...newData,
          for_date,
          to_date,
          // type: 'event',
        });
    }
  };
  const formErrorMessage = (name) => {
    return (
      errors?.[name] && (
        <p className='text-sm font-semibold text-red-500'>
          {errors?.[name]?.message}
        </p>
      )
    );
  };

  // Holiday Types
  const holidayTypes = useGetHolidayTypes();

  // CALENDAR
  const getSpecificEvent = useCalendarEvents(selectedEvent);
  const addCalendarEventMutation = useAddCalendarEvent(closeModal);
  const editCalendarEventMutation = useEditCalendarEvent(closeModal);

  useEffect(() => {
    if (selectedEvent) {
      const data = getSpecificEvent?.data?.data[0];
      reset({
        name: data?.name,
        for_date: new Date(data?.for_date),
        to_date: new Date(data?.to_date),
        is_holiday: data?.is_holiday,
        holiday_type_id: data?.holiday_type_id
          ? `${data?.holiday_type_id}`
          : null,
        from_time: data?.from_time,
        to_time: data?.to_time,
        all_day_event: data?.all_day_event,
        mandatory: data?.mandatory,
      });
    }
    // eslint-disable-next-line
  }, [getSpecificEvent?.data?.data, openModal]);

  useEffect(() => {
    if (watch('is_holiday')) {
      setValue('all_day_event', false);
      setValue('mandatory', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('is_holiday')]);

  const watchForDate = watch('for_date');

  useEffect(() => {
    if (watchForDate) {
      setValue('to_date', watch('for_date'));
    }
  }, [watchForDate, watch, setValue]);

  return (
    <Modal
      opened={openModal}
      onClose={closeModal}
      title={modalTitle}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      overflow='inside'
      centered
      size={'lg'}
      radius='md'
      transitionDuration={100}
      classNames={{
        title: 'mx-auto text-primary font-semibold text-18',
        modal: 'pl-10',
        body: 'scroller -mr-4 pr-10',
      }}
    >
      <div>
        <form className='mt-10 relative' onSubmit={handleSubmit(submitHandler)}>
          <div className='mt-10 mb-4'>
            <Controller
              control={control}
              name='name'
              defaultValue={''}
              rules={{ required: 'Required' }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label='Event name'
                  size='md'
                  required
                  classNames={{
                    input: 'border-primary',
                    label: 'text-neutralsGrey',
                  }}
                />
              )}
            />
            {formErrorMessage('name')}
          </div>

          <div className='flex items-center justify-end space-x-5 mb-10'>
            {authState?.is_admin && (
              <div className='flex items-center space-x-2'>
                <p className='text-neutralsGrey text-sm'>Holiday</p>
                <Controller
                  control={control}
                  name='is_holiday'
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={watch('is_holiday')}
                      size='md'
                    />
                  )}
                />
              </div>
            )}

            <div className='flex space-x-2'>
              <p className='text-neutralsGrey text-sm'>All day event</p>
              <Controller
                control={control}
                name='all_day_event'
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={watch('all_day_event')}
                    disabled={watch('is_holiday')}
                    size='md'
                  />
                )}
              />
            </div>

            <div className='flex space-x-2'>
              <p className='text-neutralsGrey text-sm'>Mandatory</p>
              <Controller
                control={control}
                name='mandatory'
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={watch('mandatory')}
                    disabled={watch('is_holiday')}
                    size='md'
                  />
                )}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-3 mb-10'>
            <div>
              <Controller
                control={control}
                name='for_date'
                rules={{
                  required: 'Required',
                }}
                defaultValue={new Date()}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label='From date'
                    size='md'
                    dropdownType='modal' //type can be popover also
                    minDate={dayjs(new Date()).toDate()}
                    rightSection={<CalendarIcon />}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey',
                      rightSection: 'pointer-events-none',
                    }}
                  />
                )}
              />
              {formErrorMessage('for_date')}
            </div>

            <div>
              <Controller
                control={control}
                name='to_date'
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label='To date'
                    size='md'
                    rightSection={<CalendarIcon />}
                    minDate={dayjs(new Date(watch('for_date'))).toDate()}
                    dropdownType='modal' // type can be popover also
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey',
                      rightSection: 'pointer-events-none',
                    }}
                  />
                )}
              />
              {formErrorMessage('to_date')}
            </div>
          </div>

          {!watch('all_day_event') && !watch('is_holiday') && (
            <div>
              <p className='text-neutralsGrey mb-1.5'>
                Duration<span className='text-red-600'>*</span>
              </p>
              <div className='grid grid-cols-2 gap-x-3'>
                <div>
                  <div className='relative flex items-center'>
                    <Controller
                      control={control}
                      name='from_time'
                      defaultValue={''}
                      rules={{ required: 'Required' }}
                      render={({ field }) => (
                        <input
                          {...field}
                          type='time'
                          className='pr-8 px-2 border-primary border rounded h-10   w-full'
                        />
                      )}
                    />
                    <ClockIcon className='absolute right-3 ' />
                  </div>
                  {formErrorMessage('from_time')}
                </div>
                <div>
                  <div className='relative flex items-center'>
                    <Controller
                      control={control}
                      name='to_time'
                      defaultValue={''}
                      rules={{
                        required: 'Required',
                        validate: () =>
                          watch('from_time') < watch('to_time') ||
                          'Invalid time selected',
                      }}
                      render={({ field }) => (
                        <input
                          {...field}
                          type='time'
                          className='pr-8 px-2 border-primary border rounded h-10   w-full'
                        />
                      )}
                    />
                    <ClockIcon className='absolute right-3 ' />
                  </div>
                  {formErrorMessage('to_time')}
                </div>
              </div>
            </div>
          )}

          {watch('is_holiday') && (
            <div>
              <Controller
                control={control}
                name='holiday_type_id'
                defaultValue={''}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label='Add type of holiday'
                    required
                    size='md'
                    clearable
                    data={
                      holidayTypes?.data?.data
                        ?.filter((element) => element?.is_active)
                        .map((holiday) => {
                          return {
                            value: `${holiday?.id}`,
                            label: holiday?.name,
                          };
                        }) || []
                    }
                    rightSection={<DownArrowIcon />}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey',
                    }}
                    styles={{
                      rightSection: {
                        pointerEvents: 'none',
                      },
                    }}
                  />
                )}
              />
              {formErrorMessage('holiday_type_id')}
            </div>
          )}

          <div className='flex justify-center mt-10'>
            <button className='w-32'>
              <PrimaryButton>
                <p>Save</p>
              </PrimaryButton>
            </button>
          </div>
        </form>

        <LeftArrowIcon
          className='absolute top-5 h-5 w-5 cursor-pointer'
          onClick={closeModal}
        />
      </div>
    </Modal>
  );
}
