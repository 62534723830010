import React, { useContext, useEffect } from 'react';
import { Modal, TextInput } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';

import useAddEmergencyDetails from '../hooks/useAddEmergencyDetails';
import useUpdateEmployeeEmergencyDetails from '../hooks/useUpdateEmergencyDetails';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { AuthContext } from 'context/authContext';

export default function EditEmergencyDetailsModal({
  isModalOpen,
  setIsModalOpen,
  emergencyDetails,
}) {
  const { authState } = useContext(AuthContext);

  // handle modal close
  const closeModal = () => {
    setIsModalOpen(false);
  };

  //   custom title for modal
  const customTitle = () => {
    return (
      <>
        <div className='relative flex justify-center items-center text-xl'>
          <LeftArrowIcon
            onClick={closeModal}
            className='w-4 absolute left-0 cursor-pointer'
          />
          <p>{emergencyDetails ? 'Edit' : 'Add'} details</p>
        </div>
        <p className='text-left mt-4 text-black'>Emergency contacts</p>
      </>
    );
  };

  //   default values for form
  const defaultValues = {
    employee_id: authState?.employee_id,
    primary_cname: '',
    primary_rel: '',
    primary_contact: '',
    primary_alternate: '',
    secondary_cname: '',
    secondary_rel: '',
    secondary_contact: '',
    secondary_alternate: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    // watch,
    control,
    // getValues,
    reset,
  } = useForm({ defaultValues });

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const addEmergencyDetailsMutation = useAddEmergencyDetails(closeModal, reset);
  const updateEmergencyDetailsMutation =
    useUpdateEmployeeEmergencyDetails(closeModal);

  const onSubmit = (data) => {
    const newData = { ...data, employee_id: authState?.employee_id };
    if (data?.id) {
      updateEmergencyDetailsMutation.mutate(newData);
    } else {
      addEmergencyDetailsMutation.mutate(newData);
    }
  };

  useEffect(() => {
    if (emergencyDetails) {
      reset({
        primary_cname: emergencyDetails?.primary_cname,
        primary_rel: emergencyDetails?.primary_rel,
        primary_contact: emergencyDetails?.primary_contact,
        primary_alternate: emergencyDetails?.primary_alternate,
        secondary_cname: emergencyDetails?.secondary_cname,
        secondary_rel: emergencyDetails?.secondary_rel,
        secondary_contact: emergencyDetails?.secondary_contact,
        secondary_alternate: emergencyDetails?.secondary_alternate,
        id: emergencyDetails?.id,
      });
    } else {
      reset({
        employee_id: authState?.employee_id,
        primary_cname: '',
        primary_rel: '',
        primary_contact: '',
        primary_alternate: '',
        secondary_cname: '',
        secondary_rel: '',
        secondary_contact: '',
        secondary_alternate: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergencyDetails]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-8',
        }}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-wrap'>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='primary_cname'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Primary contact name*'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('primary_cname')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='primary_rel'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Relationship with primary contact* '
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('primary_rel')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='primary_contact'
                  rules={{
                    required: 'required',
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Contact *'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('primary_contact')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='primary_alternate'
                  rules={{
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Alternate contact'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('primary_alternate')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='secondary_cname'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Secondary contact name*'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('secondary_cname')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='secondary_rel'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Relationship with Secondary contact* '
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('secondary_rel')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='secondary_contact'
                  rules={{
                    required: 'required',
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Contact *'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('secondary_contact')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='secondary_alternate'
                  rules={{
                    // required: 'required',
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Alternate contact'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('secondary_alternate')}
              </div>
              <div className='flex justify-center w-full mt-3'>
                <button className='w-28'>
                  <PrimaryButton
                    isLoading={
                      updateEmergencyDetailsMutation?.isLoading ||
                      addEmergencyDetailsMutation?.isLoading
                    }
                  >
                    Save
                  </PrimaryButton>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
