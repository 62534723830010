import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useGetTeamMembersLeaves() {
  return useQuery('dashboard-team-members-leaves', async () => {
    const response = await makeApiRequest.get(
      apiEndPoints.DASHBOARD_TEAM_MEMBERS_ON_LEAVE
    );
    return response?.data;
  });
}
