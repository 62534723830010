import React, { useState } from 'react';
import FileIcon from 'assets/icons/FileIcon.png';
import { ReactComponent as MoreOptionsIconWhite } from 'assets/icons/MoreOptionsIconWhite.svg';
import { ReactComponent as MoreOptionsIcon } from 'assets/icons/MoreOptionsIcon.svg';
import ModalVideo from 'react-modal-video';

import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import ModalImage from 'react-modal-image';

export default function FileViewer({ message }) {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  const [isOpen, setOpen] = useState(false);
  let { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <div className='flex m-2'>
      {/* image/jpeg  image/png */}
      {message?.file_type === 'image/jpeg' ||
      message?.file_type === 'image/png' ||
      message?.file_type === 'image/webp' ? (
        <div className='rounded-lg w-full max-w-xs max-h-96'>
          {/* <img src={message?.file_url} alt={message?.file_name} /> */}
          <div className='flex justify-end'>
            <Popover className='relative'>
              <Popover.Button
                ref={setReferenceElement}
                className='cursor-pointer flex items-center ml-2 py-1 mb-2 outline-none hover:scale-110'
                tabIndex={-1}
              >
                <MoreOptionsIcon className='' />
              </Popover.Button>

              <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className='absolute z-10 w-40 max-w-lg px-4 py-2'
              >
                <div className='overflow-hidden rounded-lg shadow-lg'>
                  <div className='relative bg-white p-3'>
                    <div className='flex flex-col'>
                      <a href={message?.file_url} rel='noreferrer' download>
                        <div className='flex items-start py-1 border-b border-black border-opacity-20 cursor-pointer'>
                          Download
                        </div>
                      </a>

                      <div className='flex items-start py-1 opacity-40'>
                        Copy Link
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Popover>
          </div>
          <ModalImage
            small={message?.file_url}
            large={message?.file_url}
            alt={message?.file_name}
          />
        </div>
      ) : message?.file_type === 'video/mp4' ||
        message?.file_type === 'video//webm' ? (
        <div className='bg-787878 rounded-lg w-full p-4 flex items-center justify-center'>
          <img
            onClick={() => {
              setOpen(true);
            }}
            src={FileIcon}
            alt='fileIcon'
            className='w-5 mr-2 cursor-pointer'
          />
          <p
            onClick={() => {
              setOpen(true);
            }}
            className='text-white mx-2 cursor-pointer'
          >
            {message?.file_name}
          </p>

          <Popover className='relative'>
            <Popover.Button
              ref={setReferenceElement}
              className='cursor-pointer flex items-center ml-2 py-1 outline-none hover:scale-110'
              tabIndex={-1}
            >
              <MoreOptionsIconWhite className='' />
            </Popover.Button>

            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className='absolute z-10 w-40 max-w-lg px-4 py-2'
            >
              <div className='overflow-hidden rounded-lg shadow-lg'>
                <div className='relative bg-white p-3'>
                  <div className='flex flex-col'>
                    <a href={message?.file_url} rel='noreferrer' download>
                      <div className='flex items-start py-1 border-b border-black border-opacity-20 cursor-pointer'>
                        Download
                      </div>
                    </a>
                    <div className='flex items-start py-1 opacity-40'>
                      Copy Link
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Popover>
          <ModalVideo
            channel='custom'
            autoplay
            isOpen={isOpen}
            url={message?.file_url}
            onClose={() => setOpen(false)}
          />
        </div>
      ) : (
        <div className='bg-787878 rounded-lg w-full p-4 flex  items-center justify-center'>
          <img src={FileIcon} alt='fileIcon' className='w-5 mr-2' />
          <p className='text-white mx-2'>{message?.file_name}</p>

          <Popover className='relative'>
            <Popover.Button
              ref={setReferenceElement}
              className='cursor-pointer flex items-center ml-2 py-1 outline-none hover:scale-110'
              tabIndex={-1}
            >
              <MoreOptionsIconWhite className='' />
            </Popover.Button>

            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className='absolute z-10 w-40 max-w-lg px-4 py-2'
            >
              <div className='overflow-hidden rounded-lg shadow-lg'>
                <div className='relative bg-white p-3'>
                  <div className='flex flex-col'>
                    <a href={message?.file_url} rel='noreferrer' download>
                      <div className='flex items-start py-1 border-b border-black border-opacity-20 cursor-pointer'>
                        Download
                      </div>
                    </a>
                    <div className='flex items-start py-1 opacity-40'>
                      Copy Link
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      )}
    </div>
  );
}
