import React from 'react';
import { Modal } from '@mantine/core';

import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';

import useEditAppliedLeave from '../hooks/useEditAppliedLeave';

import { ReactComponent as CrossIcon } from 'assets/icons/crossIcon.svg';
import { ReactComponent as CancelLeaveIlls } from 'assets/images/cancelLeaveIlls.svg';

export default function CancelLeaveRequest({
  isModalOpen,
  setIsModalOpen,
  leaveData,
}) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const calcelLeaveMutation = useEditAppliedLeave(closeModal, null);

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mt-4'>
        <CrossIcon
          onClick={closeModal}
          className='w-12 absolute right-0 cursor-pointer'
        />
      </div>
    );
  };

  const handleCancelLeaveRequest = () => {
    calcelLeaveMutation.mutate({
      id: leaveData?.id,
      status: 'Cancelled',
    });
  };

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='lg'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-2',
        }}
      >
        <div>
          <p className='text-center text-xl'>
            Are you sure you want to{' '}
            <span className='text-primary'>cancel</span> the leave request?
          </p>
          <CancelLeaveIlls className='w-72 mx-auto mt-2' />
          <div className='flex justify-center space-x-6 mb-4'>
            <button className='w-28' onClick={() => handleCancelLeaveRequest()}>
              <SecondaryButton isLoading={calcelLeaveMutation?.isLoading}>
                Yes
              </SecondaryButton>
            </button>
            <button className='w-28' onClick={() => closeModal()}>
              <PrimaryButton>No</PrimaryButton>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
