import React, { useState, useEffect } from 'react';
import { Switch } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
// import { ErrorToast } from 'services/toasterService';
import PrimaryButton from 'components/primaryButton';

import AddGradeModal from '../components/addGradeModal';
import DepartmentModal from '../components/departmentModal';
import AddEmployementTypesModal from '../components/addEmployementTypesModal';

import useCompanyDetails from '../hooks/useCompanyDetails';
import useEditCompanyDetails from '../hooks/useEditCompanyDetails';
import useEmploymentTypes from '../hooks/useEmploymentTypes';
import useGetAllGrades from '../hooks/useGetAllGrades';
import useEditEmploymentType from '../hooks/useEditEmploymentType';
import useEditSalaryGrade from '../hooks/useEditSalaryGrade';

import BgABCImg from 'assets/images/abc.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minusIcon.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clockIcon.svg';

export default function EmployeeDetails() {
  const [employementTypeModalOpen, setEmployementTypeModalOpen] =
    useState(false);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [debouncedForFromTime] = useDebouncedValue(fromTime, 1000);
  const [debouncedForToTime] = useDebouncedValue(toTime, 1000);
  const [isEmployeeDetailsModalOpen, setIsEmployeeDetailsModalOpen] =
    useState(false);
  const [isAddGradeModal, setAddGradeModalOpen] = useState(false);

  const employmentTypes = useEmploymentTypes();
  const allSalaryGrades = useGetAllGrades();

  const updateEmployeeGrade = useEditSalaryGrade();

  // Working Timings
  const getAllCompanyDetails = useCompanyDetails();
  const companyDetails = getAllCompanyDetails?.data?.data?.[0];
  const editCompanyDetailsMutation = useEditCompanyDetails();
  // const allCompanyDetails = companyDetails?.data?.data?.[0];
  const updateEmploeeType = useEditEmploymentType();

  // updating working hours
  useEffect(() => {
    if (debouncedForFromTime && debouncedForToTime) {
      // if (
      //   debouncedForFromTime < debouncedForToTime &&
      //   (fromTime !== companyDetails?.from_time ||
      //     toTime !== companyDetails?.to_time)
      // ) {
      // console.log(
      //   debouncedForFromTime,
      //   companyDetails?.from_time?.slice(0, 5)
      // );
      // console.log(debouncedForToTime, companyDetails?.to_time?.slice(0, 5));
      if (
        companyDetails?.from_time?.slice(0, 5) !== debouncedForFromTime ||
        companyDetails?.to_time?.slice(0, 5) !== debouncedForToTime
      ) {
        editCompanyDetailsMutation.mutate({
          id: companyDetails?.id,
          from_time: debouncedForFromTime,
          to_time: debouncedForToTime,
        });
      }
      // } else {
      //   ErrorToast({ text: 'Please select Valid time' });
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedForFromTime, debouncedForToTime]);

  useEffect(() => {
    setFromTime(companyDetails?.from_time?.slice(0, 5));
    setToTime(companyDetails?.to_time?.slice(0, 5));
  }, [companyDetails]);
  // use below for validation

  // useEffect(() => {
  //   if (debouncedForFromTime && debouncedForToTime) {
  //     if (
  //       debouncedForFromTime < debouncedForToTime &&
  //       (debouncedForFromTime !== companyDetails?.from_time ||
  //         debouncedForToTime !== companyDetails?.to_time)
  //     ) {
  //       if (
  //         companyDetails?.from_time?.slice(0, 5) !== debouncedForFromTime ||
  //         companyDetails?.to_time?.slice(0, 5) !== debouncedForToTime
  //       ) {
  //         editCompanyDetailsMutation.mutate({
  //           company_id: companyDetails?.id,
  //           from_time: debouncedForFromTime,
  //           to_time: debouncedForToTime,
  //         });
  //       }
  //     } else {
  //       ErrorToast({ text: 'Please select Valid time' });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedForFromTime, debouncedForToTime]);

  return (
    <div className='bg-white min-h-[420px] shadow-three rounded-md p-5'>
      <div className='flex items-center justify-between h-14 bg-[#FFE8E1] rounded-md py-8 px-9'>
        <p className='text-20'>Employee working hours</p>
      </div>

      {/* Default Values */}
      <div
        className='py-6 px-9'
        style={{
          backgroundImage: `url(${BgABCImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: '400px',
        }}
      >
        {/* Working Timings */}
        <div>
          <div className='flex items-center justify-between'>
            <p className='text-[#434343]'>Working timings are same for all</p>
            <Switch
              size='md'
              checked={companyDetails?.are_timings_same || ''}
              onChange={() => {
                editCompanyDetailsMutation.mutate({
                  id: companyDetails?.id,
                  are_timings_same: !companyDetails?.are_timings_same,
                });
              }}
            />
          </div>

          {/* This needs to visible only when the above "Switch" is ACTIVE */}
          {companyDetails?.are_timings_same && (
            <div className='flex items-center justify-between mt-4 mb-8'>
              <p className='text-[#434343]'>
                If same, please select the timings
              </p>
              <div className='space-x-6 flex items-center'>
                <div>
                  <p className='text-sm text-gray pl-1'>From</p>
                  <div className='relative flex items-center'>
                    <input
                      type='time'
                      value={fromTime}
                      onChange={(e) => setFromTime(e.target.value)}
                      className='pr-8 px-2 border-neutralsGrey border h-8 border-opacity-30 rounded-md'
                    />
                    <ClockIcon className='absolute right-3 ' />
                  </div>
                </div>
                <MinusIcon className='mt-5' />
                <div>
                  <p className='text-sm text-gray pl-1'>To</p>
                  <div className='relative flex items-center'>
                    <input
                      type='time'
                      value={toTime}
                      onChange={(e) => setToTime(e.target.value)}
                      className='pr-8 px-2 border-neutralsGrey border h-8 border-opacity-30 rounded-md'
                    />
                    <ClockIcon className='absolute right-3 ' />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* performance details */}
          {/* <div className='flex items-center justify-between mt-6'>
            <p className='text-[#434343]'>
              Allow employee to view performance reports
            </p>
            <Switch
              defaultChecked={allCompanyDetails?.allow_view_report}
              size='md'
              onChange={() => {
                editCompanyDetailsMutation.mutate({
                  id: allCompanyDetails?.id,
                  allow_view_report: !allCompanyDetails?.allow_view_report,
                });
              }}
            />
          </div> */}
        </div>
      </div>

      {/* employement types */}
      <div className='flex items-center justify-between h-14 bg-[#FFE8E1] rounded-md py-8 px-9 mt-4'>
        <p className='text-20'>Employment types</p>
        <button
          onClick={() => setEmployementTypeModalOpen(true)}
          className='w-36 whitespace-nowrap'
        >
          <PrimaryButton>
            <p>+ Add types</p>
          </PrimaryButton>
        </button>
      </div>
      {employmentTypes?.data?.data?.map((type) => {
        return (
          <div
            className='flex items-center justify-between mx-10 my-6'
            key={type?.id}
          >
            <p className='text-gray'>{type?.name} </p>
            <Switch
              size='md'
              checked={type?.is_active || ''}
              onChange={() => {
                updateEmploeeType.mutate({
                  id: type?.id,
                  is_active: !type?.is_active,
                });
              }}
            />
          </div>
        );
      })}

      {/* Employement grades */}
      <div className='flex items-center justify-between h-14 bg-[#FFE8E1] rounded-md py-8 px-9 mt-10'>
        <p className='text-20'>Employment grades</p>
        <button
          onClick={() => setAddGradeModalOpen(true)}
          className='w-36 whitespace-nowrap'
        >
          <PrimaryButton>
            <p>+ Add grades</p>
          </PrimaryButton>
        </button>
      </div>
      {allSalaryGrades?.data?.data?.map((type) => {
        return (
          <div
            className='flex items-center justify-between mx-10 my-6'
            key={type?.id}
          >
            <p className='text-gray pl-4'>{type?.name} </p>
            <Switch
              size='md'
              checked={type?.is_active || ''}
              onChange={() => {
                updateEmployeeGrade.mutate({
                  id: type?.id,
                  is_active: !type?.is_active,
                });
              }}
            />
          </div>
        );
      })}

      {/* Modal for adding a new field */}
      <DepartmentModal
        isModalOpen={isEmployeeDetailsModalOpen}
        setIsModalOpen={setIsEmployeeDetailsModalOpen}
        modalHeader='Add a new field'
      />

      <AddEmployementTypesModal
        isModalOpen={employementTypeModalOpen}
        setIsModalOpen={setEmployementTypeModalOpen}
      />

      <AddGradeModal
        isModalOpen={isAddGradeModal}
        setIsModalOpen={setAddGradeModalOpen}
      />
    </div>
  );
}
