import { useQuery } from "react-query";
import makeApiRequest from "services/makeApiRequest";
import apiEndPoints from "services/apiEndPoints";
import { useContext } from "react";
import { AuthContext } from "context/authContext";

export default function useGetDesignations() {
  const { authState } = useContext(AuthContext);
  return useQuery("get-employee-designations", async () => {
    const response = await makeApiRequest.get(
      `${apiEndPoints.EMPLOYEE_DESIGNATIONS}?department__company_id=${authState.company_id}`
    );
    return response?.data;
  });
}
