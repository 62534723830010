import React from 'react';
import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';
import useGetSeePermisions from '../hooks/useGetSeePermisions';
import useUpdateSeePermisions from '../hooks/useUpdateSeePermisions';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';

const { Modal, Switch } = require('@mantine/core');

const SeeTeamMemberPermisions = ({
  seeTeamMemberPermisionsModal,
  setseeTeamMemberPermisionsModal,
  isAdmin,
}) => {
  const permissions = useGetSeePermisions();
  const updatePermision = useUpdateSeePermisions(
    setseeTeamMemberPermisionsModal
  );

  const { handleSubmit, control, reset, watch } = useForm();

  const onSubmit = (data) => {
    data['can_view_pr'] = data?.can_comment_pr;
    updatePermision.mutate(data);
  };

  useEffect(() => {
    if (permissions?.data?.data) {
      reset({
        id: permissions?.data?.data[0]?.id,
        can_add_employees: permissions?.data?.data[0]?.can_add_employees,
        can_publish_blogs: permissions?.data?.data[0]?.can_publish_blogs,
        can_comment_pr: permissions?.data?.data[0]?.can_comment_pr,
        can_view_pr: permissions?.data?.data[0]?.can_view_pr,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions?.data?.data]);

  return (
    <Modal
      opened={seeTeamMemberPermisionsModal}
      onClose={() => {
        setseeTeamMemberPermisionsModal(false);
      }}
      title='Edit permissions'
      size='lg'
      centered
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      transitionDuration={100}
      overflow='inside'
      className='px-10'
      classNames={{
        title: 'text-lg font-semibold text-primary mx-auto my-4',
        body: 'scroller',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='px-5'>
          <div className='pl-7 grid grid-cols-2 gap-y-4 '>
            <p className='text-[#8E8E8E] '>Employee can add other employees</p>
            <Controller
              control={control}
              name='can_add_employees'
              render={({ field }) => (
                <Switch
                  {...field}
                  disabled={!isAdmin}
                  checked={watch('can_add_employees')}
                  className='place-self-end'
                  size='md'
                />
              )}
            />
            <p className='text-[#8E8E8E]'>
              Employee can review and publish blogs
            </p>
            <Controller
              control={control}
              name='can_publish_blogs'
              render={({ field }) => (
                <Switch
                  className='place-self-end '
                  size='md'
                  disabled={!isAdmin}
                  checked={watch('can_publish_blogs')}
                  {...field}
                />
              )}
            />
            <p className='text-[#8E8E8E]'>
              Employee can view and comment on performance reports
            </p>
            <Controller
              control={control}
              name='can_comment_pr'
              render={({ field }) => (
                <Switch
                  className='place-self-end '
                  size='md'
                  disabled={!isAdmin}
                  checked={watch('can_comment_pr')}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className='flex justify-center items-center gap-5 mt-8 mb-3'>
          {isAdmin ? (
            <>
              <div
                className='w-28'
                onClick={() => {
                  setseeTeamMemberPermisionsModal(false);
                }}
              >
                <SecondaryButton>Cancel</SecondaryButton>
              </div>
              <button className='w-28'>
                <PrimaryButton isLoading={updatePermision?.isLoading}>
                  Save
                </PrimaryButton>
              </button>
            </>
          ) : null}
        </div>
      </form>
    </Modal>
  );
};
export default SeeTeamMemberPermisions;
