import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

// import { AuthContext } from 'context/authContext';
import { AuthContext } from '../../../context/authContext';

// import makeApiRequest from 'services/makeApiRequest';
import makeApiRequest from '../../../services/makeApiRequest';
// import apiEndPoints from 'services/apiEndPoints';
import apiEndPoints from '../../../services/apiEndPoints';
import { ErrorToast } from 'services/toasterService';
// import { ErrorToast } from 'services/toasterService';
// import { ErrorToast } from '../../../services/toasterService';

export default function useLogin() {
  const { authDispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  return useMutation(
    async (data) => {
      const res = await makeApiRequest.post(apiEndPoints.LOGIN, data);
      return res.data;
    },
    {
      onSuccess: (data) => {
        if (data?.message === 'error') {
          ErrorToast({ text: 'Something went wrong' });
        } else {
          authDispatch({
            type: 'LOGIN',
            payload: data,
          });
          // SuccessToast({ text: data.message });

          if (data?.is_admin) {
            navigate(`/app/admin/dashboard/productivity`, {
              replace: true,
            });
          } else {
            navigate(`/app/employee/analytics/dashboard`, {
              replace: true,
            });
          }
        }
      },
      onError: (error) => {
        ErrorToast({
          text:
            error.response.data.non_field_errors[0] ===
            `[ErrorDetail(string='User account is not active.', code='invalid')]`
              ? 'User account is not active'
              : error.response.data.non_field_errors[0] ===
                `[ErrorDetail(string='Your username and/or password do not match.', code='invalid')]`
              ? 'Invalid Credentials'
              : error.response.data.non_field_errors[0] ===
                'User matching query does not exist.'
              ? 'User does not exist'
              : error.response.data.message,
        });
      },
    }
  );
}
