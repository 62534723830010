import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetemployeetypes(id) {
  return useQuery(
    ['get-employeement-types-in-superaAdmin', id],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.SETTINGS_EMPLOYMENT_TYPES, {
          req_company_id: id,
          is_active: 'True',
        })
      );
      return response.data;
    },
    {
      enabled: !!id,
    }
  );
}
