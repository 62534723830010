import React, { useState } from 'react';
import { Tabs } from '@mantine/core';
import Header from 'components/Header';

import OrganisationStructure from '../components/organisationStructure';

// import Departments from './departments';
// import Policies from './policies';
// import PrimaryButton from 'components/primaryButton';
// import OrganisationSalaryComponents from '../components/organisationsalarycomponents';
import OrganisationSalaryComponentsModal from '../components/organisitionsalarycomponentsModal';
// import EmployeeDetails from './employeeDetails';

// import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';  
import { useSearchParams } from 'react-router-dom';

export default function OrganisationSettingsMain() {
  const [searchParams, setSearchParams] = useSearchParams();

  // const [addDepartmentModal, setAddDepartmentModal] = useState(false);
  // const [addPolicyModal, setAddPolicyModal] = useState(false);

  const [salaryComponentsModalOpend, setSalaryComponentsModalOpend] =
    useState(false);

  const handleTabChange = (e) => {
    setSearchParams({ tab: e });
  };

  return (
    <div className='relative'>
      <div className='px-5'>
        <div className='flex justify-between items-center mt-6'>
          <p className='text-xl text-A8A8A8'>Organisation Settings</p>
          <Header />
        </div>
        <div className='relative mt-8'>
          <Tabs
            active={~~searchParams.get('tab')}
            onTabChange={handleTabChange}
            tabPadding={21}
            classNames={{
              tabsListWrapper: 'border-0',
              tabActive: 'border-2 border-primary font-semibold px-0',
              tabControl: 'text-A8A8A8 leading-5 px-0 mr-8',
              tabLabel: 'text-18 text-[#333333]',
            }}
          >
            <Tabs.Tab label='Organisation structure'>
              <OrganisationStructure />
            </Tabs.Tab>
            {/* <Tabs.Tab label='Departments'>
              <Departments
                addDepartmentModal={addDepartmentModal}
                setAddDepartmentModal={setAddDepartmentModal}
              />
            </Tabs.Tab>
            <Tabs.Tab label='Employee Details'>
              <EmployeeDetails />
            </Tabs.Tab>
            <Tabs.Tab label='Salary components'>
              <OrganisationSalaryComponents />
            </Tabs.Tab>
            <Tabs.Tab label='Policies'>
              <Policies
                addPolicyModal={addPolicyModal}
                setAddPolicyModal={setAddPolicyModal}
              />
            </Tabs.Tab> */}
          </Tabs>

          {/* {~~searchParams.get('tab') === 1 ? (
            <div className='absolute top-0 right-0'>
              <button
                onClick={() => {
                  setAddDepartmentModal(true);
                }}
              >
                <PrimaryButton>+ Add department</PrimaryButton>
              </button>
            </div>
          ) : ~~searchParams.get('tab') === 3 ? (
            <div className='absolute top-0 right-0'>
              <button onClick={() => setSalaryComponentsModalOpend(true)}>
                <PrimaryButton>+ Add a component</PrimaryButton>
              </button>
            </div>
          ) : ~~searchParams.get('tab') === 4 ? (
            <div className='absolute top-0 right-0'>
              <button onClick={() => setAddPolicyModal(true)}>
                <PrimaryButton>+ Add policy</PrimaryButton>
              </button>
            </div>
          ) : ~~searchParams.get('tab') === 0 ? (
            <div className='absolute top-0 right-0'>
              <TextInput
                icon={<SearchIcon />}
                placeholder='Search'
                className='w-40'
                classNames={{
                  input: 'border-0 rounded-none border-b-2 border-primary',
                }}
              />
            </div>
          ) : null} */}
        </div>
      </div>
      <OrganisationSalaryComponentsModal
        salaryComponentsModalOpend={salaryComponentsModalOpend}
        setSalaryComponentsModalOpend={setSalaryComponentsModalOpend}
      />

      {/* admin and super admin indications */}
      {~~searchParams.get('tab') === 0 ? (
        <div className='fixed bottom-5 right-20'>
          <div className='flex space-x-8'>
            <div className='flex items-center space-x-1 rounded-md'>
              <span className='w-3 h-3 bg-[#fb0c51]'></span>
              <p>Super admin</p>
            </div>
            <div className='flex items-center space-x-1 rounded-md'>
              <span className='w-3 h-3 bg-[#fbbb00]'></span>
              <p>Admin</p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
