import { Modal, Avatar, Tooltip } from '@mantine/core';

import dayjs from 'dayjs';

import { ReactComponent as MobileIcon } from 'assets/icons/mobileIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mailIcon.svg';
import { ReactComponent as GradeIcon } from 'assets/icons/gradeIcon.svg';

// hooks
//get datas
// import UsegetskillsfromSettings from '../hooks/usegetskills';
import Usegetemployessfromteams from '../hooks/usegetemployess';
import Usegetreviewsfromsettings from '../hooks/usegetreviewtypes';
import ApparisalFormField from './apparisalfromfield';

const ApparisalModal = ({
  addApparisalData,
  setAddApparisalData,
  apparisalEmployeData,
  setApprasialSelectEmployeeModal,
  apparisalDataEmployee,
}) => {
  //tab skills data
  // const getTabSkillData = UsegetskillsfromSettings();

  const totalEmployess = Usegetemployessfromteams(
    apparisalDataEmployee
      ? apparisalDataEmployee?.employee_id
      : apparisalEmployeData?.Select_employee
  );
  const reviewTypes = Usegetreviewsfromsettings(
    apparisalDataEmployee
      ? apparisalDataEmployee?.review_type_id
      : apparisalEmployeData?.review_type
  );
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[850px] my-4'>
        <LeftArrowIcon
          onClick={() => setAddApparisalData(false)}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className=''>Creating appraisal report</p>
      </div>
    );
  };

  return (
    <Modal
      opened={addApparisalData}
      onClose={() => {
        setAddApparisalData(false);
      }}
      title={customTitle()}
      withCloseButton={false}
      // title='Creating performance report'
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      centered
      overlayOpacity={0.5}
      transitionDuration={1}
      size={'70%'}
      overflow='inside'
      className='px-8'
      classNames={{
        title: 'text-lg font-semibold ',

        body: 'scroller',
      }}
    >
      <div className='bg-[#FFF8F6] rounded-md p-4 mx-4 '>
        <div className='flex  w-full '>
          <Avatar
            radius='xl'
            size='lg'
            color='#FB7C51'
            src={totalEmployess?.data?.data?.[0]?.profile_picture}
          />
          <div className='w-full pl-5'>
            <div className='flex justify-between items-center  w-full '>
              <h1 className='text-[#000000] font-bold text-normal'>
                {totalEmployess?.data?.data?.[0]?.user__first_name}
                <span className='text-primary font-normal text-sm ml-2'>
                  {totalEmployess?.data?.data?.[0]?.employee__preferred_pronoun}
                </span>
              </h1>
              <div className='flex items-center gap-2 mb-1'>
                <p className='font-normal text-sm'>
                  {/* {dayjs.apparisalEmployeData.segment[0].format('MMM YYYY')} */}

                  {apparisalDataEmployee ? (
                    <>
                      {`${dayjs(apparisalDataEmployee?.segment_from).format(
                        'DD MMM YY'
                      )} - ${dayjs(apparisalDataEmployee?.segment_to).format(
                        'DD MMM YY'
                      )}`}
                    </>
                  ) : (
                    <>{`${dayjs(apparisalEmployeData?.segment[0]).format(
                      'DD MMM YY'
                    )} - ${dayjs(apparisalEmployeData?.segment[1]).format(
                      'DD MMM YY'
                    )}`}</>
                  )}
                </p>
                <p className='text-primary font-normal text-base'>
                  {reviewTypes?.data?.data?.[0]?.name}
                </p>
              </div>
            </div>
            <div className='flex items-center justify-between  '>
              <p className='text-[#A8A8A8] font-normal text-base'>
                {totalEmployess?.data?.data?.[0]?.designation__name}
              </p>
              <div className=' flex  items-center gap-2'>
                <p className='flex items-center text-[#828282] font-normal text-sm'>
                  <MobileIcon className='mr-2 h-4 w-4' />
                  {totalEmployess?.data?.data?.[0]?.user__mobile_number}
                </p>
                <Tooltip
                  label={totalEmployess?.data?.data?.[0]?.user__email}
                  classNames={{
                    body: 'bg-primary',
                  }}
                  radius='md'
                >
                  <p className='flex items-center text-[#828282] font-normal text-sm'>
                    <MailIcon className='mr-2 h-4 w-4' />
                    {totalEmployess?.data?.data?.[0]?.user__email.slice(0, 30) +
                      '...'}
                  </p>
                </Tooltip>
                <p className='flex items-center text-[#828282] font-normal text-sm'>
                  <LocationIcon className='mr-2 h-4 w-4' />
                  {totalEmployess?.data?.data[0]?.department__name}
                </p>
                <p className='flex items-center text-[#828282] font-normal text-sm'>
                  <GradeIcon className='mr-2 h-4 w-4' />
                  {totalEmployess?.data?.data[0]?.grade__name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5 relative mx-4'>
        <ApparisalFormField
          apparisalEmployeData={apparisalEmployeData}
          setAddApparisalData={setAddApparisalData}
          setApprasialSelectEmployeeModal={setApprasialSelectEmployeeModal}
          apparisalDataEmployee={apparisalDataEmployee}
          employeeGrossSalary={totalEmployess?.data?.data[0]?.gross_salary}
        />
      </div>
    </Modal>
  );
};
export default ApparisalModal;
