import React, { useState } from 'react';
import { Table } from '@mantine/core';
import SecondaryButton from 'components/secondaryButton';

import { ReactComponent as NoPerformanceReports } from 'assets/icons/noPerfromanceReports.svg';

import useGetperformancereportInTeamsProfile from '../hooks/useGetperformanceReportsofEmplyee';
import dayjs from 'dayjs';
import ViewPerformanceModal from '../../performance/components/viewPerformancereportmodal';
export default function PerformanceReview() {
  const employeePerformanceReportData = useGetperformancereportInTeamsProfile();
  const [employeePerformancemodalOpened, setEmployeePerformancemodalOpened] =
    useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  return (
    <>
      <div className='min-h-[30vh]'>
        {employeePerformanceReportData?.data?.data.length === 0 ? (
          <div className='flex justify-center items-center  flex-col bg-[#ffffff] rounded-2xl shadow-3xl'>
            <NoPerformanceReports />
            <p className='mt-2 text-[#535353]'>
              Seems like their is no performance reports yet!
            </p>
          </div>
        ) : (
          <Table verticalSpacing={'lg'} highlightOnHover>
            <thead>
              <tr>
                <th
                  className='bg-[#FFE8E1] rounded-tl-xl w-72'
                  style={{ textAlign: 'center' }}
                >
                  Review type
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Date
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Status
                </th>
                <th
                  className='bg-[#FFE8E1] rounded-tr-xl'
                  style={{ textAlign: 'center' }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {employeePerformanceReportData?.data?.data?.map(
                (eachPerformance) => (
                  <tr>
                    <td className=' text-[#848383] text-base flex justify-center items-center'>
                      {' '}
                      <div className='text-[#848383] text-normal mb-7 flex flex-col justify-center'>
                        <p className='m-0 text-center'>
                          {eachPerformance?.review_type__name}
                        </p>
                        <p>
                          {' '}
                          {`${dayjs(eachPerformance?.segment_from).format(
                            ' MMM YYYY'
                          )} - ${dayjs(eachPerformance?.segment_to).format(
                            ' MMM YYYY'
                          )}`}
                        </p>
                      </div>
                    </td>

                    <td className='text-center text-[#848383] text-base'>
                      {`${dayjs(eachPerformance?.created_date).format(
                        'DD MMM YYYY'
                      )}`}
                    </td>
                    <td
                      className={
                        eachPerformance?.is_seen
                          ? 'text-center text-[#28B446] text-lg'
                          : 'text-center text-[#FF9F2D] text-lg'
                      }
                    >
                      {eachPerformance?.is_seen ? 'Acknowledged' : 'Unseen '}
                    </td>

                    <td>
                      {/* <div className='flex items-center justify-evenly'>
                <EyeIcon
                  className='cursor-pointer'
                  onClick={() => {
                    setEmployeePerformancemodalOpened(true);
                    setEmployeeId(eachPerformance?.id);
                  }}
                />
                {eachPerformance?.is_draft ? (
                  <EditIcon
                    className='cursor-pointer'
                    onClick={() => {
                      setReportModal(true);
                      setEmployeeId(eachPerformance?.id);
                    }}
                  />
                ) : null}{' '}
                <DownloadIcon className='cursor-pointer opacity-50' />
              </div> */}
                      <div className='flex justify-center items-center'>
                        <button
                          onClick={() => {
                            setEmployeePerformancemodalOpened(true);
                            setEmployeeId(eachPerformance?.id);
                          }}
                        >
                          <SecondaryButton>View</SecondaryButton>
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}
      </div>
      <ViewPerformanceModal
        employeePerformancemodalOpened={employeePerformancemodalOpened}
        setEmployeePerformancemodalOpened={setEmployeePerformancemodalOpened}
        employeeId={employeeId}
        setEmployeeId={setEmployeeId}
      />
    </>
  );
}

//  const customTimelineTitle = (isEditable) => {
//    return (
//      <div className='flex justify-between items-center'>
//        <p className='text-primary text-base'>Feb 22 - May 22</p>
//        <div>
//          {isEditable ? (
//            <button className='mr-4'>
//              <SecondaryButton>
//                <p>Edit report</p>
//              </SecondaryButton>
//            </button>
//          ) : null}
//          <button>
//            <PrimaryButton>
//              <p className='p-1'>View report</p>
//            </PrimaryButton>
//          </button>
//        </div>
//      </div>
//    );
//  };
//  return (
//    <div>
//      <div className='mt-4 pl-6'>
//        <Timeline
//          active={1}
//          bulletSize={20}
//          lineWidth={2}
//          classNames={{
//            itemTitle: 'bg-FFE8E1 p-2 font-medium',
//          }}
//        >
//          <Timeline.Item title={customTimelineTitle(true)}>
//            <div>
//              <Tabs
//                tabPadding={21}
//                classNames={{
//                  tabsListWrapper: 'border-0',
//                  tabActive: 'border-2 border-primary font-semibold text-18',
//                  tabControl: 'text-A8A8A8 leading-5 px-0 mr-6',
//                  tabLabel: 'text-base text-gray',
//                }}
//              >
//                <Tabs.Tab label='Professional excellence'>
//                  <PerformanceReviewTabsContent />
//                </Tabs.Tab>
//                <Tabs.Tab label='Personal excellence'>
//                  <PerformanceReviewTabsContent />
//                </Tabs.Tab>
//                <Tabs.Tab label='Organisational skills'>
//                  <PerformanceReviewTabsContent />
//                </Tabs.Tab>
//              </Tabs>
//            </div>
//          </Timeline.Item>
//          <Timeline.Item title={customTimelineTitle(false)}>
//            <div>
//              <Tabs
//                tabPadding={21}
//                classNames={{
//                  tabsListWrapper: 'border-0',
//                  tabActive: 'border-2 border-primary font-semibold text-18',
//                  tabControl: 'text-A8A8A8 leading-5 px-0 mr-6',
//                  tabLabel: 'text-base text-gray',
//                }}
//              >
//                <Tabs.Tab label='Professional excellence'>
//                  <PerformanceReviewTabsContent />
//                </Tabs.Tab>
//                <Tabs.Tab label='Personal excellence'>
//                  <PerformanceReviewTabsContent />
//                </Tabs.Tab>
//                <Tabs.Tab label='Organisational skills'>
//                  <PerformanceReviewTabsContent />
//                </Tabs.Tab>
//              </Tabs>
//            </div>
//          </Timeline.Item>
//        </Timeline>
//      </div>
//    </div>
//  );
