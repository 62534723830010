import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { useParams } from 'react-router-dom';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetAllAppliedLeavesForAdmin(filterValue, searchValue) {
  const { id } = useParams();
  return useQuery(['get-all-Applied-leaves-for-admin', filterValue, id, searchValue], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_APPLIED_LEAVES, {
        employee_id: id,
        month: filterValue,
        search: searchValue
      })
    );
    return res.data;
  });
}
