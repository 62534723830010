import React from 'react';
import Header from 'components/Header';
import useOrganisationCharts from './hooks/useOrganisationCharts';
import { Tree } from 'react-organizational-chart';
import { Avatar } from '@mantine/core';
import useCompanyDetails from 'pages/settings/hooks/useCompanyDetails';
import ChartLineImg from 'assets/images/chartLine.svg';

import TreeCard from './components/TreeCard';

export default function Chart() {
  const organisationChartDetails = useOrganisationCharts();
  let allOrganisationChartDetails = organisationChartDetails?.data?.data;
  const allCompanyDetails = useCompanyDetails();

  allOrganisationChartDetails = [
    {
      id: allCompanyDetails?.data?.data?.[0]?.id,
      name: allCompanyDetails?.data?.data?.[0]?.company_name,
      profile_picture: allCompanyDetails?.data?.data?.[0]?.company_logo,
      children: allOrganisationChartDetails,
    },
  ];

  return (
    <div className='px-5 pb-9'>
      <div className='flex justify-between items-center mt-6'>
        <p className='text-xl text-A8A8A8'>
          Organisation &#60;{' '}
          <span className='text-gray font-semibold'>Organisation chart</span>
        </p>
        <Header />
      </div>

      <div
        className='bg-gradient-to-b from-[#FFFFFF] to-[#FFF8F6] shadow-five rounded-[18px] mt-10 p-4 pr-0 pb-0.5'
        style={{
          backgroundImage: `url(${ChartLineImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0 98%',
          backgroundSize: '100%',
        }}
      >
        <div className='h-full overflow-scroll scroller pt-10 pb-20'>
          <Tree
            lineColor='#C0C0C0'
            lineWidth='2px'
            key={'13458979'}
            label={
              <div
                className='flex items-center justify-center w-fit mx-auto'
                key={allOrganisationChartDetails?.[0]?.id}
              >
                {allOrganisationChartDetails?.[0]?.profile_picture ? (
                  <div className='p-2 border-2 border-primary rounded-full'>
                    <Avatar
                      src={allOrganisationChartDetails?.[0]?.profile_picture}
                      size='80px'
                      color='red'
                      className='capitalize  rounded-full text-2xl shadow-sm '
                      classNames={{
                        image: 'object-contain',
                      }}
                    >
                      {allOrganisationChartDetails?.[0]?.name?.[0]}
                    </Avatar>
                  </div>
                ) : (
                  <p className='text-[#4D4D4D] capitalize text-center p-4 border-2 border-primary rounded-full'>
                    {allOrganisationChartDetails?.[0]?.name}
                  </p>
                )}
              </div>
            }
          >
            {allOrganisationChartDetails?.map((parent) => {
              return parent?.children?.map?.((child) => {
                return <TreeCard key={child?.id} child={child} />;
              });
            })}
          </Tree>
        </div>
      </div>
    </div>
  );
}
