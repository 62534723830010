import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useGetHolidayTypes() {
  return useQuery(
    'holiday-types',
    async () => {
      const response = await makeApiRequest.get(apiEndPoints.HOLIDAY_TYPES_API);
      return response?.data;
    },
    { staleTime: 3000 }
  );
}
