import { Modal } from '@mantine/core';
import SecondaryButton from 'components/secondaryButton';

const PreviewImage = ({
  previewImageModal,
  setPreviewImageModal,
  file,
  setFile,
}) => {
  return (
    <div>
      <Modal
        opened={previewImageModal}
        onClose={() => {
          setPreviewImageModal(false);
          setFile(null);
        }}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size={'70%'}
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full font-bold  text-lg py-4  text-center text-primary',
          modal: 'pl-10 ',
          body: 'scroller -mr-4 pr-8 ',
        }}
      >
        <div className='flex justify-center flex-col items-center'>
          <img src={file} alt='preview' className=' overflow-scroll ' />
        </div>
        <div className='flex justify-center items-center mt-2'>
          <button
            onClick={() => setPreviewImageModal(false)}
            className='cursor-pointer'
          >
            <SecondaryButton>Cancel</SecondaryButton>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PreviewImage;
