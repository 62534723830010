import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetAdminProfile() {
  return useQuery('get-admin-profile-details', async () => {
    const response = await makeApiRequest.get(apiEndPoints.ADMIN_PROFILE);

    return response.data;
  });
}
