import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetspecificpolicies(id) {
  return useQuery(
    ['policies-get-data-specific', id],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.POLICIES_API, {
          id: id,
        })
      );

      return response.data;
    },
    {
      enabled: !!id,
    }
  );
}
