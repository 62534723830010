import React, { useState } from 'react';
import Header from 'components/Header';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Avatar, Tabs } from '@mantine/core';
import dayjs from 'dayjs';

import EmployeeDetails from './components/employeeDetails';
import AddOrganisationModal from 'pages/organisationSettings/components/addOrganisation';

import useGetAdminCompanyDetails from './hooks/useGetCompanyDetails';

import { ReactComponent as BackArrowIcon } from 'assets/icons/backArrowIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon2.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobileIcon2.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mailIcon2.svg';
import { ReactComponent as EmployeesIcon } from 'assets/icons/employeesIcon.svg';
import { ReactComponent as CalendaryearIcon } from 'assets/icons/calendaryearIcon.svg';
import Departments from '../departments';
// import OrganisationSalaryComponents from 'pages/organisationSettings/components/organisationsalarycomponents';
import Policies from '../policies';
import OrganisationSalaryComponentsModal from 'pages/organisationSettings/components/organisitionsalarycomponentsModal';
import PrimaryButton from 'components/primaryButton';
import EmployeeDetailsTab from '../employeeDetails';
import Chart from './components/chart';

export default function AdminProfile() {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [salaryComponentsModalOpend, setSalaryComponentsModalOpend] =
    useState(false);

  const [addPolicyModal, setAddPolicyModal] = useState(false);

  const { company_id } = useParams();

  const navigate = useNavigate();
  const getCompanyDetails = useGetAdminCompanyDetails();

  let [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (e) => {
    setSearchParams({ tab: e });
  };

  return (
    <>
      <div className='mt-6 mx-6'>
        <div className='flex items-center justify-between'>
          <p className='text-xl text-A8A8A8'>
            Organisation Settings &#60;{' '}
            <span className='text-gray font-semibold'> Admin profile</span>
          </p>
          <Header />
        </div>

        <BackArrowIcon
          className='w-4 cursor-pointer mt-4'
          onClick={() => navigate('/app/organisation-settings')}
        />
        {searchParams.get('chart') ? (
          <Chart />
        ) : (
          <div>
            <div className='border border-opacity-10 border-gray rounded-xl mt-4 shadow-lg pt-4 bg-white'>
              <div className='flex justify-between items-center'>
                <div className='flex items-center space-x-2'>
                  <Avatar
                    src={getCompanyDetails?.data?.data[0]?.company_logo}
                    size={40}
                    color='red'
                    radius={50}
                    className='capitalize ml-4'
                  >
                    {getCompanyDetails?.data?.data[0]?.company_name[0]?.toUpperCase()}
                  </Avatar>
                  <p className='text-gray font-semibold text-xl'>
                    {getCompanyDetails?.data?.data[0]?.company_name}
                  </p>
                </div>
                <div className='flex items-center space-x-10 pr-4'>
                  {/* <p className='text-67A1EF'>View organisation chart</p> */}
                  <div className='flex items-center'>
                    <div className='text-right pr-2'>
                      <p className='text-[#707070]'>
                        {getCompanyDetails?.data?.data[0]?.user__first_name}
                      </p>
                      <p className='text-[#969696] text-sm'>Admin</p>
                    </div>
                    <Avatar
                      src={''}
                      size={40}
                      color='red'
                      radius={50}
                      className='capitalize border border-primary border-opacity-50'
                    >
                      {getCompanyDetails?.data?.data[0]?.user__first_name[0]?.toUpperCase()}
                    </Avatar>
                    <EditIcon
                      className='cursor-pointer ml-5'
                      onClick={() => setIsEditModalOpen(true)}
                    />
                  </div>
                </div>
              </div>
              <div className='px-5 pb-4 text-gray3 mt-2'>
                <div className='flex justify-between'>
                  <div className='flex space-x-2 items-center'>
                    <LocationIcon className='w-4' />
                    <p>
                      {getCompanyDetails?.data?.data[0]?.company_address
                        ? getCompanyDetails?.data?.data[0]?.company_address
                        : 'N/A'}
                    </p>
                  </div>
                  <div className='flex space-x-4 items-center '>
                    <EmployeesIcon className='w-7' />
                    <p>
                      {getCompanyDetails?.data?.data[0]?.total_emps}{' '}
                      {getCompanyDetails?.data?.data[0]?.total_emps === 1
                        ? 'employee'
                        : 'employees'}
                    </p>
                  </div>
                  {/* <p className='text-67A1EF'>View organisation chart</p> */}
                </div>
                <div className='flex mt-3 justify-between items-center'>
                  <div className='flex space-x-8 items-center'>
                    <div className='flex space-x-2'>
                      <MobileIcon className='w-4' />
                      <p>{getCompanyDetails?.data?.data[0]?.mobile_number}</p>
                    </div>
                    <div className='flex space-x-2'>
                      <MailIcon className='w-4' />
                      <p>{getCompanyDetails?.data?.data[0]?.user__email}</p>
                    </div>
                    <div className='flex space-x-2 items-center'>
                      <CalendaryearIcon className='w-4' />
                      {getCompanyDetails?.data?.data[0]?.fy_from_date ? (
                        <p>
                          {dayjs(
                            getCompanyDetails?.data?.data[0]?.fy_from_date
                          ).format('DD MMM YYYY')}{' '}
                          -{' '}
                          {dayjs(
                            getCompanyDetails?.data?.data[0]?.fy_to_date
                          ).format('DD MMM YYYY')}
                        </p>
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                  </div>
                  <Link
                    to={`/app/organisation-settings/admin-profile/${company_id}/?chart=${getCompanyDetails?.data?.data[0]?.company_name}`}
                  >
                    <p className='text-67A1EF cursor-pointer'>
                      View organisation chart
                    </p>
                  </Link>
                  {/* <div className='flex space-x-4 items-center '>
              <EmployeesIcon className='w-7' />
              <p>
                {getCompanyDetails?.data?.data[0]?.total_emps}{' '}
                {getCompanyDetails?.data?.data[0]?.total_emps === 1
                  ? 'employee'
                  : 'employees'}
              </p>
            </div> */}
                </div>
              </div>
            </div>
            <div className='relative mt-6'>
              <Tabs
                active={~~searchParams.get('tab')}
                onTabChange={handleTabChange}
                classNames={{
                  tabsListWrapper: 'border-0',
                  tabActive: 'border-2 border-primary font-semibold text-18',
                  tabControl: 'text-A8A8A8 leading-5 px-0 mr-4',
                  tabLabel: 'text-18 text-gray',
                }}
              >
                <Tabs.Tab label='Employees'>
                  <EmployeeDetails />
                </Tabs.Tab>
                <Tabs.Tab label='Clients' disabled className='opacity-50'>
                  {/* <DeactiveTeamMembers searchValue={debounced} /> */}
                </Tabs.Tab>
                <Tabs.Tab label='Projects' disabled className='opacity-50'>
                  {/* <DeactiveTeamMembers searchValue={debounced} /> */}
                </Tabs.Tab>
                <Tabs.Tab label='Dept. & Designation'>
                  {/* <EmployeeDetails /> */}
                  <Departments company_id={company_id} />
                </Tabs.Tab>
                <Tabs.Tab label='Employee Details'>
                  <EmployeeDetailsTab company_id={company_id} />
                </Tabs.Tab>
                {/* <Tabs.Tab label='Salary Components'>
                  <OrganisationSalaryComponents company_id={company_id} />
                </Tabs.Tab> */}
                <Tabs.Tab label='Policies'>
                  <Policies
                    company_id={company_id}
                    addPolicyModal={addPolicyModal}
                    setAddPolicyModal={setAddPolicyModal}
                    h
                  />
                </Tabs.Tab>
              </Tabs>

              <div className='absolute top-0 right-0'>
                {~~searchParams.get('tab') === 5 ? (
                  <button
                    onClick={() => {
                      setSalaryComponentsModalOpend(true);
                    }}
                  >
                    <PrimaryButton>+ Add a component</PrimaryButton>
                  </button>
                ) : null}

                {~~searchParams.get('tab') === 6 ? (
                  <button onClick={() => setAddPolicyModal(true)}>
                    <PrimaryButton>+ Add policy</PrimaryButton>
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>

      <AddOrganisationModal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        companyDetails={getCompanyDetails?.data?.data[0]}
        company_id={company_id}
        formName='Edit'
      />
      <OrganisationSalaryComponentsModal
        salaryComponentsModalOpend={salaryComponentsModalOpend}
        setSalaryComponentsModalOpend={setSalaryComponentsModalOpend}
        company_id={company_id}
      />
      {/* <SalaryComponentsModal
        salaryComponentsModalOpend={salaryComponentsModalOpend}
        setSalaryComponentsModalOpend={setSalaryComponentsModalOpend}
      /> */}
    </>
  );
}
