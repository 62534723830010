import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetleavestypes(id, cb) {
  return useQuery(
    ['get-leave-types', id],
    async () => {
      const response = await makeApiRequest.get(
        `${apiEndPoints.LEAVETYPES_API}?leave_module_id=${id}`
      );

      return response.data;
    },
    {
      onSuccess: () => {
        cb();
      },
    }
  );
}
