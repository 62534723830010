import dayjs from 'dayjs';
import React, { useState } from 'react';
import { ReactComponent as EyeSolid } from 'assets/icons/EyeSolid.svg';
import { ReactComponent as MoreOptionsIcon } from 'assets/icons/MoreOptionsIcon.svg';
import FileViewer from './fileViewer';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';

export default function SentMessageCard({
  message,
  isFirst,
  conversationContainerSendJsonMessage,
}) {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);
  return (
    <div className='flex my-1'>
      <div className='flex w-full justify-end'>
        <div className='flex bg-FEFEFE flex-col justify-around rounded ml-2 p-3 max-w-xl'>
          <div className=' flex items-center justify-end ml-2'>
            <p className='text-838383 text-xs'>
              {dayjs(message?.timestamp).isToday()
                ? dayjs(message?.timestamp).format('hh:mm A')
                : dayjs(message?.timestamp).format('DD/MM')}
            </p>
            <Popover className='relative'>
              <Popover.Button
                ref={setReferenceElement}
                className='cursor-pointer flex items-center ml-2 py-1 outline-none hover:scale-110'
                tabIndex={-1}
              >
                <MoreOptionsIcon className='' />
              </Popover.Button>

              <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className='absolute z-10 w-40 max-w-lg px-4 py-2'
              >
                <div className='overflow-hidden rounded-lg shadow-lg'>
                  <div className='relative bg-white p-3'>
                    <div className='flex flex-col'>
                      <div
                        onClick={() => {
                          conversationContainerSendJsonMessage({
                            type: 'delete_message_type',
                            message_id: message?.id,
                            is_deleted: true,
                          });
                          referenceElement.click();
                        }}
                        className='flex items-start py-1 cursor-pointer'
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Popover>
          </div>
          {message?.is_deleted ? (
            <div className='flex'>
              <p className='text-4F4F4F  '>
                <span className='italic'>This message has been deleted. </span>
                <span
                  onClick={() => {
                    conversationContainerSendJsonMessage({
                      type: 'delete_message_type',
                      message_id: message?.id,
                      is_deleted: false,
                    });
                  }}
                  className='ml-2 cursor-pointer hover:underline'
                >
                  Undo
                </span>
              </p>
            </div>
          ) : !message?.file_url ? (
            <div className='flex'>
              <p className='text-4F4F4F '>{message?.content}</p>
            </div>
          ) : (
            <FileViewer message={message}></FileViewer>
          )}
        </div>
        <div className='flex items-end ml-1 w-3'>
          {message.lastread ? (
            <span className='w-3 opacity-60'>
              <EyeSolid />
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}
