import NoPageFound from 'pages/common/pageNotFound';
import { Route, Routes } from 'react-router-dom';

import EmployeeLeaves from './pages/leaves';
// import LeaveCalender from './pages/leaves/components/leaveCalender';
// import OrganisationCalender from './pages/organisation-calendar/calendar';

const Calender = () => {
  return (
    <Routes>
      <Route path='/leaves' element={<EmployeeLeaves />} />
      {/* <Route path='/leavecalender' element={<LeaveCalender />} /> */}

      {/* empty page when route not found */}
      <Route path='*' element={<NoPageFound />} />
    </Routes>
  );
};

export default Calender;
