import React, { useContext, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Avatar, Indicator, TextInput } from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "context/authContext";
import { useParams, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import makeApiRequest from "services/makeApiRequest";
import apiEndPoints from "services/apiEndPoints";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import { ReactComponent as SendIcon } from "assets/icons/SendIcon2.svg";
import { ReactComponent as EmojiSmile } from "assets/icons/EmojiSmile.svg";
import ReceivedMessageCard from "./components/receivedMessageCard";
import SentMessageCard from "./components/sentMessageCard";
import StatusLabel from "../hooks/statusLabel";
import { ChatContext } from "context/chatContext";
import { useDebounce } from "react-use";
// import { ChatLoader } from './components/ChatLoader';

import { DashboardModal, useUppy } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import PrimaryButton from "components/primaryButton";
import UpdateGroupModal from "./components/updateGroupModal";
import { useQueryClient } from "react-query";

// import FileUploadModal from './components/fileUploadModal';

export default function ConversationContainer() {
  const [activeConnection, setActiveConnection] = useState(false);
  const [userHasWritePermission, setUserHasWritePermission] = useState(false);
  const [messages, setMessages] = useState([]);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [showEmojies, setShowEmojies] = useState(false);
  const [isfileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [isUserActiveOnScreen, setIsUserActiveOnScreen] = useState(false);
  const [activeUser, setActiveUser] = useState();

  const [page, setPage] = useState(2);
  const queryClient = useQueryClient();

  const { authState } = useContext(AuthContext);
  const { chatState, chatDispatch } = useContext(ChatContext);

  const { conversationRoom } = useParams();
  const [searchParams] = useSearchParams();

  // const [groupUserList, setGroupUsersList] = useState(
  //   chatState?.groupSpecificUsersList || null
  // );
  const [groupInfo, setGroupInfo] = useState(null);
  const [groupUpdateModal, setGroupUpdateModal] = useState(false);

  const [tempCurrentUserData, setTempCurrentUserData] = useState({});
  let lastReadMessage;

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: false,
    }).use(XHRUpload, {
      endpoint: `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_BASE_URL
          : process.env.REACT_APP_BASE_URL
      }${apiEndPoints.UPLOAD_FILES}`,
      getResponseData: (data) => {
        const fileData = JSON.parse(data);
        sendJsonMessage({
          type: "chat_message",
          message: "",
          file_url: fileData.file_url,
          filename: fileData.filename,
          filetype: fileData.type,
        });
        return {
          url: data?.file_url,
        };
      },
    });
  });

  useEffect(() => {
    if (!isfileUploadModalOpen) {
      uppy.cancelAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isfileUploadModalOpen]);

  // uppy.on('upload-success', (data) => {
  //   console.log(data, 'I am from upload success');
  // });

  const handleOpen = () => {
    setFileUploadModalOpen(true);
  };
  const handleClose = () => {
    setFileUploadModalOpen(false);
  };

  async function fetchMessages() {
    // const apiRes = await fetch(
    //   `http://192.168.0.134:8000/api/messages/?conversation=${conversationRoom}&page=${page}`,
    //   {
    //     method: 'GET',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //       Authorization: `jwt ${authState?.token}`,
    //     },
    //   }
    // );
    const apiRes = await makeApiRequest.get(
      `${apiEndPoints.CHAT_MORE_MESSAGES}?conversation=${conversationRoom}&page=${page}`
    );
    if (apiRes.status === 200) {
      const data = await apiRes.data;
      setHasMoreMessages(data.next !== null);
      setPage(page + 1);
      setMessages((prev) => prev.concat(data.results));
    }
  }

  console.log("messages :", messages);

  useEffect(() => {
    const userInfo = chatState?.conversationsList?.find(
      (element) => element?.user_id === chatState?.activeConversationUser?.id
    );
    console.log(chatState?.conversationsList, "message");
    if (!!userInfo?.message) {
      if (userInfo?.user_id === userInfo?.message?.from_user?.id) {
        setActiveUser(userInfo?.message?.from_user);
      } else {
        setActiveUser(userInfo?.message?.to_user);
      }
    } else {
      setActiveUser(tempCurrentUserData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatState?.activeConversationUser, chatState?.conversationsList]);

  let unreadmsgscount = 0;
  chatState?.conversationsList?.forEach((e) => {
    unreadmsgscount += e?.unread_msgs;
  });

  console.log("undread msgs count :",unreadmsgscount)

  // ws code

  const { readyState, sendJsonMessage } = useWebSocket(
    authState.isAuthenticated
      ? `${process.env.REACT_APP_WEB_SOCKET}${
          searchParams.get("group")
            ? apiEndPoints.GROUP_CONVERSATION
            : apiEndPoints.ONE_ON_ONE_CONVERSATION
        }${conversationRoom}/`
      : null,
    {
      queryParams: {
        token: authState.isAuthenticated ? authState.token : "",
      },
      onOpen: () => {
        console.log("Connected!");
        setActiveConnection(true);
      },
      onClose: () => {
        cancel();
        console.log("Disconnected!");
        setActiveConnection(false);
      },
      // onMessage handler
      onMessage: (e) => {
        const data = JSON.parse(e.data);
        console.log(data, "conversation container web socket");
        switch (data.type) {
          case "last_10_messages":
            setMessages(data?.messages);
            setHasMoreMessages(data?.has_more);
            break;
          case "chat_message_echo":
            if (userHasWritePermission)
              setMessages((prev) => [data.message, ...prev]);
            // sendJsonMessage({
            //   type: 'read_messages',
            // });
            break;
          case "room_detail_information":
            // setGroupUsersList(data.users_list);
            chatDispatch({
              type: "SET_GROUP_SPECIFIC_USER_LIST",
              payload: data.users_list,
            });
            setGroupInfo(data.room_info);
            break;
          case "user_join_permission":
            if (!data.user_permission) setActiveConnection(false);
            if (!data.hasOwnProperty("user_group_permission")) {
              setUserHasWritePermission(data.user_permission);
            }

            break;
          case "user_info":
            chatDispatch({
              type: "SET_ACTIVE_CONVERSATION_USER",
              payload: data?.info,
            });
            const userInfo = chatState?.conversationsList?.find(
              (element) => element?.user_id === data?.info?.id
            );

            if (!userInfo) {
              const newChatList = [
                {
                  ...data?.info,
                  user_id: data?.info?.id,
                  last_msg: "",
                  unread_msgs: 0,
                  message: null,
                },
                ...chatState?.conversationsList,
              ];

              chatDispatch({
                type: "SET_CONVERSATIONS",
                payload: newChatList,
              });
            }
            setTempCurrentUserData(data?.info);
            break;
          case "read_messages_echo":
            if (authState.user_id !== data.user_id) {
              const updateReadMessage = [...messages];
              updateReadMessage[0].read = data.read;
              setMessages(updateReadMessage);
            }
            break;
          case "send_group_info_updated":
            queryClient.invalidateQueries("current-chat-group-info");
            queryClient.invalidateQueries("chat-group-members-addons");
            chatDispatch({
              type: "SET_GROUP_SPECIFIC_USER_LIST",
              payload: data.users_list,
            });
            setGroupInfo(data.room_info);
            if (!data.user_join_permission) setActiveConnection(false);

            // setUserHasWritePermission(data.user_group_permission);
            break;
          case "delete_message_response":
            const currentMessages = messages.map((message) => {
              if (message.id === data?.message_id) {
                message.is_deleted = data.is_deleted;
              }
              return message;
            });

            setMessages(currentMessages);
            break;

          // case 'user_join':
          //   setParticipants((pcpts: string[]) => {
          //     if (!pcpts.includes(data.user)) {
          //       return [...pcpts, data.user];
          //     }
          //     return pcpts;
          //   });
          //   break;
          // case 'user_leave':
          //   setParticipants((pcpts: string[]) => {
          //     const newPcpts = pcpts.filter((x) => x !== data.user);
          //     return newPcpts;
          //   });
          //   break;
          // case 'online_user_list':
          //   setParticipants(data.users);
          //   break;
          // case 'typing':
          //   updateTyping(data);
          //   break;
          default:
            console.error("Unknown message type!");
            break;
        }
      },
    }
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (connectionStatus === "Open") {
      sendJsonMessage({
        type: "read_messages",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionStatus, isUserActiveOnScreen]);

  // ws code end

  const { handleSubmit, control, getValues, watch, setFocus, setValue, reset } =
    useForm({
      defaultValues: { message: "" },
    });

  const onSubmit = (data) => {
    sendJsonMessage({
      type: "chat_message",
      message: data.message,
    });
    reset();
  };

  // Checking user on screen
  const onFocusFunction = () => {
    // do whatever when focus is gained
    setIsUserActiveOnScreen(true);
  };

  const onBlurFunction = () => {
    // do whatever when focus is lost
    setIsUserActiveOnScreen(false);
  };

  useEffect(() => {
    onFocusFunction();

    window.addEventListener("focus", onFocusFunction);
    window.addEventListener("blur", onBlurFunction);

    return () => {
      onBlurFunction();

      window.removeEventListener("focus", onFocusFunction);
      window.removeEventListener("blur", onBlurFunction);
    };
  }, []);

  // setting user typing... status
  const [typingState, setTypingState] = useState(false);
  const messageValue = watch("message");

  const [, cancel] = useDebounce(
    () => {
      setTypingState(false);
    },
    1000,
    [messageValue]
  );

  useEffect(() => {
    if (connectionStatus === "Open") {
      sendJsonMessage({
        type: "typing",
        typing: typingState,
      });
    }
    // console.log(typingState, 'typingState');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typingState]);

  useEffect(() => {
    if (messageValue.length) setTypingState(true);
  }, [messageValue]);

  // setting user specific input field data is chatContext

  useEffect(() => {
    const inputFieldUserSpecificData = [...chatState.inputFieldDataForEachUser];

    const index = inputFieldUserSpecificData.findIndex(
      (data) => data.conversationRoom === conversationRoom
    );

    if (index >= 0) {
      inputFieldUserSpecificData[index].content = messageValue;
    } else {
      inputFieldUserSpecificData.push({
        content: "",
        conversationRoom: conversationRoom,
      });
    }

    chatDispatch({
      type: "SET_INPUT_FIELD_DATA_USER_SPECIFIC",
      payload: inputFieldUserSpecificData,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageValue]);

  useEffect(() => {
    setValue(
      "message",
      chatState.inputFieldDataForEachUser?.find(
        (element) => element.conversationRoom === conversationRoom
      )?.content ?? ""
    );
    setPage(2);
    setGroupInfo(null);
    // setGroupUsersList(null);
    chatDispatch({
      type: "SET_GROUP_SPECIFIC_USER_LIST",
      payload: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationRoom]);

  const getMessageSpecificUser = (users, id, activeUser) => {
    if (users?.length > 0) {
      return users?.find((user) => user.id === id);
    } else {
      return activeUser;
    }
  };

  return (
    <>
      {activeConnection ? (
        <div className="flex flex-col w-full">
          <div className="flex w-full">
            <div className="flex custom-indicator">
              <Indicator
                inline
                size={24}
                offset={7}
                label={groupInfo ? false : StatusLabel(activeUser?.status)}
                position="bottom-end"
                withBorder
                radius="xl"
              >
                <Avatar
                  src={
                    groupInfo
                      ? groupInfo?.profile_picture
                      : activeUser?.profile_picture || ""
                  }
                  radius="xl"
                  color="red"
                  size={"42px"}
                  classNames={{ root: "border-2 border-primary" }}
                >
                  {groupInfo
                    ? groupInfo?.name?.[0]
                    : activeUser?.username?.[0] || "-"}
                </Avatar>
              </Indicator>
            </div>

            <div className="flex flex-col flex-1 justify-around ml-2">
              <p className="leading-4 text-lg">
                {groupInfo ? groupInfo?.name : activeUser?.username ?? ""}{" "}
                {groupInfo ? null : activeUser?.preferred_pronoun ? (
                  <span className="text-primary">{`${
                    "(" + activeUser?.preferred_pronoun + ")" ?? ""
                  }`}</span>
                ) : null}
              </p>
            </div>
            {groupInfo ? (
              <>
                <div className="flex">
                  <button
                    onClick={() => {
                      setGroupUpdateModal(true);
                    }}
                    className="w-28"
                  >
                    <PrimaryButton>Group Info</PrimaryButton>
                  </button>
                </div>
                <UpdateGroupModal
                  updateGroupModal={groupUpdateModal}
                  setUpdateGroupModal={setGroupUpdateModal}
                  groupInfo={groupInfo}
                  conversationContainerSendJsonMessage={sendJsonMessage}
                  setUserHasWritePermission={setUserHasWritePermission}
                />
              </>
            ) : null}
          </div>
          <div className="flex flex-col flex-1 w-full my-2 rounded-md  max-h-full overflow-hidden ">
            <div
              id="scrollableDiv"
              className="flex flex-col-reverse bg-FFF2F2 flex-1 p-4 overflow-y-scroll scroller"
            >
              <div>
                {/* Put the scroll bar always on the bottom */}
                <InfiniteScroll
                  dataLength={messages.length}
                  next={fetchMessages}
                  className="flex flex-col-reverse scroller" // To put endMessage and loader to the top
                  inverse={true}
                  hasMore={hasMoreMessages}
                  loader={false}
                  scrollableTarget="scrollableDiv"
                >
                  {messages?.map((message, index) => {
                    if (message.read && !lastReadMessage) {
                      lastReadMessage = true;
                      message.lastread = true;
                    } else {
                      message.lastread = false;
                    }

                    return authState.user_id === message?.from_user?.id ? (
                      <SentMessageCard
                        message={message}
                        key={message?.id}
                        isFirst={index === 0}
                        conversationContainerSendJsonMessage={sendJsonMessage}
                      />
                    ) : message.is_deleted ? null : (
                      <ReceivedMessageCard
                        message={message}
                        key={message?.id}
                        activeUser={getMessageSpecificUser(
                          chatState?.groupSpecificUsersList,
                          message?.from_user?.id,
                          activeUser
                        )}
                        isFirst={index === 0}
                        conversationContainerSendJsonMessage={sendJsonMessage}
                      />
                    );
                  })}
                </InfiniteScroll>
              </div>
            </div>
            <div className=" mt-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex w-full relative">
                  <button
                    type="button"
                    disabled={!userHasWritePermission}
                    onClick={() => {
                      setTimeout(() => {
                        setShowEmojies(!showEmojies);
                      }, 10);
                    }}
                    className={`flex items-center cursor-pointer mr-3 ${
                      userHasWritePermission ? "" : "opacity-60"
                    }`}
                  >
                    <EmojiSmile />
                  </button>
                  {showEmojies ? (
                    <div className="mr-2 absolute -top-[28.5rem] left-2  z-[200]">
                      <Picker
                        data={data}
                        onEmojiSelect={(event) => {
                          setValue(
                            "message",
                            getValues("message") + event.native
                          );
                          setFocus("message");
                        }}
                        previewPosition="none"
                        onClickOutside={(e) => {
                          if (showEmojies) setShowEmojies(false);
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="w-full mr-2">
                    <Controller
                      control={control}
                      name="message"
                      rules={{
                        required: "Required",
                      }}
                      render={({ field }) => (
                        <TextInput
                          placeholder="Message"
                          size="sm"
                          autoComplete="off"
                          disabled={!userHasWritePermission}
                          classNames={{
                            input:
                              "text-[15px] bg-transparent border-[#FB7C51]  text-normal rounded pl-5",
                            label: "text-[#8E8E8E] text-sm font-normal ",
                          }}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="flex justify-center items-center mr-3 ml-2">
                    <button
                      disabled={!userHasWritePermission}
                      type="button"
                      onClick={() => handleOpen()}
                      className={` ${
                        userHasWritePermission ? "" : "opacity-60"
                      }`}
                    >
                      <img
                        src="https://img.icons8.com/metro/26/000000/upload.png"
                        alt="upload"
                      />
                    </button>
                  </div>
                  <button
                    disabled={!userHasWritePermission}
                    className={`mx-2 ${
                      userHasWritePermission ? "" : "opacity-60"
                    }`}
                  >
                    <SendIcon className="w-9 h-9" />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <DashboardModal
            uppy={uppy}
            closeModalOnClickOutside={false}
            open={isfileUploadModalOpen}
            closeAfterFinish={true}
            onRequestClose={handleClose}
            metaFields={[{ id: "name", name: "File name" }]}
            proudlyDisplayPoweredByUppy={false}
            // showRemoveButtonAfterComplete={true}
            // doneButtonHandler={(data) =>
            //   console.log(data, 'I am from doneButtonHandler')

            // }
          />
        </div>
      ) : // <div className='flex items-center justify-center flex-col w-full'>
      //   <p>unable to establish a connection!</p>
      // </div>
      null}
    </>
  );
}
