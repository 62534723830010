import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetSkillDevelopmentInAdmin() {
  const { id } = useParams();
  return useQuery(['employee-skills-development-in-Admin'], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.SKILL_DEVELOPMENT_API, {
        employee_id: id,
      })
    );

    return response.data;
  });
}
