import { useMutation } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useUpdateperformancereportseenstatus() {
  //   const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(
        apiEndPoints.PERFORMANCE_BY_ADMIN,
        data
      );
      return response?.data;
    }
    // {
    //   onSuccess: (data) => {
    //     SuccessToast({ text: data?.message });
    //     // queryClient.invalidateQueries('get-all-team-members');
    //   },
    //   onError: (error) => {
    //     ErrorToast({ text: error.response.data.message });
    //   },
    // }
  );
}
