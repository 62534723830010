import React, { useState } from 'react';

import EditEmergencyDetailsModal from './editEmergencyDetails';
import EditMedicalDetailsModal from './editMedicalDetailsModal';
import AddEmployeeFamilyDetailsModal from './addEmployeeFamilyDetailsModal';
import useGetEmployeeMedicalDetails from '../hooks/useGetEmployeeMedicalDetails';
import EditEmployeeFamilyDetailsModal from './editFamilyMembersDetails';

import useGetEmployeeFamilyInfo from '../hooks/useGetEmployeeFamilyDetails';
import useGetEmployeeEmergencyInfo from '../hooks/useGetEmployeeEmergencyDetails';
import useDeleteEmployeeFamilyMemberDetails from '../hooks/useDeleteFamilyMember';

import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minusIcon.svg';
import { ReactComponent as RoundedCrossIcon } from 'assets/icons/roundedCrossIcon.svg';
import { ReactComponent as AddIcon } from 'assets/icons/addIcon.svg';
import { ReactComponent as UploadedIcon } from 'assets/images/uploadedIcon.svg';

export default function FamilyAndEmergencyDetails() {
  const [isEditEmergencyDetailsModalOpen, setEditEmergencyModalOpen] =
    useState(false);
  const [isEditMedicalDetailsModalOpen, setEditMedicalModalOpen] =
    useState(false);
  const [isAddFamilyDetailsModalOpen, setAddFamilyModalOpen] = useState(false);
  const [isEditFamilyDetailsModalOpen, setEditFamilyModalOpen] =
    useState(false);
  const [selectedFamilyMemberData, setSelectedFamilyMemberData] = useState('');

  const getEmployeeFamilyDetails = useGetEmployeeFamilyInfo();
  const getFamilyEmergencyDetails = useGetEmployeeEmergencyInfo();
  const getEmployeeMedicalDetails = useGetEmployeeMedicalDetails();
  const deleteFamilyMemberMutation = useDeleteEmployeeFamilyMemberDetails();

  const blankData = () => {
    return (
      <span className='text-primary font-semibold text-base'>
        <MinusIcon />
      </span>
    );
  };

  return (
    <div className='shadow-lg border border-8B8B8B border-opacity-10 rounded-xl py-4 px-6 mb-8 bg-white'>
      <div className='bg-FFE8E1 bg-opacity-50 px-4 py-2 flex justify-between items-center mb-2'>
        <p className='text-primary font-medium text-xl '>Emergency contacts </p>
        <EditIcon
          className='cursor-pointer'
          onClick={() => setEditEmergencyModalOpen(true)}
        />
      </div>
      <div className='px-4 text-gray text-18'>
        <table className='w-full'>
          <tbody>
            <tr>
              <td className='py-2 w-36'>Primary contact name</td>
              <td className='px-4 w-16'>: </td>
              <td className='font-medium  w-9/12' colSpan={4}>
                {getFamilyEmergencyDetails?.data?.data?.length
                  ? getFamilyEmergencyDetails?.data?.data?.[0]?.primary_cname
                  : blankData()}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Relationship</td>
              <td className='px-4'>: </td>
              <td className='font-light  ' colSpan={4}>
                {getFamilyEmergencyDetails?.data?.data?.length
                  ? getFamilyEmergencyDetails?.data?.data?.[0]?.primary_rel
                  : blankData()}
              </td>
            </tr>
            <tr>
              <td className=' py-3'>Contact </td>
              <td className='px-4'>: </td>
              <td className='font-light ' colSpan={4}>
                {getFamilyEmergencyDetails?.data?.data?.length ? (
                  <p>
                    {
                      getFamilyEmergencyDetails?.data?.data?.[0]
                        ?.primary_contact
                    }
                    {getFamilyEmergencyDetails?.data?.data?.[0]
                      ?.primary_alternate &&
                      `, ${getFamilyEmergencyDetails?.data?.data?.[0]?.primary_alternate}`}
                  </p>
                ) : (
                  blankData()
                )}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Secondary contact name</td>
              <td className='px-4'>:</td>
              <td className='font-medium' colSpan={4}>
                {getFamilyEmergencyDetails?.data?.data?.length
                  ? getFamilyEmergencyDetails?.data?.data?.[0]?.secondary_cname
                  : blankData()}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Relationship</td>
              <td className='px-4'>: </td>
              <td className='font-light' colSpan={4}>
                {getFamilyEmergencyDetails?.data?.data?.length
                  ? getFamilyEmergencyDetails?.data?.data?.[0]?.secondary_rel
                  : blankData()}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Contact</td>
              <td className='px-4'>: </td>
              <td className='font-light' colSpan={4}>
                {getFamilyEmergencyDetails?.data?.data?.length ? (
                  <p>
                    {
                      getFamilyEmergencyDetails?.data?.data?.[0]
                        ?.secondary_contact
                    }
                    {getFamilyEmergencyDetails?.data?.data?.[0]
                      ?.secondary_alternate &&
                      `, ${getFamilyEmergencyDetails?.data?.data?.[0]?.secondary_alternate}`}
                  </p>
                ) : (
                  blankData()
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='bg-FFE8E1 bg-opacity-50 px-4 py-2 flex justify-between items-center mt-6 mb-2'>
        <p className='text-primary font-medium text-xl '>Family information</p>
        <AddIcon
          className='cursor-pointer p-1'
          onClick={() => setAddFamilyModalOpen(true)}
        />
      </div>
      <div className='px-4 text-gray text-18 pt-4'>
        {getEmployeeFamilyDetails?.data?.data?.length ? (
          <table className='w-full'>
            <tbody>
              <tr>
                <td>Name</td>
                <td>Relationship </td>
                <td className=''>Contact</td>
                <td>Action</td>
              </tr>
              {getEmployeeFamilyDetails?.data?.data?.map((item) => (
                <tr key={item?.id}>
                  <td className='py-5 font-light'>
                    <p>{item?.name}</p>
                  </td>
                  <td className=' py-5 font-light'>{item?.relationship}</td>
                  <td className=' py-5 font-light'>
                    <p>
                      {item?.mobile_number}
                      {item?.alternate_number && `, ${item?.alternate_number}`}
                    </p>
                  </td>
                  <td className=' py-5 font-light'>
                    <div className='flex items-center space-x-4'>
                      <EditIcon
                        className='cursor-pointer'
                        onClick={() => {
                          setSelectedFamilyMemberData(item);
                          setEditFamilyModalOpen(true);
                        }}
                      />
                      <RoundedCrossIcon
                        className='w-5 cursor-pointer'
                        onClick={() =>
                          deleteFamilyMemberMutation.mutate({ id: item?.id })
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className='flex flex-col items-center justify-center'>
            <UploadedIcon className='w-60 h-28' />
            <p className='text-gray3 pt-2'>No Data to show</p>
          </div>
        )}
      </div>
      <div className='bg-FFE8E1 bg-opacity-50 px-4 py-2 flex justify-between items-center mt-6 mb-2'>
        <p className='text-primary font-medium text-xl '>Medical details</p>
        <EditIcon
          className='cursor-pointer'
          onClick={() => setEditMedicalModalOpen(true)}
        />
      </div>
      <div className='px-4 text-gray text-18'>
        <table className='w-full'>
          <tbody className='flex flex-col'>
            <tr className='flex w-full'>
              <div className='flex w-1/2 mr-2'>
                <td className='pt-5 pb-3 w-44'>Blood group </td>
                <td className='px-4 pt-5 pb-3'>: </td>
                <td className='font-light pt-5 pb-3  flex-1'>
                  {getEmployeeMedicalDetails?.data?.data?.[0]?.blood_group ? (
                    <p>
                      {getEmployeeMedicalDetails?.data?.data?.[0]?.blood_group}
                    </p>
                  ) : (
                    'NIL'
                  )}
                </td>
              </div>
              <div className='flex w-1/2 ml-2'>
                <td className='pt-5 pb-3 w-44 whitespace-nowrap'>
                  Physical impairment
                </td>
                <td className='px-4 pt-5 pb-3'>: </td>
                <td className='font-light pt-5 pb-3  flex-1 break-all'>
                  {getEmployeeMedicalDetails?.data?.data?.[0]
                    ?.physical_impairment ? (
                    <span className='inline-block '>
                      {
                        getEmployeeMedicalDetails?.data?.data?.[0]
                          ?.physical_impairment_details
                      }
                    </span>
                  ) : (
                    'NIL'
                  )}
                </td>
              </div>
            </tr>
            <tr className='flex w-full'>
              <div className='flex w-1/2 mr-2'>
                <td className='pt-5 pb-3 w-44'>Critical medical issues</td>
                <td className='px-4 pt-5 pb-3'>: </td>
                <td className='font-light pt-5 pb-3  flex-1 break-all'>
                  {getEmployeeMedicalDetails?.data?.data?.[0]
                    ?.critical_medical_issues ? (
                    <p>
                      {
                        getEmployeeMedicalDetails?.data?.data?.[0]
                          ?.critical_medical_issues_details
                      }
                    </p>
                  ) : (
                    'NIL'
                  )}
                </td>
              </div>
              <div className='flex w-1/2 ml-2'>
                <td className='pt-5 pb-3 w-44 whitespace-nowrap'>Allergies</td>
                <td className='px-4 pt-5 pb-3'>: </td>
                <td className='font-light pt-5 pb-3  flex-1 break-all'>
                  {getEmployeeMedicalDetails?.data?.data?.[0]?.allergies ? (
                    <p>
                      {
                        getEmployeeMedicalDetails?.data?.data?.[0]
                          ?.allergies_details
                      }
                    </p>
                  ) : (
                    'NIL'
                  )}
                </td>
              </div>
            </tr>
            <tr className='flex w-full'>
              <div className='flex w-1/2 mr-2'>
                <td className='pt-5 pb-3 w-44'>Family doctor name</td>
                <td className='px-4 pt-5 pb-3'>: </td>
                <td className='font-light pt-5 pb-3 flex-1 break-all'>
                  {getEmployeeMedicalDetails?.data?.data?.[0]
                    ?.family_doctor_name ? (
                    <p>
                      {
                        getEmployeeMedicalDetails?.data?.data?.[0]
                          ?.family_doctor_name
                      }
                    </p>
                  ) : (
                    'NIL'
                  )}
                </td>
              </div>
              <div className='flex w-1/2 ml-2'>
                <td className='pt-5 pb-3 w-44 whitespace-nowrap'>
                  Family doctor contact
                </td>
                <td className='px-4 pt-5 pb-3'>: </td>
                <td className='font-light pt-5 pb-3  flex-1 break-all'>
                  {getEmployeeMedicalDetails?.data?.data?.[0]
                    ?.family_doctor_number ? (
                    <p>
                      {
                        getEmployeeMedicalDetails?.data?.data?.[0]
                          ?.family_doctor_number
                      }
                    </p>
                  ) : (
                    'NIL'
                  )}
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <EditEmergencyDetailsModal
        isModalOpen={isEditEmergencyDetailsModalOpen}
        setIsModalOpen={setEditEmergencyModalOpen}
        emergencyDetails={getFamilyEmergencyDetails?.data?.data?.[0]}
      />
      <EditMedicalDetailsModal
        isModalOpen={isEditMedicalDetailsModalOpen}
        setIsModalOpen={setEditMedicalModalOpen}
        medicalDetails={getEmployeeMedicalDetails?.data?.data?.[0]}
      />
      <AddEmployeeFamilyDetailsModal
        isModalOpen={isAddFamilyDetailsModalOpen}
        setIsModalOpen={setAddFamilyModalOpen}
      />
      <EditEmployeeFamilyDetailsModal
        isModalOpen={isEditFamilyDetailsModalOpen}
        setIsModalOpen={setEditFamilyModalOpen}
        familyData={selectedFamilyMemberData}
      />
    </div>
  );
}
