import { useMutation, useQueryClient } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { SuccessToast, ErrorToast } from 'services/toasterService';

import { useNavigate } from 'react-router-dom';

export default function UseUpdatepayroll(currentButton, cb) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(apiEndPoints.PAYROLL, data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        SuccessToast({ text: data.message });
        queryClient.invalidateQueries('organisation_payroll');
        queryClient.invalidateQueries('employee-payroll-data');
        queryClient.invalidateQueries('salary_component_data');
        currentButton && navigate('/app/team/my-team', { replace: true });

        cb();
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
