import React, { useEffect } from 'react';
import { Modal, TextInput } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';

import useUpdateEmployeeBankDetails from '../hooks/useUpdateEmployeeBankDetails';
import useAddEmployeeBankDetails from '../hooks/usePostEmployeeBankDetails';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';

export default function EditEmployeeBankDetailsModal({
  isModalOpen,
  setIsModalOpen,
  employeeId,
  employeeBankDetails,
}) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateEmployeeBankDetailsMutation =
    useUpdateEmployeeBankDetails(closeModal);
  const addEmployeeBankDetailsMutation = useAddEmployeeBankDetails(closeModal);

  //   custom title for modal
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>Edit details</p>
      </div>
    );
  };

  //   default values for form
  const defaultValues = {
    bank_name: '',
    account_holder_name: '',
    account_no: '',
    bank_location: '',
    ifsc_code: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    // watch,
    control,
    // getValues,
    reset,
  } = useForm({ defaultValues });

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  //   handle form submit
  const onSubmit = (data) => {
    if (employeeBankDetails?.length) {
      data['id'] = employeeBankDetails?.[0]?.id;
      updateEmployeeBankDetailsMutation.mutate(data);
    } else {
      data['employee_id'] = employeeId;
      addEmployeeBankDetailsMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (employeeBankDetails?.length) {
      reset({
        bank_name: employeeBankDetails?.[0]?.bank_name,
        account_holder_name: employeeBankDetails?.[0]?.account_holder_name,
        account_no: employeeBankDetails?.[0]?.account_no,
        bank_location: employeeBankDetails?.[0]?.bank_location,
        ifsc_code: employeeBankDetails?.[0]?.ifsc_code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeBankDetails]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-2',
        }}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-wrap'>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='bank_name'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Bank name*'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('bank_name')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='account_holder_name'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Account holder name*'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('account_holder_name')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='account_no'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Bank account no.*'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('account_no')}
              </div>
              <div className='w-1/2 py-2 px-4'>
                <Controller
                  control={control}
                  name='ifsc_code'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='IFSC code*'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('ifsc_code')}
              </div>
              <div className='w-full py-2 px-4'>
                <Controller
                  control={control}
                  name='bank_location'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Bank location*'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('bank_location')}
              </div>
              <div className='flex justify-center w-full mt-3'>
                <button className='w-28'>
                  <PrimaryButton
                    isLoading={updateEmployeeBankDetailsMutation?.isLoading}
                  >
                    Save
                  </PrimaryButton>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
