import { useContext, useState } from 'react';
import { Popover, Select } from '@mantine/core';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow2.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';
import dateFormatter from 'helper/dateFormatter';

import useGetHolidayTypes from '../hooks/useGetHolidayTypes';
import useEditCalendarEvent from '../hooks/useEditCalendarEvent';
import { AuthContext } from 'context/authContext';

export default function EventComponent({ event: calendarEvent, ...props }) {
  const [eventPopover, setEventPopover] = useState(false);

  const {
    authState: { is_admin, user_id },
  } = useContext(AuthContext);

  // Holiday Types
  const holidayTypes = useGetHolidayTypes();

  // CALENDAR Events
  const editCalendarEventMutation = useEditCalendarEvent();

  const formatTime = (time) => {
    if (time) {
      let hours = `${time[0]}${time[1]}`;
      const minutes = `${time[3]}${time[4]}`;
      const addAMorPM = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours
        ? // if hours are not equal to 0
          hours < 10
          ? '0' + hours
          : hours
        : // if hours are equal to 0, then we are assigning 12
          12;
      let strTime = hours + ':' + minutes + ' ' + addAMorPM;
      return strTime;
    }
  };
  return (
    <div
      className={`rounded mx-2 cursor-pointer p-1 pl-2 ${
        calendarEvent?.isBirthday
          ? 'text-[#585757] bg-[#E8E8E8]'
          : calendarEvent?.isHoliday
          ? 'text-[#00BA34] bg-[#00BA341A]'
          : 'text-[#FF9F2D] bg-[#FF961B1A]'
      } relative`}
      style={{
        ...props?.children?.props?.style,
      }}
      onClick={() => setEventPopover(true)}
    >
      <Popover
        opened={eventPopover}
        onClose={() => setEventPopover(false)}
        target={
          <p
            className='text-sm font-semibold truncate w-36'
            onClick={() => setEventPopover(true)}
          >
            {calendarEvent?.isBirthday
              ? `${calendarEvent?.title}'s birthday`
              : calendarEvent?.title}
            <span className='text-sm ml-4'>{props?.label}</span>
          </p>
        }
        placement='center'
        position='right'
        width={320}
        radius='md'
        shadow={'lg'}
        gutter={10}
        withArrow
        arrowSize={8}
        zIndex={20}
      >
        <div key={calendarEvent?.id}>
          <div className='flex items-center justify-between mb-1'>
            <p className='w-3/4 font-semibold text-20 break-words'>
              {calendarEvent?.isBirthday
                ? `${calendarEvent?.title}'s birthday`
                : calendarEvent?.title}
            </p>

            {!calendarEvent?.isBirthday &&
              (is_admin || user_id === calendarEvent?.createdBy) && (
                <div className='flex items-center space-x-3'>
                  <EditIcon
                    className='cursor-pointer'
                    onClick={() => {
                      props?.setIsCalenderEventModalOpen(true);
                      props?.setSelectedEventId(calendarEvent?.id);
                    }}
                  />
                  <DeleteIcon
                    className='cursor-pointer'
                    onClick={() => {
                      props?.setOpenDeleteEventModal(true);
                      props?.setSelectEventToDelete(calendarEvent?.id);
                    }}
                  />
                </div>
              )}
          </div>
          <div className='flex items-center justify-between space-x-4 text-[#979797] text-12'>
            <p>
              {dateFormatter(calendarEvent?.start, 'DD MMM YY') ===
              dateFormatter(calendarEvent?.end, 'DD MMM YY') ? (
                dateFormatter(calendarEvent?.start, 'DD MMM YY')
              ) : (
                <>
                  <span>
                    {dateFormatter(calendarEvent?.start, 'DD MMM YY')}
                  </span>
                  <span>
                    {' '}
                    - {dateFormatter(calendarEvent?.end, 'DD MMM YY')}
                  </span>
                </>
              )}
            </p>
            <div>
              {/* {calendarEvent?.isHoliday && <p>Holiday</p>} */}{' '}
              {calendarEvent?.isHoliday ? (
                <p>Holiday</p>
              ) : calendarEvent?.allDay ? (
                <p>All day event</p>
              ) : null}
              {!calendarEvent?.isHoliday && !calendarEvent?.allDay && (
                <p>
                  {formatTime(calendarEvent?.from_time)} -{' '}
                  {formatTime(calendarEvent?.to_time)}
                </p>
              )}
            </div>
          </div>
          {calendarEvent?.isHoliday && (
            <Select
              defaultValue={`${calendarEvent?.holidayType}`}
              readOnly={true}
              data={holidayTypes?.data?.data
                ?.filter((holiday) => holiday?.is_active)
                .map((holiday) => {
                  return {
                    value: `${holiday?.id}`,
                    label: holiday?.name,
                  };
                })}
              onChange={(event) =>
                editCalendarEventMutation.mutate({
                  id: calendarEvent?.id,
                  holiday_type_id: +event,
                })
              }
              disabled
              rightSection={<DownArrowIcon />}
              className='mt-6'
              classNames={{
                input: 'border-0 text-[#979797] bg-[#FFE2D8]',
                label: 'text-neutralsGrey',
              }}
              styles={{
                rightSection: {
                  pointerEvents: 'none',
                },
              }}
            />
          )}
        </div>
      </Popover>
    </div>
  );
}
