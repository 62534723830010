import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetReportiesList(id, isModalOpen) {
  return useQuery(
    ['get-employees-list', isModalOpen],
    async () => {
      const res = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.REPORTERS_AVAILABLE, { id: id })
      );
      return res.data;
    },
    { enabled: isModalOpen }
  );
}
