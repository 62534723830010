import React from 'react';
import { Modal } from '@mantine/core';

import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';

import useUpdateEmployee from '../hooks/useUpdateEmployee';

import { ReactComponent as ActivateUserIlls } from 'assets/images/activateUserIlls.svg';
import { ReactComponent as DeactiveUserIlls } from 'assets/images/deactiveUserIlls.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/crossIcon.svg';

export default function ActivateOrDeactivateEmployeeModal({
  isDeactiveModalOpen,
  setDeactiveModalOpen,
  isUserActive,
  id,
}) {
  const closeModal = () => {
    setDeactiveModalOpen(false);
  };

  const updateEmployeeMutation = useUpdateEmployee(closeModal);

  const handleUpdateEmployee = () => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('is_active', !isUserActive ? 'True' : 'False');

    updateEmployeeMutation.mutate(formData);
  };

  return (
    <Modal
      opened={isDeactiveModalOpen}
      onClose={() => setDeactiveModalOpen(false)}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size='lg'
      radius='md'
      overflow='inside'
      transitionDuration={100}
      classNames={{
        title: 'w-full text-primary font-semibold text-18',
        modal: 'pl-10',
        body: 'scroller -mr-4 pr-4',
      }}
    >
      <div>
        <CrossIcon
          className='ml-auto w-10 cursor-pointer'
          onClick={() => closeModal()}
        />
      </div>
      <p className='text-neutralsBlack text-xl text-center'>
        Are you sure you want to{' '}
        <span className='font-semibold'>
          {isUserActive ? 'deactivate ' : 'activate '}
        </span>
        the employee?
      </p>
      {/* {isUserActive ? (
        <p className='text-sm text-gray3 text-center mt-4'>
          <span className='font-bold'>Note: </span> The employee will be removed
          from all the projects.
        </p>
      ) : null} */}
      {isUserActive ? (
        <DeactiveUserIlls className='w-40 mx-auto' />
      ) : (
        <ActivateUserIlls className='w-40 mx-auto' />
      )}
      <div className='flex space-x-4 justify-center my-4'>
        <button
          className='w-28 text-center'
          onClick={() => handleUpdateEmployee()}
        >
          <SecondaryButton isLoading={updateEmployeeMutation?.isLoading}>
            Yes
          </SecondaryButton>
        </button>
        <button className='w-28' onClick={() => closeModal()}>
          <PrimaryButton>No</PrimaryButton>
        </button>
      </div>
    </Modal>
  );
}
