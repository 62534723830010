// import useGetAllDepartments from 'pages/organisationSettings/hooks/useGetAllDepartments';
import React, { useState } from 'react';
import useGetDesignations from '../hooks/useGetDesignations';
import AddDesignationModal from './addDesignationModal';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';

export default function Employeedesignations({ organisation }) {
  const [isAddDesignationModalOpen, setDesignationModalOpen] = useState(false);
  const [isEditData, setEditData] = useState(null);
  

  const getEmployeeDesigantions = useGetDesignations(organisation);
  // const getAllDepartments = useGetAllDepartments();
  // const getDepartmentData = (id) => {
  //     let name = '';
  //     getAllDepartments?.data?.data?.map((item) => {
  //       if (item?.id === id) {
  //         name = item?.name;
  //       }
  //       return true;
  //     });
  //     return name;
  //   };

  return (
    <div className='shadow-xl border border-8B8B8B border-opacity-20 rounded-xl overflow-hidden mb-8 bg-white min-h-[70vh] departments-super-admin'>
      {' '}
      {/* employment designations */}
      <table className='w-full '>
        <thead className='bg-FFE8E1 '>
          <tr className='text-333333  rounded-t-lg px-6 flex'>
            <th className='py-5 w-1/4  text-left '>Department</th>
            <th className='py-5 w-7/12  text-left'>Designation</th>
            <th className='py-5 w-1/6 '>Action</th>
          </tr>
        </thead>
      </table>
      <table className='w-full departments-two'>
        <tbody>
          {getEmployeeDesigantions?.data?.data?.map(
            (desigantions, index, array) => {
              return (
                <tr
                  key={desigantions?.id}
                  className={`flex px-6 text-868686 ${
                    array.length !== index + 1
                      ? 'border-b border-[#CECECE] border-opacity-50'
                      : ''
                  }`}
                >
                  <td className='py-5 w-1/4'>
                    {desigantions?.department__name}
                  </td>
                  <td className='py-5 w-7/12'>{desigantions?.name}</td>
                  <td className='py-5 w-1/6 text-center'>
                    <div className='flex items-center justify-center space-x-4'>
                      <EditIcon
                        onClick={() => {
                          setEditData(desigantions);
                          setDesignationModalOpen(true);
                        }}
                        className='cursor-pointer'
                      />
                    </div>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
      {/* {getEmployeeDesigantions?.data?.data?.map((item) => {
        return (
          <div className='flex justify-between my-6 px-4' key={item?.id}>
            <div className='flex items-center w-8/12'>
              <p className='text-gray w-2/6'>{item?.department__name}</p>
              <p className='text-gray w-2/6'>{item?.name}</p>
            </div>
            <div className='flex items-center mr-4'>
              <EditIcon
                onClick={() => {
                  setEditData(item);
                  setDesignationModalOpen(true);
                }}
                className='cursor-pointer'
              />
            </div>
          </div>
        );
      })} */}
      <AddDesignationModal
        isModalOpen={isAddDesignationModalOpen}
        setIsModalOpen={setDesignationModalOpen}
        organisation={organisation}
        editData={isEditData}
        setEditData={setEditData}
      />
    </div>
  );
}
