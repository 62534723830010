import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetgradeorrange() {
  return useQuery('range-or-grade', async () => {
    const response = await makeApiRequest.get(apiEndPoints.COMPANY_DETAILS);

    return response.data;
  });
}
