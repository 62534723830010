import { useState, memo } from 'react';
import Countdown from 'react-countdown';
import UseResendOtp from '../hooks/useresendotp';
import { useSearchParams } from 'react-router-dom';

const OtpTimer = ({ reset }) => {
  const [initailValue, setValue] = useState(0);
  const [searchParams] = useSearchParams();
  const resendOtpMutation = UseResendOtp();

  const resendOtpClick = () => {
    setValue(initailValue + 1);
    resendOtpMutation.mutate({
      email_or_number: searchParams.get('email'),
      resend: true,
    });
    reset();
  };
  const settingTimer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <p
          className=' text-[#4F4F4F] cursor-pointer text-center text-sm font-semibold'
          onClick={resendOtpClick}
        >
          Resend code
        </p>
      );
    }
    return (
      <p className=' text-[#4F4F4F] cursor-pointer text-center text-sm'>
        Didn’t receive code?
        <span className='cursor-pointer text-primary font-normal text-sm'>
          {' '}
          0{minutes}:{seconds.toString().length < 2 ? 0 : ''}
          {seconds}
        </span>
      </p>
    );
  };

  return (
    <Countdown
      date={Date.now() + 180000}
      renderer={settingTimer}
      key={initailValue}
    />
  );
};

export default memo(OtpTimer);
