import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetCompanyDetails() {
  return useQuery('get-company-details', async () => {
    const response = await makeApiRequest.get(apiEndPoints.COMPANY_DETAILS);

    return response.data;
  });
}
