import {
  Avatar,
  Input,
  Loader,
  Popover,
  Radio,
  RadioGroup,
  // Select,
  Table,
  Tabs,
  TextInput,
} from '@mantine/core';
import { ReactComponent as BackIcon } from 'assets/icons/backArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import noTeamMemberImg from 'assets/images/noTeamMemberImg.png';

import { useParams } from 'react-router-dom';

import Usegetlistofemployessinpolicy from '../hooks/Usegetlistofemployessinpolicy';
import useGetAllGrades from '../../../../team/pages/myTeam/hooks/useGetAllGrades';
import useGetDepartmentsForEmployees from '../../../../team/pages/myTeam/hooks/useGetDepartmentsForEmployee';

import { useState } from 'react';
import dayjs from 'dayjs';

import { useNavigate } from 'react-router-dom';

const SpecificPolicy = () => {
  const [searchInput, setSearchInput] = useState(null);
  const [byStateFilter, setByStateFilter] = useState(null);
  const [byGradeFilter, setByGradeFilter] = useState(null);
  const [byDepartmentFilter, setByDepartmentFilter] = useState(null);
  const [showAll, setShowAll] = useState(null);
  const { id } = useParams();
  const getAllGrades = useGetAllGrades();
  const getDepartmentsForEmployee = useGetDepartmentsForEmployees();

  const listOfEmployess = Usegetlistofemployessinpolicy(
    id,
    searchInput,
    byStateFilter,
    byGradeFilter,
    byDepartmentFilter
  );

  const [filterPopup, setFilterPopup] = useState(false);
  const navigate = useNavigate();

  const rows = listOfEmployess?.data?.data?.emp_data.map((eachemp) => (
    <tr key={eachemp?.id}>
      <td className='text-[#8b8b8b]'>
        <div className='flex items-center justify-start ml-8 w-56 gap-3'>
          <Avatar
            radius='xl'
            src={eachemp.employee__profile_picture}
            color='red'
          >
            {eachemp.employee__user__first_name[0]?.toUpperCase()}
          </Avatar>
          {eachemp.employee__user__first_name}
        </div>
      </td>
      <td className='text-center text-[#8b8b8b]'>
        <div className='flex flex-col items-center '>
          <div>{eachemp.employee__employee_id}</div>

          {eachemp.employee__user__is_active ? (
            <span className='text-[12px] text-[#28B446]  bg-[#c1f3cc] py-1 px-2 rounded-lg '>
              Active
            </span>
          ) : (
            <span className=' text-[12px] text-[#D0342C] bg-[#f7bab6] py-1 px-2 rounded-lg'>
              Inactive
            </span>
          )}
        </div>
      </td>
      <td className='text-center text-primary'>
        {' '}
        {eachemp.employee__department__name}
      </td>
      <td className='text-center text-primary'>
        {' '}
        {eachemp.employee__grade__name}
      </td>
      <td className='text-center'>
        {' '}
        {eachemp.is_signed ? (
          <p className='text-[#28B446]'>Acknowledged</p>
        ) : (
          <p className='text-[#FBBB00]'>Pending</p>
        )}
      </td>
      <td className='text-center text-[#8b8b8b]'>
        {eachemp.is_signed ? (
          `${dayjs(eachemp.updated_date).format('DD MMM YYYY')} - ${dayjs(
            eachemp.updated_date
          ).format('H:m:s')}`
        ) : (
          <p className='-mt-2 font-bold text-xm'>____</p>
        )}
      </td>
    </tr>
  ));

  return (
    <div className='px-5 '>
      <div className='flex justify-between items-center mt-6'>
        <p className='text-xl text-A8A8A8'>
          Organisation &#60;{' '}
          <span className='text-gray font-semibold'>Key policies</span>
        </p>
        {
          // <p
          //   className='text-[#67A1EF] cursor-pointer '
          //   onClick={() =>
          //     navigate('/app/organisation/key-policies', { replace: true })
          //   }
          // >
          //   Add Custom Key Policy
          // </p>
        }
      </div>
      <div className='mt-5 bg-[#fff] h-[87vh] shadow-2xl rounded-2xl  border border-gray border-opacity-10'>
        <div className='p-5'>
          <p className='gap-4 flex  items-center text-xl font-semibold '>
            <BackIcon
              className='h-5 w-5 cursor-pointer'
              onClick={() =>
                navigate('/app/organisation/key-policies', { replace: true })
              }
            />{' '}
            Policies
          </p>
          <div className='px-8 mt-4 flex justify-between items-center'>
            <div className=' flex flex-col'>
              <h1 className='text-primary font-medium text-xl'>
                {listOfEmployess && listOfEmployess?.data?.data?.name}
              </h1>
              <p className='text-[#828282] text-sm'>
                Created on{' '}
                {dayjs(listOfEmployess?.data?.data?.created_date).format(
                  'DD MMM YYYY'
                )}
              </p>
            </div>
            <div className='gap-5 flex items-center'>
              <h1 className='text-primary font-semibold text-2xl mr-8 -mb-4'>
                {listOfEmployess?.data?.data?.signed_count}/
                <span className='opacity-40'>
                  {listOfEmployess?.data?.data?.total_count}
                </span>
              </h1>
              <Input
                icon={<SearchIcon />}
                placeholder='Search'
                value={searchInput}
                onChange={(event) => setSearchInput(event.target.value)}
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'border-none',
                }}
                rightSection={
                  <Loader
                    size='xs'
                    className={`${
                      searchInput && listOfEmployess.isLoading
                        ? 'visible'
                        : 'invisible'
                    }`}
                  />
                }
              />

              <Popover
                opened={filterPopup}
                onClose={() => setFilterPopup(false)}
                classNames={{ inner: 'p-0' }}
                target={
                  <div onClick={() => setFilterPopup(!filterPopup)}>
                    <TextInput
                      icon={<FilterIcon />}
                      // rightSection={<CalanderIcon />}
                      classNames={{
                        wrapper: 'border-primary border-b',
                        input: ' border-none',
                      }}
                      readOnly
                      rightSection={<DownArrow />}
                      defaultValue={'All'}
                      value={
                        byStateFilter === 'True'
                          ? 'Acknowledged'
                          : byStateFilter === 'False'
                          ? 'Pending'
                          : byGradeFilter || byDepartmentFilter || showAll
                      }
                      styles={{
                        rightSection: { pointerEvents: 'none' },
                        monthPickerControlActive: { color: 'red' },
                        yearPickerControlActive: {
                          color: 'red',
                          backgroundcolor: '#ffffff',
                        },
                      }}
                    />
                  </div>
                }
                width={320}
                position='bottom'
              >
                <div>
                  <Tabs
                    orientation='vertical'
                    // variant='pills'
                    className='p-0 rounded-md min-h-[250px] w-[320px]'
                    classNames={{ tabsListWrapper: 'bg-[#FFF2ED] ' }}
                  >
                    <Tabs.Tab label='Show All'>
                      <div className='py-4 '>
                        <RadioGroup orientation='vertical'>
                          <Radio
                            label='All'
                            value='True'
                            onClick={() => {
                              setByStateFilter(null);
                              setByGradeFilter(null);
                              setByDepartmentFilter(null);
                              setShowAll('All');
                            }}
                          />
                        </RadioGroup>
                      </div>
                    </Tabs.Tab>
                    <Tabs.Tab label='By Status'>
                      <div className='py-4 '>
                        <RadioGroup orientation='vertical'>
                          <Radio
                            label='Acknowledged'
                            value='True'
                            onClick={() => {
                              setByStateFilter('True');
                              setByGradeFilter(null);
                              setByDepartmentFilter(null);
                              setShowAll(null);
                            }}
                          />
                          <Radio
                            label='Pending'
                            value='False'
                            onClick={() => {
                              setByStateFilter('False');
                              setByGradeFilter(null);
                              setByDepartmentFilter(null);
                              setShowAll(null);
                            }}
                          />
                        </RadioGroup>
                      </div>
                    </Tabs.Tab>
                    <Tabs.Tab label='By Grade'>
                      <div className='py-4 '>
                        <RadioGroup orientation='vertical'>
                          {getAllGrades?.data?.data?.map((eachgrade) => {
                            return (
                              <Radio
                                label={`${eachgrade.name}`}
                                value={`${eachgrade.name}`}
                                key={eachgrade.id}
                                onClick={() => {
                                  setByStateFilter(null);
                                  setByGradeFilter(`${eachgrade.name}`);
                                  setByDepartmentFilter(null);
                                  setShowAll(null);
                                }}
                              />
                            );
                          })}
                        </RadioGroup>
                      </div>
                    </Tabs.Tab>
                    <Tabs.Tab label='By Department'>
                      <div className='py-4 '>
                        <RadioGroup orientation='vertical'>
                          {getDepartmentsForEmployee?.data?.data?.map(
                            (eachgrade) => {
                              return (
                                <Radio
                                  label={`${eachgrade.name}`}
                                  value={`${eachgrade.name}`}
                                  key={eachgrade.id}
                                  onClick={() => {
                                    setByStateFilter(null);
                                    setByGradeFilter(null);
                                    setByDepartmentFilter(`${eachgrade.name}`);
                                  }}
                                />
                              );
                            }
                          )}
                        </RadioGroup>
                      </div>
                    </Tabs.Tab>
                  </Tabs>
                </div>
              </Popover>

              {/* <div className='underline-offset-8 w-36'>
                <FilterIcon />
              </div> */}
            </div>
          </div>
        </div>
        <div className='max-h-[75%] 2xl:max-h-[85%] overflow-y-scroll pl-2 w-[100%] scroller'>
          <Table verticalSpacing='lg' highlightOnHover>
            <thead className='sticky top-0 z-20 right-0'>
              <tr>
                <th
                  className='bg-[#FFE8E1] w-56'
                  style={{ textAlign: 'center' }}
                >
                  Name
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Emp. ID
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Dept.
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Grade
                </th>
                <th className='bg-[#FFE8E1] ' style={{ textAlign: 'center' }}>
                  Status
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Date & Time
                </th>
              </tr>
            </thead>

            <tbody className=' w-full border-none'>{rows}</tbody>
          </Table>
          {listOfEmployess?.data?.data?.emp_data.length === 0 ? (
            <div className='flex justify-center items-center min-h-[55vh] flex-col'>
              <img src={noTeamMemberImg} alt='No Employess' />
              <p className='mt-4 text-[#8b8b8b]'>No Employess to show</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SpecificPolicy;
