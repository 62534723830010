import forgotPasswordImage from '../../../assets/images/forgotPasswordImage.png';
import { ReactComponent as AptaSmallIcon } from '../../../assets/icons/aptaSmallIcon.svg';
import successBackgroundImage from '../../../assets/images/successBackgroundImage.png';

import Lottie from 'react-lottie';
import successAnimate from 'lotties/sucesslock';

import { useNavigate } from 'react-router-dom';

const PasswordSuccess = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimate,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundImage: 'URL(' + forgotPasswordImage + ')',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat, repeat',
        backgroundPosition: '90% 90%',
      }}
      className='h-screen bg-center  bg-no-repeat w-[100%] bg-cover'
    >
      <div className='bg-[#FFF] w-full py-[20px] px-[50px] flex justify-between items-center shadow-lg'>
        <AptaSmallIcon className='w-24 h-12' />
        <button
          className='h-10 w-32  bg-[#FB7C51] rounded-lg text-[#FFFFFF] font-normal text-base  hover:bg-[#e2643a] hover:shadow-lg'
          onClick={() => {
            navigate('/auth/signup', { replace: true });
          }}
        >
          Sign up
        </button>
      </div>
      <div className='flex flex-col justify-center items-center h-[85%]'>
        <div className='bg-[#fff] p-8 rounded-lg shadow-2xl w-[40%] flex justify-center flex-col items-center'>
          <h1 className='text-primary font-semibold text-xl mb-4'>
            Password changed successfully!
          </h1>
          <div
            style={{
              backgroundImage: 'URL(' + successBackgroundImage + ')',
              backgroundSize: '100% 100%',
            }}
            className='bg-no-repeat w-[100%] bg-cover'
          >
            <Lottie
              options={defaultOptions}
              height={250}
              width={250}
              speed={1000}
            />
          </div>
          <button
            className='h-10 w-32 mx-auto bg-[#FB7C51] rounded-lg mt-14 text-[#FFFFFF] font-normal text-lg  hover:bg-[#e2643a] hover:shadow-lg'
            onClick={() => {
              navigate('/auth/login', { replace: true });
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordSuccess;
