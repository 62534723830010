import React, { useContext, useState } from 'react';
import { Modal, TextInput } from '@mantine/core';
import { ReactComponent as CloseIcon } from 'assets/icons/crossIcon.svg';
import PrimaryButton from 'components/primaryButton';
import { Controller, useForm } from 'react-hook-form';
import { AuthContext } from 'context/authContext';

import useAddMySkill from '../hooks/useAddMySkill';

export default function NewSKillModal({
  newSkillModalOpened,
  setNewSkillModalOpened,
  selectEventToDelete,
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const closeModal = () => {
    reset({ name: '' });
    setNewSkillModalOpened(false);
    setSkillLevel(null);
  };

  const { authState } = useContext(AuthContext);

  const [skillLevel, setSkillLevel] = useState(null);

  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const AddSkillMutation = useAddMySkill(closeModal);

  const AddNewSkill = (data) => {
    AddSkillMutation.mutate({
      ...data,
      employee_id: authState?.employee_id,
      difficulty: skillLevel,
    });
  };

  return (
    <Modal
      opened={newSkillModalOpened}
      onClose={closeModal}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      overflow='inside'
      centered
      size={500}
      radius='md'
      transitionDuration={100}
      withCloseButton={false}
      classNames={{
        modal: 'p-0',
        body: 'mt-10 mb-14',
      }}
    >
      <div className='space-y-4 relative'>
        <p className='text-xl leading-7 font-semibold text-[#000] text-center'>
          Add a new skill
        </p>

        <div className='px-12'>
          <form onSubmit={handleSubmit(AddNewSkill)}>
            <div>
              <Controller
                name='name'
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => (
                  <TextInput
                    label='Skill'
                    {...field}
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-[25px] pl-2',
                    }}
                  />
                )}
              />
              {getErrorMessage('name')}
            </div>

            <div className='flex items-center gap-4 justify-between my-5'>
              <p
                className={
                  skillLevel === 'Advanced'
                    ? 'text-[#fff] bg-primary py-2 px-6 rounded-lg cursor-pointer'
                    : 'text-[#fff] bg-primary py-2 px-6 rounded-lg opacity-60 cursor-pointer'
                }
                onClick={() => setSkillLevel('Advanced')}
              >
                Advanced
              </p>
              <p
                className={
                  skillLevel === 'Mediocre'
                    ? 'text-[#fff] bg-primary py-2 px-6 rounded-lg cursor-pointer'
                    : 'text-[#fff] bg-primary py-2 px-6 rounded-lg opacity-60 cursor-pointer'
                }
                onClick={() => setSkillLevel('Mediocre')}
              >
                Mediocre
              </p>
              <p
                className={
                  skillLevel === 'Beginner'
                    ? 'text-[#fff] bg-primary py-2 px-6 rounded-lg cursor-pointer'
                    : 'text-[#fff] bg-primary py-2 px-6 rounded-lg opacity-60 cursor-pointer'
                }
                onClick={() => setSkillLevel('Beginner')}
              >
                Beginner
              </p>
            </div>

            <div className='flex justify-center items-center mt-12'>
              {skillLevel === null ? (
                <button type='button' className='opacity-50'>
                  <PrimaryButton>Save</PrimaryButton>
                </button>
              ) : (
                <button>
                  <PrimaryButton>Save</PrimaryButton>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      <CloseIcon
        className='w-12 h-12 absolute top-3 right-3 cursor-pointer'
        onClick={closeModal}
      />
    </Modal>
  );
}
