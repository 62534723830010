import { Input, Loader } from '@mantine/core';
import Header from 'components/Header';

import PolicyCardComponent from './components/policyCardComponent';

import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import { ReactComponent as Nopolicies } from 'assets/icons/nopolicies.svg';

import PrimaryButton from 'components/primaryButton';
import { Fragment, useState } from 'react';

import useGetpolicies from './hooks/usegetpolicyies';
import PoliciesModal from './components/newpoliciesmodal';

const KeyPolicies = () => {
  const [searchInput, setSearchInput] = useState('');
  const [isPoliciesModalOpened, setIsPoliciesModalOpened] = useState(false);
  const [policyId, setPolicyId] = useState(null);

  const getPoliciesData = useGetpolicies(searchInput);

  return (
    <>
      <div className='px-5'>
        <div className='flex justify-between items-center mt-6'>
          <p className='text-xl text-A8A8A8'>
            Organisation &#60;{' '}
            <span className='text-gray font-semibold'>Key policies</span>
          </p>
          <Header />
        </div>
        <div className='mt-7 flex justify-between items-center mx-4'>
          <h1 className='text-xl font-semibold'>Key Policies</h1>
          <div className='flex items-center gap-4'>
            <Input
              icon={<SearchIcon />}
              placeholder='Search'
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
              classNames={{
                wrapper: 'border-primary border-b',
                input: 'border-none bg-light-bg',
              }}
              rightSection={
                <Loader
                  size='xs'
                  className={`${
                    searchInput && getPoliciesData.isLoading
                      ? 'visible'
                      : 'invisible'
                  }`}
                />
              }
            />
            {getPoliciesData?.data?.data?.length === 0 ? null : (
              <button
                onClick={() => {
                  setIsPoliciesModalOpened(true);
                  setPolicyId(null);
                }}
              >
                <PrimaryButton>+Add Key Policies</PrimaryButton>
              </button>
            )}
          </div>
        </div>
        {getPoliciesData?.data?.data?.length === 0 ? (
          <div className='mt-8 bg-[#fff] h-[73vh] shadow-xl rounded-3xl flex flex-col justify-center items-center'>
            <Nopolicies />
            <p className='mt-2 text-[#535353]'>
              Seems like you didn’t upload any policy document yet
            </p>
            <button
              className='mt-4'
              onClick={() => {
                setIsPoliciesModalOpened(true);
                setPolicyId(null);
              }}
            >
              <PrimaryButton>+Add Key Policies</PrimaryButton>
            </button>
          </div>
        ) : (
          <div className='flex flex-cols-4 flex-wrap pt-6'>
            {getPoliciesData?.data?.pages?.map((pages, index) => (
              <Fragment key={index}>
                {pages?.data?.map((eachpolicy) => {
                  return (
                    <div key={eachpolicy.id} className='w-1/4 p-3'>
                      <PolicyCardComponent eachpolicy={eachpolicy} />
                    </div>
                  );
                })}
              </Fragment>
            ))}
          </div>
        )}

        {/* {getPoliciesData?.hasNextPage ? (
          <div className='flex justify-center my-4'>
            <button
              className='bg-[#D6D6D6] w-32 text-white py-3 text-sm rounded-full'
              onClick={() => getPoliciesData.fetchNextPage()}
            >
              {getPoliciesData?.isFetching ? (
                <Loader color='#ffffff' size={'sm'} className='mx-auto' />
              ) : (
                'View more'
              )}
            </button>
          </div>
        ) : null} */}

        <PoliciesModal
          setIsPoliciesModalOpened={setIsPoliciesModalOpened}
          isPoliciesModalOpened={isPoliciesModalOpened}
          policyId={policyId}
          setPolicyId={setPolicyId}
        />
      </div>
    </>
  );
};

export default KeyPolicies;
