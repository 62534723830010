import { useState } from 'react';
import { Modal } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NopageIlls } from 'assets/images/image_maintanace.svg';

const NoPageFound = () => {
  const [isModalOpen, setModalOpen] = useState(true);

  const navigate = useNavigate();
  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setModalOpen(true)}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      centered
      overlayOpacity={0.5}
      transitionDuration={100}
      size='90%'
      classNames={{
        title: 'text-lg font-semibold',
        body: 'scroller',
      }}
    >
      <div>
        <div className='bg-white shadow-lg mx-auto w-10/12 py-4 border border-gray rounded'>
          <div className='w-1/2 mx-auto'>
            <NopageIlls className='h-[40vh]' />
          </div>
          <p className='text-3xl text-center my-3 mt-10'>Oh no!</p>
          <p className='text-lg text-center'>
            Sorry we could not find that page.
          </p>
          <div className='flex justify-center my-6'>
            <button className='mx-auto w-32' onClick={() => navigate(-1)}>
              <PrimaryButton>back</PrimaryButton>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NoPageFound;
