import { Route, Routes } from 'react-router-dom';

// import CalenderLeaves from './pages/leaves/leaves';
import NoPageFound from 'pages/common/pageNotFound';

import LeaveCalender from 'pages/settings/pages/leaves/components/leaveCalender';

const Calender = () => {
  return (
    <Routes>
      {/* <Route path='/leaves' element={<CalenderLeaves />} /> */}
      <Route path='/leavecalender' element={<LeaveCalender/>} />

      {/* empty page when route not found */}
      <Route path='*' element={<NoPageFound />} />
    </Routes>
  );
};

export default Calender;
