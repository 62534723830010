import MySkills from './components/mySkills';
import SkillDevelopment from './components/skillDevelopment';
import SkillTarget from './components/skillTarget';

const EmployeeSkillMatrix = () => {
  return (
    <div className='grid grid-cols-2 gap-4'>
      <div className='h-[37vh] shadow-three rounded-3xl p-5'>
        <MySkills />
      </div>
      <div className='h-[37vh] shadow-three rounded-3xl p-5'>
        <SkillTarget />
      </div>
      <div className='col-span-2 h-fit mb-4 shadow-three rounded-3xl p-5'>
        <SkillDevelopment />
      </div>
    </div>
  );
};
export default EmployeeSkillMatrix;
