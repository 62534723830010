import React, { useContext, useEffect, useState } from "react";
import { Avatar, Popover, Indicator } from "@mantine/core";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { AuthContext } from "context/authContext";
// import { useNavigate } from 'react-router-dom';
import favNot from "favicon-notification";

import StatusLabel from "pages/chat/hooks/statusLabel";

import { ReactComponent as AvailableIcon } from "assets/icons/AvailableIcon.svg";
import { ReactComponent as AwayIcon } from "assets/icons/AwayIcon.svg";
import { ReactComponent as BeRightBackIcon } from "assets/icons/BeRightBackIcon.svg";
import { ReactComponent as BusyIcon } from "assets/icons/BusyIcon.svg";
import { ReactComponent as DNDIcon } from "assets/icons/DNDIcon.svg";
import { ReactComponent as OfflineIcon } from "assets/icons/OfflineIcon.svg";

import ChangePasswordModal from "./changePassword/changePasswordModal";

import { ChatIcon, MessageIcon } from "./svgIcons";
import { ReactComponent as NotificationsIcon } from "../assets/icons/notificationsIcon.svg";
// import { ReactComponent as MessagesIcon } from '../assets/icons/messagesIcon.svg';
import { ReactComponent as DownArrowIcon } from "../assets/icons/orangeDownArrowIcon.svg";
import { ReactComponent as PersonIcon } from "../assets/icons/personIcon.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settingsIcon.svg";
// import { ReactComponent as DollarIcon } from '../assets/icons/dollarIcon.svg';
import { ReactComponent as LockIcon } from "../assets/icons/lockIcon.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logoutIcon.svg";
import { ReactComponent as OrgSettingsIcon } from "../assets/icons/orgSettings.svg";
import LogoutModal from "./logoutButton";
import { ChatContext } from "context/chatContext";
import useGetInboxMails from "pages/mail/hooks/useGetInboxMails";
import useGetUnreadMessages from "pages/chat/hooks/useGetUnReadMessages";
import dayjs from "dayjs";
// import Notifier from 'react-desktop-notification';
// import Push from 'push.js';

export default function Header({
  setInboxMails,
  date,
  mailFilter,
  setIsLoading,
  unreadmsgscount,
  settotalMails,
}) {
  let is_read;
  if (mailFilter === "read") {
    is_read = "True";
  } else if (mailFilter === "unread") {
    is_read = "False";
  } else {
    is_read = null;
  }
  // let getMails = useGetInboxMails(dayjs(date).format("MM-YYYY"), is_read);
  let getMails = null;

  let unread = getMails?.data?.unread_mails || 0;
  let totalMails = getMails?.data?.total_mails || 0;

  getMails = getMails?.data?.data;
  const [sendJsonMessage] = useOutletContext();
  const { chatState } = useContext(ChatContext);
  // const unreadMessages = useGetUnreadMessages();
  const unreadMessages = null;

  let unreadMessage = unreadMessages?.data?.unread_messages_count || 0;

  const [notificationAudio, setNotificationAudio] = useState(null);

  useEffect(() => {
    const audio = new Audio(unreadMessages?.data?.notification_sound || "");
    setNotificationAudio(audio);
  }, [unreadMessages?.data?.notification_sound]);

  useEffect(() => {
    if (notificationAudio && unreadMessages?.data?.unread_messages_count > 0) {
      notificationAudio.play();
    }
  }, [notificationAudio, unreadMessages?.data?.unread_messages_count]);
  const updateUserStatus = (status) => {
    sendJsonMessage({
      type: "user_status_update",
      status: status,
    });
    setOpened(false);
  };
  const location = useLocation();
  const [opened, setOpened] = useState(false);

  const [stateOpen, setStateOpen] = useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const { authState } = useContext(AuthContext);
  favNot.init({
    color: "#3987F5",
    lineColor: "#ffffff",
  });

  if (unread) {
    favNot.add();
  } else {
    favNot.remove();
  }

  if (location.pathname.includes("mail")) {
    setIsLoading(getMails?.isLoading);
    setInboxMails(getMails);
    settotalMails(totalMails);
  }

  return (
    <div>
      <div className="flex items-center space-x-5 -mr-4">
        {/* <div className="flex space-x-5">
          <span
            className={`cursor-pointer border-b-2 pb-1 ${
              location.pathname.includes("conversations")
                ? " border-primary"
                : "border-transparent"
            }`}
          >
            <Link to={"/app/conversations"}>
              {unreadMessage ? (
                <Indicator
                  label={unreadMessage}
                  size={16}
                  radius="xl"
                  className=""
                >
                  {ChatIcon(
                    `${
                      location.pathname.includes("conversations")
                        ? "#FB7C51"
                        : "#0D0D0D"
                    }`,
                    `h-5 w-5 3xl:w-6 3xl:h-6  `
                  )}
                </Indicator>
              ) : (
                ChatIcon(
                  "",
                  `h-5 w-5 3xl:w-6 3xl:h-6 ${
                    location.pathname.includes("conversations")
                      ? "stroke-primary"
                      : "stroke-black"
                  } `
                )
              )}
            </Link>
          </span>
          <span
            className={`cursor-pointer border-b-2 pb-1 ${
              location.pathname.includes("app/mail")
                ? " border-primary"
                : "border-transparent"
            }`}
          >
            <Link to={"/app/mail/inbox"}>
              {unread ? (
                <Indicator label={unread} size={16} radius="xl" className="">
                  {MessageIcon(
                    `${
                      location.pathname.includes("mail") ? "#FB7C51" : "#0D0D0D"
                    }`,
                    `h-5 w-5 3xl:w-6 3xl:h-6  `
                  )}
                </Indicator>
              ) : (
                MessageIcon(
                  `${
                    location.pathname.includes("mail") ? "#FB7C51" : "#0D0D0D"
                  }`,
                  `h-5 w-5 3xl:w-6 3xl:h-6  `
                )
              )}
            </Link>
          </span>
        </div> */}
        <div
          className={`flex space-x-2.5 cursor-pointer py-1 custom-indicator ${
            opened
              ? "border-b-2 border-primary"
              : "border-b-2 border-transparent"
          }`}
          onClick={() => setOpened(true)}
        >
          {/* <Indicator
            inline
            size={24}
            offset={7}
            label={StatusLabel(chatState?.user_status)}
            position="bottom-end"
            radius="xl"
          ></Indicator> */}

          <div className="flex flex-col justify-around">
            <p className="text-primary leading-4 3xl:text-2xl">Hello</p>
            <div className="flex items-center">
              <p className="text-7A7A7A mr-2 3xl:text-2xl">
                {JSON.parse(localStorage.getItem("username"))}
              </p>
              <DownArrowIcon className="3xl:w-6 3xl:h-6" />
            </div>
          </div>
        </div>
        <Popover
          opened={opened}
          onClose={() => setOpened(false)}
          position="bottom"
          placement="end"
          width={220}
          radius={"lg"}
          gutter={40}
        >
          <div className="space-y-4 px-3 select-none">
            {/* <div className="h-4 " onClick={() => setStateOpen(!stateOpen)}>
              <div className="flex items-center space-x-2 ">
                <p className="text-7A7A7A mr-2">{chatState.user_status}</p>
                <DownArrowIcon />
              </div>
              <Popover
                opened={stateOpen}
                onClose={() => setStateOpen(false)}
                position="top-start"
                placement="center"
                width={200}
                radius={"lg"}
                gutter={-10}
              >
                <div className="space-y-2 ">
                  <div
                    className="flex items-center space-x-3 cursor-pointer"
                    onClick={() => {
                      updateUserStatus("Online");
                    }}
                  >
                    <div className="w-5">
                      <AvailableIcon />
                    </div>
                    <p>Available</p>
                  </div>
                  <div
                    className="flex items-center space-x-3 cursor-pointer"
                    onClick={() => {
                      updateUserStatus("Away");
                    }}
                  >
                    <div className="w-5">
                      <AwayIcon />
                    </div>
                    <p>Away</p>
                  </div>
                  <div
                    className="flex items-center space-x-3 cursor-pointer"
                    onClick={() => {
                      updateUserStatus("Be Right Back");
                    }}
                  >
                    <div className="w-5">
                      <BeRightBackIcon />
                    </div>
                    <p>Be Right Back</p>
                  </div>
                  <div
                    className="flex items-center space-x-3 cursor-pointer"
                    onClick={() => {
                      updateUserStatus("Offline");
                    }}
                  >
                    <div className="w-5">
                      <OfflineIcon />
                    </div>
                    <p>Offline</p>
                  </div>
                  <div
                    className="flex items-center space-x-3 cursor-pointer"
                    onClick={() => {
                      updateUserStatus("Busy");
                    }}
                  >
                    <div className="w-5">
                      <BusyIcon />
                    </div>
                    <p>Busy</p>
                  </div>
                  <div
                    className="flex items-center space-x-3 cursor-pointer"
                    onClick={() => {
                      updateUserStatus("Do Not Disturb");
                    }}
                  >
                    <div className="w-5">
                      <DNDIcon />
                    </div>
                    <p>Do Not Disturb</p>
                  </div>
                </div>
              </Popover>
            </div> */}

            <div>
              {authState?.is_admin ? (
                <Link
                  to="/app/admin/profile"
                  className="flex items-center space-x-3"
                >
                  <div className="w-5">
                    <PersonIcon />
                  </div>
                  <p>Profile</p>
                </Link>
              ) : (
                <Link
                  to="/app/employee/profile"
                  className="flex items-center space-x-3"
                >
                  <div className="w-5">
                    <PersonIcon />
                  </div>
                  <p>Profile</p>
                </Link>
              )}
            </div>
            {authState?.is_super_admin ? (
              <div>
                <Link
                  to="/app/organisation-settings"
                  className="flex items-center space-x-3"
                >
                  <div className="w-5">
                    <OrgSettingsIcon />
                  </div>
                  <p className="whitespace-nowrap">Organisation Settings</p>
                </Link>
              </div>
            ) : null}
            {authState?.is_admin ? (
              <div>
                <Link
                  to="/app/settings"
                  className="flex items-center space-x-3"
                >
                  <div className="w-5">
                    <SettingsIcon />
                  </div>
                  <p>
                    {authState?.is_super_admin ? "My Settings" : "Settings"}
                  </p>
                </Link>
              </div>
            ) : null}
            {/* <div className='flex items-center space-x-3'>
            <div className='w-5'>
              <DollarIcon />
            </div>
            <p>Plans & billing</p>
          </div> */}
            <div
              className="flex items-center space-x-3 select-none cursor-pointer"
              onClick={() => {
                setOpened(false);
                setChangePasswordModalOpen(true);
              }}
            >
              <div className="w-5">
                <LockIcon />
              </div>
              <p>Change Password</p>
            </div>
            <div
              className="flex items-center space-x-3 cursor-pointer"
              onClick={() => {
                setOpened(false);
                setLogoutModalOpen(true);
              }}
            >
              <div className="w-5">
                <LogoutIcon />
              </div>
              <p>Logout</p>
            </div>
          </div>
        </Popover>
      </div>

      {/* change password modal */}
      <ChangePasswordModal
        isChangePasswordModalOpen={isChangePasswordModalOpen}
        setChangePasswordModalOpen={setChangePasswordModalOpen}
      />
      <LogoutModal
        isLogoutModalOpen={isLogoutModalOpen}
        setLogoutModalOpen={setLogoutModalOpen}
      />
    </div>
  );
}
