import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { useParams } from 'react-router-dom';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetEmployeeLeavesExcel(isLeavesFileDownload) {
  const { id } = useParams();

  return useQuery(
    ['employee-leaves-excel'],
    async () => {
      const res = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.EMPLOYEES_LEAVES_EXCEL, {
          employee_id: id,
        })
      );
      return res.data;
    },
    {
      enabled: isLeavesFileDownload,
    }
  );
}
