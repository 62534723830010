import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Header from 'components/Header';
import PrimaryButton from 'components/primaryButton';
import { Select, Text, Textarea, TextInput } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import ChangePasswordModal from 'components/changePassword/changePasswordModal';
import { useDebouncedValue } from '@mantine/hooks';

import useGetCountries from '../hooks/useGetCountriesList';
import useUpdateCompanyDetails from '../hooks/useUpdateCompanyDetails';
import useGetCompanyDetails from '../hooks/useGetCompanyDetails';
import useGetAdminProfile from '../hooks/useGetAdminProfile';
import useUpdateAdminProfile from '../hooks/useUpdateAdminProfile';
import useAddDomian from '../hooks/useAddnewDomains';
import useGetDomains from '../hooks/useGetdomains';
import useDeleteDomain from '../hooks/useDeleteDomain';
import useUpdateDomian from '../hooks/useUpdatedomian';

import { ReactComponent as DownArrowIcon } from 'assets/icons/downArrow2.svg';
import { ReactComponent as DropZoneIcon } from 'assets/icons/dropzonePlaceholder.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/wrongIcon.svg';

// import { ReactComponent as AddIcon } from 'assets/icons/pluswithcirclebg.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/minuswithcirclebg.svg';
import { AuthContext } from 'context/authContext';

export default function AdminProfileDetails(props) {
  const updateDomian = useUpdateDomian();
  //deboubse value functions
  const [filedvalue, setFieldValue] = useState('');
  const [fileCheck, setFileCheck] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [debounced] = useDebouncedValue(filedvalue, 500);

  useEffect(() => {
    if (debounced?.value !== undefined) {
      updateDomian.mutate({
        name: debounced?.value,
        id: watch('BlogsDomain')[debounced?.index]?.id,
      });
    }

    // eslint-disable-next-line
  }, [debounced]);

  const [turnEditOn, setTurnEditOn] = useState(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);
  const [isIcon, setIsIcon] = useState(false);

  const { authState } = useContext(AuthContext);

  const countriesData = useGetCountries();
  const updateCompanyDetails = useUpdateCompanyDetails();
  const getCompanyDetails = useGetCompanyDetails();
  const getAdminDetails = useGetAdminProfile();
  const updateAdminProfile = useUpdateAdminProfile();
  const addDomain = useAddDomian(prependDomain);

  function prependDomain() {
    domainPrepend({
      name: watch('domain'),
    });
  }
  const getDomains = useGetDomains();
  const deleteDomain = useDeleteDomain();

  const defaultValues = {
    fullName: '',
    email: '',
    mobile_number: '',
    timezone_info: Intl.DateTimeFormat().resolvedOptions().timeZone,
    company_name: '',
    country_code_1: '',
    country: '',
    country_code_2: '',
    alternate_number: '',
    website: '',
    company_address: '',
    company_logo: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues,
  });

  const {
    fields,
    prepend: domainPrepend,
    remove,
  } = useFieldArray({
    control,
    name: 'BlogsDomain',
  });

  const formSubmitHandler = (data) => {
    if (turnEditOn && watch('domain') !== '') {
      addDomain.mutate({ name: watch('domain') });
      setValue('domain', '');
    }
    const formData = new FormData();
    // formData.append('email', data?.email);
    formData.append('mobile_number', data?.mobile_number);
    formData.append('timezone_info', data?.timezone_info);
    formData.append('company_name', data?.company_name);
    formData.append('country_code_1', data?.country_code_1);
    formData.append('country', data?.country);
    if (data?.country_code_2) {
      formData.append('country_code_2', data?.country_code_2);
    }
    if (data?.alternate_number) {
      formData.append('alternate_number', data?.alternate_number);
    }
    if (data?.website) {
      formData.append('website', data?.website);
    }
    formData.append('company_address', data?.company_address);
    if (
      data?.company_logo &&
      data?.company_logo !== getCompanyDetails?.data?.data?.[0]?.company_logo
    ) {
      formData.append('company_logo', data?.company_logo);
    }
    setTurnEditOn(false);
    updateAdminProfile.mutate({
      email: data?.email,
      first_name: data?.fullName,
    });
    updateCompanyDetails.mutate(formData);
  };

  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className='absolute top-1 right-0 text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const watchCountry = watch('country');

  useEffect(() => {
    countriesData?.data?.data?.map((country) => {
      if (country?.common_name === watchCountry) {
        setValue('country_code_1', country?.phone_code);
      }
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCountry]);

  useEffect(() => {
    reset({
      fullName: getAdminDetails?.data?.data?.[0]?.first_name,
      email: getAdminDetails?.data?.data?.[0]?.email,
      mobile_number: getCompanyDetails?.data?.data?.[0]?.mobile_number,
      timezone_info: getCompanyDetails?.data?.data?.[0]?.timezone_info,
      company_name: getCompanyDetails?.data?.data?.[0]?.company_name,
      country_code_1: getCompanyDetails?.data?.data?.[0]?.country_code_1,
      country: getCompanyDetails?.data?.data?.[0]?.country,
      country_code_2: getCompanyDetails?.data?.data?.[0]?.country_code_2,
      alternate_number: getCompanyDetails?.data?.data?.[0]?.alternate_number,
      website: getCompanyDetails?.data?.data?.[0]?.website,
      company_address: getCompanyDetails?.data?.data?.[0]?.company_address,
      BlogsDomain: getDomains?.data?.data?.map((eachDoamin) => ({
        name: eachDoamin?.name,
        id: `${eachDoamin?.id}`,
      })),
      // company_logo: getCompanyDetails?.data?.data?.[0]?.company_logo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCompanyDetails?.data?.data,
    getAdminDetails?.data?.data,
    getDomains?.data?.data,
  ]);

  const getPhoneCode = () => {
    let finalArray = [];
    countriesData?.data?.data?.map((country) => {
      if (country?.phone_code) {
        finalArray.push({
          value: `${country.phone_code}`,
          label: `${country.phone_code}`,
        });
      }
      return true;
    });
    return finalArray;
  };

  let countryCodes = getPhoneCode();
  countryCodes = countryCodes.filter(
    (phone, index, self) =>
      index ===
      self.findIndex((t) => t.value === phone.value && t.label === phone.label)
  );
  countryCodes.sort((a, b) => a.label - b.label);

  return (
    <div className='mt-6 mx-6'>
      <div className='flex items-center justify-between'>
        <p className='text-20 font-semibold text-gray'>My profile</p>
        <Header />
      </div>

      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className='bg-gradient-to-b from-[#FFFFFF] to-[#FFFBFA] shadow-three px-6 py-16 rounded-xl mt-4'
      >
        <div className='flex items-center justify-between mb-14 px-20'>
          <div className='flex items-center space-x-2'>
            {/* <Avatar size='lg' radius='xl' color={'orange'} /> */}
            <div>
              <p className='text-20'>
                Hello{authState?.username ? `, ${authState?.username}` : ''}
              </p>
              <p className='text-gray3 text-sm mt-1'>Admin</p>
            </div>
          </div>

          <div className='flex items-center space-x-6'>
            <p
              className='text-[#2F80ED] select-none cursor-pointer'
              onClick={() => setChangePasswordModalOpen(true)}
            >
              Change Password
            </p>
            {turnEditOn ? (
              <button
                disabled={fileCheck ? true : false}
                className={`w-32 ${
                  fileCheck
                    ? 'bg-[#fb7c5171] text-white rounded-md py-2 px-4 font-medium flex items-center justify-center cursor-not-allowed'
                    : 'bg-primary text-white rounded-md py-2 px-4 font-medium flex items-center justify-center hover:bg-[#e4643a]'
                }`}
              >
                <p>Save</p>
              </button>
            ) : (
              <div
                onClick={() => setTurnEditOn(true)}
                className='w-32 cursor-pointer'
              >
                <PrimaryButton>
                  <p>Edit</p>
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>

        <div className='space-y-6 px-20'>
          <div className='grid grid-cols-2 gap-x-6'>
            <div className='relative'>
              {getErrorMessage('fullName')}
              <Controller
                control={control}
                name='fullName'
                rules={{
                  required: {
                    value: true,
                    message: 'This is a required field',
                  },
                }}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Full Name*'
                    size='md'
                    disabled={!turnEditOn}
                    classNames={{
                      wrapper: 'rounded-md font-semibold border-primary',
                      input: 'border-primary',
                    }}
                  />
                )}
              />
            </div>

            <div className='relative'>
              {getErrorMessage('company_name')}
              <Controller
                control={control}
                name='company_name'
                rules={{
                  required: {
                    value: true,
                    message: 'This is a required field',
                  },
                }}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Company / Organisation name*'
                    size='md'
                    classNames={{
                      wrapper: 'rounded-md font-semibold border-primary',
                      input: 'border-primary',
                    }}
                    disabled={!turnEditOn}
                  />
                )}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-6'>
            <div className='relative'>
              {getErrorMessage('email')}
              <Controller
                control={control}
                name='email'
                rules={{
                  required: {
                    value: true,
                    message: 'This is a required field',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter valid email',
                  },
                }}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    {...field}
                    type='email'
                    label='Email*'
                    readOnly
                    size='md'
                    classNames={{
                      wrapper: 'rounded-md font-semibold border-primary ',
                      input: 'border-primary cursor-not-allowed',
                    }}
                    disabled={!turnEditOn}
                  />
                )}
              />
            </div>

            <div className='relative'>
              {getErrorMessage('country')}
              <Controller
                control={control}
                name='country'
                rules={{
                  required: {
                    value: true,
                    message: 'This is a required field',
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    searchable
                    data={
                      !countriesData?.data?.data?.length
                        ? []
                        : countriesData?.data?.data?.map((country) => {
                            return {
                              value: country.common_name,
                              label: country.common_name,
                            };
                          })
                    }
                    label='Country*'
                    size='md'
                    rightSection={<DownArrowIcon />}
                    classNames={{
                      wrapper: 'rounded-md font-semibold border-primary',
                      input: 'border-primary',
                    }}
                    styles={{
                      rightSection: {
                        pointerEvents: 'none',
                      },
                    }}
                    disabled={!turnEditOn}
                  />
                )}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-6'>
            <div className='col-span-1 relative'>
              <p className='text-[#212925] text-[13px] mb-1'>
                Company contact number
              </p>
              <div className='grid grid-cols-4 gap-x-6 self-end '>
                <Controller
                  control={control}
                  name='country_code_1'
                  render={({ field }) => (
                    <Select
                      {...field}
                      searchable
                      data={
                        !countriesData?.data?.data?.length ? [] : countryCodes
                      }
                      size='md'
                      disabled={!turnEditOn}
                      rightSection={<DownArrowIcon />}
                      styles={{
                        rightSection: {
                          pointerEvents: 'none',
                        },
                      }}
                      classNames={{
                        wrapper: 'rounded-md font-semibold border-primary',
                        input: 'border-primary',
                      }}
                    />
                  )}
                />
                {getErrorMessage('mobile_number')}
                <Controller
                  control={control}
                  name='mobile_number'
                  defaultValue=''
                  rules={{
                    required: {
                      value: true,
                      message: 'This is a required field',
                    },
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                    // minLength: {
                    //   value: 6,
                    //   message: 'minimum of 6 digits',
                    // },
                    maxLength: {
                      value: 16,
                      message: 'maximum of 16 digits only',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size='md'
                      className='self-end col-span-3'
                      classNames={{
                        wrapper: 'rounded-md font-semibold border-primary',
                        input: 'border-primary',
                      }}
                      disabled={!turnEditOn}
                    />
                  )}
                />
              </div>
            </div>

            <div className='col-span-1 relative'>
              <p className='text-[#212925] text-[13px] mb-1'>
                Alternate contact
              </p>
              <div className='grid grid-cols-4 gap-x-6 '>
                <Controller
                  control={control}
                  name='country_code_2'
                  render={({ field }) => (
                    <Select
                      {...field}
                      searchable
                      data={
                        !countriesData?.data?.data?.length ? [] : countryCodes
                      }
                      size='md'
                      rightSection={<DownArrowIcon />}
                      styles={{
                        rightSection: {
                          pointerEvents: 'none',
                        },
                      }}
                      classNames={{
                        wrapper: 'rounded-md font-semibold border-primary',
                        input: 'border-primary',
                      }}
                      disabled={!turnEditOn}
                    />
                  )}
                />
                {getErrorMessage('alternate_number')}
                <Controller
                  control={control}
                  name='alternate_number'
                  defaultValue=''
                  rules={{
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                    // minLength: {
                    //   value: 6,
                    //   message: 'minimum of 6 digits',
                    // },
                    maxLength: {
                      value: 16,
                      message: 'maximum of 16 digits only',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      size='md'
                      className='self-end col-span-3'
                      disabled={!turnEditOn}
                      classNames={{
                        wrapper: 'rounded-md font-semibold border-primary',
                        input: 'border-primary',
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className='grid grid-cols-12 items-center'>
            <div className='col-span-11'>
              <Controller
                control={control}
                name={'domain'}
                defaultValue=''
                render={({ field }) => (
                  <TextInput
                    type='url'
                    {...field}
                    label='Domains/ platform to publish'
                    placeholder='https://example.com'
                    size='md'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (turnEditOn && watch('domain') !== '') {
                          addDomain.mutate({ name: watch('domain') });
                          setValue('domain', '');
                        }
                      }
                    }}
                    classNames={{
                      wrapper: 'rounded-md font-semibold border-primary',
                      input: 'border-primary',
                    }}
                    disabled={!turnEditOn}
                  />
                )}
              />
            </div>
            <button
              type='button'
              className={`${
                !turnEditOn || !watch('domain')
                  ? 'flex items-center justify-center mt-5 !cursor-not-allowed opacity-50 ml-5'
                  : 'flex items-center justify-center mt-5 ml-5'
              } w-20`}
              onClick={() => {
                if (turnEditOn && watch('domain') !== '') {
                  addDomain.mutate({ name: watch('domain') });
                  setValue('domain', '');
                }
              }}
            >
              <PrimaryButton disabled={!turnEditOn || !watch('domain')}>
                Save
              </PrimaryButton>
            </button>
          </div>

          {fields.map((field, index) => (
            <div className='grid grid-cols-12' key={index}>
              <div className='col-span-11'>
                <Controller
                  control={control}
                  name={`BlogsDomain.${index}.name`}
                  // defaultValue=''
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setFieldValue({ value: e.target.value, index: index });
                      }}
                      size='md'
                      classNames={{
                        wrapper: 'rounded-md font-semibold border-primary',
                        input: 'border-primary',
                      }}
                      disabled={!turnEditOn}
                    />
                  )}
                />
              </div>
              <button
                className={
                  !turnEditOn
                    ? 'flex items-center justify-center  cursor-not-allowed opacity-50 ml-5'
                    : 'flex items-center justify-center  ml-5'
                }
                type='button'
                onClick={() => {
                  if (turnEditOn) {
                    deleteDomain.mutate(watch('BlogsDomain')[index]);
                    console.log(watch('BlogsDomain')[index]);
                    remove({
                      index,
                    });
                  }
                }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}

          <div className='relative'>
            {getErrorMessage('company_address')}
            <Controller
              control={control}
              name='company_address'
              rules={{
                required: {
                  value: true,
                  message: 'This is a required field',
                },
              }}
              defaultValue=''
              render={({ field }) => (
                <Textarea
                  {...field}
                  label='Company Address*'
                  size='md'
                  classNames={{
                    wrapper: 'rounded-md font-semibold border-primary',
                    input: 'border-primary',
                  }}
                  disabled={!turnEditOn}
                  minRows={3}
                  autosize
                />
              )}
            />
          </div>
          <div>
            {getErrorMessage('company_logo')}
            <p className='text-[#212925] text-[13px] mb-1'>Company logo</p>
            <Controller
              control={control}
              name='company_logo'
              render={({ field }) => (
                <Dropzone
                  // loading
                  {...field}
                  disabled={!turnEditOn}
                  accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                  maxSize={'5000000'}
                  onDrop={(files) => {
                    setValue('company_logo', files[0]);
                    setFileCheck(false);
                    setErrormsg(false);
                  }}
                  onReject={(files) => {
                    setValue('file_error', files[0].errors[0].message);

                    if (
                      files[0].errors[0].message ===
                      'File is larger than 5000000 bytes'
                    ) {
                      setErrormsg(true);
                      setFileCheck(false);
                    }
                    if (
                      files[0].errors[0].message ===
                      'File type must be one of image/png, image/jpeg'
                    ) {
                      setFileCheck(true);
                      setErrormsg(false);
                    }
                  }}
                  className={`border-primary rounded-md h-52 flex items-center justify-center  ${
                    turnEditOn ? 'bg-[#F8EFEF]' : 'cursor-not-allowed'
                  }`}
                >
                  {() => (
                    <div
                      className='flex flex-col items-center relative'
                      onMouseEnter={() => setIsIcon(true)}
                      onMouseLeave={() => setIsIcon(false)}
                    >
                      {watch('company_logo')?.name ? (
                        <div className='w-60 h-28 flex items-center overflow-hidden'>
                          <img
                            src={URL.createObjectURL(watch('company_logo'))}
                            alt=''
                            className='object-contain'
                          />
                        </div>
                      ) : getCompanyDetails?.data?.data?.[0]?.company_logo ? (
                        <div className='w-60 h-28 flex items-center overflow-hidden'>
                          <img
                            src={
                              getCompanyDetails?.data?.data?.[0]?.company_logo
                            }
                            alt=''
                            className='object-contain'
                          />
                        </div>
                      ) : (
                        <>
                          <DropZoneIcon />
                          <p className='mt-4 text-gray'>Upload a logo</p>
                          <Text size='xl' color='dimmed' inline mt={7}>
                            Drag image here or click to select file
                          </Text>
                        </>
                      )}
                      {isIcon && watch('company_logo') && (
                        <div className='absolute w-60 h-28 flex justify-center items-center bg-black bg-opacity-60 z-[100]'>
                          <CrossIcon
                            className='w-10 h-10'
                            onClick={() => {
                              console.log('overlay clicked');
                              setValue('company_logo', null);
                              setFileCheck(false);
                              setErrormsg(false);
                            }}
                          />
                        </div>
                      )}

                      {fileCheck ? (
                        <h1 className='text-red-600'>
                          File type must be *jpg or *png
                        </h1>
                      ) : (
                        <Text size='sm' color='dimmed' inline mt={7}>
                          Only *.jpeg and *.png images are accepted
                        </Text>
                      )}

                      {errormsg ? (
                        <h1 className='text-red-600'>
                          File must be less than 5 MB
                        </h1>
                      ) : null}
                    </div>
                  )}
                </Dropzone>
              )}
            />
            {getCompanyDetails?.data?.data?.[0]?.company_logo && (
              <div className=' mt-1 flex justify-center items-center text-center w-full'>
                {turnEditOn && (
                  <p
                    className='text-[#2F80ED] select-none cursor-pointer hover:underline '
                    onClick={() => {
                      return updateCompanyDetails.mutate({
                        company_logo: '',
                      });
                    }}
                  >
                    Remove logo
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
      <ChangePasswordModal
        isChangePasswordModalOpen={isChangePasswordModalOpen}
        setChangePasswordModalOpen={setChangePasswordModalOpen}
      />
    </div>
  );
}
