import { Modal, Select } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';

import { DateRangePicker } from '@mantine/dates';

import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as CalanderIcon } from 'assets/icons/calanderIcon.svg';

//hooks get requests
// import Usegetemployessfromteams from '../hooks/usegetemployess';
import Usegetreviewsfromsettings from '../hooks/usegetreviewtypes';
import useGetlistofEmployess from '../hooks/useGetlistofemployess';
import SecondaryButton from 'components/secondaryButton';
import PrimaryButton from 'components/primaryButton';
import { useEffect, useState } from 'react';
import ApparisalModal from './apparisalmodal';
import dayjs from 'dayjs';

const AppraisalSelectEmployeeModal = ({
  apprasialSelectEmployeeModal,
  setApprasialSelectEmployeeModal,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({ mode: 'onChange' });
  const defaultValues = {
    Select_employee: '',
    review_type: '',
    segment: '',
  };

  const [value, setValuedate] = useState([]);

  useEffect(() => {
    const objectOfReviewType = reviewTypes?.data?.data?.find(
      // eslint-disable-next-line
      (each) => each?.id == watch('review_type')
    );
    const newDate =
      objectOfReviewType?.name === 'Weekly'
        ? dayjs(value[0]).add(7, 'days')
        : objectOfReviewType?.name === 'Quarterly'
        ? dayjs(value[0]).add(3, 'months')
        : objectOfReviewType?.name === 'Annually'
        ? dayjs(value[0]).add(1, 'year')
        : null;

    setValuedate([value[0], newDate?.$d]);
    setValue('segment', value);
    // eslint-disable-next-line
  }, [value[0]]);

  useEffect(() => {
    setValue('segment', []);
    setValuedate([]);
    // eslint-disable-next-line
  }, [watch('review_type')]);

  const totalEmployess = useGetlistofEmployess();
  const reviewTypes = Usegetreviewsfromsettings();

  const [apparisalEmployeData, setApparisalEmployeData] = useState(null);
  const [addApparisalData, setAddApparisalData] = useState(false);

  const submitAppraisalEmployee = (data) => {
    setApparisalEmployeData({ ...data, segment: value });
    setAddApparisalData(true);
    // reset({ defaultValues });
  };

  return (
    <div>
      <Modal
        opened={apprasialSelectEmployeeModal}
        onClose={() => {
          setApprasialSelectEmployeeModal(false);
          reset(defaultValues);
        }}
        title='Creating appraisal report'
        size='xl'
        centered
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        transitionDuration={100}
        overflow='inside'
        className='px-10'
        classNames={{
          title: 'text-lg font-semibold mx-auto',
          body: 'scroller',
        }}
      >
        <form
          className='flex flex-col py-6 px-2'
          onSubmit={handleSubmit(submitAppraisalEmployee)}
        >
          <Controller
            control={control}
            name='Select_employee'
            rules={{
              required: 'Required',
            }}
            render={({ field }) => (
              <Select
                {...field}
                label='Select Employee*'
                // itemComponent={SelectItem}
                data={
                  totalEmployess?.data?.data?.map((eachEmployee) => {
                    return {
                      value: `${eachEmployee.id}`,
                      label: eachEmployee.user__first_name,
                    };
                  }) || []
                }
                searchable
                // maxDropdownHeight={200}
                nothingFound='Nobody here'
                // filter={(value, item) =>
                //   item.label
                //     .toLowerCase()
                //     .includes(value.toLowerCase().trim()) ||
                //   item.description
                //     .toLowerCase()
                //     .includes(value.toLowerCase().trim())
                // }
                size='lg'
                classNames={{
                  input:
                    ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5',
                  label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                }}
                rightSection={<DownArrow />}
                styles={{ rightSection: { pointerEvents: 'none' } }}
              />
            )}
          />
          {errors.Select_employee && (
            <p className='text-sm  text-[#fc0101]'>
              {errors.Select_employee.message}
            </p>
          )}

          <div className='grid grid-cols-2 gap-4 mt-8'>
            <div className=''>
              <Controller
                control={control}
                name='review_type'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label='Select review Type*'
                    size='lg'
                    data={
                      reviewTypes?.data?.data?.map((eachReview) => {
                        return {
                          value: `${eachReview.id}`,
                          label: eachReview.name,
                        };
                      }) || []
                    }
                    classNames={{
                      input:
                        ' text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                    }}
                    rightSection={<DownArrow />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                  />
                )}
              />
              {errors.review_type && (
                <p className='text-sm  text-[#fc0101]'>
                  {errors.review_type.message}
                </p>
              )}
            </div>
            <div>
              <Controller
                control={control}
                name='segment'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <DateRangePicker
                    {...field}
                    label='Review Period*'
                    value={value}
                    onChange={(e) => {
                      field.onChange(e);
                      setValuedate(e);
                    }}
                    // className='w-40'

                    classNames={{
                      input:
                        'h-[50px]  bg-transparent border-[#FB7C51] text-sm rounded-lg pl-5',
                      label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                      yearPickerControlActive: 'text-white bg-primary',
                      monthPickerControlActive: 'text-white bg-primary',
                      dropdown: '-mt-52',
                    }}
                    rightSection={<CalanderIcon />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                  />
                )}
              />
              {errors.segment && (
                <p className='text-sm  text-[#fc0101]'>
                  {errors.segment.message}
                </p>
              )}
            </div>
          </div>
          <div className='mx-auto mt-5'>
            <button
              className='mr-2 mt-7'
              type='button'
              onClick={() => {
                setApprasialSelectEmployeeModal(false);
                reset({});
              }}
            >
              <SecondaryButton>Discard</SecondaryButton>
            </button>
            <button className='mt-7'>
              <PrimaryButton>Next</PrimaryButton>
            </button>
          </div>
        </form>
      </Modal>
      <ApparisalModal
        addApparisalData={addApparisalData}
        setAddApparisalData={setAddApparisalData}
        apparisalEmployeData={apparisalEmployeData}
        setApprasialSelectEmployeeModal={setApprasialSelectEmployeeModal}
      />
    </div>
  );
};

export default AppraisalSelectEmployeeModal;
