import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useOrganisationCharts(id) {
  return useQuery(['charts-superAdmin', id], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.ORGANISATION_CHART, {
        company_id: id,
      })
    );
    return response?.data;
  });
}
