import { useMutation, useQueryClient } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { SuccessToast, ErrorToast } from 'services/toasterService';

export default function Useaddpolicies(cb) {
  const queryClient = useQueryClient();
  // console.log('POLICIES_API');
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.post(
        apiEndPoints.POLICIES_API,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('policies-get-data');
        queryClient.invalidateQueries('super-settings-policies');
        cb();
        SuccessToast({ text: data?.message });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
