import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useGetDepartmentEmployees() {
  return useQuery('settings-reporting-managers-and-employees', async () => {
    const response = await makeApiRequest.get(
      apiEndPoints.SETTINGS_REPORTING_MANAGERS_AND_EMPLOYEES
    );
    return response?.data;
  });
}
