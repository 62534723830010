import { useRef, useState, useEffect } from 'react';
import convertFileToBase64 from '../helper/convertFileToBase64';

import { ReactComponent as ImageEditIcon } from 'assets/icons/imageEdit.svg';
import { ErrorToast } from '../services/toasterService';
const AvatarUpload = ({
  imgUrl = 'https://dev-api.apta-hr.com/media_ats/images/noImage.svg',
  setProfilePicture,
}) => {
  const [selectedFile, setSelectedFile] = useState();

  const [preview, setPreview] = useState('');
  const fileRef = useRef(null);
  const triggerFileInput = () => {
    fileRef.current.click();
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    const maxAllowedSize = 5 * 1024 * 1024;

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this  simple by using the first image instead of multiple
    if (
      (e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/png') &&
      e.target.files[0].size <= maxAllowedSize
    ) {
      convertFileToBase64(e.target.files[0], (result) => {
        // updateProfileImage.mutate({ profile_picture: result });
        setProfilePicture(e.target.files[0]);
      });
      setSelectedFile(e.target.files[0]);
    } else {
      // ErrorToast({ text: 'Only Jpeg and png are supported!' });
      if (
        !(
          e.target.files[0].type === 'image/jpeg' ||
          e.target.files[0].type === 'image/png'
        )
      ) {
        ErrorToast({ text: 'Only Jpeg and png are supported!' });
      } else {
        ErrorToast({ text: 'file size should be less than 5 MB' });
      }
    }
  };
  return (
    <div className='avatar-profile-my-conatier'>
      <input
        type='file'
        name='image'
        id='image'
        accept='image/jpeg,image/png'
        ref={fileRef}
        onChange={onSelectFile}
        className='hidden'
      />
      <div id='preview-profile' className='relative'>
        <img
          className='inline object-fill min-h-20 min-w-20 h-20 w-20  rounded-full border-2 border-[#FB7C51] '
          src={preview || imgUrl}
          alt='Profile'
        />

        <div
          id='upload-button-avatar'
          aria-labelledby='image'
          aria-describedby='image'
          onClick={triggerFileInput}
        >
          <ImageEditIcon className='w-6 absolute bottom-0 right-0 cursor-pointer' />
        </div>
      </div>
    </div>
  );
};
export default AvatarUpload;
