import React, { useState, useEffect } from 'react';
import {
  Modal,
  TextInput,
  Select,
  Checkbox,
  Stepper,
  /*  PasswordInput */
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';

import PrimaryButton from 'components/primaryButton';
import WorkingHoursForm from './workingHoursForm';
// import AddEmployeeSecondStep from './addEmployeeSecondStep';
import AddEmployeeThridStep from './addEmployeeThridStep';

import UsegetCountries from 'pages/auth/hooks/usegetcountries';
import useGetReportiesList from '../hooks/useGetReportiesList';
import useGetDepartmentsForEmployees from '../hooks/useGetDepartmentsForEmployee';
import useGetEmployementTypes from '../hooks/useGetEmployementType';
import useGetCompanyDetails from '../hooks/useGetComapanyDetails';
import useGetAllGrades from '../hooks/useGetAllGrades';
import useAddEmployee from '../hooks/useAddEmployee';
import useUpdateEmployee from '../hooks/useUpdateEmployee';
import useGetDesignations from '../hooks/useGetDesignations';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';
import addEmployeeBg from 'assets/images/addEmployeeBg.png';

export default function AddTeamMember({
  isModalOpen,
  setIsModalOpen,
  name = 'Add',
  employeeDetails,
}) {
  const [selectedDays, setSelectedDays] = useState([
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
  ]);
  const [isWorkingHoursSame, setIsWorkingHoursSame] = useState(false);
  const [selectedCountryData, setSelectedCountryData] = useState();
  const [employeeId, setEmployeeId] = useState('');
  const [active, setActive] = useState(0);
  // const [otherallowances, setOtherAllowances] = useState([]);

  const closeModal = () => {
    setIsModalOpen(false);
    setActive(0);
    setIsWorkingHoursSame(true);
  };

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p> {name} employee</p>
      </div>
    );
  };

  // navigating between stepper
  const nextStep = () =>
    setActive((active) => (active < 2 ? active + 1 : active));
  const prevStep = () =>
    setActive((active) => (active > 0 ? active - 1 : active));

  const defaultValues = {
    full_name: '',
    email: '',
    country: '',
    country_code: '',
    contact: '',
    date_of_joining: '',
    employee_id: '',
    grade: '',
    reports_to: null,
    department: '',
    gross_salary: '',
    has_reportees: '',
    probation_days: '',
    is_under_probation: false,
    is_under_notice_period: false,
    notice_period_days: '',
    gender: '',
    designation: '',
    employment_type: '',
    other_allowances: '',
    basic_pay: '',
    hra: '',
    ctc: 0,
    can_add_employees: false,
    can_publish_blogs: false,
    can_view_pr: false,
    can_comment_pr: false,
  };

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    getValues,
    setValue,
    reset,
  } = useForm({ defaultValues });

  // const getEmployeeAdminFields = useGetEmployeeAdminFields();
  const getCountriesList = UsegetCountries();
  const getReportiesList = useGetReportiesList(
    employeeDetails?.id,
    isModalOpen
  );
  const getDepartmentsForEmployee = useGetDepartmentsForEmployees();
  const getEmployementTypes = useGetEmployementTypes(isModalOpen);
  const getCompanyDetails = useGetCompanyDetails();
  const getAllGrades = useGetAllGrades(isModalOpen);
  const addEmployeeMutation = useAddEmployee(closeModal, reset);
  const updateEmployeeMutation = useUpdateEmployee(closeModal, reset);
  const getDesignations = useGetDesignations(watch('department'));

  // Add team member form on submit
  const onSubmit = (data) => {
    const extraFields = {};

    // employee details
    const employeeDetails = {
      employee_id: data?.employee_id,
      date_of_joining: dayjs(data?.date_of_joining).format('YYYY-MM-DD'),
      country: data?.country,
      gross_salary: data?.ctc,
      department_id: data?.department,
      reports_to_id: data?.reports_to ? data?.reports_to : null,
      grade_id: data?.grade,
      gender: data?.gender,
      designation_id: data?.designation,
      employment_type_id: data?.employment_type,
      is_under_probation: data?.is_under_probation,
      probation_days: data?.is_under_probation ? data?.probation_days : null,
    };

    // working details
    let workingDetails = {};
    selectedDays?.map((day) => {
      if (isWorkingHoursSame) {
        return (
          (workingDetails['working_hours_same'] = true),
          (workingDetails[`is_${day}`] = true),
          (workingDetails['general_from'] = data?.general_from),
          (workingDetails['general_to'] = data?.general_to)
        );
      } else {
        return (
          (workingDetails['working_hours_same'] = false),
          (workingDetails[`is_${day}`] = true),
          (workingDetails[`${day}_from`] = data[`${day}_from`]),
          (workingDetails[`${day}_to`] = data[`${day}_to`])
        );
      }
    });

    // salary details

    // let basicPay = (data?.basic_pay * data?.ctc) / 100;
    // let hra = (data?.hra * basicPay) / 100;
    // let allowancesValue = 0;

    // otherallowances?.map(
    //   (item) =>
    //     (allowancesValue = allowancesValue + (data[item] * basicPay) / 100)
    // );

    // let salaryDetails = {
    //   other_allowances: data?.ctc - (basicPay + hra + allowancesValue),
    //   basic_pay: data?.basic_pay,
    //   hra: data?.hra,
    //   ctc: data?.ctc,
    //   professional_tax: data?.professional_tax,
    //   components: otherallowances?.map((item) => {
    //     return { id: item, value: data[item] };
    //   }),
    // };

    // permission data
    let permission_details = {
      can_add_employees: data?.can_add_employees,
      can_publish_blogs: data?.can_publish_blogs,
      can_view_pr: data?.can_view_pr,
      can_comment_pr: data?.can_view_pr,
    };

    // final data
    const finalData = {
      basic_details: {
        first_name: data?.full_name,
        email: data?.email,
        is_subadmin: data?.has_reportees === 'Yes' ? true : false,
        is_active: true,
        timezone_info: 'Asia/Kolkata',
        mobile_number: data?.contact,
        country_code: selectedCountryData?.[0]?.phone_code,
      },
      employee_details: {
        ...employeeDetails,

        extra_fields: Object.keys(extraFields)?.length ? extraFields : null,
      },
      wdt_details: workingDetails,
      // salary_details: salaryDetails,
      permission_details: permission_details,
    };

    // edit data which won't have work details
    const editData = {
      first_name: data?.full_name,
      email: data?.email.toLowerCase(),
      is_subadmin: data?.has_reportees === 'Yes' ? true : false,
      is_active: true,
      timezone_info: 'Asia/Kolkata',
      mobile_number: data?.contact,
      country_code: selectedCountryData?.[0]?.phone_code,
      ...employeeDetails,
      extra_fields: Object.keys(extraFields)?.length ? extraFields : null,
      is_under_notice_period: data?.is_under_notice_period,
      notice_period_days: data?.is_under_notice_period
        ? data?.notice_period_days
        : null,
    };

    if (name === 'Add') {
      addEmployeeMutation.mutate(finalData);
    } else {
      editData['id'] = employeeId;
      delete editData?.gross_salary;
      updateEmployeeMutation.mutate(editData);
    }
  };

  const watchCounty = watch('country');
  const watchDepartment = watch('department');

  // setting is working hours same from backend data
  useEffect(() => {
    setIsWorkingHoursSame(getCompanyDetails?.data?.data?.[0]?.are_timings_same);
    if (getCompanyDetails?.data?.data?.[0]?.are_timings_same) {
      reset({
        general_from: getCompanyDetails?.data?.data?.[0]?.from_time,
        general_to: getCompanyDetails?.data?.data?.[0]?.to_time,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyDetails?.data?.data]);

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  // reseting values for edit form
  useEffect(() => {
    if (name === 'Edit') {
      setEmployeeId(employeeDetails?.id);
      reset({
        ...getValues(),
        full_name: employeeDetails?.user__first_name,
        email: employeeDetails?.user__email,
        country: employeeDetails?.country,
        contact: employeeDetails?.user__mobile_number,
        date_of_joining: new Date(employeeDetails?.date_of_joining),
        employee_id: employeeDetails?.employee_id,
        grade: `${employeeDetails?.grade_id}`,
        reports_to: employeeDetails?.reports_to_id
          ? `${employeeDetails?.reports_to_id}`
          : null,
        department: `${employeeDetails?.department_id}`,
        gross_salary: employeeDetails?.gross_salary,
        has_reportees: employeeDetails?.user__is_subadmin ? 'Yes' : 'No',
        is_under_probation: employeeDetails?.is_under_probation,
        probation_days: employeeDetails?.probation_days,
        is_under_notice_period: employeeDetails?.is_under_notice_period,
        notice_period_days: employeeDetails?.notice_period_days,
        gender: employeeDetails?.gender,
        designation: `${employeeDetails?.designation}`,
        employment_type: `${employeeDetails?.employment_type_id}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, employeeDetails, isModalOpen]);

  // getting country phone code
  useEffect(() => {
    const newData = getCountriesList?.data?.data.filter(
      (name) => name.common_name === watch('country')
    );
    setSelectedCountryData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCounty, getCountriesList?.data?.data]);

  // custom dropdown for reporting managers
  const getCustomDropdownValues = () => {
    const newArray = [];
    getReportiesList?.data?.data?.map((employee) => {
      newArray.push({
        value: `${employee.user__id}`,
        label: employee.user__first_name,
      });
      return true;
    });
    newArray.unshift({ value: null, label: 'Reports to no one' });
    return newArray;
  };

  const handleFormSubmit = (data) => {
    if (name === 'Edit') {
      onSubmit(data);
    } else {
      if (active === 1) {
        onSubmit(data);
      } else {
        nextStep();
      }
    }
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => closeModal(false)}
      title={customTitle()}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size='xl'
      radius='md'
      overflow='inside'
      transitionDuration={100}
      classNames={{
        title: 'w-full text-primary font-semibold text-18',
        modal: 'pl-10',
        body: 'scroller -mr-4 pr-2',
      }}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className={name === 'Edit' ? '-mt-2' : 'mt-4'}>
          <Stepper
            active={active}
            onStepClick={setActive}
            breakpoint='sm'
            classNames={{ steps: `mx-40 ${name === 'Edit' ? 'hidden' : ''}` }}
          >
            <Stepper.Step allowStepSelect={false}>
              <p className='font-semibold text-18 pl-4 mt-4'>
                Basic employee details
              </p>
              <div
                className='flex flex-wrap  mt-2 bg-no-repeat bg-opacity-25'
                style={{
                  backgroundImage:
                    'linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), URL(' +
                    addEmployeeBg +
                    ')',
                  backgroundSize: '60% 30%',
                  backgroundPosition: '50% 20%',
                }}
              >
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='full_name'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <TextInput
                        label='Full name*'
                        {...field}
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('full_name')}
                </div>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='email'
                    rules={{
                      required: 'required',
                      pattern: {
                        value:
                          /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/i,
                        message: 'Please enter valid work email',
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Work email*'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('email')}
                </div>
                {/* <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='mail_server_user'
                    rules={{
                      required: 'required',
                      pattern: {
                        value:
                          /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/i,
                        message: 'Please enter valid work email',
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Contact email*'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('mail_server_user')}
                </div>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    name='mail_server_password'
                    control={control}
                    rules={{ required: 'This is a required field' }}
                    render={({ field }) => (
                      <PasswordInput
                        {...field}
                        placeholder='password'
                        label='Email password'
                        size='md'
                        classNames={{
                          input:
                            'bg-transparent border-primary text-sm  2xl:text-lg 3xl:text-xl',
                          placeholder: 'text-8E8E8E text-sm ',
                          label:
                            'text-[#8E8E8E] text-sm font-normal bg-[#FDFCFC]  ',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('mail_server_password')}
                </div> */}
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='country'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Country*'
                        size='md'
                        id='country'
                        searchable
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<DownArrow className='mt-1' />}
                        data={
                          !getCountriesList?.data?.data?.length
                            ? []
                            : getCountriesList?.data?.data?.map((dept) => {
                                return {
                                  value: dept.common_name,
                                  label: dept.common_name,
                                };
                              })
                        }
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('country')}
                </div>
                <div className='w-1/2 py-2 px-4 flex justify-between'>
                  <Controller
                    control={control}
                    name='country_code'
                    // rules={{ required: 'required' }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        value={selectedCountryData?.[0]?.phone_code}
                        label='Contact*'
                        size='md'
                        classNames={{
                          input: 'border-primary w-16 px-1',
                          label: 'text-neutralsGrey text-sm',
                        }}
                        readOnly
                      />
                    )}
                  />
                  <div>
                    <Controller
                      control={control}
                      name='contact'
                      rules={{
                        required: 'required',
                        pattern: {
                          value: /^[0-9]+[0-9]*$/,
                          message: 'Only numbers are allowed',
                        },
                        // minLength: {
                        //   value: 6,
                        //   message: 'minimum of 6 digits',
                        // },
                        maxLength: {
                          value: 16,
                          message: 'maximum of 16 digits only',
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          label=' '
                          size='md'
                          classNames={{
                            input: 'border-primary w-64 mt-0.5',
                            label: 'text-neutralsGrey text-sm',
                          }}
                        />
                      )}
                    />
                    {getErrorMessage('contact')}
                  </div>
                </div>
                {/* {dynamicDefaultActiveFields?.includes('gender') ? ( */}
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='gender'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Gender* '
                        size='md'
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<DownArrow className='mt-1' />}
                        data={[
                          { value: 'M', label: 'Male' },
                          { value: 'F', label: 'Female' },
                          { value: 'O', label: 'Others' },
                        ]}
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('gender')}
                </div>
                {/* ) : null} */}
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='date_of_joining'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        label='Date of joining*'
                        size='md'
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<CalenderIcon className='w-5' />}
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                          yearPickerControlActive: 'text-white bg-primary',
                          monthPickerControlActive: 'text-white bg-primary',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('date_of_joining')}
                </div>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='department'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Department*'
                        size='md'
                        id='department'
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<DownArrow className='mt-1' />}
                        onChange={(ev) => {
                          if (watchDepartment !== ev)
                            setValue('designation', '');
                          field.onChange(ev);
                        }}
                        data={
                          !getDepartmentsForEmployee?.data?.data?.length
                            ? []
                            : getDepartmentsForEmployee?.data?.data
                                ?.filter((element) => element?.is_active)
                                ?.map((dept) => {
                                  return {
                                    value: `${dept.id}`,
                                    label: dept.name,
                                  };
                                })
                        }
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('department')}
                </div>
                {/* {dynamicDefaultActiveFields?.includes('designation') ? ( */}
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='designation'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Designation*'
                        id='designation'
                        size='md'
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<DownArrow className='mt-1' />}
                        data={
                          !getDesignations?.data?.data?.length
                            ? []
                            : getDesignations?.data?.data
                                ?.filter((element) => element?.is_active)
                                ?.map((dept) => {
                                  return {
                                    value: `${dept.id}`,
                                    label: dept.name,
                                  };
                                })
                        }
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('designation')}
                </div>
                {/* ) : null} */}
                {/* {dynamicDefaultActiveFields?.includes('employment_type') ? ( */}
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='employment_type'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Employment type*'
                        id='employment_type'
                        size='md'
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<DownArrow className='mt-1' />}
                        data={
                          !getEmployementTypes?.data?.data?.length
                            ? []
                            : getEmployementTypes?.data?.data
                                ?.filter((element) => element?.is_active)
                                ?.map((dept) => {
                                  return {
                                    value: `${dept.id}`,
                                    label: dept.name,
                                  };
                                })
                        }
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('employment_type')}
                </div>
                {/* ) : null} */}
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='employee_id'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='Employee ID*'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('employee_id')}
                </div>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='grade'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Grade*'
                        size='md'
                        clearable
                        id='grade'
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<DownArrow className='mt-1' />}
                        data={
                          !getAllGrades?.data?.data?.length
                            ? []
                            : getAllGrades?.data?.data
                                ?.filter((element) => element?.is_active)
                                ?.map((grade) => {
                                  return {
                                    value: `${grade.id}`,
                                    label: grade.name,
                                  };
                                })
                        }
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('grade')}
                </div>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='reports_to'
                    searchable={true}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Reports to'
                        size='md'
                        id='reports_to'
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<DownArrow className='mt-1' />}
                        data={
                          getReportiesList.isLoading
                            ? [{ value: null, label: 'Reports to no one' }]
                            : getCustomDropdownValues()
                        }
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                </div>
                <div className='w-1/2 py-2 px-4'>
                  <Controller
                    control={control}
                    name='has_reportees'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='This employee has immediate reportees*'
                        size='md'
                        id='has_reportees'
                        styles={{ rightSection: { pointerEvents: 'none' } }}
                        rightSection={<DownArrow className='mt-1' />}
                        data={[
                          { value: 'Yes', label: 'Yes' },
                          { value: 'No', label: 'No' },
                        ]}
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('has_reportees')}
                </div>
                <div className='pl-4 mb-6 w-full flex'>
                  <div className='w-1/2'>
                    <div className=' mt-2 flex items-center mb-2'>
                      <Controller
                        control={control}
                        name='is_under_probation'
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            defaultChecked={watch('is_under_probation')}
                            size='sm'
                            classNames={{
                              input: 'cursor-pointer',
                            }}
                          />
                        )}
                      />
                      <p className='pl-2 text-neutralsGrey '>
                        Is employee under probation period?
                      </p>
                    </div>
                    {watch('is_under_probation') ? (
                      <div className=' pr-6'>
                        <Controller
                          control={control}
                          name='probation_days'
                          rules={{
                            required: 'required',
                            pattern: {
                              value: /^[0-9]+[0-9]*$/,
                              message: 'Only numbers are allowed',
                            },
                          }}
                          render={({ field }) => (
                            <TextInput
                              label='Probation days'
                              size='md'
                              {...field}
                              classNames={{
                                input: 'border-primary',
                                label: 'text-neutralsGrey text-sm',
                              }}
                            />
                          )}
                        />
                        {getErrorMessage('probation_days')}
                      </div>
                    ) : null}
                  </div>
                  {name === 'Edit' ? (
                    <div className='pl-2'>
                      <div className=' mt-2 flex items-center mb-2'>
                        <Controller
                          control={control}
                          name='is_under_notice_period'
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              defaultChecked={watch('is_under_notice_period')}
                              size='sm'
                              classNames={{
                                input: 'cursor-pointer',
                              }}
                            />
                          )}
                        />
                        <p className='pl-2 text-neutralsGrey '>
                          Is employee under notice period?
                        </p>
                      </div>
                      {watch('is_under_notice_period') ? (
                        <div className=' pr-6'>
                          <Controller
                            control={control}
                            name='notice_period_days'
                            rules={{
                              required: 'required',
                              pattern: {
                                value: /^[0-9]+[0-9]*$/,
                                message: 'Only numbers are allowed',
                              },
                            }}
                            render={({ field }) => (
                              <TextInput
                                label='Notice period days'
                                size='md'
                                {...field}
                                classNames={{
                                  input: 'border-primary',
                                  label: 'text-neutralsGrey text-sm',
                                }}
                              />
                            )}
                          />
                          {getErrorMessage('notice_period_days')}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {/* working hours form start */}
                {name === 'Edit' ? null : (
                  <WorkingHoursForm
                    control={control}
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                    isWorkingHoursSame={isWorkingHoursSame}
                    setIsWorkingHoursSame={setIsWorkingHoursSame}
                    watch={watch}
                    getValues={getValues}
                  />
                )}
                {/* working hours form ends */}
                <div className='w-full flex justify-center'>
                  <button className={`w-32 mx-auto pt-4`}>
                    <PrimaryButton
                      isLoading={updateEmployeeMutation?.isLoading}
                    >
                      {name === 'Edit' ? 'Save' : 'Next'}
                    </PrimaryButton>
                  </button>
                </div>
              </div>
            </Stepper.Step>
            {/* <Stepper.Step allowStepSelect={false}>
              <AddEmployeeSecondStep
                control={control}
                prevStep={prevStep}
                errors={errors}
                watch={watch}
                setValue={setValue}
                setOtherAllowances={setOtherAllowances}
              />
            </Stepper.Step> */}
            <Stepper.Step allowStepSelect={false}>
              <AddEmployeeThridStep
                prevStep={prevStep}
                control={control}
                watch={watch}
                isLoading={addEmployeeMutation?.isLoading}
              />
            </Stepper.Step>
          </Stepper>
        </div>
      </form>
    </Modal>
  );
}
