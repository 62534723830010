import React, { useContext, useEffect, useState } from 'react';
import { Modal, TextInput } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker } from '@mantine/dates';
import FileUpload from 'components/fileUpload';
import SecondaryButton from 'components/secondaryButton';
import PrimaryButton from 'components/primaryButton';
import dayjs from 'dayjs';
import { AuthContext } from 'context/authContext';

import useAddProfessionalExperienceDetails from '../hooks/usePostProfessionalExperience';
import useUpdateEmployeeExperienceDetails from '../hooks/useUpdateExperienceDetails';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as CalenderIcon } from 'assets/icons/calenderIcon.svg';

export default function EditProfessionalDetailsModal({
  isModalOpen,
  setIsModalOpen,
  experienceData,
}) {
  const [iscloseModalTrue, setCloseModalTrue] = useState(false);
  const { authState } = useContext(AuthContext);

  const defaultValues = {
    organisation: '',
    role: '',
    last_drawn_salary: '',
    company_address: '',
    work_email: '',
    reporting_manager_name: '',
    reporting_manager_email: '',
    reporting_manager_contact_number: '',
    hr_manager_name: '',
    hr_manager_email: '',
    hr_manager_contact_number: '',
    old_employee_id: '',
    from_date: null,
    to_date: null,
    joining_letter: null,
    experience_letter: null,
    relieving_letter: null,
  };

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    reset,
    setError,
  } = useForm({
    defaultValues,
    mode: 'onChange',
  });

  // handle modal close
  const closeModal = () => {
    setIsModalOpen(false);
  };

  //   custom title for modal
  const customTitle = () => {
    return (
      <>
        <div className='relative flex justify-center items-center text-xl'>
          <LeftArrowIcon
            onClick={closeModal}
            className='w-4 absolute left-0 cursor-pointer'
          />
          <p>{experienceData ? 'Edit' : 'Add'} details</p>
        </div>
        <p className='text-left mt-4 text-black'>Professional experience</p>
      </>
    );
  };

  const addProfessionalExperienceMutation = useAddProfessionalExperienceDetails(
    closeModal,
    iscloseModalTrue,
    reset
  );
  const updateExperienceMutation = useUpdateEmployeeExperienceDetails(
    closeModal,
    iscloseModalTrue,
    defaultValues,
    reset
  );

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500 pt-1'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const watchFromDate = watch('from_date');
  const watchToDate = watch('to_date');

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('employee_id', authState?.employee_id);
    formData.append('organisation', data?.organisation);
    formData.append('role', data?.role);
    formData.append('old_employee_id', data?.old_employee_id);
    formData.append('last_drawn_salary', data?.last_drawn_salary);
    formData.append('company_address', data?.company_address);
    formData.append('work_email', data?.work_email);
    formData.append('reporting_manager_name', data?.reporting_manager_name);
    formData.append('reporting_manager_email', data?.reporting_manager_email);
    formData.append(
      'reporting_manager_contact_number',
      data?.reporting_manager_contact_number
    );
    formData.append('hr_manager_name', data?.hr_manager_name);
    formData.append('hr_manager_email', data?.hr_manager_email);
    formData.append(
      'hr_manager_contact_number',
      data?.hr_manager_contact_number
    );
    formData.append('from_date', dayjs(data?.from_date).format('YYYY-MM-DD'));
    formData.append('to_date', dayjs(data?.to_date).format('YYYY-MM-DD'));
    if (
      data?.joining_letter &&
      experienceData?.joining_letter !== data?.joining_letter
    ) {
      formData.append('joining_letter', data?.joining_letter?.[0]);
    }
    if (
      data?.experience_letter &&
      experienceData?.experience_letter !== data?.experience_letter
    ) {
      formData.append('experience_letter', data?.experience_letter?.[0]);
    }
    if (
      data?.relieving_letter &&
      experienceData?.relieving_letter !== data?.relieving_letter
    ) {
      formData.append('relieving_letter', data?.relieving_letter?.[0]);
    }
    if (data?.field_id) {
      formData.append('id', data?.field_id);
      updateExperienceMutation.mutate(formData);
    } else {
      addProfessionalExperienceMutation?.mutate(formData);
    }
  };

  useEffect(() => {
    if (watchFromDate && watchToDate) {
      if (dayjs(watchToDate).diff(watchFromDate, 'day') < 1) {
        setValue('to_date', null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchFromDate, watchToDate]);

  useEffect(() => {
    if (experienceData) {
      reset({
        organisation: experienceData?.organisation,
        role: experienceData?.role,
        from_date: new Date(experienceData?.from_date),
        to_date: new Date(experienceData?.to_date),
        last_drawn_salary: experienceData?.last_drawn_salary,
        company_address: experienceData?.company_address,
        work_email: experienceData?.work_email,
        reporting_manager_name: experienceData?.reporting_manager_name,
        reporting_manager_email: experienceData?.reporting_manager_email,
        reporting_manager_contact_number:
          experienceData?.reporting_manager_contact_number,
        hr_manager_name: experienceData?.hr_manager_name,
        hr_manager_email: experienceData?.hr_manager_email,
        hr_manager_contact_number: experienceData?.hr_manager_contact_number,
        old_employee_id: experienceData?.old_employee_id,
        joining_letter: experienceData?.joining_letter,
        experience_letter: experienceData?.experience_letter,
        relieving_letter: experienceData?.relieving_letter,
        field_id: experienceData?.id,
      });
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceData]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        closeOnClickOutside={false}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-8',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-wrap '>
            <div className='w-1/2 py-2 pr-4 '>
              <Controller
                control={control}
                name='organisation'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Company Name*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('organisation')}
            </div>
            <div className='w-1/2 py-2 pl-4 '>
              <Controller
                control={control}
                name='role'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Job Designation*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('role')}
            </div>
            <div className='w-1/2 py-2 pr-4 '>
              <Controller
                control={control}
                name='old_employee_id'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Employee ID*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('old_employee_id')}
            </div>
            <div className='w-1/2 py-2 pl-4 '>
              <Controller
                control={control}
                name='work_email'
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter valid email',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Work Email*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('work_email')}
            </div>
            <div className='w-1/2 py-2 pr-4 '>
              <Controller
                control={control}
                name='from_date'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label='Joining Date*'
                    size='md'
                    rightSection={<CalenderIcon className='w-5' />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                      yearPickerControlActive: 'text-white bg-primary',
                      monthPickerControlActive: 'text-white bg-primary',
                    }}
                  />
                )}
              />
              {getErrorMessage('from_date')}
            </div>
            <div className='w-1/2 py-2 pl-4 '>
              <Controller
                control={control}
                name='to_date'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label='Reliving Date*'
                    size='md'
                    minDate={dayjs(new Date(watchFromDate))
                      .add(1, 'days')
                      .toDate()}
                    rightSection={<CalenderIcon className='w-5' />}
                    styles={{ rightSection: { pointerEvents: 'none' } }}
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                      yearPickerControlActive: 'text-white bg-primary',
                      monthPickerControlActive: 'text-white bg-primary',
                    }}
                  />
                )}
              />
              {getErrorMessage('to_date')}
            </div>

            <div className='w-1/2 py-2 pr-4 '>
              <Controller
                control={control}
                name='company_address'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Company address*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('company_address')}
            </div>
            <div className='w-1/2 py-2 pl-4 '>
              <Controller
                control={control}
                name='last_drawn_salary'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Last drawn salary*'
                    size='md'
                    type='number'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('last_drawn_salary')}
            </div>
            <div className='w-1/2 py-2 pr-4 '>
              <Controller
                control={control}
                name='reporting_manager_name'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Reporting manager name*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('reporting_manager_name')}
            </div>
            <div className='w-1/2 py-2 pl-4 '>
              <Controller
                control={control}
                name='reporting_manager_email'
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter valid email',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Reporting manager email*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('reporting_manager_email')}
            </div>
            <div className='w-1/2 py-2 pr-4 '>
              <Controller
                control={control}
                name='reporting_manager_contact_number'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='Reporting manager contact number*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('reporting_manager_contact_number')}
            </div>
            <div className='w-1/2 py-2 pl-4 '>
              <Controller
                control={control}
                name='hr_manager_name'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='HR manager name*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('hr_manager_name')}
            </div>
            <div className='w-1/2 py-2 pr-4 '>
              <Controller
                control={control}
                name='hr_manager_email'
                rules={{
                  required: 'Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Please enter valid email',
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='HR manager email*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('hr_manager_email')}
            </div>
            <div className='w-1/2 py-2 pl-4 '>
              <Controller
                control={control}
                name='hr_manager_contact_number'
                rules={{
                  required: 'Required',
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label='HR manager contact number*'
                    size='md'
                    classNames={{
                      input: 'border-primary',
                      label: 'text-neutralsGrey text-sm',
                    }}
                  />
                )}
              />
              {getErrorMessage('hr_manager_contact_number')}
            </div>

            <div className='py-2 w-1/3 pr-4 mt-2'>
              <p className='pb-1.5 text-neutralsGrey text-sm'>Joining letter</p>
              <Controller
                control={control}
                name='joining_letter'
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    multiple={false}
                    setImage={onChange}
                    value={watch('joining_letter')}
                    ename={'joining_letter'}
                    setError={setError}
                  />
                )}
              />
              {getErrorMessage('joining_letter')}
            </div>
            <div className='py-2 w-1/3 px-4 mt-2'>
              <p className='pb-1.5 text-neutralsGrey text-sm'>
                Experience letter
              </p>
              <Controller
                control={control}
                name='experience_letter'
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    multiple={false}
                    setImage={onChange}
                    value={watch('experience_letter')}
                    ename={'experience_letter'}
                    setError={setError}
                  />
                )}
              />
              {getErrorMessage('experience_letter')}
            </div>
            <div className='py-2 w-1/3 pl-4 mt-2'>
              <p className='pb-1.5 text-neutralsGrey text-sm'>
                Relieving letter
              </p>
              <Controller
                control={control}
                name='relieving_letter'
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    multiple={false}
                    setImage={onChange}
                    value={watch('relieving_letter')}
                    ename={'relieving_letter'}
                    setError={setError}
                  />
                )}
              />
              {getErrorMessage('relieving_letter')}
            </div>
          </div>
          <div className='w-full flex justify-center mt-8 space-x-5'>
            <button className='w-28' onClick={() => setCloseModalTrue(true)}>
              <SecondaryButton
                isLoading={
                  (addProfessionalExperienceMutation?.isLoading ||
                    updateExperienceMutation?.isLoading) &&
                  iscloseModalTrue
                }
              >
                Save
              </SecondaryButton>
            </button>
            <button className='w-28' onClick={() => setCloseModalTrue(false)}>
              <PrimaryButton
                isLoading={
                  (addProfessionalExperienceMutation?.isLoading ||
                    updateExperienceMutation?.isLoading) &&
                  !iscloseModalTrue
                }
              >
                Add new
              </PrimaryButton>
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
