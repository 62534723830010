import { Modal, Avatar, Tooltip } from "@mantine/core";

import dayjs from "dayjs";

import { ReactComponent as MobileIcon } from "assets/icons/mobileIcon.svg";
import { ReactComponent as LocationIcon } from "assets/icons/locationIcon.svg";
import { ReactComponent as LeftArrowIcon } from "assets/icons/orangeLeftArrow.svg";
import { ReactComponent as MailIcon } from "assets/icons/mailIcon.svg";
import { ReactComponent as GradeIcon } from "assets/icons/gradeIcon.svg";
import ProfessionalExcellenceFields from "./performanceReportsFields";

// hooks
//get datas
// import UsegetskillsfromSettings from '../hooks/usegetskills';
import Usegetemployessfromteams from "../hooks/usegetemployess";
import Usegetreviewsfromsettings from "../hooks/usegetreviewtypes";
import useGetperformancereport from "../hooks/useGetperformancereports";
// import Performance from '../../myTeam/components/Performance';
import PerformanceReport from "./PerformanceReport";

const CreateAndEditEmployeeModal = ({
  reportModal,
  setReportModal,
  sendDataToNext,
  setSelectEmployeeModal,
  employeeId,
  setEmployeeId,
}) => {
  //tab skills data
  // const getTabSkillData = UsegetskillsfromSettings();

  const totalEmployess = Usegetemployessfromteams(
    sendDataToNext?.Select_employee
  );

  const performanceReportData = useGetperformancereport(employeeId);
  const reviewTypes = Usegetreviewsfromsettings(sendDataToNext?.review_type);
  const customTitle = () => {
    return (
      <div className="relative flex justify-center items-center text-xl mx-4 w-[850px] my-4">
        <LeftArrowIcon
          onClick={() => setReportModal(false)}
          className="w-4 absolute -left-72 cursor-pointer"
        />
        <p className="">Create Performance Report</p>
      </div>
    );
  };

  return (
    <Modal
      opened={reportModal}
      onClose={() => setReportModal(false)}
      title={customTitle()}
      withCloseButton={false}
      // title='Creating performance report'
      overlayColor={"#F8F7F799"}
      overlayBlur={20}
      centered
      overlayOpacity={0.5}
      transitionDuration={1}
      size={"80%"}
      overflow="inside"
      className="px-8"
      classNames={{
        title: "text-lg font-semibold  text-center mx-auto",

        body: "scroller",
      }}
    >
      <div className="bg-[#FFF8F6] rounded-md p-4 mx-4 ">
        <div className="flex  w-full ">
          <Avatar
            radius="xl"
            size="lg"
            color="#FB7C51"
            src={
              employeeId
                ? performanceReportData?.data?.data[0]
                    ?.employee__reports_to__user_employee__profile_picture
                : totalEmployess?.data?.data[0]?.profile_picture
            }
          >
            {employeeId
              ? performanceReportData?.data?.data[0]?.employee__user__first_name.substring(
                  0,
                  1
                )
              : totalEmployess?.data?.data[0]?.user__first_name.substring(0, 1)}
          </Avatar>
          <div className="w-full pl-5">
            <div className="flex justify-between items-center  w-full ">
              <h1 className="text-[#000000] font-bold text-normal">
                {employeeId
                  ? performanceReportData?.data?.data[0]
                      ?.employee__user__first_name
                  : totalEmployess?.data?.data[0]?.user__first_name}
                <span className="text-primary font-normal text-sm ml-2">
                  {
                    performanceReportData?.data?.data[0]
                      ?.employee__preferred_pronoun
                  }
                </span>
              </h1>
              <div className="flex items-center gap-2 mb-1">
                <p className="font-normal text-sm">
                  {/* {dayjs.sendDataToNext.segment[0].format('MMM YYYY')} */}
                  {employeeId ? (
                    <>
                      {`${dayjs(
                        performanceReportData?.data?.data[0]?.segment_from ||
                          null
                      ).format("DD MMM YY")} - ${dayjs(
                        performanceReportData?.data?.data[0]?.segment_to || null
                      ).format("DD MMM YY")}`}{" "}
                    </>
                  ) : (
                    <>
                      {`${dayjs(sendDataToNext?.segment[0] || null).format(
                        "DD MMM YY"
                      )} - ${dayjs(sendDataToNext?.segment[1] || null).format(
                        "DD MMM YY"
                      )}`}{" "}
                    </>
                  )}
                </p>
                <p className="text-primary font-normal text-base">
                  {employeeId
                    ? performanceReportData?.data?.data[0]?.review_type__name
                    : reviewTypes?.data?.data[0]?.name}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between  ">
              <p className="text-[#A8A8A8] font-normal text-base">
                {employeeId
                  ? performanceReportData?.data?.data[0]
                      ?.employee__designation__name
                  : totalEmployess?.data?.data[0]?.designation__name}
              </p>
              <div className=" flex  items-center gap-2">
                <p className="flex items-center text-[#828282] font-normal text-sm">
                  <MobileIcon className="mr-2 h-4 w-4" />
                  {employeeId
                    ? performanceReportData?.data?.data[0]
                        ?.employee__user__mobile_number
                    : totalEmployess?.data?.data[0]?.user__mobile_number}
                </p>
                <Tooltip
                  label={
                    employeeId
                      ? performanceReportData?.data?.data[0]
                          ?.employee__user__email
                      : totalEmployess?.data?.data[0]?.user__email
                  }
                  classNames={{
                    body: "bg-primary",
                  }}
                  radius="md"
                >
                  <p className="flex items-center text-[#828282] font-normal min-w-44 truncate text-sm">
                    <MailIcon className="mr-2 h-4 w-4" />
                    {employeeId
                      ? performanceReportData?.data?.data[0]
                          ?.employee__user__email
                      : totalEmployess?.data?.data[0]?.user__email}
                  </p>
                </Tooltip>
                <p className="flex items-center text-[#828282] font-normal text-sm">
                  <LocationIcon className="mr-2 h-4 w-4" />
                  {totalEmployess?.data?.data[0]?.department__name}
                </p>
                <p className="flex items-center text-[#828282] font-normal text-sm">
                  <GradeIcon className="mr-2 h-4 w-4" />
                  {employeeId
                    ? performanceReportData?.data?.data[0]
                        ?.employee__grade__name
                    : totalEmployess?.data?.data[0]?.grade__name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 relative mx-4">
        {/* <Tabs
              tabPadding={21}
              classNames={{
                tabsListWrapper: 'border-0',
                tabActive: 'border-2 border-primary font-semibold text-15  ',
                tabControl: 'text-[#A8A8A8] leading-5 px-0 mr-4',
                tabLabel: 'text-12  text-gray2',
              }}
            >
              {/* {getTabSkillData?.data?.data?.map((eachSkillTab) => ( */}
        {/* <Tabs.Tab label={''}>
                  {eachSkillTab.name === 'Professional Excellence' ? (
                    <ProfessionalExcellenceFields methods={methods} />
                  ) : (
                    'Coming Soon'
                  )} */}
        {/* <ProfessionalExcellenceFields methods={methods} /> */}
        {/* </Tabs.Tab> */}
        {/* </Tabs> */}
        {/* <ProfessionalExcellenceFields
          sendDataToNext={sendDataToNext}
          setReportModal={setReportModal}
          setSelectEmployeeModal={setSelectEmployeeModal}
          employeedraftreport={employeeId ? performanceReportData : null}
        /> */}
        <PerformanceReport sendDataToNext={sendDataToNext} />
        {/* <ProfessionalExcellenceFields />/ */}
        {/* <div className='absolute top-0 right-2'>
              <p className='text-[#2F80ED] text-sm'>Edit skill types</p>
            </div> */}
      </div>
    </Modal>
  );
};
export default CreateAndEditEmployeeModal;
