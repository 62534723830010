const apiEndPoints = {
  // Auth API
  // LOGIN: '/Utilities/LoginAPI/',
  LOGIN: "/Utilities/LoginAPI/",

  REGISTER_ADMIN: "/Utilities/RegisterAdminAPI/",
  REGISTER_SUPER_ADMIN: "/Utilities/RegisterSuperAdminAPI/",
  ORGANISATION_API: "/Utilities/OrganisationsAPI/",
  LIST_OF_ORGANISATION_API: "/Utilities/OrganisationsListAPI/",

  CHANGE_PASSWORD_API: "/Utilities/ChangePasswordAPI/",

  // countries API
  COUNTRIES: "/Utilities/CountriesAPI/",
  // currency codes API
  CURRENCY_CODES: "/Utilities/CurrencyCodesAPI/",

  //Registration
  REGISTRATION: "/Utilities/RegisterUserAPI/",

  //Verify the otp
  VERIFYOTP: "/Utilities/VerifyOTPAPI/",

  // SETTINGS
  SETTINGS_DEPARTMENTS: "/Utilities/DepartmentsAPI/",
  SETTINGS_REPORTING_MANAGERS_AND_EMPLOYEES:
    "/Utilities/DepartmentsWithEmployeesAPI/",
  SETTINGS_EMPLOYEE_DETAILS_ADMIN: "/Utilities/EmployeeAdminFieldsAPI/",
  SETTINGS_EMPLOYEE_PERSONAL_DETAILS: "/Utilities/EmployeePersonalFieldsAPI/",
  SETTINGS_EMPLOYMENT_TYPES: "/Utilities/EmployementTypesAPI/",
  SETTINGS_SKILL_TYPES: "/Utilities/SkillTypesAPI/",
  SETTINGS_REVIEW_TYPES: "/Utilities/ReviewTypesAPI/",
  SETTINGS_APPRAISAL_TYPES: "/Utilities/AppraisalTypesAPI/",
  SETTINGS_GRADES: "/Utilities/GradesAPI/",
  SETTINGS_SALARY_RANGE: "/Utilities/SalaryRangeAPI/",

  FORGOT_PASSWORD: "/Utilities/ForgotPasswordAPI/",

  //SEND Otp
  SENDOTP: "/Utilities/SendOTPAPI/",

  //leaves
  LEAVEMODULE: "/Calender/LeaveModulesAPI/",
  //Leave Types
  LEAVETYPES_API: "/Calender/LeaveTypesAPI/",

  GRADES_API: "/Utilities/GradesAPI/",

  EMPLOYEE_ADMIN_FIELDS: "/Utilities/EmployeeAdminFieldsAPI/",
  TEAM_MEMBERS_API: "/Employees/EmployeesAPI/",
  LIST_OF_EMPLOYEES: "/Employees/GeneralListOfEmps/",
  DEPARTMENTS_FOR_EMPLOYEE: "/Utilities/DepartmentsAPI/",

  EMPLOYEMENT_TYPE: "/Utilities/EmployementTypesAPI/",
  // Company Details
  COMPANY_DETAILS: "/Utilities/CompanyDetailsAPI/",
  WORKING_HOURS_API: "/Employees/EmployeesWDTAPI/",

  // Payroll CRUD
  PAYROLL: "/Organisation/PayRollAPI/",
  // EmployeesPayRollAPI
  EMPLOYEE_PAYROLL_API: "/Organisation/EmployeesPayRollAPI/",
  //Approve Payroll
  APPROVE_PAYROLL_API: "/Organisation/ApprovePayRollAPI/",

  // employee APIs
  EMPLOYEE_PERSONAL_INFO_API: "/Employees/EmployeesPersonalAPI/",
  BULK_UPLOAD_EMPLOYEE_API: "/Employees/BulkUploadEmployeesAPI/",
  EMPLOYEE_BANK_DETAILS: "/Employees/BankDetailsAPI/",
  EMPLOYEE_LEAVES_API: "/Employees/EmployeeLeavesAPI/",
  EMPLOYEE_APPLIED_LEAVES: "/Employees/EmpAppliedLeavesAPI/",
  EMPLOYEE_EDUCATION_API: "/Employees/EducationDetailsAPI/",
  EMPLOYEE_PROFESSIONAL_EXPERIENCE: "/Employees/ProfExpDetailsAPI/",
  REPORTERS_AVAILABLE: "/Employees/ReportsListAPI/",
  EMPLOYEE_FAMILY_DETAILS_API: "/Employees/FamilyDetailsAPI/",
  EMPLOYEE_EMERGENCY_CONTACT_API: "/Employees/EmergencyDetailsAPI/",
  EMPLOYEE_MEDICAL_DETAILS_API: "/Employees/MedicalDetailsAPI/",

  EMPLOYEE_DESIGNATIONS: "/Utilities/DesignationsAPI/",

  EMPLOYEE_BREAKUP_API: "/Employees/SalaryBreakupAPI/",

  EMPLOYEE_SALARY_DETAILS: "/Employees/EmployeesSalaryAPI/",

  EMPLOYEES_LEAVES_EXCEL: "/Employees/LeavesExcelAPI/",

  ADMIN_PROFILE: "/Utilities/AdminUserAPI/",

  CHANGE_SUPER_ADMIN: "/Utilities/ChangeSuperAdminAPI/",

  // employee dashboard leaves data
  EMPLOYEE_DASHBOARD_LEAVES: "/Dashboard/DashLeavesAPI/",

  // Holiday Types
  HOLIDAY_TYPES_API: "/Utilities/HolidayTypesAPI/",

  // Calender
  CALENDAR_EVENTS: "/Calender/EventsAPI/",

  //salary component
  SALARY_COMPONENTS: "/Organisation/SalaryComponentsAPI/",

  // edit single salary component
  COMPONENTSDISTRIBUTIONAPI: "/Organisation/ComponentsDistributionAPI/",

  // Upcoming Events which we are showing in the Admin Dashboard
  DASHBOARD_UPCOMING_EVENTS: "/Dashboard/DashUpcomingEventsAPI/",

  // Team members on leave and who are going on leave
  DASHBOARD_TEAM_MEMBERS_ON_LEAVE: "/Dashboard/DashTeamMemLeavesAPI/",

  //Policies CRUD
  POLICIES_API: "/Organisation/PolicyAdminAPI/",
  //List of employees in a policy
  EMPLOYEE_LIST_IN_POLICY: "/Organisation/PolicySignaturesAdminAPI/",

  // Organisation settings
  ORGANISATION_DEPARTMENTS: "/Utilities/OrganisationsDepartmentsAPI/",
  ORGANISATIONS_LIST: "/Utilities/OrganisationsListAPI/",
  ORGANISATIONS_POLICY_LIST: "/Utilities/OrganisationsPoliciesAPI/",

  // employee policies
  EMPLOYEE_PLCICIES: "/Organisation/PolicyEmployeeAPI/",
  // Admin Dashboard - Activity Tracker
  DASHBOARD_ACTIVITY_TRACKER: "/Dashboard/ActivityTrackerAPI/",

  // User - Employee Payslips
  EMPLOYEE_PAYSLIPS: "/Employees/EmployeePaySlipsAPI/",

  // Organisation Chart
  ORGANISATION_CHART: "/Organisation/OrganisationChartAPI/",

  //Admin Blog
  ADMIN_BLOG: "/Blogs/AdminBlogsAPI/",

  //Employee Blog API
  EMPLOYEE_BLOG_API: "/Blogs/EmployeeBlogsAPI/",

  //Apparisal
  APPARISAL: "/Employees/AppraisalReportAPI/",

  //performance
  PERFORMANCE_BY_ADMIN: "/Employees/PerformanceReportAPI/",
  //prtformance_objective_reating
  PERFORMANCE_OBJECTIVE_RATING: "/Employees/ObjectiveRatingAPI/",
  //Behavioural Ratings
  PERFORMANCE_BEHAVIOURAL_RATING: "/Employees/BehaviouralRatingAPI/",
  //Kra_goals
  KRA_GOALS: "/Employees/KRAGoalsAPI/",

  //Domain_Api
  DOMAIN_API: "/Utilities/CompanyDomainsAPI/",

  // Publish Blog
  PUBLISH_BLOG: "/Blogs/AdminPublishBlogAPI/",

  //performance update api
  PERFORMANCE_UPDATE_API: "/Employees/PerReportUpdateAPI/",

  // get and update permision api

  TEAMS_PERMISIONS_API: "/Employees/EmployeesPermissionsAPI/",

  // Repository folder curd API
  REPOSITORY_CURD_API: "/Repository/FoldersAPI/",

  // Repository Directory Api
  REPOSITORY_Directory_API: "/Repository/DirectoryAPI/",

  //Repository copy and move api
  REPOSITORY_COPY_AND_MOVE_API: "/Repository/DirMovCopyAPI/",

  // Repository add and delete employess in put request
  REPOSITORY_ADD_AND_DELETE_EMPLOYESS_API: "/Repository/FolderMembersAPI/",
  // projects APIS
  PROJECT_API: "/Projects/ProjectsAPI/",
  PROJECT_MEMBERS_API: "/Projects/ProjectMembersAPI/",
  PROJECT_TASK_API: "/Projects/TasksAPI/",

  TASKBOARD_API: "/Projects/TaskBoardAPI/",

  TASK_MEMBERS: "/Projects/TaskMembersAPI/",

  TASK_CHECKLIST: "/Projects/TaskChecklistAPI/",
  TASK_COMMENTS: "/Projects/TaskCommentsAPI/",
  TASK_ATTACHMENTS: "/Projects/TaskAttachmentsAPI/",
  TASKS_CHARTS: "/Projects/TaskBoardChartsAPI/",

  TASKBOARD_MEMBERS: "/Projects/TaskBoardMembersAPI/",
  BUCKETS_API: "/Projects/BucketsAPI/",
  DRAG_AND_DROP_API: "/Projects/TasksDnDAPI/",

  LISTOF_PROJECTS: "/Projects/ListOfProjectsAPI/",

  // employee dashboard today's tasks

  TODAYS_TASKS_API: "/Dashboard/TodaysTasksAPI/",

  //my Skills API
  MY_SKILLS_API: "/Employees/EmployeeSkillsAPI/",
  //Skill Development API
  SKILL_DEVELOPMENT_API: "/Employees/EmployeeSkillDevAPI/",
  //Skill Target API

  SKILL_TARGET_API: "/Employees/EmployeeSkillTargetAPI/",

  // chat API's

  CHAT_MORE_MESSAGES: "/api/messages/",
  UPLOAD_FILES: "Chat/api/files",
  SEARCH_USERS: "/Utilities/conversation-list/",
  CREATE_GROUP: "/Chat/create-room/",
  UPDATE_GROUP: "/Chat/update-room/",
  USERS_LIST: "/Utilities/get-users/",
GET_UNREADMESSAGESCOUNT:"/Chat/unread_messages/",
  // websocket endpoints
  ONE_ON_ONE_CONVERSATION: "/ws/chat/",
  GROUP_CONVERSATION: "/ws/groupchat/",
  CONVERSATIONS_LIST: "/ws/conversations/",
  USER_STATUS: "/ws/userstatus/",

  // mails api
  GET_MAILS: "/Mails/FetchMailsAPI/",
  SEND_MAILS: "/Mails/SendMailAPI/",
  UPDATE_MAIL_STATUS: "/Mails/UpdateMailstatus/",
  GET_INBOX_MAILS: "/Mails/FetchMailsAPI/",
  MAILS_FOLDER: "/Mails/MailFoldersAPI/",
  SEARCH_MAILS: "/Mails/SearchMailsAPI/",

  //meetings api
  GET_MEETING_INFO: "/Calender/JoinMeetingAPI/",
};

export default apiEndPoints;
