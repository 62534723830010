import React, { useState } from 'react';
import { ReactComponent as DotsMenuIcon } from 'assets/icons/dotsMenu.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';
import useUpdateActivity from '../hooks/useUpdateActivity';
import { Checkbox, Popover } from '@mantine/core';
import useDeleteActivity from '../hooks/useDeleteActivity';

export default function ActivityItem({ activity }) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const updateActivityMutation = useUpdateActivity();
  const deleteActivityMutation = useDeleteActivity();

  return (
    <div key={activity?.id} className='flex items-start justify-between mr-8 '>
      <Checkbox className='flex items-start'
        label={activity?.task}
        checked={activity?.is_completed}
        onChange={() => {
          updateActivityMutation.mutate({
            id: activity?.id,
            is_completed: !activity?.is_completed,
          });
        }}
        classNames={{
          input: 'border-primary border-2 ',
          label: `text-[#9A9A9A] text-base  ${
            activity?.is_completed && 'line-through'
          }`,
        }}
      />

      <Popover
        opened={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        target={
          <DotsMenuIcon
            className='cursor-pointer'
            onClick={() => setPopoverOpen(true)}
          />
        }
        width={220}
        withArrow
      >
        <div>
          <button
            className='flex items-center space-x-2 w-full'
            onClick={() => deleteActivityMutation.mutate({ id: activity?.id })}
          >
            <DeleteIcon />
            <p>Delete</p>
          </button>
        </div>
      </Popover>
    </div>
  );
}
