import React, { useContext, useEffect } from 'react';
import { Checkbox, Modal, Select, TextInput } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';

import useAddMedicalDetails from '../hooks/useAddMedicalDetails';
import useUpdateEmployeeMedicalDetails from '../hooks/useUpdateMedicalDetails';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { AuthContext } from 'context/authContext';

export default function EditMedicalDetailsModal({
  isModalOpen,
  setIsModalOpen,
  medicalDetails,
}) {
  const { authState } = useContext(AuthContext);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //   custom title for modal
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>Edit details</p>
      </div>
    );
  };

  //   default values for form
  const defaultValues = {
    blood_group: '',
    physical_impairment: false,
    critical_medical_issues: false,
    allergies: false,
    physical_impairment_details: '',
    critical_medical_issues_details: '',
    allergies_details: '',
    family_doctor_name: '',
    family_doctor_number: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    // getValues,
    reset,
  } = useForm({ defaultValues });

  // error message
  const getErrorMessage = (name) => {
    return (
      errors[name] && (
        <p className=' text-sm font-semibold text-red-500'>
          {errors[name]?.message}
        </p>
      )
    );
  };

  const addMedicalDetailsMutation = useAddMedicalDetails(closeModal, reset);
  const updateMedicalDetailsMutation =
    useUpdateEmployeeMedicalDetails(closeModal);

  const onSubmit = (data) => {
    if (data?.id) {
      updateMedicalDetailsMutation.mutate({
        ...data,
        employee_id: authState?.employee_id,
      });
    } else {
      addMedicalDetailsMutation.mutate({
        ...data,
        employee_id: authState?.employee_id,
      });
    }
  };

  useEffect(() => {
    if (medicalDetails) {
      reset({
        blood_group: medicalDetails?.blood_group,
        physical_impairment: medicalDetails?.physical_impairment,
        critical_medical_issues: medicalDetails?.critical_medical_issues,
        allergies: medicalDetails?.allergies,
        physical_impairment_details:
          medicalDetails?.physical_impairment_details,
        critical_medical_issues_details:
          medicalDetails?.critical_medical_issues_details,
        allergies_details: medicalDetails?.allergies_details,
        family_doctor_name: medicalDetails?.family_doctor_name,
        family_doctor_number: medicalDetails?.family_doctor_number,
        id: medicalDetails?.id,
      });
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalDetails]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18 text-center',
          modal: 'pl-10',
          body: 'scroller -mr-4 pr-2',
        }}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-wrap'>
              <div className='w-full py-2 px-4'>
                <Controller
                  control={control}
                  name='blood_group'
                  rules={{
                    required: 'required',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label='Blood  group* '
                      size='md'
                      styles={{ rightSection: { pointerEvents: 'none' } }}
                      rightSection={<DownArrow className='mt-1' />}
                      data={[
                        { value: 'A+ve', label: 'A+ve' },
                        { value: 'A-ve', label: 'A-ve' },
                        { value: 'B+ve', label: 'B+ve' },
                        { value: 'B-ve', label: 'B-ve' },
                        { value: 'O+ve', label: 'O+ve' },
                        { value: 'O-ve', label: 'O-ve' },
                        { value: 'AB+ve', label: 'AB+ve' },
                        { value: 'AB-ve', label: 'AB-ve' },
                      ]}
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('blood_group')}
              </div>
              <div className='w-full flex pt-4'>
                <div className='w-1/3 py-2 px-4'>
                  <Controller
                    control={control}
                    name='physical_impairment'
                    render={({ field }) => (
                      <Checkbox
                        size='sm'
                        {...field}
                        checked={watch('physical_impairment')}
                        label='Physical impairment'
                        classNames={{
                          input: 'cursor-pointer',
                          label: 'text-neutralsGrey',
                        }}
                      />
                    )}
                  />
                </div>
                <div className='w-1/3 py-2 px-4'>
                  <Controller
                    control={control}
                    name='critical_medical_issues'
                    render={({ field }) => (
                      <Checkbox
                        size='sm'
                        {...field}
                        checked={watch('critical_medical_issues')}
                        label='Critical medical issues'
                        classNames={{
                          input: 'cursor-pointer',
                          label: 'text-neutralsGrey',
                        }}
                      />
                    )}
                  />
                </div>
                <div className='w-1/3 py-2 px-4'>
                  <Controller
                    control={control}
                    name='allergies'
                    render={({ field }) => (
                      <Checkbox
                        size='sm'
                        {...field}
                        checked={watch('allergies')}
                        label='Allergies'
                        classNames={{
                          input: 'cursor-pointer',
                          label: 'text-neutralsGrey',
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              {watch('physical_impairment') ? (
                <div className='w-full px-4'>
                  <Controller
                    control={control}
                    name='physical_impairment_details'
                    rules={{
                      required: 'required',
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='If yes please specify (Physical impairment issues)*'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('physical_impairment_details')}
                </div>
              ) : null}
              {watch('critical_medical_issues') ? (
                <div className='w-full px-4 py-2'>
                  <Controller
                    control={control}
                    name='critical_medical_issues_details'
                    rules={{
                      required: 'required',
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='If yes please specify (Critical medical issues)*'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('critical_medical_issues_details')}
                </div>
              ) : null}
              {watch('allergies') ? (
                <div className='w-full px-4'>
                  <Controller
                    control={control}
                    name='allergies_details'
                    rules={{
                      required: 'required',
                    }}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        label='If yes please specify (Allergies details)*'
                        size='md'
                        classNames={{
                          input: 'border-primary',
                          label: 'text-neutralsGrey text-sm',
                        }}
                      />
                    )}
                  />
                  {getErrorMessage('allergies_details')}
                </div>
              ) : null}
              <div className='w-1/2 py-5 px-4'>
                <Controller
                  control={control}
                  name='family_doctor_name'
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Family doctor name'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
              </div>
              <div className='w-1/2 py-5 px-4'>
                <Controller
                  control={control}
                  name='family_doctor_number'
                  rules={{
                    pattern: {
                      value: /^[0-9]+[0-9]*$/,
                      message: 'Only numbers are allowed',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label='Contact'
                      size='md'
                      classNames={{
                        input: 'border-primary',
                        label: 'text-neutralsGrey text-sm',
                      }}
                    />
                  )}
                />
                {getErrorMessage('family_doctor_number')}
              </div>
              <div className='flex justify-center w-full mt-3'>
                <button className='w-28'>
                  <PrimaryButton
                    isLoading={
                      updateMedicalDetailsMutation?.isLoading ||
                      addMedicalDetailsMutation?.isLoading
                    }
                  >
                    Save
                  </PrimaryButton>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
