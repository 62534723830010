import React from 'react';
import { Avatar } from '@mantine/core';
import dateFormatter from 'helper/dateFormatter';
import dayjs from 'dayjs';

export default function EventCard({ event, eventType }) {
  const today = dateFormatter(new Date(), 'YYYY-MM-DD');
  const thisWeek = dateFormatter(
    dayjs(new Date()).add(7, 'days').$d,
    'YYYY-MM-DD'
  );

  const convertDate = (eventDate) => {
    return dayjs(eventDate).add(
      Math.abs(dayjs(eventDate).diff(new Date(), 'year')) + 1,
      'year'
    ).$d;
  };

  const checkUpcomingOrNot = (eventDate) => {
    return eventDate >= today && eventDate <= thisWeek;
  };

  const checkEventDays = (fromDate, toDate) => {
    if (fromDate === toDate) {
      return dateFormatter(fromDate, 'DD MMM YY');
    }
    return `${dateFormatter(fromDate, 'DD MMM YY')} - ${dateFormatter(
      toDate,
      'DD MMMM YYYY'
    )}`;
  };

  if (eventType === 'Rookies' || eventType === 'Birthdays') {
    return (
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <Avatar
            src={
              event?.profile_picture ||
              event?.birthday_employee__profile_picture
            }
            color={'red'}
            className='capitalize'
            radius={'xl'}
            size={64}
          >
            {event?.user__first_name?.[0] ||
              event?.birthday_employee__user__first_name?.[0]}
          </Avatar>

          <div className='ml-5'>
            <p className='capitalize text-18 text-[#707070]'>
               {event?.user__first_name ||
                event?.birthday_employee__user__first_name} 
            </p>

            {eventType === 'Rookies' ? (
              <p className='text-[#969696]'>
                 {event?.designation__name}, {event?.department__name},
                {event?.grade__name} 
                
              </p>
            ) : (
              <p className='text-[#969696]'>
                 {event?.birthday_employee__designation__name},
                 {event?.birthday_employee__department__name},
                {event?.birthday_employee__grade__name}  
              </p>
            )}
            <p></p>
          </div>
        </div>

        <div className='text-right text-[#969696]'>
          <p className='text-[#28B446]'>
            {(checkUpcomingOrNot(event?.date_of_joining) ||
              checkUpcomingOrNot(
                dateFormatter(convertDate(event?.for_date), 'YYYY-MM-DD')
              ))}
          </p>
          <p>
            {eventType === 'Rookies'
              ? dateFormatter(event?.date_of_joining, 'DD MMM YY')
              : dateFormatter(convertDate(event?.for_date), 'DD MMM YY')}
          </p>
        </div>
      </div>
    );
  }

  if (eventType === 'Holidays' || eventType === 'Events') {
    return (
      <div className='flex  items-center justify-between'>
        <div className='flex items-center'>
          <Avatar color={'red'} className='capitalize' radius={'xl'} size={64}>
            {event?.name?.[0]}
          </Avatar>

          <div className='ml-5'>
            <p className='capitalize text-18 text-[#707070]'>{event?.name}</p>
            <p className='text-[#969696]'>
              {checkEventDays(event?.for_date, event?.to_date)}
            </p>
            <p className='text-[#787878] text-sm'>
            {
              // Displaying time on events
              event?.all_day_event||event?.is_holiday?"All Day":`${dayjs("2019-01-25"+event?.from_time).format("h:mm A")}-${dayjs("2019-01-25"+event.to_time).format("h:mm A")}`
            }
          </p>
          </div>
        </div>

        <div className=''>
          <p className='text-[#28B446]'>
            {today === event?.for_date ||
            today === event?.to_date ||
            (today >= event?.for_date && today <= event?.to_date)
              ? 'Today'
              : checkUpcomingOrNot(event?.for_date) && 'Upcoming'}
          </p>
          {eventType === 'Events' && (
            <p className='text-[#969696]'>Mandatory</p>
          )}
        </div>
      </div>
    );
  }
}
