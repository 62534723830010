import { Avatar, Table } from '@mantine/core';

// import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import SecondaryButton from 'components/secondaryButton';
import { useState } from 'react';
import EmployeePerformanceModal from './employeePerformanceModal';

import { ReactComponent as NoPerformanceReports } from 'assets/icons/noPerfromanceReports.svg';

import useGetperformancereportemployee from '../hooks/useGetperformancereports';
import useUpdateperformancereportseenstatus from '../hooks/useUpdatepermormancereportseenStatus';
// import { useContext } from 'react';
// import { AuthContext } from 'context/authContext';
import dayjs from 'dayjs';

const EmployeePerformanceTable = ({
  performanceYearFilter,
  performanceSearch,
  employee_id
}) => {
  // const { authState } = useContext(AuthContext);
  // const { employee_id } = authState;
  const performanceReports = useGetperformancereportemployee({
    employeeId: employee_id,

    performanceYearFilter,
    performanceSearch,
  });

  const updateSeenStatusMutation = useUpdateperformancereportseenstatus();

  const [employeePerformancemodalOpened, setEmployeePerformancemodalOpened] =
    useState(false);

  const [specificPerformanceReport, setSpecificPerformanceReport] =
    useState(null);
  const rows = performanceReports?.data?.data.map((eachReport) => (
    <>
      <tr>
        <td className='text-center text-[#848383] text-base'>
          {eachReport.review_type__name}
        </td>
        <td className='text-center text-[#848383] text-base'>
          {`${dayjs(eachReport?.segment_from).format('MMM YYYY')} - ${dayjs(
            eachReport?.segment_to
          ).format('MMM YYYY')}`}
        </td>
        <td>
          <div className='flex justify-center items-center'>
            <Avatar
              radius='xl'
              color='#FB7C51'
              src={eachReport?.created_by__user_employee__profile_picture}
            >
              {eachReport?.created_by__first_name?.substring(0, 1)}
            </Avatar>
            <div className='pl-3 text-[#848383] '>
              <p className='text-base'>
                {eachReport?.created_by__first_name || `Admin`}
              </p>
              <p className='text-normal'>
                {' '}
                {eachReport?.created_by__user_employee__designation__name ||
                  'Admin'}
              </p>
            </div>
          </div>
        </td>
        <td className='text-center text-[#848383] text-lg'>{`${dayjs(
          eachReport?.created_date
        ).format('DD MMM YYYY')}`}</td>

        <td className='text-center text-[#848383] text-base'>
          {eachReport?.overall_awarded}/ 100
        </td>
        <td>
          <div className='flex items-center justify-evenly'>
            <button
              onClick={() => {
                setEmployeePerformancemodalOpened(true);
                setSpecificPerformanceReport(eachReport);
                if (eachReport?.is_seen === false) {
                  updateSeenStatusMutation.mutate({
                    id: eachReport?.id,
                    is_seen: !eachReport?.is_seen,
                  });
                }
              }}
            >
              <SecondaryButton>View</SecondaryButton>
            </button>
          </div>
        </td>
      </tr>
    </>
  ));
  return (
    <>
      <div className='mt-5 bg-[#ffffff] min-h-[80vh] rounded-2xl shadow-3xl'>
        {performanceReports?.data?.data.length === 0 ? (
          <div className='flex justify-center items-center  flex-col bg-[#ffffff] min-h-[70vh] rounded-2xl shadow-3xl'>
            <NoPerformanceReports />
            <p className='mt-2 text-[#535353]'>
              Seems like you have no performance reports yet!
            </p>
          </div>
        ) : (
          <Table verticalSpacing='lg' highlightOnHover>
            <thead>
              <tr>
                <th
                  className='bg-[#FFE8E1] rounded-tl-xl '
                  style={{ textAlign: 'center' }}
                >
                  Review type
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Review period
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Created by
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Created on
                </th>
                <th className='bg-[#FFE8E1]' style={{ textAlign: 'center' }}>
                  Overall score
                </th>

                <th
                  className='bg-[#FFE8E1] rounded-tr-xl'
                  style={{ textAlign: 'center' }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className=' w-full max-h-20 border-none'>{rows}</tbody>
          </Table>
        )}
      </div>
      <EmployeePerformanceModal
        employeePerformancemodalOpened={employeePerformancemodalOpened}
        setEmployeePerformancemodalOpened={setEmployeePerformancemodalOpened}
        setSpecificPerformanceReport={setSpecificPerformanceReport}
        specificPerformanceReport={specificPerformanceReport}
      />
    </>
  );
};

export default EmployeePerformanceTable;
