import React, { useState } from 'react';

import AddDesignationModal from '../components/addDesignationModal';
import useGetDesignations from '../hooks/useGetDesignations';
// import useGetAllDepartments from "../hooks/useGetAllDepartments";
import PrimaryButton from 'components/primaryButton';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as NoDepartmentsImg } from 'assets/images/NoDepartments.svg';

export default function Designations() {
  const [isAddDesignationModalOpen, setDesignationModalOpen] = useState(false);
  const [isEditData, setEditData] = useState(null);
  const getEmployeeDesigantions = useGetDesignations();
  //   const getAllDepartments = useGetAllDepartments();

  //   const getDepartmentData = (id) => {
  //     let name = "";
  //     getAllDepartments?.data?.data?.map((item) => {
  //       if (item?.id === id) {
  //         name = item?.name;
  //       }
  //       return true;
  //     });
  //     return name;
  //   };

  return (
    <div className='px-6'>
      {/* employee designations */}
      {getEmployeeDesigantions?.data?.data?.length ? (
        <div>
          <div className='text-base h-14 flex justify-between items-center bg-[#FFE8E1] py-8 px-6 rounded-lg'>
            <div className='flex w-8/12 text-primary font-semibold'>
              <p className='w-2/6 pl-4'>Department</p>
              <p className='w-2/6'>Designations</p>
            </div>
            <div>
              <button
                onClick={() => setDesignationModalOpen(true)}
                className='w-36 whitespace-nowrap'
              >
                <PrimaryButton>
                  <p className='whitespace-nowrap text-base'>
                    + Add designation
                  </p>
                </PrimaryButton>
              </button>
            </div>
          </div>
          {getEmployeeDesigantions?.data?.data?.map((item) => {
            return (
              <div
                className='flex justify-between my-6 px-6 pl-10'
                key={item?.id}
              >
                <div className='flex items-center w-8/12'>
                  <p className='text-gray w-2/6'>{item?.department__name}</p>
                  <p className='text-gray w-2/6'>{item?.name}</p>
                </div>
                <div className='flex items-center mr-4'>
                  <EditIcon
                    onClick={() => {
                      setEditData(item);
                      setDesignationModalOpen(true);
                    }}
                    className='cursor-pointer'
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='bg-white h-96 flex flex-col items-center justify-center space-y-10 rounded-3xl'>
          <div>
            <NoDepartmentsImg />
            <p className='text-center mt-2'>No data to show</p>
          </div>
          <button onClick={() => setDesignationModalOpen(true)}>
            <PrimaryButton>
              <p>+ Add designation</p>
            </PrimaryButton>
          </button>
        </div>
      )}

      <AddDesignationModal
        isModalOpen={isAddDesignationModalOpen}
        setIsModalOpen={setDesignationModalOpen}
        editData={isEditData}
        setEditData={setEditData}
      />
    </div>
  );
}
