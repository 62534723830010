import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetTodaysTasks() {
  return useQuery(['employee-dashbaord-todays-tasks'], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.TODAYS_TASKS_API, {})
    );
    return response?.data;
  });
}
