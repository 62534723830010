import { Modal } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';

import { useForm } from 'react-hook-form';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as CalanderIcon } from 'assets/icons/calanderIcon.svg';

import PrimaryButton from 'components/primaryButton';

import UseAddLeaveModule from '../hooks/useaddleavemodule';

const AddAndEditModuleModal = ({ setLeavemodule, leavemodule }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[550px]'>
        <LeftArrowIcon
          onClick={() => setLeavemodule(false)}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className='text-primary'> Creating leave module</p>
      </div>
    );
  };

  const closeleavemodule = () => {
    setLeavemodule(false);
  };
  const addLeaveModuleMutation = UseAddLeaveModule(closeleavemodule);

  const submitDate = (data) => {
    const fromDate = data.date.slice(0, 10);
    const toDate = data.date.slice(13);
    addLeaveModuleMutation.mutate({ from_date: fromDate, to_date: toDate });
  };

  return (
    <div>
      <Modal
        opened={leavemodule}
        onClose={closeleavemodule}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size='lg'
        overflow='inside'
        className='px-8'
        classNames={{
          title: 'text-lg font-semibold ',

          body: 'scroller',
        }}
      >
        <form
          className='flex justify-center flex-col mt-5'
          onSubmit={handleSubmit(submitDate)}
        >
          <div>
            <DateRangePicker
              {...register('date', { required: 'Required' })}
              label='Select financial year'
              className='w-96 mx-auto'
              inputFormat='YYYY-MM-DD'
              dropdownType='modal'
              // dropdownType='popover'
              classNames={{
                input:
                  'h-[50px] text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5',
                label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                yearPickerControlActive: 'text-white bg-primary',
                monthPickerControlActive: 'text-white bg-primary',
              }}
              rightSection={<CalanderIcon />}
              styles={{ rightSection: { pointerEvents: 'none' } }}
            />
            {errors.date && (
              <p className='text-sm text-[#fc0101] ml-[110px]'>
                {errors.date.message}
              </p>
            )}
          </div>
          <button className='mt-5 mb-5  mx-auto '>
            <PrimaryButton>Save</PrimaryButton>
          </button>
        </form>
      </Modal>
    </div>
  );
};
export default AddAndEditModuleModal;
