import { Modal, Tooltip } from '@mantine/core';
// import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';

import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { ReactComponent as DownArrow } from 'assets/icons/downloadcircleIcon.svg';
import { ReactComponent as ZoomIn } from 'assets/icons/zoominIcon.svg';
import { ReactComponent as Zoomout } from 'assets/icons/zoomoutIcon.svg';

import { saveAs } from 'file-saver';

const PreviewForPDF = ({
  previewPdfModal,
  setPreviewPdfModal,
  file,
  name,
  setFile,
  setCourseName,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomValue, setZoomValue] = useState(12);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  return (
    <div className=''>
      <Modal
        opened={previewPdfModal}
        onClose={() => {
          setPreviewPdfModal(false);
          setZoomValue(12);
          setFile(null);
          setCourseName(null);
        }}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size={'80%'}
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full font-bold  text-lg py-4  text-center text-primary',
          modal: 'pl-10 h-[90vh]',
          body: 'scroller -mr-4 pr-8 h-[85vh]',
        }}
      >
        <div className='flex justify-center items-center relative'>
          <div className='w-[100%] flex flex-col justify-center items-center'>
            <div className=''>
              {/* <div className=''>
              <Document
                file={{
                  url: `${file}`,
                }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div> */}
              <div className='flex justify-center items-center'>
                <Document
                  file={{
                    url: `${file}`,
                  }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {/* {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      scale={zoomValue / 10}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  ))} */}
                  <Page
                    pageNumber={pageNumber}
                    scale={zoomValue / 10}
                    // key={`page_${index + 1}`}
                  />
                </Document>
              </div>
              <nav>
                <div className='gap-4 flex justify-between'>
                  <button
                    onClick={goToPrevPage}
                    className={
                      pageNumber === 1 ? 'opacity-50 cursor-not-allowed' : ''
                    }
                  >
                    <SecondaryButton>Previous Page</SecondaryButton>
                  </button>
                  <button
                    onClick={goToNextPage}
                    className={
                      pageNumber === numPages
                        ? 'opacity-50 cursor-not-allowed -mr-20'
                        : '-mr-20'
                    }
                  >
                    {' '}
                    <SecondaryButton>Next Page</SecondaryButton>
                  </button>
                </div>
                <div className='gap-4 flex justify-between mt-4 mb-8'>
                  <p className='self-end'>
                    Page {pageNumber} of {numPages}
                  </p>
                  <Tooltip
                    label='Download'
                    classNames={{
                      body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                    }}
                    radius='md'
                  >
                    <DownArrow
                      className='w-7 h-7 cursor-pointer -mr-16'
                      onClick={() => saveAs(file, name)}
                    />
                  </Tooltip>
                </div>
              </nav>
            </div>
          </div>{' '}
          <div className='flex flex-col gap-5  justify-end item-center my-auto w-20'>
            <Tooltip
              label={'ZoomIn'}
              // width={220}
              wrapLines
              classNames={{
                body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
              }}
              radius='md'
            >
              <button
                onClick={() => {
                  if (zoomValue >= 9 && zoomValue < 15) {
                    setZoomValue(zoomValue + 1);
                  }
                }}
              >
                <Zoomout className='w-7 h-7 hover:w-9 hover:h-9' />
              </button>
            </Tooltip>
            <Tooltip
              label={'ZoomOut'}
              // width={220}
              wrapLines
              classNames={{
                body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
              }}
              radius='md'
            >
              <button
                onClick={() => {
                  if (zoomValue > 9 && zoomValue <= 15) {
                    setZoomValue(zoomValue - 1);
                  }
                }}
              >
                <ZoomIn className='w-7 h-7 hover:w-9 hover:h-9' />
              </button>
            </Tooltip>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PreviewForPDF;
