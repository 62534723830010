import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useCompanyDetails(id) {
  return useQuery(['company-details-specific', id], async () => {
    const response = await makeApiRequest.get(`${apiEndPoints.COMPANY_DETAILS}?company_id=${id}`);
    return response?.data;
  });
}
