import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function useGetperformancereportemployee({ employeeId, id,performanceYearFilter,
  performanceSearch }) {
  return useQuery(
    ['performance-report-in-employee', employeeId, id ,performanceYearFilter,
  performanceSearch],
    async () => {
      const response = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.PERFORMANCE_BY_ADMIN, {
          employee_id: employeeId,
          is_draft:'False',
          id: id,
          search:performanceSearch,
          created_date__year:performanceYearFilter
        })
      );

      return response.data;
    }
  );
}
