import { ReactComponent as CrossIcon } from 'assets/icons/crossIcon3.svg';
import { useState } from 'react';
import NewSKillModal from './newSkillModal';

import useGetMySkills from '../hooks/useGetMySkills';
import useDeleteMySkill from '../hooks/useDeleteMySkill';

import { ReactComponent as NoSkill } from 'assets/icons/noSkills.svg';

const MySkills = () => {
  const [newSkillModalOpened, setNewSkillModalOpened] = useState(false);

  const getMySkills = useGetMySkills();
  const deleteMySkillMutation = useDeleteMySkill();
  return (
    <>
      <div>
        <div className='flex justify-between items-center'>
          <h1 className='text-[#222222] font-bold text-lg'>My skills</h1>
          <p
            className='text-[#67A1EF] cursor-pointer'
            onClick={() => setNewSkillModalOpened(true)}
          >
            Add new
          </p>
        </div>
        {getMySkills?.data?.data.length === 0 ? (
          <div className='flex justify-center flex-col items-center -mt-7'>
            <NoSkill className='h-44 w-44' />
            <h1 className='text-primary mt-1 font-bold -mr-7'>
              No Skills to show
            </h1>
          </div>
        ) : (
          <div className='flex flex-wrap gap-2 overflow-y-scroll max-h-[200px] scroller mt-3'>
            {getMySkills?.data?.data?.map((eachSkill) => {
              return (
                <>
                  {eachSkill?.from_resume_parser ? (
                    <p className='bg-[#FFEFEA] py-1 px-2 rounded-lg flex items-center gap-3'>
                      {eachSkill?.name}{' '}
                      <CrossIcon
                        className='cursor-pointer h-3 w-3'
                        onClick={() => {
                          deleteMySkillMutation.mutate({ id: eachSkill?.id });
                        }}
                      />
                    </p>
                  ) : (
                    <p className='bg-[#F2F2F2] py-1 px-2 rounded-lg flex items-center gap-3'>
                      {eachSkill?.name}{' '}
                      <CrossIcon
                        className='cursor-pointer h-3 w-3'
                        onClick={() => {
                          deleteMySkillMutation.mutate({ id: eachSkill?.id });
                        }}
                      />
                    </p>
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
      <NewSKillModal
        newSkillModalOpened={newSkillModalOpened}
        setNewSkillModalOpened={setNewSkillModalOpened}
      />
    </>
  );
};

export default MySkills;
