import React, { useContext } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router-dom';
// import ChatBot from 'components/ChatBot';
import { AuthContext } from 'context/authContext';
import apiEndPoints from 'services/apiEndPoints';
import { ChatContext } from 'context/chatContext';

export default function BasePrivateLayout() {
  const { authState } = useContext(AuthContext);
  const { chatState, chatDispatch } = useContext(ChatContext);

  const { readyState, sendJsonMessage } = useWebSocket(
    authState.isAuthenticated
      ? `${process.env.REACT_APP_WEB_SOCKET}${apiEndPoints.USER_STATUS}`
      : null,
    {
      queryParams: {
        token: authState.isAuthenticated ? authState.token : '',
      },
      onOpen: () => {
        console.log('Connected!');
      },
      onClose: () => {
        console.log('Disconnected!');
      },
      // onMessage handler
      onMessage: (e) => {
        const data = JSON.parse(e.data);
        console.log(data, 'response from STATUS web socket');
        switch (data.type) {
          case 'user_status':
            if (data.user_id === authState.user_id) {
              chatDispatch({
                type: 'USER_STATUS',
                payload: data,
              });
            } else {
              const updatedConversations = chatState?.conversationsList?.map(
                (element) => {
                  if (element.user_id === data.user_id) {
                    if (element?.user_id === element?.message?.from_user?.id) {
                      return {
                        ...element,
                        status: data.status,
                        message: {
                          ...element.message,
                          from_user: {
                            ...element.message.from_user,
                            status: data.status,
                          },
                        },
                      };
                    } else {
                      return {
                        ...element,
                        status: data.status,
                        message: {
                          ...element.message,
                          to_user: {
                            ...element.message.to_user,
                            status: data.status,
                          },
                        },
                      };
                    }
                  } else {
                    return {
                      ...element,
                    };
                  }
                }
              );

              chatDispatch({
                type: 'SET_CONVERSATIONS',
                payload: updatedConversations,
              });

              const updateGroupSpecificUserData =
                chatState?.groupSpecificUsersList?.map((element) => {
                  if (element.id === data.user_id) {
                    return {
                      ...element,
                      status: data.status,
                    };
                  } else {
                    return {
                      ...element,
                    };
                  }
                });
              chatDispatch({
                type: 'SET_GROUP_SPECIFIC_USER_LIST',
                payload: updateGroupSpecificUserData,
              });
            }
            break;

          default:
            console.error('Unknown message type!');
            break;
        }
      },
    }
  );

  // eslint-disable-next-line no-unused-vars
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <div className='flex bg-[#FFFAFA]'>
      <Sidebar />
      <div className='pl-20 h-screen w-full overflow-y-scroll scroller'>
        <Outlet context={[sendJsonMessage]} />
      </div>
      {/* <ChatBot /> */}
    </div>
  );
}
