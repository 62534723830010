import forgotPasswordImage from '../../../assets/images/forgotPasswordImage.png';
import { ReactComponent as AptaSmallIcon } from '../../../assets/icons/aptaSmallIcon.svg';
import { useForm } from 'react-hook-form';
import { PasswordInput } from '@mantine/core';
import { useNavigate, useSearchParams } from 'react-router-dom';

import UseForgotPassword from '../hooks/useforgotpassword';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ mode: 'onChnage' });

  const forgotPasswordmutation = UseForgotPassword();

  const inputPassword = watch('ConfirmPassword');
  const submitPassword = () => {
    forgotPasswordmutation.mutate({
      email_or_number: searchParams.get('email'),
      password: inputPassword,
    });
  };
  return (
    <div
      style={{
        backgroundImage: 'URL(' + forgotPasswordImage + ')',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat, repeat',
        backgroundPosition: '90% 90%',
      }}
      className='h-screen bg-center  bg-no-repeat w-[100%] bg-cover'
    >
      <div className='bg-[#FFF] w-full py-[20px] px-[50px] flex justify-between items-center shadow-lg'>
        <AptaSmallIcon className='w-24 h-12' />
        <button
          onClick={() => {
            navigate('/auth/signup', { replace: true });
          }}
          className='h-10 w-32  bg-[#FB7C51] rounded-lg text-[#FFFFFF] font-normal text-base hover:bg-[#e2643a] hover:shadow-lg'
        >
          Sign up
        </button>
      </div>
      <div className='flex flex-col justify-center items-center h-[85%]'>
        <form className='w-[35%]' onSubmit={handleSubmit(submitPassword)}>
          <div className='flex flex-col justify-center items-center'>
            <h1 className='text-primary font-bold text-3xl mb-10'>
              Change Password{' '}
            </h1>
            <div>
              <PasswordInput
                {...register('NewPassword', {
                  required: 'Required',
                  pattern: {
                    value:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/,
                    message: 'should match with requirement pattern',
                  },
                  minLength: {
                    value: 8,
                    message: 'Minimum length is 8',
                  },
                  maxLength: {
                    value: 16,
                    message: 'maximum length is 16',
                  },
                })}
                label='New Password'
                className='w-96'
                size='lg'
                classNames={{
                  innerInput:
                    'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded-lg pl-5 ',
                  input:
                    'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded-lg pl-5 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm font-normal ml-2 bg-[#FDFCFC] px-2 rounded z-10 relative',
                }}
              />
              {errors.NewPassword && (
                <p className='text-sm  text-[#fc0101]  '>
                  {errors.NewPassword.message}
                </p>
              )}
            </div>
            <div>
              <PasswordInput
                {...register('ConfirmPassword', {
                  required: 'Required',
                  validate: (value) =>
                    value === watch('NewPassword') ||
                    'The passwords do not match',
                })}
                label='Confirm New Password'
                size='lg'
                className='w-96 mt-5'
                classNames={{
                  innerInput:
                    'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded-lg pl-5 ',
                  input:
                    'text-[15px] bg-transparent border-[#FB7C51] text-sm rounded-lg pl-5 -mt-3',
                  label:
                    'text-[#8E8E8E] text-sm font-normal ml-2 bg-[#FDFCFC] px-2 rounded z-10 relative',
                }}
              />
              {errors.ConfirmPassword && (
                <p className=' text-sm  text-[#fc0101] '>
                  {errors.ConfirmPassword.message}
                </p>
              )}
            </div>
            <p className='w-[70%] text-center mt-5 text-[#828282] text-sm font-normal'>
              Should contain at least one lowercase, one uppercase, one numeric,
              one special character and minimum 8-16 characters.
            </p>

            <button className='h-10 w-32 mx-auto bg-[#FB7C51] rounded-lg mt-14 text-[#FFFFFF] font-normal text-lg hover:bg-[#e2643a] hover:shadow-lg'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
