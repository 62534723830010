const dayjs = require('dayjs');

const localizedFormat = require('dayjs/plugin/localizedFormat');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

/**
 *
 * @param {Date} date
 * @param {string} format
 * @returns date
 */
const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const dateFormatter = (date, format) => {
  return dayjs(date).tz(`${systemTimeZone}`).format(format);
};

export default dateFormatter;
