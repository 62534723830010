import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';
import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

export default function Usegetpayroll(fromAndToData) {
  return useQuery(['organisation_payroll', fromAndToData], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.PAYROLL, {
        from_date__gte: fromAndToData.fromDate,
        to_date__lte: fromAndToData.toDate,
      })
    );

    return response.data;
  });
}
