import React, { useContext, useState } from 'react';
import { Tabs, TextInput, Select } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import PrimaryButton from 'components/primaryButton';

import Header from '../../../../components/Header';
import AddTeamMember from './components/addTeamMember';
import ActiveTeamMembers from './components/activeTeamMembers';

import useGetAllEmployees from './hooks/useGetEmployees';
import useGetDepartmentsForEmployees from './hooks/useGetDepartmentsForEmployee';
import { AuthContext } from 'context/authContext';
import { useSearchParams } from 'react-router-dom';
import DeactiveTeamMembers from './components/deactiveTeamMembers';

import noTeamMemberImg from 'assets/images/noTeamMemberImg.png';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/searchIcon.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filterIcon.svg';
import { ReactComponent as DownArrow } from '../../../../assets/icons/downArrow.svg';
import BulkUploadEmployees from './components/bulkUploadEmployees';

function MyTeams() {
  const { authState } = useContext(AuthContext);

  let [searchParams, setSearchParams] = useSearchParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBulkModalOpen, setBulkIsModalOpen] = useState(false);
  // const [departmentsFilter, setDepartmentsFilter] = useState();
  const [searchValue, setSearchValue] = useState('');

  const [debounced] = useDebouncedValue(searchValue, 400);

  const getTeamMembersData = useGetAllEmployees();
  const getEmployeeDepartments = useGetDepartmentsForEmployees();

  const getDepartmentsData = () => {
    const finalArray = [{ value: null, label: 'All' }];
    getEmployeeDepartments?.data?.data?.map((dept) => {
      finalArray.push({
        value: `${dept.id}`,
        label: dept.name,
      });
      return true;
    });
    return finalArray;
  };

  // handle tabs change
  const handleTabChange = (e) => {
    setSearchParams({ tab: e });
  };

  return (
    <div className='px-5'>
      <div className='flex justify-between items-center mt-6'>
        <p className='text-xl text-A8A8A8'>
          Team &#60; <span className='text-gray font-semibold'>My team</span>
        </p>
        <Header />
      </div>
      {/* empty illustration */}
      {getTeamMembersData?.data?.data?.length ? (
        <div className='mt-8 relative'>
          <div>
            <Tabs
              active={~~searchParams.get('tab')}
              tabPadding={21}
              onTabChange={handleTabChange}
              classNames={{
                tabsListWrapper: 'border-0',
                tabActive: 'border-2 border-primary font-semibold text-18',
                tabControl: 'text-A8A8A8 leading-5 px-0 mr-4',
                tabLabel: 'text-18 text-gray',
              }}
            >
              <Tabs.Tab label='Active employees'>
                <ActiveTeamMembers searchValue={debounced} />
              </Tabs.Tab>
              <Tabs.Tab label='Inactive employees'>
                <DeactiveTeamMembers searchValue={debounced} />
              </Tabs.Tab>
            </Tabs>
          </div>
          {/* search and filter */}
          <div className='absolute top-2 right-0'>
            <div className='flex items-center space-x-4'>
              <TextInput
                icon={<SearchIcon />}
                placeholder='Search'
                onChange={(event) => setSearchValue(event.currentTarget.value)}
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-36 bg-light-bg border-none',
                }}
              />
              <Select
                placeholder='Department'
                value={searchParams.get('filterValue')}
                icon={<FilterIcon />}
                rightSection={<DownArrow />}
                onChange={(e) => {
                  if (e) {
                    setSearchParams({
                      filterValue: e,
                      tab: ~~searchParams.get('tab'),
                    });
                  } else {
                    setSearchParams({
                      tab: ~~searchParams.get('tab'),
                    });
                  }
                }}
                styles={{
                  rightSection: { pointerEvents: 'none' },
                }}
                data={
                  !getEmployeeDepartments?.data?.data?.length
                    ? []
                    : getDepartmentsData()
                }
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'bg-light-bg border-none',
                }}
              />
              {~~searchParams.get('tab') !== 1 &&
              (authState?.is_admin || authState?.can_add_employees) ? (
                <button onClick={() => setBulkIsModalOpen(true)}>
                  <PrimaryButton>Bulk upload employee</PrimaryButton>
                </button>
              ) : null}
              {~~searchParams.get('tab') !== 1 &&
              (authState?.is_admin || authState?.can_add_employees) ? (
                <button onClick={() => setIsModalOpen(true)}>
                  <PrimaryButton>+ Add employee</PrimaryButton>
                </button>
              ) : null}
            </div>
          </div>
          <div className='flex items-center fixed right-12  bottom-4'>
            <span className='w-3 h-3 bg-primary rounded-sm mr-2'></span>
            <p className='text-8B8B8B'>Reporting manager</p>
          </div>
        </div>
      ) : (
        <div className='h-[80vh] mt-12 bg-white rounded-xl flex flex-col justify-center items-center'>
          <img src={noTeamMemberImg} alt='' />
          <p className='text-xl text-gray2 mt-4'>
            This place looks better with employees
          </p>
          <div className='flex  mt-8'>
            <button className='mr-2' onClick={() => setBulkIsModalOpen(true)}>
              <PrimaryButton>Bulk upload employee</PrimaryButton>
            </button>
            <button className='ml-2' onClick={() => setIsModalOpen(true)}>
              <PrimaryButton>+ Add employee</PrimaryButton>
            </button>
          </div>
        </div>
      )}
      <AddTeamMember
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <BulkUploadEmployees
        isModalOpen={isBulkModalOpen}
        setIsModalOpen={setBulkIsModalOpen}
      />
    </div>
  );
}

export default MyTeams;
