import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';

export default function useEmploymentTypes(id) {
  return useQuery(['settings-employment-types', id], async () => {
    const response = await makeApiRequest.get(
      `${apiEndPoints.SETTINGS_EMPLOYMENT_TYPES}?req_company_id=${id}`
    );
    return response?.data;
  });
}
