import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetEmployeePersonalInfo() {
  return useQuery(['get-employee-personal-info'], async () => {
    const res = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.EMPLOYEE_PERSONAL_INFO_API)
    );
    return res.data;
  });
}
