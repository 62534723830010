import React from 'react';
import { Timeline, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';

import useGetBankDetailsForEmployee from '../hooks/useGetEmployeeBankDetails';
import useGetEmployeeEducationDetails from '../hooks/useGetEmployeeEducationDetails';
import useGetProfessionalExperienceDetails from '../hooks/useGetProfessionalExperienceDetails';
import useGetEmployeePersonalDetails from '../hooks/useGetEmployeePersonalInfo';

import { ReactComponent as EmptyEducationIlls } from 'assets/images/emptyEducationIlls.svg';
import { ReactComponent as ActiveBullet } from 'assets/icons/activeBullet.svg';
import { ReactComponent as NoExperienceIlls } from 'assets/images/noExperienceIlls.svg';
import { ReactComponent as PdfImage } from 'assets/images/pdfImage.svg';

export default function DetailsComponent() {
  const getEmployeebankDetails = useGetBankDetailsForEmployee();
  const getEmployeeEducationDetails = useGetEmployeeEducationDetails();
  const getEmployeeProfessionalExperience =
    useGetProfessionalExperienceDetails();
  const getEmployeePersonalInfo = useGetEmployeePersonalDetails();

  const getCustomTimelineTitleForExperience = (data) => {
    return (
      <div className='flex justify-between mr-4'>
        <p>
          {data?.role} at {data?.organisation}
        </p>
      </div>
    );
  };

  const customDeactiveBullet = () => {
    return <span className='w-7 h-5 rounded-full bg-[#F5DDD5]'></span>;
  };

  const customActiveBullet = () => {
    return <ActiveBullet />;
  };

  const checkFileType = (data) => {
    if (data !== 'undefined' && data !== null) {
      const newArray = data?.split('.');
      if (newArray && newArray[newArray?.length - 1] === 'pdf') {
        return 'pdf';
      } else {
        return 'img';
      }
    } else {
      return null;
    }
  };

  function getExperienceCount(monthCount) {
    function getPlural(number, word) {
      return number === 1 ? word.one : word.other;
    }

    var months = { one: 'month', other: 'months' },
      years = { one: 'year', other: 'years' },
      m = monthCount % 12,
      y = Math.floor(monthCount / 12),
      result = [];

    y && result.push(y + ' ' + getPlural(y, years));
    m && result.push(m + ' ' + getPlural(m, months));
    return result.join(' and ');
  }

  return (
    <div className='shadow-xl border border-8B8B8B border-opacity-20 rounded-xl py-4 px-6 mb-8 bg-white'>
      <p className='text-primary font-medium text-xl bg-FFE8E1 px-4 py-2'>
        Personal details
      </p>
      <div className='px-4 details-table text-gray text-18'>
        <table className='w-full'>
          <tbody>
            <tr>
              <td className='py-2 w-52'>Contact & Whatsapp</td>
              <td className='px-4 w-20'>- </td>
              <td className='font-medium text-67A1EF' colSpan={4}>
                <p>
                  {getEmployeePersonalInfo?.data?.data?.[0]
                    ?.user__mobile_number || 'NIL'}
                  {getEmployeePersonalInfo?.data?.data?.[0]?.whatsapp_number ===
                    getEmployeePersonalInfo?.data?.data?.[0]
                      ?.user__mobile_number &&
                    `, ${getEmployeePersonalInfo?.data?.data?.[0]?.whatsapp_number}`}
                </p>
              </td>
            </tr>
            <tr>
              <td className='py-3'>Mail </td>
              <td className='px-4'>- </td>
              <td className='text-67A1EF ' colSpan={4}>
                {getEmployeePersonalInfo?.data?.data?.[0]?.user__email || 'NIL'}
              </td>
            </tr>
            <tr>
              <td className=' py-3'>DOB </td>
              <td className='px-4'>- </td>
              <td className='font-light ' colSpan={4}>
                {(getEmployeePersonalInfo?.data?.data?.[0]?.date_of_birth &&
                  dayjs(
                    getEmployeePersonalInfo?.data?.data?.[0]?.date_of_birth
                  ).format('DD MMM YY')) ||
                  'NIL'}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Gender</td>
              <td className='px-4'>-</td>
              <td className='font-light' colSpan={4}>
                {getEmployeePersonalInfo?.data?.data?.[0]?.gender === 'M'
                  ? 'Male'
                  : getEmployeePersonalInfo?.data?.data?.[0]?.gender === 'F'
                  ? 'Female'
                  : 'Others' || 'NIL'}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Marital status</td>
              <td className='px-4'>- </td>
              <td className='font-light' colSpan={4}>
                {getEmployeePersonalInfo?.data?.data?.[0]?.marital_status ||
                  'NIL'}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Preferred pronoun</td>
              <td className='px-4'>- </td>
              <td className='font-light' colSpan={4}>
                {getEmployeePersonalInfo?.data?.data?.[0]?.preferred_pronoun ||
                  'NIL'}
              </td>
            </tr>
            <tr>
              <td className='pt-3 pb-5'>Address </td>
              <td className='px-4'>- </td>
              <td className='font-light' colSpan={4}>
                {getEmployeePersonalInfo?.data?.data?.[0]?.address || 'NIL'}
              </td>
            </tr>
            {/* <tr>
              <td className='  pt-3 pb-5'>Reports to </td>
              <td className='px-4 pt-3 pb-5'>- </td>
              <td colSpan={4} className='pt-3 pb-5'>
                <div>
                  <p className='text-gray'>Velera Sentonio</p>
                  <p className='font-light text-sm'>Sr. UX designer</p>
                </div>
              </td>
            </tr> */}

            <tr>
              <td className='pb-3 pt-5'>Nationality</td>
              <td className='px-4 pb-3 pt-5'>- </td>
              <td className='font-light pb-3 pt-5'>
                {getEmployeePersonalInfo?.data?.data?.[0]?.nationality || 'NIL'}
              </td>
            </tr>
            <tr>
              <td className='pb-3 pt-5'>Aadhaar ID</td>
              <td className='px-4 pb-3 pt-5'>- </td>
              <td className='font-light pb-3 pt-5'>
                {getEmployeePersonalInfo?.data?.data?.[0]?.aadhar_card || 'NIL'}
              </td>
              <td className='py-2' colSpan={3}>
                <div className='flex space-x-4 justify-end'>
                  {checkFileType(
                    getEmployeePersonalInfo?.data?.data?.[0]?.aadhar_card_image
                  ) === 'img' ? (
                    <a
                      href={
                        getEmployeePersonalInfo?.data?.data?.[0]
                          ?.aadhar_card_image
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        alt='aadhar'
                        src={
                          getEmployeePersonalInfo?.data?.data?.[0]
                            ?.aadhar_card_image
                        }
                        className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                      />
                    </a>
                  ) : checkFileType(
                      getEmployeePersonalInfo?.data?.data?.[0]
                        ?.aadhar_card_image
                    ) === 'pdf' ? (
                    <a
                      href={
                        getEmployeePersonalInfo?.data?.data?.[0]
                          ?.aadhar_card_image
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <PdfImage />
                    </a>
                  ) : null}
                </div>
              </td>
            </tr>
            <tr>
              <td className='pb-3  pt-5'>
                <p className='whitespace-nowrap'>Passport no.</p>
              </td>
              <td className='px-4 pb-3 pt-5'>- </td>
              <td className='font-light pb-3 pt-5'>
                {' '}
                {getEmployeePersonalInfo?.data?.data?.[0]?.passport_number ||
                  'NIL'}
              </td>
              <td rowSpan={2} colSpan={3}>
                <div className='flex space-x-4 justify-end'>
                  {checkFileType(
                    getEmployeePersonalInfo?.data?.data?.[0]?.passport_image_1
                  ) === 'img' ? (
                    <a
                      href={
                        getEmployeePersonalInfo?.data?.data?.[0]
                          ?.passport_image_1
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        alt='passport'
                        src={
                          getEmployeePersonalInfo?.data?.data?.[0]
                            ?.passport_image_1
                        }
                        className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                      />
                    </a>
                  ) : checkFileType(
                      getEmployeePersonalInfo?.data?.data?.[0]?.passport_image_1
                    ) === 'pdf' ? (
                    <a
                      href={
                        getEmployeePersonalInfo?.data?.data?.[0]
                          ?.passport_image_1
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <PdfImage />
                    </a>
                  ) : null}
                  {checkFileType(
                    getEmployeePersonalInfo?.data?.data?.[0]?.passport_image_2
                  ) === 'img' ? (
                    <a
                      href={
                        getEmployeePersonalInfo?.data?.data?.[0]
                          ?.passport_image_2
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        alt='pancard'
                        src={
                          getEmployeePersonalInfo?.data?.data?.[0]
                            ?.passport_image_2
                        }
                        className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                      />
                    </a>
                  ) : checkFileType(
                      getEmployeePersonalInfo?.data?.data?.[0]?.passport_image_2
                    ) === 'pdf' ? (
                    <a
                      href={
                        getEmployeePersonalInfo?.data?.data?.[0]
                          ?.passport_image_2
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <PdfImage />
                    </a>
                  ) : null}
                </div>
              </td>
            </tr>
            <tr>
              <td className='pt-3 pb-5'>Passport exp date</td>
              <td className='px-4'>- </td>
              <td className='font-light'>
                {(getEmployeePersonalInfo?.data?.data?.[0]
                  ?.passport_expiry_date &&
                  dayjs(
                    getEmployeePersonalInfo?.data?.data?.[0]
                      ?.passport_expiry_date
                  ).format('DD MMM YY')) ||
                  'NIL'}
              </td>
            </tr>
            <tr>
              <td className='py-3'>PAN ID</td>
              <td className='px-4'>- </td>
              <td className='font-light'>
                {getEmployeePersonalInfo?.data?.data?.[0]?.pan_card || 'NIL'}
              </td>
              <td colSpan={3}>
                <div className='flex space-x-4 justify-end'>
                  {checkFileType(
                    getEmployeePersonalInfo?.data?.data?.[0]?.pan_card_image
                  ) === 'img' ? (
                    <a
                      href={
                        getEmployeePersonalInfo?.data?.data?.[0]?.pan_card_image
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        alt=''
                        src={
                          getEmployeePersonalInfo?.data?.data?.[0]
                            ?.pan_card_image
                        }
                        className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                      />
                    </a>
                  ) : checkFileType(
                      getEmployeePersonalInfo?.data?.data?.[0]?.pan_card_image
                    ) === 'pdf' ? (
                    <a
                      href={
                        getEmployeePersonalInfo?.data?.data?.[0]?.pan_card_image
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <PdfImage />
                    </a>
                  ) : null}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={6}>
                <p className='text-primary font-medium text-xl bg-FFE8E1 px-4 py-2 -ml-4 -mr-4 mt-4'>
                  Bank details
                </p>
              </td>
            </tr>
            <tr>
              <td className='pt-5 pb-3'>Bank name </td>
              <td className='px-4 pt-5 pb-3'>- </td>
              <td className='font-light pt-5 pb-3'>
                {getEmployeebankDetails?.data?.data[0]?.bank_name || 'NIL'}
              </td>
              <td className='pt-5 pb-3 whitespace-nowrap w-32'>
                Account holder name
              </td>
              <td className='px-4 pt-5 pb-3'>- </td>
              <td className='font-light pt-5 pb-3'>
                {getEmployeebankDetails?.data?.data[0]?.account_holder_name ||
                  'NIL'}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Bank account no.</td>
              <td className='px-4'>- </td>
              <td className='font-light'>
                {getEmployeebankDetails?.data?.data[0]?.account_no || 'NIL'}
              </td>
              <td className='py-3'>IFSC code</td>
              <td className='px-4'>- </td>
              <td className='font-light'>
                {getEmployeebankDetails?.data?.data[0]?.ifsc_code || 'NIL'}
              </td>
            </tr>
            <tr>
              <td className='py-3'>Bank location</td>
              <td className='px-4'>- </td>
              <td className='font-light'>
                {getEmployeebankDetails?.data?.data[0]?.bank_location || 'NIL'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className='text-primary font-medium text-xl bg-FFE8E1 px-4 py-2 mt-4'>
        Education
      </p>
      <div className='mt-4 pl-6'>
        {getEmployeeEducationDetails?.data?.data?.length ? (
          <Timeline
            bulletSize={20}
            lineWidth={2}
            classNames={{ itemTitle: 'text-gray text-18' }}
          >
            {getEmployeeEducationDetails?.data?.data?.map((item, index) => (
              <Timeline.Item
                title={item?.instituition}
                key={item?.id}
                bullet={
                  index === 0 ? customActiveBullet() : customDeactiveBullet()
                }
              >
                <div className='flex justify-between items-center'>
                  <div>
                    <p className='text-gray font-light'>{item?.degree}</p>
                    <p className='text-gray font-light'>
                      {dayjs(new Date(item.from_date)).format('YYYY')}-
                      {dayjs(new Date(item.to_date)).format('YYYY')}
                    </p>
                  </div>
                  <div>
                    {checkFileType(item?.certificate) === 'img' ? (
                      <a
                        href={item?.certificate}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          alt='aadhar'
                          src={item?.certificate}
                          className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                        />
                      </a>
                    ) : checkFileType(item?.certificate) === 'pdf' ? (
                      <a
                        href={item?.certificate}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <PdfImage />
                      </a>
                    ) : null}
                  </div>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
        ) : (
          <div className='flex justify-center items-center flex-col pt-4'>
            <EmptyEducationIlls className='w-72 h-40' />
            <p className='text-gray3 pt-3'>No Educational details to show</p>
          </div>
        )}
      </div>
      <p className='text-primary font-medium text-xl bg-FFE8E1 px-4 py-2 mt-6'>
        Professional experience
      </p>
      <div className='mt-4 pl-6'>
        {getEmployeeProfessionalExperience?.data?.data?.length ? (
          <Timeline
            bulletSize={20}
            lineWidth={2}
            classNames={{
              itemTitle: 'text-gray text-18',
            }}
          >
            {getEmployeeProfessionalExperience?.data?.data?.map(
              (item, index) => (
                <Timeline.Item
                  title={getCustomTimelineTitleForExperience(item)}
                  key={item?.id}
                  bullet={
                    index === 0 ? customActiveBullet() : customDeactiveBullet()
                  }
                >
                  <div className='flex justify-between items-center'>
                    <div className='flex-1'>
                      <div>
                        <p className='text-gray font-light'>
                          {dayjs(item?.from_date).format('MMM YYYY')} -{' '}
                          {dayjs(item?.to_date).format('MMM YYYY')},{' '}
                          {getExperienceCount(
                            dayjs(item?.to_date).diff(item?.from_date, 'month')
                          )}
                        </p>
                      </div>
                      <div>
                        <div className=' text-gray'>
                          <table className='w-full my-2'>
                            <tbody className='w-full text-14'>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>Employee ID </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.old_employee_id || '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    Work Email
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.work_email || '-'}
                                  </td>
                                </div>
                              </tr>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>
                                    Company Address{' '}
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.company_address || '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    Last drawn salary
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.last_drawn_salary || '-'}
                                  </td>
                                </div>
                              </tr>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>
                                    Reporting manager name{' '}
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.reporting_manager_name || '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    Reporting manager email
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.reporting_manager_email || '-'}
                                  </td>
                                </div>
                              </tr>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>
                                    Reporting manager contact number{' '}
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.reporting_manager_contact_number ||
                                      '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    HR manager name
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.hr_manager_name || '-'}
                                  </td>
                                </div>
                              </tr>
                              <tr className='flex'>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 w-36'>
                                    HR manager email
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.hr_manager_email || '-'}
                                  </td>
                                </div>
                                <div className='w-1/2 mx-1'>
                                  <td className='py-1 whitespace-nowrap w-36'>
                                    HR manager contact number
                                  </td>
                                  <td className=' py-1'>- </td>
                                  <td className='font-light py-1 pl-2'>
                                    {item?.hr_manager_contact_number || '-'}
                                  </td>
                                </div>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className='flex space-x-4'>
                      <Tooltip
                        label='Joining letter'
                        classNames={{
                          body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                        }}
                        radius='md'
                      >
                        <div>
                          {checkFileType(item?.joining_letter) === 'img' ? (
                            <a
                              href={item?.joining_letter}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <img
                                alt='aadhar'
                                src={item?.joining_letter}
                                className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                              />
                            </a>
                          ) : checkFileType(item?.joining_letter) === 'pdf' ? (
                            <a
                              href={item?.joining_letter}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <PdfImage />
                            </a>
                          ) : null}
                        </div>
                      </Tooltip>
                      <Tooltip
                        label='Experience letter'
                        classNames={{
                          body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                        }}
                        radius='md'
                      >
                        <div>
                          {checkFileType(item?.experience_letter) === 'img' ? (
                            <a
                              href={item?.experience_letter}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <img
                                alt='aadhar'
                                src={item?.experience_letter}
                                className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                              />
                            </a>
                          ) : checkFileType(item?.experience_letter) ===
                            'pdf' ? (
                            <a
                              href={item?.experience_letter}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <PdfImage />
                            </a>
                          ) : null}
                        </div>
                      </Tooltip>
                      <Tooltip
                        label='Relieving letter'
                        classNames={{
                          body: 'bg-white text-gray2 shadow-tooltip pr-3 z-0',
                        }}
                        radius='md'
                      >
                        <div>
                          {checkFileType(item?.relieving_letter) === 'img' ? (
                            <a
                              href={item?.relieving_letter}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <img
                                alt=''
                                src={item?.relieving_letter}
                                className='border-primary border w-20 h-16 rounded-md border-opacity-60'
                              />
                            </a>
                          ) : checkFileType(item?.relieving_letter) ===
                            'pdf' ? (
                            <a
                              href={item?.relieving_letter}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <PdfImage />
                            </a>
                          ) : null}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </Timeline.Item>
              )
            )}
          </Timeline>
        ) : (
          <div className='flex justify-center items-center flex-col pt-4'>
            <NoExperienceIlls className='w-72 h-40' />
            <p className='text-gray3 pt-3'>
              No Professional experience data to show
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
