import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';
import React from 'react';

export default function OrganisationThirdStep({
  prevStep,
  handleOrgRegister,
  isLoading,
}) {
  return (
    <div className='mx-5 mt-4'>
      <p className='text-22 text-center pb-5'>You are all set! </p>
      {/* <p className='text-gray3'>Organisation chart preview</p> */}
      <div className='flex justify-center space-x-6 my-4'>
        <button
          className='w-32'
          onClick={() => {
            prevStep();
          }}
        >
          <SecondaryButton>Back</SecondaryButton>
        </button>
        <button className='w-32' onClick={() => handleOrgRegister()}>
          <PrimaryButton isLoading={isLoading}>Save</PrimaryButton>
        </button>
      </div>
    </div>
  );
}
