import React, { useState } from 'react';
import { Switch } from '@mantine/core';
import PrimaryButton from 'components/primaryButton';
import SecondaryButton from 'components/secondaryButton';
import { Controller } from 'react-hook-form';

export default function AddEmployeeThridStep({
  prevStep,
  control,
  watch,
  isLoading,
}) {
  const [addEmployee, setaddEmployee] = useState(watch('can_add_employees'));
  const [publishBlogs, setpublishBlogs] = useState(watch('can_publish_blogs'));
  const [viewPR, setviewPR] = useState(watch('can_view_pr'));

  return (
    <div className='mr-8 ml-4 mt-6'>
      <p className='font-semibold text-18'>Setting permissions</p>
      <div className='flex items-center justify-between mt-4 text-neutralsGrey'>
        <p>Employee can add other employees</p>
        <Controller
          control={control}
          name='can_add_employees'
          // defaultValue={false}
          render={({ field }) => (
            <Switch
              {...field}
              checked={addEmployee}
              onChange={(event) => {
                setaddEmployee(event.currentTarget.checked);
                field.onChange(event);
              }}
            />
          )}
        />
      </div>
      <div className='flex items-center justify-between mt-4 text-neutralsGrey'>
        <p>Employee can review and publish blogs</p>
        <Controller
          control={control}
          name='can_publish_blogs'
          // defaultValue={false}
          render={({ field }) => (
            <Switch
              {...field}
              checked={publishBlogs}
              onChange={(event) => {
                setpublishBlogs(event.currentTarget.checked);
                field.onChange(event);
              }}
            />
          )}
        />
      </div>
      <div className='flex items-center justify-between mt-4 text-neutralsGrey'>
        <p>Employee can view and comment on performance reports</p>
        <Controller
          control={control}
          name='can_view_pr'
          // defaultValue={false}
          render={({ field }) => (
            <Switch
              {...field}
              checked={viewPR}
              onChange={(event) => {
                setviewPR(event.currentTarget.checked);
                field.onChange(event);
              }}
            />
          )}
        />
      </div>
      <div className='flex justify-center space-x-6 mb-4 mt-10'>
        <div className='w-32' onClick={() => prevStep()}>
          <SecondaryButton>Back</SecondaryButton>
        </div>
        <button className='w-32'>
          <PrimaryButton isLoading={isLoading}>Save</PrimaryButton>
        </button>
      </div>
    </div>
  );
}
