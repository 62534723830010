import { useQuery } from 'react-query';
import makeApiRequest from 'services/makeApiRequest';
import apiEndPoints from 'services/apiEndPoints';
import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useCalendarEvents(id) {
  return useQuery(['calendar-events', id], async () => {
    const response = await makeApiRequest.get(
      createQueryParamsForGetReq(apiEndPoints.CALENDAR_EVENTS, {
        id: id,
      })
    );
    return response?.data;
  });
}
