import React from 'react';
import { Switch, Chips, Chip } from '@mantine/core';
import { Controller } from 'react-hook-form';
// import { ErrorToast } from 'services/toasterService';

export default function WorkingHoursForm({
  selectedDays,
  setSelectedDays,
  isWorkingHoursSame,
  setIsWorkingHoursSame,
  control,
  watch,

  getValues,
}) {
  return (
    <div className='w-full pt-4 mb-4'>
      <div className='text-neutralsGrey text-sm flex justify-between pb-2 mx-4'>
        <p>Working days &amp; timings</p>
        <div className='flex space-x-2'>
          <p>Working hours are same in each day</p>
          <Switch
            size='sm'
            checked={isWorkingHoursSame}
            onChange={(e) => {
              setIsWorkingHoursSame(e.currentTarget.checked);
            }}
          />
        </div>
      </div>
      <div className='bg-FFF5F2 min-h-40 mt-2 p-4 mx-4'>
        <div className='flex justify-between'>
          <p className='text-base text-414141'>Select working days</p>
          <div>
            <Chips
              multiple={true}
              value={selectedDays}
              onChange={setSelectedDays}
              classNames={{
                label:
                  'p-0 w-8 text-center rounded-md font-medium text-primary',
                iconWrapper: 'hidden',
                checkIcon: 'hidden',
                checked: 'text-[#ffffff] bg-primary hover:bg-primary',
              }}
            >
              <Chip value='monday'>M</Chip>
              <Chip value='tuesday'>T</Chip>
              <Chip value='wednesday'>W</Chip>
              <Chip value='thursday'>TH</Chip>
              <Chip value='friday'>F</Chip>
              <Chip value='saturday'>SA</Chip>
              <Chip value='sunday'>S</Chip>
            </Chips>
          </div>
        </div>
        <hr className='border-primary border my-4 border-opacity-20' />
        {isWorkingHoursSame ? (
          <div className='flex item-center justify-between'>
            <p className='text-base text-414141 pt-1'>Timings</p>
            <div className='flex items-center space-x-4 mb-4'>
              <Controller
                control={control}
                name='general_from'
                rules={{ required: 'required' }}
                render={({ field }) => (
                  <input
                    {...field}
                    type='time'
                    className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                  />
                )}
              />
              <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
              <Controller
                control={control}
                name='general_to'
                rules={{
                  required: 'required',
                  // validate:()=>(getValues("general_from")<getValues("general_to"))
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    type='time'
                    className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                  />
                )}
              />
            </div>
            {
              // watch('general_from') > watch('general_to')
              // ? ErrorToast({ text: 'Please select valid time' })
              // : ''
            }
          </div>
        ) : (
          <>
            {selectedDays.includes('monday') ? (
              <div className='flex item-center justify-between'>
                <p className='text-base text-414141 pt-1'>Monday</p>
                <div className='flex items-center space-x-4 mb-4'>
                  <Controller
                    control={control}
                    name='monday_from'
                    rules={{
                      required: 'required',
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                  {
                    // watch('monday_from') > watch('monday_to')
                    // ? ErrorToast({ text: 'Please select Valid time' })
                    // : ''
                  }
                  <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                  <Controller
                    control={control}
                    name='monday_to'
                    rules={{
                      required: 'required',
                      // validate: () =>
                      //   getValues('monday_from') < getValues('monday_to'),
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                </div>
              </div>
            ) : null}
            {selectedDays.includes('tuesday') ? (
              <div className='flex item-center justify-between'>
                <p className='text-base text-414141 pt-1'>Tuesday</p>
                <div className='flex items-center space-x-4 mb-4'>
                  <Controller
                    control={control}
                    name='tuesday_from'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                  <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                  <Controller
                    control={control}
                    name='tuesday_to'
                    rules={{
                      required: 'required',
                      // validate: () =>
                      //   getValues('tuesday_from') < getValues('tuesday_to'),
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                  {
                    // watch('tuesday_from') > watch('tuesday_to')
                    // ? ErrorToast({ text: 'Please select Valid time' })
                    // : ''
                  }
                </div>
              </div>
            ) : null}
            {selectedDays.includes('wednesday') ? (
              <div className='flex item-center justify-between'>
                <p className='text-base text-414141 pt-1'>Wednesday</p>
                <div className='flex items-center space-x-4 mb-4'>
                  <Controller
                    control={control}
                    name='wednesday_from'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                  <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                  <Controller
                    control={control}
                    name='wednesday_to'
                    rules={{
                      required: 'required',
                      // validate: () =>
                      //   getValues('wednesday_from') < getValues('wednesday_to'),
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                </div>
                {
                  // watch('wednesday_from') > watch('wednesday_to')
                  // ? ErrorToast({ text: 'Please select Valid time' })
                  // : ''
                }
              </div>
            ) : null}
            {selectedDays.includes('thursday') ? (
              <div className='flex item-center justify-between'>
                <p className='text-base text-414141 pt-1'>Thursday</p>
                <div className='flex items-center space-x-4 mb-4'>
                  <Controller
                    control={control}
                    name='thursday_from'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                  <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                  <Controller
                    control={control}
                    name='thursday_to'
                    rules={{
                      required: 'required',
                      // validate: () =>
                      //   getValues('thursday_from') < getValues('thursday_to'),
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                </div>
                {
                  // watch('thursday_from') > watch('thursday_to')
                  // ? ErrorToast({ text: 'Please select Valid time' })
                  // : ''
                }
              </div>
            ) : null}
            {selectedDays.includes('friday') ? (
              <div className='flex item-center justify-between'>
                <p className='text-base text-414141 pt-1'>Friday</p>
                <div className='flex items-center space-x-4 mb-4'>
                  <Controller
                    control={control}
                    name='friday_from'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                  <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                  <Controller
                    control={control}
                    name='friday_to'
                    rules={{
                      required: 'required',
                      // validate: () =>
                      //   getValues('friday_from') < getValues('friday_to'),
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                </div>
                {
                  // watch('friday_from') > watch('friday_to')
                  // ? ErrorToast({ text: 'Please select Valid time' })
                  // : ''
                }
              </div>
            ) : null}
            {selectedDays.includes('saturday') ? (
              <div className='flex item-center justify-between'>
                <p className='text-base text-414141 pt-1'>Saturday</p>
                <div className='flex items-center space-x-4 mb-4'>
                  <Controller
                    control={control}
                    name='saturday_from'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                  <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                  <Controller
                    control={control}
                    name='saturday_to'
                    rules={{
                      required: 'required',
                      // validate: () =>
                      //   getValues('saturday_from') < getValues('saturday_to'),
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                </div>
                {
                  // watch('saturday_from') > watch('saturday_to')
                  // ? ErrorToast({ text: 'Please select Valid time' })
                  // : ''
                }
              </div>
            ) : null}
            {selectedDays.includes('sunday') ? (
              <div className='flex item-center justify-between'>
                <p className='text-base text-414141 pt-1'>Sunday</p>
                <div className='flex items-center space-x-4 mb-4'>
                  <Controller
                    control={control}
                    name='sunday_from'
                    rules={{ required: 'required' }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                  <span className='w-2 bg-primary h-0.5 rounded-lg'></span>
                  <Controller
                    control={control}
                    name='sunday_to'
                    rules={{
                      required: 'required',
                      // validate: () =>
                      //   getValues('sunday_from') < getValues('sunday_to'),
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type='time'
                        className='py-1 px-2 border-neutralsGrey border border-opacity-30 rounded-md'
                      />
                    )}
                  />
                </div>
                {
                  // watch('sunday_from') > watch('sunday_to')
                  // ? ErrorToast({ text: 'Please select Valid time' })
                  // : ''
                }
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
