import React from 'react';

import useGetEmployeesForOrganisation from '../hooks/useGetEmployeesForCompany';

import { Avatar } from '@mantine/core';
import { Link } from 'react-router-dom';

import { ReactComponent as RightIcon } from 'assets/icons/rightIconcircle.svg';
import noTeamMemberImg from 'assets/images/noTeamMemberImg.png';

export default function ActiveOrInactiveEmployees({ activeTab }) {
  const getListOfEmployees = useGetEmployeesForOrganisation(
    activeTab === 0 ? 'True' : 'False'
  );

  return (
    <div className='mb-6 mt-4'>
      {getListOfEmployees?.data?.data?.length ? (
        <table className='w-full rounded-xl shadow-lg'>
          <thead>
            <tr className='bg-FFE8E1 text-left text-18 text-222222 sticky top-0 z-20'>
              <th className='py-4 pl-5 rounded-tl-xl'>Name </th>
              <th className=' pl-5'>Emp. ID</th>
              <th className=' pl-5'>Dept.</th>
              <th className=' pl-5'>Grade</th>
              <th className=' pl-5'>Salary (CTC)</th>
              <th className=' pl-5'>Employment type</th>
              <th className=' pl-5 rounded-tr-xl'>Action</th>
            </tr>
          </thead>
          <tbody>
            {getListOfEmployees?.data?.data?.map((item) => {
              return (
                <tr className='text-9B9B9B' key={item.id}>
                  <td>
                    <div className='flex space-x-1 items-center pl-4'>
                      <Avatar
                        radius='xl'
                        color='red'
                        src={item?.profile_picture}
                      >
                        {item?.user__first_name[0]?.toUpperCase()}
                      </Avatar>
                      <div className='pl-1'>
                        <p>{item?.user__first_name}</p>
                        <p>{item?.designation__name}</p>
                      </div>
                    </div>
                  </td>
                  <td className='py-6 pl-5 text-primary'>
                    {item?.employee_id}
                  </td>
                  <td className='py-5 pl-5 text-primary'>
                    {item?.department__name}
                  </td>
                  <td className='pl-5'>{item?.grade__name}</td>
                  <td className='pl-5'>{item?.gross_salary}</td>
                  <td className='pl-5'>{item?.employment_type__name}</td>
                  <td className='pl-8 text-primary'>
                    <Link to={`${item?.id}`}>
                      <RightIcon className='cursor-pointer' />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className='flex flex-col justify-center items-center'>
          <img src={noTeamMemberImg} alt='' className='w-40'/>
          <p className='text-gray3  pt-3'>No employees to show</p>
        </div>
      )}
    </div>
  );
}
