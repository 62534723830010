import React, { useEffect } from 'react';
import { Modal, TextInput } from '@mantine/core';
import PrimaryButton from '../../../components/primaryButton';
import { useForm } from 'react-hook-form';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/orangeLeftArrow.svg';
import DepartmentBgImg from '../../../assets/images/departmentModalBg.svg';
import useAddDepartment from '../hooks/useAddDepartment';
import useEditDepartment from '../hooks/useEditDepartment';
import useAddAdminEmployeeDetails from '../hooks/useAddAdminEmployeeDetails';

export default function DepartmentModal({
  isModalOpen,
  setIsModalOpen,
  modalHeader,
  departmentDetails,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm();

  const closeModal = () => {
    setIsModalOpen(false);
    if (modalHeader !== 'Edit department') {
      reset({ inputValue: '' });
    }
  };

  useEffect(() => {
    reset({ inputValue: departmentDetails?.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentDetails]);

  const addDepartmentMutation = useAddDepartment(closeModal);
  const editDepartmentMutation = useEditDepartment(closeModal);
  const addEmployeeDetailsByAdminMutation =
    useAddAdminEmployeeDetails(closeModal);

  const formSubmitHandler = (data) => {
    if (modalHeader === 'Add a department') {
      addDepartmentMutation.mutate({ name: data.inputValue });
    }

    if (modalHeader === 'Edit department') {
      isDirty &&
        editDepartmentMutation.mutate({
          id: departmentDetails?.id,
          name: data?.inputValue,
        });
    }

    if (modalHeader === 'Add a new field') {
      addEmployeeDetailsByAdminMutation.mutate({
        field_name: data?.inputValue,
        default_field: false,
      });
    }
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={closeModal}
      title={modalHeader}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      overlayOpacity={0.5}
      centered
      size={500}
      radius='md'
      transitionDuration={100}
      classNames={{
        title: 'mx-auto text-primary font-semibold text-18',
        modal: 'px-10',
      }}
      styles={{
        modal: {
          backgroundImage: `url(${DepartmentBgImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '300px 50px',
          backgroundSize: '150px',
        },
      }}
    >
      <div className='relative'>
        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <TextInput
            {...register('inputValue', { required: true })}
            label='Name'
            size='md'
            placeholder={modalHeader}
            className='mt-10'
            classNames={{
              label: 'text-sm text-neutralsGrey',
              input: 'border-primary bg-transparent',
            }}
          />
          {errors.inputValue && (
            <p className='text-sm text-red-500 font-semibold'>* required</p>
          )}
          <div className='flex justify-center'>
            <button className='mt-16 mb-4 w-24'>
              <PrimaryButton isLoading={false}>
                <p>Save</p>
              </PrimaryButton>
            </button>
          </div>
        </form>
        <LeftArrowIcon
          className='absolute -top-[62px] h-5 w-5 cursor-pointer'
          onClick={closeModal}
        />
      </div>
    </Modal>
  );
}
