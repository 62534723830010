import { ReactComponent as SuccessIcon } from 'assets/images/SuccessIcon.svg';
import { ReactComponent as InfoIlls } from 'assets/images/infoIlls.svg';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/primaryButton';
export default function MeetingSuccess() {
  return (
    <div className='mx-6 lg:mx-0'>
      <div className='relative text-center min-h-80vh max-w-screen-xl mx-auto'>
        <div className='flex min-h-[80vh] bg-SuccessBgIllus bg-no-repeat bg-center bg-fill border-[#F9FAFB] border h-full w-full flex-col justify-center mx-auto rounded-lg bg-white shadow-lg my-8  pt-4 pb-8 px-8'>
          <div className='h-full flex flex-col justify-center w-full '>
            <div className='sucss-reg-bg w-full flex justify-center items-center my-8 md:my-12 bigtab:my-8 lg:my-8'>
              <div className=''>
                <SuccessIcon />
              </div>
            </div>
            <p className='text-515151 text-xl 2xl:text-2xl'>Meeting ended</p>
            <div className='flex justify-center items-center gap-x-2'>
              <InfoIlls className='w-4 h-4' />
              <p className='text-gray3 text-sm '>
                If you accedentally quited. Please rejoin with the link provided
                in email
              </p>
            </div>
            {localStorage?.getItem('token') && (
              <div className='w-fit self-center mt-4'>
                <PrimaryButton>
                  <Link to='/'> Visit Home</Link>
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
