import React from 'react';
import { Routes, Route } from 'react-router-dom';

import MyTeams from './pages/myTeam/myTeam';
import TeamMemberProfile from './pages/myTeam/components/teamMemberProfile';
import ManageLeaves from './pages/manageLeaves/manageLeaves';
import Performance from './pages/performance/performance';
import NoPageFound from 'pages/common/pageNotFound';

export default function Teams() {
  return (
    <div>
      <Routes>
        <Route path='my-team' element={<MyTeams />} />
        <Route path='my-team/:id' element={<TeamMemberProfile />} />
        <Route path='manage-leaves' element={<ManageLeaves />} />
        <Route path='performance' element={<Performance />} />

        {/* empty page when route not found */}
        <Route path='*' element={<NoPageFound />} />
      </Routes>
    </div>
  );
}
