import { useMutation, useQueryClient } from "react-query";
import makeApiRequest from "services/makeApiRequest";
import apiEndPoints from "services/apiEndPoints";
import { ErrorToast, SuccessToast } from "services/toasterService";

export default function useAddDepartment(closeModal) {
  const queryClient = useQueryClient();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.post(
        apiEndPoints.SETTINGS_DEPARTMENTS,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("settings-departments");
        queryClient.invalidateQueries(
          "settings-reporting-managers-and-employees"
        );
        closeModal();
        SuccessToast({ text: data?.message });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
