import { Modal, Switch } from '@mantine/core';
import { DatePicker, DateRangePicker } from '@mantine/dates';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';
import { ReactComponent as CalanderIcon } from 'assets/icons/calanderIcon.svg';

import { useForm, Controller } from 'react-hook-form';
import PrimaryButton from 'components/primaryButton';

import Usecreatepayroll from '../hooks/usecreatepayroll';
import UseUpdatepayroll from '../hooks/useupdatepayroll';

import { useQuery } from 'react-query';

import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import { useState } from 'react';

// import { AuthContext } from 'context/authContext';
const CreatePayrollModal = ({
  setIsCreatePayrollOpened,
  isCreatePayrollOpened,
  payrollId,
  setPayrollId,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm();

  // const { authState } = useContext(AuthContext);

  const [currentButton, setCurrentButton] = useState(false);

  const defaultValues = {
    date1: '',
    payment_date: '',
    is_applicable_for_all: '',
  };

  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4 w-[550px]'>
        <LeftArrowIcon
          onClick={() => setIsCreatePayrollOpened(false)}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p className='text-primary'>
          {!!payrollId ? 'Edit payroll' : 'Creating payroll'}
        </p>
      </div>
    );
  };
  // eslint-disable-next-line
  const getpayrollData = useQuery(
    ['payroll-specific-data', payrollId],
    async () => {
      const response = await makeApiRequest.get(
        `${apiEndPoints.PAYROLL}?id=${payrollId}`
      );

      return response.data;
    },
    {
      enabled: !!payrollId,
      onSuccess: (data) => {
        resetFunction(data);
      },
    }
  );

  const resetFunction = (data) => {
    reset({
      date1: [
        new Date(data?.data[0]?.from_date.split('-')),
        new Date(data?.data[0]?.to_date.split('-')),
      ],
      payment_date: new Date(data?.data[0]?.payment_date.split('-')),
      is_applicable_for_all: data?.data[0]?.is_applicable_for_all,
    });
  };

  const closePayrollModal = () => {
    reset({ defaultValues });
    setIsCreatePayrollOpened(false);
    setPayrollId(null);
    setCurrentButton(false);
  };

  const createPayrollmutation = Usecreatepayroll(
    currentButton,
    closePayrollModal
  );
  const updatePayrollmutation = UseUpdatepayroll(
    currentButton,
    closePayrollModal
  );

  const sumitpayrollform = (data) => {
    const fromDate = `${data.date1[0].getFullYear()}-${String(
      data.date1[0].getMonth() + 1
    ).padStart(2, '0')}-${String(data.date1[0].getDate()).padStart(2, '0')}`;
    const toDate = `${data.date1[1].getFullYear()}-${String(
      data.date1[1].getMonth() + 1
    ).padStart(2, '0')}-${String(data.date1[1].getDate()).padStart(2, '0')}`;

    delete data.date1;
    delete data.defaultValues;
    if (!!payrollId) {
      updatePayrollmutation.mutate({
        ...data,
        payment_date: `${data.payment_date.getFullYear()}-${String(
          data.payment_date.getMonth() + 1
        ).padStart(2, '0')}-${String(data.payment_date.getDate()).padStart(
          2,
          '0'
        )}`,
        from_date: fromDate,
        to_date: toDate,
        id: payrollId,
      });
    } else {
      createPayrollmutation.mutate({
        ...data,
        payment_date: `${data.payment_date.getFullYear()}-${String(
          data.payment_date.getMonth() + 1
        ).padStart(2, '0')}-${String(data.payment_date.getDate()).padStart(
          2,
          '0'
        )}`,
        from_date: fromDate,
        to_date: toDate,
      });
    }
  };

  return (
    <Modal
      opened={isCreatePayrollOpened}
      onClose={closePayrollModal}
      title={customTitle()}
      withCloseButton={false}
      overlayColor={'#F8F7F799'}
      overlayBlur={20}
      centered
      overlayOpacity={0.5}
      transitionDuration={100}
      size='lg'
      overflow='inside'
      className='px-8'
      classNames={{
        title: 'text-lg font-semibold ',

        body: 'scroller',
      }}
    >
      {' '}
      <form onSubmit={handleSubmit(sumitpayrollform)}>
        <div>
          <Controller
            control={control}
            name='date1'
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <DateRangePicker
                // {...register('date1', { required: 'Required' })}
                {...field}
                label='Select period'
                inputFormat='YYYY-MM-DD'
                // value={field.value ? field.value : null}
                dropdownType='modal' //type can be popover also
                className='mt-5'
                classNames={{
                  input:
                    'h-[50px] text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5',
                  label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                }}
                rightSection={<CalanderIcon />}
                styles={{ rightSection: { pointerEvents: 'none' } }}
              />
            )}
          />

          {errors.date1 && (
            <p className='text-sm text-[Inter] text-[#fc0101] '>
              {errors.date1.message}
            </p>
          )}
        </div>{' '}
        <div>
          <Controller
            control={control}
            name='payment_date'
            rules={{ required: 'Required' }}
            render={({ field }) => (
              <DatePicker
                // {...register('payment_date', { required: 'Required' })}
                {...field}
                label='Select payment date'
                dropdownPosition={'top'}
                inputFormat='YYYY-MM-DD'
                dropdownType='modal' //type can be popover also
                // placeholder='Select a date'
                className='mt-5'
                classNames={{
                  input:
                    'h-[50px] text-[15px] bg-transparent border-[#FB7C51] text-lg rounded-lg pl-5',
                  label: 'text-[#8E8E8E] text-sm font-normal ml-2',
                }}
                rightSection={<CalanderIcon />}
                styles={{ rightSection: { pointerEvents: 'none' } }}
              />
            )}
          />

          {errors.payment_date && (
            <p className='text-sm text-[Inter] text-[#fc0101] '>
              {errors.payment_date.message}
            </p>
          )}
        </div>
        <div>
          <Controller
            control={control}
            name='is_applicable_for_all'
            defaultValue={true}
            render={({ field }) => (
              <Switch
                // {...register('is_applicable_for_all')}
                {...field}
                label='Applicable for all employees'
                checked={watch('is_applicable_for_all')}
                className='mt-5'
                classNames={{
                  label: 'text-[#8E8E8E] text-normal font-normal ',
                }}
              />
            )}
          />
        </div>
        <div className='flex justify-center items-center gap-4 mt-7 mb-4'>
          {/* <button
            formAction='1'
            type='button'
            onClick={() => {
              setCurrentButton(true);
              setTimeout(() => {
                sumitpayrollform({
                  date1: watch('date1'),
                  payment_date: watch('payment_date'),
                  is_applicable_for_all: watch('is_applicable_for_all'),
                });
              }, 10);
              // sumitpayrollform({
              //   date1: watch('date1'),
              //   payment_date: watch('payment_date'),
              //   is_applicable_for_all: watch('is_applicable_for_all'),
              // });

              // updatePayrollmutation.isSuccess === true
              //   ? navigate('/app/team/my-team', { replace: true })
              //   : null;
              // createPayrollmutation.isSuccess &&
              //   navigate('/app/team/my-team', { replace: true });
            }}
          >
            <SecondaryButton>Next</SecondaryButton>
          </button> */}
          <button formAction='2'>
            <PrimaryButton>Submit</PrimaryButton>
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default CreatePayrollModal;
