import React, { useContext, useEffect, useState } from 'react';
import { Modal, Stepper } from '@mantine/core';
import { AuthContext } from 'context/authContext';
import dayjs from 'dayjs';

import useAddOrganisation from '../hooks/useAddOrganisation';
import useUpdateCompanyDetails from '../hooks/useUpdateCompanyDetails';

import OrganisationFirstStep from './organisationFirstStep';
import { useForm } from 'react-hook-form';
import OrganisationSecondStep from './organisationSecondStep';
import OrganisationThirdStep from './organisationThirdStep';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/orangeLeftArrow.svg';

export default function AddOrganisationModal({
  isModalOpen,
  setIsModalOpen,
  companyDetails,
  company_id,
  formName = 'Add',
}) {
  const [active, setActive] = useState(0);
  const [finalData, setfinalData] = useState('');

  const { authState } = useContext(AuthContext);

  const defaultValues = {
    organisation_id: authState?.organisation_id,
    first_name: '',
    company_name: '',
    email: '',
    country_code: '',
    mobile_number: '',
    country: '',
    city: '',
    company_address: '',
    fy_to_date: '',
    is_fy_fiscal: 'false',
    fy_from_date: '',
  };

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues });

  const closeModal = () => {
    setIsModalOpen(false);
    reset();
    setActive(0);
  };

  //   custom Header for modal
  const customTitle = () => {
    return (
      <div className='relative flex justify-center items-center text-xl mx-4'>
        <LeftArrowIcon
          onClick={closeModal}
          className='w-4 absolute left-0 cursor-pointer'
        />
        <p>{formName} organisation</p>
      </div>
    );
  };

  // navigating between stepper
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const onFirstStepSubmit = (data) => {
    setfinalData(data);
    nextStep();
  };

  const addOrganisationMutation = useAddOrganisation(closeModal);
  const updateCompanyDetailsMutation = useUpdateCompanyDetails(closeModal);

  const handleOrgRegister = () => {
    finalData['email'] = finalData?.email.toLowerCase();
    finalData['fy_from_date'] =
      dayjs(finalData?.fy_from_date).format('YYYY-MM-DD') === 'Invalid Date' ||
      finalData?.is_fy_fiscal === 'false'
        ? '2022-01-01'
        : dayjs(finalData?.fy_from_date).format('YYYY-MM-DD');
    finalData['fy_to_date'] =
      dayjs(finalData?.fy_to_date).format('YYYY-MM-DD') === 'Invalid Date' ||
      finalData?.is_fy_fiscal === 'false'
        ? '2022-12-31'
        : dayjs(finalData?.fy_to_date).format('YYYY-MM-DD');
    finalData['is_fy_fiscal'] =
      finalData?.is_fy_fiscal === 'false' ? false : true;
    finalData['timezone_info'] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (finalData?.company_id) {
      delete finalData?.first_name;
      delete finalData?.email;
      finalData['country_code_1'] = finalData?.country_code;
      delete finalData?.country_code;
      delete finalData?.company_name;
      updateCompanyDetailsMutation?.mutate(finalData);
    } else {
      addOrganisationMutation.mutate(finalData);
    }
  };

  useEffect(() => {
    if (companyDetails) {
      reset({
        company_id: companyDetails?.company__id || company_id,
        first_name:
          companyDetails?.company__user__first_name ||
          companyDetails?.user__first_name,
        company_name:
          companyDetails?.company__company_name || companyDetails?.company_name,
        email:
          companyDetails?.company__user__email || companyDetails?.user__email,
        country_code:
          companyDetails?.company__country_code_1 ||
          companyDetails?.country_code_1,
        mobile_number:
          companyDetails?.company__mobile_number ||
          companyDetails?.mobile_number,
        country: companyDetails?.company__country || companyDetails?.country,
        city: companyDetails?.company__city || companyDetails?.city,
        company_address:
          companyDetails?.company__company_address ||
          companyDetails?.company_address,
        fy_from_date: companyDetails?.company__fy_from_date
          ? new Date(companyDetails?.company__fy_from_date)
          : companyDetails?.fy_from_date
          ? new Date(companyDetails?.fy_from_date)
          : null,
        is_fy_fiscal: companyDetails?.company__is_fy_fiscal
          ? companyDetails?.company__is_fy_fiscal
            ? 'true'
            : 'false'
          : companyDetails?.is_fy_fiscal
          ? 'true'
          : 'false',
        fy_to_date: companyDetails?.company__fy_to_date
          ? new Date(companyDetails?.company__fy_to_date)
          : companyDetails?.fy_to_date
          ? new Date(companyDetails?.fy_to_date)
          : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails]);

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={() => closeModal()}
        title={customTitle()}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        overlayOpacity={0.5}
        centered
        size='xl'
        radius='md'
        overflow='inside'
        transitionDuration={100}
        classNames={{
          title: 'w-full text-primary font-semibold text-18',
          modal: 'pl-10 ',
          body: 'scroller -mr-4 pr-2',
        }}
      >
        {/* button to trap focus */}
        <button className='hidden'>a</button>
        <div className='mt-2'>
          <Stepper
            active={active}
            onStepClick={setActive}
            breakpoint='sm'
            classNames={{ steps: 'mx-40' }}
          >
            <Stepper.Step allowStepSelect={false}>
              <OrganisationFirstStep
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onFirstStepSubmit={onFirstStepSubmit}
                formName={formName}
                watch={watch}
                setValue={setValue}
              />
            </Stepper.Step>
            <Stepper.Step allowStepSelect={false}>
              <OrganisationSecondStep
                prevStep={prevStep}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onFirstStepSubmit={onFirstStepSubmit}
                watch={watch}
              />
            </Stepper.Step>
            <Stepper.Step allowStepSelect={false}>
              <OrganisationThirdStep
                prevStep={prevStep}
                handleOrgRegister={handleOrgRegister}
                isLoading={
                  addOrganisationMutation?.isLoading ||
                  updateCompanyDetailsMutation?.isLoading
                }
              />
            </Stepper.Step>
          </Stepper>
        </div>
      </Modal>
    </div>
  );
}
