import React, { useState } from 'react';
import { Avatar } from '@mantine/core';

import dateFormatter from 'helper/dateFormatter';
import useGetAllAppliedLeavesForAdmin from '../hooks/useGetAllAppliedLeaves';

import { ReactComponent as EmptyLeavesIlls } from 'assets/images/emptyLeaveIlls.svg';
import { ReactComponent as WrongIcon } from 'assets/icons/wrongIcon.svg';
import AcceptLeaveModal from './acceptOrRejectLeaveModal';

export default function ProcessedRequest({
  leaveStatus,
  selectedMonthFilterValue,
  searchValue,
  yearFilterValue,
}) {
  const getAppliedProcessedLeaves = useGetAllAppliedLeavesForAdmin(
    leaveStatus,
    'Pending',
    selectedMonthFilterValue,
    searchValue,
    yearFilterValue
  );

  const [isAcceptLeaveModalOpen, setIsAcceptLeaveModalOpen] = useState(false);
  const [modalName, setModalName] = useState('approve');
  const [selectedLeaveData, setSelectedLeaveData] = useState('');

  return (
    <div className='shadow-lg border border-8B8B8B border-opacity-10  rounded-xl bg-white my-4 h-[65vh] 2xl:h-[80vh] overflow-y-scroll scroller'>
      {getAppliedProcessedLeaves?.data?.data?.length ? (
        <table className='w-full rounded-xl '>
          <tbody>
            <tr className='bg-FFE8E1 text-left text-18  text-222222 sticky top-0 z-20'>
              <th className='py-4 pl-5 rounded-tl-xl'>Name </th>
              <th className=' pl-5'>Dept.</th>
              <th className=' pl-5'>Leave type</th>
              <th className=' pl-5'>Leave period</th>
              <th className=' pl-5'>Duration</th>
              <th className=' pl-5'>Reason</th>
              <th className=' pl-5'>Action</th>
              <th className=' pl-5 rounded-tr-xl'></th>
            </tr>
            {getAppliedProcessedLeaves?.data?.data?.map((item) => (
              <tr className='text-9B9B9B' key={item?.id}>
                <td>
                  <div className='flex space-x-1 items-center pl-4'>
                    <Avatar
                      radius='xl'
                      color={'red'}
                      src={item?.employee__profile_picture}
                      classNames={{
                        image: 'border border-primary rounded-full',
                        placeholder: 'border border-primary rounded-full',
                      }}
                    >
                      {item?.employee__user__first_name[0].toUpperCase()}
                    </Avatar>
                    <p>{item?.employee__user__first_name}</p>
                  </div>
                </td>
                <td className='py-5 pl-5 text-primary'>
                  {item?.employee__department__name}
                </td>
                <td className='py-5 pl-5 text-primary'>
                  {item?.leave_type__name}{' '}
                </td>
                <td className='pl-5'>
                  <div>
                    <p>{dateFormatter(item?.from_date, 'DD MMM YY')} to</p>
                    <p>{dateFormatter(item?.to_date, 'DD MMM YY')}</p>
                  </div>
                </td>
                <td className='pl-5'>
                  {item?.number_of_days === 1
                    ? `${item?.number_of_days} day`
                    : `${item?.number_of_days} days`}
                </td>
                <td className='py-5 pl-5 break-all w-72'>{item?.reason}</td>
                <td className='pl-5' colspan={2}>
                  {item?.status === 'Approved' ? (
                    <div className='flex items-center justify-between'>
                      <div>
                        <p className='text-success'>{item?.status}</p>
                        <p className='text-[#707070]'>
                          by {item?.updated_by__first_name}.
                        </p>
                      </div>
                      <div className='mr-5'>
                        <WrongIcon
                          className='cursor-pointer'
                          onClick={() => {
                            setModalName('reject');
                            setSelectedLeaveData(item);
                            setIsAcceptLeaveModalOpen(true);
                          }}
                        />
                      </div>
                    </div>
                  ) : item?.status === 'Cancelled' ? (
                    <p className='text-[#BC2C18]'>{item?.status}</p>
                  ) : (
                    <>
                      <p className='text-[#BC2C18]'>{item?.status}</p>
                      <p className='text-[#707070]'>
                        by {item?.updated_by__first_name}.
                      </p>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className='flex flex-col justify-center items-center h-[60vh]'>
          <EmptyLeavesIlls className='w-72' />
          <p className='text-lg text-gray'>No leave requests to show</p>
        </div>
      )}
      <AcceptLeaveModal
        isModalOpen={isAcceptLeaveModalOpen}
        setIsModalOpen={setIsAcceptLeaveModalOpen}
        modalName={modalName}
        selectedLeaveData={selectedLeaveData}
      />
    </div>
  );
}
