import { AuthContext } from 'context/authContext';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';
import { ErrorToast } from 'services/toasterService';

export default function useChangeSuperAdmin(closeModal) {
  const { authDispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  return useMutation(
    async (data) => {
      const response = await makeApiRequest.put(
        apiEndPoints.CHANGE_SUPER_ADMIN,
        data
      );
      return response?.data;
    },
    {
      onSuccess: (data) => {
        closeModal();
        authDispatch({
          type: 'LOGOUT',
        });
        navigate(`/auth/login`, { replace: true });
      },
      onError: (error) => {
        ErrorToast({ text: error.response.data.message });
      },
    }
  );
}
