import { Avatar, Indicator } from '@mantine/core';
import dayjs from 'dayjs';
import StatusLabel from 'pages/chat/hooks/statusLabel';
import React from 'react';
import FileViewer from './fileViewer';

export default function ReceivedMessageCard({ message, activeUser }) {
  return (
    <div className='flex my-1'>
      <div className='flex w-full'>
        <div className=' custom-indicator'>
          <Indicator
            inline
            size={16}
            offset={6}
            label={StatusLabel(activeUser?.status, true)}
            position='bottom-end'
            withBorder
            radius='xl'
          >
            <Avatar
              src={activeUser?.profile_picture || ''}
              radius='xl'
              color='red'
              size={'32px'}
              classNames={{ root: 'border-2 border-primary' }}
            >
              {activeUser?.username?.[0] || '-'}
            </Avatar>
          </Indicator>
        </div>

        <div className='flex bg-FEFEFE flex-col justify-around rounded ml-2 p-3 max-w-xl'>
          <div className='flex'>
            <p className=' leading-4 text-xs text-primary'>
              {activeUser?.username}
            </p>
            <p className='text-838383 text-xs ml-2'>
              {dayjs(message?.timestamp).isToday()
                ? dayjs(message?.timestamp).format('hh:mm A')
                : dayjs(message?.timestamp).format('DD/MM')}
            </p>
          </div>
          {!message?.file_url ? (
            <div className='flex'>
              <p className='text-4F4F4F '>{message?.content}</p>
            </div>
          ) : (
            <FileViewer message={message}></FileViewer>
          )}
        </div>
      </div>
    </div>
  );
}
