import React, { useState } from 'react';
import { Tabs, Select, Input } from '@mantine/core';
import Header from 'components/Header';
import { useDebouncedValue } from '@mantine/hooks';

import NewLeaveRequest from './components/newLeaveRequest';
import ProcessedRequest from './components/processedRequest';
import useGetAllAppliedLeavesForAdmin from './hooks/useGetAllAppliedLeaves';

import { ReactComponent as SearchIcon } from '../../../../assets/icons/searchIcon.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filterIcon.svg';
import { ReactComponent as DownArrow } from '../../../../assets/icons/downArrow.svg';
import { useSearchParams } from 'react-router-dom';

export default function ManageLeaves() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [selectedMonthFilterValue, setSelectedMonthFilterValue] = useState('');
  const [leaveStatus, setLeaveStatus] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [yearFilterValue, setYearFilterValue] = useState(
    new Date().getFullYear()
  );

  const [debounced] = useDebouncedValue(searchValue, 300);

  const handleTabChange = (e) => {
    setSearchParams({ tab: e });
  };
  //calculating Years for Processed Leaves
  const getAppliedProcessedLeaves = useGetAllAppliedLeavesForAdmin(
    null,
    'Pending'
  );
  let minYear = getAppliedProcessedLeaves?.data?.years[1];
  let maxYear = getAppliedProcessedLeaves?.data?.years[0];
  const processedYears = [];
  if (minYear === undefined || maxYear === undefined) {
    processedYears.push({
      value: 'no data',
      label: 'No data',
      disabled: true,
    });
  } else {
    if (minYear === maxYear) {
      processedYears.push({ value: minYear, label: minYear });
    } else {
      for (let i = minYear; i <= maxYear; i++) {
        processedYears.push({ value: minYear, label: minYear });
        minYear++;
      }
    }
  }

  //calculating months for Processed Leaves
  let maxMonth = getAppliedProcessedLeaves?.data?.max_month;

  let monthNames = [];
  const months = [
    { value: null, label: 'All' },
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];
  if (yearFilterValue) {
    if (yearFilterValue === maxYear) {
      monthNames.push({ value: null, label: 'All' });
      for (let i = 1; i <= maxMonth; i++) {
        monthNames.push(months[i]);
      }
    } else if (!maxMonth) {
      monthNames = [
        {
          value: 'no data',
          label: 'No data',
          disabled: true,
        },
      ];
    } else {
      monthNames = months;
    }
  }

  //calculating Years for applied Leaves
  const getAppliedAppliedLeaves = useGetAllAppliedLeavesForAdmin(
    'Pending',
    null
  );
  let minYearA = getAppliedAppliedLeaves?.data?.years[1];
  let maxYearA = getAppliedAppliedLeaves?.data?.years[0];
  const appliedYears = [];
  if (minYearA === undefined || maxYearA === undefined) {
    appliedYears.push({
      value: 'no data',
      label: 'No data',
      disabled: true,
    });
  } else {
    if (minYearA === maxYearA) {
      appliedYears.push({ value: minYearA, label: minYearA });
    } else {
      for (let i = minYearA; i <= maxYearA; i++) {
        appliedYears.push({ value: minYearA, label: minYearA });
        minYearA++;
      }
    }
  }

  //calculating months for Processed Leaves
  let maxMonthA = getAppliedAppliedLeaves?.data?.max_month;

  let monthNamesA = [];
  const monthsA = [
    { value: null, label: 'All' },
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];
  if (yearFilterValue) {
    if (yearFilterValue === maxYearA) {
      monthNamesA.push({ value: null, label: 'All' });
      for (let i = 1; i <= maxMonthA; i++) {
        monthNamesA.push(monthsA[i]);
      }
    } else if (!maxMonthA) {
      monthNamesA = [
        {
          value: 'no data',
          label: 'No data',
          disabled: true,
        },
      ];
    } else {
      monthNamesA = monthsA;
    }
  }

  return (
    <div className='px-5'>
      <div className='flex justify-between items-center mt-6'>
        <p className='text-xl text-A8A8A8'>
          Team &#60;{' '}
          <span className='text-gray font-semibold'>Manage leaves</span>
        </p>
        <Header />
      </div>
      <div className='mt-8 relative'>
        <div>
          <Tabs
            active={~~searchParams.get('tab')}
            tabPadding={21}
            onTabChange={handleTabChange}
            classNames={{
              tabsListWrapper: 'border-0',
              tabActive: 'border-2 border-primary font-semibold text-18',
              tabControl: 'text-A8A8A8 leading-5 px-0 mr-4',
              tabLabel: 'text-18 text-gray',
            }}
          >
            <Tabs.Tab label='New requests'>
              <NewLeaveRequest
                selectedMonthFilterValue={selectedMonthFilterValue}
                searchValue={debounced}
                yearFilterValue={yearFilterValue}
              />
            </Tabs.Tab>
            <Tabs.Tab label='Processed requests'>
              <ProcessedRequest
                leaveStatus={leaveStatus}
                selectedMonthFilterValue={selectedMonthFilterValue}
                searchValue={debounced}
                yearFilterValue={yearFilterValue}
              />
            </Tabs.Tab>
          </Tabs>

          <div className='absolute top-2 right-0'>
            <div className='flex items-center space-x-4'>
              <Input
                icon={<SearchIcon />}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder='Search'
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-36 bg-light-bg border-none',
                }}
              />
              <Select
                placeholder='Month'
                icon={<FilterIcon />}
                onChange={setSelectedMonthFilterValue}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                rightSection={<DownArrow />}
                clearable
                data={
                  ~~searchParams.get('tab') === 0 ? monthNamesA : monthNames
                }
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-40 bg-light-bg border-none',
                }}
              />
              <Select
                placeholder='Year'
                icon={<FilterIcon />}
                styles={{ rightSection: { pointerEvents: 'none' } }}
                rightSection={<DownArrow />}
                onChange={setYearFilterValue}
                clearable
                data={
                  ~~searchParams.get('tab') === 0
                    ? appliedYears
                    : processedYears
                }
                classNames={{
                  wrapper: 'border-primary border-b',
                  input: 'w-40 bg-light-bg border-none',
                }}
              />

              {~~searchParams.get('tab') === 0 ? null : (
                <Select
                  placeholder='Type'
                  icon={<FilterIcon />}
                  onChange={setLeaveStatus}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  rightSection={<DownArrow />}
                  clearable={true}
                  data={[
                    { value: null, label: 'All' },
                    { value: 'Approved', label: 'Accepted' },
                    { value: 'Declined', label: 'Rejected' },
                    { value: 'Cancelled', label: 'Cancelled' },
                  ]}
                  classNames={{
                    wrapper: 'border-primary border-b',
                    input: 'w-40 bg-light-bg border-none',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
