import { useContext, useState } from 'react';
import { Table } from '@mantine/core';
import SecondaryButton from 'components/secondaryButton';
import dateFormatter from 'helper/dateFormatter';
import usePayslips from '../hooks/usePayslips';
import ViewPaySlipModal from './viewpayslipmodal';
import { ReactComponent as NoPaySlipsImg } from 'assets/images/noPayslips.svg';
import { AuthContext } from 'context/authContext';

import getSymbolFromCurrency from 'currency-symbol-map';

const PayslipsTab = ({ selectedYear }) => {
  const [viewPayslipModalOpened, setViewPayslipModalOpened] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();

  const { authState } = useContext(AuthContext);
  const currencyIcon = getSymbolFromCurrency(authState?.currency);

  const getAllPayslips = usePayslips(selectedYear);

  const rows = getAllPayslips?.data?.data?.map((payslip) => {
    return (
      <tr key={payslip?.id}>
        <td className='text-center text-[#707070]'>
          {dateFormatter(payslip?.payroll__from_date, 'MMMM')}
        </td>
        <td className='text-center text-[#707070]'>
          {dateFormatter(payslip?.payroll__from_date, 'DD/MM/YYYY')} -{' '}
          {dateFormatter(payslip?.payroll__to_date, 'DD/MM/YYYY')}
        </td>
        <td className='text-center text-[#707070]'>
          <p>{dateFormatter(payslip?.created_date, 'DD MMM YYYY')}</p>
          <p>{dateFormatter(payslip?.created_date, 'hh:mm A')}</p>
        </td>
        <td className='text-center text-[#707070]'>
          {currencyIcon}
          {payslip?.employee__gross_salary?.toFixed(2)}
        </td>
        <td className='text-center text-[#707070]'>
          {currencyIcon}
          {payslip?.net_pay}
        </td>
        <td className='text-center text-[#707070]'>
          <button
            onClick={() => {
              setViewPayslipModalOpened(true);
              setSelectedMonth(payslip);
            }}
            className='w-28'
          >
            <SecondaryButton>View </SecondaryButton>
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <>
        <div className='bg-white shadow-three min-h-[65vh] rounded-xl mt-4 relative'>
          <Table
            verticalSpacing='lg'
            highlightOnHover
            className='payslips-table'
          >
            <thead>
              <tr>
                <th
                  className='bg-[#FFE8E1] text-222222 rounded-tl-xl'
                  style={{ textAlign: 'center' }}
                >
                  Month
                </th>
                <th
                  className='bg-[#FFE8E1] text-222222'
                  style={{ textAlign: 'center' }}
                >
                  Payroll period
                </th>
                <th
                  className='bg-[#FFE8E1] text-222222'
                  style={{ textAlign: 'center' }}
                >
                  Date & time
                </th>
                <th
                  className='bg-[#FFE8E1] text-222222'
                  style={{ textAlign: 'center' }}
                >
                  Gross salary(CTC)
                </th>
                <th
                  className='bg-[#FFE8E1] text-222222'
                  style={{ textAlign: 'center' }}
                >
                  Net salary
                </th>
                <th
                  className='bg-[#FFE8E1] text-222222 rounded-tr-xl'
                  style={{ textAlign: 'center' }}
                >
                  Payslips
                </th>
              </tr>
            </thead>

            <tbody className=' w-full max-h-20 border-none'>{rows}</tbody>
          </Table>
          {getAllPayslips?.data?.data?.length === 0 && (
            <div className='absolute left-1/2 top-1/3 -translate-x-1/2'>
              <NoPaySlipsImg />
              <p className='text-center text-[#707070]'>No payslips to show</p>
            </div>
          )}
        </div>
      </>

      <ViewPaySlipModal
        viewPayslipModalOpened={viewPayslipModalOpened}
        setViewPayslipModalOpened={setViewPayslipModalOpened}
        selectedMonth={selectedMonth}
        currencyIcon={currencyIcon}

      />
    </div>
  );
};

export default PayslipsTab;
