import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './components/login';
import ForgotPassword from './components/forgotPassword';
import ForgotPasswordOtpVerfication from './components/forgotpasswordOtp';
import ChangePassword from './components/changePassword';
import PasswordSuccess from './components/passwordSuccess';
import VerifySignup from './components/verifysignup';
import SignUp from './components/signup';

const Auth = () => {
  return (
    <Routes>
      <Route path='/login' element={<LoginPage />} />
      <Route path='/signup' element={<SignUp />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route
        path='/forgot-password-otp-verify'
        element={<ForgotPasswordOtpVerfication />}
      />
      <Route path='/Change-password' element={<ChangePassword />} />
      <Route path='/success' element={<PasswordSuccess />} />
      <Route path='/verify' element={<VerifySignup />} />
    </Routes>
  );
};

export default Auth;
