import { useQuery } from 'react-query';
import apiEndPoints from 'services/apiEndPoints';
import makeApiRequest from 'services/makeApiRequest';

import createQueryParamsForGetReq from 'helper/createQueryParamsForGetReq';

export default function useGetAllGrades(isModalOpen, company_id) {
  return useQuery(
    ['get-all-grades', company_id],
    async () => {
      const res = await makeApiRequest.get(
        createQueryParamsForGetReq(apiEndPoints.GRADES_API, {
          req_company_id: company_id,
        })
      );
      return res.data;
    },
    {
      enabled: isModalOpen,
    }
  );
}
