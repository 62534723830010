import React, { useContext } from 'react';
import { Modal } from '@mantine/core';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/primaryButton';
import { AuthContext } from 'context/authContext';

import { ReactComponent as WelcomeImg } from 'assets/images/welcomeImg.svg';

export default function FirstLoginModal({
  isFirstLoginModalOpen,
  setIsFirstLoginModalOpen,
}) {
  const { authState } = useContext(AuthContext);

  //   const handleModalClose = () => {
  //     setIsFirstLoginModalOpen(false);
  //   };
  return (
    <div>
      <Modal
        opened={isFirstLoginModalOpen}
        onClose={() => setIsFirstLoginModalOpen(true)}
        withCloseButton={false}
        overlayColor={'#F8F7F799'}
        overlayBlur={20}
        centered
        overlayOpacity={0.5}
        transitionDuration={100}
        size='lg'
        overflow='inside'
        className='px-8'
        classNames={{
          title: 'text-lg font-semibold ',
          body: 'scroller',
        }}
      >
        <div>
          <WelcomeImg className='w-80 mx-auto' />
        </div>
        <p className='text-lg text-gray text-center'>
          We are glad to have you here. Please take a moment to set up your
          {authState?.is_super_admin ? (
            <>
              <span className='text-67A1EF'> organisation settings </span> and{' '}
              <span className='text-67A1EF'>my settings.</span>
            </>
          ) : (
            <>
              <span className='text-67A1EF'> settings. </span>
            </>
          )}
        </p>
        <div className='flex justify-center mt-8 mb-2'>
          <Link
            to={
              authState?.is_super_admin
                ? '/app/organisation-settings'
                : '/app/settings'
            }
          >
            <button>
              <PrimaryButton>Take me there</PrimaryButton>
            </button>
          </Link>
        </div>
      </Modal>
    </div>
  );
}
